import React, { useEffect, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import CustomTypography from '../utils/text';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import Searchicon from '../icons/search_icon';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import CustomDataGrid from '../components/custom_datagrid';
import LoadingOverlayComponent from '../components/lodding_component';
import { useSelector } from 'react-redux';
import { Buffer } from 'buffer';
import _ from 'lodash';
import SnackBar from '../components/SnackBar';
import WarningMessage from '../components/warning_snackbar';
import AlertBar from '../components/alertBar';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      borderColor: '#1a1a1a',
    },
    '&:hover $notchedOutline': {
      borderColor: '#205747 !important',
    },
    '&$focused $notchedOutline': {
      borderColor: '#205747 !important',
    },
  },
  focused: {},
  notchedOutline: {},
}));
const ShortListView = () => {
  const outlinedInputClasses = useOutlinedInputStyles();
  const [value, setValue] = useState('1');
  const [shortListData, setshortListData] = useState([]);
  const [longListData, setLongListData] = useState([]);
  const [serachLongList, setSearchLongList] = useState([]);
  const [serachShortListData, setserachShortListData] = useState([]);
  const [isLodding, setIsLodding] = useState(true);
  const [search, setSearch] = useState('');
  const [shortListingNotifier, setShortListingNotifier] = useState(false);
  const hwgtkey = useSelector((state) => state.mainReducer.hwgtkey);
  const hkey = useSelector((state) => state.mainReducer.hkey);
  const hubWorkspace = useSelector((state) => state.mainReducer.hubWorkspace);
  const hubUser = useSelector((state) => state.mainReducer.hubUser);
  const [snackbar, setSnackbar] = useState(null);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const projectStatus = useSelector((state) => state.mainReducer.selectedProjectStatus);
  const projectCompletedDate = useSelector((state) => state.mainReducer.selectedProjectCompletedDate);
  const [openAlert, setAlert] = useState(false);

  const [alertMessage, setAlertMessage] = useState('');
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    if (projectStatus === 'Completed' || projectStatus === 'Archived') {
      setAlert(true);
      projectStatus === 'Completed'
        ? setAlertMessage('- This project was marked as completed on')
        : setAlertMessage('- This project was marked as archived on');
    }
  }, [projectStatus]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearch('');
  };
  useEffect(() => {
    fetchProperty();
  }, []);
  const fetchProperty = () => {
    fetchProperties();
  };

  const fetchProperties = async () => {
    try {
      const response = await axiosPrivate.get(`/short-list/properties?hkey=${hkey}&hwgtkey=${hwgtkey}`);
      setIsLodding(false);
      if (response.status === 200 && response.data.length > 0) {
        var responseArray = [];
        response.data?.map((item, index) => {
          responseArray.push({
            ...item,
            id: index + 1,
            Score: Math.round(item.Score),
          });
        });
        var shortListData = responseArray?.filter((e) => e.Shortlisted === 'Short');
        setLongListData(JSON.parse(JSON.stringify(responseArray)));
        setshortListData(JSON.parse(JSON.stringify(shortListData)));
      }
    } catch (error) {
      setWarningSnackbar({
        name: 'Property Info:',
        message: 'Unable to fetch short/long properties',
        severity: 'error',
      });
    }
  };

  const handleUpdateProperty = async (rowData) => {
    const hubProjctKey = Buffer.from(rowData.HKEY_HUB_PROPERTY.data);
    try {
      const reqObj = {
        hkey: hkey,
        propertyId: hubProjctKey.toString('hex').toUpperCase(),
        isShortList: rowData.Shortlisted === 'Short' ? false : true,
      };
      const response = await axiosPrivate.put(`/short-list/status`, reqObj);
      setShortListingNotifier(true);
      setIsLodding(false);
      if (response.status === 200 && response.data.length > 0) {
        fetchProperty();
        setSnackbar({
          name: rowData['Building Name'],
          message:
            rowData.Shortlisted === 'Short'
              ? ` has been successfully removed from your shortlist`
              : `has been successfully added to your shortlist`,
          severity: 'success',
        });
      }
    } catch (error) {
      setWarningSnackbar({
        name: 'Property Info:',
        message: 'Unable to short/unshort the properties',
        severity: 'error',
      });
    }
  };

  const handlesearchArray = (searchValue) => {
    setSearch(searchValue);
    if (value !== '1') {
      if (longListData.length > 0) {
        var searchArray = _.filter(
          longListData,
          (p) =>
            String(p['Building Name']).toLowerCase().includes(searchValue.toLowerCase()) ||
            String(p['Score']).toLowerCase().includes(String(searchValue).toLowerCase()) ||
            String(p['Address']).toLowerCase().includes(searchValue.toLowerCase()) ||
            String(p['Postcode']).toLowerCase().includes(searchValue.toLowerCase())
        );
        setSearchLongList(searchArray);
      }
    } else {
      if (shortListData.length > 0) {
        var searchShortListArray = _.filter(
          shortListData,
          (p) =>
            String(p['Building Name']).toLowerCase().includes(searchValue.toLowerCase()) ||
            String(p['Score']).toLowerCase().includes(String(searchValue).toLowerCase()) ||
            String(p['Address']).toLowerCase().includes(searchValue.toLowerCase()) ||
            String(p['Postcode']).toLowerCase().includes(searchValue.toLowerCase())
        );
        setserachShortListData(searchShortListArray);
      }
    }
  };

  useEffect(() => {
    handlesearchArray(search);
    setShortListingNotifier(false);
  }, [longListData]);
  return (
    <Grid style={{ backgroundColor: '#eef1f1', height: '100%' }}>
      <>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
            paddingLeft: 60,
            paddingRight: 60,
            paddingTop: 60,
            height: '13%',
          }}
        >
          <Grid item md={8}>
            <CustomTypography
              text='Shortlist view'
              fontfamily='Calibre-R'
              fontsize='36px'
              fontweight='400'
              lineheight='40px'
              fontcolor='#1A1A1A'
            />
          </Grid>
          {/* <Grid item md={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button className={classes.exportButton} style={{
                            textTransform: 'inherit'
                        }}
                        >
                            <DownloadIcon style={{ paddingRight: 15 }} />
                            <CustomTypography
                                text="Export"
                                fontfamily="Calibre-M"
                                fontsize="16px"
                                lineheight='16px'
                                fontweight="500"
                                fontcolor={"white"}
                            />
                        </Button>
                    </Grid> */}
        </Grid>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 30,
            height: '15%',
          }}
        >
          <Grid
            item
            md={8}
            style={{
              display: 'flex',
              height: '100%',
              paddingLeft: 24,
              backgroundColor: '#FFFFFF',
            }}
          >
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'white' }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: '#003F2D',
                      },
                    }}
                  >
                    <Tab
                      label={`Shortlist (${shortListData.length})`}
                      value='1'
                      style={{
                        fontSize: '20px',
                        color: value == '1' ? '#003F2D' : '#1A1A1A',
                        lineHeight: '13px',
                        fontFamily: 'Calibre-M',
                        fontWeight: 500,
                        textTransform: 'inherit',
                        paddingRight: 30,
                      }}
                    />
                    <Tab
                      label={`Longlist (${longListData.length})`}
                      value='2'
                      style={{
                        fontSize: '20px',
                        color: value == '2' ? '#003F2D' : '#1A1A1A',
                        lineHeight: '13px',
                        fontFamily: 'Calibre-M',
                        fontWeight: 500,
                        textTransform: 'inherit',
                      }}
                    />
                  </Tabs>
                </Box>
              </TabContext>
            </Box>
          </Grid>
          <Grid
            item
            md={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              backgroundColor: '#FFFFFF',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <OutlinedInput
              style={{ height: 40, marginRight: 30, width: 300 }}
              id='outlined-adornment-weight'
              value={search}
              onChange={(event) => handlesearchArray(event.target.value)}
              placeholder={'Search'}
              endAdornment={
                <InputAdornment position='end'>
                  <Searchicon />
                </InputAdornment>
              }
              aria-describedby='outlined-weight-helper-text'
              inputProps={{
                'aria-label': 'weight',
              }}
              classes={outlinedInputClasses}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
            paddingLeft: 30,
            paddingRight: 30,
            height: '75%',
          }}
        >
          <CustomDataGrid
            data={value == 1 ? (search ? serachShortListData : shortListData) : search ? serachLongList : longListData}
            handleClickCallback={(dataObj) =>
              projectStatus !== 'Completed' && projectStatus !== 'Archived'
                ? handleUpdateProperty(dataObj)
                : setAlert(true)
            }
            tabValue={value}
            searchNotifier={search}
            shortListingNotifier={shortListingNotifier}
            projectStatus={projectStatus}
          />
        </Grid>
      </>
      {isLodding && <LoadingOverlayComponent open={isLodding} />}
      {snackbar && (
        <SnackBar
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          name={snackbar.name}
          message={snackbar.message}
        />
      )}
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
      {openAlert && (
        <AlertBar
          open={true}
          setOpen={() => setAlert(null)}
          name={'View only mode '}
          message={alertMessage}
          // date field is pending
          date={projectCompletedDate}
        />
      )}
    </Grid>
  );
};
export default ShortListView;
