import React, { useEffect, useState } from 'react';
import { Dialog, Button, makeStyles, Grid } from '@material-ui/core';
import CloseIcon from '../icons/close_icon';
import CustomTypography from '../utils/text';
import CustomTextField from '../components/custom_textfield';
import LoadingOverlayComponent from '../components/lodding_component';
import { useSelector, useDispatch } from 'react-redux';
import DropDownComponent from './custom_dropdown';
import OAuthError from '../utils/errorMessages';
import WarningMessage from '../components/warning_snackbar';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import SearchComponent from './search_field';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import dataProvision from '../utils/data_provision';

const useStyles = makeStyles({
  buttonClass: {
    '&:hover': {
      color: 'white',
      background: '#003F2D',
    },
    background: '#003F2D',
    color: 'white',
    height: 32,
    width: 180,
  },
  disablebuttonClass: {
    '&:hover': {
      color: 'white',
      background: '#d4d7d7',
    },
    background: '#d4d7d7',
    height: 32,
    width: 180,
  },
  paper: {
    borderRadius: 10,
    margin: 0,
    overflow: 'hidden',
    maxWidth: 624,
  },
});
const ProjectModal = ({
  item,
  isEdit,
  origin = '',
  open = false,
  handleClose,
  handleSubmit,
  loading,
  setLoading,
  handleFalseError,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const IndustryCode = useSelector((state) => state.mainReducer.industryCode);
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [industryCodeValue, setIndustryCodeValue] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyNameMandotary, setCompanyNameMandatory] = useState(false);
  const [nameMandotary, setNameMandatory] = useState(false);
  const [nameLengthError, setNameLengthError] = useState(false);
  const [invalidCharacter, modifyInvalidCharacter] = useState(false);
  const [specialCharacterError, updateSpecialCharacterError] = useState(false);
  const [industryCodeMandotary, setIndustryCodeMandatory] = useState(false);
  const [error, setError] = useState(false);
  const [isSubmitClicked, setisSubmitClicked] = useState(false);
  const [isRenamed, renameStatus] = useState(false);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [isDuplicateNameLoading, updateDuplicateNameLoader] = useState(false);
  const [invalidCharacterBackslash, setInvalidCharacterBackslash] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [isProjectExist, setIsProjectExist] = useState(null);
  const [onchangeProjectName, setOnchangeProjectName] = useState();

  useEffect(() => {
    if (item && (origin === 'Rename' || origin === 'Duplicate')) {
      origin === 'Duplicate' ? getDuplicateProjectName(item.PROJECT_NAME) : setName(item.PROJECT_NAME);

      setDesc(item.PROJECT_DESCRIPTION);
      setIndustryCodeValue(item.INDUSTRY_CODE);
      setCompanyName(item?.COMPANY_NAME ?? '');
    }
  }, [item]);

  const handleError = (response) => {
    if (response?.response?.data?.errorMessage?.code === '390318') {
      setWarningSnackbar(OAuthError);
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } else {
      setWarningSnackbar({
        name: 'Project Info:',
        message: 'Unable to get the duplicate name ',
        severity: 'error',
      });
    }
  };

  const getDuplicateProjectName = async (name) => {
    updateDuplicateNameLoader(true);

    try {
      const response = await axiosPrivate.get(`/projectmodel/isproject-exist?name=${name.replaceAll("'", "''")}`);

      updateDuplicateNameLoader(false);

      // 1st if
      if (response.status === 200) {
        // 2nd if
        if (response[0]?.L_COUNT === 1) {
          setName('Copy of' + '\u00A0' + name + '\u00A0' + '1');
          //setName(`Copy of ${name} 1`);
        } else {
          const searchDuplicateName = 'Copy of' + '\u00A0' + name;
          // const searchDuplicateName = `Copy of ${name}`;

          updateDuplicateNameLoader(true);

          try {
            console.log('------------duplicate search request object------------------' + searchDuplicateName);

            const response = await axiosPrivate.get(
              `/projectmodel/search-duplicate?searchDuplicateName=${searchDuplicateName}`
            );

            if (response.status === 200) {
              const n = response.data
                .filter((el) => el.PROJECT_NAME.length === searchDuplicateName.length + 2)
                .map((elm) => elm.PROJECT_NAME.slice(-1));

              let newName;

              if (n.length) {
                const maxVal = Math.max(...n) + 1;
                newName = searchDuplicateName + '\u00A0' + maxVal;
              } else {
                newName = searchDuplicateName + '\u00A0' + 1;
              }

              setName(newName);
              updateDuplicateNameLoader(false);
            }
          } catch (error) {
            console.log('error----------duplicatesearch--------------', error);
          }
        } // end of 2nd if else
      } // end of 1st if
      else {
        handleError(response);
      }
    } catch (error) {
      console.log('error in the getDuplicateProjectName--------', error);
    }
  }; //end-of-getDuplicateProjectName

  const handleSubmitResponse = () => {
    if (
      name &&
      industryCodeValue &&
      !specialCharacterError &&
      companyName &&
      name.length < 101 &&
      !invalidCharacterBackslash
    ) {
      setisSubmitClicked(true);
      if (origin != 'Rename') {
        GetIsProjectNameUnique(name, desc, origin);
      } else {
        if (name == item?.PROJECT_NAME) {
          let txt = 'create';
          if (origin === 'Rename') {
            txt = 'edit';
          }
          if (origin === 'Duplicate') {
            txt = 'Duplicate';
          }
          handleSubmit(
            name,
            desc,
            origin === 'Duplicate' || origin === 'Rename' ? true : false,
            txt,
            item,
            industryCodeValue,
            companyName
          );
        } else {
          GetIsProjectNameUnique(name, desc, origin);
        }
      }
    } else {
      if (!name) {
        setNameMandatory(true);
      }
      if (name.length > 100) {
        setNameLengthError(true);
      }
      if (!industryCodeValue) {
        setIndustryCodeMandatory(true);
      }
      if (!companyName) {
        setCompanyNameMandatory(true);
      }
    }
  };
  const GetIsProjectNameUnique = async (name, desc, origin) => {
    setLoading(true);
    try {
      const compName = typeof companyName === 'object' ? companyName.value : companyName;
      const response = await Promise.all([
        axiosPrivate.get(`/projectmodel/isprojectunique?name=${name.replaceAll("'", "''")}`),
        dataProvision(compName),
      ]);

      if (response[0].status === 200 && response[1].status === 200) {
        setIsProjectExist(name);
        if (response[0].data[0]['PROJ_COUNT'] == 0) {
          let txt = 'create';
          if (origin === 'Rename') {
            txt = 'edit';
          }
          if (origin === 'Duplicate') {
            txt = 'Duplicate';
          }

          handleSubmit(
            name,
            desc,
            origin === 'Duplicate' || origin === 'Rename' ? true : false,
            txt,
            {},
            industryCodeValue,
            { name: compName, code: response[1].data[0].customerId }
          );
          dispatch({
            type: 'SET_PROJECT_NAME',
            payload: {
              projectName: name,
              shortlisted: 0,
            },
          });
        } else {
          setLoading(false);
          setError(true);
          setisSubmitClicked(false);
        }
      } else {
        setLoading(false);
        setIsProjectExist();
        if (response[0].response?.data?.errorMessage?.code === '001003') {
          modifyInvalidCharacter(true);
        }
        setisSubmitClicked(false);
      }
    } catch (error) {}
  };

  const handleChangeProjectDescroiption = (value) => {
    if (value?.length < 301) {
      setDesc(value);
      origin === 'Rename' && value === item.PROJECT_DESCRIPTION ? renameStatus(false) : renameStatus(true);
    }
  };
  const handlechangeIndustryCode = (value) => {
    origin === 'Rename' && value === item.INDUSTRY_CODE ? renameStatus(false) : renameStatus(true);
    setIndustryCodeMandatory(false);
    setIndustryCodeValue(value);
  };
  const handlechangeCompanyName = (value) => {
    origin === 'Rename' && value === item.COMPANY_NAME ? renameStatus(false) : renameStatus(true);
    setCompanyNameMandatory(false);
    setCompanyName(value);
  };

  const handlechangeProjectName = (value) => {
    if (value.includes('\\')) {
      setInvalidCharacterBackslash(true);
    } else {
      setInvalidCharacterBackslash(false);
    }
    setError(false);
    setOnchangeProjectName(value);
    updateSpecialCharacterError(false);
    origin === 'Rename' && value === item.PROJECT_NAME ? renameStatus(false) : renameStatus(true);
    setNameMandatory(false);
    setNameLengthError(false);
    setName(value);
  };

  useEffect(() => {
    if (!(origin === 'Rename' || origin === 'Duplicate')) {
      if (name || desc || industryCodeValue || companyName) {
        var cacheData = {
          step: 'step1',
          project_data: {
            name: name,
            desc: desc,
            industryCodeValue: industryCodeValue,
            companyName: companyName,
          },
        };
        localStorage.setItem('cache_data', JSON.stringify(cacheData));
      }
    }
  }, [name, desc, industryCodeValue, companyName]);

  useEffect(() => {
    let cacheData = JSON.parse(localStorage.getItem('cache_data'));
    if (cacheData?.project_data) {
      setCompanyName(cacheData?.project_data?.companyName);
      setName(cacheData?.project_data?.name);
      setDesc(cacheData?.project_data?.desc);
      setIndustryCodeValue(cacheData?.project_data?.industryCodeValue);
    }
  }, []);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      PaperProps={{
        sx: {
          width: 624,
          maxHeight: 500,
        },
      }}
    >
      <div
        style={{
          flexDirection: 'column',
          display: 'flex',
          width: 624,
          height: 620,
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 30,
            paddingBottom: 14,
            alignItems: 'center',
          }}
        >
          <Grid container>
            <Grid item xs={10} md={10} style={{ paddingLeft: 30 }}>
              <CustomTypography
                text={
                  origin === 'Rename'
                    ? 'Rename a project'
                    : origin === 'Duplicate'
                    ? 'Duplicate project'
                    : 'Create a new project'
                }
                fontfamily='Calibre-SB'
                fontsize='24px'
                fontweight='500'
                lineheight='32px'
                fontcolor='#1F1E1E'
              />
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: 30,
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item xs={12} md={12} style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 32 }}>
              <Typography
                variant='body2'
                style={{
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '24px',
                  color: '#1a1a1a99',
                  fontsize: 14,
                  fontFamily: 'Calibre-R',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                {origin === 'Rename'
                  ? 'You can easily rename a project. Choose a relevant title and make sure the company name, industry and description are suitable.'
                  : origin === 'Duplicate'
                  ? 'Duplicating a project means that all data from the original project (including settings) get copied over to the new one. '
                  : 'Creating a project is easy. Choose a relevant title and add a short description so you can make sense of it later.'}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom:
                  error ||
                  specialCharacterError ||
                  invalidCharacter ||
                  nameMandotary ||
                  invalidCharacterBackslash ||
                  nameLengthError
                    ? 15
                    : 30,
              }}
            >
              <CustomTextField
                style={{
                  fontFamily: 'Calibre-R',
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '24px',
                }}
                id='name'
                label={'Project name*' + '\xa0'}
                type='text'
                maxLength={100}
                fullWidth
                inputPlaceholder={'Type your project name here'}
                error={error || specialCharacterError || nameMandotary || nameLengthError}
                value={name}
                onChange={(event) => {
                  handlechangeProjectName(event.target.value);
                }}
                variant='outlined'
              />
              {nameLengthError && (
                <CustomTypography
                  text={'Project name must contain maximum 100 characters'}
                  fontsize='16px'
                  fontweight='500'
                  lineheight='24px'
                  marginLeft={10}
                  marginTop={8}
                  fontcolor='#FF543E'
                />
              )}
              {(error || isProjectExist === onchangeProjectName) && (
                <CustomTypography
                  text={'Project name is already in use'}
                  fontsize='16px'
                  fontweight='500'
                  lineheight='24px'
                  marginLeft={10}
                  marginTop={8}
                  fontcolor='#FF543E'
                />
              )}
              {invalidCharacter && (
                <CustomTypography
                  text={'Please enter valid project name'}
                  fontsize='16px'
                  fontweight='500'
                  lineheight='24px'
                  marginLeft={10}
                  marginTop={8}
                  fontcolor='#FF543E'
                />
              )}
              {specialCharacterError && (
                <CustomTypography
                  text={'No special characters allowed'}
                  fontsize='16px'
                  fontweight='500'
                  lineheight='24px'
                  marginLeft={10}
                  marginTop={8}
                  fontcolor='#FF543E'
                />
              )}
              {!specialCharacterError && nameMandotary && (
                <CustomTypography
                  text={'Project name should not empty'}
                  fontsize='16px'
                  fontweight='500'
                  lineheight='24px'
                  marginLeft={10}
                  marginTop={8}
                  fontcolor='#FF543E'
                />
              )}
              {invalidCharacterBackslash && (
                <CustomTypography
                  text={'"\\" character is not allowed'}
                  fontsize='16px'
                  fontweight='500'
                  lineheight='24px'
                  marginLeft={10}
                  marginTop={8}
                  fontcolor='#FF543E'
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: companyNameMandotary ? 0 : 10,
              }}
            >
              <SearchComponent
                companyName={companyName}
                error={companyNameMandotary}
                handlechangeCompanyName={(e) => handlechangeCompanyName(e)}
              />
              {companyNameMandotary && (
                <CustomTypography
                  text={'Company name should not empty'}
                  fontsize='16px'
                  fontweight='500'
                  lineheight='24px'
                  marginLeft={10}
                  marginTop={'2px'}
                  fontcolor='#FF543E'
                />
              )}
            </Grid>
            <Grid item xs={12} md={12} style={{ paddingLeft: 30, paddingRight: 30, marginBottom: 20 }}>
              <DropDownComponent
                key={industryCodeValue}
                title={'Industry* ' + '\xa0'}
                value={industryCodeValue}
                error={industryCodeMandotary}
                data={IndustryCode}
                handleCallback={(value) => handlechangeIndustryCode(value)}
              />
              {industryCodeMandotary && (
                <CustomTypography
                  text={'Industry code should not empty'}
                  fontsize='16px'
                  fontweight='500'
                  lineheight='24px'
                  marginLeft={10}
                  marginTop={'-6px'}
                  fontcolor='#FF543E'
                />
              )}
            </Grid>
            <Grid item xs={12} md={12} style={{ paddingLeft: 30, paddingRight: 30 }}>
              <CustomTextField
                multiline={true}
                rows={3}
                maxRows={3}
                id='name'
                label={'Description' + '\xa0'}
                style={{
                  fontFamily: 'Calibre-R',
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '14px',
                  marginBottom: 10,
                }}
                type='text'
                fullWidth
                inputPlaceholder={'Add project description'}
                value={desc}
                maxLength={300}
                onChange={(event) => handleChangeProjectDescroiption(event.target.value)}
                variant='outlined'
              />
              <CustomTypography
                text={`${desc?.length} / 300`}
                fontsize='11px'
                fontweight='400'
                fontcolor='#b6b6b6'
                marginTop='45px'
                lineheight='16px'
                marginRight='10px'
                justifycontent='flex-end'
              />
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'absolute',
            bottom: !nameMandotary || industryCodeMandotary ? 9 : 20,
            width: '100%',
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: 30,
              }}
            >
              <Button
                className={
                  (origin === 'Rename' && !isRenamed) || onchangeProjectName === isProjectExist
                    ? classes.disablebuttonClass
                    : classes.buttonClass
                }
                disabled={(origin === 'Rename' && !isRenamed) || onchangeProjectName === isProjectExist}
                onClick={() =>
                  isSubmitClicked
                    ? null
                    : origin === 'Rename'
                    ? isRenamed && handleSubmitResponse()
                    : handleSubmitResponse()
                }
              >
                <CustomTypography
                  text={origin === 'Rename' ? 'Save' : origin === 'Duplicate' ? 'Duplicate' : 'Create'}
                  fontsize='16px'
                  fontfamily='Calibre-SB'
                  fontweight='500'
                  lineheight='16px'
                  fontcolor='white'
                />
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      {(loading || isDuplicateNameLoading) && <LoadingOverlayComponent open={loading || isDuplicateNameLoading} />}
      {loading && (
        <span
          style={{
            position: 'absolute',
            color: '#1a1a1a',
            bottom: '50%',
            left: '50%',
            transform: 'translate(-50%,275%)',
          }}
        >
          {origin === 'Rename'
            ? 'Renaming project...'
            : origin === 'Duplicate'
            ? 'Duplicating project...'
            : 'Creating project...'}
        </span>
      )}
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
    </Dialog>
  );
};
export default ProjectModal;
