import CurrentSiteImage from '../img/Current_Site.png';
import OffMarketImage from '../img/Off_Market.png';
import UnderOfferImage from '../img/Under_Offer.png';
import PropLongListImage from '../img/longlist.png';
import PropShortListImage from '../img/shortlist.png';
import TempImage from '../img/image.png';
import CurrencyFormatter from '../components/currency_formatter';

const generatePropertyObject = (hkeyProperty, hwgtkey, item, hkey) => {
  let heroObjArray = [];
  let heroImage = '';
  let tagImage = '';
  if (item?.TAG_PROPERTY == 'CURRENT_SITE') {
    tagImage = CurrentSiteImage;
  } else if (item?.TAG_PROPERTY == 'OFF_MARKET') {
    tagImage = OffMarketImage;
  } else if (item?.TAG_PROPERTY == 'UNDER_OFFER') {
    tagImage = UnderOfferImage;
  }
  if (item?.MEDIA?.length > 0) {
    heroObjArray = item.MEDIA?.filter((e) => e.PRIMARY_IMAGE_F == 'Y');
  }
  if (heroObjArray.length > 0) {
    if (!heroObjArray[0].MEDIA_PATH.toLowerCase().includes('.pdf')) {
      heroImage = '"' + heroObjArray[0].MEDIA_PATH + '"';
    } else {
      heroImage = TempImage;
    }
  } else {
    heroImage = TempImage;
  }
  let object = {
    type: 'Feature',
    properties: {
      PropertyKeyIds: {
        propertyId: hkeyProperty?.toString('hex').toUpperCase(),
        weightingId: hwgtkey?.toString('hex').toUpperCase(),
        projectId: hkey,
        LISTED: item?.LISTED,
        TAG_PROPERTY: item?.TAG_PROPERTY,
      },
      description: `
            <style>
            .container {
                min-width:120px;
                position: relative;
                font-family: Calibre-R;
            }
            .text-block_property { position: absolute;bottom: 5px;right: 10px;top: 10px;
                height: 40px;
                width: 40px;background-color: #FFFFFF;
                border-radius: 10px;
            }
            span-rank {
                color:#1a1a1a99;font-style:normal;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;left: 863px;right: 802px;top: 380px;
            }
            span-space {
                color:#1a1a1a99;font-style:normal;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;left: 863px;right: 802px;top: 409px;
            }
            span-floor {
                color:#1a1a1a99;font-style:normal;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;left: 863px;right: 802px;top: 438px;
            }
            span-rent {
                color:#1a1a1a99;font-style:normal;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;left: 863px;right: 802px;top: 467px;
            }
            span-date {
                color:#1a1a1a99;font-style:normal;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;left: 863px;right: 802px;top: 496px;
            }
            span-postal {
                color:#1a1a1a99;font-style:normal;font-weight:400;line-height:24px;font-size:14px;font-family:'Calibre-R';text-align=left;left: 863px;right: 802px;top: 300px;
            }
            span-address {
                color:#000000;font-weight:400;line-height:24px;font-size:20px;font-family:'Calibre-R';font-style:normal;text-align=left;left: 863px;right: 802px;top: 301px;
            }
            span-rrank {
                color:#000000;line-height:24px;font-size:12px;font-family:'Calibre-R';font-weight:500;float:right;font-style:normal;left: 979px;right: 797px;top: 380px;
            }
            span-rspace {
                color:#000000;line-height:24px;font-size:12px;font-family:'Calibre-R';font-weight:500;float:right;font-style:normal;left: 979px;right: 797px;top: 409px;
            }
            span-rfloor {
                color:#000000;line-height:24px;font-size:12px;font-family:'Calibre-R';font-weight:500;float:right;font-style:normal;left: 979px;right: 797px;top: 438px;
            }
            span-rrent {
                color:#000000;line-height:24px;font-size:12px;font-family:'Calibre-R';font-weight:500;float:right;font-style:normal;left: 979px;right: 797px;top: 467px;
            }
            span-rdate {
                color:#000000;line-height:24px;font-size:12px;font-family:'Calibre-R';font-weight:500;float:right;font-style:normal;left: 979px;right: 797px;top: 496px;
            }
            P {
                padding : 0;
                margin : 0;
                line-height : 22px;
            }
    </style>
   
    <div class="container">
<img src=${heroImage} style="width:100%;height:140px;border-top-right-radius:10px;border-top-left-radius:10px;">
    <div class="text-block_property">
            <div style="font-size:20px;font-family:'Calibre-R';
            text-align:center;font-style:normal;font-weight:400;padding-top:10px";>
            ${Math.round(item.SCORE) || ''}
            </div>
        </div>
    </div>
    <div style="padding-left:14px;padding-right:14px;margin-bottom:5px;">
    <p>
        <span-address>${
          item.TAG_PROPERTY
            ? item.ADDRESS_LINE1.length > 11
              ? item.ADDRESS_LINE1.substring(0, 11) + '...'
              : item.ADDRESS_LINE1
            : item.ADDRESS_LINE1.length > 18
            ? item.ADDRESS_LINE1.substring(0, 18) + '...'
            : item.ADDRESS_LINE1
        }</span-address>
        <img src=${
          item?.LISTED == 'Short' ? PropShortListImage : PropLongListImage
        } style="width:17.11px;height:16.36px;top:16%;bottom:15.9%"/>
        ${
          tagImage
            ? `<img src=${tagImage} style="height:16.36px;top:15.9%;bottom:15.9%" />`
            : ``
        }
    </p>
    <p>
      <span-postal>${item.POSTAL_CODE || ''}</span-postal>
    </p>
    <p><hr color='#e6ecea' size='1px'></p>
    <p>
       <span-rank>Rank</span-rank>
       <span-rrank>${item.RANK || ''}</span-rrank>
    </p>
    <p>
        <span-space>Space available (sq.ft.)</span-space>         
        <span-rspace>${CurrencyFormatter(
          item.AVAILABLE_SPACE_SQFT || ''
        )}</span-rspace>
    </p>
    <p>
        <span-floor>Number of spaces available</span-floor>  
        <span-rfloor>${item.AVAILABLE_FLOOR || ''}</span-rfloor>
    </p>
    <p>
        <span-rent>Rent from (£/sq.ft.)</span-rent>

        <span-rrent>${CurrencyFormatter(
          item?.AVERAGE_RENT.toFixed(2) || ''
        )}</span-rrent>
    </p>
    <p>
        <span-date>Available from</span-date>                
        <span-rdate>${item?.AVAILABLE_FROM || ''}</span-rdate>
    </p>
    </div>`,
    },
    geometry: {
      type: 'Point',
      coordinates: [item.LONGITUDE, item.LATITUDE],
    },
  };
  return object;
};
export default generatePropertyObject;
