import React from 'react';

function BenchMarkingIcon({
  color = '#003F2D',
  width = 20,
  height = 20,
  ...props
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 20 18'
      {...props}
    >
      <path
        fill={color}
        d='M17.465 0H2.388C1.204 0 .234.9.234 2v14c0 1.1.97 2 2.154 2h15.077c1.185 0 2.154-.9 2.154-2V2c0-1.1-.97-2-2.154-2zM6.695 14H4.543V7h2.154v7zm4.309 0H8.85V4h2.154v10zm4.307 0h-2.154v-4h2.154v4z'
      ></path>
    </svg>
  );
}

export default BenchMarkingIcon;
