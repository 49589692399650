import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import LineImage from '../img/line.png';
const TimeLineChartsComponents = ({ data = [], flag, tab }) => {
  const [addresLine, setAddresLine] = useState([]);
  const [scoreList, setScoreList] = useState([]);
  const [initMedianScore, setinitMedianScore] = useState(0);
  const [initItem, setInitItem] = useState();

  useEffect(() => {
    if (data) {
      let xLabel = [],
        medianData = [],
        transportData = [],
        esgData = [],
        buildingData = [],
        localamenityData = [],
        deliverabilityData = [];

      const getInitValue = (key) => {
        let initValue = 0;
        for (let val of data) {
          if (val[key]) {
            initValue = val[key];
            break;
          }
        }
        return initValue;
      };

      const initTr = getInitValue('Transport');
      const initEsg = getInitValue('ESG');
      const initBl = getInitValue('Building');
      const initLA = getInitValue('Location & Amenity');
      const initDR = getInitValue('Deliverability & Risk');
      setinitMedianScore(initTr + initEsg + initBl + initLA + initDR);
      setInitItem({
        Transport: initTr,
        ESG: initEsg,
        Building: initBl,
        'Location & Amenity': initLA,
        'Deliverability & Risk': initDR,
      });

      data.forEach((obj, index) => {
        xLabel.push(obj?.labelName);
        if (index === 0) {
          transportData.push(initTr);
          esgData.push(initEsg);
          buildingData.push(initBl);
          localamenityData.push(initLA);
          deliverabilityData.push(initDR);
        } else {
          obj['Transport'] = obj?.Transport || transportData[index - 1];
          obj['Building'] = obj?.Building || buildingData[index - 1];
          obj['Location & Amenity'] =
            obj['Location & Amenity'] || localamenityData[index - 1];
          obj['ESG'] = obj?.ESG || esgData[index - 1];
          obj['Deliverability & Risk'] =
            obj['Deliverability & Risk'] || deliverabilityData[index - 1];

          transportData.push(obj?.Transport || transportData[index - 1]);
          esgData.push(obj?.ESG || esgData[index - 1]);
          buildingData.push(obj?.Building || buildingData[index - 1]);
          localamenityData.push(
            obj['Location & Amenity'] || localamenityData[index - 1]
          );
          deliverabilityData.push(
            obj['Deliverability & Risk'] || deliverabilityData[index - 1]
          );

          obj['MedianScore'] = obj?.MedianScore || medianData[index - 1];
          medianData.push(
            Math.round(obj?.MedianScore) || medianData[index - 1]
          );
        }
      });
      setAddresLine(xLabel);
      var stackBarChartScoring = [
        {
          data: transportData,
          color: '#DBD99A',
          name: 'Transport',
        },
        {
          data: buildingData,
          color: '#3E7CA6',
          name: 'Building',
        },
        {
          data: localamenityData,
          color: '#A388BF',
          name: 'Location & Amenity',
        },
        {
          data: esgData,
          color: '#80BBAD',
          name: 'ESG',
        },
        {
          data: deliverabilityData,
          color: '#885073',
          name: 'Deliverability & Risk',
        },
      ];
      setScoreList(stackBarChartScoring);
    }
  }, [data]);
  const obj = {
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },
      stroke: {
        width: [2, 2, 2, 2, 2],
        curve: 'straight',
      },
      labels: addresLine,
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      grid: {
        show: true,
        position: 'back',
        strokeDashArray: 2,
        borderColor: 'rgba(203, 209, 211, 1)',
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 20,
        },
      },
      tooltip: {
        followCursor: true,
        shared: false,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let obj = data[dataPointIndex];
          const medianScore = !dataPointIndex
            ? initMedianScore
            : data[dataPointIndex].MedianScore;
          const score = `<div class="text-block">
          <span style="font-size:20px;font-family:'Calibre-R';text-align:center;font-style:normal;font-weight:400;height: 24px;left: 1674px; right: 206px;top: 308px;">
${Math.round(medianScore) || ''}
</span>
</div>`;
          return `<style>
 .root{ 
                       padding-right: 10px;
                       padding-left: 20px;
                       padding-bottom: 10px;
                       background-color: #FFFFFF;
                       width:310px;
                       }
                                          .top10container {
                                          position: relative;
                                          font-family: 'Calibre-R';
                                          word-wrap: break-word;
                                          padding-top:22px;
                                          }
                                          .text-block {
                                              position: absolute; bottom: 5px;right: 10px;top: 20px;width: 25px;height: 28px;
                                              weight: 40px;background-color: #DFE6E5;padding-left: 7px;padding-right: 7px;
                                              border-radius: 10px;text-align: center;
                                          }
                                          span-trans {
                                              color:#1a1a1a99;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;font-style:normal;left: 1457px;right: 210.94px;top: 375px;
                                          }
                                          span-loc {
                                              color:#1a1a1a99;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;font-style:normal;left: 1457px;right: 210.94px;top: 404px;
                                          }
                                          .b {
                                              word-wrap: break-word;
                                            }
                                          span-build {
                                              color:#1a1a1a99;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;font-style:normal;left: 1457px;right: 210.94px;top: 433px;
                                          }
                                          span-esg {
                                              color:#1a1a1a99;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;font-style:normal;left: 1457px;right: 210.94px;top: 462px;
                                          }
                                          span-del {
                                              color:#1a1a1a99;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;font-style:normal;left: 1457px;right: 210.94px;top: 490px;
                                          }
                                          span-rank {
                                              color:#1a1a1a99;font-style:normal;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;left: 1454px;right: 211px;top: 324px;
                                          }
                                          span-address {
                                              color:#000000;font-style:normal;font-weight:400;line-height:24px;font-size:20px;font-family:'Calibre-R';text-align=left;left: 1454px;right: 300px;top: 300px;word-wrap: break-word;
                                          }
                                          span-right {
                                              color:#000000;font-weight:500;font-style: normal;line-height:24px;font-size:12px;font-family:'Calibre-R';float:right;padding-right: 15px;
                                          }
                                          P {
                                              padding : 0;
                                              margin : 0;
                                              line-height : 22px;
                                          }
                                       </style>
                                       <div class="root">
                                          <div class="top10container">
                                          <div class="b">
                                          <span-address>${
                                            obj.tooltipLabelName
                                          }&nbsp;&nbsp;&nbsp;</span-address>
                                              </div>
                                            ${flag ? score : ''}
                                          </div>
                                          <p>
                                              <img src=${LineImage} style="width: 300px;height: 1px;left: 1454px;top: 362px;"/>
                                          </p>
                                          <p>
                                              <span-trans>Transport</span-trans>
                                              <span-right>${
                                                Math.round(
                                                  !dataPointIndex
                                                    ? initItem.Transport
                                                    : obj.Transport
                                                ) || 0
                                              }</span-right>
                                          </p>

                                          <p>
                                              <span-build>Building</span-left>
                                              <span-right>${
                                                Math.round(
                                                  !dataPointIndex
                                                    ? initItem.Building
                                                    : obj.Building
                                                ) || 0
                                              }</span-right>
                                          </p>
                                          <p>
                                          <span-loc>Location & Amenity</span-left>
                                          <span-right>${
                                            Math.round(
                                              !dataPointIndex
                                                ? initItem['Location & Amenity']
                                                : obj['Location & Amenity']
                                            ) || 0
                                          }</span-right>
                                      </p>
                                          <p>
                                              <span-esg>ESG</span-left>
                                              <span-right>${
                                                Math.round(
                                                  !dataPointIndex
                                                    ? initItem.ESG
                                                    : obj.ESG
                                                ) || 0
                                              }</span-right>
                                          </p>
                                          <p>
                                              <span-del>Deliverability & Risk</span-left>
                                              <span-right>${
                                                Math.round(
                                                  !dataPointIndex
                                                    ? initItem[
                                                        'Deliverability & Risk'
                                                      ]
                                                    : obj[
                                                        'Deliverability & Risk'
                                                      ]
                                                ) || 0
                                              }</span-right>
                                          </p>
                                      </div>`;
        },
      },
    },
  };
  return (
    <ReactApexChart
      options={obj.options}
      series={scoreList}
      type='line'
      height={tab ? 350 : 375}
    />
  );
};
export default TimeLineChartsComponents;
