import React from 'react';

function ReportsIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      fill='none'
      viewBox='0 0 18 18'
    >
      <path
        fill='#003F2D'
        d='M12.59.59C12.21.21 11.7 0 11.17 0H2C.9 0 .01.9.01 2L0 16c0 1.1.89 2 1.99 2H16c1.1 0 2-.9 2-2V6.83c0-.53-.21-1.04-.59-1.41L12.59.59zM5 14c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm0-4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm0-4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm6 0V1.5L16.5 7H12c-.55 0-1-.45-1-1z'
      ></path>
    </svg>
  );
}

export default ReportsIcon;
