import React from 'react';
import { Card, Grid } from '@material-ui/core';
import SwitchOnIcon from '../icons/switch_on';
import SwitchOffIcon from '../icons/switch_off';
const CustomeSwitchComponent = ({ value }) => {
  return (
    <>
      {value ? (
        <SwitchOnIcon style={{ cursor: 'pointer' }} />
      ) : (
        <SwitchOffIcon style={{ cursor: 'pointer' }} />
      )}
    </>
  );
};
export default CustomeSwitchComponent;
