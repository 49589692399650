import React from 'react';

function CurrentSiteIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='70'
      height='18'
      fill='none'
      viewBox='0 0 70 18'
    >
      <rect
        width='70'
        height='17'
        y='1'
        fill='#4DA6E4'
        fillOpacity='0.75'
        rx='8.5'
      ></rect>
      <path
        fill='#fff'
        d='M14.313 10.88c-.29.84-.89 1.39-1.9 1.39-1.37 0-2.28-1.16-2.28-2.86 0-1.71.91-2.86 2.28-2.86 1.01 0 1.61.54 1.9 1.37h.9c-.28-1.33-1.28-2.21-2.8-2.21-1.8 0-3.21 1.46-3.21 3.7s1.41 3.7 3.21 3.7c1.51 0 2.52-.88 2.8-2.23h-.9zm6.096-3.07h-.88v3.52c-.19.56-.69.96-1.3.96-.79 0-1.14-.5-1.14-1.22V7.81h-.88v3.41c0 1.12.63 1.89 1.81 1.89.66 0 1.21-.35 1.51-.76V13h.88V7.81zm4.143-.03c-.09-.05-.29-.08-.52-.08-.57 0-1.06.34-1.27.78v-.67h-.89V13h.89V9.51c.17-.61.65-.97 1.2-.97.21 0 .44.05.59.14v-.9zm3.66 0c-.09-.05-.29-.08-.52-.08-.57 0-1.06.34-1.27.78v-.67h-.89V13h.89V9.51c.17-.61.65-.97 1.2-.97.21 0 .44.05.59.14v-.9zm4.113 3.66c-.15.59-.63.91-1.28.91-.87 0-1.49-.68-1.51-1.78h3.68v-.32c0-1.5-.76-2.55-2.18-2.55-1.42 0-2.37 1.16-2.37 2.71 0 1.61.97 2.7 2.38 2.7 1.18 0 1.91-.68 2.11-1.67h-.83zm.01-1.54h-2.77c.12-.91.68-1.45 1.47-1.45.87 0 1.27.68 1.3 1.45zm1.952 3.1h.89V9.48c.18-.57.71-.97 1.31-.97.79 0 1.15.5 1.15 1.23V13h.89V9.59c0-1.13-.64-1.89-1.82-1.89-.68 0-1.24.35-1.53.76v-.65h-.89V13zm5.863-1.3c0 .96.59 1.41 1.43 1.41.33 0 .63-.07.83-.18v-.81c-.18.1-.44.2-.69.2-.43 0-.68-.22-.68-.72V8.55h1.25v-.74h-1.25V6.44h-.89v1.37h-.83v.74h.83v3.15zm5.428-.13c.16.97 1 1.54 2.13 1.54 1.11 0 1.93-.59 1.93-1.57 0-.72-.43-1.22-1.32-1.45l-1.01-.26c-.49-.13-.72-.29-.72-.65 0-.5.48-.74 1-.74.63 0 .99.26 1.13.73h.88c-.18-.91-.86-1.47-2.01-1.47-1.05 0-1.86.61-1.86 1.53 0 .8.52 1.19 1.26 1.37l1.02.26c.54.14.77.37.77.73 0 .53-.44.78-1.06.78-.61 0-1.07-.26-1.23-.8h-.91zm5.064-5.33c0 .31.25.56.56.56.31 0 .57-.25.57-.56a.57.57 0 00-.57-.57c-.31 0-.56.25-.56.57zm1.01 1.57h-.89V13h.89V7.81zm1.691 3.89c0 .96.59 1.41 1.43 1.41.33 0 .63-.07.83-.18v-.81c-.18.1-.44.2-.69.2-.43 0-.68-.22-.68-.72V8.55h1.25v-.74h-1.25V6.44h-.89v1.37h-.83v.74h.83v3.15zm6.603-.26c-.15.59-.63.91-1.28.91-.87 0-1.49-.68-1.51-1.78h3.68v-.32c0-1.5-.76-2.55-2.18-2.55-1.42 0-2.37 1.16-2.37 2.71 0 1.61.97 2.7 2.38 2.7 1.18 0 1.91-.68 2.11-1.67h-.83zm.01-1.54h-2.77c.12-.91.68-1.45 1.47-1.45.87 0 1.27.68 1.3 1.45z'
      ></path>
    </svg>
  );
}

export default CurrentSiteIcon;
