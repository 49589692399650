import React from "react";

function RightVectorIcon({ color = "#1A1A1A", fillOpacity, ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="12"
            fill="none"
            viewBox="0 0 8 12"
            {...props}
        >
            <path
                fill={color}
                fillOpacity={fillOpacity}
                d="M2 0L.59 1.41 5.17 6 .59 10.59 2 12l6-6-6-6z"
            >

            </path>
        </svg>
    );
}

export default RightVectorIcon;
