import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '../icons/close_icon';
import CustomTypography from '../utils/text';
import { useSelector, useDispatch } from 'react-redux';

import { Grid } from '@material-ui/core';
import InfoStatusIcon from '../icons/info_status_icon';

const AlertBar = ({
  open,
  setOpen,
  message = '',
  duration = null,
  name = '',
  date = '',
}) => {
  const isUserClosedAlertBar = useSelector(
    (state) => state.mainReducer.isUserClosedAlertBar
  );
  console.log(isUserClosedAlertBar, 'isUserClosedAlertBar');
  const [vertical, setVertical] = useState('top');
  const [horizontal, setHorizontal] = useState('center');
  const dispatch = useDispatch();

  const handleClose = (event) => {
    if (setOpen) setOpen(false);
    dispatch({ type: 'USER_CLOSED_ALERT_BAR', payload: true });
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open && !isUserClosedAlertBar}
      autoHideDuration={duration}
      // onClose={handleClose}
      style={{ height: 65 }}
    >
      <Alert
        icon={
          <InfoStatusIcon
            fontSize='inherit'
            color={'#8585E0'}
            width={18}
            height={18}
            style={{ alignItems: 'center' }}
          />
        }
        variant='outlined'
        elevation={6}
        style={{
          backgroundColor: ' #E7E7F9',
          border: '1px solid #8585E0',
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center',
        }}
        action={
          <CloseIcon
            color={'#012A2D'}
            style={{ marginRight: 20, cursor: 'pointer' }}
            onClick={() => {
              handleClose();
            }}
          />
        }
      >
        <Grid style={{ display: 'flex', flexDirection: 'row' }}>
          <CustomTypography
            text={name}
            fontsize='16px'
            fontweight='600'
            fontcolor='#000000'
            lineheight='24px'
            justifycontent='center'
            marginRight={5}
          />
          <CustomTypography
            text={message}
            fontsize='16px'
            fontweight='400'
            fontcolor='#000000'
            lineheight='24px'
            justifycontent='center'
          />
          <CustomTypography
            text={'\u00A0' + date}
            fontsize='16px'
            fontweight='600'
            fontcolor='#000000'
            lineheight='24px'
            justifycontent='center'
            marginRight={5}
          />
        </Grid>
      </Alert>
    </Snackbar>
  );
};

export default AlertBar;
