import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import CustomTypography from '../../../utils/text';
import Radio from '@mui/material/Radio';
import { useSelector, useDispatch } from 'react-redux';
import WarningMessagelert from '../../../components/warning_snackbar';
import TopBar from './TopBar';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ChartsContext from '../../../Providers/ChartsProvider';

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    left: 550,
    top: 50,
    margin: 10,
    height: 300,
  },
});

function PropertyChart(props) {
  const hkey = useSelector((state) => state.mainReducer.hkey);
  const hwgtkey = useSelector((state) => state.mainReducer.hwgtkey);
  const [topPropertiesList, updateTopProperties] = useState([]);
  const dispatch = useDispatch();
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const classes = useStyles();
  const [selectedOption, updateSelectedOption] = useState('Top score');
  const [selectedFilter, setSelectedFilter] = useState('None');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProp, setSelected] = useState('');
  const axiosPrivate = useAxiosPrivate();
  const {
    charts: { weightings },
  } = useContext(ChartsContext);

  const updateRedux = () => {
    dispatch({
      type: 'UPDATE_INDIVIDUAL_PROPERTY_DATA',
      payload: [],
    });
    dispatch({
      type: 'SHOW_INDIVIDUAL_PROPERTY',
      payload: false,
    });
  };

  const handleDropDownChange = (e) => {
    updateSelectedOption(e.target.value);
    updateRedux();
  };

  const displayBy = () => {
    const list = [
      'Top score',
      'Best Transport',
      'Best Location',
      'Best Building',
      'Best ESG',
      'Best Delivery & Risk',
      'Best Custom criteria',
      'Annual cost in',
      'Cost per sq.ft.',
    ];
    return (
      <FormControl>
        <Select
          classes={{
            paper: classes.dialog,
          }}
          value={selectedOption}
          onChange={handleDropDownChange}
          disableUnderline
          variant='standard'
          renderValue={(selected) => selected}
          sx={{
            fontWeight: 'bold',
            cursor: 'pointer',
            fontSize: '14px',
            lineHeight: '21px',
            fontFamily: 'Calibre-R',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {list.map((name) => (
            <MenuItem
              key={name}
              value={name}
              className={`dropdown_dashboard ${name === selectedOption ? 'dropdown_option' : ''}`}
            >
              <Radio
                checked={selectedOption === name}
                sx={{
                  '&.Mui-checked': {
                    color: '#003F2D',
                  },
                  '&.MuiRadio-colorPrimary': {
                    color: '#003F2D',
                  },
                }}
              />
              <CustomTypography
                text={name}
                fontsize='14px'
                fontweight='400'
                lineheight='21px'
                // fontfamily='Roboto, Helvetica, Arial, sans-serif'
                fontfamily='Calibre-R'
                fontcolor='#1A1A1A'
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const FilterBY = () => {
    const list = ['None', 'Shortlisted', 'Tagged properties'];
    return (
      <FormControl>
        <Select
          classes={{
            paper: classes.dialog,
          }}
          value={selectedFilter}
          onChange={(e) => setSelectedFilter(e.target.value)}
          disableUnderline
          variant='standard'
          renderValue={(selected) => selected}
          sx={{
            fontWeight: 'bold',
            cursor: 'pointer',
            fontSize: '14px',
            lineHeight: '21px',
            fontFamily: 'Calibre-R',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {list.map((name) => (
            <MenuItem
              key={name}
              value={name}
              className={`dropdown_dashboard ${name === selectedFilter ? 'dropdown_option' : ''}`}
            >
              <Radio
                checked={selectedFilter === name}
                sx={{
                  '&.Mui-checked': {
                    color: '#003F2D',
                  },
                  '&.MuiRadio-colorPrimary': {
                    color: '#003F2D',
                  },
                }}
              />
              <CustomTypography
                text={name}
                fontsize='14px'
                fontweight='400'
                lineheight='21px'
                fontfamily='Calibre-R'
                fontcolor='#1A1A1A'
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const NoData = () => {
    return (
      !isLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            marginTop: 50,
            marginBottom: 50,
          }}
        >
          <CustomTypography
            text='No properties to show'
            fontsize='20px'
            fontweight='400'
            lineheight='21px'
            fontcolor='#1A1A1A'
          />
        </div>
      )
    );
  };

  const lables = {
    'Top score': 'Score',
    'Best Transport': 'Transport',
    'Best Location': 'Location',
    'Best Building': 'Building',
    'Best ESG': 'ESG',
    'Best Delivery & Risk': 'Delivery & Risk',
    'Best Custom criteria': 'Custom criteria',
    'Annual cost in': 'Annual cost in',
    'Cost per sq.ft.': 'Cost per sq.ft.',
  };

  const TopProperties = () => {
    return (
      <Grid
        container
        style={{
          marginTop: '2%',
          paddingLeft: '2%',
          paddingRight: '2%',
        }}
      >
        <Grid
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: 10,
            alignItems: 'center',
          }}
        >
          <Grid xs={6}>
            <CustomTypography
              text={`Top Properties by ${lables[selectedOption]}`}
              fontsize='28px'
              fontweight='400'
              lineheight='40px'
              fontcolor='#1A1A1A'
            />
          </Grid>
          <Grid
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginRight: -5,
              marginTop: 5,
            }}
          >
            <Grid
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CustomTypography text={`Filter by:` + '\xa0'} fontweight='400' lineheight='21px' />
              <div
                style={{
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  fontSize: '14px',
                  lineHeight: '21px',
                  fontFamily: 'Calibre-R',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FilterBY />
              </div>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CustomTypography text={'\xa0' + `Display by:` + '\xa0'} fontweight='400' lineheight='21px' />
              <div
                style={{
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  fontSize: '14px',
                  lineHeight: '21px',
                  fontFamily: 'Calibre-R',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {displayBy()}&nbsp;
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginTop: '10px',
            // height: '290px',
          }}
        >
          {!!topPropertiesList.length ? (
            <TopBar
              data={topPropertiesList}
              height={'300px'}
              setSelected={setSelected}
              selectedProp={selectedProp}
              source={'top_property'}
              updateRedux={updateRedux}
              selectedOption={selectedOption}
            />
          ) : (
            <NoData />
          )}
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    updateRedux();
  }, []);

  useEffect(() => {
    if (!weightings) return;
    let orderBy = '',
      selectAnnualCost = '0 AS FI_ANNUAL_COST,',
      costPerSqft = '0 AS COST_PER_SQFT,';
    const defaultOrderBy = 'ORDER BY P.score DESC,ADDRESS_LINE1;';
    switch (selectedOption) {
      case 'Top score':
        orderBy = 'ORDER BY P.score DESC,ADDRESS_LINE1;';
        break;
      case 'Best Transport':
        orderBy = weightings.TRANSPORT ? 'ORDER BY P.TRANSPORT_SCORE DESC,ADDRESS_LINE1;' : defaultOrderBy;
        break;
      case 'Best Location':
        orderBy = weightings.LOCAL_AMENITY ? 'ORDER BY P.LOCAL_AMENITY_SCORE DESC,ADDRESS_LINE1;' : defaultOrderBy;
        break;
      case 'Best Building':
        orderBy = weightings.BUILDING ? 'ORDER BY P.BUILDING_SCORE DESC,ADDRESS_LINE1;' : defaultOrderBy;
        break;
      case 'Best ESG':
        orderBy = weightings.ESG ? 'ORDER BY P.ESG_SCORE DESC,ADDRESS_LINE1;' : defaultOrderBy;
        break;
      case 'Best Delivery & Risk':
        orderBy = weightings.DELIVERABILITY_RISK
          ? 'ORDER BY P.DELIVERABILITY_RISK_SCORE DESC,ADDRESS_LINE1;'
          : defaultOrderBy;
        break;
      case 'Best Custom criteria':
        orderBy = weightings.CUSTOM_WEIGHTAGE ? 'ORDER BY P.CUSTOM_SCORE DESC,ADDRESS_LINE1;' : defaultOrderBy;
        break;
      case 'Annual cost in':
        orderBy = 'AND FI_ANNUAL_COST > 0 ORDER BY FI_ANNUAL_COST DESC,ADDRESS_LINE1;';
        selectAnnualCost = 'NVL((NVL(PS.FLOOR_SPACE,0) * (P.FI_ALL_COST)),0) AS FI_ANNUAL_COST,';
        costPerSqft = '';
        break;
      case 'Cost per sq.ft.':
        orderBy = 'AND COST_PER_SQFT > 0 ORDER BY COST_PER_SQFT DESC,ADDRESS_LINE1;';
        selectAnnualCost = '';
        costPerSqft =
          'NVL(DECODE(NVL(PS.FLOOR_SPACE,0),0,0,(NVL(PS.FLOOR_SPACE,0) * P.FI_ALL_COST)/NVL(PS.FLOOR_SPACE,0)),0) AS COST_PER_SQFT,';
        break;
      default:
        break;
    }

    setIsLoading(true);
    props.updateLoader(true);
    updateTopProperties([]);
    let selectedClause =
      selectedFilter === 'Shortlisted'
        ? "AND (LISTED='Short' OR P.IS_SHORTLISTED=TRUE)"
        : "AND (LISTED='Long' OR P.IS_SHORTLISTED=TRUE OR P.TAG IS NOT NULL)";
    if (selectedFilter === 'Tagged properties') selectedClause = 'AND (P.TAG IS NOT NULL)';
    const reqObj = {
      hkey: hkey,
      hwgtkey: hwgtkey,
      selectAnnualCost: selectAnnualCost,
      costPerSqft: costPerSqft,
      selectedClause: selectedClause,
      orderBy: orderBy,
    };
    fetchTopProperties(reqObj);
  }, [selectedOption, selectedFilter, weightings]);

  const fetchTopProperties = async (reqObj) => {
    try {
      const response = await axiosPrivate.post(`/charts-ranking/top-properties`, reqObj);
      setIsLoading(false);
      props.updateLoader(false);
      if (response.status === 200 && response.data.length > 0) {
        if (selectedFilter === 'Shortlisted') {
          updateTopProperties(response.data.map((item, i) => ({ ...item, RANK: i + 1 })));
          return;
        }
        updateTopProperties(response.data);
      }
    } catch (error) {
      setWarningSnackbar({
        name: 'Property Info:',
        message: 'Unable to fetch property & scoring details',
        severity: 'error',
      });
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
        }}
        onClick={() => {
          dispatch({
            type: 'SHOW_INDIVIDUAL_PROPERTY',
            payload: false,
          });
          setSelected({});
        }}
      >
        <TopProperties />
        {warningSnackbar && (
          <WarningMessagelert
            open={true}
            setOpen={() => setWarningSnackbar(null)}
            severity={warningSnackbar.severity}
            name={warningSnackbar.name}
            message={warningSnackbar.message}
          />
        )}
      </Grid>
    </>
  );
}

export default PropertyChart;
