import { Buffer } from 'buffer';
const updateDataviewQuery = (updatedset, hkey, huserkey) => {
  console.log('updatedset,,,,,', updatedset);
  var updateObj = {
    HKEY_HUB_PROJECT: hkey,
    HKEY_HUB_USER: huserkey,
  };
  var PropertiesArray = [];
  if (updatedset.length > 0) {
    updatedset.map((item) => {
      const hubProjctKey = Buffer.from(item.HKEY_HUB_PROPERTY.data);
      var obj = {
        HKEY_HUB_PROPERTY: hubProjctKey.toString('hex').toUpperCase(),
      };
      Object.entries(item).forEach(([key, value]) => {
        if (key != 'id' && key != 'HKEY_HUB_PROPERTY') {
          if (typeof value === 'string' || value instanceof String) {
            if (value == '') {
              obj[key] = 0;
            } else {
              obj[key] = value;
            }
          } else {
            obj[key] = value || 0;
          }
        }
      });
      PropertiesArray.push(obj);
    });
  }
  updateObj['PROPERTIES'] = PropertiesArray;
  return updateObj;
};
export default updateDataviewQuery;
