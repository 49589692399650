import React, { useEffect, useState, useMemo } from 'react';
import Map from '../components/mapBox';
import { Button, Grid } from '@material-ui/core';
import PropertyScoreBar from '../components/bar';
import PropertCostScoreBar from '../components/cost_bar';
import { useSelector, useDispatch } from 'react-redux';
import CustomTypography from '../utils/text';
import LoadingOverlayComponent from '../components/lodding_component';
import { Buffer } from 'buffer';
import { useNavigate } from 'react-router-dom';
import DropDownComponent from '../components/dropdown';
import NoAnnualCostIcon from '../icons/no_annual_cost_icon';
import AddIcon from '../icons/add_data_icon';
import useStyles from '../styles/index';
import PropertyDetailsComponent from '../components/PropertyDetailsDisplay';
import SnackBar from '../components/SnackBar';
import WarningMessage from '../components/warning_snackbar';
import ValuePointsComponent from '../components/value_points_bar';
import useWindowDimensions from '../hooks/useWindowDimensions';
import RightDrawer from '../components/right_drawer';
import generatePropertyObject from '../utils/property_tooltip_view';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const MapView = () => {
  const { height, width } = useWindowDimensions();
  const classes = useStyles();
  const isMapFullScrren = useSelector((state) => state.mainReducer.isMapFullScrren);
  const hwgtkey = useSelector((state) => state.mainReducer.hwgtkey);
  const hkey = useSelector((state) => state.mainReducer.hkey);
  const hubWorkspace = useSelector((state) => state.mainReducer.hubWorkspace);
  const { hubUser, shortlistSelected } = useSelector((state) => state.mainReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [propertyList, setProperyList] = useState([]);
  const [costScoreList, setCostScoreList] = useState([]);
  const [valuePoundsList, setValuePoundsList] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isFullScreen, setIsFullscreen] = useState(false);
  const [openProperty, setOpenProperty] = useState(false);
  const [propertyObject, setPropertyObejct] = useState({});
  const [onHoverPropertyId, setOnHoverPropertyId] = useState();
  const [snackbar, setSnackbar] = useState(null);
  const [isShowCostinPounds, setIsShowCostinPounds] = useState(true);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [bounds, setBounds] = useState([]);
  const [shortlistedProperties, setShortlistedProperties] = useState([]);
  const [shortlistedCostProperties, setShortlistedCostProperties] = useState([]);
  const [shortlistedValueProperties, setShortlistedValueProperties] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [locations, setLocations] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const { assistantOpen } = useSelector((state) => state.mainReducer);
  const containerStyle = useMemo(
    () => ({
      width: width / 2 - 100,
      fontFamily: 'Calibre-R',
      marginLeft: '25px',
    }),
    [width]
  );

  useEffect(() => {
    localStorage.setItem('cache_data', JSON.stringify({}));
    isProjectOpened();
  }, []);

  useEffect(() => {
    setIsFullscreen(isMapFullScrren);
  }, [isMapFullScrren]);
  useEffect(() => {
    if (hwgtkey) {
      setisLoading(true);
      getMapData();
      getBarChartsData();
      dispatch({ type: 'CLEAR_SEARCH_OBJECT' });
    }
  }, [hwgtkey]);

  const isProjectOpened = async () => {
    try {
      const reqObj = {
        hkey: hkey,
        hubUser: hubUser,
        hubWorkspace: hubWorkspace,
      };
      await axiosPrivate.post(`/mapview/isproject-opened`, reqObj);
    } catch (error) {
      setWarningSnackbar({
        name: 'Error:',
        message: 'Unable to insert last project viewed details',
        severity: 'error',
      });
    }
  };

  const getMapData = async () => {
    try {
      const response = await axiosPrivate.get(`/mapview/map-data?hkey=${hkey}&hwgtkey=${hwgtkey}`);
      if (response.status === 200 && response.data.length) setCompleteData(setLocationsData(response.data));
      else
        setWarningSnackbar({
          name: 'No properties to display - ',
          message: 'Please widen your search criteria',
          severity: 'error',
        });
    } catch (error) {
      setWarningSnackbar({
        name: 'Property Info:',
        message: 'Unable to fetch property & scoring details',
        severity: 'error',
      });
    } finally {
      setisLoading(false);
    }
  };

  const generateSnackBar = (name) =>
    setWarningSnackbar({
      name,
      message: 'Unable to fetch property scoring details',
      severity: 'error',
    });

  const getBarChartsData = () => {
    fetchTopPropertyList();
    fetchTopCostScoreList();
    fetchTopValuePointsList();
  };

  const fetchTopPropertyList = async () => {
    try {
      const response = await axiosPrivate.get(`/mapview/top-properties?hkey=${hkey}&hwgtkey=${hwgtkey}`);
      if (response.status === 200) {
        setProperyList(response.data);
      }
    } catch (error) {
      generateSnackBar('Property top 10 score:');
    }
  };

  const fetchTopCostScoreList = async () => {
    try {
      const response = await axiosPrivate.get(`/mapview/topcost-score?hkey=${hkey}&hwgtkey=${hwgtkey}`);
      if (response.status === 200) {
        setCostScoreList(response.data);
      }
    } catch (error) {
      generateSnackBar('Property top 10 score:');
    }
  };

  const fetchTopValuePointsList = async () => {
    try {
      const response = await axiosPrivate.get(`/mapview/topValue-points?hkey=${hkey}&hwgtkey=${hwgtkey}`);
      if (response.status === 200) {
        setValuePoundsList(response.data);
      }
    } catch (error) {
      generateSnackBar('Property top 10 score:');
    }
  };

  const handleClick = () => {
    dispatch({
      type: 'SET_MAP_FULLSCREEN',
      payload: !isFullScreen,
    });
  };

  const handleClickDataView = () => {
    navigate('/map/tableview');
  };
  const handleClickProperty = (value, propertObject) => {
    setOpenProperty(value);
    setPropertyObejct(propertObject);
  };

  const handleOnClickProperty = (item) => {
    if (!item.LONGITUDE || !item.LATITUDE) return;
    const hkeyProperty = Buffer.from(item?.HKEY_HUB_PROPERTY?.data);
    const hwgtkey = Buffer.from(item?.HKEY_HUB_WEIGHTING?.data);
    const object = generatePropertyObject(hkeyProperty, hwgtkey, item, hkey);
    const type = {
      CURRENT_SITE: '#4DA6E4',
      OFF_MARKET: '#BAA6CF',
      UNDER_OFFER: '#F39DAD',
      Short: '#D2785A',
      Long: '#032842',
    };
    if (item.TAG_PROPERTY) setOnHoverPropertyId({ circleColor: type[item.TAG_PROPERTY], data: [object] });
    else setOnHoverPropertyId({ circleColor: type[item.LISTED], data: [object] });
  };
  const handleStarProperty = (building_name, shortOrLong) => {
    setisLoading(true);
    getMapData();
    getBarChartsData();
    setSnackbar({
      name: building_name,
      message:
        shortOrLong == 'Short'
          ? ` has been succesully removed from your shortlist`
          : `has been succesully added to your shortlist`,
      severity: 'success',
    });
  };

  const handleListedCallback = (objectVal) => {
    let data = [];
    if (objectVal.shortList === objectVal.longList) data = [...completeData];
    else if (objectVal.shortList) data = completeData.filter((el) => el.LISTED === 'Short');
    else data = completeData.filter((el) => el.LISTED === 'Long');
    const viewprops = !objectVal?.displayViewedProp ? 'No' : 'Yes';
    const gymprops = !objectVal?.gymprops ? 'No' : 'Yes';
    const selfprops = !objectVal?.selfprops ? 'No' : 'Yes';
    const cateringprops = !objectVal?.cateringprops ? 'No' : 'Yes';
    const terraceprops = !objectVal?.terraceprops ? 'No' : 'Yes';
    const onSiteShowerProps = !objectVal?.onSiteShowerProps ? 'No' : 'Yes';
    const brandingRightsProps = !objectVal?.brandingRightsProps ? 'No' : 'Yes';
    const raisedFloorsProps = !objectVal?.raisedFloorsProps ? 'No' : 'Yes';
    const lockersProps = !objectVal?.lockersProps ? 'No' : 'Yes';
    const singleLetBuildingProps = !objectVal?.singleLetBuildingProps ? 'No' : 'Yes';
    const allElectricBuildingProps = !objectVal?.allElectricBuildingProps ? 'No' : 'Yes';
    const naturallyVentilatedProps = !objectVal?.naturallyVentilatedProps ? 'No' : 'Yes';
    if (
      viewprops !== 'No' ||
      gymprops !== 'No' ||
      selfprops !== 'No' ||
      cateringprops !== 'No' ||
      terraceprops !== 'No' ||
      onSiteShowerProps !== 'No' ||
      brandingRightsProps !== 'No' ||
      raisedFloorsProps !== 'No' ||
      lockersProps !== 'No' ||
      singleLetBuildingProps !== 'No' ||
      allElectricBuildingProps !== 'No' ||
      naturallyVentilatedProps !== 'No'
    ) {
      let condition = '';
      if (objectVal?.displayViewedProp) {
        condition = ` VIEWED == "${viewprops}"`;
      }
      if (objectVal?.gymprops) {
        condition = condition
          ? condition + ` &&  BI_ON_SITE_DEDICATED_GYM == "${gymprops}"`
          : condition + `BI_ON_SITE_DEDICATED_GYM == "${gymprops}"`;
      }
      if (objectVal?.selfprops) {
        condition = condition
          ? condition + ` && BI_SELF_CONTAINED == "${selfprops}"`
          : condition + ` BI_SELF_CONTAINED == "${selfprops}"`;
      }
      if (objectVal?.cateringprops) {
        condition = condition
          ? condition + ` &&   BI_ON_SITE_CATERING_AVAILABLE == "${cateringprops}"`
          : condition + `BI_ON_SITE_CATERING_AVAILABLE == "${cateringprops}"`;
      }
      if (objectVal?.terraceprops) {
        condition = condition
          ? condition + ` &&   BI_TERRACES == "${terraceprops}"`
          : condition + `BI_TERRACES == "${terraceprops}"`;
      }
      if (objectVal?.onSiteShowerProps) {
        condition = condition
          ? condition + ` &&   BI_ON_SITE_SHOWER_FACILITIES == "${onSiteShowerProps}"`
          : condition + `BI_ON_SITE_SHOWER_FACILITIES == "${onSiteShowerProps}"`;
      }
      if (objectVal?.brandingRightsProps) {
        condition = condition
          ? condition + ` &&   BI_BRANDING_RIGHTS == "${brandingRightsProps}"`
          : condition + `BI_BRANDING_RIGHTS == "${brandingRightsProps}"`;
      }
      if (objectVal?.raisedFloorsProps) {
        condition = condition
          ? condition + ` &&   BI_RAISED_FLOORS == "${raisedFloorsProps}"`
          : condition + `BI_RAISED_FLOORS == "${raisedFloorsProps}"`;
      }
      if (objectVal?.lockersProps) {
        condition = condition
          ? condition + ` &&   BI_LOCKERS == "${lockersProps}"`
          : condition + `BI_LOCKERS == "${lockersProps}"`;
      }
      if (objectVal?.singleLetBuildingProp) {
        condition = condition
          ? condition + ` &&   BI_SINGLE_LET_BUILDING == "${singleLetBuildingProps}"`
          : condition + `BI_SINGLE_LET_BUILDING == "${singleLetBuildingProps}"`;
      }
      if (objectVal?.allElectricBuildingProps) {
        condition = condition
          ? condition + ` &&   ESG_ALL_ELECTRIC_BUILDING == "${allElectricBuildingProps}"`
          : condition + `ESG_ALL_ELECTRIC_BUILDING == "${allElectricBuildingProps}"`;
      }
      if (objectVal?.naturallyVentilatedProps) {
        condition = condition
          ? condition + ` &&   ESG_NATURALLY_VENTILATED == "${naturallyVentilatedProps}"`
          : condition + `ESG_NATURALLY_VENTILATED == "${naturallyVentilatedProps}"`;
      }
      /* eslint-disable */
      var cb = new Function(
        'o',
        'return ' + condition.replace(new RegExp(Object.keys(completeData[0]).join('|'), 'g'), 'o.$&')
      );
      data = data.filter(cb);
      if (!data.length) {
        setWarningSnackbar({
          name: 'No properties to display - ',
          message: 'Please widen your search criteria',
          severity: 'success',
        });
        setLocations([]);
        return;
      }
    }
    if (!!objectVal.epcRatingArray.length)
      data = data.filter((item) =>
        objectVal.epcRatingArray.find(
          (el) => item.ESG_EPC_RATING?.includes(el.title) || (!item.ESG_EPC_RATING && el.title === 'None')
        )
      );

    data = data.filter(
      (e) =>
        objectVal.totalScore[0] <= Math.round(e.SCORE) &&
        Math.round(e.SCORE) <= objectVal.totalScore[1] &&
        objectVal.transportScore[0] <= Math.round(e.TRANSPORT_SCORE) &&
        Math.round(e.TRANSPORT_SCORE) <= objectVal.transportScore[1] &&
        objectVal.buildingScore[0] <= Math.round(e.BUILDING_SCORE) &&
        Math.round(e.BUILDING_SCORE) <= objectVal.buildingScore[1] &&
        objectVal.locationScore[0] <= Math.round(e.LOCAL_AMENITY_SCORE) &&
        Math.round(e.LOCAL_AMENITY_SCORE) <= objectVal.locationScore[1] &&
        objectVal.esgScore[0] <= Math.round(e.ESG_SCORE) &&
        Math.round(e.ESG_SCORE) <= objectVal.esgScore[1] &&
        objectVal.deliverabilityScore[0] <= Math.round(e.DELIVERABILITY_RISK_SCORE) &&
        Math.round(e.DELIVERABILITY_RISK_SCORE) <= objectVal.deliverabilityScore[1] &&
        objectVal.custCriteriaScore[0] <= Math.round(e.CUSTOM_SCORE) &&
        Math.round(e.CUSTOM_SCORE) <= objectVal.custCriteriaScore[1]
    );
    setLocations([...data]);
    let coordinate = [0, 0];
    if (!data.length) return;
    const averageLONGITUDE = data.reduce((total, next) => total + next.LONGITUDE, 0) / data.length;
    const averageLATITUDE = data.reduce((total, next) => total + next.LATITUDE, 0) / data.length;
    coordinate = [averageLONGITUDE, averageLATITUDE];
    const max = (a, f) => a.reduce((m, x) => (m[f] > x[f] ? m : x));
    const min = (a, f) => a.reduce((m, x) => (m[f] < x[f] ? m : x));
    const maximunLongitude = max(data, 'LONGITUDE');
    const minimumLongitude = min(data, 'LONGITUDE');
    let cordArray = [];
    if (minimumLongitude) cordArray.push([minimumLongitude.LONGITUDE, minimumLongitude.LATITUDE]);
    if (maximunLongitude) cordArray.push([maximunLongitude.LONGITUDE, maximunLongitude.LATITUDE]);
    setBounds(cordArray, {
      padding: [15, 15, 15, 15],
    });
    dispatch({ type: 'SET_MAP_CENTER', payload: coordinate });
  };

  const setLocationsData = (data) =>
    data
      .filter((el) => el.LONGITUDE && el.LATITUDE && el.HKEY_HUB_PROPERTY && el.HKEY_HUB_WEIGHTING)
      .map((item) => {
        const hkeyProperty = Buffer.from(item.HKEY_HUB_PROPERTY.data);
        const hwgtkey = Buffer.from(item.HKEY_HUB_WEIGHTING.data);
        let object = { ...item, ...generatePropertyObject(hkeyProperty, hwgtkey, item, hkey) };
        if (item.TAG_PROPERTY)
          object = { ...object, properties: { ...object.properties, group: item.TAG_PROPERTY, priority: 2 } };
        else if (item.LISTED == 'Short')
          object = { ...object, properties: { ...object.properties, group: 'shortlisted', priority: 1 } };
        else object = { ...object, properties: { ...object.properties, group: 'longlisted', priority: 0 } };
        return { ...object };
      })
      .sort((a, b) => a.properties.priority - b.properties.priority);

  const returnChartView = (data) => {
    return (
      <>
        {data.length > 0 ? (
          <>
            {isShowCostinPounds ? (
              <PropertCostScoreBar
                data={shortlistSelected ? shortlistedCostProperties : costScoreList}
                handleCallback={handleOnClickProperty}
                handleMouseOnLeaveProperty={() => setOnHoverPropertyId('')}
              />
            ) : (
              <ValuePointsComponent
                data={shortlistSelected ? shortlistedValueProperties : valuePoundsList}
                handleCallback={handleOnClickProperty}
                handleMouseOnLeaveProperty={() => setOnHoverPropertyId('')}
              />
            )}
          </>
        ) : (
          <Grid
            style={{
              width: '100%',
              height: '40vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <NoAnnualCostIcon />
            <CustomTypography
              text='No financial data available'
              fontsize='25px'
              fontweight='400'
              lineheight='35px'
              fontcolor='#565656'
              marginTop='5px'
              marginLeft='10px'
            />
            <CustomTypography
              text='Activate this chart by manually adding this data.'
              fontsize='16px'
              fontweight='400'
              lineheight='24px'
              fontcolor='#565656'
              marginTop='5px'
              marginLeft='10px'
            />
            <Button
              onClick={() => handleClickDataView()}
              className={classes.addButton}
              style={{
                textTransform: 'inherit',
                paddingTop: 8,
              }}
            >
              <CustomTypography
                text='Add data'
                fontfamily='Calibre-M'
                fontsize='16px'
                lineheight='16px'
                fontweight='500'
                fontcolor={'white'}
              />
            </Button>
            <AddIcon
              style={{
                paddingRight: 15,
                cursor: 'pointer',
                marginTop: '0px',
                marginLeft: '10px',
              }}
              onClick={() => handleClickDataView()}
            />
          </Grid>
        )}
      </>
    );
  };

  useEffect(() => {
    if (!completeData.length) return;
    fetchShortListProperties();
    fetchShortListCostProperties();
    fetchShortListValueProperties();
  }, [completeData]);

  const fetchShortListProperties = async () => {
    try {
      const response = await axiosPrivate.get(`/mapview/shortlist-properties?hkey=${hkey}&hwgtkey=${hwgtkey}`);
      if (response.status === 200) {
        setShortlistedProperties(response.data);
      }
    } catch (error) {
      console.error('Error while fetching shortlist properties...', error);
    }
  };

  const fetchShortListCostProperties = async () => {
    try {
      const response = await axiosPrivate.get(`/mapview/shortlist-cost-properties?hkey=${hkey}&hwgtkey=${hwgtkey}`);
      if (response.status === 200) {
        setShortlistedCostProperties(response.data);
      }
    } catch (error) {
      console.error('Error while fetching shortlist cost properties...', error);
    }
  };

  const fetchShortListValueProperties = async () => {
    try {
      const response = await axiosPrivate.get(`/mapview/shortlist-value-properties?hkey=${hkey}&hwgtkey=${hwgtkey}`);
      if (response.status === 200) {
        setShortlistedValueProperties(response.data);
      }
    } catch (error) {
      console.error('Error while fetching shortlist value properties...', error);
    }
  };

  const NoData = () => (
    <Grid
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '40vh',
      }}
    >
      <NoAnnualCostIcon />
      <CustomTypography
        text='No data available'
        fontsize='25px'
        fontweight='400'
        lineheight='35px'
        fontcolor='#565656'
        marginTop='5px'
        marginLeft='10px'
      />
    </Grid>
  );

  return (
    <div style={{ display: 'flex', overFlow: 'hidden', height: '100vh' }}>
      <>
        <Grid item xs={isFullScreen ? 12 : 3} md={isFullScreen ? 12 : 3}>
          <Map
            propertiesData={locations}
            boundsArray={bounds}
            handleClick={handleClick}
            handleClickProperty={handleClickProperty}
            value={isFullScreen}
            handleListedCallback={handleListedCallback}
            handleOnHoverPropertyID={onHoverPropertyId}
            loading={isLoading}
            completeData={completeData}
          />
        </Grid>
        {!isFullScreen && (
          <RightDrawer
            width='45%'
            variant={!assistantOpen ? 'permanent' : 'temporary'}
            open={!assistantOpen}
            hideBackdrop={true}
          >
            <Grid style={containerStyle}>
              <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                <CustomTypography
                  text={` ${shortlistSelected ? 'Shortlisted properties' : 'Top 10'} by ${
                    shortlistSelected ? 'score' : 'scoring'
                  }`}
                  fontsize='24px'
                  fontweight='500'
                  lineheight='40px'
                  fontcolor='#1A1A1A'
                  marginTop='20px'
                />
                {!shortlistSelected &&
                  (!!propertyList.length ? (
                    <PropertyScoreBar
                      data={propertyList}
                      handleCallback={handleOnClickProperty}
                      handleMouseOnLeaveProperty={() => setOnHoverPropertyId('')}
                    />
                  ) : (
                    <NoData />
                  ))}
                {shortlistSelected &&
                  (!!shortlistedProperties.length ? (
                    <PropertyScoreBar
                      data={shortlistedProperties}
                      handleCallback={handleOnClickProperty}
                      handleMouseOnLeaveProperty={() => setOnHoverPropertyId('')}
                    />
                  ) : (
                    <NoData />
                  ))}
              </Grid>
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  margin: '0',
                }}
              >
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    margin: 0,
                    marginBottom: 30,
                  }}
                >
                  <Grid item xs={7} md={7} style={{ margin: '0' }}>
                    <CustomTypography
                      text={
                        isShowCostinPounds
                          ? `${shortlistSelected ? 'Shortlisted properties' : 'Top 10'} by annual cost`
                          : `${shortlistSelected ? 'Shortlisted properties' : 'Top 10'} by value points`
                      }
                      fontsize='24px'
                      fontweight='400'
                      lineheight='40px'
                      fontcolor='#1A1A1A'
                      marginTop='10px'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    md={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      paddingRight: 40,
                      flexDirection: 'row',
                      height: '60px',
                    }}
                  >
                    <CustomTypography
                      text={'Display by:' + '\xa0'}
                      fontsize='14px'
                      fontweight='400'
                      lineheight='20px'
                      fontcolor='#1A1A1A'
                      marginTop='5px'
                    />

                    <div style={{ minWidth: '170px' }}>
                      <DropDownComponent
                        isMapview={false}
                        outlined={true}
                        marginLeft='10px'
                        data={[
                          { id: 1, name: 'Annual cost in pounds' },
                          { id: 2, name: 'Value in points per pound' },
                        ]}
                        handleCallback={() => setIsShowCostinPounds((flag) => !flag)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid style={{ marginTop: '-40px' }}>
                  {isShowCostinPounds
                    ? returnChartView(shortlistSelected ? shortlistedCostProperties : costScoreList)
                    : returnChartView(shortlistSelected ? shortlistedValueProperties : valuePoundsList)}
                </Grid>
              </Grid>
            </Grid>
          </RightDrawer>
        )}
      </>
      {isLoading && <LoadingOverlayComponent open={isLoading} />}
      {openProperty && (
        <PropertyDetailsComponent
          open={openProperty}
          s_p_data={propertyObject}
          onClose={() => setOpenProperty(false)}
          handleStarProperty={handleStarProperty}
        />
      )}
      {snackbar && (
        <SnackBar
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          name={snackbar.name}
          message={snackbar.message}
        />
      )}
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
    </div>
  );
};
export default MapView;
