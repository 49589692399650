import React, { useMemo } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Chart from 'react-apexcharts';

function RightBottomBarChart({ scoreList = [], criteria = [], ht }) {
  const { height, width } = useWindowDimensions();
  const screenHeight = useMemo(() => {
    return height;
  }, [height]);
  const screenWidth = useMemo(() => {
    return width;
  }, [width]);
  const max = useMemo(() => {
    if (scoreList?.at(0)?.data?.at(0))
      return Math.round(Math.max(...scoreList[0].data));
    return 10;
  }, [scoreList]);
  return (
    <React.Fragment>
      <div style={{ display: 'flex', zIndex: 1, width: '300px' }}>
        <Chart
          type='bar'
          width={screenWidth / 3}
          height={ht ? ht : screenHeight / 2 - 120}
          stacktype='normal'
          series={scoreList}
          options={{
            chart: {
              type: 'bar',
              stacked: true,
              height: 350,
              events: {
                stacktype: 'normal',
                stacked: true,
              },
            },
            stroke: {
              width: [2, 2, 2, 2, 2],
              colors: ['#fff'],
              dashArray: 1,
            },
            plotOptions: {
              bar: {
                borderRadius: 0,
                horizontal: true,
                barHeight: '75%',
              },
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: criteria,
              type: 'category',
              max: Math.ceil(max / 10) * 10,
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
            yaxis: {
              labels: {
                style: {
                  fontSize: 12,
                },
                show: true,
                align: 'left',
              },
              axisTicks: {
                show: false,
              },
            },
            grid: {
              show: true,
              position: 'back',
              strokeDashArray: 2,
              borderColor: 'rgba(203, 209, 211, 1)',
              xaxis: {
                lines: {
                  show: true,
                },
              },
              yaxis: {
                lines: {
                  show: false,
                },
              },
              padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 20,
              },
            },
            tooltip: {
              // enabled: false,
              followCursor: true,
              shared: false,
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return (
                  '<div class="arrow_box-header">' +
                  '<span>' +
                  w.globals.labels[dataPointIndex] +
                  '</span>' +
                  '</div>' +
                  '<div class="arrow_box-body">' +
                  '<span>' +
                  'Score: ' +
                  ' &nbsp' +
                  '</span>' +
                  "<span class='arrow_box-text'>" +
                  Math.round(series[seriesIndex][dataPointIndex]) +
                  '</span>' +
                  '</div>'
                );
              },
            },
          }}
        />
      </div>
    </React.Fragment>
  );
}

export default RightBottomBarChart;
