import React, { useState, useMemo, useRef, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CustomTypography from '../utils/text';
import { Grid } from '@material-ui/core';
import PDFIcon from '../icons/pdf_icon';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontFamily: 'Calibre-R',
    fontSize: '14px',
    fontWeight: 400,
  },
}));

const ImageCardView = ({ object, handleOnClickCallback }) => {

  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);
  let isPdf = object.MEDIA_PATH.toLowerCase().includes('.pdf');
  const handleOnClickImage = (pathName) => {
    if (isPdf) {
      window.open(pathName, '_blank', 'noreferrer');
    } else {
      handleOnClickCallback(true);
    }
  };
  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);
  return (
    <Card
      ref={elementRef}
      sx={{
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
        '&:hover': { boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.24)' },
      }}
      style={{ minHeight: 166, borderRadius: 10, cursor: 'pointer' }}
    >
      <CardContent style={{ height: 166, padding: 0 }}>
        <LightTooltip
          title={
            isPdf
              ? object.MEDIA_NAME
              : object.MEDIA_CAPTION || object.MEDIA_NAME
          }
        >
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
            }}
            onClick={() => handleOnClickImage(object.MEDIA_PATH)}
          >
            <Grid
              style={{
                display: 'flex',
                height: '82%',
                backgroundColor: '#F5F7F7',
              }}
            >
              {isPdf ? (
                <Grid
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <PDFIcon />
                </Grid>
              ) : (
                <img
                  style={{ width: '-webkit-fill-available' }}
                  src={`${object.MEDIA_PATH}?w=248&fit=crop&auto=format`}
                  srcSet={`${object.MEDIA_PATH}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={object.MEDIA_NAME}
                  loading='lazy'
                />
              )}
            </Grid>
            <Grid
              style={{
                display: 'flex',
                height: '18%',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: 10,
              }}
            >
              <CustomTypography
                text={
                  isPdf
                    ? object.MEDIA_NAME.length > width / 5
                      ? object.MEDIA_NAME.substring(0, width / 5) + '...'
                      : object.MEDIA_NAME
                    :
                    (object.MEDIA_CAPTION?.length > width / 5
                      ? object?.MEDIA_CAPTION?.substring(0, width / 5) + '...'
                      : object?.MEDIA_CAPTION) ||
                    (object.MEDIA_NAME?.length > width / 5
                      ? object.MEDIA_NAME?.substring(0, width / 5) + '...'
                      : object.MEDIA_NAME)
                }
                fontsize='10px'
                fontweight='400'
                fontcolor='#1A1A1A'
                lineheight='24px'
                justifycontent='center'
              />
            </Grid>
          </Grid>
        </LightTooltip>
      </CardContent>
    </Card>
  );
};
export default ImageCardView;
