import { useMemo, useRef, useState } from 'react';
import * as d3 from 'd3';
import Divider from '@mui/material/Divider';
import './benchmarking.css';
import { Grid } from '@material-ui/core';

// tick length
const TICK_LENGTH = 0;

export const AxisBottom = ({ xScale, pixelsPerTick }) => {
  const range = xScale.range();

  const ticks = useMemo(() => {
    const width = range[1] - range[0];
    const numberOfTicksTarget = Math.floor(width / pixelsPerTick);

    return xScale.ticks(numberOfTicksTarget).map((value) => ({
      value,
      xOffset: xScale(value),
    }));
  }, [xScale]);

  return (
    <>
      {/* Main horizontal line */}
      <path
        // d={['M', range[0], 0, 'L', range[1], 0].join(' ')}
        d={['M', 10, 0, 'L', 10, 0].join(' ')}
        fill='none'
        stroke='currentColor'
      />

      {/* Ticks and labels */}
      {ticks.map(({ value, xOffset }) => (
        <g key={value} transform={`translate(${xOffset}, 0)`}>
          <line y2={TICK_LENGTH} stroke='currentColor' />
          <text
            key={value}
            style={{
              fontSize: '12px',
              fontFamily: 'Calibre-R',
              textAnchor: 'middle',
              transform: 'translateY(20px)',
            }}
          >
            {value}
          </text>
        </g>
      ))}
    </>
  );
};

export const DensityChart = ({
  width,
  height,
  data,
  bottom,
  color,
  stats,
  type,
  pivot,
  maxVal,
  transparent = false,
  showMarket,
}) => {
  const MARGIN = { top: 0, right: 30, bottom, left: 50 };
  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;

  const ref1 = useRef();
  const tipRef = useRef();
  const xScale = useMemo(() => {
    // const max = Math.max(...data);
    return d3
      .scaleLinear()
      .domain([0, maxVal])
      .range([10, boundsWidth - 10]);
  }, [data, width]);

  // Compute kernel density estimation
  const density = useMemo(() => {
    const kde = kernelDensityEstimator(kernelEpanechnikov(3), xScale.ticks(30));
    return kde(data);
  }, [xScale]);

  const yScale = useMemo(() => {
    const max = Math.max(...density.map((d) => d[1]));
    return d3.scaleLinear().range([boundsHeight, 0]).domain([0, max]);
  }, [data, height]);

  const path = useMemo(() => {
    const lineGenerator = d3
      .line()
      .x((d) => xScale(d[0]))
      .y((d) => yScale(d[1]))
      .curve(d3.curveBasis);
    return lineGenerator(density);
  }, [density]);

  return (
    <div className='tooltip'>
      <div
        className='tooltiptext'
        ref={tipRef}
        style={{
          top:
            type === 'Deliverability & Risk'
              ? '-95px'
              : type === 'ESG'
              ? '-40PX'
              : 5,
          width: showMarket ? 250 : 200,
        }}
      >
        {!showMarket ? (
          <>
            <div style={{ fontSize: '20px' }}>{type}</div>
            <Divider style={{ marginTop: 7, marginBottom: 7 }} />
            <Grid
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '3px',
              }}
            >
              <span style={{ color: 'rgb(86, 86, 86)' }}>Mean: </span>
              <span>{stats.mean}</span>
            </Grid>
            <Grid
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '3px',
              }}
            >
              <span style={{ color: 'rgb(86, 86, 86)' }}>Median: </span>
              <span>{stats.median}</span>
            </Grid>
            <Grid
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '3px',
              }}
            >
              <span style={{ color: 'rgb(86, 86, 86)' }}>Mode: </span>
              <span>{stats.mode}</span>
            </Grid>
            <Grid
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '3px',
              }}
            >
              <span style={{ color: 'rgb(86, 86, 86)' }}>Variance: </span>
              <span>{stats.variance}</span>
            </Grid>
            <Grid
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '3px',
              }}
            >
              <span style={{ color: 'rgb(86, 86, 86)' }}>
                Standard deviation:{' '}
              </span>
              <span>{stats.deviation}</span>
            </Grid>
          </>
        ) : (
          <>
            <div style={{ fontSize: '20px' }}>{type}</div>
            <Divider style={{ marginTop: 7, marginBottom: 7 }} />
            <Grid
              md={12}
              style={{
                width: '100%',
                display: 'flex',
                marginBottom: '3px',
              }}
            >
              <Grid md={6}>&nbsp; </Grid>
              <Grid md={3} style={{ color: 'rgb(86, 86, 86)' }}>
                Property
              </Grid>
              <Grid md={3} style={{ color: 'rgb(86, 86, 86)' }}>
                Market
              </Grid>
            </Grid>
            <Grid
              md={12}
              style={{
                width: '100%',
                display: 'flex',
                marginBottom: '3px',
              }}
            >
              <Grid md={6} style={{ color: 'rgb(86, 86, 86)' }}>
                Mean:{' '}
              </Grid>
              <Grid md={3}>
                {Array.isArray(stats) ? stats.at(0)?.mean : '-'}
              </Grid>
              <Grid md={3}>
                {Array.isArray(stats) ? stats.at(1)?.mean : '-'}
              </Grid>
            </Grid>
            <Grid
              md={12}
              style={{
                width: '100%',
                display: 'flex',
                marginBottom: '3px',
              }}
            >
              <Grid md={6} style={{ color: 'rgb(86, 86, 86)' }}>
                Median:{' '}
              </Grid>
              <Grid md={3}>
                {Array.isArray(stats) ? stats.at(0)?.median : '-'}
              </Grid>
              <Grid md={3}>
                {Array.isArray(stats) ? stats.at(1)?.median : '-'}
              </Grid>
            </Grid>
            <Grid
              md={12}
              style={{
                width: '100%',
                display: 'flex',
                marginBottom: '3px',
              }}
            >
              <Grid md={6} style={{ color: 'rgb(86, 86, 86)' }}>
                Mode:{' '}
              </Grid>
              <Grid md={3}>
                {Array.isArray(stats) ? stats.at(0)?.mode : '-'}
              </Grid>
              <Grid md={3}>
                {Array.isArray(stats) ? stats.at(1)?.mode : '-'}
              </Grid>
            </Grid>
            <Grid
              md={12}
              style={{
                width: '100%',
                display: 'flex',
                marginBottom: '3px',
              }}
            >
              <Grid md={6} style={{ color: 'rgb(86, 86, 86)' }}>
                Variance:{' '}
              </Grid>
              <Grid md={3}>
                {Array.isArray(stats) ? stats.at(0)?.variance : '-'}
              </Grid>
              <Grid md={3}>
                {Array.isArray(stats) ? stats.at(1)?.variance : '-'}
              </Grid>
            </Grid>
            <Grid
              md={12}
              style={{
                width: '100%',
                display: 'flex',
                marginBottom: '3px',
              }}
            >
              <Grid md={6} style={{ color: 'rgb(86, 86, 86)' }}>
                Standard deviation:{' '}
              </Grid>
              <Grid md={3}>
                {Array.isArray(stats) ? stats.at(0)?.deviation : '-'}
              </Grid>
              <Grid md={3}>
                {Array.isArray(stats) ? stats.at(1)?.deviation : '-'}
              </Grid>
            </Grid>
          </>
        )}
      </div>

      <svg
        width={width}
        height={height}
        title='Circle'
        ref={ref1}
        onMouseEnter={(e) => {}}
        onMouseLeave={() => {}}
        // onMouseMove={(e) => {
        //   var x = e.clientX,
        //     y = e.clientY;
        //   tipRef.current.style.top = y - 280 + 'px';
        //   tipRef.current.style.left = x - 250 + 'px';
        // }}
      >
        <g>
          width={boundsWidth}
          height={boundsHeight}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(',')})`}
          <path
            d={path}
            fill={!transparent ? color : 'transparent'}
            opacity={0.9}
            stroke={color}
            strokeWidth={0.5}
            strokeLinejoin='round'
          />
          {/* X axis, use an additional translation to appear at the bottom */}
          <g transform={`translate(0, ${boundsHeight})`}>
            <AxisBottom xScale={xScale} pixelsPerTick={40} />
          </g>
        </g>
      </svg>
    </div>
  );
};

// Function to compute density
function kernelDensityEstimator(kernel, X) {
  return function (V) {
    return X.map((x) => [x, d3.mean(V, (v) => kernel(x - v))]);
  };
}

function kernelEpanechnikov(k) {
  return function (v) {
    return Math.abs((v /= k)) <= 1 ? (0.75 * (1 - v * v)) / k : 0;
  };
}

export const DensityChartWithAxis = ({
  width = 500,
  height = 75,
  data,
  bottom = 0,
  color = '#9a6fb0',
  stats = {},
  type = '',
  pivot = 2,
  maxVal,
  transparent,
  tab,
  showMarket,
}) => (
  <DensityChart
    width={width}
    height={tab === 1 ? 70 : 75}
    data={data}
    bottom={bottom}
    color={color}
    stats={stats}
    type={type}
    pivot={pivot}
    maxVal={maxVal}
    transparent={transparent}
    showMarket={showMarket}
  />
);
