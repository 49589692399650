import React, { useState, useMemo, useRef, useEffect } from 'react';
import StarOpenIcon from '../../../icons/star_close_icon';
import StarCloseIcon from '../../../icons/star_open_icon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CustomTypography from '../../../utils/text';
import { Grid } from '@material-ui/core';
import CircleIcon from '../../../icons/circle_icon';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import GetIndividualPropertyData from './getIndividualPropertyData';
import CurrencyFormatter from '../../../components/currency_formatter';
import CurrentSiteIcon from '../../../icons/current_site';
import OffMarketIcon from '../../../icons/off_market';
import UnderOfferIcon from '../../../icons/under_offer';

export default function TopBar({
  data = [],
  height = '100px',
  source = '',
  selectedProp = '',
  setSelected,
  updateRedux,
  selectedOption = '',
}) {
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);
  let modifyCondition = ['Annual cost in', 'Cost per sq.ft.'].includes(
    selectedOption
  );

  let max = 0;
  let maxAnnualCost = useMemo(() => {
    if (selectedOption == 'Annual cost in') {
      const annual_cost_list = data.map((key) => {
        return key.FI_ANNUAL_COST;
      });
      return Math.max(...annual_cost_list);
    }
    return 0;
  }, [data]);

  let maxCostPerSqFt = useMemo(() => {
    if (selectedOption === 'Cost per sq.ft.') {
      const cost_per_sqft_list = data.map((key) => {
        return key.COST_PER_SQFT;
      });
      return Math.max(...cost_per_sqft_list);
    }
    return 0;
  }, [data]);
  switch (selectedOption) {
    case 'Annual cost in':
      max = maxAnnualCost;
      break;
    case 'Cost per sq.ft.':
      max = maxCostPerSqFt;
      break;
    default:
      break;
  }
  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);
  const screenWidth = window.innerWidth;
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontFamily: 'Calibre-R',
      fontSize: '14px',
      fontWeight: 400,
    },
  }));
  const [disableOnClickTooltip, setDisableOnClickTooltip] = useState(true);
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      minWidth: 270,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.24)',
      borderRadius: '10px',
    },
  }));

  const barPercentage = (value, index) => {
    var multiplierValue = parseInt(width) / 100;
    return (
      <svg height='35' width={width} id={index} style={{ marginTop: 20 }}>
        {' '}
        {drawVerticalLines()}
        {/* transport */}
        <rect
          center
          width={value.TRANSPORT_SCORE * multiplierValue}
          height='15'
          style={{ fill: '#DBD99A' }}
          x={0}
          y={0}
        />
        {/* Building */}
        <rect
          width={value.BUILDING_SCORE * multiplierValue}
          height='15'
          style={{ fill: '#3E7CA6' }}
          x={value.TRANSPORT_SCORE * multiplierValue}
          y={0}
        />
        {/* Location & Amenity */}
        <rect
          width={value.LOCAL_AMENITY_SCORE * multiplierValue}
          height='15'
          style={{ fill: '#A388BF' }}
          x={
            value.TRANSPORT_SCORE * multiplierValue +
            value.BUILDING_SCORE * multiplierValue
          }
          y={0}
        />
        {/* ESG */}
        <rect
          width={value.ESG_SCORE * multiplierValue}
          height='15'
          style={{ fill: '#80BBAD' }}
          x={
            value.TRANSPORT_SCORE * multiplierValue +
            value.LOCAL_AMENITY_SCORE * multiplierValue +
            value.BUILDING_SCORE * multiplierValue
          }
          y={0}
        />
        {/* Deliverability & Risk */}
        <rect
          width={value.DELIVERABILITY_RISK_SCORE * multiplierValue}
          height='15'
          style={{ fill: '#885073' }}
          x={
            value.TRANSPORT_SCORE * multiplierValue +
            value.LOCAL_AMENITY_SCORE * multiplierValue +
            value.BUILDING_SCORE * multiplierValue +
            value.ESG_SCORE * multiplierValue
          }
          y={0}
        />
        <rect
          width={value.CUSTOM_SCORE * multiplierValue}
          height='15'
          style={{ fill: '#96b0bf' }}
          x={
            value.TRANSPORT_SCORE * multiplierValue +
            value.LOCAL_AMENITY_SCORE * multiplierValue +
            value.BUILDING_SCORE * multiplierValue +
            value.ESG_SCORE * multiplierValue +
            value.DELIVERABILITY_RISK_SCORE * multiplierValue
          }
          y={0}
        />
      </svg>
    );
  };

  const drawVerticalLines = () => {
    const array = [
      0,
      width / 5,
      (width / 5) * 2,
      (width / 5) * 3,
      (width / 5) * 4,
      width,
    ];
    return array.map((i) => {
      return (
        <>
          <line
            x1={i}
            x2={i}
            y1='0'
            y2='2'
            stroke='#767676'
            strokeWidth='0.5'
          ></line>
          <line
            x1={i}
            x2={i}
            y1='4'
            y2='6'
            stroke='#767676'
            strokeWidth='0.5'
          ></line>
          <line
            x1={i}
            x2={i}
            y1='8'
            y2='10'
            stroke='#767676'
            strokeWidth='0.5'
          ></line>
          <line
            x1={i}
            x2={i}
            y1='12'
            y2='14'
            stroke='#767676'
            strokeWidth='0.5'
          ></line>
          <line
            x1={i}
            x2={i}
            y1='16'
            y2='18'
            stroke='#767676'
            strokeWidth='0.5'
          ></line>
          <line
            x1={i}
            x2={i}
            y1='20'
            y2='22'
            stroke='#767676'
            strokeWidth='0.5'
          ></line>
          <line
            x1={i}
            x2={i}
            y1='24'
            y2='26'
            stroke='#767676'
            strokeWidth='0.5'
          ></line>
          <line
            x1={i}
            x2={i}
            y1='28'
            y2='30'
            stroke='#767676'
            strokeWidth='0.5'
          ></line>
          <line
            x1={i}
            x2={i}
            y1='32'
            y2='34'
            stroke='#767676'
            strokeWidth='0.5'
          ></line>
        </>
      );
    });
  };

  const ModifiedbarPercentage = (value, index) => {
    let rwidth = 0;
    switch (selectedOption) {
      case 'Annual cost in':
        rwidth = (value.FI_ANNUAL_COST / maxAnnualCost) * width;
        break;
      case 'Cost per sq.ft.':
        rwidth = (value.COST_PER_SQFT / maxCostPerSqFt) * width;
        break;
      default:
        break;
    }

    return (
      <svg height='35' id={index} style={{ marginTop: 20, width: '100%' }}>
        {drawVerticalLines()}
        <rect
          center
          width={rwidth}
          height='15'
          style={{ fill: 'rgb(64,85,124)' }}
          x={0}
          y={0}
        />
      </svg>
    );
  };

  const circleData = [
    {
      name: 'Transport',
      color: '#DBD99A',
    },
    {
      name: 'Building',
      color: '#3E7CA6',
    },
    {
      name: 'Location & Amenity',
      color: '#A388BF',
    },
    {
      name: 'ESG',
      color: '#80BBAD',
    },
    {
      name: 'Deliverability & Risk',
      color: '#885073',
    },
    {
      name: 'Custom Criteria',
      color: '#96b0bf',
    },
  ];

  const toolTipContent = (content) => {
    return (
      <div
        style={{
          paddingRight: '12px',
          paddingLeft: '12px',
          paddingBottom: '10px',
          backgroundColor: '#FFFFFF',
        }}
      >
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Grid
            item
            xs={10}
            style={{
              fontSize: '20px',
              fontWeight: '400',
              fontColor: '#000000',
              lineHeight: '24px',
              fontFamily: 'Calibre-R',
            }}
          >
            {content.ADDRESS_LINE1.length > 20
              ? content.ADDRESS_LINE1.substring(0, 20) + '...'
              : content.ADDRESS_LINE1}
            <div
              style={{
                fontFamily: 'Calibre-R',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0px',
                color: 'rgba(26, 26, 26, 0.6)',
              }}
            >
              Rank {content.RANK}{' '}
            </div>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                backgroundColor: '#DFE6E5',
                width: '40px',
                height: '40px',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Calibre-R',
                fontSize: '20px',
                fontWeight: 400,
                lineHeight: '24px',
              }}
            >
              {Math.round(content.SCORE)}
            </div>
          </Grid>
        </Grid>
        <Divider
          style={{
            marginTop: 10,
            marginBottom: 5,
          }}
        />
        {modifyCondition ? (
          <>
            {selectedOption === 'Annual cost in' && (
              <Grid container className='chart_tooltip'>
                <Grid item xs={8} className='chart_tooltip_item'>
                  Total annual cost
                </Grid>
                <Grid item xs={4} className='chart_tooltip_item_score'>
                  £{' '}
                  {CurrencyFormatter(Math.round(content.FI_ANNUAL_COST ?? ''))}{' '}
                </Grid>
              </Grid>
            )}
            {selectedOption === 'Cost per sq.ft.' && (
              <Grid container className='chart_tooltip'>
                <Grid item xs={8} className='chart_tooltip_item'>
                  Cost per sq.ft.{' '}
                </Grid>
                <Grid item xs={4} className='chart_tooltip_item_score'>
                  £ {CurrencyFormatter(Math.round(content.COST_PER_SQFT ?? ''))}{' '}
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <>
            <Grid container className='chart_tooltip'>
              <Grid item xs={8} className='chart_tooltip_item'>
                Transport{' '}
              </Grid>
              <Grid item xs={4} className='chart_tooltip_item_score'>
                {Math.round(content.TRANSPORT_SCORE)}{' '}
              </Grid>
            </Grid>
            <Grid container className='chart_tooltip'>
              <Grid item xs={8} className='chart_tooltip_item'>
                Location & Amenity{' '}
              </Grid>
              <Grid item xs={4} className='chart_tooltip_item_score'>
                {Math.round(content.LOCAL_AMENITY_SCORE)}
              </Grid>
            </Grid>
            <Grid container className='chart_tooltip'>
              <Grid item xs={8} className='chart_tooltip_item'>
                Building{' '}
              </Grid>
              <Grid item xs={4} className='chart_tooltip_item_score'>
                {Math.round(content.BUILDING_SCORE)}
              </Grid>
            </Grid>
            <Grid container className='chart_tooltip'>
              <Grid item xs={8} className='chart_tooltip_item'>
                ESG{' '}
              </Grid>
              <Grid item xs={4} className='chart_tooltip_item_score'>
                {Math.round(content.ESG_SCORE)}
              </Grid>
            </Grid>
            <Grid container className='chart_tooltip'>
              <Grid item xs={8} className='chart_tooltip_item'>
                Deliverability & Risk{' '}
              </Grid>
              <Grid item xs={4} className='chart_tooltip_item_score'>
                {Math.round(content.DELIVERABILITY_RISK_SCORE)}
              </Grid>
            </Grid>
            <Grid container className='chart_tooltip'>
              <Grid item xs={8} className='chart_tooltip_item'>
                Custom Criteria{' '}
              </Grid>
              <Grid item xs={4} className='chart_tooltip_item_score'>
                {Math.round(content.CUSTOM_SCORE)}
              </Grid>
            </Grid>
          </>
        )}
      </div>
    );
  };

  const disbaleSelection = () => {
    setSelected({});
    updateRedux();
  };

  const getLabelValues = (num) => {
    const x = num.toString().split('.')[0];
    let result = 0;
    switch (true) {
      case x.length > 12:
        result = (num / 1000000000000).toFixed(1) + 'T';
        break;
      case x.length > 9:
        result = (num / 1000000000).toFixed(1) + 'B';
        break;
      case x.length > 6:
        result = (num / 1000000).toFixed(1) + 'M';
        break;
      case x.length > 3:
        result = (num / 1000).toFixed(1) + 'K';
        break;
      case x.length === 1:
        result = num.toFixed(0);
        break;

      default:
        result = parseInt(num);
        break;
    }
    return ' ' + result;
  };

  const renderContent = (value, index) => {
    let tag;
    switch (value.TAG_PROPERTY) {
      case 'OFF_MARKET':
        tag = <OffMarketIcon />;
        break;
      case 'UNDER_OFFER':
        tag = <UnderOfferIcon />;
        break;
      case 'CURRENT_SITE':
        tag = <CurrentSiteIcon />;
        break;
      default:
        tag = '';
        break;
    }

    return (
      <Grid
        onClick={(e) => {
          selectedProp?.PROPERTY_CODE === value?.PROPERTY_CODE
            ? disbaleSelection()
            : setSelected(value);
          e.stopPropagation();
          setDisableOnClickTooltip(true);
        }}
        onMouseMoveCapture={() => setDisableOnClickTooltip(false)}
      >
        {index === 10 && <hr className='dashed-2' />}
        <ListItem
          key={value.PROPERTY_CODE}
          sx={{
            maxHeight: 35,
            backgroundColor:
              selectedProp.PROPERTY_CODE == value.PROPERTY_CODE
                ? ' #E9F1F0'
                : 'none',
            border:
              selectedProp.PROPERTY_CODE == value.PROPERTY_CODE
                ? '0.1px solid #003F2D; '
                : 'none',
            cursor: 'pointer',
          }}
        >
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '35%',
              alignItems: 'center',
            }}
          >
            <ListItemText
              id={index}
              secondaryTypographyProps={{
                style: {
                  fontFamily: 'Calibre-R',
                  fontSize: '10px',
                  fontWeight: 400,
                  display: 'flex',
                  justifyContent: 'flex-start',
                  color: '#1A1A1A',
                  opacity: 0.5,
                },
              }}
              secondary={`${value.RANK}`}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'absolute',
                left: '30px',
              }}
            >
              <div>
                {value.LISTED == 'Short' ? <StarCloseIcon /> : <StarOpenIcon />}
                &nbsp;&nbsp;
              </div>
              <LightTooltip
                enterNextDelay={1000}
                enterDelay={1000}
                title={value.ADDRESS_LINE1}
                placement='right'
                followCursor
              >
                <ListItemText
                  id={index}
                  secondaryTypographyProps={{
                    style: {
                      fontFamily: 'Calibre-R',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '35px',
                      color: '#1A1A1A',
                    },
                  }}
                  secondary={`${
                    screenWidth > 1700
                      ? value.ADDRESS_LINE1.length > 28
                        ? value.ADDRESS_LINE1.substring(0, 27) + '...'
                        : value.ADDRESS_LINE1
                      : screenWidth > 1500
                      ? value.ADDRESS_LINE1.length > 24
                        ? value.ADDRESS_LINE1.substring(0, 23) + '...'
                        : value.ADDRESS_LINE1
                      : value.ADDRESS_LINE1.length > 18
                      ? value.ADDRESS_LINE1.substring(0, 17) + '...'
                      : value.ADDRESS_LINE1
                  }`}
                />
              </LightTooltip>
              <span
                style={{
                  display: 'inline-block',
                  marginTop: 2,
                  marginLeft: '2px',
                }}
              >
                {tag}
              </span>
            </div>
          </Grid>
          <Grid ref={elementRef} style={{ width: '65%' }}>
            <HtmlTooltip
              enterNextDelay={1000}
              enterDelay={1000}
              followCursor
              title={toolTipContent(value)}
              disableHoverListener={disableOnClickTooltip}
            >
              {modifyCondition
                ? ModifiedbarPercentage(value, index)
                : barPercentage(value, index)}
            </HtmlTooltip>
          </Grid>
        </ListItem>
      </Grid>
    );
  };
  const innerWidth = useMemo(() => window.innerWidth, []);
  return (
    <div>
      <List
        sx={{
          width: '100%',
          height:
            data.length > 10
              ? innerWidth >= 1920
                ? '58vh'
                : '65vh'
              : data.length * 5 + 2 + 'vh',
          overflowY: 'auto',
          overflowX: 'hidden',
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 1,
        }}
        id={source}
      >
        {data.map((value, index) => {
          // if (index == 1) {
          // if (shortlist) {
          //   if (value.LISTED == 'Short') {
          //     return renderContent(value, shorListCount++);
          //   }
          // }
          return renderContent(value, index);
          // }
        })}
      </List>
      <div>
        {modifyCondition ? (
          // this is for 'Annual cost in', 'Cost per sq.ft.' (x axis)
          <Grid style={{ width: '100%' }}>
            <Grid style={{ width: '35%' }}></Grid>
            <Grid
              style={{
                width: width,
                display: 'flex',
                flexDirection: 'row',
                paddingLeft: '35%',
              }}
            >
              <Grid style={{ minWidth: width / 5 }}>
                <CustomTypography
                  fontfamily={'Calibre-M'}
                  text={'0'}
                  fontsize='12px'
                  fontweight='550'
                  fontcolor={'#1a1a1a99'}
                />
              </Grid>
              <Grid style={{ minWidth: width / 5 }}>
                <CustomTypography
                  fontfamily={'Calibre-M'}
                  text={getLabelValues(max * 0.2)}
                  fontsize='12px'
                  fontweight='550'
                  fontcolor={'#1a1a1a99'}
                />
              </Grid>
              <Grid style={{ minWidth: width / 5 }}>
                <CustomTypography
                  fontfamily={'Calibre-M'}
                  text={getLabelValues(max * 0.4)}
                  fontsize='12px'
                  fontweight='550'
                  fontcolor={'#1a1a1a99'}
                />
              </Grid>
              <Grid style={{ minWidth: width / 5 }}>
                <CustomTypography
                  fontfamily={'Calibre-M'}
                  text={getLabelValues(max * 0.6)}
                  fontsize='12px'
                  fontweight='550'
                  fontcolor={'#1a1a1a99'}
                />
              </Grid>
              <Grid style={{ minWidth: width / 5 }}>
                <CustomTypography
                  fontfamily={'Calibre-M'}
                  text={getLabelValues(max * 0.8)}
                  fontsize='12px'
                  fontweight='550'
                  fontcolor={'#1a1a1a99'}
                />
              </Grid>
              <Grid style={{ minWidth: width / 5 }}>
                <CustomTypography
                  fontfamily={'Calibre-M'}
                  text={getLabelValues(max)}
                  fontsize='12px'
                  fontweight='550'
                  fontcolor={'#1a1a1a99'}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid style={{ width: '100%' }}>
            <Grid style={{ width: '35%' }}></Grid>
            <Grid
              style={{
                width: width,
                display: 'flex',
                flexDirection: 'row',
                paddingLeft: '35%',
              }}
            >
              <Grid style={{ minWidth: width / 5 }}>
                <CustomTypography
                  fontfamily={'Calibre-M'}
                  text={'0'}
                  fontsize='12px'
                  fontweight='550'
                  fontcolor={'#1a1a1a99'}
                />
              </Grid>
              <Grid style={{ minWidth: width / 5 }}>
                <CustomTypography
                  fontfamily={'Calibre-M'}
                  text={'20'}
                  fontsize='12px'
                  fontweight='550'
                  fontcolor={'#1a1a1a99'}
                />
              </Grid>
              <Grid style={{ minWidth: width / 5 }}>
                <CustomTypography
                  fontfamily={'Calibre-M'}
                  text={'40'}
                  fontsize='12px'
                  fontweight='550'
                  fontcolor={'#1a1a1a99'}
                />
              </Grid>
              <Grid style={{ minWidth: width / 5 }}>
                <CustomTypography
                  fontfamily={'Calibre-M'}
                  text={'60'}
                  fontsize='12px'
                  fontweight='550'
                  fontcolor={'#1a1a1a99'}
                />
              </Grid>
              <Grid style={{ minWidth: width / 5 }}>
                <CustomTypography
                  fontfamily={'Calibre-M'}
                  text={'80'}
                  fontsize='12px'
                  fontweight='550'
                  fontcolor={'#1a1a1a99'}
                />
              </Grid>
              <Grid style={{ minWidth: width / 5 }}>
                <CustomTypography
                  fontfamily={'Calibre-M'}
                  text={'100'}
                  fontsize='12px'
                  fontweight='550'
                  fontcolor={'#1a1a1a99'}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>

      <Grid
        item
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: 10,
        }}
      >
        {source !== 'client_property' &&
          circleData.map((itemObj, ind) => {
            return (
              <Grid
                key={ind}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 8,
                }}
              >
                <Grid>
                  <CircleIcon color={itemObj.color} />
                </Grid>
                <Grid style={{ marginRight: 10 }}>
                  <CustomTypography
                    text={itemObj.name}
                    fontsize='10px'
                    fontweight='400'
                    lineheight='21px'
                    fontcolor={'#00000'}
                    marginLeft={8}
                  />
                </Grid>
              </Grid>
            );
          })}
      </Grid>
      {(source === 'top_property' || source === 'client_property') &&
        selectedProp &&
        selectedProp.PROPERTY_CODE &&
        selectedProp.PROPERTY_CODE.length > 0 && (
          <GetIndividualPropertyData selectedProp={selectedProp} />
        )}
    </div>
  );
}
