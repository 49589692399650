import React from 'react';

function UnderOfferIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='70'
      height='18'
      fill='none'
      viewBox='0 0 70 18'
    >
      <rect width='70' height='17' y='1' fill='#F39DAD' rx='8.5'></rect>
      <path
        fill='#fff'
        d='M16.097 5.82h-.92v4.22c0 1.53-.75 2.24-1.9 2.24-1.16 0-1.9-.71-1.9-2.24V5.82h-.92v4.33c0 1.98 1.19 2.96 2.82 2.96s2.82-.98 2.82-2.95V5.82zm1.41 7.18h.89V9.48c.18-.57.71-.97 1.31-.97.79 0 1.15.5 1.15 1.23V13h.89V9.59c0-1.13-.64-1.89-1.82-1.89-.68 0-1.24.35-1.53.76v-.65h-.89V13zm9.053-1.54c-.27.53-.78.85-1.33.85-1 0-1.59-.81-1.59-1.91 0-1.1.59-1.91 1.59-1.91.55 0 1.06.33 1.33.86v2.11zm0 1.54h.89V5.39h-.89v3.02c-.26-.37-.82-.71-1.46-.71-1.53 0-2.34 1.24-2.34 2.7 0 1.47.81 2.71 2.34 2.71.64 0 1.2-.34 1.46-.71v.6zm5.639-1.56c-.15.59-.63.91-1.28.91-.87 0-1.49-.68-1.51-1.78h3.68v-.32c0-1.5-.76-2.55-2.18-2.55-1.42 0-2.37 1.16-2.37 2.71 0 1.61.97 2.7 2.38 2.7 1.18 0 1.91-.68 2.11-1.67h-.83zm.01-1.54h-2.77c.12-.91.68-1.45 1.47-1.45.87 0 1.27.68 1.3 1.45zm4.631-2.12c-.09-.05-.29-.08-.52-.08-.57 0-1.06.34-1.27.78v-.67h-.89V13h.89V9.51c.17-.61.65-.97 1.2-.97.21 0 .44.05.59.14v-.9zm5.488-.08c-1.43 0-2.39 1.11-2.39 2.7 0 1.6.96 2.71 2.39 2.71 1.41 0 2.38-1.11 2.38-2.71 0-1.59-.97-2.7-2.38-2.7zm0 .76c.93 0 1.49.81 1.49 1.94 0 1.14-.56 1.94-1.49 1.94-.94 0-1.51-.8-1.51-1.94 0-1.13.57-1.94 1.51-1.94zm9.116-3.05c-.2-.09-.4-.12-.68-.12-.91 0-1.59.61-1.59 1.65v.87h-2.18v-.73c0-.62.34-.96.85-.96.19 0 .38.05.55.15v-.79c-.19-.09-.4-.12-.68-.12-.91 0-1.59.61-1.59 1.65v.8h-.88v.74h.88V13h.87V8.55h2.18V13h.87V8.55h1.18v-.74h-1.18v-.8c0-.62.34-.96.85-.96.19 0 .38.05.55.15v-.79zm3.887 6.03c-.15.59-.63.91-1.28.91-.87 0-1.49-.68-1.51-1.78h3.68v-.32c0-1.5-.76-2.55-2.18-2.55-1.42 0-2.37 1.16-2.37 2.71 0 1.61.97 2.7 2.38 2.7 1.18 0 1.91-.68 2.11-1.67h-.83zm.01-1.54h-2.77c.12-.91.68-1.45 1.47-1.45.87 0 1.27.68 1.3 1.45zm4.632-2.12c-.09-.05-.29-.08-.52-.08-.57 0-1.06.34-1.27.78v-.67h-.89V13h.89V9.51c.17-.61.65-.97 1.2-.97.21 0 .44.05.59.14v-.9z'
      ></path>
    </svg>
  );
}

export default UnderOfferIcon;
