import React from "react";

function NoAnnualCostIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="193"
      height="103"
      fill="none"
      viewBox="0 0 193 103"
    >
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M13.56 78.859H18.706V84.005H13.56z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M23.547 78.859H28.693V84.005H23.547z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M33.533 78.859H38.679V84.005H33.533z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M43.52 78.859H48.666000000000004V84.005H43.52z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M53.508 78.859H58.654V84.005H53.508z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M63.494 78.859H68.64V84.005H63.494z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M73.481 78.859H78.627V84.005H73.481z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M83.468 78.859H88.614V84.005H83.468z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M93.455 78.859H98.601V84.005H93.455z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M103.442 78.859H108.588V84.005H103.442z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M113.429 78.859H118.575V84.005H113.429z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M123.416 78.859H128.56199999999998V84.005H123.416z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M133.402 78.859H138.54799999999997V84.005H133.402z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M143.39 78.859H148.53599999999997V84.005H143.39z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M153.377 78.859H158.523V84.005H153.377z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M163.363 78.859H168.509V84.005H163.363z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M173.351 78.859H178.49699999999999V84.005H173.351z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M183.337 78.859H188.48299999999998V84.005H183.337z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M23.547 68.872H28.693V74.018H23.547z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M43.52 68.872H48.666000000000004V74.018H43.52z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M53.508 68.872H58.654V74.018H53.508z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M63.494 68.872H68.64V74.018H63.494z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M73.481 68.872H78.627V74.018H73.481z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M83.468 68.872H88.614V74.018H83.468z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M93.455 68.872H98.601V74.018H93.455z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M103.442 68.872H108.588V74.018H103.442z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M113.429 68.872H118.575V74.018H113.429z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M123.416 68.872H128.56199999999998V74.018H123.416z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M143.39 68.872H148.53599999999997V74.018H143.39z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M153.377 68.872H158.523V74.018H153.377z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M173.351 68.872H178.49699999999999V74.018H173.351z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M13.56 58.885H18.706V64.03099999999999H13.56z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M33.533 58.885H38.679V64.03099999999999H33.533z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M43.52 58.885H48.666000000000004V64.03099999999999H43.52z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M53.508 58.885H58.654V64.03099999999999H53.508z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M63.494 58.885H68.64V64.03099999999999H63.494z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M73.481 58.885H78.627V64.03099999999999H73.481z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M83.468 58.885H88.614V64.03099999999999H83.468z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M93.455 58.885H98.601V64.03099999999999H93.455z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M103.442 58.885H108.588V64.03099999999999H103.442z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M113.429 58.885H118.575V64.03099999999999H113.429z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M123.416 58.885H128.56199999999998V64.03099999999999H123.416z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M133.402 58.885H138.54799999999997V64.03099999999999H133.402z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M143.39 58.885H148.53599999999997V64.03099999999999H143.39z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M153.377 58.885H158.523V64.03099999999999H153.377z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M163.363 58.885H168.509V64.03099999999999H163.363z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M173.351 58.885H178.49699999999999V64.03099999999999H173.351z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M14.328 49.666H17.936999999999998V53.275H14.328z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M23.547 48.898H28.693V54.044000000000004H23.547z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M33.533 48.898H38.679V54.044000000000004H33.533z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M63.494 48.898H68.64V54.044000000000004H63.494z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M73.481 48.898H78.627V54.044000000000004H73.481z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M83.468 48.898H88.614V54.044000000000004H83.468z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M93.455 48.898H98.601V54.044000000000004H93.455z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M103.442 48.898H108.588V54.044000000000004H103.442z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M113.429 48.898H118.575V54.044000000000004H113.429z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M123.416 48.898H128.56199999999998V54.044000000000004H123.416z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M143.39 48.898H148.53599999999997V54.044000000000004H143.39z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M163.363 48.898H168.509V54.044000000000004H163.363z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M174.119 49.666H177.728V53.275H174.119z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M164.132 39.679H167.741V43.288000000000004H164.132z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M154.145 49.666H157.75400000000002V53.275H154.145z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M134.171 49.666H137.78V53.275H134.171z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M24.315 39.679H27.924V43.288000000000004H24.315z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M34.302 29.693H37.911V33.302H34.302z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M44.289 39.679H47.898V43.288000000000004H44.289z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M44.289 49.666H47.898V53.275H44.289z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M184.105 49.666H187.714V53.275H184.105z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M33.533 38.911H38.679V44.057H33.533z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M63.494 38.911H68.64V44.057H63.494z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M73.481 38.911H78.627V44.057H73.481z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M83.468 38.911H88.614V44.057H83.468z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M93.455 38.911H98.601V44.057H93.455z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M103.442 38.911H108.588V44.057H103.442z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M113.429 38.911H118.575V44.057H113.429z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M123.416 38.911H128.56199999999998V44.057H123.416z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M143.39 38.911H148.53599999999997V44.057H143.39z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M153.377 38.911H158.523V44.057H153.377z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M43.52 28.924H48.666000000000004V34.07H43.52z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M73.481 28.924H78.627V34.07H73.481z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M83.468 28.924H88.614V34.07H83.468z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M93.455 28.924H98.601V34.07H93.455z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M103.442 28.924H108.588V34.07H103.442z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M113.429 28.924H118.575V34.07H113.429z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M133.402 28.924H138.54799999999997V34.07H133.402z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M53.508 18.938H58.654V24.084H53.508z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M63.494 18.938H68.64V24.084H63.494z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M83.468 18.938H88.614V24.084H83.468z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M113.429 18.938H118.575V24.084H113.429z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M133.402 18.938H138.54799999999997V24.084H133.402z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M144.926 10.487H146.999V12.56H144.926z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M144.926 30.461H146.999V32.534H144.926z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M154.913 20.474H156.98600000000002V22.547H154.913z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M154.913 30.461H156.98600000000002V32.534H154.913z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M164.9 30.461H166.973V32.534H164.9z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M164.9 10.487H166.973V12.56H164.9z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M124.952 10.487H127.02499999999999V12.56H124.952z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M94.991 20.474H97.064V22.547H94.991z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M124.952 20.474H127.02499999999999V22.547H124.952z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M124.952 30.461H127.02499999999999V32.534H124.952z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M25.083 10.487H27.156V12.56H25.083z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M25.083 20.474H27.156V22.547H25.083z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M25.083 30.461H27.156V32.534H25.083z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M45.057 20.474H47.13V22.547H45.057z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M55.044 50.435H57.117V52.508H55.044z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M15.097 20.474H17.169999999999998V22.547H15.097z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M65.031 10.487H67.104V12.56H65.031z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M15.097 30.461H17.169999999999998V32.534H15.097z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M15.097 40.448H17.169999999999998V42.521H15.097z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M5.109 60.421H7.182V62.494H5.109z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M5.109 70.408H7.182V72.481H5.109z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M5.109 80.395H7.182V82.46799999999999H5.109z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M5.109 50.435H7.182V52.508H5.109z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M5.109 40.448H7.182V42.521H5.109z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M174.887 20.474H176.96V22.547H174.887z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M174.887 40.448H176.96V42.521H174.887z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M184.874 40.448H186.947V42.521H184.874z"
      ></path>
      <path
        stroke="#1A1A1A"
        strokeOpacity="0.12"
        d="M184.874 60.421H186.947V62.494H184.874z"
      ></path>
      <path
        fill="#C0D4CB"
        d="M96.028 16.901H143.40200000000002V64.275H96.028z"
        opacity="0.5"
      ></path>
      <path
        fill="#DFE6E5"
        d="M38.411 0H109.47200000000001V71.061H38.411z"
        opacity="0.5"
      ></path>
      <path stroke="#CBD1D3" d="M0 87.63L192.056 87.63"></path>
      <path
        fill="#fff"
        stroke="#CBD1D3"
        d="M61.19 32.189H131.251V102.25H61.19z"
      ></path>
      <path
        fill="#003F2D"
        fillRule="evenodd"
        d="M100.445 50.898h-15.51a6.75 6.75 0 00-6.75 6.75v22.57a6.75 6.75 0 006.75 6.75h22.57a6.75 6.75 0 006.75-6.75v-15.51h-1.5v15.51a5.25 5.25 0 01-5.25 5.25h-22.57a5.25 5.25 0 01-5.25-5.25v-22.57c0-2.9 2.35-5.25 5.25-5.25h15.51v-1.5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillOpacity="0.08"
        fillRule="evenodd"
        d="M100.445 50.898h-15.51a6.75 6.75 0 00-6.75 6.75v22.57a6.75 6.75 0 006.75 6.75h22.57a6.75 6.75 0 006.75-6.75v-15.51h-1.5v15.51a5.25 5.25 0 01-5.25 5.25h-22.57a5.25 5.25 0 01-5.25-5.25v-22.57c0-2.9 2.35-5.25 5.25-5.25h15.51v-1.5z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#003F2D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M85.849 69.32h6.914v6.914h-6.914V69.32z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.08"
        strokeWidth="1.5"
        d="M85.849 69.32h6.914v6.914h-6.914V69.32z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#003F2D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M99.677 62.406v13.828"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.08"
        strokeWidth="1.5"
        d="M99.677 62.406v13.828"
      ></path>
      <path
        stroke="#003F2D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M92.763 62.406h13.828v13.828H92.763V62.406z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.08"
        strokeWidth="1.5"
        d="M92.763 62.406h13.828v13.828H92.763V62.406z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#003F2D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M92.763 69.32h13.828"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.08"
        strokeWidth="1.5"
        d="M92.763 69.32h13.828"
      ></path>
      <path
        fill="#003F2D"
        d="M116.837 52.553v-1.346h-3.576V47.63h-1.346v3.577h-3.596v1.346h3.596v3.577h1.346v-3.577h3.576z"
      ></path>
      <path
        fill="#fff"
        fillOpacity="0.08"
        d="M116.837 52.553v-1.346h-3.576V47.63h-1.346v3.577h-3.596v1.346h3.596v3.577h1.346v-3.577h3.576z"
      ></path>
    </svg>
  );
}

export default NoAnnualCostIcon;