import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, TextField } from '@material-ui/core';
import CustomTypography from '../utils/text';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
    innerHeight: '30',
    fontFamily: 'Calibre-R',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '21px',
    alignSelf: 'stretch',
    backgroundColor: 'white',
    '&:-webkit-autofill': {
      backgroundColor: '#FFFFFF !important;',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1a1a1a',
      },
      '&:hover fieldset': {
        borderColor: '#205747',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#205747',
      },
      '&.Mui-error fieldset': {
        borderColor: '#FF543E',
      },
    },
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 100,
  },
  root: {
    '&:hover': {
      borderColor: '#c4c4c4',
    },
    '&$focused ': {
      borderColor: '#c4c4c4',
    },
    '&.Mui-error': {
      borderColor: '#AB1212',
    },
  },
}));

const SimpleSelect = ({
  title,
  data,
  handleCallback,
  value = null,
  isMapview = true,
  error = false,
}) => {
  const [values, setValues] = React.useState(1);
  const classes = useStyles();
  const [dropdownData, setDropdownData] = useState([]);
  function handleChange(event) {
    setValues(event.target.value);
    handleCallback(event.target.value);
  }
  useEffect(() => {
    console.log(value);
    if (value) {
      setValues(value);
    } else {
      setValues('placeholder');
    }
  }, [value]);
  useEffect(() => {
    if (data?.length > 0) {
      var array = [];
      array.push({ id: 'placeholder', name: 'Select industry' });
      data?.map((obj) => {
        array.push(obj);
      });
      setDropdownData(array);
    }
  }, [data]);
  return (
    <form className={classes.container} noValidate autoComplete='off'>
      <TextField
        select
        error={error}
        id='outlined-select'
        label={title}
        inputPlaceholder={'inputPlaceholder'}
        className={classes.textField}
        value={values}
        onChange={handleChange}
        displayEmpty={true}
        InputProps={{
          disableUnderline: !isMapview,
          style: {
            fontWeight: isMapview ? '400' : 'bold',
          },
        }}
        InputLabelProps={{
          shrink: true,
          style: {
            color: error ? '#FF543E' : '#1A1A1A',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '20px',
            lineHeight: '20px',
            // fontFamily: 'Calibre-R',
          },
        }}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin='normal'
        variant={isMapview ? 'outlined' : 'standard'}
      >
        {dropdownData.map((value, index) => {
          return (
            <MenuItem
              disabled={index == 0 ? true : false}
              key={value.name}
              value={value.id}
              style={{
                paddingLeft: isMapview ? '15px' : '9px',
                display: index == 0 ? 'none' : 'auto',
              }}
            >
              <CustomTypography
                text={value.name}
                fontsize={index == 0 ? '16px' : '14px'}
                fontweight='100'
                lineheight='21px'
                fontcolor={index == 0 ? '#b3b3b3' : '#1A1A1A'}
                fontFamily={
                  index == 0
                    ? '"Roboto","Helvetica","Arial",sans-serif'
                    : "'Calibre-R'"
                }
              />
            </MenuItem>
          );
        })}
      </TextField>
    </form>
  );
};

export default SimpleSelect;
