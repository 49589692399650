import React from "react";

function TableViewIcon({ color = '#1A1A1A',width = 20, height = 20 , props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 20 18"
            {...props}
        >
            <path
                fill={color}
                d="M7.5 7.02h5V18h-5V7.02zm7 10.98h3c1.1 0 2-.9 2-2V7h-5v11zm3-18h-15C1.4 0 .5.9.5 2v3h19V2c0-1.1-.9-2-2-2zM.5 16c0 1.1.9 2 2 2h3V7h-5v9z"
            ></path>
        </svg>
    );
}

export default TableViewIcon;
