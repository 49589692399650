import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomTypography from '../utils/text';
import { Button } from '@material-ui/core';
import useStyles from '../styles';
import SliderComponent from '../components/slider_component';
import Dounught from '../components/doughnut';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import LoadingOverlayComponent from '../components/lodding_component';
import WarningMessage from '../components/warning_snackbar';
import AlertBar from '../components/alertBar';
import ArrowBackIcon from '../icons/arrowback_icon';
import DisableCheckedIcon from '../icons/disablecheck_icon';
import Lottie from 'lottie-react';
import * as animationData from '../utils/fancy-tick.json';
import useWindowDimensions from '../hooks/useWindowDimensions';
const SubCategoryWeighingsScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const ScoringCriteriaData = useSelector(
    (state) => state.mainReducer.selectionObject
  );
  const [scoringData, setScoringData] = useState([]);
  const [weightingScoreData, setweightingScoreData] = useState([]);
  const [scoring, setScoring] = useState(100);
  const location = useLocation();
  const [isLodding, setIsLodding] = useState(false);
  const [isContinueEnable, setIsContinueEnable] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const projectStatus = useSelector(
    (state) => state.mainReducer.selectedProjectStatus
  );
  const projectCompletedDate = useSelector(
    (state) => state.mainReducer.selectedProjectCompletedDate
  );
  const [openAlert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  useEffect(() => {
    if (projectStatus === 'Completed' || projectStatus === 'Archived') {
      setAlert(true);
      projectStatus === 'Completed'
        ? setAlertMessage('- This project was marked as completed on')
        : setAlertMessage('- This project was marked as archived on');
    }
  }, [projectStatus]);

  useEffect(() => {
    if (ScoringCriteriaData) {
      setweightingScoreData(ScoringCriteriaData);
    }
  }, [ScoringCriteriaData]);
  useEffect(() => {
    if (location && location.state?.data?.data) {
      if (location.state?.isEdit) {
        setIsEdit(location.state?.isEdit);
      } else {
        setIsEdit(false);
      }
      var selectedArray = location.state?.data?.data.filter(
        (e) => e.value == 'true' || e.value == true
      );
      let total = selectedArray?.reduce(function (prev, current) {
        return prev + Number(+current.score);
      }, 0);
      setScoring(100 - total);
      setScoringData(selectedArray);
    }
  }, [location]);
  useEffect(() => {
    var isEnable = true;
    if (scoring == 0) {
      scoringData.map((catItem) => {
        if (catItem.value || catItem.value == 'true') {
          if (catItem.score == 0) {
            isEnable = false;
          }
        }
      });
      var mainScore = 0;
      ScoringCriteriaData.map((obj) => {
        var filterarray = obj.data.filter(
          (e) => e.value == true || e.value == 'true'
        );
        if (filterarray.length > 0) {
          mainScore = mainScore + obj.score;
          if (obj.score == 0) {
            isEnable = false;
          }
          if (obj.header != location?.state?.data.header) {
            var subTotal = 0;
            obj.data.map((objItem) => {
              if (objItem.value || objItem.value == 'true') {
                subTotal = subTotal + objItem.score;
                if (objItem.score == 0) {
                  isEnable = false;
                }
              }
            });
            if (subTotal != 100) {
              isEnable = false;
            }
          }
        }
      });
      if (mainScore != 100) {
        isEnable = false;
      }
    } else {
      isEnable = false;
    }
    setIsContinueEnable(isEnable);
  }, [scoringData]);
  const handleOnChanage = (value, name) => {
    var data = [...scoringData];
    var index = data.findIndex((e) => e.item == name);
    if (index != -1) {
      data[index] = { ...data[index], score: value };
    }
    var value = 0;
    data?.forEach((obj) => {
      value = value + Number(obj.score);
    });
    setScoringData(data);
    setScoring(100 - value);
  };
  useEffect(() => {
    var array = [...weightingScoreData];
    if (array) {
      array.forEach((obj) => {
        if (obj.header == location?.state?.data.header) {
          obj.data.forEach((objItem) => {
            var filterarray = scoringData.filter((e) => e.item == objItem.item);
            if (filterarray.length > 0) {
              objItem['score'] = filterarray[0].score;
            }
          });
        }
      });
      let cacheData = JSON.parse(localStorage.getItem('cache_data'));
      var cache_data_obj = {
        ...cacheData,
        score_obj: array,
      };
      localStorage.setItem('cache_data', JSON.stringify(cache_data_obj));
    }
  }, [scoringData]);
  const handleBackClick = () => {
    var array = [...weightingScoreData];
    if (array) {
      array.forEach((obj) => {
        if (obj.header == location?.state?.data.header) {
          obj.data.forEach((objItem) => {
            var filterarray = scoringData.filter((e) => e.item == objItem.item);
            if (filterarray.length > 0) {
              objItem['score'] = filterarray[0].score;
            }
          });
        }
      });
      dispatch({ type: 'SET_SELECTION_OBJECT', payload: array });
    }
    if (isEdit) {
      navigate('/map/editweightingview');
    } else {
      navigate('/weighting');
    }
  };
  const isFilledAll = React.useMemo(() => {
    var returnValue = true;
    if (scoring == 0) {
      var scoringFilterArray = scoringData.filter((e) => e.score == 0);
      if (scoringFilterArray?.length != 0) {
        returnValue = false;
      }
    } else {
      returnValue = false;
    }
    return returnValue;
  }, [scoringData]);
  const subCategoryScore = React.useMemo(() => {
    var enterObjectArray = scoringData?.filter((e) => e.score > 0);
    var returnValue = enterObjectArray?.length + '/' + scoringData?.length;
    return returnValue;
  }, [scoringData]);
  return (
    <Grid
      container
      style={{
        backgroundColor: '#F5F5F5',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <>
        <Grid container style={{ display: 'flex', height: '18vh' }}>
          <Grid
            container
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 60,
              marginLeft: 60,
              marginRight: 60,
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <CustomTypography
                text={`${location?.state?.data.header}`}
                fontsize='36px'
                fontweight='400'
                fontcolor='#1A1A1A'
                lineheight='40px'
              />
              <Button
                className={classes.backbtn}
                style={{
                  marginLeft: 30,
                  width: 143,
                  marginTop: 5,
                  textTransform: 'inherit',
                }}
                onClick={handleBackClick}
              >
                <ArrowBackIcon />
                <CustomTypography
                  text='Back'
                  fontfamily='Calibre-SB'
                  fontsize='16px'
                  fontweight='500'
                  fontcolor='#003F2D'
                  lineheight='16px'
                  marginLeft={12}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            display: 'flex',
            backgroundColor: '#FFFFFF',
            height: '82vh',
            marginLeft: 30,
            marginRight: 30,
          }}
        >
          <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
            <Grid
              item
              xs={5}
              md={5}
              style={{
                borderRight: '2px solid #F5F5F5',
                display: 'flex',
                flexDirection: 'column',
                height: '90vh',
                paddingBottom: '10vh',
                paddingRight: 10,
              }}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingTop: 30,
                  paddingLeft: 32,
                  paddingRight: width > 1400 ? 55 : 35,
                }}
              >
                <Grid item xs={10} md={10}>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <>
                      {isFilledAll ? (
                        <Lottie
                          animationData={animationData}
                          loop={false}
                          onComplete={() => { }}
                          style={{
                            margin: '-20px',
                            marginLeft: '-18px',
                            width: '58px',
                          }}
                        />
                      ) : (
                        <DisableCheckedIcon />
                      )}
                    </>
                    <CustomTypography
                      text={`Available weighting points`}
                      fontsize={width > 1400 ? '30px' : '26px'}
                      fontweight='400'
                      fontcolor='#1A1A1A'
                      lineheight='40px'
                      marginLeft={9}
                      justifycontent='flex-start'
                    />
                  </Grid>
                  <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                    <CustomTypography
                      text={subCategoryScore + '\xa0'}
                      fontsize='14px'
                      fontweight='500'
                      fontcolor='#1A1A1A'
                      lineheight='16px'
                      justifycontent='flex-start'
                    />

                    <CustomTypography
                      text={`completed`}
                      fontsize='14px'
                      fontweight='500'
                      fontcolor='#767676'
                      lineheight='16px'
                      justifycontent='flex-start'
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={2}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Grid
                    style={{
                      width: "100%",
                      height: 57,
                      backgroundColor: '#D2E8DA',
                      alignItems: 'center',
                    }}
                  >
                    <CustomTypography
                      text={scoring}
                      fontsize='36px'
                      fontweight='400'
                      fontcolor='#1A1A1A'
                      lineheight='25px'
                      justifycontent='center'
                      marginTop='15px'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  borderBottom: '2px solid #F5F5F5',
                  paddingTop: 10,
                }}
              />
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingBottom: 65,
                  paddingTop: 15,
                  overflow: 'auto',
                }}
              >
                {scoringData?.map((object) => {
                  return (
                    <SliderComponent
                      projectStatus={projectStatus}
                      key={object.item}
                      paddingTop={30}
                      name={object.item}
                      score={object.score}
                      onChangeValue={(value, obj) => {
                        handleOnChanage(value, obj);
                      }}
                      data={scoringData}
                      subCategory={true}
                      paddingRight={width > 1400 ? 55 : 32}
                    />
                  );
                })}
              </Grid>
            </Grid>
            <Grid
              item
              xs={7}
              md={7}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Dounught
                style={{ width: 500, height: 500 }}
                data={scoringData}
                subCategory={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
      {isLodding && <LoadingOverlayComponent open={isLodding} />}
      {
        warningSnackbar && (
          <WarningMessage
            open={true}
            setOpen={() => setWarningSnackbar(null)}
            severity={warningSnackbar.severity}
            name={warningSnackbar.name}
            message={warningSnackbar.message}
          />
        )
      }
      {
        openAlert && (
          <AlertBar
            open={true}
            setOpen={() => setAlert(null)}
            name={'View only mode '}
            message={alertMessage}
            // date field is pending
            date={projectCompletedDate}
          />
        )
      }
    </Grid >
  );
};
export default SubCategoryWeighingsScreen;
