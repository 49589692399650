import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import EditWeighingsScreen from './edit_weighting_scores';
import { useSelector, useDispatch } from 'react-redux';
import WarningMessage from '../components/warning_snackbar';
import LoadingOverlayComponent from '../components/lodding_component';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { fetchProjectWeightingScores } from '../redux/reducers';
import ScoringCriteriaObject from '../utils/criteria_selection.json';

const EditWeightigScoresIndex = () => {
  const [isLodding, setIsLodding] = useState(false);
  const dispatch = useDispatch();
  const hubweightingKey = useSelector((state) => state.mainReducer.hwgtkey);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    setIsLodding(true);
    weightingsScore();

    async function weightingsScore() {
      try {
        const response = await axiosPrivate.get(`/weightings/score?hubweightingKey=${hubweightingKey}`);
        if (response.status === 200) {
          dispatch({
            type: 'SET_PROJECT_WEIGHTING_SCORES',
            payload: response.data,
          });
          let cacheData = JSON.parse(localStorage.getItem('cache_data'));
          const updated = fetchProjectWeightingScores(ScoringCriteriaObject, response.data);
          cacheData = {
            ...cacheData,
            score_obj: updated,
          };
          localStorage.setItem('cache_data', JSON.stringify(cacheData));
        }
      } catch (error) {
        console.log('error.........', error);
        setWarningSnackbar({
          name: 'Weighting info:',
          message: 'Unable to fetch the weighting details',
          severity: 'error',
        });
      } finally {
        setIsLodding(false);
      }
    } // End of weightingsScore
  }, []);

  return (
    <Grid>
      <EditWeighingsScreen />
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
      {isLodding && <LoadingOverlayComponent open={isLodding} />}
    </Grid>
  );
};
export default EditWeightigScoresIndex;
