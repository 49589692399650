import React from "react";

function WarningIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="19"
            fill="none"
            viewBox="0 0 22 19"
        >
            <path
                fill="#FF9742"
                d="M0 19h22L11 0 0 19zm12-3h-2v-2h2v2zm0-4h-2V8h2v4z"
            ></path>
        </svg>
    );
}

export default WarningIcon;
