import React from 'react';

function ConfirmationIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='146'
      height='150'
      fill='none'
      viewBox='0 0 146 150'
    >
      <circle cx='104' cy='108' r='42' fill='#D7F1F9'></circle>
      <path
        stroke='#003F2D'
        strokeWidth='3'
        d='M106 83.5V22c0-11.046-8.954-20-20-20H22C10.954 2 2 10.954 2 22v66c0 11.046 8.954 20 20 20h58.5'
      ></path>
      <path
        stroke='#003F2D'
        strokeWidth='3'
        d='M0 -1.5L65 -1.5'
        transform='matrix(1 0 0 -1 22 86)'
      ></path>
      <path
        stroke='#003F2D'
        strokeWidth='3'
        d='M0 -1.5L65 -1.5'
        transform='matrix(1 0 0 -1 22 69)'
      ></path>
      <path
        stroke='#003F2D'
        strokeWidth='3'
        d='M0 -1.5L65 -1.5'
        transform='matrix(1 0 0 -1 22 52)'
      ></path>
      <path
        stroke='#003F2D'
        strokeWidth='3'
        d='M0 -1.5L65 -1.5'
        transform='matrix(1 0 0 -1 22 35)'
      ></path>
      <path
        stroke='#003F2D'
        strokeWidth='3'
        d='M0 -1.5L65 -1.5'
        transform='matrix(1 0 0 -1 22 18)'
      ></path>
      <path
        stroke='#003F2D'
        strokeWidth='3'
        d='M96 109l5.895 6L112 100m8 7.5c0 9.113-7.387 16.5-16.5 16.5S87 116.613 87 107.5 94.387 91 103.5 91s16.5 7.387 16.5 16.5z'
      ></path>
    </svg>
  );
}

export default ConfirmationIcon;
