import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { FormControl, Select, MenuItem, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useSelector } from 'react-redux';
import LoadingOverlayComponent from '../components/lodding_component';
import { Grid, makeStyles } from '@material-ui/core';
import StarSelected from '../icons/star_selected';
import StarNotSelected from '../icons/star_not_selected';
import OccupiedSpaceHoverPop from './occupied_space_hover_pop';
import tubeCellRenderer from './tubeCellRenderer';
import Stack from '@mui/material/Stack';
import RightDrawer from './right_drawer';
import CustomTextField from '../components/custom_textfield';
import '../styles/dataGridview.css';
import CustomTypography from '../utils/text';
import ImageCardView from './image_card';
import WarningMessagelert from '../components/warning_snackbar';
import ImageViewer from './image_viewer';
import CustomTooltip from '../utils/simple_tooltip';
import moment from 'moment';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CurrencyFormatter from './currency_formatter';
import CurrentIcon from '../icons/current_site';
import OffmarketIcon from '../icons/off_market';
import UnderofferIcon from '../icons/under_offer';
import useStyles from '../styles/index';
import SendIcon from '../icons/send_icon';
import ContextMenuIcon from '../icons/context_icon';
import CircularProgress from '@mui/material/CircularProgress';
import { MV_TUBE_CONNECTIONS } from '../utils/constants';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { MdCheck } from 'react-icons/md';
import { FiMinus } from 'react-icons/fi';
import { cloneDeep } from 'lodash';

const PropertyDetailsDisplay = ({ s_p_data, handleStarProperty, onClose }) => {
  const gridRef = useRef();
  const stackingGridRef = useRef();
  const contactsGridRef = useRef();
  const classes = useStyles();
  const hkey = useSelector((state) => state.mainReducer.hkey);
  const hwgtkey = useSelector((state) => state.mainReducer.hwgtkey);
  const email = useSelector((state) => state.mainReducer.email);
  const hubUserKey = useSelector((state) => state.mainReducer.hubUser);
  const [isLodding, setIsLodding] = useState(false);
  const [listedLoading, setListedLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [ifStackingPlan, setIfStackingPlan] = useState(false);
  const [comments, setComments] = useState('');
  const [commentsData, setCommentsData] = useState([]);
  const projectStatus = useSelector((state) => state.mainReducer.selectedProjectStatus);
  const { assistantOpen } = useSelector((state) => state.mainReducer);
  const axiosPrivate = useAxiosPrivate();
  const headerStyle = makeStyles(() => ({
    buildingName: {
      cursor: 'pointer',
      padding: '0 5px 0 5px',
      borderRadius: '5px',
      '&:hover': {
        background: '#dfe6e5',
      },
    },
  }));

  const [count, setCount] = useState(null);

  const containerStyle = useMemo(
    () => ({
      width: window.innerWidth / 2 - 132,
      padding: 25,
      fontFamily: 'Calibre-R',
      height: window.innerHeight,
      overflow: 'hidden',
    }),
    [window.innerWidth]
  );
  const headingRow = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '15px 15px 15px 0',
      alignItems: 'flex-end',
    }),
    []
  );
  const headingTopic = useMemo(
    () => ({
      fontSize: '24px',
      fontWeight: '400',
      margin: '10px 0 0 0',
      fontFamily: 'Calibre-R',
    }),
    []
  );
  const highlightSection = useMemo(
    () => ({
      textAlign: 'center',
      fontSize: '16px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: '2px solid #003F2D',
      color: '#003F2D',
      height: '50px',
      width: '140px',
      marginLeft: '30px',
      fontFamily: 'Calibre-R',
    }),
    []
  );
  const nonHighlightSection = useMemo(
    () => ({
      textAlign: 'center',
      fontSize: '16px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: '50px',
      width: '140px',
      fontFamily: 'Calibre-R',
    }),
    []
  );
  const gridStyle = useMemo(() => ({ width: '100%', fontFamily: 'Calibre-R' }), []);
  const gridAmenityStyle = useMemo(() => ({ width: '100%', fontFamily: 'Calibre-R' }), []);
  const rowGray = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#F5F7F7',
      color: '#1A1A1A',
      fontWeight: '400',
      fontSize: '16px',
      height: '50px',
      fontFamily: 'Calibre-R',
      borderTop: '1px solid rgba(0, 63, 45, 0.1)',
      borderBottom: '1px solid rgba(0, 63, 45, 0.1)',
    }),
    []
  );
  const specificationTabStyle = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#F5F7F7',
      color: '#1A1A1A',
      fontWeight: '400',
      fontSize: '16px',
      height: '50px',
      width: '130%',
      marginLeft: '-30px',
      fontFamily: 'Calibre-R',
      borderTop: '1px solid rgba(0, 63, 45, 0.1)',
      borderBottom: '1px solid rgba(0, 63, 45, 0.1)',
    }),
    []
  );
  const [imageOpen, setImageOpen] = useState(false);
  const totalSelected = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      fontSize: '14px',
      fontWeight: '500',
      width: '240px',
      justifyContent: 'center',
    }),
    []
  );
  const totalSelectedText = useMemo(() => ({ fontSize: '14px', fontWeight: '500' }), []);
  const addHeadingFirst = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '0 10px 0 0',
      borderRight: '2px solid #D9D9D9',
      fontFamily: 'Calibre-R',
      height: '13px',
    }),
    []
  );
  const addHeadingMoreDetailsContent = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '0 10px',
      borderRight: '2px solid #D9D9D9',
      fontFamily: 'Calibre-R',
    }),
    []
  );
  const addHeadingLast = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingLeft: '10px',
      borderRight: '0px',
      fontFamily: 'Calibre-R',
    }),
    []
  );
  const addPaddIt = useMemo(
    () => ({
      paddingLeft: '10px',
      fontWeight: '400',
      color: '#000',
      fontSize: '16px',
    }),
    []
  );
  const grayHeadingText = useMemo(
    () => ({
      color: '#1a1a1a',
      fontFamily: 'Calibre-R',
    }),
    []
  );
  const addressHeadingStar = useMemo(
    () => ({
      display: 'flex',
      justifyContent: 'left',
      marginTop: '12px',
      marginRight: '9px',
      cursor: 'pointer',
    }),
    []
  );
  const addressHeadingDetails = useMemo(
    () => ({
      fontFamily: 'Calibre-R',
    }),
    []
  );
  const addHeadingHolder = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'baseline',
      margin: 0,
    }),
    []
  );
  const addHeadingRow = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      margin: 0,
    }),
    []
  );
  const addHeadingIconRow = useMemo(
    () => ({
      alignSelf: 'auto',
      paddingRight: '10px',
    }),
    []
  );
  const addHeadingBold = useMemo(
    () => ({
      fontWeight: '400',
      fontSize: '36px',
      color: '#1A1A1A',
      fontFamily: 'Calibre-R',
      lineheight: '40px',
    }),
    []
  );
  const addHeadingSideText = useMemo(
    () => ({
      fontSize: '20px',
      padding: '0 5px 0 5px',
      color: '#1A1A1A',
      width: '90px',
    }),
    []
  );
  const addHeadingMoreDetails = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      fontSize: '16px',
      color: '#1A1A1A',
      lineHeight: '12px',
    }),
    []
  );
  const addressHeadingRank = useMemo(
    () => ({
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      fontFamily: 'Calibre-R',
    }),
    []
  );
  const addressBoldNumber = useMemo(
    () => ({
      display: 'flex',
      justifyContent: 'center',
      height: '50px',
      width: '50px',
      fontSize: '30px',
      border: '1px solid #DFE6E5',
      borderRadius: '10px',
      color: '#000',
      backgroundColor: '#DFE6E5',
      alignItems: 'center',
      fontFamily: 'Calibre-R',
    }),
    []
  );
  const addressRankNumber = useMemo(
    () => ({
      display: 'flex',
      justifyContent: 'center',
      height: '20px',
      width: '50px',
      fontSize: '12px',
      color: '#1a1a1a',
      alignItems: 'center',
      fontFamily: 'Calibre-R',
    }),
    []
  );
  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontFamily: 'Calibre-R',
        fontSize: '14px',
        fontWeight: 400,
      },
    })
  );

  const getFormattedValue = useCallback((str, isClientProp, limit = 25) => {
    if (!str) return '';
    const strng = str
      .split(' ')
      .map((el) => el.charAt(0).toUpperCase() + el.slice(1))
      .join(' ');
    if (isClientProp) {
      return strng.length > 21 ? strng?.slice(0, 20) + '...' : strng;
    }
    return strng.length > limit ? strng?.slice(0, limit - 1) + '...' : strng;
  }, []);

  const setFontHeader = useMemo(() => ({ fontFamily: 'Calibre-R' }), []);
  const [rowData, setRowData] = useState();
  const [stackingRowData, setStackingRowData] = useState();
  const [tubeTransportRowData, setTubeTransportRowData] = useState([]);
  const [tramRowData, setTramRowData] = useState([]);
  const [trainTransportRowData, setTrainTransportRowData] = useState();
  const [ratingRowData, setRatingRowData] = useState();
  const [techSpecsData, setTechSpecsData] = useState();
  const [toggleButtonIndex, setToggleButtonIndex] = useState(1);
  const [images, setImages] = useState([]);
  const [buildingPlans, setBuildingPlans] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [imageViewerData, setImageViewerData] = useState([]);
  const [cureentImageIndex, setCurrentImageIndex] = useState(0);
  const [showRatings, updateRatings] = useState(true);
  const [showTechSpec, updateTechSpec] = useState(true);
  const [showRailTransport, updateRailTransport] = useState(true);
  const [showAvailability, updateAvailability] = useState(true);
  const [showAvailabilityStacking, updateAvailabilityStacking] = useState(true);
  const [contactsRowData, setContactsRowData] = useState([]);
  const values = ['Display by Availability', 'Display Stacking Plan'];
  const [selected, setSelected] = useState('Display by Availability');
  const [totalSQFT, settotalSQFT] = useState(0);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const BtnCellRenderer = (props) => {
    return (
      <Button
        variant='outlined'
        style={{
          background: props.data.SELECT_FIELD ? '#003F2D' : '#fff',
          border: props.data.SELECT_FIELD ? '1px solid #003F2D' : '1px solid rgb(86, 86, 86)',
          padding: '2px 0',
          maxWidth: '20px',
          minWidth: '20px',
          borderRadius: 0,
        }}
        onClick={() => {
          const data = cloneDeep(rowData);
          const index = data.findIndex((el) => el.id === props.data.id);
          data[index] = {
            ...data[index],
            SELECT_FIELD: !data[index].SELECT_FIELD,
          };
          setRowData([...data]);
          const stackingData = cloneDeep(stackingRowData);
          const stackIndex = stackingData.findIndex((el) => el.id === props.data.id);
          stackingData[stackIndex] = {
            ...stackingData[stackIndex],
            SELECT_FIELD: !stackingData[stackIndex].SELECT_FIELD,
          };
          setStackingRowData([...stackingData]);
          const reqObj = {
            hkey: hkey,
            propertyIdVal: propertyIdVal,
            floorKey: props.data.HKEY_MSAT_FLOOR,
            isSelected: !props.data.SELECT_FIELD,
            occupier: !props.data.OCCUPIER,
          };
          fetchAvailableFloorSpace(reqObj);
        }}
      >
        {props.data.SELECT_FIELD ? <MdCheck style={{ color: '#fff' }} /> : <FiMinus style={{ color: 'transparent' }} />}
      </Button>
    );
  };

  const ButtonRenderer = (props) => {
    const disabled = props.data.TAG_PROPERTY ? false : props.data.OCCUPIER ? true : false;
    return (
      <Button
        variant='outlined'
        disabled={disabled}
        style={{
          background: props.data.SELECT_FIELD ? '#003F2D' : '#fff',
          border: props.data.SELECT_FIELD ? '1px solid #003F2D' : '1px solid rgb(86, 86, 86)',
          opacity: disabled ? 0.3 : 1,
          padding: '2px 0',
          maxWidth: '20px',
          minWidth: '20px',
          cursor: props.data.TAG_PROPERTY ? 'pointer' : 'default',
          borderRadius: 0,
        }}
        onClick={() => {
          const stackingData = cloneDeep(stackingRowData);
          const stackIndex = stackingData.findIndex((el) => el.id === props.data.id);
          stackingData[stackIndex] = {
            ...stackingData[stackIndex],
            SELECT_FIELD: !stackingData[stackIndex].SELECT_FIELD,
          };
          setStackingRowData([...stackingData]);

          const data = cloneDeep(rowData);
          const index = data.findIndex((el) => el.id === props.data.id);
          if (index !== -1) {
            data[index] = {
              ...data[index],
              SELECT_FIELD: !data[index].SELECT_FIELD,
            };
            setRowData([...data]);
          }
          const reqObj = {
            hkey: hkey,
            propertyIdVal: propertyIdVal,
            floorKey: props.data.HKEY_MSAT_FLOOR,
            isSelected: !props.data.SELECT_FIELD,
            occupier: !props.data.OCCUPIER,
          };
          fetchAvailableFloorSpace(reqObj);
        }}
      >
        {props.data.SELECT_FIELD ? <MdCheck style={{ color: '#fff' }} /> : <FiMinus style={{ color: 'transparent' }} />}
      </Button>
    );
  };

  const columnDefs = useMemo(() => {
    return [
      {
        headerName: '',
        cellRenderer: BtnCellRenderer,
        width: '100px',
        resizable: false,
      },
      {
        headerName: 'Floor',
        field: 'FLOOR_NUMBER',
        width: '145px',
        sortable: true,
      },
      {
        headerName: 'Available (sq.ft.)',
        field: 'AVAILABLE_SPACE_SQFT',
        width: '125px',
        valueFormatter: (params) => CurrencyFormatter(params.value, '$'),
        cellStyle: (stackingRowData) => {
          if (stackingRowData.data.OCCUPIER) {
            return { backgroundColor: 'rgba(245, 247, 247, 1)' };
          } else {
            return { backgroundColor: 'rgba(212, 237, 223, 1)' };
          }
        },
      },
      {
        headerName: 'Guide Rent (£/sq.ft.)',
        field: 'NET_RENTABLE_AREA',
        width: '140px',
        valueFormatter: (params) => CurrencyFormatter(params.value ?? '', '$'),
      },
      {
        headerName: 'Service charge (£/sq/ft.)',
        field: 'SERVICE_CHARGE',
        width: '165px',
        valueFormatter: (params) => CurrencyFormatter(params.value == '-' || !params.value ? 0 : params.value, '$'),
      },
    ];
  }, [rowData]);
  const columnStackingDefs = useMemo(() => {
    return [
      {
        headerName: '',
        cellRenderer: ButtonRenderer,
        width: '100px',
        resizable: false,
      },
      {
        headerName: 'Floor',
        field: 'FLOOR_NUMBER',
        width: '155px',
        sortable: true,
      },
      {
        headerName: 'Occupier',
        field: 'OCCUPIER',
        width: '159px',
        tooltipField: 'OCCUPIER',
        tooltipComponentParams: { name: 'OCCUPIER' },
      },
      {
        headerName: 'Size (sq.ft.)',
        field: 'AVAILABLE_SPACE_SQFT',
        width: '120px',
        tooltipField: 'AVAILABLE_SPACE_SQFT',
        tooltipComponent: OccupiedSpaceHoverPop,
        valueFormatter: (params) => CurrencyFormatter(params.value ?? '', '$'),
        cellStyle: (stackingRowData) => {
          if (stackingRowData.data.OCCUPIER) {
            return { backgroundColor: 'rgba(245, 247, 247, 1)' };
          } else {
            return { backgroundColor: 'rgba(212, 237, 223, 1)' };
          }
        },
      },
      {
        headerName: 'Guide Rent (£/sq.ft.)',
        field: 'NET_RENTABLE_AREA',
        width: '140px',
        valueFormatter: (params) => CurrencyFormatter(params.value ?? '', '$'),
      },
      {
        headerName: 'LANDLORD',
        field: 'LANDLORD',
        width: '1px',
        hide: 'true',
      },
    ];
  }, [stackingRowData]);

  const propertyContactsArrayList = useMemo(() => {
    return [
      {
        headerName: 'Name',
        field: 'NAME',
        width: '130px',
        resizable: true,
        wrapText: true,
        autoHeight: true,
        lineheight: '15px',
        cellRenderer: (data) => {
          return (
            <span style={{ display: 'flex', flexWrap: 'wrap' }}>
              {data?.data?.NAME?.split(',')?.map((el, i, arr) => (
                <span key={el}>
                  {el}
                  {arr.length - 1 !== i && ','}
                </span>
              ))}
            </span>
          );
        },
        cellStyle: {
          lineHeight: '15px',
          display: 'flex',
          alignItems: 'center',
          wordBreak: 'break-word',
        },
      },
      {
        headerName: 'Role',
        field: 'ROLE',
        width: '160px',
        sortable: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        lineheight: '15px',
        cellRenderer: (data) => {
          return (
            <span style={{ display: 'flex', flexWrap: 'wrap' }}>
              {data?.data?.ROLE?.split(',')?.map((el, i, arr) => (
                <span key={el}>
                  {el}
                  {arr.length - 1 !== i && ','}
                </span>
              ))}
            </span>
          );
        },
        cellStyle: {
          lineHeight: '15px',
          display: 'flex',
          alignItems: 'center',
          wordBreak: 'break-word',
        },
      },
      {
        headerName: 'Company',
        field: 'COMPANY',
        width: '160px',
        sortable: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        lineheight: '15px',
        cellRenderer: (data) => {
          return (
            <span style={{ display: 'flex', flexWrap: 'wrap' }}>
              {data?.data?.COMPANY?.split(',')?.map((el, i, arr) => (
                <span key={el}>
                  {el}
                  {arr.length - 1 !== i && ','}
                </span>
              ))}
            </span>
          );
        },
        cellStyle: {
          lineHeight: '15px',
          display: 'flex',
          alignItems: 'center',
          wordBreak: 'break-word',
        },
      },
      {
        headerName: 'Contact details',
        field: 'CONTACT_DETAILS',
        width: '300px',
        wrapText: true,
        sortable: true,
        resizable: true,
        autoHeight: true,
        lineheight: '18px',
        cellRenderer: (data) => {
          return (
            <span style={{ display: 'flex', flexWrap: 'wrap' }}>
              {data?.data?.CONTACT_DETAILS?.split(',')?.map((el, i, arr) => (
                <span key={el}>
                  {el}
                  {arr.length - 1 !== i && ','}
                </span>
              ))}
            </span>
          );
        },
        cellStyle: {
          lineHeight: '18px',
          display: 'flex',
          alignItems: 'center',
          wordBreak: 'break-word',
        },
      },
      {
        headerName: 'Associated floors',
        field: 'FLOOR_LEASE',
        width: '250px',
        sortable: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        lineheight: '15px',
        cellRenderer: (data) => {
          return (
            <span style={{ display: 'flex', flexWrap: 'wrap' }}>
              {data?.data?.FLOOR_LEASE?.split(',')?.map((el, i, arr) => (
                <span key={el}>
                  {el}
                  {arr.length - 1 !== i && ','}
                </span>
              ))}
            </span>
          );
        },
        cellStyle: {
          lineHeight: '15px',
          display: 'flex',
          alignItems: 'center',
          wordBreak: 'break-word',
        },
      },
      {
        headerName: 'Email',
        field: 'EMAIL',
        width: '280px',
        sortable: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        cellRenderer: (data) => {
          return (
            <span
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                wordBreak: 'break-word',
                lineHeight: '15px',
              }}
            >
              {data?.data?.EMAIL?.split(',')?.map((el, i, arr) => (
                <span key={el} style={{}}>
                  {el}
                  {arr.length - 1 !== i && ','}
                </span>
              ))}
            </span>
          );
        },
        cellStyle: {
          lineHeight: '15px',
          display: 'flex',
          alignItems: 'center',
          wordBreak: 'break-word',
        },
      },
    ];
  }, [contactsRowData]);
  const isRowSelectable = useMemo(() => {
    return (params) => {
      if (params.data.TAG_PROPERTY) {
        return true;
      } else if (params.data.OCCUPIER) {
        return false;
      } else {
        return true;
      }
    };
  }, []);
  const [tubeTransportColumnDefs, setTubeTransportColumnDefs] = useState([
    {
      headerName: 'Nearest tube stations',
      field: 'NEAREST_TUBE_NAME',
      width: '145px',
      tooltipField: 'NEAREST_TUBE_NAME',
      tooltipComponentParams: { name: 'NEAREST_TUBE_NAME' },
    },
    {
      headerName: 'Connections',
      field: 'TUBE_CONNECTIONS',
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        lineHeight: '15px',
        display: 'flex',
        alignItems: 'center',
        wordBreak: 'break-word',
      },
      width: '275px',
      cellRenderer: tubeCellRenderer,
      tooltipField: 'TUBE_CONNECTIONS',
      tooltipComponentParams: { name: 'TUBE_CONNECTIONS' },
    },
    {
      headerName: 'Distance',
      field: 'TUBE_DISTANCE',
      width: '90px',
    },
    {
      headerName: 'Walking Time',
      field: 'TUBE_WALKING_TIME',
      width: '123px',
    },
  ]);
  const tramTransportColumnDefs = [
    {
      headerName: 'Nearest tram stations',
      field: 'Station name',
      width: '145px',
      tooltipField: 'Station name',
      tooltipComponentParams: { name: 'Station name' },
    },
    {
      headerName: 'Connections',
      field: 'Connections',
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        lineHeight: '15px',
        display: 'flex',
        alignItems: 'center',
        wordBreak: 'break-word',
      },
      width: '275px',
      tooltipComponentParams: { name: 'Connections' },
    },
    {
      headerName: 'Distance',
      field: 'Distance',
      width: '90px',
    },
    {
      headerName: 'Walking Time',
      field: 'Walking time',
      width: '123px',
    },
  ];
  const [trainTransportColumnDefs, setTrainTransportColumnDefs] = useState([
    {
      headerName: 'Nearest national rail',
      field: 'NEAREST_TRAIN_STATION',
      width: '145px',
      tooltipField: 'NEAREST_TRAIN_STATION',
      tooltipComponentParams: { name: 'NEAREST_TRAIN_STATION' },
    },
    {
      headerName: 'Connections',
      field: 'TRAIN_CONNECTIONS',
      width: '270px',
      wrapText: true,
      autoHeight: true,
      lineheight: '22px',
      cellRenderer: (data) => {
        return (
          <span style={{ display: 'flex', flexWrap: 'wrap' }}>
            {data?.data?.TRAIN_CONNECTIONS?.split(',')
              ?.filter((el) => !MV_TUBE_CONNECTIONS.includes(el.trim()))
              .map((el, i, arr) => (
                <span key={el}>
                  {el}
                  {arr.length - 1 !== i && ','}&nbsp;
                </span>
              ))}
          </span>
        );
      },
      cellStyle: {
        lineHeight: '22px',
        display: 'flex',
        alignItems: 'center',
        wordBreak: 'break-word',
      },
    },
    {
      headerName: 'Distance',
      field: 'TRAIN_DISTANCE',
      width: '90px',
    },
    { headerName: 'Walking Time', field: 'TRAIN_WALKING_TIME', width: '123px' },
  ]);
  const [ratingColumnDefs, setRatingColumnDefs] = useState([
    { headerName: 'Rating type', field: 'RATING_TYPE', width: '347px' },
    { headerName: 'Rating level', field: 'RATING_LEVEL', width: '285px' },
  ]);
  const [techSpecColumnDefs, setTechSpecColumnDefs] = useState([
    { headerName: 'Specification', field: 'AMENITY_NAME', width: '347px' },
    { headerName: 'Value', field: 'AMENITY_VALUE', width: '285px' },
  ]);
  const [headingData, setHeadingData] = useState({
    SHORT_OR_LONG: '',
    ADDRESS_LINE1: '-',
    POSTAL_CODE: '-',
    LANDLORD_DATA: '-',
    AVAILABLE_FROM_DATA: '-',
    SCORE_DATA: '-',
    RANK_DATA: '-',
    TAG_PROPERTY: '',
    CONDITION_DATA: '-',
  });
  useEffect(() => {
    setIsLodding(true);
    populateAgGridData();
  }, [s_p_data]);

  useEffect(() => {
    if (toggleButtonIndex === 3) {
      handleOnComments();
    }
  }, [count]);

  function handleChange(event) {
    setSelected(event.target.value);
    if (event.target.value === 'Display by Availability') {
      setIfStackingPlan(false);
    } else {
      setIfStackingPlan(true);
    }
  }

  let propertyIdVal = JSON.parse(s_p_data).propertyId;

  const populateAgGridData = async () => {
    if (s_p_data) {
      try {
        const response = await axiosPrivate.get(
          `/property-details/single-property/${hkey}/${hwgtkey}/${propertyIdVal}`
        );
        if (response.status === 200) {
          if (!response.data.length) {
            onClose();
            return;
          }
          let propertiesData = [];
          propertiesData = response.data.map((item) => ({
            postal_code: [item.POSTAL_CODE],
            address_line_1: item.ADDRESS_LINE1,
            floors: [item.FLOORS],
            true_owner: [item.TRUE_OWNER],
            listed_property: [item.LISTED],
            available_from: [item.AVAILABLE_FROM],
            train_transport_info: [item.TRAIN_TRANSPORT_INFO],
            tube_transport_info: [item.TUBE_TRANSPORT_INFO],
            tramTransportInfo: [item.TRAM_TRANSPORT_INFO],
            breem_rating: [item.ESG_BREEAM_RATING],
            epc_rating: [item.ESG_EPC_RATING],
            wiredscore_rating: [item.BI_WIREDSCORE_RATING],
            technical_specs_aka_amenities: [item.AMENITIES],
            score: [item.SCORE],
            rank: [item.RANK],
            TAG_PROPERTY: item.TAG_PROPERTY,
            condition: [item.CONDITION],
            selected_floors: [item.SELECTED_FLOORS_JSON],
            propertyCode: item.PROPERTY_CODE,
          }));
          handleSingleProperty(propertiesData.length ? propertiesData[0] : {});
          if (response.data[0]?.CONTACTS) {
            response.data[0]?.CONTACTS?.map((obj, index) => {
              obj.id = index;
            });
            setContactsRowData(response.data[0]?.CONTACTS);
          } else {
            setContactsRowData([]);
          }
        }
      } catch (error) {
        setWarningSnackbar({
          name: 'Property Info:',
          message: 'Unable to fetch data',
          severity: 'error',
        });
      } finally {
        setIsLodding(false);
      }
    }
  };

  useEffect(() => {
    fetchGalleryDetails();
    fetchPropertyComments();
    getUnReadCommentsCount();
  }, [s_p_data]);

  const handleSingleProperty = (singlePropertyTemp) => {
    const short_or_long = singlePropertyTemp.listed_property;
    const address_line_1 = singlePropertyTemp.address_line_1;
    const postal_code_data = singlePropertyTemp.postal_code;
    const true_owner_data = singlePropertyTemp.true_owner;
    const available_from_data = singlePropertyTemp.available_from;
    const score_data = singlePropertyTemp.score;
    const rank_data = singlePropertyTemp.rank;
    const tagProperty = singlePropertyTemp.TAG_PROPERTY;
    let headerArray = [];
    let someObj = {
      SHORT_OR_LONG: short_or_long,
      ADDRESS_LINE1: address_line_1,
      POSTAL_CODE: postal_code_data,
      LANDLORD_DATA: true_owner_data,
      AVAILABLE_FROM_DATA: available_from_data,
      SCORE_DATA: score_data,
      RANK_DATA: rank_data,
      TAG_PROPERTY: tagProperty,
      CONDITION_DATA: singlePropertyTemp.condition,
      PROPERTY_ID: singlePropertyTemp.propertyCode,
    };
    headerArray.push(someObj);
    setHeadingData(headerArray[0]);
    if (headerArray[0].TAG_PROPERTY) {
      setSelected('Display Stacking Plan');
      setIfStackingPlan(true);
    } else {
      setSelected('Display by Availability');
      setIfStackingPlan(false);
    }
    const availablility_data_aka_floors_data = singlePropertyTemp.floors;
    let totSQFT = 0;
    let availabilityArray = [];
    if (availablility_data_aka_floors_data[0]) {
      updateAvailabilityStacking(true);
      availablility_data_aka_floors_data[0].forEach((item, index) => {
        const isSelected = singlePropertyTemp.selected_floors
          .at(0)
          ?.find((el) => item.HKEY_MSAT_FLOOR === el.FLOOR && !item.OCCUPIER === el.AVAILABLE);
        let object = {
          id: item.HKEY_MSAT_FLOOR + index,
          SELECT_FIELD: !!isSelected,
          FLOOR_NUMBER: item.FLOOR_NUMBER + ' floor',
          AVAILABLE_SPACE_SQFT: item.AVAILABLE_SPACE_SQFT,
          NET_RENTABLE_AREA: item.NET_RENTABLE_AREA,
          SERVICE_CHARGE: item?.SERVICE_CHARGE || '-',
          OCCUPIER: item.OCCUPIER,
          LEASE_EXPIRY_DATE: item.LEASE_EXPIRY_DATE,
          LANDLORD: item.LANDLORD,
          TAG_PROPERTY: tagProperty,
          HKEY_MSAT_FLOOR: item.HKEY_MSAT_FLOOR,
        };
        availabilityArray.push(object);
        if (tagProperty) {
          totSQFT += item.AVAILABLE_SPACE_SQFT;
        } else {
          if (!item.OCCUPIER) {
            totSQFT += item.AVAILABLE_SPACE_SQFT;
          }
        }
      });
    } else {
      updateAvailabilityStacking(false);
      availabilityArray = [
        {
          SELECT_FIELD: false,
          FLOOR_NUMBER: '-',
          AVAILABLE_SPACE_SQFT: '-',
          NET_RENTABLE_AREA: '-',
          SERVICE_CHARGE: '-',
          OCCUPIER: '-',
          LEASE_EXPIRY_DATE: '-',
          LANDLORD: '-',
          TAG_PROPERTY: '',
        },
      ];
    }
    let displayByAvilableFilter = availabilityArray?.filter((e) => e.OCCUPIER == 'NULL' || e.OCCUPIER == '');
    const tube_transport_info_data = singlePropertyTemp.tube_transport_info;
    let tubeStationsObj = JSON.parse(tube_transport_info_data)['Nearest tube stations'];
    const tramTransportData = singlePropertyTemp.tramTransportInfo;
    const tramStationsList = JSON.parse(tramTransportData)['Nearest tram stations'];
    settotalSQFT(totSQFT);
    if (displayByAvilableFilter.length > 0) {
      setRowData(displayByAvilableFilter);
      updateAvailability(true);
    } else {
      updateAvailability(false);
      setRowData([
        {
          id: 0,
          SELECT_FIELD: false,
          FLOOR_NUMBER: '-',
          AVAILABLE_SPACE_SQFT: '-',
          NET_RENTABLE_AREA: '-',
          SERVICE_CHARGE: '-',
          OCCUPIER: '-',
          LEASE_EXPIRY_DATE: '-',
          LANDLORD: '-',
          TAG_PROPERTY: '',
        },
      ]);
    }
    setStackingRowData(availabilityArray);
    let tubeTransportArray = [];
    if (tubeStationsObj[0]) {
      tubeStationsObj.map((item) => {
        let object = {
          TUBE_CONNECTIONS: item['Connections'],
          NEAREST_TUBE_NAME: item['Station name'],
          TUBE_DISTANCE: item['Distance'],
          TUBE_WALKING_TIME: item['Walking time'],
        };
        tubeTransportArray.push(object);
      });
    }
    setTubeTransportRowData(tubeTransportArray);
    setTramRowData(tramStationsList);
    const train_transport_info_data = singlePropertyTemp.train_transport_info;
    let trainStationsObj = JSON.parse(train_transport_info_data)['Nearest national rail'];
    let trainTransportArray = [];
    if (trainStationsObj[0]) {
      updateRailTransport(true);
      trainStationsObj.map((item) => {
        let object = {
          TRAIN_CONNECTIONS: item['Connections'],
          NEAREST_TRAIN_STATION: item['Station name'],
          TRAIN_DISTANCE: item['Distance'],
          TRAIN_WALKING_TIME: item['Walking time'],
        };
        trainTransportArray.push(object);
      });
    } else {
      updateRailTransport(false);
    }
    setTrainTransportRowData(trainTransportArray);
    const breem_rating_data = singlePropertyTemp.breem_rating;
    const epc_rating_data = singlePropertyTemp.epc_rating;
    const wiredscore_rating_data = singlePropertyTemp.wiredscore_rating;
    let breem_rating_data_value = '';
    let epc_rating_data_value = '';
    let wiredscore_rating_data_value = '';

    if (breem_rating_data == null || breem_rating_data == 'null' || breem_rating_data == '') {
      breem_rating_data_value = '-';
    } else {
      breem_rating_data_value = breem_rating_data;
    }
    if (epc_rating_data == null || epc_rating_data == 'null' || epc_rating_data == '') {
      epc_rating_data_value = '-';
    } else {
      epc_rating_data_value = epc_rating_data;
    }
    if (wiredscore_rating_data == null || wiredscore_rating_data == 'null' || wiredscore_rating_data == '') {
      wiredscore_rating_data_value = '-';
    } else {
      wiredscore_rating_data_value = wiredscore_rating_data;
    }
    if (epc_rating_data_value == '-' && wiredscore_rating_data_value == '-' && breem_rating_data_value == '-') {
      updateRatings(false);
    } else {
      updateRatings(true);
    }
    const ratings_data = [
      {
        RATING_TYPE: 'EPC',
        RATING_LEVEL: epc_rating_data_value,
      },
      {
        RATING_TYPE: 'Wired Score',
        RATING_LEVEL: wiredscore_rating_data_value,
      },
      {
        RATING_TYPE: 'BREEAM',
        RATING_LEVEL: breem_rating_data_value,
      },
    ];
    setRatingRowData(ratings_data);
    const technical_specs_aka_amenities_data = singlePropertyTemp.technical_specs_aka_amenities;
    let itemArray = [];
    if (technical_specs_aka_amenities_data[0]) {
      updateTechSpec(true);
      technical_specs_aka_amenities_data[0].map((item) => {
        let object = {
          AMENITY_NAME: item.AMENITY_NAME,
          AMENITY_VALUE: item.AMENITY_VALUE,
        };
        itemArray.push(object);
      });
    } else {
      updateTechSpec(false);
      let object = {
        AMENITY_NAME: '-',
        AMENITY_VALUE: '-',
      };
      itemArray.push(object);
    }
    setTechSpecsData(itemArray);
  };

  const handleShortOrLongListing = async (object, props) => {
    setListedLoading(true);
    try {
      const reqObj = {
        isShortList: object === 'Short' ? false : true,
        hkey: hkey,
        propertyId: propertyIdVal,
      };
      const response = await axiosPrivate.put(`/short-list/status`, reqObj);
      if (response.status === 200) {
        handleStarPropertyNow(headingData.ADDRESS_LINE1, object);
        populateAgGridData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setListedLoading(false);
    }
  };

  const handleStarPropertyNow = (building_name, shortOrlong) => {
    handleStarProperty(building_name, shortOrlong);
  };

  const handleOnClickCallback = (isPDF, data, index) => {
    setImageOpen(isPDF);
    var imageData = [];
    if (data.length > 0) {
      data.map((item) => {
        if (!item.MEDIA_PATH.toLowerCase().includes('.pdf')) {
          imageData.push({
            src: item.MEDIA_PATH,
            caption: item.MEDIA_CAPTION || item.MEDIA_NAME,
          });
        }
      });
    }
    setImageViewerData(imageData);
    setCurrentImageIndex(index);
  };

  const defaultColDef = useMemo(() => {
    return {
      tooltipComponent: CustomTooltip,
    };
  }, []);

  const selectedAvilableValue = useMemo(() => {
    var selectdeArray = rowData?.filter((e) => e.SELECT_FIELD);
    var selectSum = selectdeArray?.reduce(function (acc, obj) {
      return acc + obj.AVAILABLE_SPACE_SQFT;
    }, 0);
    return selectSum || 0;
  }, [rowData]);

  const selectedStackingValue = useMemo(() => {
    var selectdeArray = stackingRowData?.filter((e) => e.SELECT_FIELD);
    var selectSum = selectdeArray?.reduce(function (acc, obj) {
      return acc + obj.AVAILABLE_SPACE_SQFT;
    }, 0);
    return selectSum || 0;
  }, [stackingRowData]);

  const getRowId = useMemo(() => {
    return (params) => {
      var data = params.data;
      return data.id;
    };
  }, []);
  const getContactsRowId = useMemo(() => {
    return (params) => {
      var data = params.data;
      return data.id;
    };
  }, []);

  const ClientIconRender = (tag) => {
    if (tag == 'CURRENT_SITE') {
      return <CurrentIcon style={{ alignItems: 'center', display: 'flex', marginTop: 2 }} />;
    } else if (tag == 'OFF_MARKET') {
      return <OffmarketIcon style={{ alignItems: 'center', display: 'flex', marginTop: 2 }} />;
    } else if (tag == 'UNDER_OFFER') {
      return <UnderofferIcon style={{ alignItems: 'center', display: 'flex', marginTop: 2 }} />;
    } else {
      return <></>;
    }
  };
  const fetchPropertyComments = async () => {
    try {
      const resp = await axiosPrivate.get(`/property-details/comments?hkey=${hkey}&propertyIdVal=${propertyIdVal}`);
      let commentsList = [];
      if (resp.data?.length) {
        commentsList = resp.data.map((el) => {
          var dateValue = moment(el?.TIME).format('DD/MM HH:mm');
          if (email?.toLowerCase() === el?.EMAIL?.toLowerCase())
            return { ...el, IsCurrentUser: true, COMMENTED_DT: dateValue };
          else return { ...el, IsCurrentUser: false, COMMENTED_DT: dateValue };
        });
      }
      setCommentsData(commentsList);
    } catch (error) {
      setWarningSnackbar({
        name: 'Error:',
        message: 'Unable to fetch Comments',
        severity: 'error',
      });
    } finally {
      setSending(false);
    }
  };

  const getUnReadCommentsCount = async () => {
    try {
      const resp = await axiosPrivate.get(`/property-details/unread-comments/${hubUserKey}/${hkey}/${propertyIdVal}`);
      if (resp.data?.length) {
        setCount(resp.data[0].COUNT_UNREAD);
      } else {
        setCount(0);
      }
    } catch (error) {
      setWarningSnackbar({
        name: 'Error:',
        message: 'Unable to fetch unread comments count',
        severity: 'error',
      });
    }
  };

  const InsertUserComments = async () => {
    const comment = comments.replaceAll('\\', '\\\\');
    setSending(true);
    try {
      const reqObj = {
        hkey: hkey,
        propertyIdVal: propertyIdVal,
        comment: comment.replaceAll("'", "''"),
        email: email,
      };
      const response = await axiosPrivate.post(`/property-details/comments`, reqObj);
      if (response.status === 200) {
        setComments('');
        fetchPropertyComments();
      }
    } catch (error) {
      setSending(false);
      setWarningSnackbar({
        name: 'Project Info:',
        message: 'Unable to insert Comments ',
        severity: 'error',
      });
    }
  };

  const handleOnComments = async () => {
    if (count > 0) {
      try {
        const reqObj = {
          hkey: hkey,
          hubUserKey: hubUserKey,
          propertyIdVal: propertyIdVal,
        };
        const response = await axiosPrivate.post(`/property-details/log-comments`, reqObj);
        if (response.status === 200) {
          if (response.data?.length) {
            setCount(0);
          }
        }
      } catch (error) {
        console.log('failed to read comments', error);
      }
    }
    setToggleButtonIndex(3);
  };

  const fetchGalleryDetails = async () => {
    try {
      const response = await axiosPrivate.get(`/property-details/gallery-details/${hkey}/${hwgtkey}/${propertyIdVal}`);
      if (response.status === 200) {
        let imagesData = [];
        let buildingPlanData = [];
        let documentsData = [];
        if (response.data.length > 0) {
          imagesData = response.data[0].MEDIA?.filter(
            (e) =>
              e.MEDIA_TYPE_DESC === 'Image' &&
              !(
                e.PROPERTY_IMAGE_TYPE_DESC === 'Floor Layout' ||
                e.PROPERTY_IMAGE_TYPE_DESC === 'Floor Plan' ||
                e.PROPERTY_IMAGE_TYPE_DESC === 'Flr-image'
              )
          );
          buildingPlanData = response.data[0].MEDIA?.filter(
            (e) =>
              e.MEDIA_TYPE_DESC === 'Image' &&
              (e.PROPERTY_IMAGE_TYPE_DESC === 'Floor Layout' ||
                e.PROPERTY_IMAGE_TYPE_DESC === 'Floor Plan' ||
                e.PROPERTY_IMAGE_TYPE_DESC === 'Flr-image')
          );
          documentsData = response.data[0].MEDIA?.filter((e) => e.MEDIA_TYPE_DESC !== 'Image');
        }
        imagesData.sort(function (x, y) {
          return x.PRIMARY_IMAGE_F === 'Y' ? -1 : y.PRIMARY_IMAGE_F === 'Y' ? 1 : 0;
        });
        setImages(imagesData);
        setBuildingPlans(buildingPlanData);
        setDocuments(documentsData);
      }
    } catch (error) {
      setWarningSnackbar({
        name: 'Property Info:',
        message: 'Unable to fetch data.',
        severity: 'error',
      });
    }
  };

  const fetchAvailableFloorSpace = async (reqObj) => {
    try {
      const response = await axiosPrivate.post(`/property-details/floor-space`, reqObj);
      if (response.status === 200) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #dadde9',
        minWidth: 320,
        marginRight: 10,
        borderRadius: 10,
      },
    })
  );
  return (
    <RightDrawer
      width='45%'
      variant={!assistantOpen ? 'permanent' : 'temporary'}
      open={!assistantOpen}
      openSecondary={true}
      hideBackdrop={true}
      onClose={onClose}
    >
      <div style={containerStyle}>
        {/* Address heading*/}
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '30px',
          }}
        >
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Grid style={addressHeadingStar}>
              {listedLoading ? (
                <CircularProgress color='inherit' size={30} sx={{ color: '#CBCDCB' }} />
              ) : headingData.SHORT_OR_LONG == 'Short' ? (
                <StarSelected
                  onClick={() => {
                    projectStatus !== 'Completed' && projectStatus !== 'Archived' && handleShortOrLongListing('Short');
                  }}
                />
              ) : (
                <StarNotSelected
                  onClick={() => {
                    projectStatus !== 'Completed' && projectStatus !== 'Archived' && handleShortOrLongListing('Long');
                  }}
                />
              )}
            </Grid>
            <div style={addressHeadingDetails}>
              <div style={addHeadingHolder}>
                <div style={addHeadingRow}>
                  <LightTooltip
                    enterNextDelay={1000}
                    enterDelay={1000}
                    title={headingData.ADDRESS_LINE1}
                    placement='right'
                    followCursor
                    style={{ margin: 0 }}
                  >
                    <ListItemText
                      id={headingData.ADDRESS_LINE1}
                      sx={{
                        maxWidth: '200px',
                      }}
                      className={headerStyle().buildingName}
                      secondaryTypographyProps={{
                        style: {
                          ...addHeadingBold,
                        },
                      }}
                      secondary={`${getFormattedValue(headingData.ADDRESS_LINE1, headingData.TAG_PROPERTY)}`}
                      onClick={() => {
                        const env = window.location.hostname === 'ukplatform-qa.cbre.com' ? 'uat.miq' : 'miq';
                        window.open(
                          `https://${env}.cbre.com/records/property/${headingData.PROPERTY_ID}/edit`,
                          '_blank'
                        );
                      }}
                    />
                  </LightTooltip>
                  <div style={addHeadingSideText}>{headingData.POSTAL_CODE}</div>
                  {headingData.TAG_PROPERTY && (
                    <div style={addHeadingIconRow}>{ClientIconRender(headingData.TAG_PROPERTY)}</div>
                  )}
                </div>
              </div>
              <div style={addHeadingMoreDetails}>
                <div style={addHeadingFirst}>
                  <div style={grayHeadingText}>Landlord:</div>
                  <LightTooltip
                    enterNextDelay={1000}
                    enterDelay={1000}
                    title={headingData?.LANDLORD_DATA?.at(0)}
                    placement='right'
                    followCursor
                    style={{ margin: 0 }}
                  >
                    <ListItemText
                      id={headingData?.LANDLORD_DATA?.at(0)}
                      sx={{
                        maxWidth: '200px',
                      }}
                      secondaryTypographyProps={{
                        style: {
                          ...addPaddIt,
                        },
                      }}
                      secondary={`${getFormattedValue(headingData?.LANDLORD_DATA?.at(0), false, 21)}`}
                    />
                  </LightTooltip>
                </div>
                {!((!showAvailability && !ifStackingPlan) || !showAvailability) && (
                  <div style={addHeadingMoreDetailsContent}>
                    <div style={grayHeadingText}>Available From:</div>{' '}
                    <div style={addPaddIt}>{headingData.AVAILABLE_FROM_DATA}</div>
                  </div>
                )}
                <div style={addHeadingLast}>
                  <div style={grayHeadingText}>Condition:</div>
                  <div style={addPaddIt}> {headingData.CONDITION_DATA}</div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid style={addressHeadingRank}>
            <div style={addressBoldNumber}>{Math.round(headingData.SCORE_DATA)}</div>
            <div style={addressRankNumber}>Rank {headingData.RANK_DATA}</div>
          </Grid>
        </Grid>
        {/* Tab Bar for  Specifications,Gallery,Comments } */}
        <div style={specificationTabStyle} className='ag-theme-alpine'>
          <List component={Stack} direction='row'>
            <ListItem
              style={toggleButtonIndex == 1 ? highlightSection : nonHighlightSection}
              button
              onClick={() => setToggleButtonIndex(1)}
            >
              <ListItemText primary='Specification' style={setFontHeader} />
            </ListItem>
            <ListItem
              style={toggleButtonIndex == 2 ? highlightSection : nonHighlightSection}
              button
              onClick={() => setToggleButtonIndex(2)}
            >
              <ListItemText primary='Gallery' style={setFontHeader} />
            </ListItem>
            {/* Contacts */}
            <ListItem
              style={toggleButtonIndex == 4 ? highlightSection : nonHighlightSection}
              button
              onClick={() => setToggleButtonIndex(4)}
            >
              <ListItemText primary='Contacts' style={setFontHeader} />
            </ListItem>
            <ListItem
              style={toggleButtonIndex == 3 ? highlightSection : nonHighlightSection}
              button
              onClick={() => handleOnComments()}
            >
              <ListItemText primary='Comments' style={setFontHeader} />

              {toggleButtonIndex !== 3 && count > 0 && (
                <Grid
                  style={{
                    position: 'absolute',
                    right: 18,
                    top: 3,
                    width: '20px',
                  }}
                >
                  <div
                    style={{
                      background: 'red',
                      color: 'white',
                      borderRadius: '50%',
                      padding: '2px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '13px',
                    }}
                  >
                    {count}
                  </div>
                </Grid>
              )}
            </ListItem>
          </List>
        </div>
        <div
          style={{
            height: window.innerHeight - 250,
            overflowX: 'auto',
            marginRight: toggleButtonIndex === 3 ? '-20px' : 0,
          }}
        >
          {/* Specifications */}
          {toggleButtonIndex == 1 ? (
            <>
              {/* Availability*/}
              <div style={headingRow} className='ag-theme-alpine'>
                <div style={headingTopic}>
                  {ifStackingPlan ? <div> Availability (stacking plan)</div> : <div>Availability</div>}
                </div>
                <div>
                  <FormControl>
                    <Select value={selected} onChange={handleChange} disableUnderline>
                      {values.map((value, index) => {
                        return (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {/* AG Grid Data Availability*/}
              {!showAvailabilityStacking && !showAvailability ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderBottom: '1px solid rgba(0, 63, 45, 0.1)',
                    borderTop: '1px solid rgba(0, 63, 45, 0.1)',
                    backgroundColor: 'rgb(245, 247, 247)',
                    height: '40px',
                  }}
                >
                  No record found
                </div>
              ) : (
                <div>
                  {ifStackingPlan ? (
                    showAvailabilityStacking ? (
                      <div>
                        <div style={gridStyle} className='ag-theme-alpine'>
                          <AgGridReact
                            deltaRowDataMode={true}
                            ref={stackingGridRef}
                            rowData={stackingRowData}
                            columnDefs={columnStackingDefs}
                            suppressRowClickSelection={true}
                            getRowId={getRowId}
                            domLayout={'autoHeight'}
                            tooltipShowDelay={0}
                            tooltipHideDelay={5000}
                            isRowSelectable={isRowSelectable}
                            suppressDragLeaveHidesColumns={true}
                          />
                        </div>
                        <div style={rowGray} className='ag-theme-alpine'>
                          <div style={totalSelected}>
                            <strong>Selected</strong>
                          </div>
                          <div style={totalSelectedText}>
                            <strong>{CurrencyFormatter(selectedStackingValue)} sq.ft.</strong> (out of a total of{' '}
                            {CurrencyFormatter(totalSQFT)} )
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderBottom: '1px solid rgba(0, 63, 45, 0.1)',
                          borderTop: '1px solid rgba(0, 63, 45, 0.1)',
                          backgroundColor: 'rgb(245, 247, 247)',
                          height: '40px',
                        }}
                      >
                        {/* No stacking availability to show */}
                        No record found
                      </div>
                    )
                  ) : showAvailability ? (
                    <div>
                      <div style={gridStyle} className='ag-theme-alpine'>
                        <AgGridReact
                          ref={gridRef}
                          rowData={rowData}
                          columnDefs={columnDefs}
                          suppressRowClickSelection={true}
                          getRowId={getRowId}
                          domLayout={'autoHeight'}
                          suppressDragLeaveHidesColumns={true}
                        />
                      </div>
                      <div style={rowGray} className='ag-theme-alpine'>
                        <div style={totalSelected}>
                          <strong>Selected</strong>
                        </div>
                        <div style={totalSelectedText}>
                          <strong>{CurrencyFormatter(selectedAvilableValue)} sq.ft.</strong> (out of a total of{' '}
                          {CurrencyFormatter(totalSQFT)} )
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderBottom: '1px solid rgba(0, 63, 45, 0.1)',
                        borderTop: '1px solid rgba(0, 63, 45, 0.1)',
                        backgroundColor: 'rgb(245, 247, 247)',
                        height: '40px',
                      }}
                    >
                      {/* No availability to show */}
                      No available space
                    </div>
                  )}
                </div>
              )}

              {/* Transport*/}
              <div style={headingRow} className='ag-theme-alpine'>
                <div style={headingTopic}>Transport</div>
              </div>
              {/* AG Grid Data Transport*/}
              {/* Tube Stations*/}
              {!!tubeTransportRowData.length ? (
                <div style={gridStyle} className='ag-theme-alpine'>
                  <AgGridReact
                    rowData={tubeTransportRowData}
                    columnDefs={tubeTransportColumnDefs}
                    domLayout={'autoHeight'}
                    suppressDragLeaveHidesColumns={true}
                    defaultColDef={defaultColDef}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                  ></AgGridReact>
                </div>
              ) : !!tramRowData.length ? (
                <div style={gridStyle} className='ag-theme-alpine'>
                  <AgGridReact
                    rowData={tramRowData}
                    columnDefs={tramTransportColumnDefs}
                    domLayout={'autoHeight'}
                    suppressDragLeaveHidesColumns={true}
                    defaultColDef={defaultColDef}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                  ></AgGridReact>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderBottom: '1px solid rgba(0, 63, 45, 0.1)',
                    borderTop: '1px solid rgba(0, 63, 45, 0.1)',
                    backgroundColor: 'rgb(245, 247, 247)',
                    height: '40px',
                    marginBottom: '20px',
                  }}
                >
                  {/* No tube station data to show */}
                  No tube or tram transport data to show
                </div>
              )}

              {/* Train Stations*/}
              {showRailTransport ? (
                <div style={gridStyle} className='ag-theme-alpine'>
                  <AgGridReact
                    rowData={trainTransportRowData}
                    columnDefs={trainTransportColumnDefs}
                    domLayout={'autoHeight'}
                    suppressDragLeaveHidesColumns={true}
                    defaultColDef={defaultColDef}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderBottom: '1px solid rgba(0, 63, 45, 0.1)',
                    borderTop: '1px solid rgba(0, 63, 45, 0.1)',
                    backgroundColor: 'rgb(245, 247, 247)',
                    height: '40px',
                  }}
                >
                  {/* No rail data to show */}
                  No train transport data to show
                </div>
              )}

              {/* Ratings*/}
              <div style={headingRow} className='ag-theme-alpine'>
                <div style={headingTopic}>Ratings </div>
              </div>
              {/* AG Grid Ratings*/}
              {showRatings ? (
                <div style={gridStyle} className='ag-theme-alpine'>
                  <AgGridReact
                    rowData={ratingRowData}
                    columnDefs={ratingColumnDefs}
                    domLayout={'autoHeight'}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderBottom: '1px solid rgba(0, 63, 45, 0.1)',
                    borderTop: '1px solid rgba(0, 63, 45, 0.1)',
                    backgroundColor: 'rgb(245, 247, 247)',
                    height: '40px',
                  }}
                >
                  {/* No rating details to show */}
                  No rating data to show
                </div>
              )}

              {/* Technical Specifications*/}
              <div style={headingRow} className='ag-theme-alpine'>
                <div style={headingTopic}>Technical Specifications</div>
              </div>
              {/* AG Grid Technical Specifications */}
              {showTechSpec ? (
                <div style={gridAmenityStyle} className='ag-theme-alpine'>
                  <AgGridReact
                    rowData={techSpecsData}
                    columnDefs={techSpecColumnDefs}
                    domLayout={'autoHeight'}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderBottom: '1px solid rgba(0, 63, 45, 0.1)',
                    borderTop: '1px solid rgba(0, 63, 45, 0.1)',
                    backgroundColor: 'rgb(245, 247, 247)',
                    height: '40px',
                  }}
                >
                  {/* No technical specification details to show */}
                  No property technical spec data to show
                </div>
              )}

              <p>&nbsp;</p>
            </>
          ) : toggleButtonIndex == 2 ? (
            /* Gallery */
            <Grid
              className='ag-theme-alpine'
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 20,
              }}
            >
              {!images?.length > 0 ? null : (
                <>
                  <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                    <CustomTypography
                      text={'Image gallery'}
                      fontsize='24px'
                      fontweight='400'
                      fontcolor='#1A1A1A'
                      lineheight='40px'
                      justifycontent='center'
                    />
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    {images.map((imageObject, index) => {
                      return (
                        <Grid
                          id={index}
                          item
                          md={4}
                          key={index}
                          style={{
                            paddingTop: 15,
                            paddingRight: 10,
                          }}
                        >
                          <ImageCardView
                            handleOnClickCallback={(isOpenModal) => handleOnClickCallback(isOpenModal, images, index)}
                            object={imageObject}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              )}
              {!buildingPlans?.length > 0 ? null : (
                <>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: images?.length > 0 ? 20 : 0,
                    }}
                  >
                    <CustomTypography
                      text={'Building plans'}
                      fontsize='24px'
                      fontweight='400'
                      fontcolor='#1A1A1A'
                      lineheight='40px'
                      justifycontent='center'
                    />
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    {buildingPlans.map((buildPlanObject, index) => {
                      return (
                        <Grid
                          id={index}
                          item
                          md={4}
                          key={index}
                          style={{
                            paddingTop: 15,
                            // paddingRight: (index + 1) % 3 === 0 ? 10 : 10,
                            paddingRight: 10,
                          }}
                        >
                          <ImageCardView
                            handleOnClickCallback={(isOpenModal) =>
                              handleOnClickCallback(isOpenModal, buildingPlans, index)
                            }
                            object={buildPlanObject}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              )}
              {!documents?.length > 0 ? null : (
                <>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: buildingPlans?.length > 0 || images?.length > 0 ? 20 : 0,
                    }}
                  >
                    <CustomTypography
                      text={'Other documents'}
                      fontsize='24px'
                      fontweight='400'
                      fontcolor='#1A1A1A'
                      lineheight='40px'
                      justifycontent='center'
                    />
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    {documents.map((documentObject, index) => {
                      return (
                        <Grid
                          id={index}
                          item
                          md={4}
                          key={index}
                          style={{
                            paddingTop: 15,
                            paddingRight: 10,
                          }}
                        >
                          <ImageCardView
                            handleOnClickCallback={(isOpenModal) =>
                              handleOnClickCallback(isOpenModal, documents, index)
                            }
                            object={documentObject}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              )}
            </Grid>
          ) : toggleButtonIndex == 4 ? (
            // Contacts
            <Grid
              className='ag-theme-alpine'
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 20,
                overflowX: 'none',
                // marginRight: '12px',
              }}
            >
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {/* <CustomTypography
                    text={'Contacts (CBRE)'}
                    fontsize='24px'
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    lineheight='40px'
                    justifycontent='center'
                  /> */}
                </Grid>
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowX: 'auto',
                    maxHeight: '100%',
                    paddingBottom: '2%',
                  }}
                >
                  {contactsRowData.length > 0 && contactsRowData ? (
                    <div style={gridStyle} className='ag-theme-alpine'>
                      <AgGridReact
                        getRowId={getContactsRowId}
                        getRowNodeId={(data) => data.id}
                        deltaRowDataMode={true}
                        ref={contactsGridRef}
                        rowData={contactsRowData}
                        columnDefs={propertyContactsArrayList}
                        suppressRowClickSelection={true}
                        rowSelection={'multiple'}
                        domLayout={'autoHeight'}
                        tooltipShowDelay={0}
                        tooltipHideDelay={5000}
                        suppressDragLeaveHidesColumns={true}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        margin: 10,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderBottom: '1px solid rgba(0, 63, 45, 0.1)',
                        borderTop: '1px solid rgba(0, 63, 45, 0.1)',
                        backgroundColor: 'rgb(245, 247, 247)',
                        height: '40px',
                        fontweight: '500',
                        fontfamily: 'Calibre-R',
                        fontSize: '16px',
                      }}
                    >
                      No contacts found
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            /* Comments  */
            <Grid
              className='ag-theme-alpine'
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 20,
                overflowX: 'none',
                marginRight: '12px',
              }}
            >
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <CustomTypography
                    text={'Comments'}
                    fontsize='24px'
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    lineheight='40px'
                    justifycontent='center'
                  />
                </Grid>
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowX: 'auto',
                    maxHeight: '30vh',
                    paddingBottom: '120px',
                  }}
                >
                  {commentsData?.length > 0 &&
                    commentsData?.map((obj) => {
                      if (obj?.IsCurrentUser) {
                        return (
                          <Grid
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              marginBottom: 5,
                              marginRight: '10px',
                            }}
                          >
                            <Grid
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                maxWidth: '85%',
                              }}
                            >
                              <Grid
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  marginRight: 20,
                                }}
                              >
                                <CustomTypography
                                  text={obj?.COMMENTED_DT}
                                  fontfamily='Calibre-R'
                                  fontsize='10px'
                                  lineheight='21px'
                                  fontweight='400'
                                  fontcolor='#767676'
                                />
                              </Grid>
                              <Grid
                                style={{
                                  backgroundColor: '#dfeeea',
                                  display: 'flex',
                                  borderTopLeftRadius: 25,
                                  borderBottomLeftRadius: 25,
                                  borderBottomRightRadius: 25,
                                  marginRight: 10,
                                }}
                              >
                                <Grid
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 30,
                                    wordBreak: 'break-word',
                                  }}
                                >
                                  <CustomTypography
                                    text={obj?.COMMENTS}
                                    fontfamily='Calibre-R'
                                    fontsize='14px'
                                    lineheight='21px'
                                    fontweight='400'
                                    fontcolor='#1A1A1A'
                                  />
                                </Grid>
                                <Grid
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-start',
                                    paddingTop: 15,
                                    paddingRight: 15,
                                  }}
                                >
                                  <ContextMenuIcon />
                                </Grid>
                              </Grid>
                            </Grid>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Grid
                                    style={{
                                      display: 'flex',
                                      width: 320,
                                    }}
                                  >
                                    <Grid
                                      style={{
                                        display: 'flex',
                                        padding: 20,
                                        flexDirection: 'column',
                                        width: '100%',
                                      }}
                                    >
                                      <CustomTypography
                                        text={obj?.CMT_USERNAME}
                                        fontfamily='Calibre-R'
                                        fontsize='20px'
                                        lineheight='24px'
                                        fontweight='400'
                                        fontcolor='#000000'
                                      />
                                      <Grid
                                        style={{
                                          backgroundColor: '#e6ecea',
                                          height: 1,
                                          marginTop: 14,
                                          marginBottom: 12,
                                        }}
                                      />
                                      <Grid container style={{ display: 'flex' }}>
                                        <Grid
                                          item
                                          xs={3}
                                          sm={3}
                                          md={3}
                                          style={{
                                            display: 'flex',
                                            alignContent: 'center',
                                            justifyContent: 'flex-start',
                                          }}
                                        >
                                          <CustomTypography
                                            text='E-mail'
                                            fontfamily='Calibre-R'
                                            fontsize='12px'
                                            lineheight='24px'
                                            fontweight='400'
                                            fontcolor='#767676'
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={9}
                                          sm={9}
                                          md={9}
                                          style={{
                                            display: 'flex',
                                            alignContent: 'center',
                                            justifyContent: 'flex-end',
                                          }}
                                        >
                                          <CustomTypography
                                            text={obj?.EMAIL}
                                            fontfamily='Calibre-R'
                                            fontsize='12px'
                                            lineheight='24px'
                                            fontweight='500'
                                            fontcolor='#1A1A1A'
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </React.Fragment>
                              }
                            >
                              <Grid
                                style={{
                                  backgroundColor: '#003F2D',
                                  width: 32,
                                  height: 34,
                                  borderRadius: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginTop: 25,
                                }}
                              >
                                <CustomTypography
                                  text={obj?.INITIAL}
                                  fontfamily='Calibre-R'
                                  fontsize='14px'
                                  lineheight='21px'
                                  fontweight='400'
                                  fontcolor='#FFFFFF'
                                />
                              </Grid>
                            </HtmlTooltip>
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-start',
                              marginBottom: 5,
                            }}
                          >
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Grid
                                    style={{
                                      display: 'flex',
                                      width: 320,
                                    }}
                                  >
                                    <Grid
                                      style={{
                                        display: 'flex',
                                        padding: 20,
                                        flexDirection: 'column',
                                        width: '100%',
                                      }}
                                    >
                                      <CustomTypography
                                        text={obj?.CMT_USERNAME}
                                        fontfamily='Calibre-R'
                                        fontsize='20px'
                                        lineheight='24px'
                                        fontweight='400'
                                        fontcolor='#000000'
                                      />
                                      <Grid
                                        style={{
                                          backgroundColor: '#e6ecea',
                                          height: 1,
                                          marginTop: 14,
                                          marginBottom: 12,
                                        }}
                                      />
                                      <Grid container style={{ display: 'flex' }}>
                                        <Grid
                                          item
                                          xs={3}
                                          sm={3}
                                          md={3}
                                          style={{
                                            display: 'flex',
                                            alignContent: 'center',
                                            justifyContent: 'flex-start',
                                          }}
                                        >
                                          <CustomTypography
                                            text='E-mail'
                                            fontfamily='Calibre-R'
                                            fontsize='12px'
                                            lineheight='24px'
                                            fontweight='400'
                                            fontcolor='#767676'
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={9}
                                          sm={9}
                                          md={9}
                                          style={{
                                            display: 'flex',
                                            alignContent: 'center',
                                            justifyContent: 'flex-end',
                                          }}
                                        >
                                          <CustomTypography
                                            text={obj?.EMAIL}
                                            fontfamily='Calibre-R'
                                            fontsize='12px'
                                            lineheight='24px'
                                            fontweight='500'
                                            fontcolor='#1A1A1A'
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </React.Fragment>
                              }
                            >
                              <Grid
                                style={{
                                  backgroundColor: '#003F2D',
                                  width: 32,
                                  height: 34,
                                  borderRadius: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginTop: 25,
                                }}
                              >
                                <CustomTypography
                                  text={obj?.INITIAL}
                                  fontfamily='Calibre-R'
                                  fontsize='14px'
                                  lineheight='21px'
                                  fontweight='400'
                                  fontcolor='#FFFFFF'
                                />
                              </Grid>
                            </HtmlTooltip>

                            <Grid
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                maxWidth: '85%',
                              }}
                            >
                              <Grid
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  marginLeft: 20,
                                }}
                              >
                                <CustomTypography
                                  text={obj?.COMMENTED_DT}
                                  fontfamily='Calibre-R'
                                  fontsize='10px'
                                  lineheight='21px'
                                  fontweight='400'
                                  fontcolor='#767676'
                                />
                              </Grid>
                              <Grid
                                style={{
                                  backgroundColor: '#f2f4f6',
                                  display: 'flex',
                                  borderTopRightRadius: 25,
                                  borderBottomLeftRadius: 25,
                                  borderBottomRightRadius: 25,
                                  marginLeft: 10,
                                }}
                              >
                                <Grid
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 30,
                                    wordBreak: 'break-word',
                                  }}
                                >
                                  <CustomTypography
                                    text={obj?.COMMENTS}
                                    fontfamily='Calibre-R'
                                    fontsize='14px'
                                    lineheight='21px'
                                    fontweight='400'
                                    fontcolor='#1A1A1A'
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      }
                    })}
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
        {isLodding && <LoadingOverlayComponent open={isLodding} />}
        {warningSnackbar && (
          <WarningMessagelert
            open={true}
            setOpen={() => setWarningSnackbar(null)}
            severity={warningSnackbar.severity}
            name={warningSnackbar.name}
            message={warningSnackbar.message}
          />
        )}
      </div>
      {toggleButtonIndex === 3 && (
        <Grid
          style={{
            position: 'absolute',
            bottom: 0,
            width: '93%',
            padding: '15px 25px',
            boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.05)',
            zIndex: 100,
            background: '#fff',
          }}
        >
          <Grid
            style={
              {
                // width: '100%',
              }
            }
          >
            <CustomTextField
              multiline={true}
              rows={3}
              maxRows={3}
              id='comment'
              label={'Leave comment...' + '\xa0'}
              style={{
                fontFamily: 'Calibre-R',
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '14px',
                marginBottom: 10,
              }}
              type='text'
              fullWidth
              value={comments}
              error={comments?.length > 500 ? true : false}
              onChange={(event) => setComments(event.target.value)}
              variant='standard'
            />
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {comments?.length > 500 && (
                  <CustomTypography
                    text={`Character limit exceeded`}
                    fontsize='11px'
                    fontweight='400'
                    fontcolor='#FF543E'
                    marginTop='45px'
                    lineheight='16px'
                    marginRight='10px'
                    justifycontent='flex-start'
                  />
                )}
              </Grid>

              <CustomTypography
                text={`${comments?.length} / 500`}
                fontsize='11px'
                fontweight='400'
                fontcolor='#b6b6b6'
                marginTop='45px'
                lineheight='16px'
                marginRight='10px'
                justifycontent='flex-end'
              />
            </Grid>
          </Grid>
          <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={comments == '' || comments?.length > 500 || sending ? true : false}
              className={
                comments == '' || comments?.length > 500 || sending ? classes.createdisablebtn : classes.createbtn
              }
              onClick={() => InsertUserComments()}
            >
              {!sending ? (
                <SendIcon color={comments == '' || comments?.length > 500 ? '#949494' : '#FFFFFF'} />
              ) : (
                <CircularProgress color='inherit' size={18} sx={{ color: '#949494' }} />
              )}
              <CustomTypography
                text={sending ? 'Sending...' : 'Send'}
                fontfamily='Calibre-SB'
                fontsize='16px'
                lineheight='16px'
                fontweight='500'
                fontcolor={comments == '' || comments?.length > 500 || sending ? '#949494' : '#FFFFFF'}
                marginLeft={10}
              />
            </Button>
          </Grid>
        </Grid>
      )}
      {imageOpen && (
        <ImageViewer
          open={imageOpen}
          imgsData={imageViewerData}
          indexValue={cureentImageIndex}
          onClick={() => {
            setImageOpen(false);
          }}
        />
      )}
    </RightDrawer>
  );
};
export default PropertyDetailsDisplay;
