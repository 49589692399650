import React, { useMemo, useState } from 'react';
import RightDrawer from './right_drawer';
import InfiniteGrid from './custom_infinite_table';
import { Grid } from '@material-ui/core';
import CustomTypography from '../utils/text';
import CancelIcon from '../icons/close_icon';
import { useSelector } from 'react-redux';
import { Buffer } from 'buffer';
import LoadingComponent from '../components/lodding_component';
import WarningMessage from '../components/warning_snackbar';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const SelectProperty = ({
  open,
  setOpenDrawer,
  propertiesData,
  setPropertyHkey,
  setPropertiesData,
  isDataLoading,
  setSnackbar,
}) => {
  const hubUserKey = useSelector((state) => state.mainReducer.hubUser);
  const [isLoading, setIsLoading] = useState(false);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  const containerStyle = useMemo(
    () => ({
      width: window.innerWidth / 1.7 - 132,
      paddingRight: 30,
      paddingTop: 30,
      paddingLeft: 30,
      fontFamily: 'Calibre-R',
      height: window.innerHeight,
    }),
    []
  );

  const handleSelectProperty = (value) => {
    setIsLoading(true);
    setPropertiesData((prev) => {
      let updated = [...prev];
      const index = prev.findIndex((el) => el.PROPERTY_CODE === value.PROPERTY_CODE);
      if (index !== -1) {
        const item = { ...updated[index] };
        item.ACTIVE = false;
        updated[index] = item;
        updated = updated.map((el) => {
          if (el.PROPERTY_CODE !== value.PROPERTY_CODE) return { ...el, ACTIVE: true };
          else return { ...el };
        });
      }
      return [...updated];
    });
    fetchSingelProperty(value);
  };

  const fetchSingelProperty = async (value) => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/market-overview/single-property/${value.PROPERTY_CODE}`);
      if (response.status === 200 && response.data.length > 0) {
        const propertyhkey = Buffer.from(response.data[0].HKEY_HUB_PROPERTY.data).toString('hex').toUpperCase();
        const reqObj = {
          HKEY_HUB_USER: hubUserKey,
          HKEY_HUB_PROPERTY: propertyhkey,
        };
        selectedSingelProperty(reqObj, value);
      }
    } catch (error) {
      setIsLoading(false);
      console.log('Error while fetching the single property details...!', error);
    }
  };

  const selectedSingelProperty = async (reqObj, value) => {
    try {
      const response = await axiosPrivate.post(`/benchmarking/market-overview/selected-property`, reqObj);
      if (response.status === 200 && response.data.length > 0) {
        setPropertyHkey(reqObj.HKEY_HUB_PROPERTY);
        setSnackbar({
          name: value.BUILDING_NAME ? value.BUILDING_NAME : value.ADDRESS_LINE1,
          message: ' has been added.',
          severity: 'success',
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log('Error while fetching the selected single property details...!', error);
    } finally {
      setOpenDrawer(false);
      setIsLoading(false);
    }
  };

  return (
    <RightDrawer
      open={open}
      openSecondary={true}
      onClose={() => setOpenDrawer(false)}
      variant='temporary'
    >
      <div style={containerStyle}>
        <>
          <Grid container>
            <Grid
              item
              md={6}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <CustomTypography
                text={`Select property`}
                fontsize='36px'
                fontweight='400'
                fontcolor='#1A1A1A'
                lineheight='40px'
                marginRight='10px'
              />
            </Grid>
            <Grid
              item
              md={6}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <CancelIcon
                style={{ paddingRight: '30' }}
                onClick={() => setOpenDrawer(false)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'row',
              height: '94%',
            }}
          >
            <InfiniteGrid
              data={propertiesData}
              handleAddProperty={handleSelectProperty}
              isLoading={isDataLoading}
            />
          </Grid>
        </>

        {isLoading && <LoadingComponent open={isLoading} />}
        {warningSnackbar && (
          <WarningMessage
            open={true}
            setOpen={() => setWarningSnackbar(null)}
            severity={warningSnackbar.severity}
            name={warningSnackbar.name}
            message={warningSnackbar.message}
          />
        )}
      </div>
    </RightDrawer>
  );
};
export default SelectProperty;
