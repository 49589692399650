import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapZoomIcon from '../icons/map_fullscreen_icon';
import IconAI from '../icons/IconAI';
import TubestationIcon from '../icons/tubestation_icon';
import PropTypeIcon from '../icons/property_type_list_icon';
import MapStyleIcon from '../icons/map_style_icon';
import { useSelector, useDispatch } from 'react-redux';
import Menu from '@mui/material/Menu';
import CustomCheckbox from './custom_checkbox';
import TubeImage from '../img/tubestation.png';
import FlexImage from '../img/flexspaces.png';
import FlexImageHover from '../img/fleximage-hover.png';
import GraduateIcon from '../img/Graduate.png';
import { Button } from '@material-ui/core';
import CustomTypography from '../utils/text';
import CustomRadioButton from '../components/radio_button';
import { Grid, Checkbox } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import RangeSlider from '../components/double_slider_componets';
import AIAssistant from './AIAssistant';
import WarningMessage from '../components/warning_snackbar';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import CloseIcon from '../icons/close_icon';
import { EPC_RATINGS } from '../utils/constants';
import flexSpacesTooltip from '../utils/flexSpacesTooltip';
import LeaseView from './LeaseView';
import './mapBox.css';
import LoadingOverlayComponent from '../components/lodding_component';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOXGL_TOKEN;
const MapBoxComponent = ({
  handleClick,
  value,
  handleClickProperty,
  handleOnHoverPropertyID,
  handleListedCallback = false,
  boundsArray,
  clearMapfilter = false,
  modifyMapFilter,
  propertiesData = [],
  completeData,
}) => {
  const mapContainerRef = useRef(null);
  const map = useRef(null);
  const dispatch = useDispatch();
  const isMapFullScrren = useSelector((state) => state.mainReducer.isMapFullScrren);
  const [tubestationAnchorEl, setTubestationAnchorEl] = useState(0);
  const [mapStyleMenuAnchorEl, setMapStyleMenuAnchorEl] = useState(0);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [tubeStationMenu, setTubestationMenu] = useState(false);
  const [listMenu, setListMenu] = useState(false);
  const [mapStyleMenu, setMapStyleMenu] = useState(false);
  const [tubestationData, setTubeStationData] = useState([]);
  const [tubesList, setTubesList] = useState([]);
  const [flexSpacesData, setFlexSpacesData] = useState([]);
  const [flexSpacesList, setFlexSpacesList] = useState([]);
  const [mapStyle, setMapStyle] = useState('light-v10');
  const [displayViewedProp, setDisplayViewedProp] = useState(false);
  const [displayGymProp, setDisplayGymProp] = useState(false);
  const [displayCateringProp, setDisplayCateringProp] = useState(false);
  const [displayTerraceProp, setDisplayTerraceProp] = useState(false);
  const [displayOnSiteShowerProp, setDisplayOnSiteShowerProp] = useState(false);
  const [displayBrandingRightsProp, setDisplayBrandingRightsProp] = useState(false);
  const [displayRaisedFloorsProp, setDisplayRaisedFloorsProp] = useState(false);
  const [displayLockersProp, setDisplayLockersProp] = useState(false);
  const [displaySingleLetBuildingProp, setDisplaySingleLetBuildingProp] = useState(false);
  const [displayAllElectricBuildingProp, setDisplayAllElectricBuildingProp] = useState(false);
  const [displayNaturallyVentilatedProp, setDisplayNaturallyVentilatedProp] = useState(false);
  const [displaySelfProp, setDisplaySelfProp] = useState(false);
  const [totalScore, setTotalScore] = useState([0, 100]);
  const [transportScore, setTransportScore] = useState([0, 100]);
  const [buildingScore, setBuildingScore] = useState([0, 100]);
  const [locationScore, setLocationScore] = useState([0, 100]);
  const [esgScore, setEsgScore] = useState([0, 100]);
  const [scoringList, setScoringList] = useState({});
  const [deliverabilityScore, setdeliverabilityScore] = useState([0, 100]);
  const [custCriteriaScore, setCustCriteriaScore] = useState([0, 100]);
  const [chatId, setChatId] = useState();
  const [chatLoaded, setChatLoaded] = useState(false);
  const [AIData, setAIData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leaseViewOpen, setLeaseViewOpen] = useState(false);
  const [leaseId, setLeaseId] = useState();
  let checkedColor = '#003f2d';
  let unCheckColor = '#767676';
  const axiosPrivate = useAxiosPrivate();
  const axiosAIAssitant = useAxiosPrivate('aiasstant');
  const [epcRatingList, setEpcRatingList] = useState(EPC_RATINGS);
  const mapCenterValues = useSelector((state) => state.mainReducer.mapCenter);
  const { mapZoomLevel, assistantOpen } = useSelector((state) => state.mainReducer);
  const selected = useSelector((state) => state.mainReducer);
  const hkey = useSelector((state) => state.mainReducer.hkey);
  const hubUser = useSelector((state) => state.mainReducer.hubUser);
  const hoveredStateId = useRef(null);
  const clickStateId = useRef(null);
  const [listItems, setListItems] = useState([
    { id: 1, name: 'Shortlisted properties', isChecked: false },
    { id: 2, name: 'Longlisted properties', isChecked: false },
  ]);
  const mapStyleItems = [
    { id: 1, name: 'Light', value: 'light-v10' },
    { id: 2, name: 'Satellite', value: 'satellite-v9' },
    { id: 3, name: 'Streets', value: 'streets-v11' },
  ];
  const [mapItems, setMapItems] = useState([
    { id: 1, name: 'Tube Stations', isChecked: false },
    { id: 2, name: 'Flex spaces', isChecked: false },
  ]);

  const [mapAiCoordinates, setMapAiCoordinates] = useState({});
  const [lastPrompt, setLastPrompt] = useState();
  const tubeImage = new Image();
  tubeImage.src = TubeImage;

  const flexImage = new Image();
  flexImage.src = FlexImage;

  const flexImageHover = new Image();
  flexImageHover.src = FlexImageHover;

  const universityImage = new Image();
  universityImage.src = GraduateIcon;

  useEffect(() => {
    if (clearMapfilter) {
      handleClearAll();
      modifyMapFilter(false);
    }
  }, [clearMapfilter]);

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/light-v10',
      center: mapCenterValues,
      zoom: mapZoomLevel,
    });
    dispatch({ type: 'SET_ASSISTANT_OPEN', payload: false });
  }, []);

  const fetchMainCategoryScores = async () => {
    try {
      const response = await axiosPrivate.get(`/mapbox/main-category-scores/${hkey}`);
      if (response.status === 200 && response.data.length > 0) {
        fetchMapFilterValues(response.data[0]);
        setScoringList(response.data[0]);
      }
    } catch (error) {
      console.log('Error while fetcing the main scores...!', error);
    }
  };

  const fetchMapFilterValues = async (scoresObject) => {
    try {
      const response = await axiosPrivate.get(`/mapbox/map-filters?hkey=${hkey}&hubUser=${hubUser}`);
      if (response.status === 200) {
        handleCallbackMapfilterValues(response.data, scoresObject);
      }
    } catch (error) {
      console.log('Error while fetcing the map filters...!', error);
    }
  };

  const handleCallbackMapfilterValues = (responseArray, scoresObject) => {
    if (!responseArray.length || clearMapfilter) {
      setTransportScore([0, scoresObject?.TRANSPORT || 100]);
      setBuildingScore([0, scoresObject?.BUILDING || 100]);
      setLocationScore([0, scoresObject?.LOCAL_AMENITY || 100]);
      setEsgScore([0, scoresObject?.ESG || 100]);
      setdeliverabilityScore([0, scoresObject?.DELIVERABILITY_RISK || 100]);
      setCustCriteriaScore([0, scoresObject?.CUSTOM_WEIGHTAGE || 100]);
      return;
    }
    const response = responseArray[0];
    setDisplayViewedProp(!!response?.VIEWED && response?.VIEWED !== 'false');
    setDisplaySelfProp(!!response?.SELF_CONTAINED && response?.SELF_CONTAINED !== 'false');
    setDisplayGymProp(!!response?.GYM && response?.GYM !== 'false');
    setDisplayCateringProp(!!response?.CATERING && response?.CATERING !== 'false');
    setDisplayTerraceProp(!!response?.TERRACE && response?.TERRACE !== 'false');
    setDisplayOnSiteShowerProp(!!response?.SHOWERS && response?.SHOWERS !== 'false');
    setDisplayBrandingRightsProp(!!response?.BRIGHTS && response?.BRIGHTS !== 'false');
    setDisplayRaisedFloorsProp(!!response?.RFLOORS && response?.RFLOORS !== 'false');
    setDisplayLockersProp(!!response?.LOCKERS && response?.LOCKERS !== 'false');
    setDisplaySingleLetBuildingProp(!!response?.SINGLE_LET_BUILD && response?.SINGLE_LET_BUILD !== 'false');
    setDisplayAllElectricBuildingProp(!!response?.ALL_ELEC_BUILD && response?.ALL_ELEC_BUILD !== 'false');
    setDisplayNaturallyVentilatedProp(!!response?.NAT_VENTILATED && response?.NAT_VENTILATED !== 'false');
    setTotalScore([response?.MIN_TOTAL_SCORE || 0, response?.MAX_TOTAL_SCORE || 100]);
    setTransportScore([response?.MIN_TRANSPORT || 0, response?.MAX_TRANSPORT || scoresObject.TRANSPORT]);
    setBuildingScore([response?.MIN_BUILDING || 0, response?.MAX_BUILDING || scoresObject.BUILDING]);
    setLocationScore([response?.MIN_LOCAL_AMENITY || 0, response?.MAX_LOCAL_AMENITY || scoresObject.LOCAL_AMENITY]);
    setEsgScore([response?.MIN_ESG || 0, response?.MAX_ESG || scoresObject.ESG]);
    setdeliverabilityScore([response?.MIN_DEL_RISK || 0, response?.MAX_DEL_RISK || scoresObject.DELIVERABILITY_RISK]);
    setCustCriteriaScore([
      response?.MIN_CUST_CRITERIA || 0,
      response?.MAX_CUST_CRITERIA || scoresObject.CUSTOM_WEIGHTAGE,
    ]);
    const dataSet = [
      {
        id: 1,
        name: 'Shortlisted properties',
        isChecked: response.SHORT_LISTED,
      },
      {
        id: 2,
        name: 'Longlisted properties',
        isChecked: response.LONG_LISTED,
      },
    ];
    setListItems(dataSet);
    dispatch({
      type: 'SET_IS_SHORTLISTED',
      payload: dataSet.find((el) => el.id === 1).isChecked && !dataSet.find((el) => el.id === 2).isChecked,
    });
    if (response?.EPC_RATING?.length > 0) {
      let EpcRatingArray = response?.EPC_RATING.split(',');
      if (EpcRatingArray.length > 0) {
        var epcRatingDataset = [...epcRatingList];
        EpcRatingArray.map((e) => {
          let index = epcRatingDataset.findIndex((itemObj) => itemObj.title == e);
          if (index !== -1) {
            epcRatingDataset[index] = {
              ...epcRatingDataset[index],
              isChecked: true,
            };
          }
        });
        setEpcRatingList(epcRatingDataset);
      }
    }
  };

  const circleProperties = {
    'circle-radius': [
      'case',
      ['==', ['feature-state', 'click'], true],
      18,
      ['==', ['feature-state', 'hover'], true],
      15,
      10,
    ],
    'circle-stroke-width': [
      'case',
      ['==', ['feature-state', 'click'], true],
      5,
      ['==', ['feature-state', 'hover'], true],
      4,
      0.5,
    ],
    'circle-stroke-opacity': [
      'case',
      ['==', ['feature-state', 'click'], true],
      1,
      ['==', ['feature-state', 'hover'], true],
      1,
      0,
    ],
    'circle-opacity': [
      'case',
      ['==', ['feature-state', 'click'], true],
      1,
      ['==', ['feature-state', 'hover'], true],
      0.75,
      0.75,
    ],
  };

  useEffect(() => {
    fetchMainCategoryScores();
  }, []);

  useEffect(() => {
    if (!map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/light-v10',
      center: mapCenterValues,
      zoom: mapZoomLevel,
    });

    map.current.setStyle('mapbox://styles/mapbox/' + mapStyle);
    map.current.on('load', () => {
      map.current.addImage('tube', tubeImage);
      map.current.addImage('flex', flexImage);
      map.current.addImage('flexhover', flexImageHover);
      map.current.addImage('university', universityImage);

      map.current.addSource('propertiesSource', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: propertiesData,
        },
        generateId: true,
      });
      map.current.addLayer({
        id: 'properties',
        type: 'circle',
        source: 'propertiesSource',
        paint: {
          'circle-color': [
            'match',
            ['get', 'group'],
            'shortlisted',
            '#D2785A',
            'CURRENT_SITE',
            '#4DA6E4',
            'OFF_MARKET',
            '#BAA6CF',
            'UNDER_OFFER',
            '#F39DAD',
            'longlisted',
            '#032842',
            /* Default color if no match */ '#888888',
          ],
          'circle-stroke-color': [
            'case',
            ['==', ['feature-state', 'click'], true],
            '#FFFFFF',
            ['==', ['feature-state', 'hover'], true],
            '#FFFFFF',
            '#032842',
          ],
          ...circleProperties,
        },
      });

      //tube station
      map.current.addSource('tubeStation', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: tubestationData,
        },
      });
      const image = new Image(35, 35);
      image.src = TubeImage;
      map.current.addImage('tube_image', image);
      map.current.addLayer({
        id: 'tubeStations',
        source: 'tubeStation',
        type: 'symbol',
        layout: {
          'icon-image': 'tube',
          'icon-size': 0.8,
        },
      });

      // Flex Spaces
      map.current.addSource('flexSpace', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: flexSpacesData,
        },
      });
      map.current.addLayer({
        id: 'flexSpaces',
        source: 'flexSpace',
        type: 'symbol',
        layout: {
          'icon-image': 'flex',
          'icon-size': 0.8,
        },
      });

      map.current.addSource('AIDataSource', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: AIData,
        },
      });

      if (lastPrompt?.toLowerCase().includes('uni')) {
        map.current.addLayer({
          id: 'ai_data',
          source: 'AIDataSource',
          type: 'symbol',
          layout: {
            'icon-image': 'university',
            'icon-size': 1,
          },
        });
      } else
        map.current.addLayer({
          id: 'ai_data',
          type: 'circle',
          source: 'AIDataSource',
          paint: {
            'circle-color': '#4ADEDE',
            'circle-stroke-color': [
              'case',
              ['==', ['feature-state', 'click'], true],
              '#FFFFFF',
              ['==', ['feature-state', 'hover'], true],
              '#FFFFFF',
              '#4ADEDE',
            ],
            ...circleProperties,
          },
        });
    });
    const nav = new mapboxgl.NavigationControl({
      visualizePitch: true,
    });
    map.current.addControl(nav, 'top-left');
    let icon = document.getElementById('icon');
    icon.addEventListener('click', OnCLickFunction, false);
    let tubeIcon = document.getElementById('tube_icon');
    tubeIcon.addEventListener('click', handleOnClick, false);
    let propTypeIcon = document.getElementById('prop_type_icon');
    propTypeIcon.addEventListener('click', handleOnListMenuClick, false);
    let mapTypeIcon = document.getElementById('map_type_icon');
    mapTypeIcon.addEventListener('click', handleOnClickMapStyle, false);

    return () => {
      map.current.remove();
      icon.removeEventListener('click', OnCLickFunction);
      tubeIcon.removeEventListener('click', handleOnClick);
      propTypeIcon.removeEventListener('click', handleOnListMenuClick);
      mapTypeIcon.removeEventListener('click', handleOnClickMapStyle);
    };
  }, [value, mapStyle, lastPrompt, AIData]);

  useEffect(() => {
    if (!map.current) return;
    let propId = false;
    map.current.on('load', () => {
      let quakeID = null;
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        width: '300px',
        height: '300px',
        font: 'Calibre-R',
        backgroud: '#FFFFFF',
        borderRadius: '10px',
      });

      map.current.on('click', 'properties', (e) => {
        dispatch({ type: 'SET_ASSISTANT_OPEN', payload: false });
        propId = true;
        let propertyClickId = null;
        e.preventDefault();
        onClickPropertyDot(e.features[0].properties.PropertyKeyIds);
        if (propertyClickId || propertyClickId == 0) {
          map.current.setFeatureState(
            {
              source: 'propertiesSource',
              id: propertyClickId,
            },
            {
              click: false,
            }
          );
        }

        const index = getFocusedIndices();

        if (index !== -1)
          map.current.setFeatureState(
            {
              source: 'propertiesSource',
              id: index,
            },
            {
              click: false,
            }
          );

        propertyClickId = e.features[0].id;
        dispatch({
          type: 'SET_PROPERTIES_DATA',
          payload: {
            PropertyKeyIds: e.features[0].properties.PropertyKeyIds,
            propertyClickId: JSON.parse(e.features[0].properties.PropertyKeyIds).propertyId,
          },
        });
        map.current.setFeatureState(
          {
            source: 'propertiesSource',
            id: propertyClickId,
          },
          {
            click: true,
          }
        );
      });
      map.current.on('mouseenter', 'properties', (e) => {
        map.current.getCanvas().style.cursor = 'pointer';
        const coordinates = e.features[0].geometry.coordinates.slice();
        const description = e.features[0].properties.description;
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
        if (quakeID) {
          map.current.removeFeatureState({
            source: 'propertiesSource',
            id: quakeID,
          });
        }
        quakeID = e.features[0].id;
        map.current.setFeatureState(
          {
            source: 'propertiesSource',
            id: quakeID,
          },
          {
            hover: true,
          }
        );
      });

      map.current.on('mouseleave', 'properties', () => {
        map.current.getCanvas().style.cursor = '';
        popup.remove();
        if (quakeID || quakeID == 0) {
          map.current.setFeatureState(
            {
              source: 'propertiesSource',
              id: quakeID,
            },
            {
              hover: false,
            }
          );
        }
        quakeID = null;
      });
    });
    map.current.on('click', (e) => {
      let propertyClickId = null;
      if (!propId) {
        const index = getFocusedIndices();
        if (index !== -1)
          map.current.setFeatureState(
            {
              source: 'propertiesSource',
              id: index,
            },
            {
              click: false,
            }
          );

        if (e.defaultPrevented === false) {
          dispatch({
            type: 'SET_PROPERTIES_DATA',
            payload: {
              PropertyKeyIds: '',
              propertyClickId: '',
            },
          });
          onClickPropertyDot('');

          if (propertyClickId || propertyClickId == 0) {
            map.current.setFeatureState(
              {
                source: 'propertiesSource',
                id: propertyClickId,
              },
              {
                click: false,
              }
            );
          }

          propertyClickId = null;
        }
      }
      propId = false;
    });
    map.current.on('render', afterChangeComplete);
    function afterChangeComplete() {
      if (!map.current.loaded()) {
        return;
      }

      if (selected.PropertyKeyIds) {
        const index = getFocusedIndices();
        const features = map.current.querySourceFeatures('propertiesSource');
        features.forEach((feature) => {
          map.current.setFeatureState(
            {
              source: 'propertiesSource',
              id: feature.id,
            },
            {
              click: false,
            }
          );
        });
        if (index !== -1)
          map.current.setFeatureState(
            {
              source: 'propertiesSource',
              id: index,
            },
            {
              click: true,
            }
          );
      }

      map.current.off('render', afterChangeComplete);
    }
  }, [propertiesData, selected.propertyClickId, lastPrompt, value]);

  useEffect(() => {
    if (!map?.current) return;
    map.current?.getSource('propertiesSource')?.setData({
      type: 'FeatureCollection',
      features: [...propertiesData].filter(
        (el) => !AIData.find((e) => e.properties?.cbre_property?.attributes?.property_code === el.PROPERTY_CODE)
      ),
    });
    if (boundsArray?.length == 2) {
      map.current?.fitBounds(boundsArray);
    }
  }, [propertiesData, propertiesData.length, boundsArray, value]);

  useEffect(() => {
    if (!map?.current) return;
    map.current?.getSource('tubeStation')?.setData({
      type: 'FeatureCollection',
      features: [...tubestationData],
    });
  }, [tubestationData, value]);

  useEffect(() => {
    if (!map?.current) return;
    map.current?.getSource('flexSpace')?.setData({
      type: 'FeatureCollection',
      features: [...flexSpacesData],
    });
  }, [flexSpacesData, value]);

  useEffect(() => {
    if (!map?.current) return;
    let propId = false;
    map.current.on('load', () => {
      if (!lastPrompt?.length) return;
      const aipopup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        width: '300px',
        height: '300px',
        font: 'Calibre-R',
        backgroud: '#FFFFFF',
        borderRadius: '10px',
      });
      map.current.on('mouseenter', 'ai_data', (e) => {
        map.current.getCanvas().style.cursor = 'pointer';
        if (!e.features.length > 0) return;
        if (hoveredStateId.current !== null) {
          map.current.setFeatureState({ source: 'AIDataSource', id: hoveredStateId.current }, { hover: false });
        }
        hoveredStateId.current = e.features[0].id;
        map.current.setFeatureState({ source: 'AIDataSource', id: hoveredStateId.current }, { hover: true });

        const coordinates = e.features[0].geometry.coordinates.slice();
        const description = e.features[0].properties.description;
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        aipopup.setLngLat(coordinates).setHTML(description).addTo(map.current);
      });

      map.current.on('mouseleave', 'ai_data', () => {
        if (hoveredStateId.current !== null) {
          map.current.setFeatureState({ source: 'AIDataSource', id: hoveredStateId.current }, { hover: false });
          hoveredStateId.current = null;
        }
        map.current.getCanvas().style.cursor = '';
        aipopup.remove();
      });
      map.current.on('click', 'ai_data', (e) => {
        if (lastPrompt.toLowerCase().includes('lease')) {
          setLeaseViewOpen(true);
          setLeaseId(e.features[0].id);
        }
        propId = true;
        e.preventDefault();
        if (e.features.length > 0) {
          if (clickStateId.current !== null) {
            map.current.setFeatureState({ source: 'AIDataSource', id: clickStateId.current }, { click: false });
          }
          clickStateId.current = e.features[0].id;
          map.current.setFeatureState({ source: 'AIDataSource', id: clickStateId.current }, { click: true });
        }
      });
      map.current.on('click', () => {
        if (!propId) {
          map.current.setFeatureState({ source: 'AIDataSource', id: hoveredStateId.current }, { hover: false });
          map.current.setFeatureState({ source: 'AIDataSource', id: clickStateId.current }, { click: false });
        }
        propId = false;
      });
    });
  }, [AIData, lastPrompt, value]);

  useEffect(() => {
    if (!map?.current) return;
    map.current.on('load', () => {
      const flexpopup = new mapboxgl.Popup({
        className: 'custom-popup',
        closeButton: false,
        closeOnClick: false,
      });
      // Track the feature being hovered
      map.current.on('mouseenter', 'flexSpaces', (e) => {
        // Set the icon image for the hovered feature
        map.current.setLayoutProperty('flexSpaces', 'icon-image', [
          'match',
          ['id'],
          e.features[0].id,
          'flexhover',
          'flex',
        ]);
        map.current.getCanvas().style.cursor = 'pointer';
        const coordinates = e.features[0].geometry.coordinates.slice();
        const description = e.features[0].properties.description;
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        flexpopup.setLngLat(coordinates).setHTML(description).addTo(map.current);
      });

      map.current.on('mouseleave', 'flexSpaces', (e) => {
        map.current.setLayoutProperty('flexSpaces', 'icon-image', 'flex'); // Revert to the original icon size
        map.current.getCanvas().style.cursor = '';
        flexpopup.remove();
      });
    });
  }, [flexSpacesData, value]);

  const handleClearAll = () => {
    setListItems((prev) => prev.map((el) => ({ ...el, isChecked: false })));
    setDisplayViewedProp(false);
    setDisplayGymProp(false);
    setDisplaySelfProp(false);
    setDisplayCateringProp(false);
    setDisplayTerraceProp(false);
    setDisplayOnSiteShowerProp(false);
    setDisplayBrandingRightsProp(false);
    setDisplayRaisedFloorsProp(false);
    setDisplayLockersProp(false);
    setDisplaySingleLetBuildingProp(false);
    setDisplayAllElectricBuildingProp(false);
    setDisplayNaturallyVentilatedProp(false);
    setTotalScore([0, 100]);
    setTransportScore([0, scoringList.TRANSPORT]);
    setBuildingScore([0, scoringList.BUILDING]);
    setLocationScore([0, scoringList.LOCAL_AMENITY]);
    setEsgScore([0, scoringList.ESG]);
    setdeliverabilityScore([0, scoringList.DELIVERABILITY_RISK]);
    setCustCriteriaScore([0, scoringList.CUSTOM_WEIGHTAGE]);
    setEpcRatingList(EPC_RATINGS);
  };

  const getFocusedIndices = () => {
    const index = propertiesData.findIndex(
      (el) => el.properties.PropertyKeyIds.propertyId === selected.propertyClickId
    );

    return index;
  };

  useEffect(() => {
    if (!map.current) return;
    map.current.on('move', () => {
      dispatch({
        type: 'SET_MAP_ZOOM',
        payload: map.current.getZoom().toFixed(2),
      });
      dispatch({
        type: 'SET_MAP_CENTER',
        payload: [map.current.getCenter().lng.toFixed(4), map.current.getCenter().lat.toFixed(4)],
      });
    });
  });
  useEffect(() => {
    if (handleOnHoverPropertyID?.data?.length > 0 && map?.current && propertiesData.length > 0) {
      var data = handleOnHoverPropertyID.data;
      if (!map.current.getLayer('hover-property-id')) {
        map.current.addSource('hover-property', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: data,
          },
          generateId: true,
        });
        map.current.addLayer({
          id: 'hover-property-id',
          type: 'circle',
          source: 'hover-property',
          paint: {
            'circle-radius': 15,
            'circle-color': handleOnHoverPropertyID.circleColor,
            'circle-stroke-color': '#ffffff',
            'circle-stroke-width': 3,
            'circle-opacity': 0.75,
          },
        });
        const onHoverPopp = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          width: '300px',
          height: '300px',
          font: 'Calibre-R',
          backgroud: '#FFFFFF',
          borderRadius: '10px',
        });
        const coordinates = data[0].geometry.coordinates.slice();
        const description = data[0].properties.description;
        onHoverPopp.setLngLat(coordinates).setHTML(description).addTo(map.current);
      }
    } else {
      if (map.current.getLayer('hover-property-id')) {
        map.current.removeLayer('hover-property-id');
      }
      if (map.current.getSource('hover-property')) {
        map.current.removeSource('hover-property');
      }
      const popups = document.getElementsByClassName('mapboxgl-popup');
      if (popups.length) {
        popups[0].remove();
      }
      return;
    }
  }, [handleOnHoverPropertyID]);

  const onClickPropertyDot = (propertyObject) => {
    if (propertyObject) {
      handleClickProperty(true, propertyObject);
    } else {
      handleClickProperty(false, '');
    }
  };

  const OnCLickFunction = () => {
    handleZoom();
  };

  const handleZoom = () => {
    handleClick();
    handleClickProperty(false, '');
  };

  useEffect(() => {
    if (mapItems[0].isChecked) fetchTubestationData();
    else setTubeStationData([]);
  }, [mapItems[0].isChecked]);

  useEffect(() => {
    if (mapItems[1].isChecked) fetchFlexSpaces();
    else setFlexSpacesData([]);
  }, [mapItems[1].isChecked]);

  const fetchTubestationData = async () => {
    if (tubesList.length) {
      setTubeStationData([...tubesList]);
      return;
    }
    try {
      setLoading(true);
      const response = await axiosPrivate.get(`/mapbox/tube-stations`);
      if (response.status === 200 && response.data.length > 0) {
        const tubes = response.data.map((item) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [item.LONGITUDE, item.LATITUDE],
          },
        }));
        setTubesList(tubes);
        setTubeStationData(tubes);
      }
    } catch (error) {
      console.log('Error while fetching the tube stations...!', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFlexSpaces = async () => {
    if (flexSpacesList.length) {
      setFlexSpacesData([...flexSpacesList]);
      return;
    }
    try {
      setLoading(true);
      const response = await axiosPrivate.get(`/mapbox/flex-spaces`);
      if (response.status === 200 && response.data.length > 0) {
        const flexSpaces = response.data.map((item, index) => ({
          id: index,
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [item.LONGITUDE, item.LATITUDE],
          },
          properties: { description: flexSpacesTooltip(item) },
        }));
        setFlexSpacesData(flexSpaces);
        setFlexSpacesList(flexSpaces);
      }
    } catch (error) {
      console.log('Error while fetching the flex spaces...!', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnClick = (event) => {
    setTubestationAnchorEl(event.currentTarget);
    setTubestationMenu(true);
    setListMenu(false);
  };
  const handleClose = () => {
    setTubestationAnchorEl(null);
    setTubestationMenu(false);
  };
  const handleOnListMenuClick = (event) => {
    handleCloseListMenu();
  };
  const handleCloseListMenu = () => {
    setListMenu((prev) => !prev);
    let object = {
      PROJECT_HKEY: hkey,
      USER_HKEY: hubUser,
    };
    object['VIEWED'] = displayViewedProp;
    object['SELF_CONTAINED'] = displaySelfProp;
    object['GYM'] = displayGymProp;
    object['CATERING'] = displayCateringProp;
    object['TERRACE'] = displayTerraceProp;
    object['SHOWERS'] = displayOnSiteShowerProp;
    object['BRIGHTS'] = displayBrandingRightsProp;
    object['RFLOORS'] = displayRaisedFloorsProp;
    object['LOCKERS'] = displayLockersProp;
    object['SINGLE_LET_BUILD'] = displaySingleLetBuildingProp;
    object['ALL_ELEC_BUILD'] = displayAllElectricBuildingProp;
    object['NAT_VENTILATED'] = displayNaturallyVentilatedProp;
    object['SHORT_LISTED'] = listItems[0].isChecked;
    object['LONG_LISTED'] = listItems[1].isChecked;
    if (totalScore[0] !== 0 || totalScore[1] !== 100) {
      object['MIN_TOTAL_SCORE'] = totalScore[0];
      object['MAX_TOTAL_SCORE'] = totalScore[1];
    }
    if (transportScore[0] !== 0 || transportScore[1] !== 100) {
      object['MIN_TRANSPORT'] = transportScore[0];
      object['MAX_TRANSPORT'] = transportScore[1];
    }
    if (buildingScore[0] !== 0 || buildingScore[1] !== 100) {
      object['MIN_BUILDING'] = buildingScore[0];
      object['MAX_BUILDING'] = buildingScore[1];
    }
    if (locationScore[0] !== 0 || locationScore[1] !== 100) {
      object['MIN_LOCAL_AMENITY'] = locationScore[0];
      object['MAX_LOCAL_AMENITY'] = locationScore[1];
    }
    if (esgScore[0] !== 0 || esgScore[1] !== 100) {
      object['MIN_ESG'] = esgScore[0];
      object['MAX_ESG'] = esgScore[1];
    }
    if (deliverabilityScore[0] !== 0 || deliverabilityScore[1] !== 100) {
      object['MIN_DEL_RISK'] = deliverabilityScore[0];
      object['MAX_DEL_RISK'] = deliverabilityScore[1];
    }
    if (custCriteriaScore[0] != 0 || custCriteriaScore[1] != 100) {
      object['MIN_CUST_CRITERIA'] = custCriteriaScore[0];
      object['MAX_CUST_CRITERIA'] = custCriteriaScore[1];
    }
    var epcSelectdeDataSet = epcRatingList.filter((e) => e.isChecked == true);
    if (epcSelectdeDataSet.length > 0) {
      object['EPC_RATING'] = epcSelectdeDataSet.map((epcObj) => epcObj.title.replace("'", "''''")).join(',');
    }
    applyFilters(object);
  };

  const applyFilters = async (object) => {
    try {
      await axiosPrivate.put(`/mapbox/map-filters`, object);
    } catch (error) {
      console.log('Error while fetching the filter query...!', error);
    }
  };
  const handleOnClickMapStyle = (event) => {
    setMapStyleMenuAnchorEl(event.currentTarget);
    setMapStyleMenu(true);
    setListMenu(false);
  };
  const handleCloseMapStyle = () => {
    setMapStyleMenuAnchorEl(null);
    setMapStyleMenu(false);
  };

  const assistantHandler = async () => {
    dispatch({ type: 'SET_ASSISTANT_OPEN', payload: !assistantOpen });
    if (assistantOpen) {
      setChatId();
      // map.current?.getSource('AIDataSource')?.setData({
      //   type: 'FeatureCollection',
      //   features: [],
      // });
    } else {
      setChatLoaded(true);
      try {
        const response = await axiosAIAssitant.post(`/create_chat_session`);
        if (response.status === 200) setChatId(response.data.chat_id);
      } catch (error) {
        console.log(error);
      } finally {
        setChatLoaded(false);
      }
    }
    setListMenu(false);
  };
  const handleSelectListMenu = (object, Index) => {
    let dataSet = [...listItems];
    if (Index !== -1) {
      dataSet[Index] = { ...dataSet[Index], isChecked: !object.isChecked };
    }
    dispatch({
      type: 'SET_IS_SHORTLISTED',
      payload: dataSet.find((el) => el.id === 1).isChecked && !dataSet.find((el) => el.id === 2).isChecked,
    });
    setListItems(dataSet);
  };
  const handleSelectMapItemsMenu = (object, Index) => {
    let dataSet = [...mapItems];
    if (Index !== -1) {
      dataSet[Index] = { ...dataSet[Index], isChecked: !object.isChecked };
    }
    setMapItems(dataSet);
  };
  const handleChangeEpcRatings = (object, Index) => {
    let dataSet = [...epcRatingList];
    if (Index !== -1) {
      dataSet[Index] = { ...dataSet[Index], isChecked: !object.isChecked };
    }
    setEpcRatingList(dataSet);
  };
  const handleSelectMapStyle = (object) => {
    setMapStyle(object.value);
  };

  useEffect(() => {
    if (handleListedCallback) {
      let epcArray = epcRatingList?.filter((e) => e.isChecked == true);
      let obj = {
        shortList: listItems[0]?.isChecked,
        longList: listItems[1]?.isChecked,
        displayViewedProp: displayViewedProp,
        gymprops: displayGymProp,
        selfprops: displaySelfProp,
        cateringprops: displayCateringProp,
        terraceprops: displayTerraceProp,
        onSiteShowerProps: displayOnSiteShowerProp,
        brandingRightsProps: displayBrandingRightsProp,
        raisedFloorsProps: displayRaisedFloorsProp,
        lockersProps: displayLockersProp,
        singleLetBuildingProps: displaySingleLetBuildingProp,
        allElectricBuildingProps: displayAllElectricBuildingProp,
        naturallyVentilatedProps: displayNaturallyVentilatedProp,
        totalScore: totalScore,
        transportScore: transportScore,
        buildingScore: buildingScore,
        locationScore: locationScore,
        esgScore: esgScore,
        deliverabilityScore: deliverabilityScore,
        custCriteriaScore,
        epcRatingArray: epcArray,
      };
      handleListedCallback(obj);
    }
  }, [
    listItems,
    displayGymProp,
    displayCateringProp,
    displaySelfProp,
    displayViewedProp,
    displayTerraceProp,
    displayOnSiteShowerProp,
    displayBrandingRightsProp,
    displayRaisedFloorsProp,
    displayLockersProp,
    displaySingleLetBuildingProp,
    displayAllElectricBuildingProp,
    displayNaturallyVentilatedProp,
    totalScore,
    transportScore,
    buildingScore,
    locationScore,
    esgScore,
    deliverabilityScore,
    custCriteriaScore,
    epcRatingList,
    completeData,
  ]);
  useEffect(() => {
    dispatch({
      type: 'SET_PROPERTIES_DATA',
      payload: {
        PropertyKeyIds: '',
        propertyClickId: '',
      },
    });
  }, []);

  useEffect(() => {
    if (!map?.current) return;
    const bounds = map.current.getBounds();
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();
    const east = northEast.lng;
    const west = southWest.lng;
    const north = northEast.lat;
    const south = southWest.lat;
    setMapAiCoordinates({ north, south, west, east });
  }, [mapZoomLevel]);

  return (
    <div>
      <div
        ref={mapContainerRef}
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 70,
          paddingLeft: 10,
          // boxShadow: '0px 0px 5px red',
          right: value ? 0 : '45vw',
        }}
      />
      <div
        id='prop_type_icon'
        style={{
          position: 'absolute',
          top: '10px',
          right: value ? '11%' : '54.5%',
          cursor: 'pointer',
        }}
      >
        <PropTypeIcon />
      </div>
      <div
        id='map_type_icon'
        style={{
          position: 'absolute',
          top: '10px',
          right: value ? '8%' : '51.5%',
          cursor: 'pointer',
        }}
      >
        <MapStyleIcon />
      </div>
      <div
        id='tube_icon'
        style={{
          position: 'absolute',
          top: '10px',
          right: value ? '5%' : '48.5%',
          cursor: 'pointer',
        }}
      >
        <TubestationIcon />
      </div>
      <div
        id='icon'
        style={{
          position: 'absolute',
          top: '10px',
          right: value ? '2%' : '45.5%',
          cursor: 'pointer',
        }}
      >
        <MapZoomIcon />
      </div>
      <div
        id='ai-assistant'
        style={{
          position: 'absolute',
          bottom: 20,
          right: value ? '50%' : '63%',
          transform: value ? 'translate(50%)' : 'translate(0%)',
        }}
      >
        {/* <Button
          style={{
            background: '#fff',
            color: '#003F2D',
            size: 16,
            paddingLeft: 15,
            paddingRight: 15,
            border: '1px solid #003F2D',
          }}
          onClick={assistantHandler}
        >
          {assistantOpen ? <CloseIcon color='#003F2D' /> : <IconAI />}
          <div style={{ marginLeft: 10 }}>{assistantOpen ? 'Close' : 'Launch'} AI Assistant</div>
        </Button> */}
      </div>
      <Menu
        style={{
          marginTop: 50,
        }}
        PaperProps={{
          style: {
            width: '200px',
          },
        }}
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={tubestationAnchorEl}
        open={tubeStationMenu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {mapItems.map((item, itemindex) => {
          return (
            <div
              key={item.name}
              style={{
                paddingLeft: 22,
                marginBottom: '7px',
              }}
            >
              <CustomCheckbox
                id={item.name}
                label={item.name}
                checked={item.isChecked}
                onChange={() => handleSelectMapItemsMenu(item, itemindex)}
              />
            </div>
          );
        })}
      </Menu>
      <Dialog
        variant='permanent'
        hideBackdrop={true}
        open={listMenu}
        style={{
          marginTop: 50,
          overflowX: 'auto',
        }}
        PaperProps={{
          style: {
            width: '640px',
            minHeight: '87.5vh',
            marginRight: isMapFullScrren ? '0%' : '-27.5%',
            marginLeft: isMapFullScrren ? '50%' : '0%',
            paddingBottom: '30px',
          },
        }}
        onClose={handleCloseListMenu}
      >
        <DialogTitle
          style={{
            maxHeight: '50px',
            paddingLeft: '30px',
            paddingRight: '45px',
            borderBottom: '1px solid #003f2d1a',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid
            item
            xs={6}
            md={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <CustomTypography
              text='Map Filters'
              fontsize='24px'
              fontweight='400'
              lineheight='40px'
              fontcolor='#1A1A1A'
            />
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-end',
              cursor: 'pointer',
            }}
            onClick={() => handleClearAll()}
          >
            <CustomTypography
              text='Clear all'
              fontsize='16px'
              fontweight='500'
              lineheight='16px'
              fontcolor='#003F2D'
              // marginTop='0px'
            />
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: 0, margin: 0, marginRight: '10px' }}>
          <Grid
            id='map_filter'
            style={{
              display: 'flex',
              flexDirection: 'column',
              // height: '91%',
              marginTop: '17px',
              overflowX: 'hidden',
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
          >
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: 22,
              }}
            >
              <CustomTypography
                text='Display'
                fontsize='24px'
                fontweight='400'
                lineheight='40px'
                fontcolor='#1A1A1A'
                marginTop='0px'
                marginBottm='8px'
              />
              {listItems.map((item, itemindex) => {
                return (
                  <div
                    key={item.name}
                    style={{
                      marginBottom: '7px',
                    }}
                  >
                    <CustomCheckbox
                      id={item.name}
                      label={item.name}
                      checked={item.isChecked}
                      onChange={() => handleSelectListMenu(item, itemindex)}
                    />
                  </div>
                );
              })}
            </Grid>
            <Grid
              container
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: 22,
                marginTop: '15px',
              }}
            >
              <CustomTypography
                text='EPC Ratings'
                fontsize='24px'
                fontweight='400'
                lineheight='40px'
                fontcolor='#1A1A1A'
                marginTop='0px'
                marginBottm='5px'
              />
              <Grid container style={{ display: 'flex', flexDirection: 'row', width: '75%' }}>
                {epcRatingList.map((item, epxIndex) => {
                  return (
                    <Grid
                      item
                      md={3}
                      key={epxIndex + item.title}
                      style={{
                        maxHeight: 40,
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <CustomCheckbox
                        id={item.title}
                        label={item.title}
                        checked={item.isChecked}
                        onChange={() => handleChangeEpcRatings(item, epxIndex)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: 22,
                paddingRight: 22,
              }}
            >
              <Grid item xs={12} style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexDirection: 'column',
                    }}
                  >
                    <CustomTypography
                      text='Scores'
                      fontfamily='Calibre-R'
                      fontsize='24px'
                      fontweight='400'
                      lineheight='40px'
                      fontcolor='#1A1A1A'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <CustomTypography
                      text='Total Score'
                      fontfamily='Calibre-R'
                      fontsize='12px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#1A1A1A'
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  minWidth: '105%',
                }}
              >
                <RangeSlider score={totalScore} handleCallabck={(value) => setTotalScore(value)} maxValue={100} />
              </Grid>
              {scoringList.TRANSPORT > 0 ? (
                <>
                  <Grid item xs={12} md={12}>
                    <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <CustomTypography
                          text='Transport'
                          fontfamily='Calibre-R'
                          fontsize='12px'
                          fontweight='400'
                          lineheight='16px'
                          fontcolor='#1A1A1A'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      minWidth: '105%',
                    }}
                  >
                    <RangeSlider
                      score={transportScore}
                      handleCallabck={(value) => setTransportScore(value)}
                      maxValue={scoringList.TRANSPORT}
                    />
                  </Grid>
                </>
              ) : null}

              {scoringList.BUILDING > 0 ? (
                <>
                  <Grid item xs={12} md={12} style={{}}>
                    <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <CustomTypography
                          text='Building'
                          fontfamily='Calibre-R'
                          fontsize='12px'
                          fontweight='400'
                          lineheight='16px'
                          fontcolor='#1A1A1A'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      minWidth: '105%',
                    }}
                  >
                    <RangeSlider
                      score={buildingScore}
                      handleCallabck={(value) => setBuildingScore(value)}
                      maxValue={scoringList.BUILDING}
                    />
                  </Grid>
                </>
              ) : null}
              {scoringList.LOCAL_AMENITY > 0 ? (
                <>
                  <Grid item xs={12} md={12} style={{}}>
                    <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <CustomTypography
                          text='Location & Amenity'
                          fontfamily='Calibre-R'
                          fontsize='12px'
                          fontweight='400'
                          lineheight='16px'
                          fontcolor='#1A1A1A'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      minWidth: '105%',
                    }}
                  >
                    <RangeSlider
                      score={locationScore}
                      handleCallabck={(value) => setLocationScore(value)}
                      maxValue={scoringList.LOCAL_AMENITY}
                    />
                  </Grid>
                </>
              ) : null}
              {scoringList.ESG > 0 ? (
                <>
                  <Grid item xs={12} md={12} style={{}}>
                    <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <CustomTypography
                          text='ESG'
                          fontfamily='Calibre-R'
                          fontsize='12px'
                          fontweight='400'
                          lineheight='16px'
                          fontcolor='#1A1A1A'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      minWidth: '105%',
                    }}
                  >
                    <RangeSlider
                      score={esgScore}
                      handleCallabck={(value) => setEsgScore(value)}
                      maxValue={scoringList.ESG}
                    />
                  </Grid>
                </>
              ) : null}
              {scoringList.DELIVERABILITY_RISK > 0 ? (
                <>
                  <Grid item xs={12} md={12} style={{}}>
                    <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <CustomTypography
                          text='Deliverability & Risk'
                          fontfamily='Calibre-R'
                          fontsize='12px'
                          fontweight='400'
                          lineheight='16px'
                          fontcolor='#1A1A1A'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      minWidth: '105%',
                    }}
                  >
                    <RangeSlider
                      score={deliverabilityScore}
                      handleCallabck={(value) => setdeliverabilityScore(value)}
                      maxValue={scoringList.DELIVERABILITY_RISK}
                    />
                  </Grid>
                </>
              ) : null}
              {!!scoringList.CUSTOM_WEIGHTAGE && (
                <>
                  <Grid item xs={12} md={12} style={{}}>
                    <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <CustomTypography
                          text='Custom Criteria'
                          fontfamily='Calibre-R'
                          fontsize='12px'
                          fontweight='400'
                          lineheight='16px'
                          fontcolor='#1A1A1A'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      minWidth: '105%',
                    }}
                  >
                    <RangeSlider
                      score={custCriteriaScore}
                      handleCallabck={(value) => setCustCriteriaScore(value)}
                      maxValue={scoringList.CUSTOM_WEIGHTAGE}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid style={{ display: 'flex', flexDirection: 'column' }}>
              <Grid item xs={12} md={12} style={{ paddingBottom: 5, paddingTop: 25 }}>
                <Grid
                  container
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingLeft: 22,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexDirection: 'column',
                    }}
                  >
                    <CustomTypography
                      text='Other parameters'
                      fontfamily='Calibre-R'
                      fontsize='24px'
                      fontweight='400'
                      lineheight='40px'
                      fontcolor='#1A1A1A'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginLeft: 12,
                    }}
                  >
                    <Checkbox
                      checked={displayViewedProp}
                      checkedIcon={<MdCheckBox color={checkedColor} />}
                      icon={<MdCheckBoxOutlineBlank color={unCheckColor} />}
                      onChange={() => {
                        setDisplayViewedProp(!displayViewedProp);
                      }}
                    />
                    <CustomTypography
                      text='Property has been viewed'
                      fontfamily='Calibre-R'
                      fontsize='16px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#1A1A1A'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginLeft: 12,
                    }}
                  >
                    <Checkbox
                      checked={displaySelfProp}
                      checkedIcon={<MdCheckBox color={checkedColor} />}
                      icon={<MdCheckBoxOutlineBlank color={unCheckColor} />}
                      onChange={() => {
                        setDisplaySelfProp(!displaySelfProp);
                      }}
                    />
                    <CustomTypography
                      text='Self contained'
                      fontfamily='Calibre-R'
                      fontsize='16px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#1A1A1A'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginLeft: 12,
                    }}
                  >
                    <Checkbox
                      checked={displayGymProp}
                      checkedIcon={<MdCheckBox color={checkedColor} />}
                      icon={<MdCheckBoxOutlineBlank color={unCheckColor} />}
                      onChange={() => {
                        setDisplayGymProp(!displayGymProp);
                      }}
                    />
                    <CustomTypography
                      text='On-site dedicated gym'
                      fontfamily='Calibre-R'
                      fontsize='16px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#1A1A1A'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginLeft: 12,
                    }}
                  >
                    <Checkbox
                      checked={displayCateringProp}
                      checkedIcon={<MdCheckBox color={checkedColor} />}
                      icon={<MdCheckBoxOutlineBlank color={unCheckColor} />}
                      onChange={() => {
                        setDisplayCateringProp(!displayCateringProp);
                      }}
                    />
                    <CustomTypography
                      text='On-site catering'
                      fontfamily='Calibre-R'
                      fontsize='16px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#1A1A1A'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginLeft: 12,
                    }}
                  >
                    <Checkbox
                      checked={displayTerraceProp}
                      checkedIcon={<MdCheckBox color={checkedColor} />}
                      icon={<MdCheckBoxOutlineBlank color={unCheckColor} />}
                      onChange={() => {
                        setDisplayTerraceProp(!displayTerraceProp);
                      }}
                    />
                    <CustomTypography
                      text='Terraces'
                      fontfamily='Calibre-R'
                      fontsize='16px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#1A1A1A'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginLeft: 12,
                    }}
                  >
                    <Checkbox
                      checked={displayOnSiteShowerProp}
                      checkedIcon={<MdCheckBox color={checkedColor} />}
                      icon={<MdCheckBoxOutlineBlank color={unCheckColor} />}
                      onChange={() => {
                        setDisplayOnSiteShowerProp(!displayOnSiteShowerProp);
                      }}
                    />
                    <CustomTypography
                      text='On-site shower'
                      fontfamily='Calibre-R'
                      fontsize='16px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#1A1A1A'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginLeft: 12,
                    }}
                  >
                    <Checkbox
                      checked={displayBrandingRightsProp}
                      checkedIcon={<MdCheckBox color={checkedColor} />}
                      icon={<MdCheckBoxOutlineBlank color={unCheckColor} />}
                      onChange={() => {
                        setDisplayBrandingRightsProp(!displayBrandingRightsProp);
                      }}
                    />
                    <CustomTypography
                      text='Branding rights'
                      fontfamily='Calibre-R'
                      fontsize='16px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#1A1A1A'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginLeft: 12,
                    }}
                  >
                    <Checkbox
                      checked={displayRaisedFloorsProp}
                      checkedIcon={<MdCheckBox color={checkedColor} />}
                      icon={<MdCheckBoxOutlineBlank color={unCheckColor} />}
                      onChange={() => {
                        setDisplayRaisedFloorsProp(!displayRaisedFloorsProp);
                      }}
                    />
                    <CustomTypography
                      text='Raised floors'
                      fontfamily='Calibre-R'
                      fontsize='16px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#1A1A1A'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginLeft: 12,
                    }}
                  >
                    <Checkbox
                      checked={displayLockersProp}
                      checkedIcon={<MdCheckBox color={checkedColor} />}
                      icon={<MdCheckBoxOutlineBlank color={unCheckColor} />}
                      onChange={() => {
                        setDisplayLockersProp(!displayLockersProp);
                      }}
                    />
                    <CustomTypography
                      text='Lockers'
                      fontfamily='Calibre-R'
                      fontsize='16px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#1A1A1A'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginLeft: 12,
                    }}
                  >
                    <Checkbox
                      checked={displaySingleLetBuildingProp}
                      checkedIcon={<MdCheckBox color={checkedColor} />}
                      icon={<MdCheckBoxOutlineBlank color={unCheckColor} />}
                      onChange={() => {
                        setDisplaySingleLetBuildingProp(!displaySingleLetBuildingProp);
                      }}
                    />
                    <CustomTypography
                      text='Single-let building'
                      fontfamily='Calibre-R'
                      fontsize='16px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#1A1A1A'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginLeft: 12,
                    }}
                  >
                    <Checkbox
                      checked={displayAllElectricBuildingProp}
                      checkedIcon={<MdCheckBox color={checkedColor} />}
                      icon={<MdCheckBoxOutlineBlank color={unCheckColor} />}
                      onChange={() => {
                        setDisplayAllElectricBuildingProp(!displayAllElectricBuildingProp);
                      }}
                    />
                    <CustomTypography
                      text='All-Electric building'
                      fontfamily='Calibre-R'
                      fontsize='16px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#1A1A1A'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginLeft: 12,
                    }}
                  >
                    <Checkbox
                      checked={displayNaturallyVentilatedProp}
                      checkedIcon={<MdCheckBox color={checkedColor} />}
                      icon={<MdCheckBoxOutlineBlank color={unCheckColor} />}
                      onChange={() => {
                        setDisplayNaturallyVentilatedProp(!displayNaturallyVentilatedProp);
                      }}
                    />
                    <CustomTypography
                      text='Naturally Ventilated'
                      fontfamily='Calibre-R'
                      fontsize='16px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#1A1A1A'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Menu
        style={{
          marginTop: 50,
        }}
        PaperProps={{
          style: {
            width: '200px',
            height: '200px',
          },
        }}
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={mapStyleMenuAnchorEl}
        open={mapStyleMenu}
        onClose={handleCloseMapStyle}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div style={{ paddingLeft: 22 }}>
          <CustomTypography
            text='Map style'
            fontsize='24px'
            fontweight='400'
            lineheight='40px'
            fontcolor='#1A1A1A'
            marginTop='0px'
            marginBottm='5px'
          />
          <Grid style={{ display: 'flex', flexDirection: 'column' }}>
            {mapStyleItems.map((item) => {
              return (
                <CustomRadioButton
                  key={item.name}
                  id={item.name}
                  label={item.name}
                  checked={mapStyle == item.value ? true : false}
                  onChange={() => handleSelectMapStyle(item)}
                />
              );
            })}
          </Grid>
        </div>
      </Menu>

      {assistantOpen && (
        <AIAssistant
          open={assistantOpen}
          chatId={chatId}
          setChatId={setChatId}
          loader={chatLoaded}
          setLoader={setChatLoaded}
          mapcoordinates={mapAiCoordinates}
          getAIData={(data) => setAIData(data)}
          setLastPrompt={setLastPrompt}
        />
      )}
      {leaseViewOpen && (
        <LeaseView open={leaseViewOpen} setOpen={setLeaseViewOpen} leaseId={leaseId} setLoading={setLoading} />
      )}
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
      <LoadingOverlayComponent open={loading} />
    </div>
  );
};
export default MapBoxComponent;
