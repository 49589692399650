import React from 'react';

function DuplicateIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='22'
      fill='none'
      viewBox='0 0 20 22'
    >
      <path
        fill='#1A1A1A'
        d='M14.5 0h-12C1.4 0 .5.9.5 2v14h2V2h12V0zm-1 4h-7c-1.1 0-1.99.9-1.99 2L4.5 20c0 1.1.89 2 1.99 2H17.5c1.1 0 2-.9 2-2V10l-6-6zm-7 16V6h6v5h5v9h-11z'
      ></path>
    </svg>
  );
}

export default DuplicateIcon;
