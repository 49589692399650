import React from "react";

function CircleIcon({ color = '#DBD99A', props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
            {...props}
        >
            <circle cx="8" cy="8" r="8" fill={color}></circle>
        </svg>
    );
}

export default CircleIcon;
