import { useState, useEffect, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@material-ui/core';
import Searchicon from '../icons/search_icon';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { getFormattedValue } from '../utils/utilities';

const ComboBox = ({ setPopup, options, setOptions, data, popup, changeHandler, id, value, loading }) => {
  const [page, setPage] = useState(1);
  const [searchVal, setSearchVal] = useState('');
  const [listboxNode, setListboxNode] = useState('');
  const [position, setPosition] = useState(0);

  const formattedData = useMemo(
    () =>
      data
        .filter((elm) => elm.BUILDING_NAME)
        .map((el) => ({
          label: getFormattedValue(el.BUILDING_NAME),
          value: el.BUILDING_NAME,
          id: el.PROPERTY_CODE,
        })),
    [data]
  );

  useEffect(() => {
    if (listboxNode !== '') {
      listboxNode.scrollTop = position;
    }
  }, [position, listboxNode]);

  const loadMoreResults = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    if (!searchVal) setOptions([...formattedData.slice(0, nextPage * 50)]);
  };
  const handleScroll = (event) => {
    setListboxNode(event.currentTarget);

    const x = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - x <= 1) {
      setPosition(x);
      loadMoreResults();
    }
  };

  useEffect(() => {
    if (!!formattedData.length || !popup) {
      setOptions([...formattedData.slice(0, page * 50)]);
    }
  }, [formattedData, popup]);

  const outlinedStyles = makeStyles((theme) => ({
    root: {
      '&:-webkit-autofill': {
        backgroundColor: '#FFFFFF !important;',
      },
      '& .MuiAutocomplete-input': {
        paddingLeft: !popup ? '20px !important' : '',
      },
      '& .MuiInputAdornment-root': {
        paddingLeft: '20px',
      },
      '& .MuiOutlinedInput-root': {
        '& ::placeholder': {
          fontSize: popup ? '14px' : '18px',
        },
        '& fieldset': {
          borderColor: '#1a1a1a',
        },
        '&:hover fieldset': {
          borderColor: '#1a1a1a',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1a1a1a',
        },
      },
    },
  }));
  const outlinedClasses = outlinedStyles();

  return (
    <Autocomplete
      size='small'
      id={id}
      ListboxProps={{
        onScroll: handleScroll,
      }}
      options={options}
      value={value}
      sx={{ margin: '0 10px' }}
      className={outlinedClasses.root}
      onOpen={() => setPopup(true)}
      onClose={() => {
        setPopup(false);
        setPage(1);
        setSearchVal('');
      }}
      onInputChange={(e) => {
        if (e?.target?.value?.length >= 2) {
          setSearchVal(e.target.value);
          setPage(1);
          setOptions(formattedData.filter((el) => el.value.toLowerCase().includes(e.target.value.toLowerCase())));
          return;
        }
        if (!e?.target?.value?.length) {
          setOptions([...formattedData.slice(0, page * 50)]);
          setSearchVal('');
        }
      }}
      onChange={(e, v) => changeHandler(e, v, id)}
      isOptionEqualToValue={(option, val) => option.label === val}
      renderOption={(props, option, { selected = false }) => (
        <div
          style={{
            background: selected ? '#E9F1F0' : 'white',
            hover: 'red',
          }}
        >
          <li
            {...props}
            style={{
              marginLeft: '-10px',
              paddingTop: '2px',
              paddingBottom: '2px',
              fontSize: '6px',
            }}
          >
            <FormControlLabel
              sx={{ ml: 1 }}
              value={option.value}
              control={
                <Radio
                  size='small'
                  checked={selected}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 20,
                      color: '#1A1A1A',
                    },
                  }}
                />
              }
              label={option ? option.label : null}
            />
          </li>
        </div>
      )}
      renderInput={(params, par) => (
        <TextField
          {...params}
          placeholder={!popup ? 'Select property' : 'Filter by keyword'}
          variant='outlined'
          className={outlinedClasses.root}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {popup && !params.inputProps.value > 0 && (
                  <InputAdornment position='start'>
                    <Searchicon />
                  </InputAdornment>
                )}
              </>
            ),
            endAdornment: (
              <>
                {loading && popup && <CircularProgress color='inherit' size={18} sx={{ color: '#a8a8a8' }} />}
                {!(params.inputProps.value && popup && loading) && params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default ComboBox;
