import $ from 'jquery';
export default class floorSizeNumericCellEditor {
  init(params) {
    this.value = params.value;
    this.input = document.createElement('input');
    this.input.style.height = '100%';
    this.input.style.width = '100%';
    this.input.id = 'input';
    this.input.type = 'number';
    this.input.min = 2;
    this.input.max = 10;
    this.input.step = 0.01;
    this.input.value = this.value;
    this.input.backspace = false;
    this.input.onpaste = function (e) {
      let value = parseInt(e.clipboardData.getData('Text'));
      if (!isNaN(value)) {
        if (value >= 2 && value <= 10) {
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    this.input.onkeydown = (e) => {
      if (e.keyCode == 8 || e.keyCode == 46) {
        this.input.backspace = true;
      }
      this.input.backspace = false;
    };
    this.input.addEventListener('input', (event) => {
      if (event.target.value >= 2 && event.target.value <= 10) {
        this.value = event.target.value;
      } else {
        if (!this.input.backspace && event.target.value !== 0 && event.target.value.length >= 2) {
          params.error();
          this.input.value = 0;
          this.value = 0;
        }
      }
    });
  }
  getGui() {
    return this.input;
  }
  getValue() {
    return this.value;
  }
  isCancelBeforeStart() {
    return false;
  }
  isCancelAfterEnd() {
    return this.value > 1000;
  }

  afterGuiAttached() {
    this.input.focus();
  }
}
