import React from "react";

function MapViewIcon({ color = '#1A1A1A', width = 20, height = 20, props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 18 18"
            {...props}
        >
            <path
                fill={color}
                d="M15.949.738l-14.68 6.14c-.83.35-.81 1.53.02 1.85l5.34 2.07a1 1 0 01.57.57l2.06 5.33c.32.84 1.51.86 1.86.03l6.15-14.67c.33-.83-.5-1.66-1.32-1.32z"
            ></path>
        </svg>
    );
}

export default MapViewIcon;
