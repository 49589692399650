import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import RightDrawer from '../components/right_drawer';
import AssistantField from './AssitantField';
import CancelIcon from '../icons/close_icon';
import CustomTypography from '../utils/text';
import { useDispatch, useSelector } from 'react-redux';
import { samplePrompts } from '../utils/constants';
import { FiArrowRight } from 'react-icons/fi';
import CircularProgress from '@mui/material/CircularProgress';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import genericTooltip from '../utils/genericTooltip';

const AIAssistant = ({ open, chatId, setChatId, loader, setLoader, mapcoordinates, getAIData, setLastPrompt }) => {
  const email = useSelector((state) => state.mainReducer.email);
  function generateChatName(email) {
    const [userPart] = email.split('@'); // Get the part before the '@'
    const [firstName, lastName] = userPart.split('.'); // Split the user part by '.'
    const firstLetter = firstName?.charAt(0).toUpperCase() ?? '';
    const secondLetter = lastName?.charAt(0).toUpperCase() ?? '';

    return firstLetter + secondLetter;
  }

  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [prompts, setPrompts] = useState([]);
  const [showChatLoader, setShowChatLoader] = useState(false);
  const onKeyPress = (event) => {
    if (event.key === 'Enter' && value) commonHandler();
  };
  const onClickIcon = () => {
    if (value) commonHandler();
  };
  const commonHandler = () => {
    setPrompts((prev) => [...prev, { prompt: value, value: null }]);
    assistantChatting(value);
    setValue('');
  };

  const promptStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#DFE6E5',
    borderRadius: 10,
    fontFamily: 'Calibre-R',
    marginBottom: 15,
    padding: 10,
  };
  const axiosAIAssitant = useAxiosPrivate('aiasstant');

  const assistantChatting = async (promptValue) => {
    setShowChatLoader(true);
    const reqObj = {
      chat_id: chatId,
      user_prompt: promptValue + JSON.stringify(mapcoordinates),
    };
    try {
      const response = await axiosAIAssitant.post(`/chatting`, reqObj);
      if (response.data.result?.features.length > 0) {
        const items = response.data.result.features.map((el, i) => ({
          ...el,
          id: el.properties.cbre_property?.attributes?.property_code,
          properties: {
            ...el.properties,
            description: genericTooltip(el.properties, promptValue, i + 1),
          },
        }));
        getAIData(items);
        setLastPrompt(promptValue);
        setPrompts((prev) => [
          ...prev.slice(0, -1),
          {
            ...prev[prev.length - 1],
            value: `I have found ${response.data.result.features.length} results for your query`,
          },
        ]);
      } else if (response.data.response_for_user)
        setPrompts((prev) => [
          ...prev.slice(0, -1),
          { ...prev[prev.length - 1], value: response.data.response_for_user },
        ]);
      else setPrompts((prev) => [...prev.slice(0, -1), { ...prev[prev.length - 1], value: 'Something went wrong!' }]);
    } catch (error) {
      setPrompts((prev) => [...prev.slice(0, -1), { ...prev[prev.length - 1], value: error.message }]);
    } finally {
      setShowChatLoader(false);
    }
  };

  return (
    <div style={{ zIndex: 2000 }}>
      <RightDrawer open={open} variant={open ? 'permanent' : 'temporary'} width='45%' hideBackdrop={true}>
        <Grid
          style={{
            margin: 20,
            marginTop: 5,
            marginRight: 5,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Grid>
            <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20 }}>
              <CustomTypography
                text={'AI Assistant'}
                fontsize='24px'
                fontweight='400'
                fontcolor='#1A1A1A'
                lineheight='40px'
                justifycontent='center'
              />
              <IconButton
                onClick={() => {
                  dispatch({ type: 'SET_ASSISTANT_OPEN', payload: false });
                  setChatId();
                }}
              >
                <CancelIcon />
              </IconButton>
            </Grid>

            <Grid style={{ marginRight: 5 }}>
              {!prompts.length && (
                <Grid style={{ display: 'flex', width: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
                  <Grid md={5}>
                    <CustomTypography
                      text={'Examples'}
                      fontsize='20px'
                      fontweight='400'
                      fontcolor='#1A1A1A'
                      lineheight='24px'
                      justifycontent='center'
                      marginBottm={15}
                    />
                    {samplePrompts.map((el, i) => (
                      <Grid
                        key={el.value + i}
                        style={{ ...promptStyle, cursor: 'pointer', minHeight: 90 }}
                        onClick={() => {
                          setPrompts((prev) => [...prev, { prompt: el.value.slice(1, -1), value: null }]);
                          assistantChatting(el.value);
                        }}
                      >
                        <p style={{ paddingRight: 10, textAlign: 'center', fontStyle: 'italic' }}>{el.value}</p>
                        <FiArrowRight style={{ width: 30 }} />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid md={5}>
                    <CustomTypography
                      text={'Things to keep in mind'}
                      fontsize='20px'
                      fontweight='400'
                      fontcolor='#1A1A1A'
                      lineheight='24px'
                      justifycontent='center'
                      marginBottm={15}
                    />
                    {samplePrompts.map((el, i) => (
                      <Grid
                        key={el.helpText + i}
                        style={{
                          ...promptStyle,
                          background: '#EBE5AE',
                          minHeight: 90,
                        }}
                      >
                        <p style={{ paddingRight: 10, textAlign: 'center' }}>{el.helpText}</p>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
              {!!prompts.length && (
                <Grid style={{ height: '77vh', overflowY: 'auto', paddingRight: 5 }}>
                  {prompts.map((el, i) => (
                    <>
                      <Grid key={el.prompt + i} style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
                        <Grid
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: '50%',
                            marginRight: 20,
                            background: '#003F2D',
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {generateChatName(email)}
                        </Grid>
                        <CustomTypography
                          text={el.prompt}
                          fontsize='16px'
                          fontweight='400'
                          fontcolor='#1A1A1A'
                          lineheight='20px'
                          justifycontent='center'
                        />
                      </Grid>
                      <Grid key={el.prompt} style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                        <Grid
                          style={{
                            minWidth: 40,
                            height: 40,
                            borderRadius: '50%',
                            marginRight: 20,
                            background: 'rgb(223, 238, 234)',
                            color: '#003F2D',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          AI
                        </Grid>
                        <Grid
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '85%',
                          }}
                        >
                          <CustomTypography
                            text={'Getting results. This might take a few minutes.'}
                            fontsize='16px'
                            fontweight='400'
                            fontcolor='#1A1A1A'
                            lineheight='20px'
                            justifycontent='center'
                          />
                          {showChatLoader && prompts.length - 1 === i && (
                            <CircularProgress color='inherit' size={24} sx={{ color: '#003F2D' }} />
                          )}
                        </Grid>
                      </Grid>
                      {el.value && (
                        <Grid key={el.prompt} style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                          <Grid
                            style={{
                              minWidth: 40,
                              height: 40,
                              borderRadius: '50%',
                              marginRight: 20,
                              background: 'rgb(223, 238, 234)',
                              color: '#003F2D',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            AI
                          </Grid>
                          <CustomTypography
                            text={el.value}
                            fontsize='16px'
                            fontweight='400'
                            fontcolor='#1A1A1A'
                            lineheight='20px'
                            justifycontent='center'
                          />
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid>
            <AssistantField
              disabled={false}
              id='ai-assistant'
              type='string'
              fullWidth
              inputPlaceholder={'Type message'}
              value={value}
              onKeyPress={onKeyPress}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              onClick={onClickIcon}
              variant='outlined'
            />
          </Grid>
        </Grid>
        {loader && (
          <>
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                width: '100%',
                height: '100vh',
                background: '#000',
                opacity: 0.3,
              }}
            ></div>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress size={40} sx={{ color: '#003F2D', marginRight: '10px', opacity: 1, zIndex: 2000 }} />
            </div>
          </>
        )}
      </RightDrawer>
    </div>
  );
};

export default AIAssistant;
