import React from "react";

function CalenderIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="18"
            fill="none"
            viewBox="0 0 16 18"
        >
            <path
                fill="#000"
                d="M3.833 8.167H5.5v1.666H3.833V8.167zM15.5 4v11.667c0 .916-.75 1.666-1.667 1.666H2.167c-.925 0-1.667-.75-1.667-1.666L.508 4a1.66 1.66 0 011.659-1.667H3V.667h1.667v1.666h6.666V.667H13v1.666h.833c.917 0 1.667.75 1.667 1.667zM2.167 5.667h11.666V4H2.167v1.667zm11.666 10V7.333H2.167v8.334h11.666zM10.5 9.833h1.667V8.167H10.5v1.666zm-3.333 0h1.666V8.167H7.167v1.666z"
            ></path>
        </svg>
    );
}

export default CalenderIcon;
