import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import CustomTypography from '../utils/text';
import LoadingOverlayComponent from '../components/lodding_component';
import CustomTextField from '../components/custom_textfield';
import DatePicker from '../components/date_picker';
import AutoComplite from '../components/autocomplite_textfeild';
import { Button } from '@material-ui/core';
import useStyles from '../styles';
import moment from 'moment';
import Map from '../components/mapBox';
import { Buffer } from 'buffer';
import WarningMessage from '../components/warning_snackbar';
import SuccessSnackBar from '../components/SnackBar';
import PropertyDetailsComponent from '../components/PropertyDetailsDisplay';
import generatePropertyObject from '../utils/property_tooltip_view';
import AlertBar from '../components/alertBar';
import HubsAutoComplete from '../components/postalcode_autoComplite';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const AdvancedFiltersView = () => {
  const isMapFullScrren = useSelector((state) => state.mainReducer.isMapFullScrren);
  const { assistantOpen } = useSelector((state) => state.mainReducer);
  const hwgtkey = useSelector((state) => state.mainReducer.hwgtkey);
  const hkey = useSelector((state) => state.mainReducer.hkey);
  const hubUser = useSelector((state) => state.mainReducer.hubUser);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [isFullScreen, setIsFullscreen] = useState(false);
  const [marketValues, setMarketsValue] = useState([]);
  const [submarket, setSubmarket] = useState([]);
  const [district, setDistrict] = useState([]);
  const [neighbourhood, setNeighbourhood] = useState([]);
  const [postalCode, setPostalCode] = useState([]);
  const [MinAvailSpace, SetMinAvailableSpace] = useState('');
  const [MaxAvailSpace, SetMaxAvailableSpace] = useState('');
  const [MinFloorSize, SetMinFloorSize] = useState('');
  const [MaxFloorSize, SetMaxFloorSize] = useState('');
  const [MinRentAmt, SetMinRentAmt] = useState('');
  const [MaxRentAmt, SetMaxRentAmt] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setTodate] = useState('');
  const locationData = useSelector((state) => state.mainReducer.locationData);
  const [marketDropdown, setMarketDropdown] = useState([]);
  const [subMarketDropdown, setSubMarketDropdown] = useState([]);
  const [districtDropdown, setDistrictDropdown] = useState([]);
  const [neighbourhoodDropdown, setneighbourhoodDropdown] = useState([]);
  const [transportHubs, setTransportHubs] = useState([]);
  const [transportHubsValue, setTransportHubsValue] = useState([]);
  const [isContinueEnable, setIsContinueEnable] = useState(true);
  const [epcrating, setEpcrating] = useState([]);
  const [leaseValue, setLeaseValue] = useState('');
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [successSnackbar, setSuccessSnackbar] = useState(null);
  const [openProperty, setOpenProperty] = useState(false);
  const [propertyObject, setPropertyObejct] = useState({});
  const [RentAmtError, setRentAmtError] = useState(false);
  const [AvailSizeError, setAvailSizeError] = useState(false);
  const [FloorSizeError, setFloorSizeError] = useState(false);
  const [flag, setFlag] = useState(true);
  const [isInvalidFromDate, setIsInvalidFromDate] = useState(false);
  const [isInvalidToDate, setIsInvalidToDate] = useState(false);
  const [bounds, setBounds] = useState([]);
  const [error, setError] = useState(false);
  const validNumber = /^[0-9]*$/;
  const projectStatus = useSelector((state) => state.mainReducer.selectedProjectStatus);
  const projectCompletedDate = useSelector((state) => state.mainReducer.selectedProjectCompletedDate);
  const [openAlert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [clearMapfilter, modifyMapFilter] = useState(false);
  const [completeData, setCompleteData] = useState([]);
  const [locations, setLocations] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    if (projectStatus === 'Completed' || projectStatus === 'Archived') {
      setAlert(true);
      projectStatus === 'Completed'
        ? setAlertMessage('- This project was marked as completed on')
        : setAlertMessage('- This project was marked as archived on');
    }
  }, [projectStatus]);

  useEffect(() => {
    setIsContinueEnable(true);
  }, [
    neighbourhood,
    district,
    postalCode,
    submarket,
    marketValues,
    epcrating,
    leaseValue,
    MinAvailSpace,
    MaxAvailSpace,
    MinRentAmt,
    MaxRentAmt,
    MinFloorSize,
    MaxFloorSize,
    fromDate,
    toDate,
  ]);

  const handleClickReset = () => {
    setIsContinueEnable(true);
    setNeighbourhood([]);
    setDistrict([]);
    setSubmarket([]);
    setMarketsValue([]);
    setPostalCode([]);
    setEpcrating([]);
    setLeaseValue('');
    SetMinRentAmt('');
    SetMaxRentAmt('');
    SetMinFloorSize('');
    SetMaxFloorSize('');
    SetMaxAvailableSpace('');
    SetMinAvailableSpace('');
    setFromDate(null);
    setTodate(null);
    setFlag(true);
    setIsInvalidFromDate(false);
    setIsInvalidToDate(false);
    setTransportHubsValue([]);
  };
  useEffect(() => {
    setIsFullscreen(isMapFullScrren);
  }, [isMapFullScrren]);
  useEffect(() => {
    if (hwgtkey) {
      setIsLoading(true);
      fetchPropertySearchHistory();
      getMapData();
    }
  }, [hwgtkey]);

  const fetchPropertySearchHistory = async () => {
    try {
      const response = await axiosPrivate.get(
        `/advance-filter/property-search-history?hkey=${hkey}&hwgtkey=${hwgtkey}`
      );
      if (response.status === 200 && response.data.length) handleSearchResponse(response.data);
    } catch (error) {
      setWarningSnackbar({
        name: 'Search criteria:',
        message: 'Unable to fetch search criteria info',
        severity: 'error',
      });
    }
  };

  const handleSearchResponse = (response) => {
    if (!response.length) return;
    const serchObject = response[0];
    if (serchObject?.MAX_AVAILABLE_SPACE) SetMaxAvailableSpace(serchObject?.MAX_AVAILABLE_SPACE);
    if (serchObject?.MIN_AVAILABLE_SPACE) SetMinAvailableSpace(serchObject?.MIN_AVAILABLE_SPACE);
    if (serchObject?.MAX_FLOOR_SIZE) SetMaxFloorSize(serchObject?.MAX_FLOOR_SIZE);
    if (serchObject?.MIN_FLOOR_SIZE) SetMinFloorSize(serchObject?.MIN_FLOOR_SIZE);
    if (serchObject?.MAX_RENT) SetMaxRentAmt(serchObject?.MAX_RENT);
    if (serchObject?.MIN_RENT) SetMinRentAmt(serchObject?.MIN_RENT);
    if (serchObject?.LEASE_TYPE) setLeaseValue(serchObject?.LEASE_TYPE);
    if (serchObject?.AVAILABLE_FROM) setFromDate(moment(serchObject?.AVAILABLE_FROM));
    if (serchObject?.AVAILABLE_TO) setTodate(moment(serchObject?.AVAILABLE_TO));
    if (response[0]?.DISTRICT?.length) {
      const districtArray = response[0]?.DISTRICT.split('|');
      const array = districtArray.map((e) => ({ id: e, title: e }));
      setDistrict(array);
    }
    if (response[0]?.MARKET?.length) {
      const marketArray = response[0]?.MARKET.split('|');
      const Markarray = marketArray.map((e) => ({ id: e, title: e }));
      setMarketsValue(Markarray);
    }
    if (response[0]?.SUB_MARKET?.length) {
      const subMarketArray = response[0]?.SUB_MARKET.split('|');
      const SubMarray = subMarketArray.map((e) => ({ id: e, title: e }));
      setSubmarket(SubMarray);
    }
    if (response[0]?.NEIGHBOURHOOD) {
      const neighbourhoodArray = response[0]?.NEIGHBOURHOOD.split('|');
      const neighbarray = neighbourhoodArray.map((e) => ({ id: e, title: e }));
      setNeighbourhood(neighbarray);
    }
    if (response[0]?.POSTAL_CODE?.length) {
      const PostalCodeArray = response[0]?.POSTAL_CODE.split('|');
      const postalarray = PostalCodeArray.map((e) => ({ id: e, title: e }));
      setPostalCode(postalarray);
    }
    if (response[0]?.TRANSPORT_HUB?.length) {
      const hubs = response[0]?.TRANSPORT_HUB.split('|');
      const hubsList = hubs.map((e) => ({ id: e, title: e }));
      setTransportHubsValue(hubsList);
    }
    if (response[0]?.EPC_RATING?.length) {
      const EpcRatingArray = response[0]?.EPC_RATING.split(',');
      const epcarray = EpcRatingArray.map((e) => ({ id: e, title: e }));
      setEpcrating(epcarray);
    }
  };

  const getMapData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.get(`/mapview/map-data?hkey=${hkey}&hwgtkey=${hwgtkey}`);
      if (response.status === 200 && response.data.length) setCompleteData(setLocationsData(response.data));
      else
        setWarningSnackbar({
          name: 'No properties to display - ',
          message: 'Please widen your search criteria',
          severity: 'error',
        });
    } catch (error) {
      setWarningSnackbar({
        name: 'Map view:',
        message: 'Unable to fetch property data',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = () => {
    dispatch({
      type: 'SET_MAP_FULLSCREEN',
      payload: isFullScreen ? false : true,
    });
  };
  useEffect(() => {
    if (!locationData.length) return;
    const marketsArray = locationData.filter((a, i) => locationData.findIndex((s) => a.MARKET === s.MARKET) === i);
    const marketsData = marketsArray.filter((el) => el.MARKET).map((e) => ({ id: e.MARKET, title: e.MARKET }));
    const sortArray = sortingArray(marketsData);
    if (sortArray.length) sortArray.unshift({ id: 0, title: 'All' });
    setMarketDropdown(sortArray);
  }, [locationData]);

  useEffect(() => {
    getTransportByName();
  }, []);

  const getTransportByName = async () => {
    try {
      const response = await axiosPrivate.get(`/advance-filter/transport-hubs`);
      if (response.status === 200 && response.data.length) {
        const hubs = response.data.map((el) => ({
          id: el.NAME,
          title: el.NAME,
        }));
        const sorted = sortingArray(hubs);
        // sorted.unshift({ id: 0 });
        setTransportHubs(sorted);
      }
    } catch (error) {
      console.log('Error while fetching the transport by name...!', error);
    }
  };

  const sortingArray = (array) => {
    const strAscending = [...array].sort((a, b) => (a.title > b.title ? 1 : -1));
    return strAscending;
  };
  useEffect(() => {
    const markets = marketValues.map((el) => el.title);
    let selectedLocationData = locationData;
    if (markets.length > 0) {
      selectedLocationData = locationData.filter((el) => markets.includes(el.MARKET));
    }
    const distinctSubmarkets = selectedLocationData
      .filter((a, i) => selectedLocationData.findIndex((s) => a.SUBMARKET === s.SUBMARKET) === i)
      .filter((el) => el.SUBMARKET);
    const slctdMarkets = distinctSubmarkets.filter((el) => markets.includes(el.MARKET)).map((elm) => elm.SUBMARKET);
    const subMarkets = distinctSubmarkets.map((elm) => ({
      id: elm.SUBMARKET,
      title: elm.SUBMARKET,
    }));
    const sorted = sortingArray(subMarkets);
    if (sorted.length > 0) {
      sorted.unshift({ id: 0, title: 'All' });
    }
    setSubmarket((prev) => {
      const tempSubmarket = prev?.filter((el) => slctdMarkets?.includes(el.title));
      if (tempSubmarket.length === distinctSubmarkets.length) {
        tempSubmarket.unshift({ id: 0, title: 'All' });
      }
      return [...tempSubmarket];
    });
    setSubMarketDropdown(sorted);
  }, [marketValues]);

  useEffect(() => {
    const subMarkets = submarket.map((el) => el.title);
    const markets = marketValues.map((el) => el.title);
    let selectedLocationData = locationData;
    if (markets.length > 0) {
      selectedLocationData = locationData.filter((el) => markets.includes(el.MARKET));
    }
    if (subMarkets.length > 0) {
      selectedLocationData = selectedLocationData.filter((el) => subMarkets.includes(el.SUBMARKET));
    }
    const distinctDistricts = selectedLocationData
      .filter((a, i) => selectedLocationData.findIndex((s) => a.DISTRICT === s.DISTRICT) === i)
      .filter((el) => el.DISTRICT);

    const slctdSubmarkets = distinctDistricts
      .filter((el) => subMarkets.includes(el.SUBMARKET))
      .map((elm) => elm.DISTRICT);

    const districts = distinctDistricts.map((elm) => ({
      id: elm.DISTRICT,
      title: elm.DISTRICT,
    }));

    const sorted = sortingArray(districts);
    if (sorted.length > 0) {
      sorted.unshift({ id: 0, title: 'All' });
    }
    setDistrict((prev) => {
      let tempDistrict = [...prev];
      if (slctdSubmarkets.length) tempDistrict = prev?.filter((el) => slctdSubmarkets?.includes(el.title));
      if (tempDistrict.length === distinctDistricts.length) {
        tempDistrict.unshift({ id: 0, title: 'All' });
      }
      return [...tempDistrict];
    });

    setDistrictDropdown(sorted);
  }, [submarket, marketValues]);

  useEffect(() => {
    const districts = district.map((el) => el.title);
    const subMarkets = submarket.map((el) => el.title);
    const markets = marketValues.map((el) => el.title);
    let selectedLocationData = locationData;
    if (markets.length > 0) {
      selectedLocationData = locationData.filter((el) => markets.includes(el.MARKET));
    }
    if (subMarkets.length > 0) {
      selectedLocationData = selectedLocationData.filter((el) => subMarkets.includes(el.SUBMARKET));
    }
    if (districts.length > 0) {
      selectedLocationData = selectedLocationData.filter((el) => districts.includes(el.DISTRICT));
    }
    const distinctNeighbourhood = selectedLocationData
      .filter((a, i) => selectedLocationData.findIndex((s) => a.NEIGHBOURHOOD === s.NEIGHBOURHOOD) === i)
      .filter((el) => el.NEIGHBOURHOOD);

    const slctdDistricts = distinctNeighbourhood
      .filter((el) => districts.includes(el.DISTRICT))
      .map((elm) => elm.NEIGHBOURHOOD);

    const slctdSubMarkets = distinctNeighbourhood
      .filter((el) => subMarkets.includes(el.SUBMARKET))
      .map((elm) => elm.NEIGHBOURHOOD);

    const slctdMarkets = distinctNeighbourhood
      .filter((el) => markets.includes(el.MARKET))
      .map((elm) => elm.NEIGHBOURHOOD);

    const neighborhood = distinctNeighbourhood.map((elm) => ({
      id: elm.NEIGHBOURHOOD,
      title: elm.NEIGHBOURHOOD,
    }));

    const sorted = sortingArray(neighborhood);
    if (sorted.length > 0) {
      sorted.unshift({ id: 0, title: 'All' });
    }
    setNeighbourhood((prev) => {
      let tempNeighbourhood = [];

      if (slctdDistricts.length) tempNeighbourhood = prev?.filter((el) => slctdDistricts?.includes(el.title));
      else if (slctdSubMarkets.length) tempNeighbourhood = prev?.filter((el) => slctdSubMarkets?.includes(el.title));
      else tempNeighbourhood = prev?.filter((el) => slctdMarkets?.includes(el.title));

      if (!!tempNeighbourhood.length && tempNeighbourhood.length === distinctNeighbourhood.length) {
        tempNeighbourhood.unshift({ id: 0, title: 'All' });
      }
      return [...tempNeighbourhood];
    });
    setneighbourhoodDropdown(sorted);
  }, [district, submarket, marketValues]);

  const handleFromDateCallback = (fromDateValue) => {
    if (fromDateValue) {
      if (fromDateValue['$d'] == 'Invalid Date' || moment(moment().format('MM/DD/YYYY')) > fromDateValue) {
        setIsInvalidFromDate(true);
      } else {
        setIsInvalidFromDate(false);
      }
    } else {
      setIsInvalidFromDate(false);
    }
    setFromDate(fromDateValue);
  };

  const handleToDateCallback = (toDateValue) => {
    if (toDateValue) {
      if (
        toDateValue['$d'] == 'Invalid Date' ||
        moment(moment().format('MM/DD/YYYY')) > toDateValue ||
        (fromDate != '' && fromDate > toDateValue && toDateValue != '')
      ) {
        setIsInvalidToDate(true);
      } else {
        setIsInvalidToDate(false);
      }
    } else setIsInvalidToDate(false);

    setTodate(toDateValue);
  };
  const handleOnBlurMinRentAmt = (value) => {
    setError('');
    let num = parseInt(value);
    ComapreMaxandMinValues(num, MaxRentAmt, 'Rent');
    let cleanNum = num;
    if (!isNaN(cleanNum)) {
      SetMinRentAmt(cleanNum);
    }
  };

  const handleOnBlurMaxRentAmt = (value) => {
    setError('');
    let num = parseInt(value);
    ComapreMaxandMinValues(MinRentAmt, num, 'Rent');
    let cleanNum = num;
    if (!isNaN(cleanNum)) {
      SetMaxRentAmt(cleanNum);
    }
  };
  const handleOnBlurMinFloorSize = (value) => {
    setError('');
    let num = parseInt(value);
    ComapreMaxandMinValues(num, MaxFloorSize, 'FloorSize');
    let cleanNum = num;
    if (!isNaN(cleanNum)) {
      SetMinFloorSize(cleanNum);
    }
  };
  const handleOnBlurMaxFloorSize = (value) => {
    setError('');
    let num = parseInt(value);
    ComapreMaxandMinValues(MinFloorSize, num, 'FloorSize');
    let cleanNum = num;
    if (!isNaN(cleanNum)) {
      SetMaxFloorSize(cleanNum);
    }
  };

  const handleOnBlurMinAvailSpace = (value) => {
    setError('');
    let num = parseInt(value);
    ComapreMaxandMinValues(num, MaxAvailSpace, 'AvailSpace');
    let cleanNum = num;
    if (!isNaN(cleanNum)) {
      SetMinAvailableSpace(cleanNum);
    }
  };
  const handleOnBlurMaxAvailSpace = (value) => {
    setError('');
    let num = parseInt(value);
    ComapreMaxandMinValues(MinAvailSpace, num, 'AvailSpace');
    let cleanNum = num;
    if (!isNaN(cleanNum)) {
      SetMaxAvailableSpace(cleanNum);
    }
  };

  const IsValueInvalid = (value) => {
    return value == '' || value == undefined || (!isNaN(value) && value > -1);
  };

  const handleClickApplyFilter = () => {
    setIsLoading(true);
    let object = {
      PROJECT_HKEY: hkey,
      WEIGHTING_HKEY: hwgtkey,
      USER_HKEY: hubUser,
    };

    if (MinFloorSize) object['MIN_FLOOR_SIZE'] = parseInt(MinFloorSize);
    if (MaxFloorSize) object['MAX_FLOOR_SIZE'] = parseInt(MaxFloorSize);
    if (MinRentAmt) object['MIN_RENT'] = parseInt(MinRentAmt);
    if (MaxRentAmt) object['MAX_RENT'] = parseInt(MaxRentAmt);
    if (MinAvailSpace) object['MIN_AVAILABLE_SPACE'] = parseInt(MinAvailSpace);
    if (MaxAvailSpace) object['MAX_AVAILABLE_SPACE'] = parseInt(MaxAvailSpace);
    if (fromDate) object['AVAILABLE_FROM'] = moment(new Date(fromDate)).format('YYYY-MM-DD');
    if (toDate) object['AVAILABLE_TO'] = moment(new Date(toDate)).format('YYYY-MM-DD');
    if (marketValues.length > 0) object['MARKET'] = replaceQuote(marketValues);
    if (submarket.length > 0) object['SUB_MARKET'] = replaceQuote(submarket);
    if (district.length > 0) object['DISTRICT'] = replaceQuote(district);
    if (neighbourhood.length > 0) object['NEIGHBOURHOOD'] = replaceQuote(neighbourhood);
    if (transportHubsValue.length) object['TRANSPORT_HUB'] = replaceQuote(transportHubsValue);
    applyFilters(object);
    dispatch({
      type: 'SET_AVAILABLE_TO',
      payload: toDate ? moment(new Date(toDate)).format('YYYY-MM-DD') : null,
    });
  };

  const applyFilters = async (object) => {
    try {
      const response = await axiosPrivate.post(`/mapview/search-criteria`, object);
      if (response.status === 200 && response.data.length > 0) {
        getMapData();
        modifyMapFilter(true);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errorCode === '000904' || error.errorCode === '-20001') {
        setWarningSnackbar({
          name: 'No properties to display - ',
          message: 'Please widen your search criteria',
          severity: 'success',
        });
      } else {
        setWarningSnackbar({
          name: 'No properties to display - ',
          message: 'Please widen your search criteria',
          severity: 'success',
        });
      }
    }
  };

  const replaceQuote = (obj) => obj.map((el) => el.title.replace("'", "''''")).join('|');

  const handleClickProperty = (value, propertObject) => {
    setOpenProperty(value);
    setPropertyObejct(propertObject);
  };
  const handleStarProperty = (building_name, shortOrLong) => {
    setSuccessSnackbar({
      name: building_name,
      message:
        shortOrLong == 'Short'
          ? ` has been succesully removed from your shortlist`
          : `has been succesully added to your shortlist`,
      severity: 'success',
    });
    getMapData();
  };
  const ComapreMaxandMinValues = (minValue, maxValue, keyValue) => {
    if (!isNaN(maxValue) && maxValue > -1 && !isNaN(minValue) && minValue > -1) {
      const errorValue = Math.round(parseInt(maxValue) * 100) < Math.round(parseInt(minValue) * 100);
      switch (keyValue) {
        case 'FloorSize':
          setFloorSizeError(errorValue);
          break;
        case 'Rent':
          setRentAmtError(errorValue);
          break;
        case 'AvailSpace':
          setAvailSizeError(errorValue);
          break;
        default:
          break;
      }
    }
  };

  const handleListedCallback = (objectVal) => {
    if (isLoading) return;
    let data = [];
    if (objectVal.shortList === objectVal.longList) data = [...completeData];
    else if (objectVal.shortList) data = completeData.filter((el) => el.LISTED === 'Short');
    else data = completeData.filter((el) => el.LISTED === 'Long');
    const viewprops = !objectVal?.displayViewedProp ? 'No' : 'Yes';
    const gymprops = !objectVal?.gymprops ? 'No' : 'Yes';
    const selfprops = !objectVal?.selfprops ? 'No' : 'Yes';
    const cateringprops = !objectVal?.cateringprops ? 'No' : 'Yes';
    const terraceprops = !objectVal?.terraceprops ? 'No' : 'Yes';
    const onSiteShowerProps = !objectVal?.onSiteShowerProps ? 'No' : 'Yes';
    const brandingRightsProps = !objectVal?.brandingRightsProps ? 'No' : 'Yes';
    const raisedFloorsProps = !objectVal?.raisedFloorsProps ? 'No' : 'Yes';
    const lockersProps = !objectVal?.lockersProps ? 'No' : 'Yes';
    const singleLetBuildingProps = !objectVal?.singleLetBuildingProps ? 'No' : 'Yes';
    const allElectricBuildingProps = !objectVal?.allElectricBuildingProps ? 'No' : 'Yes';
    const naturallyVentilatedProps = !objectVal?.naturallyVentilatedProps ? 'No' : 'Yes';
    if (
      viewprops !== 'No' ||
      gymprops !== 'No' ||
      selfprops !== 'No' ||
      cateringprops !== 'No' ||
      terraceprops !== 'No' ||
      onSiteShowerProps !== 'No' ||
      brandingRightsProps !== 'No' ||
      raisedFloorsProps !== 'No' ||
      lockersProps !== 'No' ||
      singleLetBuildingProps !== 'No' ||
      allElectricBuildingProps !== 'No' ||
      naturallyVentilatedProps !== 'No'
    ) {
      let condition = '';
      if (objectVal?.displayViewedProp) {
        condition = ` VIEWED == "${viewprops}"`;
      }
      if (objectVal?.gymprops) {
        condition = condition
          ? condition + ` &&  BI_ON_SITE_DEDICATED_GYM == "${gymprops}"`
          : condition + `BI_ON_SITE_DEDICATED_GYM == "${gymprops}"`;
      }
      if (objectVal?.selfprops) {
        condition = condition
          ? condition + ` && BI_SELF_CONTAINED == "${selfprops}"`
          : condition + ` BI_SELF_CONTAINED == "${selfprops}"`;
      }
      if (objectVal?.cateringprops) {
        condition = condition
          ? condition + ` &&   BI_ON_SITE_CATERING_AVAILABLE == "${cateringprops}"`
          : condition + `BI_ON_SITE_CATERING_AVAILABLE == "${cateringprops}"`;
      }
      if (objectVal?.terraceprops) {
        condition = condition
          ? condition + ` &&   BI_TERRACES == "${terraceprops}"`
          : condition + `BI_TERRACES == "${terraceprops}"`;
      }
      if (objectVal?.onSiteShowerProps) {
        condition = condition
          ? condition + ` &&   BI_ON_SITE_SHOWER_FACILITIES == "${onSiteShowerProps}"`
          : condition + `BI_ON_SITE_SHOWER_FACILITIES == "${onSiteShowerProps}"`;
      }
      if (objectVal?.brandingRightsProps) {
        condition = condition
          ? condition + ` &&   BI_BRANDING_RIGHTS == "${brandingRightsProps}"`
          : condition + `BI_BRANDING_RIGHTS == "${brandingRightsProps}"`;
      }
      if (objectVal?.raisedFloorsProps) {
        condition = condition
          ? condition + ` &&   BI_RAISED_FLOORS == "${raisedFloorsProps}"`
          : condition + `BI_RAISED_FLOORS == "${raisedFloorsProps}"`;
      }
      if (objectVal?.lockersProps) {
        condition = condition
          ? condition + ` &&   BI_LOCKERS == "${lockersProps}"`
          : condition + `BI_LOCKERS == "${lockersProps}"`;
      }
      if (objectVal?.singleLetBuildingProp) {
        condition = condition
          ? condition + ` &&   BI_SINGLE_LET_BUILDING == "${singleLetBuildingProps}"`
          : condition + `BI_SINGLE_LET_BUILDING == "${singleLetBuildingProps}"`;
      }
      if (objectVal?.allElectricBuildingProps) {
        condition = condition
          ? condition + ` &&   ESG_ALL_ELECTRIC_BUILDING == "${allElectricBuildingProps}"`
          : condition + `ESG_ALL_ELECTRIC_BUILDING == "${allElectricBuildingProps}"`;
      }
      if (objectVal?.naturallyVentilatedProps) {
        condition = condition
          ? condition + ` &&   ESG_NATURALLY_VENTILATED == "${naturallyVentilatedProps}"`
          : condition + `ESG_NATURALLY_VENTILATED == "${naturallyVentilatedProps}"`;
      }
      /* eslint-disable */
      var cb = new Function(
        'o',
        'return ' + condition.replace(new RegExp(Object.keys(completeData[0]).join('|'), 'g'), 'o.$&')
      );
      data = data.filter(cb);
      if (!data.length) {
        setWarningSnackbar({
          name: 'No properties to display - ',
          message: 'Please widen your search criteria',
          severity: 'success',
        });
        setLocations([]);
        return;
      }
    }
    if (!!objectVal.epcRatingArray.length)
      data = data.filter((item) =>
        objectVal.epcRatingArray.find(
          (el) => item.ESG_EPC_RATING?.includes(el.title) || (!item.ESG_EPC_RATING && el.title === 'None')
        )
      );

    data = data.filter(
      (e) =>
        objectVal.totalScore[0] <= Math.round(e.SCORE) &&
        Math.round(e.SCORE) <= objectVal.totalScore[1] &&
        objectVal.transportScore[0] <= Math.round(e.TRANSPORT_SCORE) &&
        Math.round(e.TRANSPORT_SCORE) <= objectVal.transportScore[1] &&
        objectVal.buildingScore[0] <= Math.round(e.BUILDING_SCORE) &&
        Math.round(e.BUILDING_SCORE) <= objectVal.buildingScore[1] &&
        objectVal.locationScore[0] <= Math.round(e.LOCAL_AMENITY_SCORE) &&
        Math.round(e.LOCAL_AMENITY_SCORE) <= objectVal.locationScore[1] &&
        objectVal.esgScore[0] <= Math.round(e.ESG_SCORE) &&
        Math.round(e.ESG_SCORE) <= objectVal.esgScore[1] &&
        objectVal.deliverabilityScore[0] <= Math.round(e.DELIVERABILITY_RISK_SCORE) &&
        Math.round(e.DELIVERABILITY_RISK_SCORE) <= objectVal.deliverabilityScore[1] &&
        objectVal.custCriteriaScore[0] <= Math.round(e.CUSTOM_SCORE) &&
        Math.round(e.CUSTOM_SCORE) <= objectVal.custCriteriaScore[1]
    );
    setLocations([...data]);
    let coordinate = [0, 0];
    if (!data.length) return;
    const averageLONGITUDE = data.reduce((total, next) => total + next.LONGITUDE, 0) / data.length;
    const averageLATITUDE = data.reduce((total, next) => total + next.LATITUDE, 0) / data.length;
    coordinate = [averageLONGITUDE, averageLATITUDE];
    const max = (a, f) => a.reduce((m, x) => (m[f] > x[f] ? m : x));
    const min = (a, f) => a.reduce((m, x) => (m[f] < x[f] ? m : x));
    const maximunLongitude = max(data, 'LONGITUDE');
    const minimumLongitude = min(data, 'LONGITUDE');
    let cordArray = [];
    if (minimumLongitude) cordArray.push([minimumLongitude.LONGITUDE, minimumLongitude.LATITUDE]);
    if (maximunLongitude) cordArray.push([maximunLongitude.LONGITUDE, maximunLongitude.LATITUDE]);
    setBounds(cordArray, {
      padding: [15, 15, 15, 15],
    });
    dispatch({ type: 'SET_MAP_CENTER', payload: coordinate });
  };

  const setLocationsData = (data) =>
    data
      .filter((el) => el.LONGITUDE && el.LATITUDE && el.HKEY_HUB_PROPERTY && el.HKEY_HUB_WEIGHTING)
      .map((item) => {
        const hkeyProperty = Buffer.from(item.HKEY_HUB_PROPERTY.data);
        const hwgtkey = Buffer.from(item.HKEY_HUB_WEIGHTING.data);
        let object = { ...item, ...generatePropertyObject(hkeyProperty, hwgtkey, item, hkey) };
        if (item.TAG_PROPERTY)
          object = { ...object, properties: { ...object.properties, group: item.TAG_PROPERTY, priority: 2 } };
        else if (item.LISTED == 'Short')
          object = { ...object, properties: { ...object.properties, group: 'shortlisted', priority: 1 } };
        else object = { ...object, properties: { ...object.properties, group: 'longlisted', priority: 0 } };
        return { ...object };
      })
      .sort((a, b) => a.properties.priority - b.properties.priority);

  return (
    <div
      id='advance_filter'
      style={{
        display: 'flex',
        height: '100vh',
        overflow: 'auto',
        marginRight: '9px',
      }}
    >
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'row',
          overFlow: 'hidden',
          height: '100vh',
        }}
      >
        <Grid item xs={isFullScreen ? 12 : 6} md={isFullScreen ? 12 : 6}>
          <Map
            propertiesData={locations}
            boundsArray={bounds}
            handleClick={handleClick}
            clearMapfilter={clearMapfilter}
            modifyMapFilter={modifyMapFilter}
            handleClickProperty={handleClickProperty}
            value={isFullScreen}
            handleListedCallback={handleListedCallback}
            loading={isLoading}
            completeData={completeData}
          />
        </Grid>
        {!isFullScreen && !openProperty && !assistantOpen && (
          <Grid
            item
            xs={6}
            md={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              overflow: 'auto',
              paddingBottom: 70,
              maxWidth: '47%',
              marginLeft: '3%',
              background: 'white',
              boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.2)',
              zIndex: 200,
            }}
          >
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                overflowX: 'hidden',
                width: '92.5%',
                paddingLeft: '3%',
                paddingRight: '4.5%',
              }}
            >
              <Grid
                item
                sx={12}
                style={{
                  zIndex: 900,
                }}
              >
                <Grid
                  item
                  md={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'fixed',
                    backgroundColor: 'white',
                    width: '100%',
                    height: '80px',
                  }}
                >
                  <CustomTypography
                    text='Filter properties'
                    fontsize='36px'
                    fontweight='400'
                    lineheight='40px'
                    fontcolor='#1A1A1A'
                    marginTop='20px'
                    letter='-1%'
                    marginLeft='60'
                  />
                </Grid>

                <Grid
                  item
                  md={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    flexDirection: 'row',
                    position: 'fixed',
                    right: 50,
                    height: 80,
                  }}
                >
                  <Button
                    disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
                    className={classes.resetbtn}
                    style={{
                      textTransform: 'inherit',
                      borderColor: '#FFFFFF',
                      width: '64px',
                    }}
                    onClick={() => handleClickReset()}
                  >
                    <CustomTypography
                      text='Clear'
                      fontfamily='Calibre-M'
                      fontsize='16px'
                      fontweight='500'
                      fontcolor={projectStatus === 'Completed' || projectStatus === 'Archived' ? '#7f8080' : '#003F2D'}
                      lineheight='16px'
                      backcolor='#FFFFFF'
                      marginRight='30'
                    />
                  </Button>
                  <Button
                    className={
                      isContinueEnable &&
                      !(
                        !IsValueInvalid(MinAvailSpace) ||
                        !IsValueInvalid(MaxAvailSpace) ||
                        !IsValueInvalid(MinFloorSize) ||
                        !IsValueInvalid(MaxFloorSize) ||
                        !IsValueInvalid(MinRentAmt) ||
                        !IsValueInvalid(MaxRentAmt) ||
                        RentAmtError ||
                        AvailSizeError ||
                        FloorSizeError ||
                        isInvalidFromDate ||
                        isInvalidToDate ||
                        (fromDate != '' && toDate && fromDate > toDate) ||
                        projectStatus === 'Completed' ||
                        projectStatus === 'Archived'
                      )
                        ? classes.ApplybuttonClass
                        : classes.disableApplyClass
                    }
                    style={{ marginLeft: '0px' }}
                    disabled={
                      projectStatus === 'Completed' ||
                      projectStatus === 'Archived' ||
                      !isContinueEnable ||
                      !IsValueInvalid(MinAvailSpace) ||
                      !IsValueInvalid(MaxAvailSpace) ||
                      !IsValueInvalid(MinFloorSize) ||
                      !IsValueInvalid(MaxFloorSize) ||
                      !IsValueInvalid(MinRentAmt) ||
                      !IsValueInvalid(MaxRentAmt) ||
                      RentAmtError ||
                      AvailSizeError ||
                      FloorSizeError ||
                      isInvalidFromDate ||
                      isInvalidToDate ||
                      (fromDate != '' && toDate && fromDate > toDate)
                    }
                    onClick={() => handleClickApplyFilter()}
                  >
                    <CustomTypography
                      text='Apply'
                      fontsize='16px'
                      fontfamily='Calibre-M'
                      fontweight='500'
                      lineheight='16px'
                      padding='4px, 12px, 4px, 12px'
                      fontcolor={
                        !isContinueEnable ||
                        !IsValueInvalid(MinAvailSpace) ||
                        !IsValueInvalid(MaxAvailSpace) ||
                        !IsValueInvalid(MinFloorSize) ||
                        !IsValueInvalid(MaxFloorSize) ||
                        !IsValueInvalid(MinRentAmt) ||
                        !IsValueInvalid(MaxRentAmt) ||
                        RentAmtError ||
                        AvailSizeError ||
                        FloorSizeError
                          ? '#7f8080'
                          : '#FFFFFF'
                      }
                    />
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  paddingBottom: 15,
                  paddingTop: 10,
                  marginTop: 60,
                  zIndex: 900,
                }}
              >
                <Grid
                  item
                  style={{
                    width: '150%',
                    position: 'fixed',
                    marginLeft: '-20px',
                    borderBottom: '1px solid #003F2D',
                    opacity: '0.1',
                  }}
                ></Grid>
              </Grid>
            </Grid>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'hidden',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  overflowX: 'hidden',
                  width: '92.5%',
                  paddingLeft: '3%',
                  // paddingRight: '4.5%',
                }}
              >
                <Grid container style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Grid item xs={12} md={12} style={{ paddingBottom: 15, paddingTop: 10 }}>
                    <CustomTypography
                      text='Property details'
                      fontfamily='Calibre-R'
                      fontsize='24px'
                      fontweight='400'
                      lineheight='40px'
                      fontcolor='#1A1A1A'
                    />
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingBottom: 25 }}>
                    <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid
                        item
                        xs={6}
                        md={6}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          paddingRight: 14,
                          flexDirection: 'column',
                        }}
                      >
                        <CustomTextField
                          disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
                          id='MinAvailSpace'
                          label={'Minimum Available Size' + '\xa0' + '\xa0'}
                          type='string'
                          fullWidth
                          inputPlaceholder={'Type in size in sq.ft.'}
                          value={MinAvailSpace}
                          onChange={(event) => {
                            if (validNumber.test(event.target.value)) {
                              let a = event.target.value ? parseInt(event.target.value) : '';
                              SetMinAvailableSpace(a);
                              setError('');
                            } else {
                              setError('minAvail');
                            }
                          }}
                          variant='outlined'
                          onBlur={handleOnBlurMinAvailSpace}
                          error={IsValueInvalid(MinAvailSpace) && !AvailSizeError ? false : true}
                        />
                        {(!IsValueInvalid(MinAvailSpace) || AvailSizeError || error === 'minAvail') && (
                          <CustomTypography
                            text={AvailSizeError ? 'Minimum Avilable Space must be less than Maximum' : 'Invalid value'}
                            fontfamily='Calibre-R'
                            fontsize='11px'
                            fontweight='400'
                            lineheight='16px'
                            fontcolor='#FF543E'
                            marginLeft={12}
                            marginTop={12}
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={6}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          paddingLeft: 14,
                          flexDirection: 'column',
                        }}
                      >
                        <CustomTextField
                          disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
                          id='MaxAvailSpace'
                          label={'Maximum Available Size' + '\xa0' + '\xa0'}
                          type='string'
                          fullWidth
                          inputPlaceholder={'Type in size in sq.ft.'}
                          value={MaxAvailSpace}
                          onChange={(event) => {
                            if (validNumber.test(event.target.value)) {
                              let a = event.target.value ? parseInt(event.target.value) : '';

                              SetMaxAvailableSpace(a);
                              setError('');
                            } else {
                              setError('maxAvail');
                            }
                          }}
                          onBlur={handleOnBlurMaxAvailSpace}
                          variant='outlined'
                          error={IsValueInvalid(MaxAvailSpace) && !AvailSizeError ? false : true}
                        />
                        {(!IsValueInvalid(MaxAvailSpace) || AvailSizeError || error === 'maxAvail') && (
                          <CustomTypography
                            text={AvailSizeError ? 'Minimum Avilable Space must be less than Maximum' : 'Invalid value'}
                            fontfamily='Calibre-R'
                            fontsize='11px'
                            fontweight='400'
                            lineheight='16px'
                            fontcolor='#FF543E'
                            marginLeft={12}
                            marginTop={12}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingBottom: 25 }}>
                    <Grid
                      container
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        paddingTop: 8,
                      }}
                    >
                      <Grid
                        item
                        xs={6}
                        md={6}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          paddingRight: 14,
                          flexDirection: 'column',
                        }}
                      >
                        <CustomTextField
                          disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
                          id='MinFloorSize'
                          label={'Minimum Floor Size' + '\xa0'}
                          type='string'
                          fullWidth
                          inputPlaceholder={'Type in floor size in sq.ft.'}
                          value={MinFloorSize}
                          onChange={(event) => {
                            if (validNumber.test(event.target.value)) {
                              let a = event.target.value ? parseInt(event.target.value) : '';
                              SetMinFloorSize(a);
                              setError('');
                            } else {
                              setError('minFloor');
                            }
                          }}
                          variant='outlined'
                          onBlur={handleOnBlurMinFloorSize}
                          error={IsValueInvalid(MinFloorSize) && !FloorSizeError ? false : true}
                        />
                        {(!IsValueInvalid(MinFloorSize) || FloorSizeError || error === 'minFloor') && (
                          <CustomTypography
                            text={
                              FloorSizeError
                                ? 'Minimum floor size must be less than Maximum floor size'
                                : 'Invalid value'
                            }
                            fontfamily='Calibre-R'
                            fontsize='11px'
                            fontweight='400'
                            lineheight='16px'
                            fontcolor='#FF543E'
                            marginLeft={12}
                            marginTop={12}
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={6}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          paddingLeft: 14,
                          flexDirection: 'column',
                        }}
                      >
                        <CustomTextField
                          disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
                          id='MaxFloorSize'
                          label={'Maximum Floor Size' + '\xa0'}
                          type='string'
                          fullWidth
                          inputPlaceholder={'Type in floor size in sq.ft.'}
                          value={MaxFloorSize}
                          onChange={(event) => {
                            if (validNumber.test(event.target.value)) {
                              let a = event.target.value ? parseInt(event.target.value) : '';

                              SetMaxFloorSize(a);
                              setError('');
                            } else {
                              setError('maxFloor');
                            }
                          }}
                          onBlur={handleOnBlurMaxFloorSize}
                          variant='outlined'
                          error={IsValueInvalid(MaxFloorSize) && !FloorSizeError ? false : true}
                        />
                        {(!IsValueInvalid(MaxFloorSize) || FloorSizeError || error === 'maxFloor') && (
                          <CustomTypography
                            text={FloorSizeError ? 'Minimum floor size must be less than Maximum' : 'Invalid value'}
                            fontfamily='Calibre-R'
                            fontsize='11px'
                            fontweight='400'
                            lineheight='16px'
                            fontcolor='#FF543E'
                            marginLeft={12}
                            marginTop={12}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingBottom: 25 }}>
                    <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid
                        item
                        xs={6}
                        md={6}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          paddingRight: 14,
                          paddingTop: 8,
                          flexDirection: 'column',
                        }}
                      >
                        <CustomTextField
                          disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
                          id='Minimum Rent'
                          label={'Minimum Rent' + '\xa0'}
                          type='string'
                          fullWidth
                          inputPlaceholder={'Type in rent per sq.ft.'}
                          value={MinRentAmt}
                          onChange={(event) => {
                            if (validNumber.test(event.target.value)) {
                              let a = event.target.value ? parseInt(event.target.value) : '';

                              SetMinRentAmt(a);
                              setError('');
                            } else {
                              setError('minRent');
                            }
                          }}
                          onBlur={handleOnBlurMinRentAmt}
                          variant='outlined'
                          error={IsValueInvalid(MinRentAmt) && !RentAmtError ? false : true}
                        />
                        {(!IsValueInvalid(MinRentAmt) || RentAmtError || error === 'minRent') && (
                          <CustomTypography
                            text={RentAmtError ? 'Minimum rent amount must be less than Maximum' : 'Invalid value'}
                            fontfamily='Calibre-R'
                            fontsize='11px'
                            fontweight='400'
                            lineheight='16px'
                            fontcolor='#FF543E'
                            marginLeft={12}
                            marginTop={12}
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={6}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          paddingLeft: 14,
                          paddingTop: 8,
                          flexDirection: 'column',
                        }}
                      >
                        <CustomTextField
                          disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
                          id='Maximum Rent'
                          label={'Maximum Rent' + '\xa0'}
                          type='string'
                          fullWidth
                          inputPlaceholder={'Type in rent per sq.ft.'}
                          value={MaxRentAmt}
                          onChange={(event) => {
                            if (validNumber.test(event.target.value)) {
                              let a = event.target.value ? parseInt(event.target.value) : '';
                              SetMaxRentAmt(a);
                              setError('');
                            } else {
                              setError('maxRent');
                            }
                          }}
                          onBlur={handleOnBlurMaxRentAmt}
                          variant='outlined'
                          error={IsValueInvalid(MaxRentAmt) && !RentAmtError ? false : true}
                          // error={((MaxRentAmt == '' || MaxRentAmt == undefined) || (!isNaN(MaxRentAmt) && MaxRentAmt > -1)) ? false : true}
                        />
                        {(!IsValueInvalid(MaxRentAmt) || RentAmtError || error === 'maxRent') && (
                          <CustomTypography
                            text={RentAmtError ? 'Minimum rent amount must be less than Maximum' : 'Invalid value'}
                            // text="Invalid value"
                            fontfamily='Calibre-R'
                            fontsize='11px'
                            fontweight='400'
                            lineheight='16px'
                            fontcolor='#FF543E'
                            marginLeft={12}
                            marginTop={12}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingBottom: 25 }}>
                    <Grid
                      container
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        paddingTop: 8,
                      }}
                    >
                      <Grid
                        item
                        xs={6}
                        md={6}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          paddingRight: 14,
                          flexDirection: 'column',
                        }}
                      >
                        <DatePicker
                          disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
                          id='fromDate'
                          label={'Available from' + '\xa0'}
                          width={'100%'}
                          inputPlaceholder={'Choose from date'}
                          fullWidth
                          value={fromDate}
                          minDate={fromDate}
                          maxDate={toDate}
                          height={54}
                          border={false}
                          callback={handleFromDateCallback}
                        />
                        {isInvalidFromDate && fromDate != '' && (
                          <CustomTypography
                            text='Invalid date'
                            fontfamily='Calibre-R'
                            fontsize='11px'
                            fontweight='400'
                            lineheight='16px'
                            fontcolor='#FF543E'
                            marginLeft={12}
                            marginTop={12}
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={6}
                        style={{
                          display: 'flex',
                          paddingLeft: 14,
                          flexDirection: 'column',
                        }}
                      >
                        <DatePicker
                          disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
                          id='toDate'
                          label={'To' + '\xa0'}
                          width={'100%'}
                          value={toDate}
                          inputPlaceholder={'Choose to date'}
                          fullWidth
                          height={54}
                          minDate={fromDate}
                          border={false}
                          callback={handleToDateCallback}
                        />
                        {isInvalidToDate && (
                          <CustomTypography
                            text={
                              fromDate != '' && fromDate > toDate && toDate != ''
                                ? 'To date must be greater than from date'
                                : 'Invalid date'
                            }
                            fontfamily='Calibre-R'
                            fontsize='11px'
                            fontweight='400'
                            lineheight='16px'
                            fontcolor='#FF543E'
                            marginLeft={12}
                            marginTop={12}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingBottom: 25 }}>
                    <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          flexDirection: 'column',
                        }}
                      >
                        <CustomTypography
                          text='Location'
                          fontfamily='Calibre-R'
                          fontsize='24px'
                          fontweight='400'
                          lineheight='40px'
                          fontcolor='#1A1A1A'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingBottom: 25 }}>
                    <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          flexDirection: 'column',
                        }}
                      >
                        <AutoComplite
                          disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
                          key={'MARKET'}
                          title={'Market' + '\xa0'}
                          inputPlaceholder={'Add locations by market'}
                          data={marketDropdown}
                          selectedValue={marketValues}
                          handleChange={(e) => setMarketsValue(e)}
                          setFlag={setFlag}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingBottom: 25 }}>
                    <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          flexDirection: 'column',
                        }}
                      >
                        <AutoComplite
                          disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
                          key={'SUBMARKET'}
                          title={'Submarket' + '\xa0'}
                          inputPlaceholder={'Add locations by submarket'}
                          data={subMarketDropdown}
                          selectedValue={submarket}
                          handleChange={(e) => {
                            if (e.length === 0) {
                              setNeighbourhood([]);
                              setDistrict([]);
                            }
                            setSubmarket(e);
                          }}
                          setFlag={setFlag}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingBottom: 25 }}>
                    <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          flexDirection: 'column',
                        }}
                      >
                        <AutoComplite
                          disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
                          key={'district'}
                          title={'District' + '\xa0'}
                          inputPlaceholder={'Add locations by district'}
                          data={districtDropdown}
                          selectedValue={district}
                          handleChange={(e) => {
                            if (e.length === 0) {
                              setNeighbourhood([]);
                            }
                            setDistrict(e);
                          }}
                          setFlag={setFlag}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingBottom: 25 }}>
                    <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          flexDirection: 'column',
                        }}
                      >
                        <AutoComplite
                          disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
                          key={'neighborhood'}
                          title={'Neighbourhood' + '\xa0'}
                          inputPlaceholder={'Add locations by neighbourhood'}
                          data={neighbourhoodDropdown}
                          selectedValue={neighbourhood}
                          handleChange={(e) => setNeighbourhood(e)}
                          setFlag={setFlag}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingBottom: 25 }}>
                    <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid item xs={12} md={12}>
                        <HubsAutoComplete
                          title={'Preferred transport hubs\xa0\xa0\xa0'}
                          inputPlaceholder={'Add preferred tube or train stations'}
                          data={transportHubs}
                          selectedValue={transportHubsValue}
                          isSelected={true}
                          flag={flag}
                          setFlag={setFlag}
                          handleChange={(e) => setTransportHubsValue(e)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12} md={12} style={{ paddingBottom: 25 }}>
                    <Grid
                      container
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          flexDirection: 'column',
                        }}
                      >
                        <PostalAutoComplite
                          key={'postal'}
                          loading={loading}
                          title={'Postcode' + '\xa0'}
                          inputPlaceholder={'Add locations by postcode'}
                          data={PostalCodeDropdown}
                          selectedValue={postalCode}
                          isSelected={
                            marketValues.length > 0 ||
                            submarket.length > 0 ||
                            district.length > 0 ||
                            neighbourhood.length > 0
                          }
                          flag={flag}
                          setFlag={setFlag}
                          handleChange={(e) => {
                            setPostalCode(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid> */}
                </Grid>
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
      {openProperty && (
        <PropertyDetailsComponent
          open={openProperty}
          s_p_data={propertyObject}
          onClose={() => setOpenProperty(false)}
          handleStarProperty={handleStarProperty}
        />
      )}
      {successSnackbar && (
        <SuccessSnackBar
          open={true}
          setOpen={() => setSuccessSnackbar(null)}
          severity={successSnackbar.severity}
          name={successSnackbar.name}
          message={successSnackbar.message}
        />
      )}
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
      {isLoading && <LoadingOverlayComponent open={isLoading} />}
      {openAlert && (
        <AlertBar
          open={true}
          setOpen={() => setAlert(null)}
          name={'View only mode '}
          message={alertMessage}
          // date field is pending
          date={projectCompletedDate}
        />
      )}
    </div>
  );
};
export default AdvancedFiltersView;
