import React from "react";

function SwitchOffIcon({ ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="41"
            height="24"
            fill="none"
            viewBox="0 0 41 24"
            {...props}
        >
            <rect width="36" height="14" x="5" y="4" fill="#959595" rx="7"></rect>
            <g filter="url(#filter0_d_2432_12435)">
                <circle cx="12" cy="11" r="10" fill="#fff"></circle>
                <circle cx="12" cy="11" r="9.5" stroke="#778F9C"></circle>
            </g>
            <defs>
                <filter
                    id="filter0_d_2432_12435"
                    width="24"
                    height="24"
                    x="0"
                    y="0"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="1"></feOffset>
                    <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2432_12435"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2432_12435"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
    );
}

export default SwitchOffIcon;
