import React from 'react';
import Drawer from '@mui/material/Drawer';
const RightDrawer = ({ open, children, onClose, variant = 'permanent', width = 'unset', hideBackdrop = false }) => {
  return (
    <Drawer
      // BackdropProps={{ invisible: false }}
      hideBackdrop={hideBackdrop}
      anchor={'right'}
      variant={variant}
      open={open}
      onBackdropClick={onClose}
      PaperProps={{
        style: {
          width,
          boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.2)',
          overflowX: 'hidden',
          overflow: 'hidden',
        },
      }}
    >
      {children}
    </Drawer>
  );
};

export default RightDrawer;
