import React, { useEffect, useState, useContext } from 'react';
import CustomTypography from '../../../utils/text';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import WarningMessagelert from '../../../components/warning_snackbar';
import DefaultPieCharts from './defaultPieChart';
import DefaultRightBottomScreen from './defaultRightBottomScreen';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { getObject } from '../../../utils/utilities';
import ChartsContext from '../../../Providers/ChartsProvider';

function GlobalChart({ updateLoader }) {
  const hkey = useSelector((state) => state.mainReducer.hkey);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [scoringData, updateScoringData] = useState([]);
  const [transportData, updateTransportData] = useState([]);
  const [deliveryData, updateDeliveryData] = useState([]);
  const [esgData, updateEsgData] = useState([]);
  const [buildingData, updateBuildingData] = useState([]);
  const [localAmenityData, updateLocalAmenityData] = useState([]);
  const [customData, setCustomData] = useState([]);
  const [allWeightings, setAllWeightings] = useState();
  const axiosPrivate = useAxiosPrivate();
  const {
    charts: { setWeightings, setCustWeightings },
  } = useContext(ChartsContext);

  useEffect(() => {
    updateLoader(true);
    getAllWeightings();
  }, []);

  const getAllWeightings = async () => {
    try {
      const response = await axiosPrivate.get(`/charts-ranking/project-weightings/${hkey}`);
      if (response.status === 200 && response.data.length) {
        const keys = Object.keys(response.data[0]);
        let trSubWeightings = {},
          biSubWeightings = {},
          laSubWeightings = {},
          esgSubWeightings = {},
          drSubWeightings = {},
          mainWeightings = {};

        for (let key of keys) {
          switch (true) {
            case key.includes('TR_'):
              trSubWeightings[key] = response.data[0][key];
              break;
            case key.includes('BI_'):
              biSubWeightings[key] = response.data[0][key];
              break;
            case key.includes('DR_'):
              drSubWeightings[key] = response.data[0][key];
              break;
            case key.includes('ESG_'):
              esgSubWeightings[key] = response.data[0][key];
              break;
            case key.includes('LA_'):
              laSubWeightings[key] = response.data[0][key];
              break;
            default:
              mainWeightings[key] = response.data[0][key];
              break;
          }
        }

        updateData(mainWeightings);
        setWeightings(mainWeightings);
        updateTransportData(compareLogic(trSubWeightings));
        updateBuildingData(compareLogic(biSubWeightings));
        updateLocalAmenityData(compareLogic(laSubWeightings));
        updateEsgData(compareLogic(esgSubWeightings));
        updateDeliveryData(compareLogic(drSubWeightings));
        if (response.data[0].CUSTOM_WEIGHTAGE > 0) {
          const temp = getObject(response.data[0].CUSTOM_SUB_CAT);
          setCustomData(compareLogic(temp));
          setCustWeightings(Object.keys(temp).filter((el) => temp[el] > 0));
        }
        setAllWeightings(response.data[0]);
      }
    } catch (error) {
      handleErrorResponse();
    } finally {
      updateLoader(false);
    }
  };

  const handleErrorResponse = () => {
    setWarningSnackbar({
      name: 'Error:',
      message: 'Unable to fetch Global Weightings',
      severity: 'error',
    });
  };

  const compareLogic = (obj) => {
    const data = Object.entries(obj).sort((a, b) => b[1] - a[1]);
    let value = {};
    data.map((i) => {
      value[i[0]] = i[1];
    });
    return value;
  };

  const updateData = (input) => {
    updateScoringData([
      {
        name: 'Transport',
        fill: '#DBD99A',
        value: input.TRANSPORT,
      },
      {
        name: 'Building',
        fill: '#3E7CA6',
        value: input.BUILDING,
      },
      {
        name: 'Location & Amenity',
        fill: '#A388BF',
        value: input.LOCAL_AMENITY,
      },
      {
        name: 'ESG',
        fill: '#80BBAD',
        value: input.ESG,
      },
      {
        name: 'Deliverability & Risk',
        fill: '#885073',
        value: input.DELIVERABILITY_RISK,
      },
      {
        name: 'Custom Criteria',
        fill: '#96b0bf',
        value: input.CUSTOM_WEIGHTAGE,
      },
    ]);
  };

  return (
    <Grid
      xs={12}
      item
      style={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '3%',
      }}
    >
      <Grid container>
        <Grid item xs={12} style={{ marginLeft: '4%' }}>
          <CustomTypography
            text='Global Weightings'
            fontsize='30px'
            fontweight='400'
            lineheight='40px'
            fontcolor='#1A1A1A'
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: '2%',
            marginBottom: 20,
          }}
        >
          <DefaultPieCharts
            chartData={scoringData}
            transportData={transportData}
            deliveryData={deliveryData}
            esgData={esgData}
            buildingData={buildingData}
            localAmenityData={localAmenityData}
            customData={customData}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} style={{ marginLeft: '4%' }}>
          <CustomTypography
            text={'Global - most influential criteria'}
            fontsize='30px'
            lineheight='40px'
            fontweight='400'
            fontcolor='#1A1A1A'
          />
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: 0, paddingTop: 0 }}>
          <DefaultRightBottomScreen allWeightings={allWeightings} />
        </Grid>
      </Grid>
      {warningSnackbar && (
        <WarningMessagelert
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
    </Grid>
  );
}

export default GlobalChart;
