import React from 'react';

function DataviewUnCheckBoxIcon({ color = '#767676', ...props }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      fill='none'
      viewBox='0 0 18 18'
      {...props}
    >
      <path
        fill={color}
        fillRule='evenodd'
        d='M0 0h18v18H0V0zm16 16V2H2v14h14z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default DataviewUnCheckBoxIcon;
