import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { TextField, Grid } from '@material-ui/core';
import useOutlinedInputStyles from '../styles/textfeild_style';
const minDistance = 0;
export default function MinimumDistanceSlider({
  handleCallabck,
  score,
  maxValue = 100,
}) {
  const outlinedInputClasses = useOutlinedInputStyles();
  const [value1, setValue1] = useState([0, maxValue]);
  const validValue = /^\d+$/;
  useEffect(() => {
    setValue1(score);
  }, [score]);
  const valuetext = (value) => {
    return `${value}`;
  };
  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
      handleCallabck([
        Math.min(newValue[0], value1[1] - minDistance),
        value1[1],
      ]);
    } else {
      if (
        Number(Math.max(newValue[1], value1[0] + minDistance)) >= value1[0] &&
        Number(Math.max(newValue[1], value1[0] + minDistance)) <= maxValue
      ) {
        setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
        handleCallabck([
          value1[0],
          Math.max(newValue[1], value1[0] + minDistance),
        ]);
      }
    }
  };
  const handleMinBlur = (value) => {
    if (value1[0] < 0) {
      setValue1([0, value1[1]]);
    }
  };
  const handleMaxBlur = () => {
    if (value1[1] < 0) {
      setValue1([value1[0], 0]);
    }
  };
  const handleMinInputChange = (event) => {
    const val = event.target.value;
    if (val && !validValue.test(val)) return;
    if (Number(val) <= value1[1] && Number(val) <= maxValue) {
      setValue1([Number(val), value1[1]]);
      handleCallabck([Number(val), value1[1]]);
    }
  };
  const handleMaxInputChange = (event) => {
    const val = event.target.value;
    if (val && !validValue.test(val)) return;
    if (Number(val) >= value1[0] && Number(val) <= maxValue) {
      setValue1([value1[0], Number(val)]);
      handleCallabck([value1[0], Number(val)]);
    }
  };
  return (
    <Grid container alignItems='center'>
      <Grid
        item
        xs={1}
        style={{ display: 'flex', alignItems: 'flex-start', paddingRight: 6 }}
      >
        <TextField
          style={{ width: 46 }}
          value={value1[0]}
          className={outlinedInputClasses.root}
          size='small'
          variant='outlined'
          onChange={handleMinInputChange}
          onBlur={handleMinBlur}
          inputProps={{
            type: 'tel',
            style: {
              padding: '8px',
              paddingLeft:
                parseInt(value1[0]) < 100
                  ? parseInt(value1[0] < 10 ? '16px' : '12px')
                  : '8px',
            },
          }}
        />
      </Grid>
      <Grid
        item
        xs={9}
        md={9}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 10,
          marginLeft: 10,
        }}
      >
        <Slider
          getAriaLabel={() => 'Minimum distance'}
          value={value1}
          max={maxValue}
          onChange={handleChange1}
          valueLabelDisplay='auto'
          getAriaValueText={valuetext}
          disableSwap
        />
      </Grid>
      <Grid
        item
        xs={1}
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'end',
          paddingLeft: 6,
        }}
      >
        <TextField
          style={{ width: 46 }}
          value={value1[1]}
          className={outlinedInputClasses.root}
          size='small'
          variant='outlined'
          onChange={handleMaxInputChange}
          onBlur={handleMaxBlur}
          inputProps={{
            type: 'tel',
            style: {
              padding: 8,
              paddingLeft:
                parseInt(value1[1]) < 100
                  ? parseInt(value1[1]) < 10
                    ? '16px'
                    : '12px'
                  : '8px',
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
