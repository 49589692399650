import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '../../icons/collapse_left_icon';
import ChevronRightIcon from '../../icons/collapse_right_icon';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  drawer: {
    height: 'calc(100%)',
    position: 'relative',
  },
  drawerOpen: {
    width: 200,
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
  },
  drawerClose: {
    overflowX: 'hidden',
    width: 70,
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
  },
  content: {
    flexGrow: 1,
  },
  arrow: {
    position: 'fixed',
    marginTop: 20,
  },
  arrowOpen: {
    left: 185,
  },
  arrowClose: {
    left: 55,
  },
}));

const MiniDrawer = ({ children, toggleDrawer, open, classes1 }) => {
  const classes = useStyles();
  const handleDrawerOpen = () => {
    toggleDrawer();
  };
  return (
    <Drawer
      variant='permanent'
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes1.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <Grid
        className={clsx(classes.arrow, {
          [classes.arrowOpen]: open,
          [classes.arrowClose]: !open,
        })}
      >
        {open ? (
          <ChevronLeftIcon
            onClick={handleDrawerOpen}
            style={{ cursor: 'pointer' }}
          />
        ) : (
          <ChevronRightIcon
            onClick={handleDrawerOpen}
            style={{ cursor: 'pointer' }}
          />
        )}
      </Grid>
      {children}
    </Drawer>
  );
};

export default MiniDrawer;
