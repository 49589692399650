import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '../icons/close_icon';
import ConfirmationIcon from '../icons/confirmation_icon';
import CustomTypography from '../utils/text';
import useStyles from '../styles';
import DeleteIconConfirmation from '../icons/delete_icon_confirmation';
import ArchiveIcon from '../icons/archive_icon';
import UnArchiveProjectIcon from '../icons/unarchive_project_icon';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.24)',
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ConfirmationPopup({
  openPopup = false,
  closePopup,
  popUpMessage,
  popUpCallback,
  popUpItem,
}) {
  const [open, setOpen] = useState(openPopup);
  const classes = useStyles();

  useState(() => {
    setOpen(openPopup);
  }, [openPopup]);

  const handleClose = () => {
    setOpen(false);
    closePopup();
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <Grid
          container
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '510px',
            width: '624px',
          }}
        >
          <Grid
            xs={12}
            style={{
              display: 'flex',
            }}
          >
            <Grid
              xs={11}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                maxWidth: '60%',
              }}
            >
              <CustomTypography
                text='Are you sure?'
                fontsize='24px'
                fontweight='600'
                lineheight='32px'
                fontcolor='#1F1E1E'
              />
            </Grid>

            <Grid
              xs={1}
              style={{
                display: 'flex',
                alignItems: 'flex-top',
                justifyContent: 'flex-end',
                paddingLeft: 150,
                paddingTop: 5,
              }}
            >
              <div style={{ cursor: 'pointer' }} onClick={handleClose}>
                <CloseIcon />
              </div>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {popUpMessage.source === 'COMPLETE_PROJECT' && <ConfirmationIcon />}
            {popUpMessage.source === 'ARCHIVE_PROJECT' && <ArchiveIcon />}
            {popUpMessage.source === 'DELETE_PROJECT' && (
              <DeleteIconConfirmation />
            )}
            {popUpMessage.source === 'UN_ARCHIVE_PROJECT' && (
              <UnArchiveProjectIcon />
            )}
          </Grid>
          <HtmlTooltip
            placement='top'
            title={popUpMessage.title.length > 50 ? popUpMessage.title : ''}
          >
            <Grid
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CustomTypography
                text={
                  popUpMessage.title.length > 50
                    ? popUpMessage.title.slice(0, 50) + '...'
                    : popUpMessage.title
                }
                fontsize='20px'
                fontweight='400'
                lineheight='20px'
                fontcolor='rgba(0, 0, 0, 1)'
                letterSpacing='-0.01em'
              />
            </Grid>
          </HtmlTooltip>
          <Grid
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              className={classes.backbtn}
              style={{ textTransform: 'inherit', background: 'none' }}
              onClick={handleClose}
            >
              <CustomTypography
                text='I changed my mind'
                fontfamily='Calibre-R'
                fontsize='16px'
                fontweight='500'
                fontcolor='#003F2D'
                lineheight='16px'
              />
            </Button>
            <Button
              className={
                popUpMessage.source === 'DELETE_PROJECT'
                  ? classes.DeletebuttonClass
                  : classes.buttonClass
              }
              style={{ textTransform: 'inherit', marginLeft: 20 }}
              onClick={() => popUpCallback(popUpItem, popUpMessage.source)}
            >
              {/* confirm button */}
              <CustomTypography
                text={popUpMessage.confirm_button_text}
                fontsize='16px'
                fontfamily='Calibre-R'
                fontweight='500'
                lineheight='16px'
                fontcolor={'white'}
              />
            </Button>
          </Grid>
          <Grid
            xs={8}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CustomTypography
              text={popUpMessage.footer_text}
              textAlign='center'
              fontsize='16px'
              fontweight='400'
              lineheight='24px'
              fontcolor={'rgba(26, 26, 26, 0.6)'}
            />
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}

// <BootstrapDialogTitle
// id="customized-dialog-title"
// onClose={handleClose}
// ></BootstrapDialogTitle>
