import React from 'react';

function OffMarketIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='70'
      height='18'
      fill='none'
      viewBox='0 0 70 18'
    >
      <rect width='70' height='17' y='1' fill='#BAA6CF' rx='8.5'></rect>
      <path
        fill='#fff'
        d='M14.412 5.71c-1.88 0-3.24 1.53-3.24 3.7s1.36 3.7 3.24 3.7c1.89 0 3.26-1.53 3.26-3.7s-1.37-3.7-3.26-3.7zm0 .83c1.39 0 2.32 1.19 2.32 2.87 0 1.68-.93 2.87-2.32 2.87-1.38 0-2.31-1.19-2.31-2.87 0-1.68.93-2.87 2.31-2.87zm10.023-1.13c-.2-.09-.4-.12-.68-.12-.91 0-1.59.61-1.59 1.65v.87h-2.18v-.73c0-.62.34-.96.85-.96.19 0 .38.05.55.15v-.79c-.19-.09-.4-.12-.68-.12-.91 0-1.59.61-1.59 1.65v.8h-.88v.74h.88V13h.87V8.55h2.18V13h.87V8.55h1.18v-.74h-1.18v-.8c0-.62.34-.96.85-.96.19 0 .38.05.55.15v-.79zm.366 4.94h2.82v-.81H24.8v.81zM35.67 13V9.56c0-1.12-.58-1.86-1.63-1.86-.72 0-1.25.36-1.59.83-.23-.49-.74-.83-1.4-.83-.64 0-1.14.31-1.39.71v-.6h-.89V13h.89V9.42c.15-.56.61-.92 1.14-.92.66 0 .97.47.97 1.2V13h.89V9.42c.16-.56.62-.92 1.15-.92.66 0 .98.47.98 1.2V13h.88zm4.328-1.29c-.15.39-.67.65-1.22.65-.63 0-1.25-.3-1.25-.92 0-.62.62-.92 1.25-.92.55 0 1.07.27 1.22.65v.54zm.84 1.29V9.54c0-1.14-.74-1.84-1.97-1.84-1 0-1.77.6-1.94 1.48h.85c.17-.49.52-.72 1.07-.72.75 0 1.15.46 1.15 1.11v.66c-.23-.2-.77-.43-1.31-.43-1.12 0-1.98.66-1.98 1.64 0 1.02.86 1.65 1.93 1.65.61 0 1.16-.26 1.36-.47V13h.84zm4.084-5.22c-.09-.05-.29-.08-.52-.08-.57 0-1.06.34-1.27.78v-.67h-.89V13h.89V9.51c.17-.61.65-.97 1.2-.97.21 0 .44.05.59.14v-.9zm4.4 5.22h1.07l-2.41-2.95 2.33-2.24h-1.15l-2.37 2.3V5.39h-.89V13h.89v-1.86l.55-.53 1.98 2.39zm5.043-1.56c-.15.59-.63.91-1.28.91-.87 0-1.49-.68-1.51-1.78h3.68v-.32c0-1.5-.76-2.55-2.18-2.55-1.42 0-2.37 1.16-2.37 2.71 0 1.61.97 2.7 2.38 2.7 1.18 0 1.91-.68 2.11-1.67h-.83zm.01-1.54h-2.77c.12-.91.68-1.45 1.47-1.45.87 0 1.27.68 1.3 1.45zm2.191 1.8c0 .96.59 1.41 1.43 1.41.33 0 .63-.07.83-.18v-.81c-.18.1-.44.2-.69.2-.43 0-.68-.22-.68-.72V8.55h1.25v-.74h-1.25V6.44h-.89v1.37h-.83v.74h.83v3.15z'
      ></path>
    </svg>
  );
}

export default OffMarketIcon;
