import React, { useEffect, useState } from 'react';
import CustomTypography from '../../../utils/text';
import { Grid } from '@material-ui/core';
import IndividualBarChart from './individualBarChart';
import { useSelector } from 'react-redux';
import WarningMessagelert from '../../../components/warning_snackbar';
import IndividualPieCharts from './individualPieChart';

function IndividualChart() {
  const selectedIndividualPropertyData = useSelector(
    (state) => state.mainReducer.selectedIndividualPropertyData
  );

  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [scoringData, updateScoringData] = useState([]);

  useEffect(() => {
    updateScoringData([
      {
        name: 'Transport',
        fill: '#DBD99A',
        value: selectedIndividualPropertyData.mainCategoryScore.TRANSPORT_SCORE,
      },
      {
        name: 'Building',
        fill: '#3E7CA6',
        value: selectedIndividualPropertyData.mainCategoryScore.BUILDING_SCORE,
      },
      {
        name: 'Location & Amenity',
        fill: '#A388BF',
        value:
          selectedIndividualPropertyData.mainCategoryScore.LOCAL_AMENITY_SCORE,
      },
      {
        name: 'ESG',
        fill: '#80BBAD',
        value: selectedIndividualPropertyData.mainCategoryScore.ESG_SCORE,
      },
      {
        name: 'Deliverability & Risk',
        fill: '#885073',
        value:
          selectedIndividualPropertyData.mainCategoryScore
            .DELIVERABILITY_RISK_SCORE,
      },
      {
        name: 'Custom Criteria',
        fill: '#96b0bf',
        value: selectedIndividualPropertyData.mainCategoryScore.CUSTOM_SCORE,
      },
      {
        name: 'Available points',
        fill: '	rgb(255,255,255)',
        value: 100 - selectedIndividualPropertyData.overAllScore,
      },
    ]);
  }, [selectedIndividualPropertyData?.mainCategoryScore]);

  return (
    <Grid
      xs={12}
      item
      style={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 15,
      }}
    >
      <Grid container>
        <Grid item xs={12} style={{ marginLeft: '25px' }}>
          <CustomTypography
            text={`${selectedIndividualPropertyData?.address} - Score Breakdown`}
            fontsize='30px'
            fontweight='400'
            lineheight='40px'
            fontcolor='#1A1A1A'
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: '12px',
          }}
        >
          <IndividualPieCharts
            chartData={scoringData}
            transportData={selectedIndividualPropertyData?.transport}
            deliveryData={selectedIndividualPropertyData?.deliveryability}
            esgData={selectedIndividualPropertyData?.esg}
            buildingData={selectedIndividualPropertyData?.building}
            localAmenityData={selectedIndividualPropertyData?.localAmenity}
            customData={selectedIndividualPropertyData?.customCriteria}
            overAllScore={selectedIndividualPropertyData?.overAllScore}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} style={{ marginLeft: '25px', paddingTop: 20 }}>
          <CustomTypography
            text={'Most influential criteria'}
            fontsize='30px'
            lineheight='40px'
            fontweight='400'
            fontcolor='#1A1A1A'
          />
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: 0 }}>
          <IndividualBarChart
            subCategoryScore={
              selectedIndividualPropertyData?.allSubCategoryScore
            }
          />
        </Grid>
      </Grid>
      {warningSnackbar && (
        <WarningMessagelert
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
    </Grid>
  );
}

export default IndividualChart;
