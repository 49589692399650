import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  errorBox: {
    position: 'absolute',
    width: '319px',
    Height: '93px',
    Top: '211px',
    Left: '31px',
    Radius: '4px',
    Gap: '48px',
    sizing: 'border-box',
    marginTop: 20,
  },
  sidebarStyle: {
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '12px',
    backgroundColor: ' #404040',
    color: ' #ffffff',
    zIndex: '1 !important',
    padding: '6px',
    fontWeight: 'bold',
  },
  btn: {
    '&:hover': {
      // background: 'white',
      // color: '#2d6950',
      color: 'white',
      background: '#003F2D',
      border: '1px solid #0000001a',
    },
    background: '#003F2D',
    color: 'white',
  },
  btn_text: {
    '&:hover': {
      color: 'red',
    },
  },
}));

export default useStyles;
