import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import CustomTypography from '../../utils/text';
import { Button } from '@material-ui/core';
import useStyles from '../../styles';
import GlobalChart from './rightSection/globalChart';
import PropertyChart from './leftSection/propertiesChart';
import { useNavigate } from 'react-router-dom';
import Calculator from '../../icons/calc_icon';
import CompareProperties from '../../components/CompareProperties';
import LoadingOverlayComponent from '../../components/lodding_component';
import IndividualChart from './individualProperty/individualChart';
import { useSelector } from 'react-redux';
import AlertBar from '../../components/alertBar';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const Charts = () => {
  const [open, setOpen] = useState(false);
  const [properties, setProperties] = useState([]);
  const [data, setData] = useState([]);
  const [allowedLabels, setAllowedLabels] = useState([]);
  const classes = useStyles();
  const navigate = useNavigate();
  const hwgtkey = useSelector((state) => state.mainReducer.hwgtkey);
  const hkey = useSelector((state) => state.mainReducer.hkey);
  const [isLoading, setIsLoading] = useState(false);
  const [propertiesLoading, setPropertiesLoading] = useState(false);
  const [retainedProperties, setRetainedProperties] = useState([]);
  const [retainedLoading, setRetainedLoading] = useState(false);
  const showIndividualProperty = useSelector((state) => state.mainReducer.isIndividualProperty);

  const projectStatus = useSelector((state) => state.mainReducer.selectedProjectStatus);
  const projectCompletedDate = useSelector((state) => state.mainReducer.selectedProjectCompletedDate);
  const [openAlert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    if (projectStatus === 'Completed' || projectStatus === 'Archived') {
      setAlert(true);
      projectStatus === 'Completed'
        ? setAlertMessage('- This project was marked as completed on')
        : setAlertMessage('- This project was marked as archived on');
    }
  }, [projectStatus]);

  const updateLoader = (status) => {
    setIsLoading(status);
  };

  useEffect(() => {
    setPropertiesLoading(true);
    setRetainedLoading(true);
    getProperties();
    getShowLabels();
  }, []);

  const getProperties = async (flag) => {
    try {
      const reqObj = {
        hkey: hkey,
        hwgtkey: hwgtkey,
      };
      const response = await axiosPrivate.post(`/charts-ranking/properties`, reqObj);
      if (response.status === 200) {
        filterProperties(response.data);
      }
    } catch (error) {
      console.log('Error while fetching the properties...!', error);
    } finally {
      setRetainedLoading(false);
      setPropertiesLoading(false);
    }
  };
  const filterProperties = (response) => {
    const medals = { 1: 'gold', 2: 'silver', 3: 'bronze' };
    const temp = [...response];
    let comparedProperties = [],
      otherProperties = [];
    const updatedResp = response.map((el) => ({
      ...el,
      BUILDING_SCORE: Math.round(el.BUILDING_SCORE),
      CUSTOM_SCORE: Math.round(el.CUSTOM_SCORE),
      DELIVERABILITY_RISK_SCORE: Math.round(el.DELIVERABILITY_RISK_SCORE),
      ESG_SCORE: Math.round(el.ESG_SCORE),
      LOCAL_AMENITY_SCORE: Math.round(el.LOCAL_AMENITY_SCORE),
      TRANSPORT_SCORE: Math.round(el.TRANSPORT_SCORE),
    }));
    const bFlag = updatedResp
      .filter((el) => el.BEST_BUILDING_FLAG === 1)
      .sort((a, b) => a.BUILDING_SCORE - b.BUILDING_SCORE);
    const bPivot = bFlag.at(0)?.BUILDING_SCORE;

    const cFlag = updatedResp.filter((el) => el.BEST_CUSTOM_FLAG === 1).sort((a, b) => a.CUSTOM_SCORE - b.CUSTOM_SCORE);
    const cPivot = cFlag.at(0)?.CUSTOM_SCORE;

    const dFlag = updatedResp
      .filter((el) => el.BEST_DELIVERABILITY_RISK_FLAG === 1)
      .sort((a, b) => a.DELIVERABILITY_RISK_SCORE - b.DELIVERABILITY_RISK_SCORE);
    const dPivot = dFlag.at(0)?.DELIVERABILITY_RISK_SCORE;

    const eFlag = updatedResp.filter((el) => el.BEST_ESG_FLAG === 1).sort((a, b) => a.ESG_SCORE - b.ESG_SCORE);
    const ePivot = eFlag.at(0)?.ESG_SCORE;

    const lFlag = updatedResp
      .filter((el) => el.BEST_LOCAL_AMENITY_FLAG === 1)
      .sort((a, b) => a.LOCAL_AMENITY_SCORE - b.LOCAL_AMENITY_SCORE);
    const lPivot = lFlag.at(0)?.LOCAL_AMENITY_SCORE;

    const tFlag = updatedResp
      .filter((el) => el.BEST_TRANSPORT_FLAG === 1)
      .sort((a, b) => a.TRANSPORT_SCORE - b.TRANSPORT_SCORE);
    const tPivot = tFlag.at(0)?.TRANSPORT_SCORE;

    const bbFlag = updatedResp
      .filter((el) => el.BOTTOM_BUILDING_FLAG === 1)
      .sort((a, b) => b.BUILDING_SCORE - a.BUILDING_SCORE);
    const bbPivot = bbFlag.at(0)?.BUILDING_SCORE;

    const bcFlag = updatedResp
      .filter((el) => el.BOTTOM_CUSTOM_FLAG === 1)
      .sort((a, b) => b.CUSTOM_SCORE - a.CUSTOM_SCORE);
    const bcPivot = bcFlag.at(0)?.CUSTOM_SCORE;

    const bdFlag = updatedResp
      .filter((el) => el.BOTTOM_DELIVERABILITY_RISK_FLAG === 1)
      .sort((a, b) => b.DELIVERABILITY_RISK_SCORE - a.DELIVERABILITY_RISK_SCORE);
    const bdPivot = bdFlag.at(0)?.DELIVERABILITY_RISK_SCORE;

    const beFlag = updatedResp.filter((el) => el.BOTTOM_ESG_FLAG === 1).sort((a, b) => b.ESG_SCORE - a.ESG_SCORE);
    const bePivot = beFlag.at(0)?.ESG_SCORE;

    const blFlag = updatedResp
      .filter((el) => el.BOTTOM_LOCAL_AMENITY_FLAG === 1)
      .sort((a, b) => b.LOCAL_AMENITY_SCORE - a.LOCAL_AMENITY_SCORE);
    const blPivot = blFlag.at(0)?.LOCAL_AMENITY_SCORE;

    const btFlag = updatedResp
      .filter((el) => el.BOTTOM_TRANSPORT_FLAG === 1)
      .sort((a, b) => b.TRANSPORT_SCORE - a.TRANSPORT_SCORE);
    const btPivot = btFlag.at(0)?.TRANSPORT_SCORE;

    const tempResp = updatedResp.map((el) => {
      const item = { ...el };
      if (bPivot === el.BUILDING_SCORE) item.BEST_BUILDING_FLAG = 1;
      if (cPivot === el.CUSTOM_SCORE) item.BEST_CUSTOM_FLAG = 1;
      if (dPivot === el.DELIVERABILITY_RISK_SCORE) item.BEST_DELIVERABILITY_RISK_FLAG = 1;
      if (ePivot === el.ESG_SCORE) item.BEST_ESG_FLAG = 1;
      if (lPivot === el.LOCAL_AMENITY_SCORE) item.BEST_LOCAL_AMENITY_FLAG = 1;
      if (tPivot === el.TRANSPORT_SCORE) item.BEST_TRANSPORT_FLAG = 1;

      if (bbPivot === el.BUILDING_SCORE) item.BOTTOM_BUILDING_FLAG = 1;
      if (bcPivot === el.CUSTOM_SCORE) item.BOTTOM_CUSTOM_FLAG = 1;
      if (bdPivot === el.DELIVERABILITY_RISK_SCORE) item.BOTTOM_DELIVERABILITY_RISK_FLAG = 1;
      if (bePivot === el.ESG_SCORE) item.BOTTOM_ESG_FLAG = 1;
      if (blPivot === el.LOCAL_AMENITY_SCORE) item.BOTTOM_LOCAL_AMENITY_FLAG = 1;
      if (btPivot === el.TRANSPORT_SCORE) item.BOTTOM_TRANSPORT_FLAG = 1;

      if (item.BEST_BUILDING_FLAG === item.BOTTOM_BUILDING_FLAG) {
        item.BEST_BUILDING_FLAG = false;
        item.BOTTOM_BUILDING_FLAG = false;
      }
      if (item.BEST_CUSTOM_FLAG === item.BOTTOM_CUSTOM_FLAG) {
        item.BEST_CUSTOM_FLAG = false;
        item.BOTTOM_CUSTOM_FLAG = false;
      }
      if (item.BEST_DELIVERABILITY_RISK_FLAG === item.BOTTOM_DELIVERABILITY_RISK_FLAG) {
        item.BEST_DELIVERABILITY_RISK_FLAG = false;
        item.BOTTOM_DELIVERABILITY_RISK_FLAG = false;
      }
      if (item.BEST_ESG_FLAG === item.BOTTOM_ESG_FLAG) {
        item.BEST_ESG_FLAG = false;
        item.BOTTOM_ESG_FLAG = false;
      }
      if (item.BEST_LOCAL_AMENITY_FLAG === item.BOTTOM_LOCAL_AMENITY_FLAG) {
        item.BEST_LOCAL_AMENITY_FLAG = false;
        item.BOTTOM_LOCAL_AMENITY_FLAG = false;
      }

      if (item.BEST_TRANSPORT_FLAG === item.BOTTOM_TRANSPORT_FLAG) {
        item.BEST_TRANSPORT_FLAG = false;
        item.BOTTOM_TRANSPORT_FLAG = false;
      }

      return { ...item };
    });
    tempResp.forEach((elm, i) => {
      temp[i] = {
        ...elm,
        BADGES: {
          topRanked: medals[elm.TOP_RANKED],
          Transport: !!elm.BEST_TRANSPORT_FLAG,
          Building: !!elm.BEST_BUILDING_FLAG,
          ESG: !!elm.BEST_ESG_FLAG,
          'Location & Amenity': !!elm.BEST_LOCAL_AMENITY_FLAG,
          'Deliverability & Risk': !!elm.BEST_DELIVERABILITY_RISK_FLAG,
          topFinancial: elm.FINANCIAL_RNK <= 5,
          'Custom Criteria': !!elm.BEST_CUSTOM_FLAG,
        },
        bottomFlags: {
          transport: !!elm.BOTTOM_TRANSPORT_FLAG,
          building: !!elm.BOTTOM_BUILDING_FLAG,
          esg: !!elm.BOTTOM_ESG_FLAG,
          location: !!elm.BOTTOM_LOCAL_AMENITY_FLAG,
          deliverability: !!elm.BOTTOM_DELIVERABILITY_RISK_FLAG,
          custCriteria: !!elm.BOTTOM_CUSTOM_FLAG,
        },
      };
      if (elm.PROPERTY_COMPARE) comparedProperties = comparedProperties.concat(temp[i]);
      else otherProperties = otherProperties.concat(temp[i]);
    });
    setRetainedProperties(comparedProperties);
    const props = otherProperties
      .filter((elm) => elm.BUILDING_NAME)
      .map((el) => ({
        label: getFormattedValue(el.BUILDING_NAME),
        value: el.BUILDING_NAME,
        id: el.PROPERTY_CODE,
      }));
    setData(temp);
    setProperties(props);
  };

  const getShowLabels = async () => {
    try {
      const response = await axiosPrivate.get(`/charts-ranking/show-labels?hkey=${hkey}&hwgtkey=${hwgtkey}`);
      if (response.status === 200 && response.data.length > 0) {
        let allowed = [];
        if (response.data && !!response.data.length)
          for (let key in response.data[0]) {
            if (response.data[0][key]) {
              allowed = allowed.concat(key);
            }
          }

        setAllowedLabels(allowed);
      }
    } catch (error) {
      console.log('Error while fetching the show labels...!', error);
    }
  };

  return (
    <Grid
      style={{
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: 'rgb(238, 241, 241)',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <Grid
        container
        style={{
          display: 'flex',
          height: '6vh',
          flexDirection: 'row',
          marginTop: '3.5%',
        }}
      >
        <Grid
          item
          xs={7}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            paddingLeft: '4%',
          }}
        >
          <CustomTypography
            text={`Charts & Rankings`}
            fontsize='36px'
            fontweight='400'
            fontcolor='#1A1A1A'
            lineheight='40px'
            justifycontent='flex-start'
          />
        </Grid>
        <Grid
          item
          xs={5}
          style={{
            paddingRight: '4%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
            className={
              projectStatus === 'Completed' || projectStatus === 'Archived' ? classes.disablebtnClass : classes.backbtn
            }
            style={{
              textTransform: 'inherit',
              background: projectStatus === 'Completed' || projectStatus === 'Archived' ? '#d4d7d7' : 'none',
            }}
            onClick={() => navigate('/map/updateweightingsscores')}
          >
            <CustomTypography
              text='Edit weightings'
              fontfamily='Calibre-SB'
              fontsize='16px'
              fontweight='500'
              fontcolor={projectStatus === 'Completed' || projectStatus === 'Archived' ? '#7f8080' : '#003F2D'}
              lineheight='16px'
            />
          </Button>
          <Button
            className={classes.buttonClass}
            style={{ textTransform: 'inherit', marginLeft: '5%' }}
            onClick={() => setOpen(true)}
          >
            <Calculator /> &nbsp;&nbsp;
            <CustomTypography
              text={'Compare properties'}
              fontsize='16px'
              fontfamily='Calibre-SB'
              fontweight='500'
              lineheight='16px'
              fontcolor={'#eee'}
            />
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '1%',
          paddingLeft: '2%',
          paddingRight: '2%',
          width: '100%',
        }}
      >
        <Grid item style={{ backgroundColor: 'white', width: '58%' }}>
          <PropertyChart updateLoader={updateLoader} />
        </Grid>
        <Grid
          item
          style={{
            backgroundColor: 'white',
            marginLeft: '1.1%',
            width: '40.9%',
          }}
        >
          {showIndividualProperty ? (
            <IndividualChart updateLoader={updateLoader} />
          ) : (
            <GlobalChart updateLoader={updateLoader} />
          )}
        </Grid>
      </Grid>
      <CompareProperties
        open={open}
        setOpen={setOpen}
        buildings={properties}
        setProperties={setProperties}
        data={data}
        allowedLabels={allowedLabels}
        loading={propertiesLoading}
        retained={retainedProperties}
        retainedLoading={retainedLoading}
      />
      {isLoading && <LoadingOverlayComponent open={isLoading} />}
      {openAlert && (
        <AlertBar
          open={true}
          setOpen={() => setAlert(null)}
          name={'View only mode '}
          message={alertMessage}
          // date field is pending
          date={projectCompletedDate}
        />
      )}
    </Grid>
  );
};
export default Charts;

export const getFormattedValue = (str) => {
  const strng = str
    .toLowerCase()
    .split(' ')
    .map((el) => el.charAt(0).toUpperCase() + el.slice(1))
    .join(' ');
  return strng.length > 17 ? strng?.slice(0, 16) + '...' : strng;
};
