import React from 'react';
import { Radio, FormControlLabel } from '@material-ui/core';
import CustomTypography from '../utils/text';
import CheckedRadioIcon from '../icons/checked_radio_icon';
import UnCheckedRadioIcon from '../icons/unchecked_radio_icon';
const CustomCheckbox = ({
  label,
  checkedColor = '#003f2d',
  unCheckColor = '#767676',
  marginLeft,
  marginRight = 0,
  checked,
  ...props
}) => {
  return (
    <FormControlLabel
      style={{ marginLeft: marginLeft, marginRight: marginRight }}
      control={
        <Radio
          {...props}
          checked={checked}
          checkedIcon={<CheckedRadioIcon color={checkedColor} />}
          icon={<UnCheckedRadioIcon color={unCheckColor} />}
        />
      }
      label={
        <CustomTypography
          text={label}
          fontsize='16px'
          fontweight='400'
          lineheight='24px'
          fontcolor={checked ? '#363636' : '#1a1a1a99'}
        />
      }
    />
  );
};

export default CustomCheckbox;
