const TopFinancial = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.832 11.6673V5.00065C15.832 4.08398 15.082 3.33398 14.1654 3.33398H2.4987C1.58203 3.33398 0.832031 4.08398 0.832031 5.00065V11.6673C0.832031 12.584 1.58203 13.334 2.4987 13.334H14.1654C15.082 13.334 15.832 12.584 15.832 11.6673ZM8.33203 10.834C6.9487 10.834 5.83203 9.71732 5.83203 8.33398C5.83203 6.95065 6.9487 5.83398 8.33203 5.83398C9.71536 5.83398 10.832 6.95065 10.832 8.33398C10.832 9.71732 9.71536 10.834 8.33203 10.834ZM19.1654 5.83398V15.0007C19.1654 15.9173 18.4154 16.6673 17.4987 16.6673H3.33203V15.0007H17.4987V5.83398H19.1654Z'
      fill='#80BBAD'
    />
  </svg>
);

export default TopFinancial;
