import React from 'react';
import styles from '../styles/loginStyles';
function StarOpenIcon(props) {
  const classes = styles();
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.00032 14.5196L13.1503 17.0296C13.9103 17.4896 14.8403 16.8096 14.6403 15.9496L13.5403 11.2296L17.2103 8.04958C17.8803 7.46958 17.5203 6.36958 16.6403 6.29958L11.8103 5.88958L9.92032 1.42958C9.58032 0.619583 8.42032 0.619583 8.08032 1.42958L6.19032 5.87958L1.36032 6.28958C0.480317 6.35958 0.120317 7.45958 0.790317 8.03958L4.46032 11.2196L3.36032 15.9396C3.16032 16.7996 4.09032 17.4796 4.85032 17.0196L9.00032 14.5196Z'
        fill={props?.color ?? '#EFDA19'}
      />
    </svg>
  );
}

export default StarOpenIcon;
