import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlayComponent from '../components/lodding_component';
import { Grid } from '@material-ui/core';
import RightDrawer from './right_drawer';
import CustomTextField from '../components/custom_textfield';
import '../styles/dataGridview.css';
import CustomTypography from '../utils/text';
import WarningMessage from '../components/warning_snackbar';
import AutoComplite from '../components/autocomplite_textfeild';
import CustomCheckbox from './custom_checkbox';
import DatePicker from '../components/date_picker';
import moment from 'moment';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
const BenchMarkingFilter = ({ open, onClose, onCallback, handleApplyCallback }) => {
  const IndustryCode = useSelector((state) => state.mainReducer.industryCode);
  const locationData = useSelector((state) => state.mainReducer.locationData);
  const hubUserKey = useSelector((state) => state.mainReducer.hubUser);
  const LandlardData = useSelector((state) => state.mainReducer.landlardData);
  const [industryCodeValue, setIndustryCodeValue] = useState([]);
  const [landlardArray, setLandlardArray] = useState([]);
  const [marketValues, setMarketsValue] = useState([]);
  const [submarket, setSubmarket] = useState([]);
  const [district, setDistrict] = useState([]);
  const [neighbourhood, setNeighbourhood] = useState([]);
  const [marketDropdown, setMarketDropdown] = useState([]);
  const [subMarketDropdown, setSubMarketDropdown] = useState([]);
  const [districtDropdown, setDistrictDropdown] = useState([]);
  const [neighbourhoodDropdown, setneighbourhoodDropdown] = useState([]);
  const [MinAvailSpace, SetMinAvailableSpace] = useState('');
  const [MaxAvailSpace, SetMaxAvailableSpace] = useState('');
  const [MinFloorSize, SetMinFloorSize] = useState('');
  const [MaxFloorSize, SetMaxFloorSize] = useState('');
  const [MinRentAmt, SetMinRentAmt] = useState('');
  const [MaxRentAmt, SetMaxRentAmt] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setTodate] = useState('');
  const [epcrating, setEpcrating] = useState([]);
  const [error, setError] = useState(false);
  const [flag, setFlag] = useState(true);
  const [RentAmtError, setRentAmtError] = useState(false);
  const [AvailSizeError, setAvailSizeError] = useState(false);
  const [FloorSizeError, setFloorSizeError] = useState(false);
  const [isLodding, setIsLodding] = useState(false);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [isInvalidFromDate, setIsInvalidFromDate] = useState(false);
  const [isInvalidToDate, setIsInvalidToDate] = useState(false);
  const validNumber = /^[0-9]*$/;
  const axiosPrivate = useAxiosPrivate();
  const [epcRatingList, setEpcRatingList] = useState([
    {
      id: 'A',
      title: 'A',
      isChecked: false,
    },
    {
      id: 'B',
      title: 'B',
      isChecked: false,
    },
    {
      id: 'C',
      title: 'C',
      isChecked: false,
    },
    {
      id: 'D',
      title: 'D',
      isChecked: false,
    },
    {
      id: 'E',
      title: 'E',
      isChecked: false,
    },
    {
      id: 'F',
      title: 'F',
      isChecked: false,
    },
    {
      id: 'G',
      title: 'G',
      isChecked: false,
    },
    {
      id: 'None',
      title: 'None',
      isChecked: false,
    },
  ]);
  const sortingArray = (array) => {
    const strAscending = [...array].sort((a, b) => (a.title > b.title ? 1 : -1));
    return strAscending;
  };
  const IsValueInvalid = (value) => {
    return value == '' || value == undefined || (!isNaN(value) && value > -1);
  };
  useEffect(() => {
    setIsLodding(true);
    fetchBenchmarkingFilter();
  }, []);

  const fetchBenchmarkingFilter = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/market-overview/filter/${hubUserKey}`);
      setIsLodding(false);
      if (response.status === 200 && response.data.length > 0) {
        handleBenchMarkingCallback(response.data);
      }
    } catch (error) {
      setWarningSnackbar({
        name: 'Error:',
        message: 'Unable to insert last project viewed details',
        severity: 'error',
      });
    }
  };
  const handleBenchMarkingCallback = (response) => {
    setIsLodding(false);
    if (response?.length > 0) {
      if (response[0].MIN_AVAILABLE_SPACE) {
        SetMinAvailableSpace(response[0].MIN_AVAILABLE_SPACE);
      }
      if (response[0].MAX_AVAILABLE_SPACE) {
        SetMaxAvailableSpace(response[0].MAX_AVAILABLE_SPACE);
      }
      if (response[0].MIN_FLOOR_SIZE) {
        SetMinFloorSize(response[0].MIN_FLOOR_SIZE);
      }
      if (response[0]?.START_DATE) {
        setFromDate(moment(response[0]?.START_DATE));
      }
      if (response[0]?.END_DATE) {
        setTodate(moment(response[0]?.END_DATE));
      }

      if (response[0].MAX_FLOOR_SIZE) {
        SetMaxFloorSize(response[0].MAX_FLOOR_SIZE);
      }
      if (response[0].MIN_RENT) {
        SetMinRentAmt(response[0].MIN_RENT);
      }
      if (response[0].MAX_RENT) {
        SetMaxRentAmt(response[0].MAX_RENT);
      }
      if (response[0]?.LANDLORDS?.length > 0) {
        if (response[0]?.LANDLORDS.length > 0) {
          let array = [];
          response[0]?.LANDLORDS.map((e) => {
            array.push({ id: e, title: e });
          });
          setLandlardArray(array);
        }
      }
      if (response[0]?.MARKETS?.length > 0) {
        if (response[0]?.MARKETS.length > 0) {
          let array = [];
          response[0]?.MARKETS.map((e) => {
            array.push({ id: e, title: e });
          });
          setMarketsValue(array);
        }
      }
      if (response[0]?.SUB_MARKETS?.length > 0) {
        if (response[0]?.SUB_MARKETS.length > 0) {
          let array2 = [];
          response[0]?.SUB_MARKETS.map((e) => {
            array2.push({ id: e, title: e });
          });
          setSubmarket(array2);
        }
      }
      if (response[0]?.DISTRICTS?.length > 0) {
        if (response[0]?.DISTRICTS.length > 0) {
          let array3 = [];
          response[0]?.DISTRICTS.map((e) => {
            array3.push({ id: e, title: e });
          });
          setDistrict(array3);
        }
      }
      if (response[0]?.NEIGHBOURHOODS?.length > 0) {
        if (response[0]?.NEIGHBOURHOODS.length > 0) {
          let array4 = [];
          response[0]?.NEIGHBOURHOODS.map((e) => {
            array4.push({ id: e, title: e });
          });
          setNeighbourhood(array4);
        }
      }
      if (response[0]?.EPC_RATINGS?.length > 0) {
        let epcRatingListData = [...epcRatingList];
        response[0]?.EPC_RATINGS?.map((obj) => {
          let isIndex = epcRatingListData.findIndex((e) => e.id == obj);
          if (isIndex != -1) {
            epcRatingListData[isIndex] = {
              ...epcRatingListData[isIndex],
              isChecked: true,
            };
          }
        });
        setEpcRatingList(epcRatingListData);
      }
      if (response[0]?.INDUSTRIES?.length > 0) {
        let IndustryCodeArray = [...IndustryCode];
        var array5 = [];
        response[0]?.INDUSTRIES.map((obj) => {
          let objSet = IndustryCodeArray.find((c) => c.id == obj);
          if (objSet) {
            array5.push({ ...objSet, title: objSet.name });
          }
        });
        setIndustryCodeValue(array5);
      }
    }
  };
  const ComapreMaxandMinValues = (minValue, maxValue, keyValue) => {
    if (!isNaN(maxValue) && maxValue > -1) {
      if (!isNaN(minValue) && minValue > -1) {
        if (Math.round(parseInt(maxValue) * 100) < Math.round(parseInt(minValue) * 100)) {
          if (keyValue == 'FloorSize') {
            setFloorSizeError(true);
          } else if (keyValue == 'Rent') {
            setRentAmtError(true);
          } else if (keyValue == 'AvailSpace') {
            setAvailSizeError(true);
          }
        } else {
          if (keyValue == 'FloorSize') {
            setFloorSizeError(false);
          } else if (keyValue == 'Rent') {
            setRentAmtError(false);
          } else if (keyValue == 'AvailSpace') {
            setAvailSizeError(false);
          }
        }
      }
    }
  };
  useEffect(() => {
    var count = 0;
    if (MinRentAmt || MaxRentAmt) {
      count = count + 1;
    }
    if (MinAvailSpace || MaxAvailSpace) {
      count = count + 1;
    }
    if (marketValues.length > 0) {
      count = count + 1;
    }
    if (submarket.length > 0) {
      count = count + 1;
    }
    if (district.length > 0) {
      count = count + 1;
    }
    if (neighbourhood.length > 0) {
      count = count + 1;
    }
    if (industryCodeValue.length > 0) {
      count = count + 1;
    }
    if (MaxFloorSize || MinFloorSize) {
      count = count + 1;
    }
    var epcSelectdeDataSet = epcRatingList.filter((e) => e.isChecked == true);
    if (epcSelectdeDataSet.length > 0) {
      count = count + 1;
    }
    onCallback(count);
  }, [
    industryCodeValue,
    MinFloorSize,
    MaxFloorSize,
    epcRatingList,
    neighbourhood,
    district,
    submarket,
    marketValues,
    MaxAvailSpace,
    MinAvailSpace,
    MaxRentAmt,
    MinRentAmt,
  ]);
  useEffect(() => {
    if (locationData.length > 0) {
      let marketsArray = locationData.filter((a, i) => locationData.findIndex((s) => a.MARKET === s.MARKET) === i);
      let marketsData = [];
      marketsArray?.map((e, index) => {
        if (e.MARKET != null) {
          marketsData.push({ id: e.MARKET, title: e.MARKET });
        }
      });
      let sortArray = sortingArray(marketsData);
      if (sortArray.length > 0) {
        sortArray.unshift({ id: 0, title: 'All' });
      }
      setMarketDropdown(sortArray);
    }
  }, [locationData]);
  useEffect(() => {
    const markets = marketValues.map((el) => el.title);
    let selectedLocationData = locationData;
    if (markets.length > 0) {
      selectedLocationData = locationData.filter((el) => markets.includes(el.MARKET));
    }
    const distinctSubmarkets = selectedLocationData
      .filter((a, i) => selectedLocationData.findIndex((s) => a.SUBMARKET === s.SUBMARKET) === i)
      .filter((el) => el.SUBMARKET);
    const slctdMarkets = distinctSubmarkets.filter((el) => markets.includes(el.MARKET)).map((elm) => elm.SUBMARKET);
    const subMarkets = distinctSubmarkets.map((elm) => ({
      id: elm.SUBMARKET,
      title: elm.SUBMARKET,
    }));
    const sorted = sortingArray(subMarkets);
    if (sorted.length > 0) {
      sorted.unshift({ id: 0, title: 'All' });
    }
    setSubmarket((prev) => {
      const tempSubmarket = prev?.filter((el) => slctdMarkets?.includes(el.title));
      if (tempSubmarket.length === distinctSubmarkets.length) {
        tempSubmarket.unshift({ id: 0, title: 'All' });
      }
      return [...tempSubmarket];
    });
    setSubMarketDropdown(sorted);
  }, [marketValues]);

  useEffect(() => {
    const subMarkets = submarket.map((el) => el.title);
    const markets = marketValues.map((el) => el.title);
    let selectedLocationData = locationData;
    if (markets.length > 0) {
      selectedLocationData = locationData.filter((el) => markets.includes(el.MARKET));
    }
    if (subMarkets.length > 0) {
      selectedLocationData = selectedLocationData.filter((el) => subMarkets.includes(el.SUBMARKET));
    }
    const distinctDistricts = selectedLocationData
      .filter((a, i) => selectedLocationData.findIndex((s) => a.DISTRICT === s.DISTRICT) === i)
      .filter((el) => el.DISTRICT);

    const slctdSubmarkets = distinctDistricts
      .filter((el) => subMarkets.includes(el.SUBMARKET))
      .map((elm) => elm.DISTRICT);

    const districts = distinctDistricts.map((elm) => ({
      id: elm.DISTRICT,
      title: elm.DISTRICT,
    }));

    const sorted = sortingArray(districts);
    if (sorted.length > 0) {
      sorted.unshift({ id: 0, title: 'All' });
    }
    setDistrict((prev) => {
      const tempDistrict = prev?.filter((el) => slctdSubmarkets?.includes(el.title));
      if (tempDistrict.length === distinctDistricts.length) {
        tempDistrict.unshift({ id: 0, title: 'All' });
      }
      return [...tempDistrict];
    });

    setDistrictDropdown(sorted);
  }, [submarket, marketValues]);

  useEffect(() => {
    const districts = district.map((el) => el.title);
    const subMarkets = submarket.map((el) => el.title);
    const markets = marketValues.map((el) => el.title);
    let selectedLocationData = locationData;
    if (markets.length > 0) {
      selectedLocationData = locationData.filter((el) => markets.includes(el.MARKET));
    }
    if (subMarkets.length > 0) {
      selectedLocationData = selectedLocationData.filter((el) => subMarkets.includes(el.SUBMARKET));
    }
    if (districts.length > 0) {
      selectedLocationData = selectedLocationData.filter((el) => districts.includes(el.DISTRICT));
    }
    const distinctNeighbourhood = selectedLocationData
      .filter((a, i) => selectedLocationData.findIndex((s) => a.NEIGHBOURHOOD === s.NEIGHBOURHOOD) === i)
      .filter((el) => el.NEIGHBOURHOOD);

    const slctdDistricts = distinctNeighbourhood
      .filter((el) => districts.includes(el.DISTRICT))
      .map((elm) => elm.NEIGHBOURHOOD);

    const slctdSubMarkets = distinctNeighbourhood
      .filter((el) => subMarkets.includes(el.SUBMARKET))
      .map((elm) => elm.NEIGHBOURHOOD);

    const slctdMarkets = distinctNeighbourhood
      .filter((el) => markets.includes(el.MARKET))
      .map((elm) => elm.NEIGHBOURHOOD);

    const neighborhood = distinctNeighbourhood.map((elm) => ({
      id: elm.NEIGHBOURHOOD,
      title: elm.NEIGHBOURHOOD,
    }));

    const sorted = sortingArray(neighborhood);
    if (sorted.length > 0) {
      sorted.unshift({ id: 0, title: 'All' });
    }
    setNeighbourhood((prev) => {
      let tempNeighbourhood = [];

      if (slctdDistricts.length) tempNeighbourhood = prev?.filter((el) => slctdDistricts?.includes(el.title));
      else if (slctdSubMarkets.length) tempNeighbourhood = prev?.filter((el) => slctdSubMarkets?.includes(el.title));
      else tempNeighbourhood = prev?.filter((el) => slctdMarkets?.includes(el.title));

      if (!!tempNeighbourhood.length && tempNeighbourhood.length === distinctNeighbourhood.length) {
        tempNeighbourhood.unshift({ id: 0, title: 'All' });
      }
      return [...tempNeighbourhood];
    });
    setneighbourhoodDropdown(sorted);
  }, [district, submarket, marketValues]);

  const handleOnBlurMinRentAmt = (value) => {
    setError('');
    let num = parseInt(value);
    ComapreMaxandMinValues(num, MaxRentAmt, 'Rent');
    let cleanNum = num;
    if (!isNaN(cleanNum)) {
      SetMinRentAmt(cleanNum);
    }
  };

  const handleOnBlurMaxRentAmt = (value) => {
    setError('');
    let num = parseInt(value);
    ComapreMaxandMinValues(MinRentAmt, num, 'Rent');
    let cleanNum = num;
    if (!isNaN(cleanNum)) {
      SetMaxRentAmt(cleanNum);
    }
  };
  const handleOnBlurMinFloorSize = (value) => {
    setError('');
    let num = parseInt(value);
    ComapreMaxandMinValues(num, MaxFloorSize, 'FloorSize');
    let cleanNum = num;
    if (!isNaN(cleanNum)) {
      SetMinFloorSize(cleanNum);
    }
  };
  const handleOnBlurMaxFloorSize = (value) => {
    setError('');
    let num = parseInt(value);
    ComapreMaxandMinValues(MinFloorSize, num, 'FloorSize');
    let cleanNum = num;
    if (!isNaN(cleanNum)) {
      SetMaxFloorSize(cleanNum);
    }
  };

  const handleOnBlurMinAvailSpace = (value) => {
    setError('');
    let num = parseInt(value);
    ComapreMaxandMinValues(num, MaxAvailSpace, 'AvailSpace');
    let cleanNum = num;
    if (!isNaN(cleanNum)) {
      SetMinAvailableSpace(cleanNum);
    }
  };
  const handleOnBlurMaxAvailSpace = (value) => {
    setError('');
    let num = parseInt(value);
    ComapreMaxandMinValues(MinAvailSpace, num, 'AvailSpace');
    let cleanNum = num;
    if (!isNaN(cleanNum)) {
      SetMaxAvailableSpace(cleanNum);
    }
  };
  const handleChangeEpcRatings = (object, Index) => {
    let dataSet = [...epcRatingList];
    if (Index != -1) {
      dataSet[Index] = { ...dataSet[Index], isChecked: !object.isChecked };
    }
    setEpcRatingList(dataSet);
  };
  const handleClearEpcRatings = () => {
    let dataSet = [...epcRatingList];
    dataSet?.map((dataObj) => {
      dataObj.isChecked = false;
    });
    setEpcRatingList(dataSet);
  };
  const handleClickReset = () => {
    setNeighbourhood([]);
    setDistrict([]);
    setSubmarket([]);
    setMarketsValue([]);
    handleClearEpcRatings();
    SetMinRentAmt('');
    SetMaxRentAmt('');
    SetMinFloorSize('');
    SetMaxFloorSize('');
    SetMaxAvailableSpace('');
    SetMinAvailableSpace('');
    setFlag(true);
    setIndustryCodeValue([]);
    setLandlardArray([]);
    setFromDate('');
    setTodate('');
  };
  const replaceQuote = (obj) => obj.map((el) => el.title.replace("'", "''''"));
  const replaceIndustryQuote = (obj) => obj.map((el) => el.id.replace("'", "''''"));
  const handleClickApplyFilter = () => {
    setIsLodding(true);
    let object = {
      HKEY_HUB_USER: hubUserKey,
    };

    if (MinFloorSize) {
      object['MIN_FLOOR_SIZE'] = parseInt(MinFloorSize);
    }
    if (MaxFloorSize) {
      object['MAX_FLOOR_SIZE'] = parseInt(MaxFloorSize);
    }
    if (MinRentAmt) {
      object['MIN_RENT'] = parseInt(MinRentAmt);
    }
    if (MaxRentAmt) {
      object['MAX_RENT'] = parseInt(MaxRentAmt);
    }
    if (MinAvailSpace) {
      object['MIN_AVAILABLE_SPACE'] = parseInt(MinAvailSpace);
    }
    if (fromDate) {
      object['START_DATE'] = moment(new Date(fromDate)).format('YYYY-MM-DD');
    }
    if (toDate) {
      object['END_DATE'] = moment(new Date(toDate)).format('YYYY-MM-DD');
    }
    if (MaxAvailSpace) {
      object['MAX_AVAILABLE_SPACE'] = parseInt(MaxAvailSpace);
    }
    if (marketValues.length > 0) {
      object['MARKETS'] = replaceQuote(marketValues);
    }
    if (submarket.length > 0) {
      object['SUB_MARKETS'] = replaceQuote(submarket);
    }
    if (district.length > 0) {
      object['DISTRICTS'] = replaceQuote(district);
    }
    if (neighbourhood.length > 0) {
      object['NEIGHBOURHOODS'] = replaceQuote(neighbourhood);
    }
    if (industryCodeValue.length > 0) {
      object['INDUSTRIES'] = replaceIndustryQuote(industryCodeValue);
    }
    if (landlardArray.length > 0) {
      object['LANDLORDS'] = replaceQuote(landlardArray);
    }
    var epcSelectdeDataSet = epcRatingList.filter((e) => e.isChecked == true);
    if (epcSelectdeDataSet.length > 0) {
      object['EPC_RATINGS'] = epcSelectdeDataSet.map((epcObj) => epcObj.title.replace("'", "''''"));
    }
    var epcSelectdeDataSet = epcRatingList.filter((e) => e.isChecked == true);
    if (epcSelectdeDataSet.length > 0) {
      object['EPC_RATINGS'] = epcSelectdeDataSet.map((epcObj) => epcObj.title.replace("'", "''''"));
    }
    benchmarkingFilter(object);
  };

  const benchmarkingFilter = async (object) => {
    try {
      const response = await axiosPrivate.post(`/benchmarking/market-overview/property-filter`, object);
      setIsLodding(false);
      if (response.status === 200 && response.data.length > 0) {
        handleApplyCallback();
      }
    } catch (error) {
      setIsLodding(false);
      if (error.errorCode === '000904' || error.errorCode === '-20001') {
        setWarningSnackbar({
          name: 'No properties to display - ',
          message: 'Please widen your search criteria',
          severity: 'success',
        });
      } else {
        setWarningSnackbar({
          name: 'No benchmarkings to display - ',
          message: 'Please widen your search criteria',
          severity: 'success',
        });
      }
    }
  };

  const handleFromDateCallback = (fromDateValue) => {
    if (fromDateValue) {
      fromDateValue['$d'] == 'Invalid Date' ? setIsInvalidFromDate(true) : setIsInvalidFromDate(false);
    } else setIsInvalidFromDate(false);
    setFromDate(fromDateValue);
  };
  const handleToDateCallback = (toDateValue) => {
    if (toDateValue) {
      toDateValue['$d'] == 'Invalid Date' || (fromDate != '' && fromDate > toDateValue && toDateValue != '')
        ? setIsInvalidToDate(true)
        : setIsInvalidToDate(false);
    } else setIsInvalidToDate(false);
    setTodate(toDateValue);
  };
  const IndustryCodeArray = IndustryCode.map((object) => {
    return { ...object, title: object.name };
  });
  return (
    <RightDrawer
      open={open}
      openSecondary={true}
      onClose={onClose}
      variant='temporary'
      width='45%'
    >
      <Grid>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: 99,
            borderBottom: '1px solid #003f2d1a',
            paddingLeft: 30,
            paddingRight: 30,
          }}
        >
          <Grid
            item
            md={6}
            sm={6}
            lg={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <CustomTypography
              text='Filter'
              fontsize='36px'
              fontweight='400'
              lineheight='40px'
              fontcolor='#1A1A1A'
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={6}
            lg={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              style={{
                textTransform: 'inherit',
                borderColor: '#FFFFFF',
                width: '40px',
                marginRight: 5,
              }}
              onClick={() => handleClickReset()}
            >
              <CustomTypography
                text='Clear'
                fontfamily='Calibre-M'
                fontsize='16px'
                fontweight='500'
                fontcolor='#003F2D'
                lineheight='16px'
                backcolor='#FFFFFF'
              />
            </Button>
            <Button
              style={{
                width: 100,
                height: 36,
                backgroundColor:
                  !IsValueInvalid(MinAvailSpace) ||
                  !IsValueInvalid(MaxAvailSpace) ||
                  !IsValueInvalid(MinFloorSize) ||
                  !IsValueInvalid(MaxFloorSize) ||
                  !IsValueInvalid(MinRentAmt) ||
                  !IsValueInvalid(MaxRentAmt) ||
                  RentAmtError ||
                  AvailSizeError ||
                  FloorSizeError ||
                  isInvalidFromDate ||
                  isInvalidToDate ||
                  (fromDate != '' && fromDate > toDate && toDate != '')
                    ? '#d4d7d7'
                    : '#003F2D',
                color:
                  !IsValueInvalid(MinAvailSpace) ||
                  !IsValueInvalid(MaxAvailSpace) ||
                  !IsValueInvalid(MinFloorSize) ||
                  !IsValueInvalid(MaxFloorSize) ||
                  !IsValueInvalid(MinRentAmt) ||
                  !IsValueInvalid(MaxRentAmt) ||
                  RentAmtError ||
                  AvailSizeError ||
                  FloorSizeError ||
                  isInvalidFromDate ||
                  isInvalidToDate ||
                  (fromDate != '' && fromDate > toDate && toDate != '')
                    ? '#7f8080'
                    : '#ffffff',
                borderRadius: 4,
              }}
              disabled={
                !IsValueInvalid(MinAvailSpace) ||
                !IsValueInvalid(MaxAvailSpace) ||
                !IsValueInvalid(MinFloorSize) ||
                !IsValueInvalid(MaxFloorSize) ||
                !IsValueInvalid(MinRentAmt) ||
                !IsValueInvalid(MaxRentAmt) ||
                RentAmtError ||
                AvailSizeError ||
                FloorSizeError ||
                isInvalidFromDate ||
                isInvalidToDate ||
                (fromDate != '' && fromDate > toDate && toDate != '')
              }
              onClick={() => handleClickApplyFilter()}
            >
              <CustomTypography
                text='Apply'
                fontsize='16px'
                fontfamily='Calibre-M'
                fontweight='500'
                lineheight='16px'
                padding='4px, 12px, 4px, 12px'
                fontcolor='#FFFFFF'
              />
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            height: window.innerHeight - 99,
            paddingBottom: 40,
            overflowX: 'auto',
            paddingLeft: 30,
            paddingRight: 30,
            flexDirection: 'column',
          }}
        >
          <Grid
            container
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <CustomTypography
              text='Projects'
              fontsize='24px'
              fontfamily='Calibre-R'
              fontweight='400'
              lineheight='40px'
              fontcolor='#1A1A1A'
              marginTop='21px'
            />
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingTop: 10 }}
              >
                <AutoComplite
                  valueKey='Benchmarking'
                  key={'Industry'}
                  title={'Industry' + '\xa0'}
                  inputPlaceholder={'Filter by keyword'}
                  data={IndustryCodeArray}
                  selectedValue={industryCodeValue}
                  handleChange={(e) => setIndustryCodeValue(e)}
                  setFlag={setFlag}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingTop: 20 }}
              >
                <AutoComplite
                  valueKey='Benchmarking'
                  key={'Landlord'}
                  title={'Landlord' + '\xa0'}
                  inputPlaceholder={'Filter by keyword'}
                  data={LandlardData}
                  selectedValue={landlardArray}
                  handleChange={(e) => setLandlardArray(e)}
                  setFlag={setFlag}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingTop: 15 }}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingTop: 8,
                }}
              >
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingRight: 14,
                    flexDirection: 'column',
                  }}
                >
                  <DatePicker
                    id='fromDate'
                    label={'Date range from' + '\xa0'}
                    width={'100%'}
                    inputPlaceholder={'Choose from date'}
                    fullWidth
                    value={fromDate}
                    maxDate={toDate}
                    minDate={''}
                    height={54}
                    border={false}
                    callback={handleFromDateCallback}
                  />
                  {isInvalidFromDate && fromDate != '' && (
                    <CustomTypography
                      text='Invalid date'
                      fontfamily='Calibre-R'
                      fontsize='11px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#FF543E'
                      marginLeft={12}
                      marginTop={12}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{
                    display: 'flex',
                    paddingLeft: 14,
                    flexDirection: 'column',
                  }}
                >
                  <DatePicker
                    id='toDate'
                    label={'To' + '\xa0'}
                    width={'100%'}
                    value={toDate}
                    inputPlaceholder={'Choose to date'}
                    fullWidth
                    height={54}
                    minDate={fromDate || moment()}
                    border={false}
                    callback={handleToDateCallback}
                  />
                  {isInvalidToDate && (
                    <CustomTypography
                      text={
                        fromDate != '' && fromDate > toDate && toDate != ''
                          ? 'To date must be greater than from date'
                          : 'Invalid date'
                      }
                      fontfamily='Calibre-R'
                      fontsize='11px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#FF543E'
                      marginLeft={12}
                      marginTop={12}
                    />
                  )}
                  {/* {fromDate != '' && fromDate > toDate && toDate != '' && (
                  //   <CustomTypography
                  //     text='To date must be greater than from date'
                  //     fontfamily='Calibre-R'
                  //     fontsize='11px'
                  //     fontweight='400'
                  //     lineheight='16px'
                  //     fontcolor='#FF543E'
                  //     marginLeft={12}
                  //     marginTop={12}
                  //   />
                  // )} */}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingBottom: 25, paddingTop: 12 }}
            >
              <CustomTypography
                text='Property details'
                fontfamily='Calibre-R'
                fontsize='24px'
                fontweight='400'
                lineheight='40px'
                fontcolor='#1A1A1A'
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingBottom: 25 }}
            >
              <Grid
                container
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingRight: 14,
                    flexDirection: 'column',
                  }}
                >
                  <CustomTextField
                    id='MinAvailSpace'
                    label={'Minimum Available Size' + '\xa0' + '\xa0'}
                    type='string'
                    fullWidth
                    inputPlaceholder={'Type in size in sq.ft.'}
                    value={MinAvailSpace}
                    onChange={(event) => {
                      if (validNumber.test(event.target.value)) {
                        let a = event.target.value ? parseInt(event.target.value) : '';
                        SetMinAvailableSpace(a);
                        setError('');
                      } else {
                        setError('minAvail');
                      }
                    }}
                    variant='outlined'
                    onBlur={handleOnBlurMinAvailSpace}
                    error={IsValueInvalid(MinAvailSpace) && !AvailSizeError ? false : true}
                  />
                  {(!IsValueInvalid(MinAvailSpace) || AvailSizeError || error === 'minAvail') && (
                    <CustomTypography
                      text={AvailSizeError ? 'Minimum Avilable Space must be less than Maximum' : 'Invalid value'}
                      fontfamily='Calibre-R'
                      fontsize='11px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#FF543E'
                      marginLeft={12}
                      marginTop={12}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingLeft: 14,
                    flexDirection: 'column',
                  }}
                >
                  <CustomTextField
                    id='MaxAvailSpace'
                    label={'Maximum Available Size' + '\xa0' + '\xa0'}
                    type='string'
                    fullWidth
                    inputPlaceholder={'Type in size in sq.ft.'}
                    value={MaxAvailSpace}
                    onChange={(event) => {
                      if (validNumber.test(event.target.value)) {
                        let a = event.target.value ? parseInt(event.target.value) : '';

                        SetMaxAvailableSpace(a);
                        setError('');
                      } else {
                        setError('maxAvail');
                      }
                    }}
                    onBlur={handleOnBlurMaxAvailSpace}
                    variant='outlined'
                    error={IsValueInvalid(MaxAvailSpace) && !AvailSizeError ? false : true}
                  />
                  {(!IsValueInvalid(MaxAvailSpace) || AvailSizeError || error === 'maxAvail') && (
                    <CustomTypography
                      text={AvailSizeError ? 'Minimum Avilable Space must be less than Maximum' : 'Invalid value'}
                      fontfamily='Calibre-R'
                      fontsize='11px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#FF543E'
                      marginLeft={12}
                      marginTop={12}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingBottom: 25 }}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingTop: 8,
                }}
              >
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingRight: 14,
                    flexDirection: 'column',
                  }}
                >
                  <CustomTextField
                    id='MinFloorSize'
                    label={'Minimum Floor Size' + '\xa0'}
                    type='string'
                    fullWidth
                    inputPlaceholder={'Type in floor size in sq.ft.'}
                    value={MinFloorSize}
                    onChange={(event) => {
                      if (validNumber.test(event.target.value)) {
                        let a = event.target.value ? parseInt(event.target.value) : '';
                        SetMinFloorSize(a);
                        setError('');
                      } else {
                        setError('minFloor');
                      }
                    }}
                    variant='outlined'
                    onBlur={handleOnBlurMinFloorSize}
                    error={IsValueInvalid(MinFloorSize) && !FloorSizeError ? false : true}
                  />
                  {(!IsValueInvalid(MinFloorSize) || FloorSizeError || error === 'minFloor') && (
                    <CustomTypography
                      text={
                        FloorSizeError ? 'Minimum floor size must be less than Maximum floor size' : 'Invalid value'
                      }
                      fontfamily='Calibre-R'
                      fontsize='11px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#FF543E'
                      marginLeft={12}
                      marginTop={12}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingLeft: 14,
                    flexDirection: 'column',
                  }}
                >
                  <CustomTextField
                    id='MaxFloorSize'
                    label={'Maximum Floor Size' + '\xa0'}
                    type='string'
                    fullWidth
                    inputPlaceholder={'Type in floor size in sq.ft.'}
                    value={MaxFloorSize}
                    onChange={(event) => {
                      if (validNumber.test(event.target.value)) {
                        let a = event.target.value ? parseInt(event.target.value) : '';

                        SetMaxFloorSize(a);
                        setError('');
                      } else {
                        setError('maxFloor');
                      }
                    }}
                    onBlur={handleOnBlurMaxFloorSize}
                    variant='outlined'
                    error={IsValueInvalid(MaxFloorSize) && !FloorSizeError ? false : true}
                  />
                  {(!IsValueInvalid(MaxFloorSize) || FloorSizeError || error === 'maxFloor') && (
                    <CustomTypography
                      text={FloorSizeError ? 'Minimum floor size must be less than Maximum' : 'Invalid value'}
                      fontfamily='Calibre-R'
                      fontsize='11px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#FF543E'
                      marginLeft={12}
                      marginTop={12}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingBottom: 25 }}
            >
              <Grid
                container
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingRight: 14,
                    paddingTop: 8,
                    flexDirection: 'column',
                  }}
                >
                  <CustomTextField
                    id='Minimum Rent'
                    label={'Minimum Rent' + '\xa0'}
                    type='string'
                    fullWidth
                    inputPlaceholder={'Type in rent per sq.ft.'}
                    value={MinRentAmt}
                    onChange={(event) => {
                      if (validNumber.test(event.target.value)) {
                        let a = event.target.value ? parseInt(event.target.value) : '';

                        SetMinRentAmt(a);
                        setError('');
                      } else {
                        setError('minRent');
                      }
                    }}
                    onBlur={handleOnBlurMinRentAmt}
                    variant='outlined'
                    error={IsValueInvalid(MinRentAmt) && !RentAmtError ? false : true}
                  />
                  {(!IsValueInvalid(MinRentAmt) || RentAmtError || error === 'minRent') && (
                    <CustomTypography
                      text={RentAmtError ? 'Minimum rent amount must be less than Maximum' : 'Invalid value'}
                      fontfamily='Calibre-R'
                      fontsize='11px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#FF543E'
                      marginLeft={12}
                      marginTop={12}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingLeft: 14,
                    paddingTop: 8,
                    flexDirection: 'column',
                  }}
                >
                  <CustomTextField
                    id='Maximum Rent'
                    label={'Maximum Rent' + '\xa0'}
                    type='string'
                    fullWidth
                    inputPlaceholder={'Type in rent per sq.ft.'}
                    value={MaxRentAmt}
                    onChange={(event) => {
                      if (validNumber.test(event.target.value)) {
                        let a = event.target.value ? parseInt(event.target.value) : '';
                        SetMaxRentAmt(a);
                        setError('');
                      } else {
                        setError('maxRent');
                      }
                    }}
                    onBlur={handleOnBlurMaxRentAmt}
                    variant='outlined'
                    error={IsValueInvalid(MaxRentAmt) && !RentAmtError ? false : true}
                  />
                  {(!IsValueInvalid(MaxRentAmt) || RentAmtError || error === 'maxRent') && (
                    <CustomTypography
                      text={RentAmtError ? 'Minimum rent amount must be less than Maximum' : 'Invalid value'}
                      fontfamily='Calibre-R'
                      fontsize='11px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#FF543E'
                      marginLeft={12}
                      marginTop={12}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingBottom: 25 }}
            >
              <Grid
                container
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                  }}
                >
                  <CustomTypography
                    text='Location'
                    fontfamily='Calibre-R'
                    fontsize='24px'
                    fontweight='400'
                    lineheight='40px'
                    fontcolor='#1A1A1A'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingBottom: 25 }}
            >
              <Grid
                container
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                  }}
                >
                  <AutoComplite
                    key={'MARKET'}
                    title={'Market' + '\xa0'}
                    inputPlaceholder={'Add locations by market'}
                    data={marketDropdown}
                    selectedValue={marketValues}
                    handleChange={(e) => setMarketsValue(e)}
                    setFlag={setFlag}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingBottom: 25 }}
            >
              <Grid
                container
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                  }}
                >
                  <AutoComplite
                    key={'SUBMARKET'}
                    title={'Submarket' + '\xa0'}
                    inputPlaceholder={'Add locations by submarket'}
                    data={subMarketDropdown}
                    selectedValue={submarket}
                    handleChange={(e) => {
                      if (e.length === 0) {
                        setNeighbourhood([]);
                        setDistrict([]);
                      }
                      setSubmarket(e);
                    }}
                    setFlag={setFlag}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingBottom: 25 }}
            >
              <Grid
                container
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                  }}
                >
                  <AutoComplite
                    key={'district'}
                    title={'District' + '\xa0'}
                    inputPlaceholder={'Add locations by district'}
                    data={districtDropdown}
                    selectedValue={district}
                    handleChange={(e) => {
                      if (e.length === 0) {
                        setNeighbourhood([]);
                      }
                      setDistrict(e);
                    }}
                    setFlag={setFlag}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingBottom: 25 }}
            >
              <Grid
                container
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                  }}
                >
                  <AutoComplite
                    key={'neighborhood'}
                    title={'Neighbourhood' + '\xa0'}
                    inputPlaceholder={'Add locations by neighbourhood'}
                    data={neighbourhoodDropdown}
                    selectedValue={neighbourhood}
                    handleChange={(e) => setNeighbourhood(e)}
                    setFlag={setFlag}
                  />
                </Grid>
              </Grid>
            </Grid>
            <CustomTypography
              text='EPC Ratings'
              fontsize='24px'
              fontfamily='Calibre-R'
              fontweight='400'
              lineheight='40px'
              fontcolor='#1A1A1A'
              marginBottm={8}
            />
            <Grid
              container
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              {epcRatingList.map((item, epxIndex) => {
                return (
                  <Grid
                    item
                    md={3}
                    key={epxIndex + item.title}
                    style={{
                      maxHeight: 40,
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <CustomCheckbox
                      id={item.title}
                      label={item.title}
                      checked={item.isChecked}
                      onChange={() => handleChangeEpcRatings(item, epxIndex)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        {warningSnackbar && (
          <WarningMessage
            open={true}
            setOpen={() => setWarningSnackbar(null)}
            severity={warningSnackbar.severity}
            name={warningSnackbar.name}
            message={warningSnackbar.message}
          />
        )}
        {isLodding && <LoadingOverlayComponent open={isLodding} />}
      </Grid>
    </RightDrawer>
  );
};
export default BenchMarkingFilter;
