import React from 'react';
import { PieChart, Pie, Legend, Tooltip } from 'recharts';
import { Grid } from '@material-ui/core';
import Divider from '@mui/material/Divider';

const renderColorfulLegendText = (value, entry) => {
  return (
    <span
      style={{
        color: '#000000',
        fontWeight: 400,
        padding: '10px',
        fontFamily: 'Calibre-R',
        lineHeight: '21px',
        fontSize: '14px',
      }}
    >
      {value}
    </span>
  );
};

const getSubCategoryName = (name) => {
  let labelName = '';
  switch (name) {
    case 'TRANSPORT':
      labelName = 'Transport';
      break;
    case 'LOCAL_AMENITY':
      labelName = 'Location & Amenity';
      break;
    case 'BUILDING':
      labelName = 'Building';
      break;
    case 'ESG':
      labelName = 'ESG';
      break;
    case 'DELIVERABILITY_RISK':
      labelName = 'Deliverability & Risk';
      break;
    case 'TR_PROXIMITY_NEAREST_TUBE_STATION':
      labelName = 'Proximity to nearest tube station';
      break;
    case 'TR_PROXIMITY_MAINLINE_TRAIN_STATION':
      labelName = 'Proximity to mainline train station';
      break;
    case 'TR_PROXIMITY_ELIZABETH_LINE':
      labelName = 'Proximity to Elizabeth Line';
      break;
    case 'TR_TUBES_WITHIN_1KM':
      labelName = 'Tube lines within 1km';
      break;
    case 'TR_PROXIMITY_REGIONAL_TRAIN_STATION':
      labelName = 'REGIONAL - Proximity to mainline train station';
      break;
    case 'TR_PROXIMITY_REGIONAL_TRAM_STATION':
      labelName = 'REGIONAL - Proximity to tram station';
      break;
    case 'BI_ON_SITE_DEDICATED_GYM':
      labelName = 'On-site Dedicated Gym';
      break;
    case 'BI_ON_SITE_CATERING_AVAILABLE':
      labelName = 'On-site Catering Available';
      break;
    case 'BI_TERRACES':
      labelName = 'Terraces';
      break;
    case 'BI_FLOOR_PLATE_SIZE':
      labelName = 'Floor plate suitability';
      break;
    case 'BI_LOOK_AND_FEEL':
      labelName = 'Look and Feel';
      break;
    case 'BI_FLOOR_TO_CEILING_HEIGHT':
      labelName = 'Floor to ceiling height';
      break;
    case 'BI_OCCUPATIONAL_DENSITY':
      labelName = 'Occupational Density';
      break;
    case 'BI_COLUMNS':
      labelName = 'Columns';
      break;
    case 'BI_NATURAL_LIGHT':
      labelName = 'Natural Light';
      break;
    case 'BI_ON_SITE_AMENITY':
      labelName = 'On-site Amenity';
      break;
    case 'BI_ON_SITE_SHOWER_FACILITIES':
      labelName = 'On-site shower facilities';
      break;
    case 'BI_SELF_CONTAINED':
      labelName = 'Self-contained';
      break;
    case 'BI_WIREDSCORE_RATING':
      labelName = 'WiredScore Rating';
      break;
    case 'BI_BUILDING_TECHNOLOGY':
      labelName = 'Building Technology';
      break;
    case 'BI_BRANDING_RIGHTS':
      labelName = 'Branding Rights';
      break;
    case 'BI_AIR_CONDITIONING':
      labelName = 'Air Conditioning';
      break;
    case 'BI_RAISED_FLOORS':
      labelName = 'Raised Floors';
      break;
    case 'BI_CAR_PARKING_SPACES':
      labelName = 'Car Park Spaces';
      break;
    case 'BI_LOCKERS':
      labelName = 'Lockers';
      break;
    case 'BI_SINGLE_LET_BUILDING':
      labelName = 'Single let Building';
      break;
    case 'LA_TRANSPORT_HUB':
      labelName = 'Proximity to transport hubs';
      break;
    case 'LA_LEVEL_LOCAL_AMENITY':
      labelName = 'Level of local amenity';
      break;
    case 'LA_GREEN_SPACE':
      labelName = 'Green Spaces';
      break;
    case 'LA_PERSONAL_SAFETY':
      labelName = 'Personal safety';
      break;
    case 'LA_PROX_GYM_HOTEL':
      labelName = 'Proximity to gyms/hotels';
      break;
    case 'LA_PROX_CLIENT_COMPETITOR':
      labelName = 'Proximity to Clients/Competitors';
      break;
    case 'LA_FLEX_OPTIONS':
      labelName = 'Flex options in the immediate area';
      break;
    case 'ESG_BREEAM_RATING':
      labelName = 'BREEAM Rating';
      break;
    case 'ESG_NABERS_RATING':
      labelName = 'Nabers Rating';
      break;
    case 'ESG_EPC_RATING':
      labelName = 'EPC Rating';
      break;
    case 'ESG_ALL_ELECTRIC_BUILDING':
      labelName = 'All-Electric Building';
      break;
    case 'ESG_NET_CARBON_ZERO':
      labelName = 'Net Carbon Zero';
      break;
    case 'ESG_NATURALLY_VENTILATED':
      labelName = 'Naturally Ventilated';
      break;
    case 'ESG_WELL_RATING':
      labelName = 'WELL Rating';
      break;
    case 'ESG_CYCLE_SPACES':
      labelName = 'Cycle Spaces';
      break;
    case 'DR_DELIVERY_DATE':
      labelName = 'Delivery status';
      break;
    case 'DR_LANDLORD_CREDIBILITY':
      labelName = 'Landlord Credibility';
      break;
    default:
      break;
  }
  return labelName;
};
const CustomTooltip = ({
  active,
  payload,
  label,
  transportData = [],
  deliveryData = [],
  esgData = [],
  buildingData = [],
  localAmenityData = [],
  customData = [],
}) => {
  if (active && payload && payload.length) {
    let SubWeightingDetails = [];
    let color = '#DFE6E5';
    let backgroundColor = '#DFE6E5';
    switch (payload[0]?.name) {
      case 'Transport':
        SubWeightingDetails = transportData;
        color = '#DBD99A';
        backgroundColor = 'rgb(246,245,229)';
        break;
      case 'Location & Amenity':
        SubWeightingDetails = localAmenityData;
        color = '#A388BF';
        backgroundColor = 'rgb(206,196,217)';
        break;
      case 'Building':
        SubWeightingDetails = buildingData;
        color = '#3E7CA6';
        backgroundColor = 'rgb(223,238,234)';
        break;
      case 'ESG':
        SubWeightingDetails = esgData;
        color = '#80BBAD';
        backgroundColor = 'rgb(206,222,232)';
        break;
      case 'Deliverability & Risk':
        SubWeightingDetails = deliveryData;
        color = '#885073';
        backgroundColor = 'rgb(193,150,177)';
        break;
      case 'Custom Criteria':
        SubWeightingDetails = customData;
        color = '#96b0bf';
        backgroundColor = '#c0d8e6';
        break;
      default:
        break;
    }

    const max = Math.max(...Object.values(SubWeightingDetails));

    return (
      <Grid style={{ padding: 8, paddingBottom: 3, paddingTop: 3 }}>
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 5,
            paddingBottom: 3,
            paddingTop: 2,
          }}
        >
          <Grid
            item
            xs={8}
            style={{
              fontSize: '20px',
              fontWeight: '400',
              fontColor: '#000000',
              lineHeight: '24px',
              fontFamily: 'Calibre-R',
            }}
          >
            {payload[0]?.name}
          </Grid>
          <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
              style={{
                backgroundColor: backgroundColor,
                width: '50px',
                height: '50px',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontFamily: 'Calibre-R',
                fontSize: '16px',
              }}
            >
              {Math.round(payload[0].payload.payload.value)}%
            </div>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 5, marginBottom: 10 }} />
        {Object.keys(SubWeightingDetails).map((key, index) => {
          let keyName = getSubCategoryName(key) ? getSubCategoryName(key) : key;
          keyName = keyName.length > 35 ? keyName.substring(0, 35) + '...' : keyName;
          if (SubWeightingDetails[key] > 0) {
            return (
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 5,
                  paddingBottom: 2,
                }}
              >
                <Grid
                  item
                  xs={7}
                  style={{
                    fontSize: '12px',
                    fontWeight: '400',
                    color: 'rgba(26, 26, 26, 0.6)',
                    lineHeight: '24px',
                    fontFamily: 'Calibre-R',
                  }}
                >
                  {keyName}
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    fontSize: '12px',
                    fontWeight: '500',
                    fontColor: '#000000',
                    lineHeight: '24px',
                    fontFamily: 'Calibre-R',
                  }}
                >
                  <svg width='220' height='20'>
                    <g>
                      <rect
                        center
                        width={(SubWeightingDetails[key] / max) * 100 - 6}
                        height='15'
                        style={{ fill: color }}
                        x={0}
                        y={5}
                      />
                      <text x={(SubWeightingDetails[key] / max) * 100 - 4} y='17'>
                        {parseFloat(SubWeightingDetails[key]).toFixed(2)}
                      </text>
                    </g>
                  </svg>
                  &nbsp;&nbsp;
                </Grid>
              </Grid>
            );
          }
        })}
      </Grid>
    );
  }
  return null;
};

function DefaultPieCharts({
  chartData = [],
  transportData = [],
  deliveryData = [],
  esgData = [],
  buildingData = [],
  localAmenityData = [],
  customData = [],
}) {
  return (
    <PieChart width={320} height={240} cursor={false}>
      <Pie
        data={chartData}
        cx={170}
        cy={120}
        innerRadius={70}
        outerRadius={115}
        dataKey='value'
        startAngle={90}
        endAngle={-270}
        style={{ outline: 'none' }}
      ></Pie>
      <Legend
        iconType='circle'
        layout='vertical'
        verticalAlign='middle'
        iconSize={10}
        padding={10}
        formatter={renderColorfulLegendText}
        wrapperStyle={{ width: '250px', top: 65, left: 300 }}
      />
      <Tooltip
        content={
          <CustomTooltip
            transportData={transportData}
            deliveryData={deliveryData}
            esgData={esgData}
            buildingData={buildingData}
            localAmenityData={localAmenityData}
            customData={customData}
          />
        }
        isAnimationActive={false}
        wrapperStyle={{
          outline: 'none',
          backgroundColor: 'white',
          padding: '8px',
          // border: '1px solid #cccc',
          width: '350px',
          overflow: 'auto',
          border: '1px solid #dadde9',
          boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.24)',
          borderRadius: '10px',
          zIndex: 2,
          top: '-82%',
        }}
      />
    </PieChart>
  );
}

export default DefaultPieCharts;
