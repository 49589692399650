import React from 'react';
function StarCloseIcon(props) {
  return (
    <svg
      //   className='star_icon'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M16.6484 6.29458L11.8084 5.87458L9.91836 1.42458C9.57836 0.614578 8.41836 0.614578 8.07836 1.42458L6.18836 5.88458L1.35836 6.29458C0.478364 6.36458 0.118364 7.46458 0.788364 8.04458L4.45836 11.2246L3.35836 15.9446C3.15836 16.8046 4.08836 17.4846 4.84836 17.0246L8.99836 14.5246L13.1484 17.0346C13.9084 17.4946 14.8384 16.8146 14.6384 15.9546L13.5384 11.2246L17.2084 8.04458C17.8784 7.46458 17.5284 6.36458 16.6484 6.29458ZM8.99836 12.6546L5.23836 14.9246L6.23836 10.6446L2.91836 7.76458L7.29836 7.38458L8.99836 3.35458L10.7084 7.39458L15.0884 7.77458L11.7684 10.6546L12.7684 14.9346L8.99836 12.6546Z'
        fill='#CBCDCB'
      />
    </svg>
  );
}

export default StarCloseIcon;
