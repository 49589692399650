import React from "react";

function SwitchOnIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="24"
      fill="none"
      viewBox="0 0 41 24"
      {...props}
    >
      <rect
        width="36"
        height="14"
        y="4"
        fill="#003F2D"
        opacity="0.38"
        rx="7"
      ></rect>
      <g filter="url(#filter0_d_2432_12295)">
        <circle cx="29" cy="11" r="10" fill="#003F2D"></circle>
      </g>
      <defs>
        <filter
          id="filter0_d_2432_12295"
          width="24"
          height="24"
          x="17"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2432_12295"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_2432_12295"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default SwitchOnIcon;
