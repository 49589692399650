import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';
import { BsThreeDotsVertical, BsTag, BsEyeSlash, BsChevronRight } from 'react-icons/bs';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { Buffer } from 'buffer';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const Ellipsis = ({ setRowData, setSnackbar, getTableData, setClientProperties, ...props }) => {
  const hkey = useSelector((state) => state.mainReducer.hkey);
  const tippyRef = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => {
    setVisible(false);
    setSubVisible(false);
  };

  const subTippyRef = useRef();
  const [subVisible, setSubVisible] = useState(false);
  const [selected, setSelected] = useState();
  const subShow = () => setSubVisible(true);
  const subHide = () => setSubVisible(false);
  const axiosPrivate = useAxiosPrivate();

  const addTagOrHideProperty = async (reqObj, tag) => {
    try {
      const response = await axiosPrivate.put(`/dataview/hide-property`, reqObj);
      if (response.status === 200 && response.data.length > 0) {
        getTableData('');
        if (!reqObj.isHide) {
          setClientProperties((prev) => {
            let tempProperties = [...prev];
            const index = prev.findIndex((el) => el.PROPERTY_CODE === props.data.PROPERTY_CODE);
            if (index !== -1) {
              tempProperties[index] = {
                ...prev[index],
                ACTIVE: !reqObj.tag,
              };
              return [...tempProperties];
            }
            return [...prev];
          });
          setSnackbar({
            name: props.data.BUILDING_NAME ? props.data.BUILDING_NAME : 'This Property',
            message: ` has been tagged as ${tag}`,
            severity: 'success',
          });
        } else {
          setSnackbar({
            name: props.data.BUILDING_NAME ? props.data.BUILDING_NAME : 'Property',
            message: props.data.IS_HIDDEN ? ` has been unhidden.` : ` has been hidden.`,
            severity: 'success',
          });
        }
      }
    } catch (error) {
      console.log('Error while adding the tag or hide property...!', error);
    }
  };
  const subDropdown = (
    <div className='menu-container-sub'>
      <FormControl>
        <RadioGroup
          size='small'
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: 16,
              color: '#1A1A1A',
            },
          }}
          value={
            ['OFF_MARKET', 'CURRENT_SITE', 'UNDER_OFFER'].includes(props.data.TAG_PROPERTY)
              ? props.data.TAG_PROPERTY
              : 'No tag'
          }
          name='radio-buttons-group'
          onChange={(e) => {
            const propertyId = Buffer.from(props.data.HKEY_HUB_PROPERTY.data).toString('hex').toUpperCase();

            const proptag = {
              'No tag': '',
              'Off-market': 'OFF_MARKET',
              'Current site': 'CURRENT_SITE',
              'Under offer': 'UNDER_OFFER',
            };
            var tag = proptag[e.target.value];
            const reqObj = {
              hkey: hkey,
              propertyId: propertyId,
              tag: tag,
              isHide: false,
            };
            addTagOrHideProperty(reqObj, e.target.value);
            setVisible(false);
            setSubVisible(false);
            setSelected(e.target.defaultValue);
          }}
        >
          <FormControlLabel
            sx={{
              ml: 0,
              paddingLeft: '0.25rem',
              width: '90%',
              background: selected === 'No tag' ? '#e9f1f0' : 'inherit',
            }}
            className='menu-item'
            value='No tag'
            control={<Radio />}
            label='No tag'
          />
          <FormControlLabel
            sx={{
              ml: 0,
              paddingLeft: '0.25rem',
              width: '90%',
              background: selected === 'Off-market' ? '#e9f1f0' : 'inherit',
            }}
            className='menu-item'
            value='Off-market'
            control={<Radio />}
            label='Off-market'
          />
          <FormControlLabel
            sx={{
              ml: 0,
              paddingLeft: '0.25rem',
              width: '90%',
              background: selected === 'Current site' ? '#e9f1f0' : 'inherit',
            }}
            className='menu-item'
            value='Current site'
            control={<Radio />}
            label='Current site'
          />
          <FormControlLabel
            sx={{
              ml: 0,
              paddingLeft: '0.25rem',
              width: '90%',
              background: selected === 'Under offer' ? '#e9f1f0' : 'inherit',
            }}
            className='menu-item'
            value='Under offer'
            control={<Radio />}
            label='Under offer'
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
  /* eslint-disable jsx-a11y/anchor-is-valid */
  const dropDownContent = (
    <div className='menu-container'>
      <Tippy
        ref={subTippyRef}
        content={subDropdown}
        visible={subVisible}
        onClickOutside={() => {
          subHide();
        }}
        allowHTML={true}
        arrow={false}
        // appendTo={document.body}
        interactive={true}
        placement='right'
        animation='fade'
        duration={0}
      >
        <a
          href='#'
          onClick={() => {
            subVisible ? subHide() : subShow();
          }}
          className='menu-item'
          style={{ display: 'flex', alignItems: 'center', padding: '10px' }}
        >
          <BsTag style={{ width: '18px', height: '18px', marginBottom: '-3px' }} />
          <span style={{ marginLeft: '10px' }}>Tag Property</span>
          <BsChevronRight
            style={{
              width: '15px',
              height: '15px',
              marginBottom: '-3px',
              marginLeft: '30px',
            }}
          />
        </a>
      </Tippy>

      <div
        onClick={() => {
          const propertyId = Buffer.from(props.data.HKEY_HUB_PROPERTY.data).toString('hex').toUpperCase();
          const reqObj = {
            hkey: hkey,
            propertyId: propertyId,
            isHiden: props.data.IS_HIDDEN ? false : true,
            isHide: true,
          };
          addTagOrHideProperty(reqObj);
          setVisible(false);
        }}
        className='menu-item'
        style={{ display: 'flex', alignItems: 'center', padding: '10px' }}
      >
        <BsEyeSlash style={{ width: '18px', height: '18px' }} />
        <span style={{ marginLeft: '10px' }}>{props.data.IS_HIDDEN ? 'Unhide Property' : 'Hide Property'}</span>
      </div>
    </div>
  );

  return (
    <>
      <Tippy
        ref={tippyRef}
        content={dropDownContent}
        visible={visible}
        onClickOutside={hide}
        allowHTML={true}
        arrow={false}
        appendTo={document.body}
        interactive={true}
        placement='right'
        animation='fade'
        duration={0}
      >
        <span
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
          onClick={() => {
            visible ? hide() : show();
          }}
        >
          <BsThreeDotsVertical style={{ fontSize: '14px' }} />
        </span>
      </Tippy>
    </>
  );
};

export default Ellipsis;
