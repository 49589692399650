

import React from "react";
import styles from '../styles/loginStyles';
function StarSelected(props) {
    const classes = styles();
    return (
        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M15.0001 24.2225L22.2626 28.615C23.5926 29.42 25.2201 28.23 24.8701 26.725L22.9451 18.465L29.3676 12.9C30.5401 11.885 29.9101 9.95996 28.3701 9.83746L19.9176 9.11996L16.6101 1.31496C16.0151 -0.102544 13.9851 -0.102544 13.3901 1.31496L10.0826 9.10246L1.63007 9.81996C0.0900663 9.94246 -0.539934 11.8675 0.632566 12.8825L7.05507 18.4475L5.13007 26.7075C4.78007 28.2125 6.40757 29.4025 7.73757 28.5975L15.0001 24.2225Z" fill="#EFDA19"/>
        </svg>

    );
}

export default StarSelected;
