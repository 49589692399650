import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LeftDrawer from '../navbar_drawer/left_drawer';
import DashboardIcon from '../../icons/dashboard_icon';
import MapViewIcon from '../../icons/mapview_icon';
import FilterIcon from '../../icons/filter_icon';
import ChartIcon from '../../icons/chart_icon';
import TableViewIcon from '../../icons/tableview_icon';
import ReportsIcon from '../../icons/reports_icon';
//import WeightingsIcon from '../../icons/weightings_icon';
import WeightingsIcon from '../../icons/edit_weighting_icon';
import ShortListIcon from '../../icons/shortlist_icon';
import RaiseRequest from '../../components/RaiseRequest';
import clsx from 'clsx';
import { Box, ListItemIcon, Drawer, ListItemText, Hidden, List, ListItem, makeStyles } from '@material-ui/core';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 65,
  },
  toolbar: {
    backgroundColor: '#fafafa',
  },
  title: {
    fontFamily: 'Calibre-R',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
  },
  menuButton: {
    fontFamily: 'Calibre-R',
    height: 50,
    '&.active': {
      backgroundColor: '#ebf0ef',
      color: '#003F2D',
    },
  },
  icon: {
    minWidth: 35,
    width: 20,
    height: '100%',
    color: '#004C60',
    marginRight: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.active': {
      borderBottom: '2px solid #051D33',
    },
  },
}));
const MapNavigationBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(null);
  const [isDrawerOpen, toggleDrawer] = useState(false);
  const [itemsList, setItemList] = useState([
    {
      id: 1,
      href: '/dashboard',
      icon: DashboardIcon,
      name: 'Dashboard',
    },
    {
      id: 2,
      href: '/map/mapview',
      icon: MapViewIcon,
      name: 'Map view',
    },
    {
      id: 3,
      href: '/map/advancedfilters',
      icon: FilterIcon,
      name: 'Filter properties',
    },
    {
      id: 4,
      href: '/map/charts',
      icon: ChartIcon,
      name: 'Charts & ranking',
    },
    {
      id: 5,
      href: '/map/tableview',
      icon: TableViewIcon,
      name: 'Data view',
    },
    {
      id: 6,
      href: '/map/updateweightingsscores',
      icon: WeightingsIcon,
      name: 'Weightings',
    },
    {
      id: 7,
      href: '/map/shortlistview',
      icon: ShortListIcon,
      name: 'Shortlist',
    },
    {
      id: 8,
      href: '/map/reports',
      icon: ReportsIcon,
      name: 'Reports',
    },
  ]);
  const content = (
    <Box height='100%' display='flex' flexDirection='column'>
      <Box>
        <List
          style={{
            color: '#1A1A1A',
            paddingTop: 60,
            paddingLeft: 7,
            paddingRight: 7,
          }}
          component='nav'
        >
          {itemsList.map((item) => (
            <NavItem key={item.name} item={item} open={open} isDrawerOpen={isDrawerOpen} />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );
  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor='left'
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant='temporary'
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <LeftDrawer
          classes1={classes}
          toggleDrawer={() => {
            toggleDrawer(!isDrawerOpen);
            setOpen(null);
          }}
          open={isDrawerOpen}
        >
          {content}
          <RaiseRequest isDrawerOpen={isDrawerOpen} />
        </LeftDrawer>
      </Hidden>
    </>
  );
};

const NavItem = ({ item, handleClick, open, isDrawerOpen }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const NavIcon = item.icon;

  const getClassNames = () => {
    if (item.href === location.pathname) {
      if (isDrawerOpen) {
        return clsx(classes.menuButton, 'active');
      } else {
        return clsx(classes.menuButton, 'active', 'open');
      }
    } else return clsx(classes.menuButton);
  };

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontFamily: 'Calibre-R',
        fontSize: '14px',
        fontWeight: 400,
      },
    })
  );

  return (
    <>
      <LightTooltip
        enterNextDelay={10}
        enterDelay={10}
        title={item.name}
        placement='right'
        followCursor
        style={{ margin: 10 }}
      >
        <ListItem
          button
          className={getClassNames()}
          style={{ color: '##1A1A1A', paddingLeft: 18, paddingTop: 12 }}
          onClick={() => navigate(item.href)}
        >
          <ListItemIcon
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              minWidth: '40px',
            }}
          >
            <span>
              <NavIcon color={item.href === location.pathname ? '#003F2D' : '#1A1A1A'} width={20} height={20} />
            </span>
          </ListItemIcon>
          {isDrawerOpen && (
            <ListItemText
              classes={{ primary: classes.title }}
              primary={item.name}
              style={{ fontWeight: '400', fontFamily: 'Calibre-R' }}
            />
          )}
        </ListItem>
      </LightTooltip>
    </>
  );
};
MapNavigationBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default MapNavigationBar;
