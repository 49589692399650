import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  sidebarStyle: {
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '12px',
    backgroundColor: ' #404040',
    color: ' #ffffff',
    zIndex: '1 !important',
    padding: '6px',
    fontWeight: 'bold',
  },
  createbtn: {
    '&:hover': {
      color: 'white',
      background: '#003F2D',
    },
    background: '#003F2D',
    color: 'white',
    height: 36,
    width: 189,
    marginTop: 20,
  },
  createdisablebtn: {
    '&:hover': {
      color: '#949494',
      background: '#dadada',
    },
    background: '#dadada',
    color: '#949494',
    height: 36,
    width: 189,
    marginTop: 20,
  },
  sentbtn: {
    '&:hover': {
      color: 'white',
      background: '#003F2D',
    },
    background: '#003F2D',
    color: 'white',
    height: 36,
    width: 240,
  },
  buttonClass: {
    '&:hover': {
      color: 'white',
      background: '#003F2D',
    },
    background: '#003F2D',
    color: 'white',
    height: 36,
    width: 180,
  },
  DeletebuttonClass: {
    '&:hover': {
      color: ' white',
      background: '#FF4B4B',
    },
    background: '#FF4B4B',
    color: ' white',
    height: 36,
    width: 180,
  },
  ApplybuttonClass: {
    '&:hover': {
      color: 'white',
      background: '#003F2D',
    },
    background: '#003F2D',
    color: 'white',
    height: 36,
    width: 100,
    marginLeft: 30,
  },
  disableApplyClass: {
    '&:hover': {
      color: 'white',
      background: '#d4d7d7',
    },
    background: '#d4d7d7',
    color: '#7f8080',
    height: 36,
    width: 100,
  },
  btn: {
    '&:hover': {
      color: 'white',
      background: '#003F2D',
    },
    background: '#003F2D',
    color: 'white',
    height: 36,
    width: 180,
  },
  editbtn: {
    '&:hover': {
      color: 'white',
      background: '#003F2D',
    },
    background: '#003F2D',
    color: 'white',
    height: 36,
    width: 240,
  },
  cancel_btn: {
    background: '#F5F7F7',
    border: '1px solid #003F2D',
    color: '#003F2D',
    height: 36,
    width: 240,
  },
  disablebtnClass: {
    '&:hover': {
      color: 'white',
      background: '#d4d7d7',
    },
    background: '#d4d7d7',
    color: '#7f8080',
    height: 36,
    width: 180,
  },
  backbutton: {
    background: 'white',
    border: '1px solid #003F2D',
    color: 'white',
    height: 36,
    width: 180,
  },
  paper: {
    borderRadius: 10,
    margin: 0,
    overflow: 'hidden',
    maxWidth: 624,
  },
  editbuttonClass: {
    background: '#003F2D',
    color: '#003F2D',
    height: '25px',
    width: '145px',
    textTransform: 'inherit',
    marginTop: '50px',
    fontfamily: 'Calibre-R',
    fontsize: '36px',
    fontweight: '400',
    lineheight: '40px',
    marignRight: '100px',
    display: 'left',
    marginLeft: '48.5%',
    fontcolor: '#FFFFFF',
  },
  disablebuttonClass: {
    '&:hover': {
      color: 'white',
      background: '#d4d7d7',
    },
    background: '#d4d7d7',
    color: '#7f8080',
    height: 36,
    width: 180,
  },
  disableaddPropertyClass: {
    '&:hover': {
      color: 'white',
      background: '#d4d7d7',
    },
    background: '#d4d7d7',
    border: '1px solid #d4d7d7',
    height: 36,
    width: 172,
  },
  backbtn: {
    background: 'white',
    border: '1px solid #003F2D',
    color: 'white',
    height: 36,
    width: 180,
  },
  resetbtn: {
    background: 'white',
    border: '1px solid #003F2D',
    color: 'white',
    height: 36,
    width: 108,
  },
  menuItem: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: '500',
    fontfamily: 'Calibre-R',
  },
  exportButton: {
    '&:hover': {
      color: 'white',
      background: '#003F2D',
    },
    background: '#003F2D',
    color: 'white',
    height: 36,
    width: 240,
  },
}));

export default useStyles;
