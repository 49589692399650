import React from "react";

function InfoIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill="#003F2D"
                fillRule="evenodd"
                d="M8 0a8 8 0 108 8 8.009 8.009 0 00-8-8zm.167 3.333a1 1 0 110 2 1 1 0 010-2zm-1.167 9h2.667a.667.667 0 100-1.333h-.5A.167.167 0 019 10.833V7.667c0-.737-.597-1.334-1.333-1.334H7a.667.667 0 000 1.334h.5c.092 0 .167.074.167.166v3A.167.167 0 017.5 11H7a.667.667 0 000 1.333z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default InfoIcon;
