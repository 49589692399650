import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Grid } from '@material-ui/core';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomTypography from '../utils/text';
import LeftVector from '../icons/left_vector';
import RightVector from '../icons/right_vector';
import FirstPageIcon from '../icons/firstpage_icon';
import LastPageIcon from '../icons/lastPage_icon';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import useStyles from '../styles/index';
import StarOpenIcon from '../icons/star_open_icon';
import StarCloseIcon from '../icons/star_close_icon';
import CurrentSiteIcon from '../icons/current_site';
import OffMarketIcon from '../icons/off_market';
import UnderOfferIcon from '../icons/under_offer';
import CurrencyFormatter from './currency_formatter';
const CustomDataGrid = ({
  handleClickCallback,
  data,
  tabValue,
  searchNotifier,
  shortListingNotifier,
  projectStatus = '',
}) => {
  const gridRef = useRef();
  const classes = useStyles();
  const [rows, setRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Building Name',
      field: 'Building Name',
      suppressHeaderMenuButton: true,
      cellRenderer: TagPropertyIconComponent,
      width: 250,
    },
    {
      headerName: 'Shortlisted',
      field: 'Shortlisted',
      cellRenderer: IconComponent,
      width: 120,
      headerClass: 'ag-center-header',
      suppressHeaderMenuButton: true,
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: 'Score',
      field: 'Score',
      suppressHeaderMenuButton: true,
      width: 75,
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: 'Rank',
      field: 'Rank',
      suppressHeaderMenuButton: true,
      width: 75,
      cellStyle: { textAlign: 'center' },
    },
    { headerName: 'Address', field: 'Address', suppressHeaderMenuButton: true, width: 240 },
    {
      headerName: 'Postcode',
      field: 'Postcode',
      suppressHeaderMenuButton: true,
      width: 120,
    },
    {
      headerName: 'District',
      field: 'District',
      suppressHeaderMenuButton: true,
      width: 150,
    },
    {
      headerName: 'Neighbourhood',
      field: 'Neighbourhood',
      suppressHeaderMenuButton: true,
      width: 160,
    },
    { headerName: 'Town', field: 'Town', suppressHeaderMenuButton: true, width: 160 },
    {
      headerName: 'Rent from (£/sq.ft.)',
      field: 'RentAmt',
      valueGetter: (val) => val.data.RentAmt.toFixed(2),
      suppressHeaderMenuButton: true,
      width: 160,
    },
    {
      headerName: 'Available from',
      field: 'Available from',
      suppressHeaderMenuButton: true,
      width: 140,
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: 'Nearest Tube',
      field: 'Nearest Tube',
      suppressHeaderMenuButton: true,
      width: 180,
    },
    // { headerName: 'Availability Size', field: 'AVAILABILITY SIZE', suppressHeaderMenuButton: true, width: 160, cellStyle: { textAlign: 'center' } },
    {
      headerName: 'Total Building Availability (sq.ft.)',
      field: 'Total Building Availability (sq ft)',
      suppressHeaderMenuButton: true,
      valueFormatter: (params) => CurrencyFormatter(params.value, '$'),
      width: 260,
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: 'Total Number of Available floors',
      field: 'Total Number of Available floors',
      suppressHeaderMenuButton: true,
      width: 240,
      cellStyle: { textAlign: 'center' },
    },
  ]);

  useEffect(() => {
    if (searchNotifier) {
      setPageNumber(1);
    } else if (shortListingNotifier) {
      setPageNumber(pageNumber);
    } else {
      setPageNumber(1);
    }

    setRowData(data);
    if (data?.length > 0) {
      var quotient = Math.floor(data.length / rows);
      var remainder = data.length % rows;
      var remainder = data.length % rows;
      if (remainder != 0) {
        setPageCount(quotient + 1);
      } else {
        setPageCount(quotient);
      }
    } else {
      setPageCount(0);
      setPageNumber(0);
    }
  }, [data, searchNotifier]);
  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
    };
  }, []);

  useEffect(() => {
    if (gridRef.current.api) {
      onBtFirst();
      setPageNumber(1);
    }
  }, [tabValue]);

  const onGridReady = useCallback(
    (params) => {
      if (data) {
        setRowData(data);
        if (data) {
          var quotient = Math.floor(data.length / rows);
          var remainder = data.length % rows;
          var remainder = data.length % rows;
          if (remainder != 0) {
            setPageCount(quotient + 1);
          } else {
            setPageCount(quotient);
          }
        }
      }
    },
    [data]
  );

  const onPaginationChanged = useCallback(() => {
    if (gridRef.current.api) {
    }
  }, []);

  const onBtFirst = useCallback(() => {
    gridRef.current.api.paginationGoToFirstPage();
  }, []);

  const onBtLast = useCallback(() => {
    gridRef.current.api.paginationGoToLastPage();
  }, []);

  const onBtNext = useCallback(() => {
    gridRef.current.api.paginationGoToNextPage();
  }, []);

  const onBtPrevious = useCallback(() => {
    gridRef.current.api.paginationGoToPreviousPage();
  }, []);

  const onPageSizeChanged = useCallback((value) => {
    setRows(value.target.value);
    gridRef.current.api.paginationSetPageSize(Number(value.target.value));
  }, []);
  useEffect(() => {
    if (rowData) {
      var quotient = Math.floor(rowData.length / rows);
      var remainder = rowData.length % rows;
      var remainder = rowData.length % rows;
      var value = 0;
      if (remainder != 0) {
        value = quotient + 1;
      } else {
        value = quotient;
      }
      setPageCount(value);
      if (value < pageNumber) {
        setPageNumber(value);
      }
    }
  }, [rows]);
  function IconComponent(props) {
    if (props.data.Shortlisted == 'Short') {
      return (
        <StarOpenIcon
          style={{
            alignItems: 'center',
            cursor: projectStatus === 'Completed' || projectStatus === 'Archived' ? 'not-allowed' : 'pointer',
            paddingRight: 100,
          }}
          color={projectStatus === 'Completed' || projectStatus === 'Archived' ? '#7F8480' : '#EFDA19'}
          onClick={() => {
            if (projectStatus !== 'Completed' && projectStatus !== 'Archived') {
              handleClickStartIcon(props.data);
            }
          }}
        />
      );
    } else {
      return (
        <StarCloseIcon
          style={{
            alignItems: 'center',
            cursor: projectStatus === 'Completed' || projectStatus === 'Archived' ? 'not-allowed' : 'pointer',
            paddingRight: 100,
          }}
          onClick={() => {
            if (projectStatus !== 'Completed' && projectStatus !== 'Archived') {
              handleClickStartIcon(props.data);
            }
          }}
        />
      );
    }
  }
  function TagPropertyIconComponent(props) {
    let addressLineName =
      props?.data['Building Name']?.length > 21
        ? props?.data['Building Name']?.substring(0, 21) + '...'
        : props?.data['Building Name'];
    return (
      <Grid style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <CustomTypography
          text={addressLineName}
          fontfamily='Calibre-R'
          fontsize='16px'
          fontweight='400'
          fontcolor='#1A1A1A'
          lineheight='16px'
          marginRight='8px'
          marginTop='10px'
        />
        {props.data.TAG_PROPERTY == 'OFF_MARKET' ? (
          <OffMarketIcon style={{ alignItems: 'center', display: 'flex', marginTop: 2 }} />
        ) : props.data.TAG_PROPERTY == 'UNDER_OFFER' ? (
          <UnderOfferIcon style={{ alignItems: 'center', display: 'flex', marginTop: 2 }} />
        ) : props.data.TAG_PROPERTY == 'CURRENT_SITE' ? (
          <CurrentSiteIcon style={{ alignItems: 'center', display: 'flex', marginTop: 2 }} />
        ) : (
          ''
        )}
      </Grid>
    );
  }
  const handleClickStartIcon = (response) => {
    handleClickCallback(response);
  };
  return (
    <div style={containerStyle}>
      <div
        className='example-wrapper'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'column',
              height: '86%',
            }}
          >
            <div style={gridStyle} className='ag-theme-alpine'>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                paginationPageSize={rows}
                pagination={true}
                suppressPaginationPanel={true}
                onGridReady={onGridReady}
                onPaginationChanged={onPaginationChanged}
                suppressDragLeaveHidesColumns={true}
              ></AgGridReact>
            </div>
          </Grid>
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'row',
              height: '10%',
              bottom: 0,
            }}
          >
            <Grid
              container
              style={{
                display: 'flex',
                backgroundColor: '#FFFFFF',
                height: '100%',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Grid
                item
                xs={3}
                md={3}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <CustomTypography
                  text='Rows Per Page:'
                  fontsize='12px'
                  fontweight='400'
                  lineheight='16px'
                  fontcolor='#767676'
                  marginLeft={24}
                />
                <FormControl
                  sx={{
                    m: 1,
                    minWidth: 40,
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 1.5,
                  }}
                  variant='standard'
                >
                  <Select
                    style={{
                      fontSize: '12px',
                      fontFamily: 'calibre-SB',
                      lineHeight: '16px',
                      fontWeight: '500',
                    }}
                    labelId='demo-simple-select-label'
                    value={rows}
                    onChange={(e) => {
                      onPageSizeChanged(e);
                    }}
                    id='page-size'
                    disableUnderline
                  >
                    <MenuItem className={classes.MenuItem} value={10}>
                      10
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={20}>
                      20
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={30}>
                      30
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={40}>
                      40
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={50}>
                      50
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={60}>
                      60
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={70}>
                      70
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={80}>
                      80
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={90}>
                      90
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={100}>
                      100
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={9}
                md={9}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingRight: 30,
                  alignItems: 'center',
                }}
              >
                <FirstPageIcon
                  style={{ marginRight: 30, cursor: 'pointer' }}
                  onClick={
                    pageCount != 0 && pageNumber != 1
                      ? () => {
                          setPageNumber(1);
                          onBtFirst();
                        }
                      : null
                  }
                  color={pageNumber == 0 || pageNumber === 1 ? '#767676' : '#878787'}
                  fillOpacity={pageNumber == 0 || pageNumber === 1 ? '.2' : '.8'}
                />
                <LeftVector
                  style={{ marginRight: 30, cursor: 'pointer' }}
                  // onClick={pageCount != 0 && pageNumber != 1 ? () => { setPageNumber(pageNumber - 1); onBtPrevious() } : null}
                  onClick={
                    pageCount != 0 && pageNumber != 1
                      ? () => {
                          setPageNumber(pageNumber - 1);
                          onBtPrevious();
                        }
                      : null
                  }
                  color={pageNumber == 0 || pageNumber === 1 ? '#767676' : '#878787'}
                  fillOpacity={pageNumber == 0 || pageNumber === 1 ? '.2' : '.8'}
                />
                <CustomTypography
                  style={{
                    marginRight: 30,
                    border: '1px solid #c6c6c6',
                    padding: 6,
                    fontSize: '12px',
                    lineHeight: '16px',
                  }}
                  text={`${pageNumber > pageCount ? pageCount : pageNumber} of ${pageCount}`}
                  fontsize={12}
                  fontfamily='Calibre-R'
                  fontweight='400'
                  lineheight={16}
                  fontcolor='#262626'
                  marginLeft={24}
                />
                <RightVector
                  style={{ marginRight: 30, cursor: 'pointer' }}
                  // onClick={pageCount != 0 && pageNumber != pageCount ? () => { setPageNumber(pageNumber + 1); onBtNext() } : null}
                  onClick={
                    pageCount != 0 && pageNumber != pageCount
                      ? () => {
                          setPageNumber(pageNumber + 1);
                          onBtNext();
                        }
                      : null
                  }
                  color={pageCount == pageNumber ? '#767676' : '#878787'}
                  fillOpacity={pageCount == pageNumber ? '.2' : '.8'}
                />
                <LastPageIcon
                  style={{ cursor: 'pointer' }}
                  onClick={
                    pageCount != 0 && pageNumber != pageCount
                      ? () => {
                          setPageNumber(pageCount);
                          onBtLast();
                        }
                      : null
                  }
                  color={pageCount == pageNumber ? '#767676' : '#878787'}
                  fillOpacity={pageCount == pageNumber ? '.2' : '.8'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default CustomDataGrid;
