import React from "react";

function DisableCheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#C3C3C3"
        d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zM7.29 14.29L3.7 10.7a.996.996 0 111.41-1.41L8 12.17l6.88-6.88a.996.996 0 111.41 1.41L8.7 14.29a.996.996 0 01-1.41 0z"
      ></path>
    </svg>
  );
}

export default DisableCheckIcon;
