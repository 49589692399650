/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import StoreConfig from '../redux/store/index';
const useAxiosPrivate = (targetAPI) => {
  //console.log('targetAPI**************' + targetAPI);
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  let target;
  if (targetAPI === 'aiasstant') target = process.env.REACT_APP_AI_ASSITANT_API_URL;
  else if (targetAPI === 'lease') target = process.env.REACT_APP_AI_ASSITANT_LEASE_URL;
  else target = process.env.REACT_APP_API_BASE_URL;
  const axiosPrivate = axios.create({
    baseURL: target,
  });
  const { store } = StoreConfig;

  const refreshToken = async () => {
    try {
      const responseData = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      const token = responseData.accessToken;
      const decoded = jwt_decode(token);
      if (decoded?.unique_name) {
        dispatch({
          type: 'SET_USER_EMAIL',
          payload: decoded?.unique_name,
        });
        dispatch({
          type: 'SET_OAUTH_TOKEN',
          payload: responseData.accessToken,
        });
      }
      console.log('Acquired New Token...........!');
      return token;
    } catch (error) {
      console.error('Authentication Error: Token expired within 24hrs. Initiating redirect to login.', { error });
      await instance.loginRedirect(loginRequest).catch((err) => {
        console.error('Redirection Error: Failed to redirect to login page.', { err });
      });
    }
  };

  const oauth_token = store.getState().mainReducer.oauth_token;
  axiosPrivate.interceptors.request.use(
    (config) => {
      if (!config.headers['Authorization']) {
        config.headers['Authorization'] = oauth_token;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosPrivate.interceptors.response.use(
    (response) => response,
    async (error) => {
      const prevRequest = error?.config;
      if (error?.response?.status === 401 && !prevRequest?.sent) {
        prevRequest.sent = true;
        const token = await refreshToken();
        prevRequest.headers['Authorization'] = token;
        return axiosPrivate(prevRequest);
      }
      return Promise.reject(error);
    }
  );
  return axiosPrivate;
};

export default useAxiosPrivate;
