import React from 'react';

function EditPencilIcon({
  color = 'white',
  width = '10',
  height = '17',
  ...props
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 19 10'
      {...props}
    >
      <path
        fill={color}
        d='M0.498047 15.4613V18.5013C0.498047 18.7813 0.718047 19.0013 0.998047 19.0013H4.03805C4.16805 19.0013 4.29805 18.9513 4.38805 18.8513L15.308 7.94128L11.558 4.19128L0.648047 15.1013C0.548047 15.2013 0.498047 15.3213 0.498047 15.4613ZM18.208 5.04128C18.598 4.65128 18.598 4.02128 18.208 3.63128L15.868 1.29128C15.478 0.901279 14.848 0.901279 14.458 1.29128L12.628 3.12128L16.378 6.87128L18.208 5.04128Z'
      />
    </svg>
  );
}

export default EditPencilIcon;
