import React from "react";

function CollapseLeftIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
            {...props}
        >
            <g filter="url(#filter0_dd_1_2562)">
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M16 26C9.373 26 4 20.627 4 14S9.373 2 16 2s12 5.373 12 12-5.373 12-12 12z"
                    clipRule="evenodd"
                ></path>
            </g>
            <path
                fill="#fff"
                fillOpacity="0.01"
                d="M0 0H24V24H0z"
                transform="matrix(-1 0 0 1 28 2)"
            ></path>
            <path
                fill="#003F2D"
                fillRule="evenodd"
                d="M17.706 11.698a.987.987 0 000-1.407 1.01 1.01 0 00-1.419 0l-2.965 2.94a1.088 1.088 0 000 1.548l2.955 2.93a1.01 1.01 0 001.638-.322.989.989 0 00-.218-1.085l-2.318-2.297 2.327-2.307z"
                clipRule="evenodd"
            ></path>
            <defs>
                <filter
                    id="filter0_dd_1_2562"
                    width="32"
                    height="32"
                    x="0"
                    y="0"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.25 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1_2562"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.31 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_1_2562"
                        result="effect2_dropShadow_1_2562"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_1_2562"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
    );
}

export default CollapseLeftIcon;
