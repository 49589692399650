import heroImg from '../img/building3.jpg';

const genericTooltip = (item, prompt, rank) => {
  let body = '';
  const flag = prompt.toLowerCase().includes('uni');
  const addressDetails = item.body.find((el) => el.label === 'Address')?.value.split(',');
  const address = addressDetails.slice(0, -1).join(',');
  const addressMarkup = `<p>
            <span-rank>Address</span-rank>
            <span-rrank>${address.length > 28 ? `${address.slice(0, 27)}...` : address}</span-rrank>
            </p>
            <p>
                <span-rank>Postal code</span-rank>
                <span-rrank>${addressDetails.slice(-1)?.at(0)}</span-rrank>
            </p>
            `;
  if (prompt.toLowerCase().includes('lease')) {
    const totalExpires = item.body
      .filter((el) => el.label !== 'Address')
      .reduce((acc, curr) => {
        const sum = +curr.value.split(' ').at(0);
        return acc + sum;
      }, 0);

    body = `
            <p>
                <span-rank>Total expiries</span-rank>
                <span-rrank>${totalExpires}</span-rrank>
            </p>
    `;
  } else {
    body = item.body
      .filter((elm) => elm.label !== 'Address')
      .map((el) =>
        !el.value?.includes('http')
          ? `<p>
                <span-rank>${el.label}</span-rank>
                <span-rrank>${
                  el.label?.trim() === 'Rent' ? Number(el.value.trim())?.toFixed(2) + '£' : el.value
                }</span-rrank>
            </p>`
          : `<p>
                <span-rank>Website</span-rank>
                <span-rrank><a href=${el.value}>Website-Link</a></span-rrank>
            </p>`
      )
      .join('');
  }
  let rankStr = '';
  if (prompt.toLowerCase().includes('expens'))
    rankStr = `<p>
                <span-rank>Rank</span-rank>
                <span-rrank>${rank}</span-rrank>
               </p>`;

  let heroImage = heroImg;
  if (item.image_url) heroImage = item.image_url ? encodeURI(JSON.parse(item.image_url).MEDIA_PATH) : '';

  const description = `
            <style>
            .container {
                min-width:120px;
                position: relative;
                font-family: Calibre-R;
            }
            span-rank {
                color:#1a1a1a99;font-style:normal;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;left: 863px;right: 802px;top: 380px;
            }
            span-space {
                color:#1a1a1a99;font-style:normal;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;left: 863px;right: 802px;top: 409px;
            }
            span-postal {
                color:#1a1a1a99;font-style:normal;font-weight:400;line-height:24px;font-size:14px;font-family:'Calibre-R';text-align=left;left: 863px;right: 802px;top: 300px;
            }
            span-address {
                color:#000000;font-weight:400;line-height:24px;font-size:20px;font-family:'Calibre-R';font-style:normal;text-align=left;left: 863px;right: 802px;top: 301px;
            }
            span-rrank {
                color:#000000;line-height:24px;font-size:12px;font-family:'Calibre-R';font-weight:500;float:right;font-style:normal;left: 979px;right: 797px;top: 380px;
            }
            P {
                padding : 0;
                margin : 0;
                line-height : 22px;
            }
    </style>
   
    <div class="container">
${
  !flag
    ? `<img src=${heroImage} style="width:100%;height:140px;border-top-right-radius:10px;border-top-left-radius:10px;object-fit:cover;"></img>`
    : ''
}
    </div>
    <div style="padding-left:14px;padding-right:14px;margin-bottom:5px;">
    <p>
        <span-address>${item.title}</span-address>
    </p>
    <p><hr color='#e6ecea' size='1px'></p>
    ${addressMarkup}
    ${body}
    ${rankStr}
    </div>`;
  return description;
};
export default genericTooltip;
