const DownIcon = () => (
  <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10 2.67188C14.41 2.67188 18 6.26188 18 10.6719C18 15.0819 14.41 18.6719 10 18.6719C5.59 18.6719 2 15.0819 2 10.6719C2 6.26188 5.59 2.67188 10 2.67188ZM10 0.671875C4.48 0.671875 0 5.15188 0 10.6719C0 16.1919 4.48 20.6719 10 20.6719C15.52 20.6719 20 16.1919 20 10.6719C20 5.15188 15.52 0.671875 10 0.671875ZM11 10.6719V7.67188C11 7.12187 10.55 6.67188 10 6.67188C9.45 6.67188 9 7.12187 9 7.67188V10.6719H7.21C6.76 10.6719 6.54 11.2119 6.86 11.5219L9.65 14.3119C9.85 14.5119 10.16 14.5119 10.36 14.3119L13.15 11.5219C13.46 11.2119 13.24 10.6719 12.8 10.6719H11Z'
      fill='#56A7F2'
    />
  </svg>
);

export default DownIcon;
