import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { BsEyeSlash } from 'react-icons/bs';
import CustomTypography from '../utils/text';
import LeftVector from '../icons/left_vector';
import RightVector from '../icons/right_vector';
import FirstPageIcon from '../icons/firstpage_icon';
import LastPageIcon from '../icons/lastPage_icon';
import StarOpenIcon from '../icons/star_open_icon';
import StarCloseIcon from '../icons/star_close_icon';
import DataviewCheckBoxIcon from '../icons/dataview_checked_icon';
import DataviewUnCheckBoxIcon from '../icons/dataview_uncheck_icon';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import useStyles from '../styles/index';
import NumericCellEditor from '../utils/numericCellEditor';
import FloorSizeNumericCellEditor from '../utils/floorSizeNumericCellEditor';
import CurrencyFormatter from '../components/currency_formatter';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/selectable.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/selectable';
import WarningMessage from '../components/warning_snackbar';
import SnackBar from '../components/SnackBar';
import Ellipsis from './Ellipsis';
import CurrentIcon from '../icons/current_site';
import OffmarketIcon from '../icons/off_market';
import UnderofferIcon from '../icons/under_offer';
import { getObject } from '../utils/utilities';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
const CustomDataGrid = ({
  dataList,
  IsEdit = false,
  projectStatus = '',
  scoreObj,
  handleUpdate,
  handleChangeListstatus,
  handleChangeViewStatus,
  highlightingActiveColumns,
  IshighlightingActiveColumnsEnable,
  getTableData,
  setClientProperties,
}) => {
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [snackbar, setSnackbar] = useState();
  const gridRef = useRef();
  const classes = useStyles();
  const [rows, setRows] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState([]);
  const [activeRow, setActiveRow] = useState({});
  const [custWeightings, setCustWeightings] = useState([]);
  const isEnabled = useSelector((state) => state.mainReducer.nonScoringSwitch);
  const hubweightingKey = useSelector((state) => state.mainReducer.hwgtkey);
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const cellEditorSelector = (params) => {
    if (params.column.originalParent.colGroupDef.headerName.includes('Custom Criteria')) {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Excellent', 'Very good', 'Good', 'Average', 'Blank'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'BI_ON_SITE_DEDICATED_GYM') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Yes', 'No'],
        },
        popup: true,
      };
    }

    if (params.colDef.field === 'BI_ON_SITE_CATERING_AVAILABLE') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Yes', 'No'],
        },
        popup: true,
      };
    }

    if (params.colDef.field === 'BI_TERRACES') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Yes', 'No'],
        },
        popup: true,
      };
    }

    if (params.colDef.field === 'BI_FLOOR_PLATE_SUITABILITY') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Excellent', 'Very good', 'Good', 'Average'],
        },
        popup: true,
      };
    }

    if (params.colDef.field === 'BI_LOOK_AND_FEEL') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Excellent', 'Very good', 'Good', 'Average', 'Blank'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'BI_OCCUPATIONAL_DENSITY') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: [' <=1:8 m2', '1:10 m2', '1:12 m2', '>1:12 m2'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'BI_NATURAL_LIGHT') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Excellent', 'Very good', 'Good', 'Average', 'Blank'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'BI_ON_SITE_AMENITY') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Excellent', 'Very good', 'Good', 'Average'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'BI_ON_SITE_SHOWER_FACILITIES') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Yes', 'No'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'BI_SELF_CONTAINED') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Yes', 'No'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'BI_WIREDSCORE_RATING') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: [
            'Platinum',
            'Gold',
            'Silver',
            'Bronze',
            'None',
            'Targeting Platinum',
            'Targeting Gold',
            'Targeting Silver',
            'Certified',
            'Registered',
          ],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'BI_COLUMNS') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Column free', 'Virtually column free', 'Noticeable column grid', 'Heavy column grid', 'Blank'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'BI_BUILDING_TECHNOLOGY') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Building technology app', 'No building technology available', 'Blank'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'BI_BRANDING_RIGHTS') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Yes', 'No', 'Blank'],
        },
        popup: true,
      };
    }

    if (params.colDef.field === 'BI_AIR_CONDITIONING') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Yes', 'No'],
        },
        popup: true,
      };
    }

    if (params.colDef.field === 'BI_RAISED_FLOORS') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Yes', 'No'],
        },
        popup: true,
      };
    }

    if (params.colDef.field === 'BI_LOCKERS') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Yes', 'No'],
        },
        popup: true,
      };
    }

    if (params.colDef.field === 'LA_LEVEL_LOCAL_AMENITY') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Excellent', 'Very good', 'Good', 'Average', 'Blank'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'LA_GREEN_SPACE') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Excellent', 'Very good', 'Good', 'Average', 'Blank'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'LA_PERSONAL_SAFETY') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Excellent', 'Very good', 'Good', 'Average', 'Blank'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'LA_PROX_CLIENT_COMPETITOR') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Excellent', 'Very good', 'Good', 'Average', 'Blank'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'LA_PROX_GYM_HOTEL') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Excellent', 'Very good', 'Good', 'Average', 'Blank'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'LA_FLEX_OPTIONS') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Excellent', 'Very good', 'Good', 'Average', 'Blank'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'ESG_BREEAM_RATING') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: [
            'Outstanding',
            'Excellent',
            'Very Good',
            'Good',
            'Pass',
            'Acceptable',
            'None',
            'Targeting Outstanding',
            'Targeting Excellent',
            'Targeting Very Good',
            'Targeting Good',
            'Unclassified',
          ],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'ESG_NABERS_RATING') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: [
            '6 Stars',
            '5 Stars',
            '4 Stars',
            '3 Stars',
            '2 Stars',
            '1 Star',
            'None',
            'Targeting 6 Stars',
            'Targeting 5 Stars',
            'Targeting 4 Stars',
            'Targeting 3 Stars',
            'Targeting 2 Stars',
            'Targeting 1 Star',
          ],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'ESG_EPC_RATING') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: [
            'A+',
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'None',
            'Targeting A+',
            'Targeting A',
            'Targeting B',
            'Targeting C',
          ],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'ESG_NET_CARBON_ZERO') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Construction & Operation', 'Construction Only', 'Operation Only', 'Not Net Carbon Zero', 'Blank'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'ESG_ALL_ELECTRIC_BUILDING') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Yes', 'No', 'Blank'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'ESG_NATURALLY_VENTILATED') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Yes', 'No', 'Blank'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'BI_SINGLE_LET_BUILDING') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: ['Yes', 'No'],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'ESG_WELL_RATING') {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: [
            'Platinum',
            'Gold',
            'Silver',
            'Bronze',
            'None',
            'Targeting Platinum',
            'Targeting Gold',
            'Targeting Silver',
            'Targeting Bronze',
            'Targeting Registered',
          ],
        },
        popup: true,
      };
    }
    if (params.colDef.field === 'DR_LANDLORD_CREDIBILITY') {
      return {
        component: 'agSelectCellEditor',

        params: {
          values: ['Excellent', 'Very good', 'Good', 'Average', 'Blank'],
        },

        popup: true,
      };
    }

    if (params.colDef.field === 'BI_FLOOR_TO_CEILING_HEIGHT') {
      return {
        component: FloorSizeNumericCellEditor,
        params: {
          error: () =>
            setWarningSnackbar({
              name: 'Invalid Input.',
              message: 'Enter input between 2 and 10.',
              severity: 'error',
            }),
        },
        popup: false,
      };
    }
    if (params.colDef.field === 'BI_CAR_PARKING_SPACES') {
      return {
        component: NumericCellEditor,
        params: {
          limit: { min: 0, max: 10000 },
          error: () =>
            setWarningSnackbar({
              name: 'Invalid Input.',
              message: 'Enter input between 0 and 10000.',
              severity: 'error',
            }),
          source: 'BI_CAR_PARKING_SPACES',
        },
        popup: false,
      };
    }
    if (params.colDef.field === 'ESG_CYCLE_SPACES') {
      return {
        component: NumericCellEditor,
        params: {
          limit: { min: 0, max: 10000 },
          error: () =>
            setWarningSnackbar({
              name: 'Invalid Input.',
              message: 'Enter input between 0 and 10000.',
              severity: 'error',
            }),
          source: 'ESG_CYCLE_SPACES',
        },
        popup: false,
      };
    } else {
      return {
        component: NumericCellEditor,
        popup: false,
        params: {
          source: 'FI_',
        },
      };
    }
  };

  let array = [
    {
      cellRenderer: Ellipsis,
      cellRendererParams: {
        setRowData,
        setSnackbar,
        getTableData,
        setClientProperties,
      },
      width: 30,
      pinned: 'left',
      lockPosition: 'left',
      colId: 'action',
      headerClass: 'header-fixed-cols',
      cellStyle: {
        padding: 0,
      },
      cellClassRules: {
        'cell-pass': (params) => params.data.flag,
      },
    },
    {
      headerClass: 'building-header',
      groupId: 'collapse',
      openByDefault: true,
      children: [
        {
          headerName: 'Building Name',
          field: 'BUILDING_NAME',
          sortable: true,
          resizable: true,
          lockPinned: true,
          suppressHeaderMenuButton: true,
          cellRenderer: ClientIconComponent,
          width: 250,
          headerClass: 'header-fixed-cols',
          pinned: 'left',
          style: { textAlign: 'left' },
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Shortlisted',
          field: 'LISTED',
          sortable: true,
          lockPinned: true,
          suppressHeaderMenuButton: true,
          resizable: true,
          cellRenderer: IconComponent,
          columnGroupShow: 'open',
          cellRendererParams: {
            IsEdit,
          },
          width: 105,
          headerClass: 'header-fixed-cols',
          cellStyle: { textAlign: 'center' },
          pinned: 'left',
        },
        {
          headerName: 'Viewed',
          field: 'VIEWED',
          sortable: true,
          lockPinned: true,
          suppressHeaderMenuButton: true,
          resizable: true,
          cellRenderer: CheckComponent,
          columnGroupShow: 'open',
          cellRendererParams: {
            IsEdit,
          },
          width: 80,
          headerClass: 'header-fixed-cols',
          cellStyle: { textAlign: 'center' },
          pinned: 'left',
        },
        {
          headerName: 'Score',
          field: 'SCORE',
          sortable: true,
          lockPinned: true,
          resizable: true,
          suppressHeaderMenuButton: true,
          headerClass: 'header-fixed-cols',
          width: 92,
          pinned: 'left',
          columnGroupShow: 'open',
          cellStyle: {
            textAlign: 'left',
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
          },
        },
        {
          headerName: 'Address',
          field: 'ADDRESS_LINE1',
          sortable: true,
          resizable: true,
          lockPinned: true,
          headerClass: 'header-fixed-cols',
          width: 200,
          suppressHeaderMenuButton: true,
          cellRenderer: AddressComponent,
          pinned: 'left',
          columnGroupShow: 'open',
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
            border: 'none',
          },
        },
        {
          headerName: 'Postcode',
          field: 'POSTAL_CODE',
          sortable: true,
          lockPinned: true,
          width: 95,
          resizable: true,
          headerClass: 'header-fixed-cols',
          suppressHeaderMenuButton: true,
          pinned: 'left',
          columnGroupShow: 'open',
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
      ],
    },
    {
      headerName: `Transport ${scoreObj?.TRANSPORT ? '(' + scoreObj?.TRANSPORT + '%)' : ''} `,
      headerClass: 'header-transport',
      suppressHeaderMenuButton: true,
      resizable: true,
      children: [
        {
          headerName: 'Proximity to nearest tube station',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.NEAREST_TUBE_IN_METER
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.NEAREST_TUBE_IN_METER == false,
          field: 'NEAREST_TUBE_IN_METER',
          width: 260,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
        {
          headerName: 'Proximity to mainline train station',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.NEAREST_MAIN_TRAIN_STATION_IN_KM
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'NEAREST_MAIN_TRAIN_STATION_IN_KM',
          hide:
            IshighlightingActiveColumnsEnable == false &&
            highlightingActiveColumns?.NEAREST_MAIN_TRAIN_STATION_IN_KM == false,
          width: 245,
          suppressHeaderMenuButton: true,
          comparator: function (value1, value2) {
            let number1 = value1?.replace('km', '');
            let number2 = value2?.replace('km', '');
            if (number1 === null && number2 === null) {
              return 0;
            }
            if (isNaN(number1)) {
              return -1;
            }
            if (isNaN(number2)) {
              return 1;
            }
            if (number1 === null) {
              return -1;
            }
            if (number2 === null) {
              return 1;
            }
            return number1 - number2;
          },
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
        {
          headerName: 'Proximity to Elizabeth Line',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.NEAREST_ELIZABETH_LINE_STATION_IN_KM
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'NEAREST_ELIZABETH_LINE_STATION_IN_KM',
          hide:
            IshighlightingActiveColumnsEnable == false &&
            highlightingActiveColumns?.NEAREST_ELIZABETH_LINE_STATION_IN_KM == false,
          width: 200,
          suppressHeaderMenuButton: true,
          comparator: function (value1, value2) {
            let number1 = value1?.replace('km', '');
            let number2 = value2?.replace('km', '');
            if (number1 === null && number2 === null) {
              return 0;
            }
            if (isNaN(number1)) {
              return -1;
            }
            if (isNaN(number2)) {
              return 1;
            }
            if (number1 === null) {
              return -1;
            }
            if (number2 === null) {
              return 1;
            }
            return number1 - number2;
          },
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
        {
          headerName: 'Tube lines within 1km',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.TUBES_WITHIN_1_KM
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'TUBES_WITHIN_1_KM',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.TUBES_WITHIN_1_KM == false,
          width: 170,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
        {
          headerName: 'REGIONAL - Proximity to mainline train station',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.NEAREST_REGIONAL_TRAIN_STATION
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'NEAREST_MAIN_TRAIN_STATION_IN_KM',
          hide:
            IshighlightingActiveColumnsEnable == false &&
            highlightingActiveColumns?.NEAREST_REGIONAL_TRAIN_STATION == false,
          width: 245,
          suppressHeaderMenuButton: true,
          comparator: function (value1, value2) {
            let number1 = value1?.replace('km', '');
            let number2 = value2?.replace('km', '');
            if (number1 === null && number2 === null) {
              return 0;
            }
            if (isNaN(number1)) {
              return -1;
            }
            if (isNaN(number2)) {
              return 1;
            }
            if (number1 === null) {
              return -1;
            }
            if (number2 === null) {
              return 1;
            }
            return number1 - number2;
          },
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
        {
          headerName: 'REGIONAL - Proximity to tram station',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.NEAREST_TRAM_IN_METER
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'NEAREST_TRAM_IN_METER',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.NEAREST_TRAM_IN_METER == false,
          width: 170,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
        // {
        //   headerName: 'Tram stops within 1km',
        //   headerClass:
        //     IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.NEAREST_TRAM_IN_METER
        //       ? 'header-hide_element'
        //       : 'header-unhide_element',
        //   lockPinned: true,
        //   resizable: true,
        //   field: 'TRAMS_WITHIN_1_KM',
        //   hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.NEAREST_TRAM_IN_METER == false,
        //   width: 170,
        //   suppressHeaderMenuButton: true,
        //   cellStyle: {
        //     fontFamily: 'Calibre-R',
        //     fontSize: '500',
        //     lineheight: '16px',
        //     fontStyle: 'normal',
        //     letterSpacing: '-0.01em',
        //     color: '#1A1A1A',
        //     textAlign: 'right',
        //   },
        // },
      ],
    },
    {
      headerName: `Building ${scoreObj?.BUILDING ? '(' + scoreObj?.BUILDING + '%)' : ''}`,
      headerClass: 'header-building',
      lockPinned: true,
      suppressHeaderMenuButton: true,
      resizable: true,
      border: '0px white !important',
      children: [
        {
          resizable: true,

          headerName: 'On-site Dedicated Gym',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_ON_SITE_DEDICATED_GYM
              ? 'header-hide_element'
              : 'header-unhide_element',
          field: 'BI_ON_SITE_DEDICATED_GYM',
          hide:
            IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.BI_ON_SITE_DEDICATED_GYM == false,
          width: 250,
          lockPinned: true,
          suppressHeaderMenuButton: true,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'On-site Catering Available',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_ON_SITE_CATERING_AVAILABLE
              ? 'header-hide_element'
              : 'header-unhide_element',
          resizable: true,
          field: 'BI_ON_SITE_CATERING_AVAILABLE',
          hide:
            IshighlightingActiveColumnsEnable == false &&
            highlightingActiveColumns?.BI_ON_SITE_CATERING_AVAILABLE == false,
          width: 250,
          lockPinned: true,
          suppressHeaderMenuButton: true,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Terraces',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_TERRACES
              ? 'header-hide_element'
              : 'header-unhide_element',
          resizable: true,
          field: 'BI_TERRACES',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.BI_TERRACES == false,
          width: 85,
          lockPinned: true,
          suppressHeaderMenuButton: true,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Floor plate suitability',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_FLOOR_PLATE_SUITABILITY
              ? 'header-hide_element'
              : 'header-unhide_element',
          resizable: true,
          field: 'BI_FLOOR_PLATE_SUITABILITY',
          width: 170,
          lockPinned: true,
          editable: IsEdit,
          hide:
            IshighlightingActiveColumnsEnable == false &&
            highlightingActiveColumns?.BI_FLOOR_PLATE_SUITABILITY == false,
          suppressHeaderMenuButton: true,
          cellEditorSelector: cellEditorSelector,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Look and Feel',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_LOOK_AND_FEEL
              ? 'header-hide_element'
              : 'header-unhide_element',
          resizable: true,
          field: 'BI_LOOK_AND_FEEL',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.BI_LOOK_AND_FEEL == false,
          lockPinned: true,
          width: 120,
          suppressHeaderMenuButton: true,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          valueGetter: function (params) {
            const option = params.data.BI_LOOK_AND_FEEL;
            if (option === 'Blank') return null;
            return option;
          },
          valueFormatter: function (params) {
            if (params.value === '0') return '';
            return params.value;
          },
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Floor to ceiling height',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_FLOOR_TO_CEILING_HEIGHT
              ? 'header-hide_element'
              : 'header-unhide_element',
          resizable: true,
          lockPinned: true,
          field: 'BI_FLOOR_TO_CEILING_HEIGHT',
          hide:
            IshighlightingActiveColumnsEnable == false &&
            highlightingActiveColumns?.BI_FLOOR_TO_CEILING_HEIGHT == false,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          width: 170,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
        {
          headerName: 'Occupational Density',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_OCCUPATIONAL_DENSITY
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'BI_OCCUPATIONAL_DENSITY',
          hide:
            IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.BI_OCCUPATIONAL_DENSITY == false,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          width: 170,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
        {
          headerName: 'Columns',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_COLUMNS
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'BI_COLUMNS',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.BI_COLUMNS == false,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          valueGetter: function (params) {
            const option = params.data.BI_COLUMNS;
            if (option === 'Blank') return null;
            return option;
          },
          valueFormatter: function (params) {
            if (params.value === '0') return '';
            return params.value;
          },
          width: 165,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Natural Light',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_NATURAL_LIGHT
              ? 'header-hide_element'
              : 'header-unhide_element',
          resizable: true,
          lockPinned: true,
          field: 'BI_NATURAL_LIGHT',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.BI_NATURAL_LIGHT == false,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          valueGetter: function (params) {
            const option = params.data.BI_NATURAL_LIGHT;
            if (option === 'Blank') return null;
            return option;
          },
          valueFormatter: function (params) {
            if (params.value === '0') return '';
            return params.value;
          },
          width: 130,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'On-site Amenity',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_ON_SITE_AMENITY
              ? 'header-hide_element'
              : 'header-unhide_element',
          resizable: true,
          lockPinned: true,
          field: 'BI_ON_SITE_AMENITY',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.BI_ON_SITE_AMENITY == false,
          width: 135,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'On-site shower facilities',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_ON_SITE_SHOWER_FACILITIES
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'BI_ON_SITE_SHOWER_FACILITIES',
          hide:
            IshighlightingActiveColumnsEnable == false &&
            highlightingActiveColumns?.BI_ON_SITE_SHOWER_FACILITIES == false,
          width: 200,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Self-contained',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_SELF_CONTAINED
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'BI_SELF_CONTAINED',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.BI_SELF_CONTAINED == false,
          width: 125,
          suppressHeaderMenuButton: true,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Wired Score rating',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_WIREDSCORE_RATING
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'BI_WIREDSCORE_RATING',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.BI_WIREDSCORE_RATING == false,
          width: 150,
          suppressHeaderMenuButton: true,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Building Technology',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_BUILDING_TECHNOLOGY
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'BI_BUILDING_TECHNOLOGY',
          hide:
            IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.BI_BUILDING_TECHNOLOGY == false,
          valueGetter: function (params) {
            const option = params.data.BI_BUILDING_TECHNOLOGY;
            if (option === 'Blank') return null;
            return option;
          },
          valueFormatter: function (params) {
            if (params.value === '0') return '';
            return params.value;
          },
          width: 180,
          suppressHeaderMenuButton: true,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Branding Rights',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_BRANDING_RIGHTS
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'BI_BRANDING_RIGHTS',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.BI_BRANDING_RIGHTS == false,
          valueGetter: function (params) {
            const option = params.data.BI_BRANDING_RIGHTS;
            if (option === 'Blank') return null;
            return option;
          },
          valueFormatter: function (params) {
            if (params.value === '0') return '';
            return params.value;
          },
          width: 150,
          suppressHeaderMenuButton: true,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Air Conditioning',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_AIR_CONDITIONING
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'BI_AIR_CONDITIONING',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.BI_AIR_CONDITIONING == false,
          width: 140,
          suppressHeaderMenuButton: true,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Raised Floors',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_RAISED_FLOORS
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'BI_RAISED_FLOORS',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.BI_RAISED_FLOORS == false,
          width: 120,
          suppressHeaderMenuButton: true,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Car Park Spaces',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_CAR_PARKING_SPACES
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'BI_CAR_PARKING_SPACES',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.BI_CAR_PARKING_SPACES == false,
          width: 130,
          suppressHeaderMenuButton: true,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Lockers',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_LOCKERS
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'BI_LOCKERS',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.BI_LOCKERS == false,
          width: 85,
          suppressHeaderMenuButton: true,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
        {
          headerName: 'Single-let building',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.BI_SINGLE_LET_BUILDING
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'BI_SINGLE_LET_BUILDING',
          hide:
            IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.BI_SINGLE_LET_BUILDING == false,
          width: 150,
          suppressHeaderMenuButton: true,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
      ],
    },
    {
      headerName: `Location & Amenity ${scoreObj?.LOCAL_AMENITY ? '(' + scoreObj?.LOCAL_AMENITY + '%)' : ''}`,
      headerClass: 'header-location',
      suppressHeaderMenuButton: true,
      resizable: true,
      children: [
        {
          headerName: 'Proximity to transport hubs',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.LA_TRANSPORT_HUB
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.LA_TRANSPORT_HUB == false,
          field: 'LA_TRANSPORT_HUB',
          width: 225,
          suppressHeaderMenuButton: true,
          comparator: function (value1, value2) {
            let number1 = value1?.replace('km', '');
            let number2 = value2?.replace('km', '');
            if (number1 === null && number2 === null) {
              return 0;
            }
            if (isNaN(number1)) {
              return -1;
            }
            if (isNaN(number2)) {
              return 1;
            }
            if (number1 === null) {
              return -1;
            }
            if (number2 === null) {
              return 1;
            }
            return number1 - number2;
          },
          // valueFormatter: (params) => CurrencyFormatter(params.value, ''),
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
        {
          headerName: 'Level of local amenity',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.LA_LEVEL_LOCAL_AMENITY
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'LA_LEVEL_LOCAL_AMENITY',
          hide:
            IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.LA_LEVEL_LOCAL_AMENITY == false,
          valueGetter: function (params) {
            const option = params.data.LA_LEVEL_LOCAL_AMENITY;
            if (option === 'Blank') return null;
            return option;
          },
          valueFormatter: function (params) {
            if (params.value === '0') return '';
            return params.value;
          },
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          width: 190,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Green Spaces',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.LA_GREEN_SPACE
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'LA_GREEN_SPACE',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.LA_GREEN_SPACE == false,
          valueGetter: function (params) {
            const option = params.data.LA_GREEN_SPACE;
            if (option === 'Blank') return null;
            return option;
          },
          valueFormatter: function (params) {
            if (params.value === '0') return '';
            return params.value;
          },
          width: 195,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Personal safety',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.LA_PERSONAL_SAFETY
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'LA_PERSONAL_SAFETY',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.LA_PERSONAL_SAFETY == false,
          valueGetter: function (params) {
            const option = params.data.LA_PERSONAL_SAFETY;
            if (option === 'Blank') return null;
            return option;
          },
          valueFormatter: function (params) {
            if (params.value === '0') return '';
            return params.value;
          },
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          width: 195,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Proximity to Clients/Competitors',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.LA_PROX_CLIENT_COMPETITOR
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'LA_PROX_CLIENT_COMPETITOR',
          hide:
            IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.LA_PROX_CLIENT_COMPETITOR == false,
          valueGetter: function (params) {
            const option = params.data.LA_PROX_CLIENT_COMPETITOR;
            if (option === 'Blank') return null;
            return option;
          },
          valueFormatter: function (params) {
            if (params.value === '0') return '';
            return params.value;
          },
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          width: 235,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Proximity to gyms/hotels',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.LA_PROX_GYM_HOTEL
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'LA_PROX_GYM_HOTEL',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.LA_PROX_GYM_HOTEL == false,
          valueGetter: function (params) {
            const option = params.data.LA_PROX_GYM_HOTEL;
            if (option === 'Blank') return null;
            return option;
          },
          valueFormatter: function (params) {
            if (params.value === '0') return '';
            return params.value;
          },
          width: 195,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Flex options in the immediate area',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.LA_FLEX_OPTIONS
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'LA_FLEX_OPTIONS',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.LA_FLEX_OPTIONS == false,
          valueGetter: function (params) {
            const option = params.data.LA_FLEX_OPTIONS;
            if (option === 'Blank') return null;
            return option;
          },
          valueFormatter: function (params) {
            if (params.value === '0') return '';
            return params.value;
          },
          width: 250,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
      ],
    },
    {
      headerName: `ESG ${scoreObj?.ESG ? '(' + scoreObj?.ESG + '%)' : ''}`,
      resizable: true,
      headerClass: 'header-esg',
      suppressHeaderMenuButton: true,
      lockPinned: true,
      children: [
        {
          headerName: 'BREEAM Rating',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.ESG_BREEAM_RATING
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'ESG_BREEAM_RATING',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.ESG_BREEAM_RATING == false,
          width: 140,
          suppressHeaderMenuButton: true,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Nabers Rating',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.ESG_NABERS_RATING
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'ESG_NABERS_RATING',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.ESG_NABERS_RATING == false,
          width: 125,
          suppressHeaderMenuButton: true,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'EPC Rating',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.ESG_EPC_RATING
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'ESG_EPC_RATING',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.ESG_EPC_RATING == false,
          width: 115,
          suppressHeaderMenuButton: true,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'All-Electric Building',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.ESG_ALL_ELECTRIC_BUILDING
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'ESG_ALL_ELECTRIC_BUILDING',
          hide:
            IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.ESG_ALL_ELECTRIC_BUILDING == false,
          valueGetter: function (params) {
            const option = params.data.ESG_ALL_ELECTRIC_BUILDING;
            if (option === 'Blank') return null;
            return option;
          },
          valueFormatter: function (params) {
            if (params.value === '0') return '';
            return params.value;
          },
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          width: 155,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Net Carbon Zero',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.ESG_NET_CARBON_ZERO
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'ESG_NET_CARBON_ZERO',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.ESG_NET_CARBON_ZERO == false,
          valueGetter: function (params) {
            const option = params.data.ESG_NET_CARBON_ZERO;
            if (option === 'Blank') return null;
            return option;
          },
          valueFormatter: function (params) {
            if (params.value === '0') return '';
            return params.value;
          },
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          width: 140,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Naturally Ventilated',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.ESG_NATURALLY_VENTILATED
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'ESG_NATURALLY_VENTILATED',
          hide:
            IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.ESG_NATURALLY_VENTILATED == false,
          valueGetter: function (params) {
            const option = params.data.ESG_NATURALLY_VENTILATED;
            if (option === 'Blank') return null;
            return option;
          },
          valueFormatter: function (params) {
            if (params.value === '0') return '';
            return params.value;
          },
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          width: 158,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'WELL Rating',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.ESG_WELL_RATING
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'ESG_WELL_RATING',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.ESG_WELL_RATING == false,
          width: 115,
          suppressHeaderMenuButton: true,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Cycle Spaces',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.ESG_CYCLE_SPACES
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'ESG_CYCLE_SPACES',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.ESG_CYCLE_SPACES == false,
          width: 115,
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
      ],
    },
    {
      headerName: `Deliverability & Risk ${
        scoreObj?.DELIVERABILITY_RISK ? '(' + scoreObj?.DELIVERABILITY_RISK + '%)' : ''
      }`,
      headerClass: 'header-deliver',
      suppressHeaderMenuButton: true,
      lockPinned: true,
      resizable: true,
      children: [
        {
          headerName: 'Delivery status',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.DR_DELIVERY_DATE
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'DR_DELIVERY_DATE',
          hide: IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.DR_DELIVERY_DATE == false,
          width: 200,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
        {
          headerName: 'Landlord Credibility',
          headerClass:
            IshighlightingActiveColumnsEnable && !highlightingActiveColumns?.DR_LANDLORD_CREDIBILITY
              ? 'header-hide_element'
              : 'header-unhide_element',
          lockPinned: true,
          resizable: true,
          field: 'DR_LANDLORD_CREDIBILITY',
          hide:
            IshighlightingActiveColumnsEnable == false && highlightingActiveColumns?.DR_LANDLORD_CREDIBILITY == false,
          valueGetter: function (params) {
            const option = params.data.DR_LANDLORD_CREDIBILITY;
            if (option === 'Blank') return null;
            return option;
          },
          valueFormatter: function (params) {
            if (params.value === '0') return '';
            return params.value;
          },
          editable: IsEdit,
          cellEditorSelector: cellEditorSelector,
          width: 200,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'left',
          },
        },
      ],
    },
    {
      headerName: 'Financial Considerations & Others',
      headerClass: 'header-financial',
      suppressHeaderMenuButton: true,
      lockPinned: true,
      resizable: true,
      children: [
        {
          headerName: 'Rent from (£/sq.ft.)',
          lockPinned: true,
          resizable: true,
          field: 'FI_RENT',
          valueGetter: (val) => Number(val.data.FI_RENT).toFixed(2),
          valueSetter: (params) => {
            if (params.newValue === undefined || params.newValue > 1000) {
              return false;
            } else {
              params.data.FI_RENT = Number(params.newValue).toFixed(2);
              return true;
            }
          },
          width: 155,
          editable: IsEdit,
          sortable: !IsEdit,
          comparator: !IsEdit ? (a, b) => a - b : () => -1,
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            precision: 2,
            step: 0.01,
            showStepperButtons: true,
            min: 0,
          },
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
        {
          headerName: 'Business Rates from (£/sq.ft.)',
          lockPinned: true,
          resizable: true,
          field: 'FI_BUSINESS_RATES',
          valueGetter: (val) => Number(val.data.FI_BUSINESS_RATES).toFixed(2),
          valueSetter: (params) => {
            if (params.newValue === undefined || params.newValue > 1000) {
              return false;
            } else {
              params.data.FI_BUSINESS_RATES = Number(params.newValue).toFixed(2);
              return true;
            }
          },
          editable: IsEdit,
          sortable: !IsEdit,
          comparator: !IsEdit ? (a, b) => a - b : () => -1,
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            precision: 2,
            step: 0.01,
            showStepperButtons: true,
            min: 0,
          },
          width: 215,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
        {
          headerName: 'Service Charge from (£/sq.ft.)',
          lockPinned: true,
          resizable: true,
          field: 'FI_SERVICE_CHARGE',
          valueGetter: (val) => Number(val.data.FI_SERVICE_CHARGE).toFixed(2),
          valueSetter: (params) => {
            if (params.newValue === undefined || params.newValue > 1000) {
              return false;
            } else {
              params.data.FI_SERVICE_CHARGE = Number(params.newValue).toFixed(2);
              return true;
            }
          },
          editable: IsEdit,
          sortable: !IsEdit,
          comparator: !IsEdit ? (a, b) => a - b : () => -1,
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            precision: 2,
            step: 0.01,
            showStepperButtons: true,
            min: 0,
          },
          width: 215,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
        {
          headerName: 'Estate Charge from (£/sq.ft.)',
          lockPinned: true,
          resizable: true,
          field: 'FI_ESTATE_CHARGE',
          valueGetter: (val) => Number(val.data.FI_ESTATE_CHARGE).toFixed(2),
          valueSetter: (params) => {
            if (params.newValue === undefined || params.newValue > 1000) {
              return false;
            } else {
              params.data.FI_ESTATE_CHARGE = Number(params.newValue).toFixed(2);
              return true;
            }
          },
          editable: IsEdit,
          sortable: !IsEdit,
          comparator: !IsEdit ? (a, b) => a - b : () => -1,
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            precision: 2,
            step: 0.01,
            showStepperButtons: true,
            min: 0,
          },
          width: 215,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
        {
          headerName: 'All in Cost (£/sq.ft.)',
          lockPinned: true,
          resizable: true,
          field: 'FI_ALL_IN_COST',
          valueGetter: AllCostValueGetter,
          valueFormatter: (params) => CurrencyFormatter(params.value, ''),
          width: 155,
          suppressHeaderMenuButton: true,
          sortable: !IsEdit,
          comparator: !IsEdit ? (a, b) => a - b : () => -1,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
        {
          headerName: 'Total Space Selected (sq.ft.)',
          lockPinned: true,
          resizable: true,
          valueFormatter: (params) => CurrencyFormatter(params.value, '$'),
          sortable: !IsEdit,
          comparator: !IsEdit ? (a, b) => a - b : () => -1,
          field: 'FLOOR_SPACE',
          width: 205,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
        {
          headerName: 'Total Annual Cost (£/Annum)',
          lockPinned: true,
          resizable: true,
          // hide: true,
          valueGetter: TotalCostValueGetter,
          valueFormatter: (params) => CurrencyFormatter(params.value, '$'),
          sortable: !IsEdit,
          comparator: !IsEdit ? (a, b) => a - b : () => -1,
          width: 215,
          suppressHeaderMenuButton: true,
          cellStyle: {
            fontFamily: 'Calibre-R',
            fontSize: '500',
            lineheight: '16px',
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: '#1A1A1A',
            textAlign: 'right',
          },
        },
      ],
    },
  ];
  const [columnDefs, setColumnDefs] = useState();
  useEffect(() => {
    setColumnDefs((prev) => {
      const index = prev?.findIndex((el) => el.headerName?.includes('Custom Criteria'));
      if (columnDefs && index !== -1) {
        return [...array.slice(0, -1), prev[index], array[array.length - 1]];
      } else {
        return [...array];
      }
    });
  }, [scoreObj, IshighlightingActiveColumnsEnable, highlightingActiveColumns]);
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
    };
  }, []);
  const onGridReady = useCallback(
    (params) => {
      if (dataList) {
        setRowData(dataList);
        if (dataList) {
          const quotient = Math.floor(dataList.length / rows);
          const remainder = dataList.length % rows;
          if (remainder !== 0) {
            setPageCount(quotient + 1);
          } else {
            setPageCount(quotient);
          }
        }
      }
    },
    [dataList]
  );

  useEffect(() => {
    if (!columnDefs || !dataList.length || !dataList.find((el) => el.CUSTOM_FIELD_VALUES)) return;
    let updatedDefs = [...columnDefs];
    const ccItem = dataList[0].CC_DATA;
    if (!ccItem) return;
    const keys = Object.keys(ccItem);
    const ccDefs = keys.map((el) => ({
      resizable: true,

      headerName: el,
      headerClass:
        IshighlightingActiveColumnsEnable && !custWeightings.includes(el)
          ? 'header-hide_element'
          : 'header-unhide_element',
      field: el,
      hide: IshighlightingActiveColumnsEnable ? false : !custWeightings.includes(el),
      editable: custWeightings.includes(el) && IsEdit,
      cellEditorSelector,
      valueGetter: function (params) {
        const option = params.data[el];
        if (option === 'Blank') return null;
        return option;
      },
      valueFormatter: function (params) {
        if (params.value === '0') return '';
        return params.value;
      },
      width: 200,
      lockPinned: true,
      suppressHeaderMenuButton: true,
    }));
    const ccObj = {
      headerName: `Custom Criteria ${scoreObj?.CUSTOM_WEIGHTAGE ? '(' + scoreObj.CUSTOM_WEIGHTAGE + '%)' : ''}`,
      headerClass: 'custom-criteria',
      lockPinned: true,
      suppressHeaderMenuButton: true,
      resizable: true,
      border: '0px white !important',
      children: [...ccDefs],
    };
    if (!updatedDefs.find((el) => el.headerClass === 'custom-criteria'))
      updatedDefs = [...updatedDefs.slice(0, -1), ccObj, ...updatedDefs.slice(updatedDefs.length - 1)];
    else {
      updatedDefs = updatedDefs.map((el) => {
        if (el.headerClass !== 'custom-criteria') return { ...array.find((elm) => elm.headerClass === el.headerClass) };
        return { ...ccObj };
      });
    }
    setColumnDefs(updatedDefs);
  }, [dataList, scoreObj, IsEdit, custWeightings, IshighlightingActiveColumnsEnable]);
  useEffect(() => {
    if (!IsEdit) {
      if (gridRef.current.api) {
        gridRef.current.api.stopEditing();
      }
    }
    setColumnDefs((prev) => {
      const index = prev?.findIndex((el) => el.headerName?.includes('Custom Criteria'));
      if (columnDefs && index !== -1) {
        return [...array.slice(0, -1), prev[index], array[array.length - 1]];
      } else {
        return [...array];
      }
    });
  }, [IsEdit]);

  useEffect(() => {
    setRowData([...dataList]);
  }, [dataList, IsEdit]);

  useEffect(() => {
    if (dataList?.length) {
      const quotient = Math.floor(dataList.length / rows);
      const remainder = dataList.length % rows;
      if (remainder !== 0) {
        setPageCount(quotient + 1);
        setPageNumber(1);
      } else {
        setPageCount(quotient);
        setPageNumber(1);
      }
    } else {
      setPageCount(0);
      setPageNumber(0);
    }
  }, [dataList.length]);

  const onPaginationChanged = () => {};

  const onBtFirst = useCallback(() => {
    gridRef.current.api.paginationGoToFirstPage();
  }, []);
  const onBtLast = useCallback(() => {
    gridRef.current.api.paginationGoToLastPage();
  }, []);
  const onBtNext = useCallback(() => {
    gridRef.current.api.paginationGoToNextPage();
  }, []);
  const onBtPrevious = useCallback(() => {
    gridRef.current.api.paginationGoToPreviousPage();
  }, []);
  const onPageSizeChanged = useCallback((value) => {
    setRows(value.target.value);
    gridRef.current.api.paginationSetPageSize(Number(value.target.value));
  }, []);

  useEffect(() => {
    if (rowData) {
      const quotient = Math.floor(rowData.length / rows);
      const remainder = rowData.length % rows;
      let value = 0;
      if (remainder !== 0) {
        value = quotient + 1;
      } else {
        value = quotient;
      }
      setPageCount(value);
      if (value < pageNumber) {
        setPageNumber(value);
      }
    }
  }, [rows]);

  useEffect(() => {
    if (!gridRef?.current?.api) {
      return;
    }

    gridRef.current.api.paginationGoToPage(1);
    gridRef.current.api.paginationGoToPage(0);
  }, [dataList.length]);

  // useEffect(() => {
  //   if (!IsEdit) gridRef.current?.columnApi?.resetColumnState();
  // }, [IsEdit]);

  const onCellEditingStarted = useCallback((event) => {}, []);
  const onCellEditingStopped = useCallback(async (event) => {
    let cost =
      parseInt(event.data?.FI_RENT || 0) +
      parseInt(event.data?.FI_BUSINESS_RATES || 0) +
      parseInt(event.data?.FI_SERVICE_CHARGE || 0) +
      parseInt(event.data?.FI_ESTATE_CHARGE || 0);
    let totalCost = parseInt(event.data?.FLOOR_SPACE || 0) * cost;
    let object = {
      key: event.colDef.field,
      value: event.value,
      id: event.data.id,
      HKEY_HUB_PROPERTY: event.data.HKEY_HUB_PROPERTY,
      FI_ALL_COST: cost,
      FI_TOTAL_ANNUAL_COST: totalCost,
    };
    await handleUpdate(object);
  }, []);
  const ClientIconRender = (tag) => {
    if (tag == 'CURRENT_SITE') {
      return <CurrentIcon style={{ alignItems: 'center', display: 'flex', marginTop: 2 }} />;
    } else if (tag == 'OFF_MARKET') {
      return <OffmarketIcon style={{ alignItems: 'center', display: 'flex', marginTop: 2 }} />;
    } else if (tag == 'UNDER_OFFER') {
      return <UnderofferIcon style={{ alignItems: 'center', display: 'flex', marginTop: 2 }} />;
    } else {
      return <></>;
    }
  };
  function ClientIconComponent(props) {
    let addressLineName =
      props?.data?.BUILDING_NAME?.length > 21
        ? props?.data?.BUILDING_NAME?.substring(0, 21) + '...'
        : props?.data?.BUILDING_NAME;

    return (
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'flex-start',
          cursor: 'pointer',
        }}
      >
        {props.data.IS_HIDDEN && (
          <BsEyeSlash
            style={{
              width: '16px',
              color: '#FF543E',
              marginRight: '5px',
            }}
          />
        )}
        <CustomTypography
          text={addressLineName}
          fontfamily='Calibre-R'
          fontsize='16px'
          fontweight='400'
          fontcolor='#1A1A1A'
          lineheight='16px'
          marginRight='8px'
          marginTop='0px'
          onClick={() => {
            const env = window.location.hostname === 'ukplatform-qa.cbre.com' ? 'uat.miq' : 'miq';
            window.open(`https://${env}.cbre.com/records/property/${props.data.PROPERTY_CODE}/edit`, '_blank');
          }}
        />
        {props.data.TAG_PROPERTY && <Grid>{ClientIconRender(props.data.TAG_PROPERTY)}</Grid>}
      </Grid>
    );
  }
  function AddressComponent(props) {
    let address = props.data.ADDRESS_LINE1;
    return (
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'flex-start',
          cursor: 'pointer',
        }}
      >
        <CustomTypography
          text={address}
          // fontfamily='Calibre-R'
          // fontsize='14'
          // fontweight='400'
          // fontcolor='#1A1A1A'
          // lineheight='16px'
          // marginRight='8px'
          // marginTop='0px'

          onClick={() => {
            const env = window.location.hostname === 'ukplatform-qa.cbre.com' ? 'uat.miq' : 'miq';
            window.open(`https://${env}.cbre.com/records/property/${props.data.PROPERTY_CODE}/edit`, '_blank');
          }}
        />
      </Grid>
    );
  }

  function IconComponent(props) {
    if (props.data.LISTED == 'Short') {
      return (
        <StarOpenIcon
          style={{
            cursor: ['Completed', 'Archived'].includes(projectStatus) || props.IsEdit ? 'not-allowed' : 'pointer',
            marginTop: 5,
          }}
          color={projectStatus === 'Completed' || projectStatus === 'Archived' ? '#7F8480' : '#EFDA19'}
          onClick={() => {
            if (!['Completed', 'Archived'].includes(projectStatus) && !props.IsEdit) {
              handleClickStartIcon(props.data);
            }
          }}
        />
      );
    } else {
      return (
        <StarCloseIcon
          style={{
            cursor: ['Completed', 'Archived'].includes(projectStatus) || props.IsEdit ? 'not-allowed' : 'pointer',
            marginTop: 5,
          }}
          onClick={() => {
            if (!['Completed', 'Archived'].includes(projectStatus) && !props.IsEdit) handleClickStartIcon(props.data);
          }}
        />
      );
    }
  }
  const handleClickStartIcon = (inputparams) => {
    handleChangeListstatus(inputparams);
  };
  function CheckComponent(props) {
    if (props.data.VIEWED == true) {
      return (
        <DataviewCheckBoxIcon
          style={{
            cursor: ['Completed', 'Archived'].includes(projectStatus) || props.IsEdit ? 'not-allowed' : 'pointer',
            marginTop: 5,
          }}
          color={projectStatus === 'Completed' ? '#7f8080' : '#003F2D'}
          onClick={() => {
            if (!['Completed', 'Archived'].includes(projectStatus) && !props.IsEdit)
              handleOnChangeViewStatus(props.data);
          }}
        />
      );
    } else {
      return (
        <DataviewUnCheckBoxIcon
          style={{
            cursor: ['Completed', 'Archived'].includes(projectStatus) || props.IsEdit ? 'not-allowed' : 'pointer',
            marginTop: 5,
          }}
          color={projectStatus === 'Completed' || projectStatus === 'Archived' ? '#7f8080' : '#003F2D'}
          onClick={() => {
            if (!['Completed', 'Archived'].includes(projectStatus) && !props.IsEdit)
              handleOnChangeViewStatus(props.data);
          }}
        />
      );
    }
  }
  const handleOnChangeViewStatus = (responseObj) => {
    handleChangeViewStatus(responseObj);
  };
  function AllCostValueGetter(params) {
    var value =
      (params.data?.FI_RENT ? Number(params.data.FI_RENT) : 0) +
      (params.data?.FI_BUSINESS_RATES ? Number(params.data.FI_BUSINESS_RATES) : 0) +
      (params.data?.FI_SERVICE_CHARGE ? Number(params.data.FI_SERVICE_CHARGE) : 0) +
      (params.data?.FI_ESTATE_CHARGE ? Number(params.data.FI_ESTATE_CHARGE) : 0);
    return value.toFixed(2);
  }
  function TotalCostValueGetter(params) {
    var value =
      (params.data?.FI_RENT ? Number(params.data.FI_RENT) : 0) +
      (params.data?.FI_BUSINESS_RATES ? Number(params.data.FI_BUSINESS_RATES) : 0) +
      (params.data?.FI_SERVICE_CHARGE ? Number(params.data.FI_SERVICE_CHARGE) : 0) +
      (params.data?.FI_ESTATE_CHARGE ? Number(params.data.FI_ESTATE_CHARGE) : 0);
    return Math.round((params.data?.FLOOR_SPACE ? Number(params.data.FLOOR_SPACE) : 0) * value.toFixed(2));
  }
  const getRowId = useMemo(() => {
    return (params) => params.data.id;
  }, []);

  useEffect(() => {
    setRowData((prev) => {
      const index = prev.findIndex((el) => el.id === activeRow.id);
      const updated = prev.map((el) => ({ ...el, flag: false }));
      const item = { ...prev[index], flag: true };
      updated[index] = { ...item };
      return [...updated];
    });
  }, [activeRow]);

  // useEffect(() => {
  //   gridRef.current?.api?.refreshCells({
  //     force: true,
  //   });
  // }, [rowData]);

  // useEffect(() => {
  //   if (isEnabled && gridRef.current?.columnApi) {
  //     gridRef.current?.columnApi?.setColumnGroupState([{ groupId: 'collapse', open: false }]);
  //   }
  // }, [columnDefs, IshighlightingActiveColumnsEnable, isEnabled]);

  useEffect(() => {
    dispatch({ type: 'NON_SCORING_SWITCH', payload: false });
    fetchCustomWeightings();
  }, []);

  const fetchCustomWeightings = async () => {
    try {
      const response = await axiosPrivate.get(`/charts-ranking/custom-weightings/${hubweightingKey}`);
      if (response.status === 200 && response.data[0].CUSTOM_WEIGHTAGE > 0) {
        const temp = getObject(response.data[0].CUSTOM_SUB_CAT);
        setCustWeightings(Object.keys(temp).filter((el) => temp[el] > 0));
      }
    } catch (error) {
      console.log('Error while fetching the custom weightings...!', error);
    }
  };

  return (
    <div style={containerStyle}>
      <div
        className='ag-theme-alpine'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          fontFamily: 'Calibre-R',
          fontSize: '500',
          lineheight: '16px',
          fontStyle: 'normal',
          letterSpacing: '-0.01em',
        }}
      >
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'column',
              height: '90%',
            }}
          >
            <div style={gridStyle} className='ag-theme-alpine data-view'>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onCellEditingStarted={onCellEditingStarted}
                onCellEditingStopped={onCellEditingStopped}
                rowSelection={'multiple'}
                paginationPageSize={rows}
                pagination={true}
                suppressPaginationPanel={true}
                suppressScrollOnNewData={false}
                suppressAggFuncInHeader={true}
                suppressMaintainUnsortedOrder={true}
                onGridReady={onGridReady}
                onPaginationChanged={onPaginationChanged}
                suppressDragLeaveHidesColumns={true}
                getRowId={getRowId}
                onRowClicked={(e) => setActiveRow(e.data)}
              />
            </div>
          </Grid>
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'row',
              height: '10%',
              bottom: '0',
            }}
          >
            <Grid
              container
              style={{
                marginBottom: 50,
                display: 'flex',
                backgroundColor: '#FFFFFF',
                height: '100%',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Grid
                item
                xs={3}
                md={3}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <CustomTypography
                  text='Rows Per Page:'
                  fontsize='12px'
                  fontweight='400'
                  lineheight='16px'
                  fontcolor='#767676'
                  marginLeft={24}
                />
                <FormControl
                  sx={{
                    m: 1,
                    minWidth: 40,
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 1.5,
                  }}
                  variant='standard'
                >
                  <Select
                    style={{
                      fontSize: '12px',
                      lineHeight: '16px',
                      fontWeight: '500',
                      fontFamily: 'Calibre-SB',
                    }}
                    labelId='demo-simple-select-label'
                    value={rows}
                    onChange={(e) => {
                      onPageSizeChanged(e);
                    }}
                    id='page-size'
                    disableUnderline
                  >
                    <MenuItem className={classes.MenuItem} value={10}>
                      10
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={20}>
                      20
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={30}>
                      30
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={40}>
                      40
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={50}>
                      50
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={60}>
                      60
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={70}>
                      70
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={80}>
                      80
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={90}>
                      90
                    </MenuItem>
                    <MenuItem className={classes.MenuItem} value={100}>
                      100
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={9}
                md={9}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingRight: 30,
                  alignItems: 'center',
                }}
              >
                <FirstPageIcon
                  style={{ marginRight: 30, cursor: 'pointer' }}
                  onClick={
                    pageNumber != 1 && pageNumber != 0
                      ? () => {
                          setPageNumber(1);
                          onBtFirst();
                        }
                      : null
                  }
                  color={pageNumber === 1 || pageNumber === 0 ? '#767676' : '#878787'}
                  fillOpacity={pageNumber === 1 || pageNumber === 0 ? '.2' : '.8'}
                />
                <LeftVector
                  style={{ marginRight: 30, cursor: 'pointer' }}
                  onClick={
                    pageNumber != 1 && pageNumber != 0
                      ? () => {
                          setPageNumber(pageNumber - 1);
                          onBtPrevious();
                        }
                      : null
                  }
                  color={pageNumber === 1 || pageNumber === 0 ? '#767676' : '#878787'}
                  fillOpacity={pageNumber === 1 || pageNumber === 0 ? '.2' : '.8'}
                />
                <CustomTypography
                  style={{
                    marginRight: 30,
                    border: '1px solid #c6c6c6',
                    padding: 6,
                    fontSize: '12px',
                    lineHeight: '16px',
                  }}
                  text={`${pageNumber} of ${pageCount}`}
                  fontsize={12}
                  fontweight='400'
                  lineheight={16}
                  fontcolor='#262626'
                  marginLeft={24}
                />
                <RightVector
                  style={{ marginRight: 30, cursor: 'pointer' }}
                  onClick={
                    pageNumber != pageCount
                      ? () => {
                          setPageNumber(pageNumber + 1);
                          onBtNext();
                        }
                      : null
                  }
                  color={pageCount == pageNumber ? '#767676' : '#878787'}
                  fillOpacity={pageCount == pageNumber ? '.2' : '.8'}
                />
                <LastPageIcon
                  style={{ cursor: 'pointer' }}
                  onClick={
                    pageNumber != pageCount
                      ? () => {
                          setPageNumber(pageCount);
                          onBtLast();
                        }
                      : null
                  }
                  color={pageCount == pageNumber ? '#767676' : '#878787'}
                  fillOpacity={pageCount == pageNumber ? '.2' : '.8'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
      {snackbar && (
        <SnackBar
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          name={snackbar.name}
          message={snackbar.message}
        />
      )}
    </div>
  );
};
export default CustomDataGrid;
