import React from 'react';

function ArchieveIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      fill='none'
      viewBox='0 0 18 18'
    >
      <path
        fill='#1A1A1A'
        d='M17.54 2.23L16.15.55C15.88.21 15.47 0 15 0H3c-.47 0-.88.21-1.16.55L.46 2.23C.17 2.57 0 3.02 0 3.5V16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3.5c0-.48-.17-.93-.46-1.27zM3.24 2h11.52l.81.97H2.44l.8-.97zM2 16V5h14v11H2zm8.45-9h-2.9v3H5l4 4 4-4h-2.55V7z'
      ></path>
    </svg>
  );
}

export default ArchieveIcon;
