import React from "react";

function EditWeightingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      fill="none"
      viewBox="0 0 19 18"
    >
      <rect width="3" height="8" x="11.063" fill="#1A1A1A" rx="1.5"></rect>
      <rect
        width="3"
        height="8"
        x="3.063"
        y="10"
        fill="#1A1A1A"
        rx="1.5"
      ></rect>
      <path
        fill="#1A1A1A"
        fillRule="evenodd"
        d="M2.063 6c-1.1 0-2-.9-2-2s.9-2 2-2h7v4h2-9zm9 0V2h5c1.1 0 2 .9 2 2s-.9 2-2 2h-5zm-9 10c-1.1 0-2-.9-2-2s.9-2 2-2h4v4h1-5zm6 0h8c1.1 0 2-.9 2-2s-.9-2-2-2h-8v4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default EditWeightingIcon;
