import React from 'react';

function SendIcon({ color = '#fff' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      fill='none'
      viewBox='0 0 25 24'
    >
      <path
        fill={color}
        d='M4.17 20.4l17.45-7.48a1 1 0 000-1.84L4.17 3.6a.993.993 0 00-1.39.91l-.01 4.61c0 .5.37.93.87.99L17.77 12 3.64 13.88c-.5.07-.87.5-.87 1l.01 4.61c0 .71.73 1.2 1.39.91z'
      ></path>
    </svg>
  );
}

export default SendIcon;
