import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import WarningMessagelert from '../../../components/warning_snackbar';
import RightBottomBarChart from '../rightBottomBarChart';
import { allSubWeightings } from '../../../utils/constants';
import { orderBy } from 'lodash';
import { getObject } from '../../../utils/utilities';

const DefaultRightBottomScreen = ({ allWeightings }) => {
  const [criteria, setCriteria] = useState([]);
  const [scoreList, setScoreList] = useState([]);
  const [warningSnackbar, setWarningSnackbar] = useState(null);

  useEffect(() => {
    if (!allWeightings) return;
    handleWeightings(allWeightings);
  }, [allWeightings]);

  const handleWeightings = (weightings) => {
    let data = [];
    Object.keys(weightings).forEach(function (item) {
      switch (item) {
        case 'TRANSPORT':
          Object.keys(weightings).forEach(function (key) {
            if (key.includes('TR_')) {
              if (weightings[key] && weightings[key] > 0) {
                data.push({
                  name: key,
                  value: Math.round((weightings[key] / 100) * weightings['TRANSPORT']),
                });
              }
            }
          });

          break;
        case 'DELIVERABILITY_RISK':
          Object.keys(weightings).forEach(function (key) {
            if (key.includes('DR_')) {
              if (weightings[key] && weightings[key] > 0) {
                data.push({
                  name: key,
                  value: Math.round((weightings[key] / 100) * weightings['DELIVERABILITY_RISK']),
                });
              }
            }
          });

          break;
        case 'ESG':
          Object.keys(weightings).forEach(function (key) {
            if (key.includes('ESG_') && key !== 'ESG_SCORE') {
              if (weightings[key] && weightings[key] > 0) {
                data.push({
                  name: key,
                  value: Math.round((weightings[key] / 100) * weightings['ESG']),
                });
              }
            }
          });

          break;
        case 'BUILDING':
          Object.keys(weightings).forEach(function (key) {
            if (key.includes('BI_')) {
              if (weightings[key] && weightings[key] > 0) {
                data.push({
                  name: key,
                  value: Math.round((weightings[key] / 100) * weightings['BUILDING']),
                });
              }
            }
          });

          break;
        case 'LOCAL_AMENITY':
          Object.keys(weightings).forEach(function (key) {
            if (key.includes('LA_')) {
              if (weightings[key] && weightings[key] > 0) {
                data.push({
                  name: key,
                  value: Math.round((weightings[key] / 100) * weightings['LOCAL_AMENITY']),
                });
              }
            }
          });

          break;
        case 'CUSTOM_WEIGHTAGE':
          const temp = getObject(weightings.CUSTOM_SUB_CAT);
          Object.keys(temp).forEach((el) => {
            if (temp[el] > 0) {
              data.push({
                name: el,
                value: Math.round((temp[el] / 100) * weightings['CUSTOM_WEIGHTAGE']),
              });
            }
          });
          break;
        default:
          break;
      }
    });

    const updated = data.map((el) => {
      if (allSubWeightings[el.name])
        return {
          ...el,
          name: allSubWeightings[el.name],
        };
      return {
        ...el,
      };
    });
    handleSetData(orderBy(updated, ['value', 'name'], ['desc', 'asc']));
  };

  const handleSetData = (items) => {
    let criteriaList = [];
    let scoreData = [];
    items.forEach((el, index) => {
      if (el?.value > 0) {
        let str =
          `${index + 1}` +
          '\u00A0' +
          '\u00A0' +
          '\u00A0' +
          '\u00A0' +
          '\u00A0' +
          `${index < 9 ? '\u00A0' + '\u00A0' : ''}` +
          `${el.name}`;

        criteriaList.push(str);
        if (el.value < 1) {
          scoreData.push(1);
        } else {
          scoreData.push(el.value);
        }
      }
    });
    let stackBarChartScoring = [
      {
        data: scoreData,
        color: '#1F3765',
        name: 'Score',
      },
    ];
    setScoreList(stackBarChartScoring);
    setCriteria(criteriaList);
  };
  return (
    <Grid
      style={{
        height: '34vh',
        overflowY: 'scroll',
      }}
    >
      <RightBottomBarChart
        scoreList={scoreList}
        criteria={criteria}
        ht={criteria.length <= 10 ? 250 : criteria.length * 20}
      />
      {warningSnackbar && (
        <WarningMessagelert
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
    </Grid>
  );
};
export default DefaultRightBottomScreen;
