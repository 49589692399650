import React from "react";

function ChartIcon({ color = "#1A1A1A", width = 20, height = 20, props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 16 16"
            {...props}
        >
            <path
                fill={color}
                d="M8 16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2S6 .9 6 2v12c0 1.1.9 2 2 2zm-6 0c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2s-2 .9-2 2v4c0 1.1.9 2 2 2zm10-9v7c0 1.1.9 2 2 2s2-.9 2-2V7c0-1.1-.9-2-2-2s-2 .9-2 2z"
            ></path>
        </svg>
    );
}

export default ChartIcon;
