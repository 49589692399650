import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '../icons/close_icon';
import WarningIcon from '../icons/warning_icon';
import CustomTypography from '../utils/text';
import { Grid } from '@material-ui/core';
const WarningSnackBar = ({ open, setOpen, message, duration = 4000, name }) => {
  const [vertical, setVertical] = useState('top');
  const [horizontal, setHorizontal] = useState('center');
  const handleClose = (event) => {
    if (setOpen) setOpen(false);
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      style={{ height: 65 }}
    >
      <Alert
        icon={
          <WarningIcon
            fontSize='inherit'
            color={'#1C7D26'}
            width={18}
            height={18}
            style={{ alignItems: 'center' }}
          />
        }
        variant='outlined'
        elevation={6}
        style={{
          backgroundColor: '#FFEDDB',
          border: '1px solid #FF9742',
          display: 'flex',
          alignItems: 'center',
        }}
        action={
          <CloseIcon
            color={'#B85F00'}
            style={{ marginRight: 20, cursor: 'pointer' }}
            onClick={() => {
              handleClose();
            }}
          />
        }
      >
        <Grid style={{ display: 'flex', flexDirection: 'row' }}>
          <CustomTypography
            text={name.length > 50 ? name.slice(0, 50) + '...' : name}
            fontsize='16px'
            fontweight='600'
            fontcolor='#000000'
            lineheight='24px'
            justifycontent='center'
            marginRight={5}
          />
          <CustomTypography
            text={message}
            fontsize='16px'
            fontweight='400'
            fontcolor='#000000'
            lineheight='24px'
            justifycontent='center'
          />
        </Grid>
      </Alert>
    </Snackbar>
  );
};

export default WarningSnackBar;
