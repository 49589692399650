import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import CloseIcon from '../icons/close_icon';
import CustomTypography from '../utils/text';
import useStyles from '../styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import MenuItem from '@mui/material/MenuItem';
import CurrencyFormatter from './currency_formatter';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.24)',
    padding: 10,
  },
}));

const adjustTextLength = (txt = '') => {
  return txt?.length > 40 ? txt.slice(0, 40) + '...' : txt;
};

const tooltipText = (txt = '') => {
  return txt?.length > 40 ? txt : '';
};

function TagSelection({ updateSelection, selectedData = {}, handleAddPropertyRequest, onClose }) {
  const classes = useStyles();
  const [tagSelection, updateTagSelection] = useState('Current site');
  const [submit, updateSubmit] = useState(false);
  const onSubmit = () => {
    updateSubmit(true);
    handleAddPropertyRequest(selectedData, tagSelection);
  };
  return (
    <div>
      {selectedData && (
        <Grid
          container
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            marginTop: 10,
          }}
        >
          <Grid
            xs={12}
            style={{ display: 'flex' }}
          >
            <Grid
              xs={10}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <CustomTypography
                text={selectedData.BUILDING_NAME ?? selectedData.CALCULATED_ADDRESS ?? ''}
                fontsize='36px'
                fontweight='400'
                lineheight='40px'
                fontcolor='#1F1E1E'
              />
            </Grid>
            <Grid
              xs={1}
              style={{
                display: 'flex',
                alignItems: 'flex-top',
                justifyContent: 'flex-end',
                paddingLeft: 35,
                paddingTop: 15,
              }}
            >
              <div
                style={{ cursor: submit ? 'not-allowed' : 'pointer' }}
                onClick={() => {
                  !submit && updateSelection(false);
                  onClose();
                }}
              >
                <CloseIcon />
              </div>
            </Grid>
          </Grid>
          <Grid style={{ width: '100%', marginTop: 20 }}>
            <TableContainer
              component={Paper}
              sx={{ boxShadow: 'none' }}
            >
              <Table
                size='medium'
                sx={{ minWidth: 600 }}
              >
                <TableHead
                  sx={{
                    borderBottom: '1px solid rgba(0, 63, 45, 0.1)',
                    borderTop: '1px solid rgba(0, 63, 45, 0.1)',
                    backgroundColor: 'rgb(245, 247, 247)',
                    height: '40px',
                  }}
                >
                  <TableRow>
                    {['Building name', 'Address', 'Postcode', 'Availability', 'Available from'].map((header) => {
                      return (
                        <TableCell key={header}>
                          <CustomTypography
                            text={header}
                            fontsize='14px'
                            fontweight='600'
                            lineheight='16px'
                            fontcolor='#1F1E1E'
                            justifycontent={header === 'Availability' ? 'center' : 'left'}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                        borderBottom: '1px solid rgba(0, 63, 45, 0.1)',
                      },
                    }}
                  >
                    <HtmlTooltip title={tooltipText(selectedData.BUILDING_NAME ?? '')}>
                      <TableCell>
                        <CustomTypography
                          text={adjustTextLength(selectedData.BUILDING_NAME)}
                          fontsize='14px'
                          fontweight='400'
                          lineheight='21px'
                          fontcolor='#1F1E1E'
                        />
                      </TableCell>
                    </HtmlTooltip>

                    <HtmlTooltip title={tooltipText(selectedData.CALCULATED_ADDRESS ?? '')}>
                      <TableCell>
                        <CustomTypography
                          text={adjustTextLength(selectedData.CALCULATED_ADDRESS)}
                          fontsize='14px'
                          fontweight='400'
                          lineheight='21px'
                          fontcolor='#1F1E1E'
                        />
                      </TableCell>
                    </HtmlTooltip>
                    <HtmlTooltip title={tooltipText(selectedData.POSTAL_CODE ?? '')}>
                      <TableCell>
                        <CustomTypography
                          text={adjustTextLength(selectedData.POSTAL_CODE)}
                          fontsize='14px'
                          fontweight='400'
                          lineheight='21px'
                          fontcolor='#1F1E1E'
                        />
                      </TableCell>
                    </HtmlTooltip>
                    <TableCell>
                      <CustomTypography
                        text={
                          selectedData.AVAILABLE_SPACE_SQFT ? CurrencyFormatter(selectedData.AVAILABLE_SPACE_SQFT) : '0'
                        }
                        fontsize='14px'
                        fontweight='400'
                        lineheight='21px'
                        fontcolor='#1F1E1E'
                        justifycontent='center'
                      />
                    </TableCell>
                    <TableCell>
                      <CustomTypography
                        text={selectedData.AVAILABLE_FROM ?? ''}
                        fontsize='14px'
                        fontweight='400'
                        lineheight='21px'
                        fontcolor='#1F1E1E'
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {submit ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                // paddingTop: '90px',
                paddingBottom: '90px',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: 400,
                fontFamily: 'Calibre-R',
                paddingTop: '200px',
              }}
            >
              <div style={{ paddingBottom: '10px' }}>
                <CircularProgress
                  style={{ color: '#003F2D' }}
                  size={36}
                />
              </div>
              <div>Adding property... </div>
            </div>
          ) : (
            <Grid style={{ padding: 70, width: '57%' }}>
              <Grid>
                <CustomTypography
                  text='Tag property'
                  fontsize='24px'
                  fontweight='bold'
                  lineheight='32px'
                  fontcolor='#1F1E1E'
                />
              </Grid>
              <Grid style={{ marginTop: 10 }}>
                <CustomTypography
                  text='Select the appropriate tag which will also reflect on the Map, Property page and Charts & Ranking page.'
                  fontsize='16px'
                  fontweight='400'
                  lineheight='24px'
                  fontcolor='rgba(26, 26, 26, 0.6)'
                />
              </Grid>
              <Grid style={{ paddingTop: 40 }}>
                {['Off-market', 'Current site', 'Under offer'].map((tag) => {
                  return (
                    <MenuItem
                      key={tag}
                      value={tag}
                      className={`dropdown_dashboard ${tag === tagSelection ? 'dropdown_option' : ''}`}
                      onClick={() => {
                        updateTagSelection(tag);
                      }}
                    >
                      <Radio
                        checked={tagSelection === tag}
                        sx={{
                          '&.Mui-checked': {
                            color: '#003F2D',
                          },
                          '&.MuiRadio-colorPrimary': {
                            color: '#003F2D',
                          },
                        }}
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <CustomTypography
                          text={tag}
                          fontsize='14px'
                          fontweight='400'
                          lineheight='21px'
                          fontcolor='#1A1A1A'
                          fontfamily='Calibre-R'
                        />
                        <div
                          style={{
                            borderRadius: '20px',
                            background:
                              tag === 'Current site' ? ' #4DA6E4 ' : tag === 'Off-market' ? '#BAA6CF' : '#F39DAD',
                            width: '100px',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <CustomTypography
                            text={tag}
                            fontsize='14px'
                            fontweight='400'
                            lineheight='21px'
                            fontcolor='#FFFFFF'
                            fontfamily='Calibre-R'
                          />
                        </div>
                      </div>
                    </MenuItem>
                  );
                })}
              </Grid>
              <Grid
                style={{
                  marginTop: 50,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  className={classes.backbtn}
                  style={{
                    textTransform: 'inherit',
                    background: 'none',
                    width: 120,
                    height: '30px',
                  }}
                  onClick={() => updateSelection(false)}
                >
                  <CustomTypography
                    text='Back'
                    fontsize='16px'
                    fontweight='500'
                    fontcolor={'#003F2D'}
                    lineheight='16px'
                  />
                </Button>
                <Button
                  className={classes.buttonClass}
                  style={{
                    textTransform: 'inherit',
                    marginLeft: 20,
                    width: '138px',
                    height: '30px',
                  }}
                  onClick={onSubmit}
                >
                  <CustomTypography
                    text={'Add property'}
                    fontsize='16px'
                    fontweight='500'
                    lineheight='16px'
                    fontcolor={'#eee'}
                  />
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
}

export default TagSelection;
