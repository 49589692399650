import React from "react";

function CollapseRightIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"  
      {...props}
    >
      <g filter="url(#filter0_dd_1129_5103)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M16 26c6.627 0 12-5.373 12-12S22.627 2 16 2 4 7.373 4 14s5.373 12 12 12z"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#fff"
        fillOpacity="0.01"
        d="M0 0H24V24H0z"
        transform="translate(4 2)"
      ></path>
      <path
        fill="#003F2D"
        fillRule="evenodd"
        d="M14.294 11.698a.99.99 0 010-1.407 1.01 1.01 0 011.42 0l2.964 2.94a1.09 1.09 0 010 1.548l-2.955 2.93a1.01 1.01 0 01-1.638-.322.988.988 0 01.218-1.085l2.318-2.297-2.327-2.307z"
        clipRule="evenodd"
      ></path>
      <defs>
        <filter
          id="filter0_dd_1129_5103"
          width="32"
          height="32"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1129_5103"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.31 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_1129_5103"
            result="effect2_dropShadow_1129_5103"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1129_5103"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default CollapseRightIcon;

