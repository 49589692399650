import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
    innerHeight: '30',
    fontFamily: 'Calibre-R',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '21px',
    alignSelf: 'stretch',
    backgroundColor: 'white',
    '&:-webkit-autofill': {
      backgroundColor: '#FFFFFF !important;',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1a1a1a',
      },
      '&:hover fieldset': {
        borderColor: '#205747',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#205747',
      },
      '&.Mui-error fieldset': {
        borderColor: '#FF543E',
      },
    },
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 100,
  },
}));

const SimpleSelect = ({
  title,
  data,
  handleCallback,
  inputPlaceholder,
  outlined = false,
  value = null,
  isMapview = true,
  error = false,
}) => {
  const [values, setValues] = React.useState(1);
  const classes = useStyles();
  function handleChange(event) {
    setValues(event.target.value);
    handleCallback(event.target.value);
  }
  useEffect(() => {
    if (value) {
      setValues(value);
    }
  }, [value]);
  return (
    <>
      {outlined ? (
        <form className={classes.container} noValidate autoComplete='off'>
          <TextField
            error={error}
            id='outlined-select'
            select
            label={title}
            inputPlaceholder={inputPlaceholder}
            className={classes.textField}
            value={values}
            onChange={handleChange}
            InputProps={{
              disableUnderline: !isMapview,
              style: {
                fontWeight: isMapview ? '400' : 'bold',
              },
            }}
            InputLabelProps={{
              shrink: true,
              style: {
                color: error ? '#FF543E' : '#1A1A1A',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '20px',
                lineHeight: '20px',
                fontFamily: 'Calibre-R',
              },
            }}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin='normal'
            variant={isMapview ? 'outlined' : 'standard'}
          >
            {data.map((value, index) => {
              return (
                <MenuItem
                  key={value.name}
                  value={value.id}
                  style={{
                    paddingLeft: isMapview ? '15px' : '9px',
                  }}
                >
                  {value.name}
                </MenuItem>
              );
            })}
          </TextField>
        </form>
      ) : (
        <FormControl>
          <Select
            value={values}
            onChange={handleChange}
            disableUnderline
            inputProps={{
              name: 'agent',
              id: 'age-simple',
            }}
          >
            {data.map((value, index) => {
              return (
                <MenuItem key={value.name} value={value.id}>
                  {value.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default SimpleSelect;
