import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import LoadingOverlayComponent from '../../components/lodding_component';
import { Grid } from '@material-ui/core';
import RightDrawer from '../right_drawer';
import '../../styles/dataGridview.css';
import CustomTypography from '../../utils/text';
import WarningMessage from '../../components/warning_snackbar';
import AutoComplete from '../../components/autocomplite_textfeild';
import DatePicker from '../../components/date_picker';
import { replaceQuote } from '../../utils/utilities';
import moment from 'moment';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
const SinglePropFilter = ({ open, setOpen, setCount, callback }) => {
  const [industryValue, setIndustryValue] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setTodate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [isInvalidFromDate, setIsInvalidFromDate] = useState(false);
  const [isInvalidToDate, setIsInvalidToDate] = useState(false);
  const industryCodes = useSelector((state) => state.mainReducer.industryCode).map((el) => ({ ...el, title: el.name }));
  const hubUserKey = useSelector((state) => state.mainReducer.hubUser);
  const axiosPrivate = useAxiosPrivate();
  const handleClickReset = () => {
    setIndustryValue([]);
    setFromDate('');
    setTodate('');
    setIsInvalidFromDate(false);
    setIsInvalidToDate(false);
  };

  const handleClickApply = () => {
    let count = 0;
    setIsLoading(true);
    const object = {
      HKEY_HUB_USER: hubUserKey,
    };

    if (fromDate) {
      object['START_DATE'] = moment(new Date(fromDate)).format('YYYY-MM-DD');
      count++;
    }
    if (toDate) {
      object['END_DATE'] = moment(new Date(toDate)).format('YYYY-MM-DD') + ' 23:59:59.001';
      count++;
    }

    if (industryValue.length > 0) {
      object['INDUSTRIES'] = replaceQuote(industryValue);
      count++;
    }
    fetchPropertyFilter(object, count);
  };

  const fetchPropertyFilter = async (object, count) => {
    try {
      const response = await axiosPrivate.post(`/benchmarking/single-property/property-filter`, object);
      if (response.status === 200 && response.data.length > 0) {
        callback();
        setCount(count);
      }
    } catch (error) {
      console.log('Error while fetching the single property filter...!', error);
    } finally {
      setOpen(false);
      setIsLoading(false);
    }
  };
  const handleFromDateCallback = (fromDateValue) => {
    fromDateValue && fromDateValue['$d'] == 'Invalid Date' ? setIsInvalidFromDate(true) : setIsInvalidFromDate(false);

    setFromDate(fromDateValue);
  };
  const handleToDateCallback = (toDateValue) => {
    (toDateValue && toDateValue['$d'] == 'Invalid Date') || fromDate > toDateValue
      ? setIsInvalidToDate(true)
      : setIsInvalidToDate(false);
    setTodate(toDateValue);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchSinglePropertyFilter();
  }, []);

  const fetchSinglePropertyFilter = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/single-property/filter/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        const data = response.data[0];
        const industries = industryCodes
          .filter((el) => data.INDUSTRIES?.includes(el.id))
          .map((elm) => ({ ...elm, title: elm.name }));
        setIndustryValue(industries);

        if (data?.START_DATE) {
          setFromDate(moment(data?.START_DATE));
        }
        if (data?.END_DATE) {
          setTodate(moment(data?.END_DATE));
        }
      }
    } catch (error) {
      console.log('Error while fetcing the single property filter...!', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RightDrawer
      open={open}
      openSecondary={true}
      onClose={() => setOpen(false)}
      variant='temporary'
      width='45%'
    >
      <Grid>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: 99,
            borderBottom: '1px solid #003f2d1a',
            paddingLeft: 30,
            paddingRight: 30,
          }}
        >
          <Grid
            item
            md={6}
            sm={6}
            lg={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <CustomTypography
              text='Filter'
              fontsize='36px'
              fontweight='400'
              lineheight='40px'
              fontcolor='#1A1A1A'
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={6}
            lg={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              style={{
                textTransform: 'inherit',
                borderColor: '#FFFFFF',
                width: '40px',
                marginRight: 5,
              }}
              onClick={handleClickReset}
            >
              <CustomTypography
                text='Clear'
                fontfamily='Calibre-M'
                fontsize='16px'
                fontweight='500'
                fontcolor='#003F2D'
                lineheight='16px'
                backcolor='#FFFFFF'
              />
            </Button>
            <Button
              style={{
                width: 100,
                height: 36,
                backgroundColor:
                  isInvalidFromDate ||
                  isInvalidToDate ||
                  (fromDate > toDate && toDate) ||
                  (!fromDate && moment() > toDate && toDate)
                    ? '#d4d7d7'
                    : '#003F2D',
                color:
                  isInvalidFromDate ||
                  isInvalidToDate ||
                  (fromDate > toDate && toDate) ||
                  (!fromDate && moment() > toDate && toDate)
                    ? '#7f8080'
                    : '#ffffff',
                borderRadius: 4,
              }}
              disabled={
                isInvalidFromDate ||
                isInvalidToDate ||
                (fromDate > toDate && toDate) ||
                (!fromDate && moment() > toDate && toDate)
              }
              onClick={handleClickApply}
            >
              <CustomTypography
                text='Apply'
                fontsize='16px'
                fontfamily='Calibre-M'
                fontweight='500'
                lineheight='16px'
                padding='4px, 12px, 4px, 12px'
                fontcolor='#FFFFFF'
              />
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            height: window.innerHeight - 99,
            paddingBottom: 40,
            overflowX: 'auto',
            paddingLeft: 30,
            paddingRight: 30,
            flexDirection: 'column',
          }}
        >
          <Grid
            container
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <CustomTypography
              text='Projects'
              fontsize='24px'
              fontfamily='Calibre-R'
              fontweight='400'
              lineheight='40px'
              fontcolor='#1A1A1A'
              marginTop='21px'
            />
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingTop: 10 }}
              >
                <AutoComplete
                  valueKey='Benchmarking'
                  key={'ClientIndustry'}
                  title={'Client Industry \xa0'}
                  inputPlaceholder={'Filter by keyword'}
                  data={industryCodes}
                  selectedValue={industryValue}
                  handleChange={(e) => setIndustryValue(e)}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingTop: 15 }}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingTop: 8,
                }}
              >
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingRight: 14,
                    flexDirection: 'column',
                  }}
                >
                  <DatePicker
                    id='fromDate'
                    label={'Date range from' + '\xa0'}
                    width={'100%'}
                    inputPlaceholder={'Choose from date'}
                    fullWidth
                    value={fromDate}
                    maxDate={toDate}
                    minDate={''}
                    height={54}
                    border={false}
                    callback={handleFromDateCallback}
                  />
                  {isInvalidFromDate && fromDate != '' && (
                    <CustomTypography
                      text='Invalid date'
                      fontfamily='Calibre-R'
                      fontsize='11px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#FF543E'
                      marginLeft={12}
                      marginTop={12}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{
                    display: 'flex',
                    paddingLeft: 14,
                    flexDirection: 'column',
                  }}
                >
                  <DatePicker
                    id='toDate'
                    label={'To' + '\xa0'}
                    width={'100%'}
                    value={toDate}
                    inputPlaceholder={'Choose to date'}
                    fullWidth
                    height={54}
                    minDate={fromDate || moment()}
                    border={false}
                    callback={handleToDateCallback}
                  />
                  {isInvalidToDate && (
                    <CustomTypography
                      text={
                        fromDate != '' && fromDate > toDate && toDate != ''
                          ? 'To date must be greater than from date'
                          : 'Invalid date'
                      }
                      fontfamily='Calibre-R'
                      fontsize='11px'
                      fontweight='400'
                      lineheight='16px'
                      fontcolor='#FF543E'
                      marginLeft={12}
                      marginTop={12}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {warningSnackbar && (
          <WarningMessage
            open={true}
            setOpen={() => setWarningSnackbar(null)}
            severity={warningSnackbar.severity}
            name={warningSnackbar.name}
            message={warningSnackbar.message}
          />
        )}
        {isLoading && <LoadingOverlayComponent open={isLoading} />}
      </Grid>
    </RightDrawer>
  );
};
export default SinglePropFilter;
