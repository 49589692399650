import { useEffect, useMemo, useState } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, makeStyles, Button } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Searchicon from '../icons/search_icon';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import { Buffer } from 'buffer';
import CustomTypography from '../utils/text';
import CloseIcon from '../icons/close_icon';
//import { getQueryResponse } from '../redux/actions';
import WarningMessage from '../components/warning_snackbar';
import { useNavigate } from 'react-router-dom';
//import OAuthError from '../utils/errorMessages';
import { useSelector } from 'react-redux';
import { getCapitalized } from '../utils/utilities';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const ShareProjectModal = ({
  open,
  setOpen,
  users = [],
  setUsers,
  loading,
  email,
  hubKey,
  setHubKey,
  getProjects,
  projectName,
  completeUsers = [],
  consolidated,
  setCompleteUsers,
  setIsLoading,
}) => {
  const [popup, setPopup] = useState(false);
  const [value, setValue] = useState(null);
  const [sharedWith, setSharedWith] = useState([]);
  const [fetchedShareWith, setFetchedShareWith] = useState([]);
  const [owner, setOwner] = useState();
  const [collabsLoading, setCollabsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const navigate = useNavigate();
  const hubUser = useSelector((state) => state.mainReducer.hubUser);
  const env = window.location.hostname;
  const axiosPrivate = useAxiosPrivate();
  const useStyles = useMemo(
    () =>
      makeStyles({
        dialog: {
          height: 624,
          width: 620,
          borderRadius: '4px',
        },
        paper: {
          '&.MuiAutocomplete-paper': {
            padding: '10px',
          },
        },
        listbox: {
          '&.MuiAutocomplete-listbox': { paddingRight: '30px' },
          '&.MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
            backgroundColor: '#E9F1F0',
          },
        },
        root: {
          '&:-webkit-autofill': {
            backgroundColor: '#FFFFFF !important;',
          },
          '& .MuiAutocomplete-input': {
            paddingLeft: !popup ? '20px !important' : '',
          },
          '& .MuiInputAdornment-root': {
            paddingLeft: '20px',
          },
          '& .MuiOutlinedInput-root': {
            '& ::placeholder': {
              fontSize: '14px',
            },
            '& fieldset': {
              borderColor: '#1a1a1a',
            },
            '&:hover fieldset': {
              borderColor: '#1a1a1a',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#1a1a1a',
            },
          },
          '& .MuiInputBase-input': {
            height: '15px',
          },
        },
        btn: {
          '&:hover': {
            color: 'white',
            background: '#003F2D',
          },
          background: '#003F2D',
          color: 'white',
          height: 36,
          width: 180,
          '&.MuiButton-outlined': {
            border: '1px solid #003F2D',
          },
        },
        disabledBtn: {
          '&:hover': {
            color: 'white',
            background: '#d4d7d7',
          },
          background: '#d4d7d7',
          color: '#7f8080',
          height: 36,
          width: 180,
        },
        option: {
          '&:hover': {
            backgroundColor: '#E9F1F0 !important',
          },
        },
      }),
    [popup]
  );
  const classes = useStyles();
  const changeHandler = (event, val) => {
    setSharedWith((prev) => {
      const index = prev.findIndex((el) => el.value === val.value);
      if (index !== -1) {
        const updated = [...prev];
        const obj = { ...updated[index], ADDED: !updated[index].ADDED };
        updated[index] = { ...obj };
        return [...updated];
      }

      const updated =
        val.value.toLowerCase() === email.toLowerCase()
          ? [{ ...val, ADDED: true }, ...prev]
          : [...prev, { ...val, ADDED: true }].sort((a, b) => {
              if (a.label < b.label) {
                return -1;
              }
              if (a.label > b.label) {
                return 1;
              }
              return 0;
            });

      return [...updated];
    });
    setValue(val);
    setDisabled(false);
  };

  useEffect(() => {
    setTimeout(() => {
      if (value) setValue(null);
    }, 100);
  }, [value]);

  useEffect(() => {
    if (owner) {
      setCompleteUsers([...completeUsers.filter((el) => el.value !== owner.email)]);
    }
  }, [owner]);

  useEffect(() => {
    setUsers(() => {
      const shared = sharedWith
        .filter((elm) => elm.ADDED)
        .filter((eli) => eli.value !== owner.email)
        .map((el) => el.value);
      return [...completeUsers.filter((elm) => !shared.includes(elm.value))];
    });
  }, [sharedWith, completeUsers]);

  useEffect(() => {
    setCollabsLoading(true);

    if (hubKey || open) {
      getcollabs();
    }

    async function getcollabs() {
      try {
        const response = await axiosPrivate.get(`/dashboard/hub-users?hubprojectkey=${hubKey}`);

        if (response.status === 200) {
          setCollabsLoading(false);
          const mUser = response.data.find((el) => el.ROLE_CODE === 'Owner');
          const oUsers = response.data.filter((elm) => elm.ROLE_CODE === 'EDITOR');
          const ownerName = getCapitalized(
            `${mUser?.FIRST_NAME ? mUser.FIRST_NAME : ''} ${mUser?.LAST_NAME ? mUser.LAST_NAME : ''}`
          );
          setOwner({
            email: mUser.EMAIL,
            name: ownerName,
          });

          const data = oUsers
            .map((el) => {
              const fName = el.FIRST_NAME?.charAt(0).toUpperCase() + el.FIRST_NAME?.slice(1);
              return {
                label: `${fName} ${el.LAST_NAME === 'undefined' ? '' : el.LAST_NAME}|${el.EMAIL}`,
                value: el.EMAIL,
                HKEY_HUB_USER: Buffer.from(el?.HKEY_HUB_USER?.data)?.toString('hex').toUpperCase(),
                ADDED: true,
              };
            })
            .sort((a, b) => {
              if (a.label < b.label) {
                return -1;
              }
              if (a.label > b.label) {
                return 1;
              }
              return 0;
            });
          setFetchedShareWith([...data]);
          setSharedWith([...data]);
        } //end of success response
      } catch (error) {
        console.log('error while calling collabs in shared project model--------------', error);
        setWarningSnackbar({
          name: 'Project Info:',
          message: 'Unable to get share project details',
          severity: 'error',
        });
      }
    }
  }, [hubKey, open]);

  const cancelHandler = () => {
    setOpen(false);
    setSharedWith([]);
    setHubKey();
    setDisabled(true);
  };

  const submitHandler = async () => {
    setDisabled(true);

    const sharedWithUsers = sharedWith.map((el) => {
      return {
        HKEY_HUB_USER: el.HKEY_HUB_USER,
        ADDED: el.ADDED,
        ROLE: 'EDITOR',
      };
    });

    try {
      const reqObj = {
        hubKey: hubKey,
        sharedWithUsers: JSON.stringify(sharedWithUsers).replaceAll('"', "'"),
      };

      const response = await axiosPrivate.post(`/shareprojectmodel/share-project`, reqObj);

      if (response.status === 200) {
        setOpen(false);
        setIsLoading(true);
        updateShareProject();

        const onlyEmails = fetchedShareWith.map((el) => el.value);
        const addedUsers = sharedWith
          .filter((el) => el.ADDED && !onlyEmails.includes(el.value))
          .map((e) => ({
            name: completeUsers.find((elm) => elm.value.toLowerCase() === e.value.toLowerCase())?.label.split('|')[0],
            value: e.value.toLowerCase(),
          }));
        const fromUser = consolidated
          .find((elm) => elm.value.toLowerCase() === email.toLowerCase())
          ?.label.split('|')
          .at(0);
        const capitalized = getCapitalized(fromUser);

        if (addedUsers.length) {
          const userObj = {
            email: email,
            payload: {
              to: addedUsers.map((el) => ({
                ...el,
                name: getCapitalized(el.name),
              })),
              projectName,
              url: `https://${env}`,
              fromUser: capitalized,
              route: 'mailtToUser',
            },
          };
          try {
            const response = await axiosPrivate.post(`/dashboard/postusercount`, userObj);
            console.log('response from usercount', response);
          } catch (error) {
            console.log(error);
          }
        }

        if (addedUsers.length && email.toLowerCase() !== owner.email.toLowerCase()) {
          const ownerObj = {
            email: email,
            payload: {
              owner,
              projectName,
              url: `https://${env}`,
              type: 'userAdded',
              fromUser: capitalized,
              route: 'mailtToOwner',
            },
          };
          try {
            const response = await axiosPrivate.post(`/dashboard/postusercount`, ownerObj);
            console.log('response from usercount', response);
          } catch (error) {
            console.log(error);
          }
        }

        // end-of-postusercount
      }
    } catch (error) {
      setOpen(false);
      console.log(error);
    }
  };

  const updateShareProject = async () => {
    try {
      const reqObj = {
        hubUser: hubUser,
        hubKey: hubKey,
      };

      const response = await axiosPrivate.put(`/shareprojectmodel/share-project`, reqObj);
      if (response.status === 200) {
        getProjects(sharedWith.every((el) => !el.ADDED));
      }
    } catch (error) {
      console.log('error in updateShareProject..................', error);
      setWarningSnackbar({
        name: 'Project Info:',
        message: 'Unable to get share project details',
        severity: 'error',
      });
    }
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle style={{ margin: '30px', marginBottom: 0, padding: 0 }}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <CustomTypography
            text='Share Project'
            fontsize='24px'
            fontweight='500'
            lineheight='32px'
            fontcolor='#1F1E1E'
            letterSpacing='-0.01em'
          />
          <CloseIcon
            onClick={() => {
              setOpen(false);
              setHubKey();
              setUsers([...completeUsers]);
              setSharedWith([]);
              setDisabled(true);
            }}
            style={{
              cursor: 'pointer',
              opacity: 0.8,
              width: '14px',
            }}
          />
        </Grid>
      </DialogTitle>
      <DialogContent style={{ margin: '15px', marginTop: 0, padding: 0 }}>
        <div style={{ margin: '15px', marginBottom: 0 }}>
          <p
            style={{
              marginRight: '64px',
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: '400',
              color: 'rgba(26, 26, 26, 0.6)',
              textAlign: 'justify',
            }}
          >
            Sharing a project will allow you to collaborate with other people in your team. Once shared, they will get
            full edit access to the project.
          </p>
          <Autocomplete
            id='share-project-modal'
            classes={{
              paper: classes.paper,
              popper: classes.popper,
              listbox: classes.listbox,
              option: classes.option,
            }}
            options={users}
            disabled={collabsLoading}
            value={value}
            sx={{ marginTop: '28px' }}
            onOpen={() => setPopup(true)}
            onClose={() => setPopup(false)}
            onChange={(event, value) => changeHandler(event, value)}
            noOptionsText={'No user found'}
            renderOption={(props, option, { selected = false }) => {
              let [name, mail] = option.label.split('|');
              if (option.label.length > 50) mail = mail.slice(0, 20) + '...';
              return (
                <li
                  {...props}
                  style={{
                    fontSize: '14px',
                    lineHeight: '14px',
                    color: '#1A1A1A',
                    padding: '14px 16px',
                  }}
                >
                  <span
                    style={{
                      fontWeight: '600',
                      paddingRight: '10px',
                      borderRight: '2px solid rgba(0, 63, 45, 0.1)',
                    }}
                  >
                    {getCapitalized(name)}
                  </span>
                  <span
                    style={{
                      fontWeight: '500',
                      paddingLeft: '10px',
                      color: 'rgba(26, 26, 26, 1)',
                      fontSize: '13px',
                      lineHeight: '13px',
                    }}
                  >
                    {mail}
                  </span>
                </li>
              );
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  placeholder={!popup ? 'Enter name or email' : ''}
                  variant='outlined'
                  className={classes.root}
                  label='Add team member'
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        {popup && !params.inputProps.value && (
                          <InputAdornment position='start'>
                            <Searchicon />
                          </InputAdornment>
                        )}
                      </>
                    ),
                    endAdornment: (
                      <>
                        {loading && popup && (
                          <CircularProgress color='inherit' size={20} sx={{ color: '#a8a8a8', marginRight: '-10px' }} />
                        )}

                        {!popup && !params.inputProps.value && (
                          <InputAdornment position='end'>
                            <Searchicon />
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      color: '#1A1A1A',
                      fontSize: '19px',
                      marginTop: '-2px',
                      letterSpacing: '0.005em',
                      fontFamily: 'Calibre-R',
                    },
                  }}
                />
              );
            }}
          />
        </div>
        {!collabsLoading ? (
          <section
            style={{
              padding: '28px 45px',
              maxHeight: '290px',
              overflowY: 'auto',
            }}
          >
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '14px',
                lineHeight: '21px',
                color: '#1A1A1A',
                fontWeight: 600,
                paddingBottom: '10px',
                borderBottom: sharedWith.length ? '1px solid rgba(0, 63, 45, 0.1)' : 'none',
              }}
            >
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontWeight: 600,
                }}
              >
                <span>{owner?.name}</span>
                <span style={{ color: 'rgba(26, 26, 26, 0.6)', fontWeight: 400 }}>{owner?.email}</span>
              </Grid>
              <Grid style={{ color: 'rgba(26, 26, 26, 0.46)', fontWeight: 500 }}>Owner</Grid>
            </Grid>
            {sharedWith
              .filter((elm) => elm.ADDED)
              .map((el, index, arr) => {
                const [name, mail] = el.label.split('|');
                return (
                  <Grid
                    key={el.label}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      fontSize: '14px',
                      lineHeight: '21px',
                      color: '#1A1A1A',
                      fontWeight: 600,
                      padding: '10px 0',
                      borderBottom: arr.length - 1 !== index ? '1px solid rgba(0, 63, 45, 0.1)' : 'none',
                    }}
                  >
                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        fontWeight: 600,
                      }}
                    >
                      <span>{getCapitalized(name)}</span>
                      <span
                        style={{
                          color: 'rgba(26, 26, 26, 0.6)',
                          fontWeight: 400,
                        }}
                      >
                        {mail}
                      </span>
                    </Grid>
                    <Grid
                      style={{
                        color: el.value.toLowerCase() === email.toLowerCase() ? 'rgba(26, 26, 26, 0.46)' : '#003F2D',
                        fontWeight: 500,
                        lineHeight: '16px',
                        cursor: email === el.value ? 'text' : 'pointer',
                      }}
                      onClick={() => {
                        setSharedWith((prev) =>
                          prev.map((el) => {
                            // console.log('email:', el.value, mail, email);
                            if (el.value === mail && el.value?.toLowerCase() !== email?.toLowerCase())
                              return { ...el, ADDED: false };
                            return { ...el };
                          })
                        );
                        el.value.toLowerCase() !== email.toLowerCase() && setDisabled(false);
                      }}
                    >
                      Remove
                    </Grid>
                  </Grid>
                );
              })}
          </section>
        ) : (
          <Grid
            item
            md={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '200px',
            }}
          >
            <CircularProgress
              color='inherit'
              size={40}
              sx={{
                color: '#a8a8a8',
              }}
            />
          </Grid>
        )}
        <section>
          <Grid style={{ position: 'absolute', right: '30px', bottom: '30px' }}>
            <Button className={classes.btn} variant='outlined' style={{ background: 'white' }} onClick={cancelHandler}>
              <CustomTypography
                text='Cancel'
                fontfamily='Calibre-R'
                fontsize='16px'
                lineheight='16px'
                fontweight='500'
                fontcolor={false ? '#7f8080' : '#003F2D'}
              />
            </Button>
            {/* <a
              href={`mailto:${sharedWith
                .filter((elm) => elm.ADDED)
                .map((el) => el.value + ';')
                .reduce(
                  (acc, curr) => acc + curr,
                  ''
                )}?subject=Site Evaluation Tool &body=${encodeURIComponent(
                formatted
              )}`}
              style={{
                textDecoration: 'none',
                cursor: disabled ? 'default' : 'pointer',
              }}
            > */}
            <Button
              className={disabled ? classes.disabledBtn : classes.btn}
              style={{
                marginLeft: '30px',
              }}
              onClick={() => {
                submitHandler();
              }}
              disabled={disabled}
            >
              <CustomTypography
                text='Done'
                fontfamily='Calibre-R'
                fontsize='16px'
                lineheight='16px'
                fontweight='500'
                fontcolor={disabled ? '#7f8080' : 'white'}
              />
            </Button>
            {/* </a> */}
          </Grid>
        </section>
      </DialogContent>
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
    </Dialog>
  );
};

export default ShareProjectModal;
