import React from 'react';

function VerticalLineIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1'
      height='24'
      fill='none'
      viewBox='0 0 1 24'
    >
      <path fill='#D9D9D9' d='M0 0H1V24H0z'></path>
    </svg>
  );
}

export default VerticalLineIcon;
