import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import LineImage from '../img/line.png';
const ScoreBarComponet = ({ data = [], tab }) => {
  const [addresLine, setAddresLine] = useState([]);
  const [scoreList, setScoreList] = useState([]);
  useEffect(() => {
    var addressList = [];
    var transportData = [];
    var esgData = [];
    var buildingData = [];
    var localamenityData = [];
    var deliverabilityData = [];
    var rankData = [];
    var totalscoreData = [];
    data.forEach((obj) => {
      addressList.push(obj.ADDRESS_LINE1);
      transportData.push(obj.TRANSPORT_SCORE);
      esgData.push(obj.ESG_SCORE);
      buildingData.push(obj.BUILDING_SCORE);
      localamenityData.push(obj.LOCAL_AMENITY_SCORE);
      deliverabilityData.push(obj.DELIVERABILITY_RISK_SCORE);
      rankData.push(obj.RANK);
      totalscoreData.push(obj.SCORE);
    });
    setAddresLine(addressList);
    var stackBarChartScoring = [
      {
        data: transportData,
        color: '#DBD99A',
        name: 'Transport',
      },
      {
        data: buildingData,
        color: '#3E7CA6',
        name: 'Building',
      },
      {
        data: localamenityData,
        color: '#A388BF',
        name: 'Location & Amenity',
      },
      {
        data: esgData,
        color: '#80BBAD',
        name: 'ESG',
      },
      {
        data: deliverabilityData,
        color: '#885073',
        name: 'Deliverability & Risk',
      },
    ];
    setScoreList(stackBarChartScoring);
  }, [data]);
  return (
    <Chart
      type='bar'
      height={tab ? 350 : 400}
      stacktype='normal'
      series={scoreList}
      options={{
        chart: {
          type: 'bar',
          stacked: true,
          height: tab ? 350 : 400,
          events: {
            stacktype: 'normal',
            stacked: true,
          },
        },
        stroke: {
          width: [2, 2, 2, 2, 2],
          colors: ['#fff'],
          dashArray: 1,
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            barHeight: '75%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: addresLine,
          type: 'category',
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: true,
            align: 'left',
          },
          axisTicks: {
            show: false,
          },
        },
        grid: {
          show: true,
          position: 'back',
          strokeDashArray: 2,
          borderColor: 'rgba(203, 209, 211, 1)',
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 20,
          },
        },
        tooltip: {
          followCursor: true,
          shared: false,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let obj = data[dataPointIndex];
            return `<style>
                                         .root{ 
                                            padding-right: 20px;
                                            padding-left: 20px;
                                            padding-bottom: 10px;
                                            background-color: #FFFFFF;
                                            }
                                            .top10container {
                                            position: relative;
                                            font-family: 'Calibre-R';                                            
                                            word-wrap: break-word;
                                            padding-top:15px;
                                            }
                                            .text-block {
                                                position: absolute; bottom: 5px;right: 10px;top: 20px;width: 25px;height: 28px;
                                                weight: 40px;background-color: #DFE6E5;padding-left: 7px;padding-right: 7px;
                                                border-radius: 10px;text-align: center;
                                            }
                                            span-trans {
                                                color:#1a1a1a99;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;font-style:normal;left: 1457px;right: 210.94px;top: 375px;                                               
                                            }
                                            span-loc {
                                                color:#1a1a1a99;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;font-style:normal;left: 1457px;right: 210.94px;top: 404px;                                              
                                            }
                                            .b {
                                                word-wrap: break-word;
                                              }
                                            span-build {
                                                color:#1a1a1a99;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;font-style:normal;left: 1457px;right: 210.94px;top: 433px;                                                
                                            }
                                            span-esg {
                                                color:#1a1a1a99;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;font-style:normal;left: 1457px;right: 210.94px;top: 462px;                                                
                                            }
                                            span-del {
                                                color:#1a1a1a99;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;font-style:normal;left: 1457px;right: 210.94px;top: 490px;                                                
                                            }
                                            span-rank {
                                                color:#1a1a1a99;font-style:normal;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;left: 1454px;right: 211px;top: 324px;
                                            }
                                            span-address {
                                                color:#000000;font-style:normal;font-weight:400;line-height:24px;font-size:20px;font-family:'Calibre-R';text-align=left;left: 1454px;right: 300px;top: 300px;word-wrap: break-word;
                                            }
                                            span-right {
                                                color:#000000;font-weight:500;font-style: normal;line-height:24px;font-size:12px;font-family:'Calibre-R';float:right;padding-right: 15px;
                                            }
                                            P {
                                                padding : 0;
                                                margin : 0;
                                                line-height : 22px;
                                            }
                                         </style>
                                         <div class="root">
                                            <div class="top10container">
                                            <div class="b">
                                            <span-address>${
                                              obj.ADDRESS_LINE1.length > 20
                                                ? obj.ADDRESS_LINE1.substring(
                                                    0,
                                                    20
                                                  ) + '...'
                                                : obj.ADDRESS_LINE1
                                            }</span-address>
                                                </div>
                                                <div class="text-block">
                                                    <span style="font-size:20px;font-family:'Calibre-R';text-align:center;font-style:normal;font-weight:400;height: 24px;left: 1674px; right: 206px;top: 308px;">${
                                                      Math.round(obj.SCORE) ||
                                                      ''
                                                    }</span>
                                                </div>
                                            </div>
                                            <p>
                                            <span-rank>Rank</span-rank>&nbsp;<span-rank>${
                                              obj.RANK || ''
                                            }</span-rank>
                                            </p>
                                            <p>
                                                <img src=${LineImage} style="width: 260px;height: 1px;left: 1454px;top: 362px;"/>
                                            </p>
                                            <p>
                                                <span-trans>Transport</span-trans>
                                                <span-right>${
                                                  Math.round(
                                                    obj.TRANSPORT_SCORE
                                                  ) || 0
                                                }</span-right>
                                            </p>
                                           
                                            <p> 
                                                <span-build>Building</span-left>  
                                                <span-right>${
                                                  Math.round(
                                                    obj.BUILDING_SCORE
                                                  ) || 0
                                                }</span-right>
                                            </p>
                                            <p> 
                                            <span-loc>Location & Amenity</span-left>  
                                            <span-right>${
                                              Math.round(
                                                obj.LOCAL_AMENITY_SCORE
                                              ) || 0
                                            }</span-right>
                                        </p>
                                            <p>
                                                <span-esg>ESG</span-left>
                                                <span-right>${
                                                  Math.round(obj.ESG_SCORE) || 0
                                                }</span-right>
                                            </p>
                                            <p> 
                                                <span-del>Deliverability & Risk</span-left>                
                                                <span-right>${
                                                  Math.round(
                                                    obj.DELIVERABILITY_RISK_SCORE
                                                  ) || 0
                                                }</span-right>
                                            </p>
                                        </div>`;
          },
        },
      }}
    />
  );
};
export default ScoreBarComponet;
