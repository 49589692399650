import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import PluseIcon from '../icons/pluse_icon';
import Checkboxcomponent from './custom_checkbox';
import CloseFillIcon from '../icons/close_fill_icon';
import ClearTextIcon from '../icons/clear_text';
import CircularProgress from '@mui/material/CircularProgress';

const useAutoCompliteStyles = makeStyles(() => ({
  root: {
    '&:hover': {
      borderColor: '#c4c4c4',
    },
    '&$focused ': {
      borderColor: '#c4c4c4',
    },
    '&.Mui-error': {
      borderColor: '#AB1212',
    },
  },
  focused: {},
  notchedOutline: {},
}));
const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    '&:-webkit-autofill': {
      backgroundColor: '#FFFFFF !important;',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1a1a1a',
      },
      '&:hover fieldset': {
        borderColor: '#205747',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#205747',
      },
    },
  },
  focused: {},
  notchedOutline: {
    '&.MuiOutlinedInput-root.Mui-error': {
      borderColor: '#AB1212',
    },
  },
  inputPlaceholderColor: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        color: '#1a1a1a',
      },
      '&:hover fieldset': {
        color: '#205747',
      },
      '&.Mui-focused fieldset': {
        color: '#205747',
      },
    },
  },
}));
const PostalCodeAutocompleteComponent = ({
  data,
  title,
  inputPlaceholder,
  handleChange,
  selectedValue,
  disabled = false,
  loading = false,
  isSelected,
  flag,
  setFlag = true,
}) => {
  const autoCompliteStyles = useAutoCompliteStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const [selectedList, setSelectedList] = useState([]);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [searchVal, setSearchVal] = useState('');
  const [listboxNode, setListboxNode] = useState('');
  const [position, setPosition] = useState(0);

  useEffect(() => {
    if (listboxNode !== '') {
      listboxNode.scrollTop = position;
    }
  }, [position, listboxNode]);

  const loadMoreResults = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    if (!searchVal) setOptions([...data.slice(0, nextPage * 100)]);
  };
  const handleScroll = (event) => {
    setListboxNode(event.currentTarget);

    const x = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - x <= 1) {
      setPosition(x);
      loadMoreResults();
    }
  };

  const isArraysEqual = (a, b) => a.id === b.id;
  const onlyInLeft = (left, right, compareFunction) =>
    left.filter(
      (leftValue) =>
        !right.some((rightValue) => compareFunction(leftValue, rightValue))
    );
  const handlechangeSelect = (event, value) => {
    var listarray = [];
    const onlyInselectedList = onlyInLeft(selectedList, value, isArraysEqual);
    const onlyInvalue = onlyInLeft(value, selectedList, isArraysEqual);
    if (onlyInselectedList.length > onlyInvalue.length) {
      if (
        onlyInselectedList.length > 0 ? onlyInselectedList[0].id == 0 : false
      ) {
      } else {
        var array = value.filter((e) => e.id != 0);
        listarray = array;
      }
    } else {
      if (onlyInvalue.length > 0 && onlyInvalue[0].id == 0) {
        listarray = data;
      } else {
        if (value.length == data.length - 1) {
          listarray = data;
        } else {
          let array = value.filter((e) => e.id != 0);
          listarray = array;
        }
      }
    }
    setSelectedList(listarray);
    handleChange(listarray);
  };
  const handleDelete = (id) => {
    let data = [...selectedList];
    if (id != 0) {
      let filterList = data.filter((e) => e.id != id && e.id != 0);
      setSelectedList(filterList);
      handleChange(filterList);
    } else {
      setSelectedList([]);
      handleChange([]);
    }
  };
  useEffect(() => {
    setSelectedList(selectedValue);
    handleChange(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    if ((!!data.length && flag) || isSelected) {
      setFlag(false);
      setOptions([...data.slice(0, page * 100)]);
    }
  }, [data, isSelected, flag]);

  useEffect(() => {
    if (!searchVal.length) {
      setFlag(true);
    }
  }, [searchVal]);

  return (
    <Autocomplete
      id={'postalCode'}
      ListboxProps={{
        onScroll: handleScroll,
      }}
      value={selectedList}
      multiple
      limitTags={4}
      disableCloseOnSelect
      disabled={disabled}
      options={options}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
        setPage(1);
        setSearchVal('');
      }}
      onInputChange={(e) => {
        if (e.target?.value?.length >= 2) {
          setSearchVal(e.target.value);
          setPage(1);

          setOptions(
            data.filter((el) =>
              el.title.toLowerCase().includes(e.target.value.toLowerCase())
            )
          );
        }
      }}
      loading={loading}
      popupIcon={<PluseIcon color='#1a1a1a99' style={{ marginRight: 12 }} />}
      clearIcon={<ClearTextIcon style={{ marginRight: 13 }} />}
      onChange={(event, value) => {
        handlechangeSelect(event, value);
      }}
      getOptionLabel={(option) => option.title}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          if (option.title !== 'All') {
            return (
              <Chip
                key={option.id}
                style={{ backgroundColor: '#e6ecea', paddingRight: 6 }}
                size='small'
                label={option.title}
                onDelete={() => {
                  handleDelete(option.id);
                }}
                onClick={() => {
                  handleDelete(option.id);
                }}
                deleteIcon={<CloseFillIcon />}
              />
            );
          }
        })
      }
      isOptionEqualToValue={(option, value) => option.title === value.title}
      renderOption={(props, option, { selected = false }) => (
        <li {...props}>
          <Checkboxcomponent
            id={option.title}
            label={option.title}
            checked={selected}
          />
        </li>
      )}
      renderInput={(params) => (
        <TextField
          style={{
            borderRadius: 4,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
          }}
          {...params}
          variant='outlined'
          className={outlinedInputClasses.root}
          label={title}
          placeholder={selectedList.length == 0 ? inputPlaceholder : ''}
          InputLabelProps={{
            shrink: true,
            style: {
              color: '#1A1A1A',
              fontSize: '20px',
              marginTop: 4,
              lineHeight: '16px',
              fontFamily: 'Calibre-R',
            },
          }}
          InputProps={{
            classes: {
              root: autoCompliteStyles.root,
              focused: autoCompliteStyles.focused,
              notchedOutline: autoCompliteStyles.notchedOutline,
            },
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading && open ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            type: 'search',
          }}
        />
      )}
    />
  );
};
export default PostalCodeAutocompleteComponent;
