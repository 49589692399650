import React from "react";

function LeftVectorIcon({ color = "#1A1A1A",fillOpacity, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="12"
      fill="none"
      viewBox="0 0 8 12"
      {...props}
    >
      <path
        fill={color}
        fillOpacity={fillOpacity}
        d="M7.41 1.41L6 0 0 6l6 6 1.41-1.41L2.83 6l4.58-4.59z"
      ></path>
    </svg>
  );
}

export default LeftVectorIcon;
