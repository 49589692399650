import React, { useEffect, useState } from 'react';
import { Buffer } from 'buffer';
import WarningMessagelert from '../../../components/warning_snackbar';
import LoadingOverlayComponent from '../../../components/lodding_component';
import { useDispatch } from 'react-redux';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

function GetIndividualPropertyData(props) {
  const dispatch = useDispatch();
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let value = props.selectedProp;
    let HKEY_HUB_PROJECT = Buffer.from(value.HKEY_HUB_PROJECT?.data).toString('hex').toUpperCase();
    let HKEY_HUB_WEIGHTING = Buffer.from(value.HKEY_HUB_WEIGHTING?.data).toString('hex').toUpperCase();
    let HKEY_HUB_PROPERTY = Buffer.from(value.HKEY_HUB_PROPERTY?.data).toString('hex').toUpperCase();
    setIsLoading(true);
    const reqObj = {
      hkey: HKEY_HUB_PROJECT,
      hwgtkey: HKEY_HUB_WEIGHTING,
      propertyIdVal: HKEY_HUB_PROPERTY,
    };
    getIdividualProperty(reqObj);
  }, [props.selectedProp]);

  const getIdividualProperty = async (reqObj) => {
    try {
      const response = await axiosPrivate.post(`/charts-ranking/individual-property`, reqObj);
      if (response.status === 200 && response.data.length > 0) {
        handleIndividualPropertyResponse(response.data);
      }
    } catch (error) {
      setWarningSnackbar({
        name: 'Property Info:',
        message: 'Unable to fetch Individual Properties',
        severity: 'error',
      });
    }
  };

  const handleIndividualPropertyResponse = (response) => {
    setIsLoading(false);
    if (response[0]) {
      let data = response[0];
      delete data['HKEY_HUB_PROJECT'];
      delete data['HKEY_HUB_PROPERTY'];
      delete data['HKEY_HUB_WEIGHTING'];
      let arr = Object.entries(data).sort((a, b) => b[1] - a[1]);
      let building = {};
      let transport = {};
      let deliveryability = {};
      let localAmenity = {};
      let esg = {};
      let customCriteria = {};
      let overAllScore = '';
      let mainCategoryScore = {};
      let allSubCategoryScore = {};
      let address = props.selectedProp.ADDRESS_LINE1;
      Object.keys(arr).forEach(function (key) {
        if (arr[key][0].includes('TR_')) {
          if (arr[key][1] && arr[key][1] > 0) {
            transport[arr[key][0]] = arr[key][1];
            // allSubCategoryScore[arr[key][0]] = arr[key][1];
          }
        }
        if (arr[key][0].includes('BI_')) {
          if (arr[key][1] && arr[key][1] > 0) {
            building[arr[key][0]] = arr[key][1];
            // allSubCategoryScore[arr[key][0]] = arr[key][1];
          }
        }
        if (arr[key][0].includes('DR_')) {
          if (arr[key][1] && arr[key][1] > 0) {
            deliveryability[arr[key][0]] = arr[key][1];
            // allSubCategoryScore[arr[key][0]] = arr[key][1];
          }
        }
        if (arr[key][0].includes('ESG_') && arr[key][0] !== 'ESG_SCORE') {
          if (arr[key][1] && arr[key][1] > 0) {
            esg[arr[key][0]] = arr[key][1];
            // allSubCategoryScore[arr[key][0]] = arr[key][1];
          }
        }
        if (arr[key][0].includes('LA_')) {
          if (arr[key][1] && arr[key][1] > 0) {
            localAmenity[arr[key][0]] = arr[key][1];
            // allSubCategoryScore[arr[key][0]] = arr[key][1];
          }
        }
        if (arr[key][0].includes('CUSTOM_SUB_SCORE') && arr[key][1]) {
          const ccKeys = Object.keys(arr[key][1]);
          ccKeys.forEach((el) => {
            if (arr[key][1][el] > 0) customCriteria[el] = arr[key][1][el];
          });
        }
        if (arr[key][0] === 'SCORE') {
          overAllScore = arr[key][1];
        }
        if (
          arr[key][0] === 'TRANSPORT_SCORE' ||
          arr[key][0] === 'LOCAL_AMENITY_SCORE' ||
          arr[key][0] === 'ESG_SCORE' ||
          arr[key][0] === 'BUILDING_SCORE' ||
          arr[key][0] === 'DELIVERABILITY_RISK_SCORE' ||
          arr[key][0] === 'CUSTOM_SCORE'
        ) {
          mainCategoryScore[arr[key][0]] = arr[key][1];
        }
      });

      // let result = [];
      // result.push({
      //   TRANSPORT_SCORE:
      //     Object.values(transport).length > 0
      //       ? Math.max(...Object.values(transport))
      //       : 0,
      // });

      // result.push({
      //   ESG_SCORE:
      //     Object.values(esg).length > 0 ? Math.max(...Object.values(esg)) : 0,
      // });

      // result.push({
      //   DELIVERABILITY_RISK_SCORE:
      //     Object.values(deliveryability).length > 0
      //       ? Math.max(...Object.values(deliveryability))
      //       : 0,
      // });

      // result.push({
      //   LOCAL_AMENITY_SCORE:
      //     Object.values(localAmenity).length > 0
      //       ? Math.max(...Object.values(localAmenity))
      //       : 0,
      // });

      // result.push({
      //   BUILDING_SCORE:
      //     Object.values(building).length > 0
      //       ? Math.max(...Object.values(building))
      //       : 0,
      // });

      // for (let i of Object.entries(mainCategoryScore)) {
      //   for (let j of Object.entries(mainCategoryScore)) {
      //     if (i[0] !== j[0]) {
      //       // if (keysSorted[i] === keysSorted[j]) {
      //       if (mainCategoryScore[i[0]] == mainCategoryScore[j[0]]) {
      //         const modifiedvalue1 = result.find((el) => {
      //           return Object.keys(el)[0] == j[0];
      //         });
      //         const modifiedvalue2 = result.find((el) => {
      //           return Object.keys(el)[0] == i[0];
      //         });
      //         if (modifiedvalue1[j[0]] >= modifiedvalue2[i[0]]) {
      //           let indexi = Object.entries(mainCategoryScore).findIndex(
      //             (el) => el == j[0]
      //           );
      //           let indexj = Object.entries(mainCategoryScore).findIndex(
      //             (el) => el == i[0]
      //           );
      //           if (indexj < indexi) {
      //             let temp = Object.entries(mainCategoryScore)[indexj];
      //             Object.entries(mainCategoryScore)[indexj] =
      //               Object.entries(mainCategoryScore)[indexi];
      //             Object.entries(mainCategoryScore)[indexi] = temp;
      //           }
      //         }
      //       } else {
      //       }
      //     }
      //   }
      // }

      Object.entries(mainCategoryScore).forEach(([item, value], index) => {
        switch (item) {
          case 'TRANSPORT_SCORE':
            let unSortedTransport = {};
            Object.keys(arr).forEach(function (key) {
              if (arr[key][0].includes('TR_')) {
                if (arr[key][1] && arr[key][1] > 0) {
                  // unSortedTransport[arr[key][0]] =
                  //   (arr[key][1] / 100) *
                  //   mainCategoryScore['TRANSPORT_SCORE'];

                  allSubCategoryScore[arr[key][0]] = (arr[key][1] / 100) * mainCategoryScore['TRANSPORT_SCORE'];
                }
              }
            });
            // let sortedTransport = Object.entries(unSortedTransport).sort(
            //   (a, b) => b[1] - a[1]
            // );
            // Object.keys(sortedTransport).forEach(function (key) {
            //   allSubCategoryScore[sortedTransport[key][0]] =
            //     sortedTransport[key][1];
            // });
            break;
          case 'DELIVERABILITY_RISK_SCORE':
            let unSortedDeliverability = {};
            Object.keys(arr).forEach(function (key) {
              if (arr[key][0].includes('DR_')) {
                if (arr[key][1] && arr[key][1] > 0) {
                  // unSortedDeliverability[arr[key][0]] =
                  //   (arr[key][1] / 100) *
                  //   mainCategoryScore['DELIVERABILITY_RISK_SCORE'];
                  allSubCategoryScore[arr[key][0]] =
                    (arr[key][1] / 100) * mainCategoryScore['DELIVERABILITY_RISK_SCORE'];
                }
              }
            });
            // let sortedDeliverability = Object.entries(
            //   unSortedDeliverability
            // ).sort((a, b) => b[1] - a[1]);
            // Object.keys(sortedDeliverability).forEach(function (key) {
            //   allSubCategoryScore[sortedDeliverability[key][0]] =
            //     sortedDeliverability[key][1];
            // });
            break;
          case 'ESG_SCORE':
            let unSortedESG = {};
            Object.keys(arr).forEach(function (key) {
              if (arr[key][0].includes('ESG_') && arr[key][0] !== 'ESG_SCORE') {
                if (arr[key][1] && arr[key][1] > 0) {
                  // unSortedESG[arr[key][0]] =
                  //   (arr[key][1] / 100) * mainCategoryScore['ESG_SCORE'];
                  allSubCategoryScore[arr[key][0]] = (arr[key][1] / 100) * mainCategoryScore['ESG_SCORE'];
                }
              }
            });
            // let sortedESG = Object.entries(unSortedESG).sort(
            //   (a, b) => b[1] - a[1]
            // );
            // Object.keys(sortedESG).forEach(function (key) {
            //   allSubCategoryScore[sortedESG[key][0]] = sortedESG[key][1];
            // });
            break;
          case 'BUILDING_SCORE':
            let unSortedBuilding = {};
            Object.keys(arr).forEach(function (key) {
              if (arr[key][0].includes('BI_')) {
                if (arr[key][1] && arr[key][1] > 0) {
                  // unSortedBuilding[arr[key][0]] =
                  //   (arr[key][1] / 100) * mainCategoryScore['BUILDING_SCORE'];
                  allSubCategoryScore[arr[key][0]] = (arr[key][1] / 100) * mainCategoryScore['BUILDING_SCORE'];
                }
              }
            });
            // let sortedBuilding = Object.entries(unSortedBuilding).sort(
            //   (a, b) => b[1] - a[1]
            // );
            // Object.keys(sortedBuilding).forEach(function (key) {
            //   allSubCategoryScore[sortedBuilding[key][0]] =
            //     sortedBuilding[key][1];
            // });
            break;
          case 'LOCAL_AMENITY_SCORE':
            let unSortedLocalAmenity = {};
            Object.keys(arr).forEach(function (key) {
              if (arr[key][0].includes('LA_')) {
                if (arr[key][1] && arr[key][1] > 0) {
                  // unSortedLocalAmenity[arr[key][0]] =
                  //   (arr[key][1] / 100) *
                  //   mainCategoryScore['LOCAL_AMENITY_SCORE'];
                  allSubCategoryScore[arr[key][0]] = (arr[key][1] / 100) * mainCategoryScore['LOCAL_AMENITY_SCORE'];
                }
              }
            });
            // let sortedLocalAmenity = Object.entries(
            //   unSortedLocalAmenity
            // ).sort((a, b) => b[1] - a[1]);
            // Object.keys(sortedLocalAmenity).forEach(function (key) {
            //   allSubCategoryScore[sortedLocalAmenity[key][0]] =
            //     sortedLocalAmenity[key][1];
            // });
            break;
          case 'CUSTOM_SCORE':
            const ccKeys = Object.keys(customCriteria);
            ccKeys.forEach((el) => {
              allSubCategoryScore[el] = (customCriteria[el] / 100) * mainCategoryScore['CUSTOM_SCORE'];
            });
            break;
          default:
            break;
        }
      });

      allSubCategoryScore = Object.entries(allSubCategoryScore).sort((a, b) => b[1] - a[1]);
      let finalSubCategoryScore = {};
      Object.values(allSubCategoryScore).map((el) => {
        finalSubCategoryScore[el[0]] = el[1];
      });
      var items = [
        {
          transport: transport,
          localAmenity: localAmenity,
          deliveryability: deliveryability,
          building: building,
          esg: esg,
          customCriteria,
          overAllScore: overAllScore,
          mainCategoryScore: mainCategoryScore,
          address: address,
          allSubCategoryScore: finalSubCategoryScore,
        },
      ];
    }
    dispatch({ type: 'SHOW_INDIVIDUAL_PROPERTY', payload: true });
    dispatch({ type: 'UPDATE_INDIVIDUAL_PROPERTY_DATA', payload: items[0] });
  };

  return (
    <>
      {warningSnackbar && (
        <WarningMessagelert
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
      {isLoading && <LoadingOverlayComponent open={isLoading} />}
    </>
  );
}

export default GetIndividualPropertyData;
