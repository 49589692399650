import React, { useMemo } from 'react';
export default (props) => {
  const data = useMemo(() => {
    return props.api.getDisplayedRowAtIndex(props.rowIndex).data;
  }, []);
  return (
    <div>
      <div
        style={{
          backgroundColor: 'white',
          padding: '10px 20px',
          background: '#FFFFFF',
          borderWidth: 1,
          boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.24)',
          borderRadius: '5px',
        }}
      >
        <div
          style={{
            color: 'rgba(26, 26, 26, 1)',
            fontSize: '14px',
            lineHeight: '15px',
            fontFamily: 'Calibre-R',
            width: '150px',
            fontWeight: '400',
          }}
        >
          {data[props.name]}
        </div>
      </div>
    </div>
  );
};
