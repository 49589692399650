import React, { useCallback, useEffect, useState } from 'react';
import CustomTypography from '../utils/text';
import { useSelector, useDispatch } from 'react-redux';
import LoadingOverlayComponent from '../components/lodding_component';
import EditDataIcon from '../icons/edit_pencil_icon';
import SaveIcon from '../icons/save_icon';
import CustomDataGrid from '../components/custom_dataview';
import { Button, Grid, makeStyles } from '@material-ui/core';
import Searchicon from '../icons/search_icon';
import useStyles from '../styles/index';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import _ from 'lodash';
import SnackBar from '../components/SnackBar';
import WarningMessage from '../components/warning_snackbar';
import { Buffer } from 'buffer';
import PlusIcon from '../icons/pluse_icon';
import CustomeSwitchComponent from '../components/custome_switch';
import AddPropertyRightDrawer from '../components/add_property';
import updateDataviewQuery from '../utils/update_dataview';
import AlertBar from '../components/alertBar';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      borderColor: '#1a1a1a',
    },
    '&:hover $notchedOutline': {
      borderColor: '#205747 !important',
    },
    '&$focused $notchedOutline': {
      borderColor: '#205747 !important',
    },
  },
  focused: {},
  notchedOutline: {},
}));

const TableViewScreen = (props) => {
  const outlinedInputClasses = useOutlinedInputStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [searchArray, setSearchArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const hkey = useSelector((state) => state.mainReducer.hkey);
  const hwgtkey = useSelector((state) => state.mainReducer.hwgtkey);
  const hubWorkspace = useSelector((state) => state.mainReducer.hubWorkspace);
  const hubUser = useSelector((state) => state.mainReducer.hubUser);
  const availableTo = useSelector((state) => state.mainReducer.availableTo);
  const updateDataView = useSelector((state) => state.mainReducer.updateDataView);
  const dataviewData = useSelector((state) => state.mainReducer.dataviewList);
  const [search, setSearch] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [scoreObjeect, setScoreObject] = useState();
  const [snackbar, setSnackbar] = useState(null);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [openPropertyDrawer, setOpenPropertyDrawer] = useState(false);
  const [highlightingActiveColumns, setHighlightingActiveColumns] = useState({});
  const [ishighlightingActiveColumnsEnable, setIshighlightingActiveColumnsEnable] = useState(false);
  const projectStatus = useSelector((state) => state.mainReducer.selectedProjectStatus);
  const projectCompletedDate = useSelector((state) => state.mainReducer.selectedProjectCompletedDate);
  const [openAlert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [dataList, setDataList] = useState([]);
  const [propertiesLoading, setPropertiesLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    if (projectStatus === 'Completed' || projectStatus === 'Archived') {
      setAlert(true);
      projectStatus === 'Completed'
        ? setAlertMessage('- This project was marked as completed on')
        : setAlertMessage('- This project was marked as archived on');
    }
  }, [projectStatus]);

  useEffect(() => {
    fetchDataView();
    fetchMainCategoryScores();
    fetchScoringDatviewColumns();
  }, []);

  useEffect(() => {
    const updatedIds = updateDataView.map((el) => el.id);
    const updated = dataviewData.map((el) => {
      if (updatedIds.includes(el.id)) {
        return { ...el, ...updateDataView.find((elm) => elm.id === el.id) };
      } else return { ...el };
    });

    setData(updated);
  }, [dataviewData]);

  useEffect(() => {
    handlesearchArray(search);
  }, [data]);

  const fetchMainCategoryScores = async () => {
    try {
      const response = await axiosPrivate.get(`/mapbox/main-category-scores/${hkey}`);
      if (response.status === 200 && response.data.length > 0) {
        setScoreObject(response.data[0]);
      }
    } catch (error) {
      console.log('Error while fetcing the main scores...!', error);
      setIsLoading(false);
      setWarningSnackbar({
        name: 'Scoring info:',
        message: 'Unable to fetch main category scoring details',
        severity: 'error',
      });
    }
  };

  const fetchScoringDatviewColumns = async () => {
    try {
      const response = await axiosPrivate.get(`/dataview/data-view-score?hkey=${hkey}&hwgtkey=${hwgtkey}`);
      if (response.status === 200 && response.data.length > 0) {
        setHighlightingActiveColumns(response.data[0]);
      }
    } catch (error) {
      console.log('Error while fetcing the data view scores...!', error);
    }
  };

  const fetchDataView = async () => {
    try {
      const response = await axiosPrivate.get(`/dataview/data-view?hkey=${hkey}&hwgtkey=${hwgtkey}`);
      if (response.status === 200 && response.data.length > 0) {
        handledataviewCallback(response.data);
      }
    } catch (error) {
      console.log('Error while fetcing the property details...!', error);
      setWarningSnackbar({
        name: 'Property info:',
        message: 'Unable to fetch property details',
        severity: 'error',
      });
    }
  };

  const handledataviewCallback = (response) => {
    setIsLoading(false);
    setIsEditMode(false);
    let dataList = [];
    const length = response.length;
    if (response.length) {
      dataList = response?.map((item, index) => {
        const ccItem = item.CUSTOM_FIELD_VALUES;
        return {
          ...item,
          id: index + 1,
          IsEdit: false,
          SCORE: Math.round(item.SCORE),
          length,
          CC_DATA: ccItem,
          ...ccItem,
        };
      });
      dispatch({ type: 'SET_DATAVIEW', payload: dataList });
      setData([...dataList]);
    }
  };

  useEffect(() => {
    dispatch({ type: 'SET_DATAVIEW', payload: [] });
  }, []);

  const handlesearchArray = useCallback(
    (searchValue) => {
      setSearch(searchValue);
      if (data.length > 0) {
        var searchArray = _.filter(
          data,
          (p) =>
            String(p['BUILDING_NAME']).toLowerCase().includes(searchValue.toLowerCase()) ||
            String(p['SCORE']).toLowerCase().includes(String(searchValue).toLowerCase()) ||
            String(p['ADDRESS_LINE1']).toLowerCase().includes(searchValue.toLowerCase()) ||
            String(p['POSTAL_CODE']).toLowerCase().includes(searchValue.toLowerCase())
        );
        setSearchArray(searchArray);
      }
    },
    [data]
  );

  const handleUpdateDataView = (object) => {
    dispatch({ type: 'SET_UPDATE_DATAVIEW', payload: object });
  };

  const handleSubmitChanges = async () => {
    if (updateDataView.length > 0) {
      let updatedData = [];
      updateDataView.forEach((item) => {
        const keys = Object.keys(item);
        let temp = {};
        keys.forEach((obj) => {
          if (obj === 'id' || obj.includes('_')) {
            temp = { ...temp, [obj]: item[obj] };
          } else {
            temp = {
              ...temp,
              custom_fields: { ...temp.custom_fields, [obj]: item[obj] },
            };
          }
        });
        updatedData = updatedData.concat(temp);
      });

      updatedData = updatedData.map((el) => {
        const item = data.find(
          (elm) =>
            Buffer.from(el.HKEY_HUB_PROPERTY.data).toString('hex').toUpperCase() ===
            Buffer.from(elm.HKEY_HUB_PROPERTY.data).toString('hex').toUpperCase()
        );
        if (el.custom_fields) {
          let custom_fields = {
            ...item.CUSTOM_FIELD_VALUES,
            ...el.custom_fields,
          };
          return {
            ...el,
            custom_fields: custom_fields,
          };
        }
        return el;
      });
      var updateDataviewObj = updateDataviewQuery(updatedData, hkey, hubUser);

      setIsLoading(true);
      try {
        const response = await axiosPrivate.put(`/dataview/data-view`, updateDataviewObj);
        if (response.status === 200 && response.data.length > 0) {
          handleUpdateDataviewCallback(response.data[0]);
        }
      } catch (error) {
        console.log('Error while fetcing the property scoring details...!', error);
        setIsLoading(false);
        setWarningSnackbar({
          name: 'Scoring Info:',
          message: 'Unable to insert property scoring details',
          severity: 'error',
        });
      }
    } else {
      setIsEditMode(false);
    }
  };

  const handleUpdateDataviewCallback = async () => {
    dispatch({ type: 'CLEAR_UPDATEDATA' });
    const date = availableTo ? "'" + availableTo + "'" : null;
    try {
      const reqObj = {
        hkey: hkey,
        hwgtkey: hwgtkey,
        date: date,
      };
      const response = await axiosPrivate.put(`/dataview/data-view-merge`, reqObj);
      if (response.status === 200 && response.data.length > 0) {
        handleCalledMerge(response.data[0]);
      }
    } catch (error) {
      console.log('Error while fetcing the property scoring details...!', error);
      setIsLoading(false);
      setWarningSnackbar({
        name: 'Scoring Info:',
        message: 'Unable to insert property scoring details',
        severity: 'error',
      });
    }
  };

  const handleCalledMerge = (response) => {
    setIsLoading(false);
    if (response) {
      setSnackbar({
        name: '',
        message: `Properties Scoring & Rating Details are updated successfully `,
        severity: 'success',
      });
      setIsLoading(true);
      fetchDataView();
    }
  };

  useEffect(() => {
    setPropertiesLoading(true);
    fetchProperties();
  }, [hkey]);

  const fetchProperties = async () => {
    try {
      const response = await axiosPrivate.get(`/dataview/properties/${hkey}`);
      if (response.status === 200 && response.data.length > 0) {
        const data = response.data.map((elm) => ({
          ...elm,
          id: elm.PROPERTY_CODE,
          ACTIVE: !elm.TAG_PROP_YN,
        }));
        setDataList(data);
      }
    } catch (error) {
      console.log('Error while fetcing data view properties...!', error);
      setWarningSnackbar({
        name: 'Error:',
        message: 'Unable to fetch properties',
        severity: 'error',
      });
    } finally {
      setPropertiesLoading(false);
    }
  };

  const handleCancelChanges = () => {
    var data = JSON.parse(JSON.stringify(dataviewData));
    setData(data);
    setIsEditMode(false);
    dispatch({ type: 'CLEAR_UPDATEDATA' });
  };

  const handleChangeListstatus = (object) => {
    var obj = {
      ...object,
      LISTED: object.LISTED === 'Short' ? 'Long' : 'Short',
    };
    dispatch({ type: 'SET_MODIFY_DATAVIEW', payload: obj });
    const hubLinkProjctKey = Buffer.from(object.LKEY_PROPERTY_PROJECT.data);
    const hubLinkPropKey = Buffer.from(object.HKEY_HUB_PROPERTY.data);
    const reqObj = {
      isShortList: object.LISTED === 'Short' ? false : true,
      hubLinkProjctKey: hubLinkProjctKey.toString('hex').toUpperCase(),
      hkey: hkey,
      propertyId: hubLinkPropKey.toString('hex').toUpperCase(),
    };
    updateShortList(reqObj, object);
  };

  const updateShortList = async (reqObj, object) => {
    try {
      const response = await axiosPrivate.put(`/short-list/status`, reqObj);
      if (response.status === 200 && response.data.length > 0) {
        fetchDataView();
        setSnackbar({
          name: object?.BUILDING_NAME,
          message:
            object.LISTED === 'Short'
              ? ` has been successfully removed from your shortlist`
              : `has been successfully added to your shortlist`,
          severity: 'success',
        });
      }
    } catch (error) {
      console.log('Error while list/unlist the property from shortlist list...!', error);
      setWarningSnackbar({
        name: 'Shortlisted Property Info:',
        message: 'Unable to list/unlist the property from shortlist list',
        severity: 'error',
      });
    }
  };

  const handleChangeViewStatus = (object) => {
    var obj = { ...object, VIEWED: !object.VIEWED };
    dispatch({ type: 'SET_MODIFY_DATAVIEW', payload: obj });
    const hubLinkProjctKey = Buffer.from(object.LKEY_PROPERTY_PROJECT.data);
    const hubLinkPropKey = Buffer.from(object.HKEY_HUB_PROPERTY.data);
    const reqObj = {
      listed: object.VIEWED === true ? false : true,
      hubLinkProjctKey: hubLinkProjctKey.toString('hex').toUpperCase(),
      hkey: hkey,
      hubLinkPropKey: hubLinkPropKey.toString('hex').toUpperCase(),
    };
    changeViewStatus(reqObj, object);
  };

  const changeViewStatus = async (reqObj, object) => {
    try {
      const response = await axiosPrivate.put(`/dataview/change-view-status`, reqObj);
      setIsLoading(false);
      if (response.status === 200 && response.data.length > 0) {
        fetchDataView();
        setSnackbar({
          name: object?.BUILDING_NAME,
          message: object.VIEWED
            ? ` has been successfully marked as unviewed`
            : ` has been successfully marked as viewed`,
          severity: 'success',
        });
      }
    } catch (error) {
      console.log('Error while list/unlist the property from viewed list...!', error);
      setWarningSnackbar({
        name: 'Viewed Property Info:',
        message: 'Unable to list/unlist the property from viewed list',
        severity: 'error',
      });
    }
  };

  const handleSuccessCallback = (name, tagSelection = '') => {
    //  client property.
    setSnackbar({
      name: name,
      message: `was added as a ${tagSelection}. `,
      severity: 'success',
    });
    fetchDataView();
    fetchMainCategoryScores();
  };

  return (
    <Grid style={{ backgroundColor: '#eef1f1', height: '100%' }}>
      <>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            flexDirection: 'row',
            paddingLeft: 60,
            paddingRight: 60,
            height: '14%',
            paddingBottom: 24,
          }}
        >
          <Grid
            item
            md={6}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <CustomTypography
              text='Data view'
              fontsize='36px'
              fontweight='400'
              lineheight='40px'
              fontcolor='#1A1A1A'
            />
            {isEditMode && (
              <CustomTypography
                text='  (edit mode)'
                fontsize='36px'
                fontweight='400'
                lineheight='40px'
                fontcolor='#80BBAD'
              />
            )}
          </Grid>
          <Grid
            item
            md={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {isEditMode ? (
              <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  className={classes.cancel_btn}
                  onClick={() => handleCancelChanges()}
                  style={{ marginRight: 30 }}
                >
                  <CustomTypography
                    text='Cancel'
                    fontfamily='Calibre-SB'
                    fontsize='16px'
                    lineheight='16px'
                    fontweight='500'
                    fontcolor='#003F2D'
                  />
                </Button>
                <Button
                  className={classes.editbtn}
                  onClick={() => handleSubmitChanges()}
                >
                  <SaveIcon
                    style={{ paddingRight: 11 }}
                    width={18}
                    height={18}
                  />
                  <CustomTypography
                    text='Apply changes'
                    fontfamily='Calibre-SB'
                    fontsize='16px'
                    lineheight='16px'
                    fontweight='500'
                    fontcolor='#FFFFFF'
                  />
                </Button>
              </Grid>
            ) : (
              <Button
                disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
                className={
                  projectStatus === 'Completed' || projectStatus === 'Archived'
                    ? classes.disablebuttonClass
                    : classes.editbtn
                }
                onClick={() => setIsEditMode(true)}
              >
                <EditDataIcon
                  style={{ paddingRight: 11, paddingBottom: 7 }}
                  width={18}
                  height={28}
                  color={projectStatus === 'Completed' || projectStatus === 'Archived' ? '#7f8080' : 'white'}
                />
                <CustomTypography
                  text='Edit data'
                  fontfamily='Calibre-SB'
                  fontsize='16px'
                  lineheight='16px'
                  fontweight='500'
                  fontcolor={projectStatus === 'Completed' || projectStatus === 'Archived' ? '#7f8080' : '#FFFFFF'}
                />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
            paddingLeft: 27,
            paddingRight: 27,
            height: '10%',
          }}
        >
          <Grid
            item
            md={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              backgroundColor: '#FFFFFF',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <OutlinedInput
              style={{ height: 40, marginLeft: 24, width: 300 }}
              id='outlined-adornment-weight'
              value={search}
              onChange={(event) => handlesearchArray(event.target.value)}
              placeholder={'Search'}
              endAdornment={
                <InputAdornment position='end'>
                  <Searchicon />
                </InputAdornment>
              }
              aria-describedby='outlined-weight-helper-text'
              inputProps={{
                'aria-label': 'weight',
              }}
              classes={outlinedInputClasses}
            />
          </Grid>
          <Grid
            item
            md={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              backgroundColor: '#FFFFFF',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingRight: 20,
                paddingTop: 5,
              }}
            >
              <CustomTypography
                text={'Display non-scoring criteria' + '\xa0'}
                fontfamily='Calibre-SB'
                fontsize='14px'
                fontweight='500'
                fontcolor={'#1A1A1A'}
                lineheight='21px'
                marginRight={5}
              />
              <div
                onClick={() => {
                  dispatch({ type: 'NON_SCORING_SWITCH', payload: true });
                  setIshighlightingActiveColumnsEnable(!ishighlightingActiveColumnsEnable);
                }}
              >
                <CustomeSwitchComponent value={ishighlightingActiveColumnsEnable} />
              </div>
            </Grid>
            <Button
              className={
                isEditMode || projectStatus === 'Completed' || projectStatus === 'Archived'
                  ? classes.disableaddPropertyClass
                  : classes.cancel_btn
              }
              disabled={isEditMode || projectStatus === 'Completed' || projectStatus === 'Archived'}
              style={{ marginRight: 36, width: 172, textTransform: 'inherit' }}
              onClick={() => {
                setOpenPropertyDrawer(true);
                dispatch({
                  type: 'SET_SEARCH_VALUE_ADD_PROPERTY',
                  payload: '',
                });
              }}
            >
              <PlusIcon
                style={{ paddingRight: 13 }}
                color={
                  isEditMode || projectStatus === 'Completed' || projectStatus === 'Archived' ? '#7f8080' : '#003F2D'
                }
              />
              <CustomTypography
                text='Add properties'
                fontfamily='Calibre-SB'
                fontsize='16px'
                fontweight='500'
                fontcolor={
                  isEditMode || projectStatus === 'Completed' || projectStatus === 'Archived' ? '#7f8080' : '#003F2D'
                }
                lineheight='16px'
              />
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
            height: '76%',
            paddingLeft: 27,
            paddingRight: 27,
          }}
        >
          <CustomDataGrid
            dataList={search ? searchArray : data}
            IsEdit={isEditMode}
            projectStatus={projectStatus}
            scoreObj={scoreObjeect}
            handleUpdate={handleUpdateDataView}
            handleChangeListstatus={handleChangeListstatus}
            highlightingActiveColumns={highlightingActiveColumns}
            handleChangeViewStatus={handleChangeViewStatus}
            IshighlightingActiveColumnsEnable={ishighlightingActiveColumnsEnable}
            getTableData={fetchDataView}
            setClientProperties={setDataList}
          />
        </Grid>
        {snackbar && (
          <SnackBar
            open={true}
            setOpen={() => setSnackbar(null)}
            severity={snackbar.severity}
            name={snackbar.name}
            message={snackbar.message}
          />
        )}
        {warningSnackbar && (
          <WarningMessage
            open={true}
            setOpen={() => setWarningSnackbar(null)}
            severity={warningSnackbar.severity}
            name={warningSnackbar.name}
            message={warningSnackbar.message}
          />
        )}
        {openPropertyDrawer && (
          <AddPropertyRightDrawer
            open={openPropertyDrawer}
            onClose={() => {
              setOpenPropertyDrawer(false);
              dispatch({ type: 'SET_SEARCH_VALUE_ADD_PROPERTY', payload: '' });
            }}
            handleSuccessCallback={(message, tagSelection) => handleSuccessCallback(message, tagSelection)}
            dataList={dataList}
            setDataList={setDataList}
            isLoading={propertiesLoading}
          />
        )}
      </>
      {isLoading && <LoadingOverlayComponent open={isLoading} />}
      {openAlert && (
        <AlertBar
          open={true}
          setOpen={() => setAlert(null)}
          name={'View only mode '}
          message={alertMessage}
          // date field is pending
          date={projectCompletedDate}
        />
      )}
    </Grid>
  );
};
export default TableViewScreen;
