import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Grid } from '@material-ui/core';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomTypography from '../utils/text';
import LeftVector from '../icons/left_vector';
import RightVector from '../icons/right_vector';
import FirstPageIcon from '../icons/firstpage_icon';
import LastPageIcon from '../icons/lastPage_icon';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Button } from '@material-ui/core';
import { MdOutlineFileDownload } from 'react-icons/md';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import useStyles from '../styles/index';
import { downloadFileS3 } from '../utils/DownloadFileS3';
import WarningMessage from '../components/warning_snackbar';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const ClientReportsGrid = ({ data }) => {
  const gridRef = useRef();
  const classes = useStyles();
  const [rows, setRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState();
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [loadingItem, setLoadingItem] = useState();
  const axiosPrivate = useAxiosPrivate();
  const clickHandler = (item) => {
    const fileName = item.PATH?.split('/').at(-1);
    setLoadingItem(item.id);
    downloadFileS3(fileName, axiosPrivate)
      .then()
      .catch(() => {
        setWarningSnackbar({
          name: 'Error',
          message: 'while downloading PPT file.',
          severity: 'error',
        });
      })
      .finally(() => setLoadingItem());
  };
  const BtnCellRenderer = (props) => {
    return (
      <Button
        variant='outlined'
        style={{ background: '#fff', border: '1px solid #003F2D' }}
        onClick={() => clickHandler(props.data)}
      >
        {props.data.id === loadingItem ? (
          <CircularProgress
            color='inherit'
            size={18}
            sx={{ color: '#003F2D', marginRight: '10px' }}
          />
        ) : (
          <MdOutlineFileDownload
            color='#003F2D'
            style={{ width: '18px', height: '18px', marginRight: '10px' }}
          />
        )}
        <CustomTypography
          text='Download'
          fontfamily='Calibre-R'
          fontsize='16px'
          fontweight='500'
          fontcolor='#003F2D'
          lineheight='16px'
        />
      </Button>
    );
  };

  const columnDefs = [
    {
      headerName: 'Report Name',
      field: 'REPORT_NAME',
      valueGetter: (params) =>
        params.data['REPORT_NAME']?.length > 60
          ? params.data['REPORT_NAME']?.substring(0, 60) + '...'
          : params.data['REPORT_NAME'],
      width: 380,
      resizable: false,
      cellStyle: { textAlign: 'left' },
      tooltipField: 'REPORT_NAME',
    },
    {
      headerName: '# of shortlist properties',
      field: 'NUMBER_OF_SHORTLIST_PROPERTIES',
      width: 200,
      // headerClass: 'ag-center-header',
      cellStyle: { textAlign: 'left' },
      resizable: false,
    },
    {
      headerName: 'Last Exported',
      field: 'LAST_EXPORTED_ON',
      width: 240,
      // headerClass: 'ag-center-header',
      cellStyle: { textAlign: 'left' },
      resizable: false,
    },
    {
      headerName: 'Exported by',
      field: 'EXPORTED_BY',
      width: 380,
      // headerClass: 'ag-center-header',
      cellStyle: { textAlign: 'left' },
      resizable: false,
    },
    {
      headerName: '',
      cellRenderer: BtnCellRenderer,
      width: 200,
      resizable: false,
    },
  ];

  useEffect(() => {
    setRowData(data);
    const quotient = Math.floor(data.length / rows);
    const remainder = data.length % rows;
    if (remainder) setPageCount(quotient + 1);
    else setPageCount(quotient);
  }, [data]);
  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
    };
  }, []);

  const onGridReady = useCallback(() => {
    setRowData(data);
  }, [data]);

  const onPaginationChanged = useCallback(() => {
    if (gridRef.current?.api) {
    }
  }, []);

  const onBtFirst = useCallback(() => {
    gridRef.current.api.paginationGoToFirstPage();
  }, []);

  const onBtLast = useCallback(() => {
    gridRef.current.api.paginationGoToLastPage();
  }, []);

  const onBtNext = useCallback(() => {
    gridRef.current.api.paginationGoToNextPage();
  }, []);

  const onBtPrevious = useCallback(() => {
    gridRef.current.api.paginationGoToPreviousPage();
  }, []);

  const onPageSizeChanged = useCallback((value) => {
    setRows(value.target.value);
    gridRef.current.api.paginationSetPageSize(Number(value.target.value));
  }, []);

  useEffect(() => {
    if (rowData?.length) {
      const quotient = Math.floor(rowData.length / rows);
      const remainder = rowData.length % rows;
      let value = 0;
      if (remainder !== 0) {
        value = quotient + 1;
      } else {
        value = quotient;
      }
      setPageCount(value);
      if (value < pageNumber) {
        setPageNumber(value);
      }
    }
  }, [rows]);

  return (
    <div style={containerStyle}>
      <div
        className='example-wrapper'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'column',
              height: '86%',
            }}
          >
            <div
              style={gridStyle}
              className='ag-theme-alpine'
            >
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                paginationPageSize={rows}
                pagination={true}
                suppressPaginationPanel={true}
                onGridReady={onGridReady}
                onPaginationChanged={onPaginationChanged}
                suppressDragLeaveHidesColumns={true}
              ></AgGridReact>
            </div>
          </Grid>
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'row',
              height: '10%',
              bottom: 0,
            }}
          >
            <Grid
              container
              style={{
                display: 'flex',
                backgroundColor: '#FFFFFF',
                height: '100%',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Grid
                item
                xs={3}
                md={3}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <CustomTypography
                  text='Rows Per Page:'
                  fontsize='12px'
                  fontweight='400'
                  lineheight='16px'
                  fontcolor='#767676'
                  marginLeft={24}
                />
                <FormControl
                  sx={{
                    m: 1,
                    minWidth: 40,
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 1.5,
                  }}
                  variant='standard'
                >
                  <Select
                    style={{
                      fontSize: '12px',
                      fontFamily: 'calibre-SB',
                      lineHeight: '16px',
                      fontWeight: '500',
                    }}
                    labelId='demo-simple-select-label'
                    value={rows}
                    onChange={(e) => {
                      onPageSizeChanged(e);
                    }}
                    id='page-size'
                    disableUnderline
                  >
                    <MenuItem
                      className={classes.MenuItem}
                      value={10}
                    >
                      10
                    </MenuItem>
                    <MenuItem
                      className={classes.MenuItem}
                      value={20}
                    >
                      20
                    </MenuItem>
                    <MenuItem
                      className={classes.MenuItem}
                      value={30}
                    >
                      30
                    </MenuItem>
                    <MenuItem
                      className={classes.MenuItem}
                      value={40}
                    >
                      40
                    </MenuItem>
                    <MenuItem
                      className={classes.MenuItem}
                      value={50}
                    >
                      50
                    </MenuItem>
                    <MenuItem
                      className={classes.MenuItem}
                      value={60}
                    >
                      60
                    </MenuItem>
                    <MenuItem
                      className={classes.MenuItem}
                      value={70}
                    >
                      70
                    </MenuItem>
                    <MenuItem
                      className={classes.MenuItem}
                      value={80}
                    >
                      80
                    </MenuItem>
                    <MenuItem
                      className={classes.MenuItem}
                      value={90}
                    >
                      90
                    </MenuItem>
                    <MenuItem
                      className={classes.MenuItem}
                      value={100}
                    >
                      100
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={9}
                md={9}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingRight: 30,
                  alignItems: 'center',
                }}
              >
                <FirstPageIcon
                  style={{ marginRight: 30, cursor: 'pointer' }}
                  onClick={
                    pageCount != 0 && pageNumber != 1
                      ? () => {
                          setPageNumber(1);
                          onBtFirst();
                        }
                      : null
                  }
                  color={pageNumber == 0 || pageNumber === 1 ? '#767676' : '#878787'}
                  fillOpacity={pageNumber == 0 || pageNumber === 1 ? '.2' : '.8'}
                />
                <LeftVector
                  style={{ marginRight: 30, cursor: 'pointer' }}
                  // onClick={pageCount != 0 && pageNumber != 1 ? () => { setPageNumber(pageNumber - 1); onBtPrevious() } : null}
                  onClick={
                    pageCount != 0 && pageNumber != 1
                      ? () => {
                          setPageNumber(pageNumber - 1);
                          onBtPrevious();
                        }
                      : null
                  }
                  color={pageNumber == 0 || pageNumber === 1 ? '#767676' : '#878787'}
                  fillOpacity={pageNumber == 0 || pageNumber === 1 ? '.2' : '.8'}
                />
                <CustomTypography
                  style={{
                    marginRight: 30,
                    border: '1px solid #c6c6c6',
                    padding: 6,
                    fontSize: '12px',
                    lineHeight: '16px',
                  }}
                  text={`${pageNumber > pageCount ? pageCount : pageNumber} of ${pageCount}`}
                  fontsize={12}
                  fontfamily='Calibre-R'
                  fontweight='400'
                  lineheight={16}
                  fontcolor='#262626'
                  marginLeft={24}
                />
                <RightVector
                  style={{ marginRight: 30, cursor: 'pointer' }}
                  // onClick={pageCount != 0 && pageNumber != pageCount ? () => { setPageNumber(pageNumber + 1); onBtNext() } : null}
                  onClick={
                    pageCount != 0 && pageNumber != pageCount
                      ? () => {
                          setPageNumber(pageNumber + 1);
                          onBtNext();
                        }
                      : null
                  }
                  color={pageCount == pageNumber ? '#767676' : '#878787'}
                  fillOpacity={pageCount == pageNumber ? '.2' : '.8'}
                />
                <LastPageIcon
                  style={{ cursor: 'pointer' }}
                  onClick={
                    pageCount != 0 && pageNumber != pageCount
                      ? () => {
                          setPageNumber(pageCount);
                          onBtLast();
                        }
                      : null
                  }
                  color={pageCount == pageNumber ? '#767676' : '#878787'}
                  fillOpacity={pageCount == pageNumber ? '.2' : '.8'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
    </div>
  );
};
export default ClientReportsGrid;
