


import React from "react";
import styles from '../styles/loginStyles';
function StarNotSelected(props) {
    const classes = styles();
    return (
        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M28.3876 9.82L19.9176 9.085L16.6101 1.2975C16.0151 -0.12 13.9851 -0.12 13.3901 1.2975L10.0826 9.1025L1.63007 9.82C0.0900663 9.9425 -0.539934 11.8675 0.632566 12.8825L7.05507 18.4475L5.13007 26.7075C4.78007 28.2125 6.40757 29.4025 7.73757 28.5975L15.0001 24.2225L22.2626 28.615C23.5926 29.42 25.2201 28.23 24.8701 26.725L22.9451 18.4475L29.3676 12.8825C30.5401 11.8675 29.9276 9.9425 28.3876 9.82ZM15.0001 20.95L8.42006 24.9225L10.1701 17.4325L4.36007 12.3925L12.0251 11.7275L15.0001 4.675L17.9926 11.745L25.6576 12.41L19.8476 17.45L21.5976 24.94L15.0001 20.95Z" fill="#CBCDCB"/>
</svg>

    );
}

export default StarNotSelected;
