import React from "react";

function MapStyleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <g filter="url(#filter0_d_8186_19120)">
        <rect width="32" height="32" x="8" y="5" fill="#fff" rx="5"></rect>
      </g>
      <path
        fill="#1A1A1A"
        d="M24 11c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm-8 10c0-.61.08-1.21.21-1.78L20.99 24v1c0 1.1.9 2 2 2v1.93C19.06 28.43 16 25.07 16 21zm13.89 5.4c-.26-.81-1-1.4-1.9-1.4h-1v-3c0-.55-.45-1-1-1h-6v-2h2c.55 0 1-.45 1-1v-2h2c1.1 0 2-.9 2-2v-.41C29.92 14.77 32 17.65 32 21c0 2.08-.81 3.98-2.11 5.4z"
      ></path>
      <defs>
        <filter
          id="filter0_d_8186_19120"
          width="48"
          height="48"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="3"></feOffset>
          <feGaussianBlur stdDeviation="4"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8186_19120"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_8186_19120"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}
export default MapStyleIcon;