import React, { useMemo } from 'react';
import { BsQuestionSquareFill } from 'react-icons/bs';
import { Grid } from '@material-ui/core';
import { snowLink } from '../utils/constants';
const RaiseRequest = ({ isDrawerOpen }) => {
  const linkStyle = useMemo(
    () => ({
      width: 55,
      height: 55,
      marginLeft: 7,
      marginBottom: 7,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    []
  );
  return (
    <a
      href={snowLink}
      target='_blank'
      rel='noreferrer'
      className='help-link drawer-link'
      style={!isDrawerOpen ? linkStyle : { ...linkStyle, width: 185 }}
    >
      {!isDrawerOpen ? (
        <BsQuestionSquareFill
          style={{
            width: 20,
            height: 20,
            color: '#003F2D',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      ) : (
        <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <BsQuestionSquareFill
            style={{
              width: 20,
              height: 20,
              color: '#003F2D',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
          <p style={{ marginLeft: 7 }}>Create a support ticket</p>
        </Grid>
      )}
    </a>
  );
};

export default RaiseRequest;
