import React, { useState, useMemo, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import RightDrawer from '../components/right_drawer';
import CancelIcon from '../icons/close_icon';
import CustomTypography from '../utils/text';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { AgGridReact } from 'ag-grid-react';

const LeaseView = ({ open, setOpen, leaseId, setLoading }) => {
  const [rowData, setRowData] = useState([]);
  const [buildingInfo, setBuildingInfo] = useState({ address: 'NA', postal_code: 'NA' });
  const gridStyle = useMemo(() => ({ width: '100%', fontFamily: 'Calibre-R', marginTop: 30, height: 500 }), []);
  const axios = useAxiosPrivate('lease');

  const columnDefs = useMemo(() => {
    return [
      {
        headerName: 'Floor',
        field: 'floor',
        flex: 1,
        sortable: true,
      },
      {
        headerName: 'Tenant Name',
        field: 'tenant_name',
        flex: 1,
        sortable: true,
      },
      {
        headerName: 'Lease start date',
        field: 'lease_start_date',
        flex: 1,
      },
      {
        headerName: 'Lease end date',
        field: 'lease_end_date',
        flex: 1,
      },
    ];
  }, []);

  useEffect(() => {
    if (leaseId) {
      setLoading(true);
      axios
        .get(`/lease_end_dates_by_property_code/${leaseId}`)
        .then((resp) => {
          setRowData(resp.data.leases);
          const { address, postal_code } = resp.data;
          setBuildingInfo({ address, postal_code });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [leaseId]);

  return (
    <RightDrawer open={open} variant={true ? 'permanent' : 'temporary'} width='45%' hideBackdrop={true}>
      <Grid
        style={{
          margin: 20,
          marginTop: 5,
          marginRight: 5,
          height: '100vh',
        }}
      >
        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <CancelIcon />
          </IconButton>
        </Grid>
        <Grid style={{ display: 'flex', marginTop: '-20px' }}>
          <CustomTypography
            text={buildingInfo.address}
            fontsize='36px'
            fontweight='400'
            fontcolor='#1A1A1A'
            lineheight='40px'
            display='inline-block'
          />
          <CustomTypography
            text={buildingInfo.postal_code}
            fontsize='20px'
            paddingLeft='10px'
            fontweight='400'
            fontcolor='#1A1A1A'
            lineheight='40px'
            marginTop='5px'
            display='inline-block'
          />
        </Grid>
        <Grid style={gridStyle} className='ag-theme-alpine'>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            suppressRowClickSelection={true}
            // domLayout={'autoHeight'}
            suppressDragLeaveHidesColumns={true}
            domLayout='normal'
          />
        </Grid>
      </Grid>
    </RightDrawer>
  );
};

export default LeaseView;
