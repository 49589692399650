import React from "react";

function LastPageIcon({ color = '#1A1A1A',fillOpacity, ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="12"
            fill="none"
            viewBox="0 0 14 12"
            {...props}
        >
            <path
                fill={color}
                fillOpacity={fillOpacity}
                d="M.795 1.41L5.385 6l-4.59 4.59L2.205 12l6-6-6-6-1.41 1.41zM11.205 0h2v12h-2V0z"
            ></path>
        </svg>
    );
}

export default LastPageIcon;
