export default class NumericCellEditor {
  init(params) {
    this.value = params.value;
    this.minValue = params.limit?.min ?? 0;
    this.maxValue = params.limit?.max ?? 0;
    this.source = params.source ?? '';
    this.input = document.createElement('input');
    this.input.style.height = '100%';
    this.input.style.width = '100%';
    this.input.id = 'input';
    this.input.type = 'number';
    this.input.value = this.value;
    this.input.step = params.source === 'FI_' ? 0.01 : 1;
    this.input.min = 0;
    this.input.backspace = false;
    this.input.onkeydown = function (e) {
      if (e.key === '.' && params.source === 'FI_') {
        return true;
      }
      if (params.source && (params.source == 'BI_CAR_PARKING_SPACES' || params.source == 'ESG_CYCLE_SPACES')) {
        if (e.keyCode == 110 || e.keyCode == 190) {
          // prevent decimal values
          return false;
        }
      }
      if (e.keyCode == 8 || e.keyCode == 46) {
        this.input.backspace = true;
      }
      //   this.input.backspace = false;
      if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode == 8)) {
        return false;
      }
    };
    this.input.onpaste = function (e) {
      let value = e.clipboardData.getData('Text');
      if (params.source && (params.source == 'BI_CAR_PARKING_SPACES' || params.source == 'ESG_CYCLE_SPACES')) {
        const digits_only = (string) => [...string].every((c) => '0123456789'.includes(c));
        if (!digits_only(value)) {
          return false;
        }
      }
      if (!isNaN(value)) {
        if (value > -1) {
        } else {
          return false;
        }
      } else {
        return false;
      }
    };
    this.input.addEventListener('input', (event) => {
      if (this.source == 'BI_CAR_PARKING_SPACES' || this.source == 'ESG_CYCLE_SPACES') {
        if (event.target.value >= this.minValue && event.target.value <= this.maxValue) {
          this.value = this.source === 'ESG_CYCLE_SPACES' ? event.target.value : Number(event.target.value);
        } else {
          if (!this.input.backspace && event.target.value !== 0 && event.target.value > this.maxValue) {
            console.log('show error limit');
            params.error();
            this.input.value = 0;
            this.value = 0;
          }
        }
      } else {
        if (event.target.value >= 0) {
          this.value = Number(event.target.value).toFixed(2);
        } else {
        }
      }
    });
  }
  getGui() {
    return this.input;
  }
  getValue() {
    return this.value;
  }
  isCancelBeforeStart() {
    return false;
  }
  isCancelAfterEnd() {
    if (this.maxValue > 0) {
      return this.value > this.maxValue;
    }
    return this.value > 1000;
  }
  afterGuiAttached() {
    this.input.focus();
  }
}
