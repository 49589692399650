const EditIcon = ({ disabled }) => (
  <svg
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3 17.7525V21.5025H6.75L17.81 10.4425L14.06 6.6925L3 17.7525ZM20.71 7.5425C21.1 7.1525 21.1 6.5225 20.71 6.1325L18.37 3.7925C17.98 3.4025 17.35 3.4025 16.96 3.7925L15.13 5.6225L18.88 9.3725L20.71 7.5425Z'
      fill={disabled ? '#1A1A1A' : '#003F2D'}
      fill-opacity={disabled ? 0.46 : 1}
    />
  </svg>
);
export default EditIcon;
