import React, { useMemo, useState } from 'react';
import RightDrawer from './right_drawer';
import InfinateTableComponent from './custom_infinite_table';
import { Grid } from '@material-ui/core';
import CustomTypography from '../utils/text';
import CancelIcon from '../icons/close_icon';
import { useSelector, useDispatch } from 'react-redux';
import { Buffer } from 'buffer';
// import LoadingOverlayComponent from '../components/lodding_component';
import WarningMessage from '../components/warning_snackbar';
import TagSelection from './tagSelection';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const AddPropertyRightDrawer = ({ open, onClose, handleSuccessCallback, dataList, setDataList, isLoading }) => {
  const hkey = useSelector((state) => state.mainReducer.hkey);
  const hwgtkey = useSelector((state) => state.mainReducer.hwgtkey);
  const availableTo = useSelector((state) => state.mainReducer.availableTo);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [selection, updateSelection] = useState(false);
  const [selectedData, updateSelectedData] = useState();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  const containerStyle = useMemo(
    () => ({
      width: window.innerWidth / 1.7 - 132,
      paddingRight: 30,
      paddingTop: 30,
      paddingLeft: 30,
      fontFamily: 'Calibre-R',
      height: window.innerHeight,
    }),
    []
  );

  const handleAddPropertyRequest = (value, tagSelection) => {
    var arrayList = [...dataList];
    var index = arrayList.findIndex((e) => e.id === value.id);
    if (index !== -1) {
      arrayList[index] = { ...arrayList[index], ACTIVE: false };
      setDataList(arrayList);
    }
    if (value.ACTIVE) {
      InsertProperty(value, tagSelection);
    }
  };

  const handleSelectProperty = (value) => {
    updateSelection(true);
    updateSelectedData(value);
  };
  const InsertProperty = (obj, tagSelection) => {
    var tag_value = '';
    if (tagSelection === 'Off-market') {
      tag_value = 'OFF_MARKET';
    } else if (tagSelection === 'Current site') {
      tag_value = 'CURRENT_SITE';
    } else if (tagSelection === 'Under offer') {
      tag_value = 'UNDER_OFFER';
    }
    const reqObj = {
      hkey: hkey,
      propertyCode: obj.PROPERTY_CODE,
    };
    getIndividualProperty(reqObj, tag_value, tagSelection);
  };

  const getIndividualProperty = async (reqObj, tag_value, tagSelection) => {
    try {
      const response = await axiosPrivate.get(
        `/dataview/individual-Property?hkey=${hkey}&propertyCode=${reqObj.propertyCode}`
      );
      if (response.status === 200 && response.data.length > 0) {
        const object = response.data[0];
        const propertyKey = Buffer.from(object.HKEY_HUB_PROPERTY.data);
        var linKey = '';
        if (object.LNK_PROPERTY_YN === 1) {
          const hubLinkProjctKey = Buffer.from(object.LKEY_PROPERTY_PROJECT.data);
          linKey = hubLinkProjctKey.toString('hex').toUpperCase();
        }
        const reqObject = {
          hkey: hkey,
          lnkPropertyYn: object.LNK_PROPERTY_YN,
          propertyKey: propertyKey.toString('hex').toUpperCase(),
          tag_value: tag_value,
          hubLinkProjctKey: linKey,
        };
        addTagProperty(reqObject, object, tagSelection);
      }
    } catch (error) {
      setWarningSnackbar({
        name: 'Error:',
        message: 'Unable to add the property',
        severity: 'error',
      });
    }
  };

  const addTagProperty = async (reqObj, object, tagSelection) => {
    try {
      const response = await axiosPrivate.post(`/dataview/add-property-tag`, reqObj);
      if (response.status === 200 && response.data.length > 0) {
        updatedataView(object, tagSelection);
      }
    } catch (error) {
      console.log('Error while adding the tag to the property...!', error);
    }
  };
  const updatedataView = async (object, tagSelection) => {
    try {
      const date = availableTo ? "'" + availableTo + "'" : null;
      const reqObj = {
        hkey: hkey,
        hwgtkey: hwgtkey,
        date: date,
      };
      const response = await axiosPrivate.put(`/dataview/data-view-merge`, reqObj);
      if (response.status === 200 && response.data.length > 0) {
        updateSelection(false);
        dispatch({ type: 'SET_SEARCH_VALUE_ADD_PROPERTY', payload: '' });
        handleSuccessCallback(object.BUILDING_NAME, tagSelection);
      }
    } catch (error) {
      console.log('Error while updating the data view...!', error);
    }
  };

  return (
    <RightDrawer
      open={open}
      openSecondary={true}
      onClose={onClose}
      variant='temporary'
    >
      <div style={containerStyle}>
        {selection ? (
          <TagSelection
            updateSelection={updateSelection}
            selectedData={selectedData}
            handleAddPropertyRequest={handleAddPropertyRequest}
            onClose={onClose}
          />
        ) : (
          <>
            <Grid container>
              <Grid
                item
                md={6}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <CustomTypography
                  text={`Add properties`}
                  fontsize='36px'
                  fontweight='400'
                  fontcolor='#1A1A1A'
                  lineheight='40px'
                  marginRight='10px'
                />
              </Grid>
              <Grid
                item
                md={6}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <CancelIcon
                  style={{ paddingRight: '30' }}
                  onClick={() => onClose()}
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
                height: '94%',
              }}
            >
              <InfinateTableComponent
                data={dataList}
                handleAddProperty={handleSelectProperty}
                isLoading={isLoading}
              />
            </Grid>
          </>
        )}

        {/* {!dataList.length && isLoading && (
          <LoadingOverlayComponent open={isLoading} marginLeft={'700px'} />
        )} */}
        {warningSnackbar && (
          <WarningMessage
            open={true}
            setOpen={() => setWarningSnackbar(null)}
            severity={warningSnackbar.severity}
            name={warningSnackbar.name}
            message={warningSnackbar.message}
          />
        )}
      </div>
    </RightDrawer>
  );
};
export default AddPropertyRightDrawer;
