const TopRanked = ({ color }) => {
  const colors = { gold: '#F9AD0E', silver: '#96969A', bronze: '#cd7f32' };
  return (
    <svg width='14' height='19' viewBox='0 0 14 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.81536 10.6635L6.7487 9.19687L8.6737 10.6635L7.94036 8.28854L9.8737 6.75521H7.50703L6.7487 4.41354L5.99036 6.75521H3.6237L5.5487 8.28854L4.81536 10.6635ZM13.4154 7.58854C13.4154 3.90521 10.432 0.921875 6.7487 0.921875C3.06536 0.921875 0.0820312 3.90521 0.0820312 7.58854C0.0820312 9.28021 0.715365 10.8135 1.7487 11.9885V18.4219L6.7487 16.7552L11.7487 18.4219V11.9885C12.782 10.8135 13.4154 9.28021 13.4154 7.58854ZM6.7487 2.58854C9.50703 2.58854 11.7487 4.83021 11.7487 7.58854C11.7487 10.3469 9.50703 12.5885 6.7487 12.5885C3.99036 12.5885 1.7487 10.3469 1.7487 7.58854C1.7487 4.83021 3.99036 2.58854 6.7487 2.58854Z'
        fill={colors[color]}
      />
    </svg>
  );
};

export default TopRanked;
