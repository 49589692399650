import React, { useState } from 'react';
import { Card, Grid } from '@mui/material';
import ContextMenu from './ContextMenu';
import CustomTypography from '../utils/text';
import moment from 'moment';
import { MdOutlinePeopleOutline } from 'react-icons/md';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.24)',
  },
}));

const CardComponent = ({ item, handleOnClickMenu, handleOnclickProject }) => {
  const [enableOptions, updateOptions] = useState(false);
  const dispatch = useDispatch();
  const onItemMenuSelected = (name) => {
    handleOnClickMenu(name, item);
  };
  const handleOnCickProjectCard = () => {
    handleOnclickProject();
  };
  const onClickContextMenuItem = (name, event) => {
    event.stopPropagation();
    onItemMenuSelected(name);
  };

  let options = [];
  switch (item.STATUS) {
    case 'Completed':
      options = [
        { id: 1, name: 'Share' },
        { id: 2, name: 'Duplicate' },
        { id: 3, name: 'Rename' },
        // { id: 4, name: "Complete" },
        // { id: 5, name: 'Archive' },
        { id: 6, name: 'Delete' },
      ];
      break;
    case 'Archived':
      options = [
        { id: 1, name: 'Share' },
        { id: 2, name: 'Duplicate' },
        { id: 3, name: 'Rename' },
        { id: 4, name: 'Complete' },
        { id: 5, name: 'Unarchive' },
        { id: 6, name: 'Delete' },
      ];
      break;
    default:
      options = [
        { id: 1, name: 'Share' },
        { id: 2, name: 'Duplicate' },
        { id: 3, name: 'Rename' },
        { id: 4, name: 'Complete' },
        { id: 5, name: 'Archive' },
        { id: 6, name: 'Delete' },
      ];
      break;
  }

  return (
    <Card
      sx={{
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
        '&:hover': { boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.24)' },
      }}
      style={{
        minHeight: 260,
        borderRadius: 10,
        maxHeight: 260,
        cursor: 'pointer',
      }}
      onClick={() => {
        !enableOptions && handleOnCickProjectCard();
        dispatch({ type: 'SET_CLEAR_SCORING_OBJECT' });
      }}
    >
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          padding: 20,
          height: 185,
        }}
      >
        <Grid
          item
          md={11}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <HtmlTooltip
            title={item?.PROJECT_NAME?.length > 23 ? item.PROJECT_NAME : ''}
          >
            <Grid>
              <CustomTypography
                text={`${
                  item.PROJECT_NAME.length > 23
                    ? item.PROJECT_NAME.substring(0, 23) + '...'
                    : item.PROJECT_NAME
                }`}
                fontsize='30px'
                fontweight='400'
                lineheight='30px'
                fontcolor={item.STATUS == 'Archived' ? '#767676' : '#1A1A1A'}
              />
            </Grid>
          </HtmlTooltip>

          <Grid style={{ display: 'flex', flexDirection: 'row' }}>
            <CustomTypography
              text={`Shortlisted properties: `}
              fontsize='20px'
              fontweight='400'
              lineheight='30px'
              fontcolor={item.STATUS == 'Archived' ? '#767676' : '#1A1A1A'}
            />
            <CustomTypography
              text={`${item.SHORTLISTED}`}
              fontsize='20px'
              fontweight='600'
              lineheight='30px'
              fontcolor={item.STATUS == 'Archived' ? '#767676' : '#1A1A1A'}
              marginLeft={5}
            />
          </Grid>
        </Grid>
        <Grid
          item
          md={1}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
          }}
        >
          <ContextMenu
            menuItems={options}
            showLines={true}
            updateOptions={updateOptions}
            callBack={(name, event) => onClickContextMenuItem(name, event)}
          />
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          bottom: 0,
          flexDirection: 'row',
          padding: '20px',
        }}
      >
        <Grid
          item
          md={8}
          style={{ display: 'flex', justifyContent: 'flex-start' }}
        >
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            {item.USER_COUNT > 1 && (
              <Grid
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'absolute',
                  bottom: '30px',
                  left: 0,
                }}
              >
                <MdOutlinePeopleOutline
                  style={{ width: '20px', height: '20px', marginRight: '5px' }}
                />
                <CustomTypography
                  text={`${item.USER_COUNT - 1}  collaborator${
                    item.USER_COUNT - 1 > 1 ? 's' : ''
                  }`}
                  fontsize='14px'
                  fontweight='600'
                  lineheight='20px'
                  fontcolor={item.STATUS == 'Archived' ? '#767676' : '#1A1A1A'}
                />
              </Grid>
            )}
            <Grid style={{ display: 'flex', flexDirection: 'row' }}>
              <CustomTypography
                text={`Last updated:`}
                fontsize='14px'
                fontweight='400'
                lineheight='40px'
                fontcolor={item.STATUS == 'Archived' ? '#767676' : '#1A1A1A'}
              />
              <CustomTypography
                text={`${moment(item.LAST_UPDATE?.substring(0, 11)).format(
                  'DD.MM.yyyy'
                )}`}
                fontsize='14px'
                fontweight='600'
                lineheight='40px'
                fontcolor={item.STATUS == 'Archived' ? '#767676' : '#1A1A1A'}
                marginLeft={5}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={4}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor:
              item.STATUS == 'Completed'
                ? '#C7E6F0'
                : item.STATUS == 'Archived'
                ? '#E6ECEA'
                : '#D0ECDC',
            borderRadius: 100,
            height: 32,
            paddingBottom: 4,
          }}
        >
          <CustomTypography
            text={item.STATUS}
            fontfamily='Calibre-M'
            fontsize='12px'
            fontweight='400'
            lineheight='16px'
            // fontcolor={item.STATUS == "Closed" ? "#767676" : "#1A1A1A"}
            fontcolor={'#1A1A1A'}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
export default CardComponent;
