import { Button, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../img/background.jpg';
import CbreIcon from '../icons/cbre_icon';
import CustomTypography from '../utils/text';
import { useDispatch } from 'react-redux';
import ErrorModal from '../components/error_componet';
import ScoringCriteriaObject from '../utils/criteria_selection.json';
import { Buffer } from 'buffer';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { useIsAuthenticated } from '@azure/msal-react';
import jwt_decode from 'jwt-decode';
import LoadingOverlayComponent from '../components/lodding_component';
import WarningMessage from '../components/warning_snackbar';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { snowLink } from '../utils/constants';
const useStyles = makeStyles({
  btn: {
    '&:hover': {
      color: 'white',
      background: '#003F2D',
      border: '1px solid #0000001a',
    },
    background: '#003F2D',
    color: 'white',
  },
  btn_text: {
    '&:hover': {
      color: 'red',
    },
  },
});
const Login = () => {
  const { instance, accounts } = useMsal();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [noUserError, setNoUserError] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [isLodding, setIsLodding] = useState(false);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    dispatch({ type: 'SET_SELECTION_OBJECT', payload: ScoringCriteriaObject });
    dispatch({
      type: 'USER_SET_SELECTION_OBJECT',
      payload: ScoringCriteriaObject,
    });
    dispatch({ type: 'CLEAR_DATA' });
  }, []);

  const handleInserUser = async (email, token) => {
    var fullname = email.split('@')[0].split('.');
    var firstName = fullname[0];
    var lastName = fullname[1];
    try {
      const reqObj = {
        firstName: firstName,
        lastName: lastName,
        email: email,
      };

      const response = await axiosPrivate.post(`/login/user`, reqObj, {
        headers: { Authorization: token },
      });

      if (response.status === 200) {
        getHubWorkspace(email, token);
      }
    } catch (error) {
      setIsLodding(false);
      setWarningSnackbar({
        name: 'User Info:',
        message: 'Unable to insert new user/workspace details',
        severity: 'error',
      });
    }
  };

  const getHubWorkspace = async (email, token) => {
    try {
      setIsLodding(false);
      const response = await axiosPrivate.get(`/login/hubworkspace?email=${email}`, {
        headers: { Authorization: token },
      });

      if (response.status === 200) {
        const hub_workspace = Buffer.from(response.data[0].HKEY_HUB_WORKSPACE.data);
        dispatch({
          type: 'SET_HUB_WORKSPACE',
          payload: hub_workspace.toString('hex').toUpperCase(),
        });
        const hub_user = Buffer.from(response.data[0].HKEY_HUB_USER.data);
        dispatch({
          type: 'SET_HUB_USER',
          payload: hub_user.toString('hex').toUpperCase(),
        });
        dispatch({ type: 'SET_USER_EMAIL', payload: email });
        navigate('/dashboard');
      }
    } catch (error) {
      console.log('error.........', error);
      setErrorMessage('Specified user credential is incorrect');
      setWarningSnackbar({
        name: 'User Info:',
        message: 'Unable to get user/workspace details',
        severity: 'error',
      });
    }
  };

  const handleSubmitSSO = async () => {
    setErrorMessage('');
    await instance.loginRedirect(loginRequest).catch((err) => {
      console.error('Redirection Error: Failed to redirect to login page.', { err });
    });
  };
  useEffect(() => {
    console.log('isAuthenticated==>', isAuthenticated);
    if (isAuthenticated) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((responseData) => {
          const token = responseData.accessToken;
          const decoded = jwt_decode(token);
          if (decoded?.unique_name) {
            setIsLodding(true);
            dispatch({ type: 'SET_USER_EMAIL', payload: decoded?.unique_name });
            dispatch({
              type: 'SET_OAUTH_TOKEN',
              payload: responseData.accessToken,
            });
            fetchUserCount(decoded?.unique_name, responseData.accessToken);
          }
        });
    }
  }, [isAuthenticated]);

  const fetchUserCount = async (userName, token) => {
    try {
      const response = await axiosPrivate.get(`/login/usercount?email=${userName}`, {
        headers: { Authorization: token },
      });

      if (response.status === 200) {
        if (response.data[0]['COUNT(1)'] === 0) {
          handleInserUser(userName, token);
        } else {
          getHubWorkspace(userName, token);
        }
      }
    } catch (error) {
      setIsLodding(false);
      console.log('error.........', error);
      setErrorMessage('Specified user credential is incorrect');
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        overFlow: 'hidden',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Grid container>
        <Grid
          item
          xs={9}
          md={9}
          sm={7}
          lg={9}
          xl={9}
        >
          <img
            className='tile-image'
            src={backgroundImage}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              minHeight: '100%',
              verticalAlign: 'middle',
            }}
            alt='logo'
          />
        </Grid>
        <Grid
          item
          xs={3}
          md={3}
          sm={5}
          lg={3}
          xl={3}
          style={{ backgroundColor: 'white' }}
        >
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100vh',
              padding: 32,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <CbreIcon />
              <CustomTypography
                text='Sign in'
                fontfamily='Calibre-R'
                fontsize='24px'
                fontweight='500'
                lineheight='32px'
                fontcolor='#000000'
                marginTop='40px'
              />
              <Grid
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CustomTypography
                  text='Need any help? '
                  fontsize='16px'
                  fontweight='400'
                  lineheight='20px'
                  fontcolor='#000000'
                  marginTop='6px'
                />
                <a
                  href={snowLink}
                  target='_blank'
                  rel='noreferrer'
                  className='help-link'
                  style={{ display: 'flex', alignItems: 'center', padding: '10px' }}
                >
                  Create a support ticket
                </a>
              </Grid>
              {errorMessage && (
                <ErrorModal
                  errorMessage={errorMessage}
                  handleClose={() => {
                    setNoUserError(false);
                    setErrorMessage('');
                  }}
                />
              )}
              <Grid
                style={{
                  borderTop: '1px solid #959595',
                  marginTop: 32,
                  marginBottom: 32,
                }}
              ></Grid>
              <Button
                size='large'
                type='submit'
                className={classes.btn}
                variant='contained'
                style={{ fontWeight: 700, fontSize: 18, height: 40 }}
                onClick={handleSubmitSSO}
              >
                <CustomTypography
                  className={classes.btn_text}
                  text='Login with SSO'
                  fontsize='16px'
                  fontweight='500'
                  lineheight='16px'
                  fontcolor='#FFFFFF'
                  textundelineOffset='0.2 em'
                />
              </Button>
              {/* <Grid style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                <CustomTypography
                  text="Forgot your password?"
                  fontsize="16px"
                  fontweight="400"
                  lineheight="20px"
                  fontcolor="#003F2D"
                  marginTop='16px'
                  textDecoration='underline'
                />
              </Grid> */}
            </div>
          </Grid>
        </Grid>
      </Grid>
      {isLodding && <LoadingOverlayComponent open={isLodding} />}
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
    </div>
  );
};
export default Login;
