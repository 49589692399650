import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import LineImage from '../img/line.png';
const TimeLineMedianChart = ({ data = [], tab }) => {
  const [addresLine, setAddresLine] = useState([]);
  const [scoreList, setScoreList] = useState([]);
  useEffect(() => {
    if (data) {
      var xLabel = [];
      var medianData = [];
      data.forEach((obj, index) => {
        xLabel.push(obj?.labelName);
        if (index == 0) {
          medianData.push(Math.round(obj?.MedianScore));
        } else {
          obj['MedianScore'] = obj?.MedianScore || medianData[index - 1];
          medianData.push(
            Math.round(obj?.MedianScore) || medianData[index - 1]
          );
        }
      });
      setAddresLine(xLabel);
      var stackBarChartScoring = [
        {
          data: medianData,
          color: '#17E88F',
          name: 'Median score',
        },
      ];
      setScoreList(stackBarChartScoring);
    }
  }, [data]);
  const obj = {
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },
      stroke: {
        width: [2],
        curve: 'straight',
      },
      labels: addresLine,
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      grid: {
        show: true,
        position: 'back',
        strokeDashArray: 2,
        borderColor: 'rgba(203, 209, 211, 1)',
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 20,
        },
      },
      tooltip: {
        followCursor: true,
        shared: false,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let obj = data[dataPointIndex];
          return `<style>
                    .root{ 
                       padding-right: 10px;
                       padding-left: 20px;
                       padding-bottom: 10px;
                       background-color: #FFFFFF;
                       width:310px;
                       }
                       .top10container {
                       position: relative;
                       font-family: 'Calibre-R';                                            
                       word-wrap: break-word;
                       padding-top:15px;
                       }
                       .text-block {
                           position: absolute; bottom: 5px;right: 10px;top: 20px;width: 25px;height: 28px;
                           weight: 40px;background-color: #DFE6E5;padding-left: 7px;padding-right: 7px;
                           border-radius: 10px;text-align: center;
                       }
                       span-trans {
                           color:#1a1a1a99;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;font-style:normal;left: 1457px;right: 210.94px;top: 375px;                                               
                       }
                       span-loc {
                           color:#1a1a1a99;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;font-style:normal;left: 1457px;right: 210.94px;top: 404px;                                              
                       }
                       .b {
                           word-wrap: break-word;
                         }
                       span-build {
                           color:#1a1a1a99;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;font-style:normal;left: 1457px;right: 210.94px;top: 433px;                                                
                       }
                       span-esg {
                           color:#1a1a1a99;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;font-style:normal;left: 1457px;right: 210.94px;top: 462px;                                                
                       }
                       span-del {
                           color:#1a1a1a99;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;font-style:normal;left: 1457px;right: 210.94px;top: 490px;                                                
                       }
                       span-rank {
                           color:#1a1a1a99;font-style:normal;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;left: 1454px;right: 211px;top: 324px;
                       }
                       span-address {
                           color:#000000;font-style:normal;font-weight:400;line-height:24px;font-size:20px;font-family:'Calibre-R';text-align=left;left: 1454px;right: 300px;top: 300px;word-wrap: break-word;
                       }
                       span-right {
                           color:#000000;font-weight:500;font-style: normal;line-height:24px;font-size:12px;font-family:'Calibre-R';float:right;padding-right: 15px;
                       }
                       P {
                           padding : 0;
                           margin : 0;
                           line-height : 22px;
                       }
                    </style>
                    <div class="root">
                       <div class="top10container">
                          <div class="b">
                              <span-address>
                                ${obj?.tooltipLabelName}&nbsp;&nbsp;&nbsp;
                              </span-address>
                          </div>
                          <div class="text-block">
                          <span style="font-size:20px;font-family:'Calibre-R';text-align:center;font-style:normal;font-weight:400;height: 24px;left: 1674px; right: 206px;top: 308px;">
                              ${Math.round(obj?.MedianScore) || ''}
                              </span>
                          </div>
                       </div>
                       <p>
                           <img src=${LineImage} style="width: 300px;height: 1px;left: 1454px;top: 362px;"/>
                       </p>
                       <p>
                           <span-trans>Transport</span-trans>
                           <span-right>${
                             Math.round(obj.Transport) || 0
                           }</span-right>
                       </p>
                      
                       <p> 
                           <span-build>Building</span-left>  
                           <span-right>${
                             Math.round(obj.Building) || 0
                           }</span-right>
                       </p>
                       <p> 
                       <span-loc>Location & Amenity</span-left>  
                       <span-right>${
                         Math.round(obj['Location & Amenity']) || 0
                       }</span-right>
                   </p>
                       <p>
                           <span-esg>ESG</span-left>
                           <span-right>${Math.round(obj.ESG) || 0}</span-right>
                       </p>
                       <p> 
                           <span-del>Deliverability & Risk</span-left>                
                           <span-right>${
                             Math.round(obj['Deliverability & Risk']) || 0
                           }</span-right>
                       </p>
                   </div>`;
        },
      },
    },
  };
  return (
    <ReactApexChart
      options={obj.options}
      series={scoreList}
      type='line'
      height={tab ? 350 : 375}
    />
  );
};
export default TimeLineMedianChart;
