import { createContext, useState } from 'react';
const ChartsContext = createContext({});

export const ChartsProvider = ({ children }) => {
  const [weightings, setWeightings] = useState();
  const [custWeightings, setCustWeightings] = useState();

  return (
    <ChartsContext.Provider value={{ charts: { weightings, setWeightings, custWeightings, setCustWeightings } }}>
      {children}
    </ChartsContext.Provider>
  );
};

export default ChartsContext;
