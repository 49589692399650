import React, { useEffect, useState } from 'react';
import { Dialog, Button, Grid } from '@material-ui/core';
import CloseIcon from '../icons/close_icon';
import CustomTypography from '../utils/text';
import CustomTextField from '../components/custom_textfield';
import DatePicker from '../components/date_picker';
import moment from 'moment';
import AutoComplite from './autocomplite_textfeild';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../styles/index';
import Typography from '@mui/material/Typography';
import HubsAutoComplete from '../components/postalcode_autoComplite';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const SearchCriteriaModal = ({ open = false, handleClose, handleSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SerachObjectData = useSelector((state) => state.mainReducer.serachObject);
  const [marketValues, setMarketsValue] = useState(SerachObjectData.location ?? []);
  const [submarket, setSubmarket] = useState(SerachObjectData.subMarket ?? []);
  const [district, setDistrict] = useState(SerachObjectData.district ?? []);
  const [neighbourhood, setNeighbourhood] = useState(SerachObjectData.neighbourhood ?? []);
  const [smin, setSmin] = useState(SerachObjectData.smin ?? '');
  const [smax, setSmax] = useState(SerachObjectData.smax ?? '');
  const [min, setMin] = useState(SerachObjectData.min ?? '');
  const [max, setMax] = useState(SerachObjectData.max ?? '');
  const [fromDate, setFromDate] = useState(SerachObjectData.fromDate ?? '');
  const [toDate, setTodate] = useState(SerachObjectData.toDate ?? '');
  const [error, setError] = useState(false);
  const locationData = useSelector((state) => state.mainReducer.locationData);
  const [marketDropdown, setMarketDropdown] = useState([]);
  const [subMarketDropdown, setSubMarketDropdown] = useState([]);
  const [districtDropdown, setDistrictDropdown] = useState([]);
  const [neighbourhoodDropdown, setneighbourhoodDropdown] = useState([]);
  const [transportHubs, setTransportHubs] = useState([]);
  const [transportHubsValue, setTransportHubsValue] = useState(SerachObjectData.transportHubs ?? []);
  const [flag, setFlag] = useState(true);
  const [rentError, setRentError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [isInvalidFromDate, setIsInvalidFromDate] = useState(false);
  const [isInvalidToDate, setIsInvalidToDate] = useState(false);
  const validNumber = /^[0-9]*$/;
  const [neighborhoodFlag, setNeighborhoodFlag] = useState(true);
  const [districtFlag, setDistrictFlag] = useState(true);
  const [subMarketFlag, setSubMarketFlag] = useState(true);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    if (SerachObjectData) {
      setMarketsValue(SerachObjectData.location);
      setSubmarket(SerachObjectData.subMarket);
      setDistrict(SerachObjectData.district);
      setNeighbourhood(SerachObjectData.neighbourhood);
      setMin(SerachObjectData.min);
      setMax(SerachObjectData.max);
      setSmax(SerachObjectData.smax);
      setSmin(SerachObjectData.smin);
      setFromDate(SerachObjectData.fromDate);
      setTodate(SerachObjectData.toDate);
      setTransportHubsValue(SerachObjectData.transportHubs);
    }
  }, [SerachObjectData]);
  useEffect(() => {
    if (locationData.length > 0) {
      var marketsArray = locationData.filter((a, i) => locationData.findIndex((s) => a.MARKET === s.MARKET) === i);
      var marketsData = [];
      marketsArray?.map((e, index) => {
        if (e.MARKET != null) {
          marketsData.push({ id: e.MARKET, title: e.MARKET });
        }
      });
      var sortArray = sortingArray(marketsData);
      if (sortArray.length > 0) {
        sortArray.unshift({ id: 0, title: 'All' });
      }
      setMarketDropdown(sortArray);
    }
  }, [locationData]);

  useEffect(() => {
    getTransportHubNames();

    async function getTransportHubNames() {
      try {
        const response = await axiosPrivate.get(`/advance-filter/transport-hubs`);

        if (response.status === 200) {
          const hubs = response.data.map((el) => ({
            id: el.NAME,
            title: el.NAME,
          }));
          const sorted = sortingArray(hubs);

          setTransportHubs(sorted);
        }
      } catch (error) {
        console.log('error while fetching Transport hubs.........', error);
      }
    }
  }, []);

  const sortingArray = (array) => {
    const strAscending = [...array].sort((a, b) => (a.title > b.title ? 1 : -1));
    return strAscending;
  };

  useEffect(() => {
    const markets = marketValues.map((el) => el.title);
    let selectedLocationData = locationData;
    if (markets.length > 0) {
      selectedLocationData = locationData.filter((el) => markets.includes(el.MARKET));
      setSubMarketFlag(false);
    }
    const distinctSubmarkets = selectedLocationData
      .filter((a, i) => selectedLocationData.findIndex((s) => a.SUBMARKET === s.SUBMARKET) === i)
      .filter((el) => el.SUBMARKET);
    const slctdMarkets = distinctSubmarkets.filter((el) => markets.includes(el.MARKET)).map((elm) => elm.SUBMARKET);
    const subMarkets = distinctSubmarkets.map((elm) => ({
      id: elm.SUBMARKET,
      title: elm.SUBMARKET,
    }));
    const sorted = sortingArray(subMarkets);
    if (sorted.length > 0) {
      sorted.unshift({ id: 0, title: 'All' });
    }
    setSubmarket((prev) => {
      return prev?.filter((el) => slctdMarkets?.includes(el.title));
    });
    if (!slctdMarkets.length && subMarketFlag && SerachObjectData.subMarket) {
      setSubmarket(SerachObjectData.subMarket);
    }
    setSubMarketDropdown(sorted);
  }, [marketValues, locationData]);

  useEffect(() => {
    const subMarkets = submarket.map((el) => el.title);
    const markets = marketValues.map((el) => el.title);
    let selectedLocationData = locationData;
    if (markets.length > 0) {
      selectedLocationData = locationData.filter((el) => markets.includes(el.MARKET));
    }
    if (subMarkets.length > 0) {
      selectedLocationData = selectedLocationData.filter((el) => subMarkets.includes(el.SUBMARKET));
      setDistrictFlag(false);
    }
    const distinctDistricts = selectedLocationData
      .filter((a, i) => selectedLocationData.findIndex((s) => a.DISTRICT === s.DISTRICT) === i)
      .filter((el) => el.DISTRICT);

    const slctdSubmarkets = distinctDistricts
      .filter((el) => subMarkets.includes(el.SUBMARKET))
      .map((elm) => elm.DISTRICT);

    const districts = distinctDistricts.map((elm) => ({
      id: elm.DISTRICT,
      title: elm.DISTRICT,
    }));

    const sorted = sortingArray(districts);
    if (sorted.length > 0) {
      sorted.unshift({ id: 0, title: 'All' });
    }
    setDistrict((prev) => {
      return prev?.filter((el) => slctdSubmarkets?.includes(el.title));
    });
    if (!slctdSubmarkets.length && districtFlag && SerachObjectData.district) {
      setDistrict(SerachObjectData.district);
    }
    setDistrictDropdown(sorted);
  }, [submarket, marketValues, locationData]);

  useEffect(() => {
    const districts = district.map((el) => el.title);
    const subMarkets = submarket.map((el) => el.title);
    const markets = marketValues.map((el) => el.title);
    let selectedLocationData = locationData;
    if (markets.length > 0) {
      selectedLocationData = locationData.filter((el) => markets.includes(el.MARKET));
    }
    if (subMarkets.length > 0) {
      selectedLocationData = selectedLocationData.filter((el) => subMarkets.includes(el.SUBMARKET));
    }
    if (districts.length > 0) {
      selectedLocationData = selectedLocationData.filter((el) => districts.includes(el.DISTRICT));
      setNeighborhoodFlag(false);
    }
    const distinctNeighbourhood = selectedLocationData
      .filter((a, i) => selectedLocationData.findIndex((s) => a.NEIGHBOURHOOD === s.NEIGHBOURHOOD) === i)
      .filter((el) => el.NEIGHBOURHOOD);

    const slctdDistricts = distinctNeighbourhood
      .filter((el) => districts.includes(el.DISTRICT))
      .map((elm) => elm.NEIGHBOURHOOD);

    const neighborhood = distinctNeighbourhood.map((elm) => ({
      id: elm.NEIGHBOURHOOD,
      title: elm.NEIGHBOURHOOD,
    }));

    const sorted = sortingArray(neighborhood);
    if (sorted.length > 0) {
      sorted.unshift({ id: 0, title: 'All' });
    }
    setNeighbourhood((prev) => {
      return prev?.filter((el) => slctdDistricts?.includes(el.title));
    });
    if (!slctdDistricts.length && neighborhoodFlag && SerachObjectData.neighbourhood) {
      setNeighbourhood(SerachObjectData.neighbourhood);
    }
    setneighbourhoodDropdown(sorted);
  }, [district, submarket, marketValues, locationData]);

  const handleSubmitResponse = () => {
    var obj = {
      location: marketValues,
      subMarket: submarket,
      district: district,
      neighbourhood: neighbourhood,
      min: min,
      max: max,
      smin: smin,
      smax: smax,
      fromDate: fromDate,
      toDate: toDate,
      transportHubs: transportHubsValue,
    };
    dispatch({ type: 'SET_SEARCH_OBJECT', payload: obj });
    handleSubmit();
    setError('');
  };
  const handleFromDateCallback = (fromDateValue) => {
    if (fromDateValue) {
      if (fromDateValue['$d'] == 'Invalid Date' || moment(moment().format('MM/DD/YYYY')) > fromDateValue) {
        setIsInvalidFromDate(true);
      } else {
        setIsInvalidFromDate(false);
      }
    } else {
      setIsInvalidFromDate(false);
    }
    setFromDate(fromDateValue);
  };

  const handleToDateCallback = (toDateValue) => {
    if (toDateValue) {
      if (
        toDateValue['$d'] == 'Invalid Date' ||
        moment(moment().format('MM/DD/YYYY')) > toDateValue ||
        (fromDate != '' && fromDate > toDateValue && toDateValue != '')
      ) {
        setIsInvalidToDate(true);
      } else {
        setIsInvalidToDate(false);
      }
    } else setIsInvalidToDate(false);

    setTodate(toDateValue);
  };

  const handleColseClick = () => {
    handleClose(false);
    setIsInvalidFromDate(false);
    setIsInvalidToDate(false);
  };
  const handleOnBlurMin = (value) => {
    setError('');
    checkRent(value, max, true);
    var num = parseInt(value);
    var cleanNum = num;
    if (!isNaN(cleanNum)) {
      setMin(cleanNum);
    }
  };
  const handleOnBlurMax = (value) => {
    setError('');
    checkRent(min, value, true);
    var num = parseInt(value);
    var cleanNum = num;
    if (!isNaN(cleanNum)) {
      setMax(cleanNum);
    }
  };
  const checkRent = (minValue, maxValue, isRent) => {
    if (!isNaN(maxValue) && maxValue > -1) {
      if (!isNaN(minValue) && minValue > -1) {
        if (Math.round(parseInt(maxValue) * 100) < Math.round(parseInt(minValue) * 100)) {
          if (isRent) {
            setRentError(true);
          } else {
            setSizeError(true);
          }
        } else {
          if (isRent) {
            setRentError(false);
          } else {
            setSizeError(false);
          }
        }
      }
    }
  };
  const handleOnBlurSMin = (value) => {
    setError('');
    checkRent(value, smax, false);
    var num = parseInt(value);
    var cleanNum = num;
    if (!isNaN(cleanNum)) {
      setSmin(cleanNum);
    }
  };
  const handleOnBlurSMax = (value) => {
    setError('');
    checkRent(smin, value, false);
    var num = parseInt(value);
    var cleanNum = num;
    if (!isNaN(cleanNum)) {
      setSmax(cleanNum);
    }
  };
  const IsValueInvalid = (value) => {
    return value == '' || value == undefined || (!isNaN(value) && value > -1);
  };
  useEffect(() => {
    if (
      marketValues ||
      submarket ||
      district ||
      neighbourhood ||
      min ||
      max ||
      smin ||
      smax ||
      fromDate ||
      toDate ||
      transportHubsValue
    ) {
      let cacheData = JSON.parse(localStorage.getItem('cache_data'));
      var cache_data_obj = {
        ...cacheData,
        search_obj: {
          location: marketValues,
          subMarket: submarket,
          district: district,
          neighbourhood: neighbourhood,
          min: min,
          max: max,
          smin: smin,
          smax: smax,
          fromDate: fromDate,
          toDate: toDate,
          transportHubs: transportHubsValue,
        },
      };
      localStorage.setItem('cache_data', JSON.stringify(cache_data_obj));
    }
  }, [marketValues, submarket, district, neighbourhood, min, max, smin, smax, fromDate, toDate, transportHubsValue]);
  return (
    <Dialog
      open={open}
      classes={{ paper: classes.paper }}
      PaperProps={{
        sx: {
          width: 624,
          maxHeight: 893,
        },
      }}
    >
      <div
        style={{
          flexDirection: 'column',
          display: 'flex',
          width: 624,
          height: 893,
          borderRadius: 10,
          paddingTop: 30,
          paddingRight: 30,
          paddingBottom: 10,
          paddingLeft: 30,
          overflow: 'hidden',
        }}
      >
        <div style={{ height: '100%', flexDirection: 'column' }}>
          <div style={{ display: 'flex', height: '90%', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: 10,
                alignItems: 'center',
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  md={10}
                >
                  <CustomTypography
                    text='Enter search criteria'
                    fontfamily='Calibre-SB'
                    fontsize='24px'
                    fontweight='500'
                    lineheight='32px'
                    fontcolor='#1F1E1E'
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={2}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={handleColseClick}
                >
                  <CloseIcon />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ paddingBottom: 32 }}
                >
                  <Typography
                    variant='body2'
                    style={{
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      color: '#1a1a1a99',
                      fontsize: 14,
                      fontFamily: 'Calibre-R',
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    Narrowing down your search criteria can help you drive your client conversations and also improve
                    app performance.
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                overflow: 'auto',
                justifyContent: 'flex-start',
                width: 644,
              }}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  paddingRight: 15,
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ paddingBottom: 25 }}
                >
                  <Grid
                    container
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                      }}
                    >
                      <CustomTypography
                        text='Select location'
                        fontfamily='Calibre-SB'
                        fontsize='14px'
                        fontweight='500'
                        lineheight='16px'
                        fontcolor='#1A1A1A'
                        marginBottm={12}
                      />
                      <AutoComplite
                        key={'MARKET'}
                        title={'Market' + '\xa0'}
                        inputPlaceholder={'Add locations by market'}
                        data={marketDropdown}
                        limitTags={3}
                        selectedValue={marketValues}
                        handleChange={(e) => setMarketsValue(e)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ paddingBottom: 25 }}
                >
                  <Grid
                    container
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                      }}
                    >
                      <AutoComplite
                        key={'SUBMARKET'}
                        title={'Submarket' + '\xa0'}
                        limitTags={3}
                        inputPlaceholder={'Add locations by submarket'}
                        data={subMarketDropdown}
                        selectedValue={submarket}
                        handleChange={(e) => {
                          if (e.length === 0) {
                            setNeighbourhood([]);
                            setDistrict([]);
                          }
                          setSubmarket(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ paddingBottom: 25 }}
                >
                  <Grid
                    container
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                      }}
                    >
                      <AutoComplite
                        key={'district'}
                        title={'District' + '\xa0'}
                        limitTags={3}
                        inputPlaceholder={'Add locations by district'}
                        data={districtDropdown}
                        selectedValue={district}
                        handleChange={(e) => {
                          if (e.length === 0) {
                            setNeighbourhood([]);
                          }
                          setDistrict(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ paddingBottom: 25 }}
                >
                  <Grid
                    container
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                      }}
                    >
                      <AutoComplite
                        key={'neighborhood'}
                        title={'Neighbourhood' + '\xa0'}
                        limitTags={3}
                        inputPlaceholder={'Add locations by neighbourhood'}
                        data={neighbourhoodDropdown}
                        selectedValue={neighbourhood}
                        handleChange={(e) => setNeighbourhood(e)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ paddingBottom: 25 }}
                >
                  <Grid
                    container
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                    >
                      <HubsAutoComplete
                        title={'Preferred transport hubs\xa0\xa0\xa0'}
                        inputPlaceholder={'Add preferred tube or train stations'}
                        data={transportHubs}
                        selectedValue={transportHubsValue}
                        isSelected={true}
                        flag={flag}
                        setFlag={setFlag}
                        handleChange={(e) => setTransportHubsValue(e)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ paddingBottom: 10 }}
                >
                  <CustomTypography
                    text='Select rent'
                    fontfamily='Calibre-SB'
                    fontsize='14px'
                    fontweight='500'
                    lineheight='16px'
                    fontcolor='#1A1A1A'
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ paddingBottom: 25 }}
                >
                  <Grid
                    container
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <Grid
                      item
                      xs={6}
                      md={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        paddingRight: 14,
                        flexDirection: 'column',
                      }}
                    >
                      <CustomTextField
                        id='Minimum Rent'
                        label={'Minimum Rent' + '\xa0'}
                        type='string'
                        fullWidth
                        inputPlaceholder={'Type in rent per sq.ft.'}
                        value={min}
                        onChange={(event) => {
                          if (validNumber.test(event.target.value)) {
                            let a = event.target.value ? parseInt(event.target.value) : '';
                            setMin(a);
                            setError('');
                          } else {
                            setError('minRent');
                          }
                        }}
                        onBlur={handleOnBlurMin}
                        variant='outlined'
                        error={IsValueInvalid(min) && !rentError ? false : true}
                      />
                      {(!IsValueInvalid(min) || rentError || error === 'minRent') && (
                        <CustomTypography
                          text={rentError ? 'Minimum rent must be less than Maximum rent' : 'Invalid value'}
                          fontfamily='Calibre-R'
                          fontsize='11px'
                          fontweight='400'
                          lineheight='16px'
                          fontcolor='#FF543E'
                          marginLeft={12}
                          marginTop={12}
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        paddingLeft: 14,
                        flexDirection: 'column',
                      }}
                    >
                      <CustomTextField
                        id='max'
                        label={'Maximum Rent' + '\xa0'}
                        type='string'
                        fullWidth
                        inputPlaceholder={'Type in rent per sq.ft.'}
                        value={max}
                        onChange={(event) => {
                          if (validNumber.test(event.target.value)) {
                            let a = event.target.value ? parseInt(event.target.value) : '';
                            setMax(a);
                            setError('');
                          } else {
                            setError('maxRent');
                          }
                        }}
                        onBlur={handleOnBlurMax}
                        variant='outlined'
                        error={IsValueInvalid(max) && !rentError ? false : true}
                      />
                      {(!IsValueInvalid(max) || rentError || error === 'maxRent') && (
                        <CustomTypography
                          text={rentError ? 'Minimum rent must be less than Maximum rent' : 'Invalid value'}
                          fontfamily='Calibre-R'
                          fontsize='11px'
                          fontweight='400'
                          lineheight='16px'
                          fontcolor='#FF543E'
                          marginLeft={12}
                          marginTop={12}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                >
                  <CustomTypography
                    text='Select size'
                    fontfamily='Calibre-SB'
                    fontsize='14px'
                    fontweight='500'
                    lineheight='16px'
                    fontcolor='#1A1A1A'
                    marginBottm={10}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ paddingBottom: 25 }}
                >
                  <Grid
                    container
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <Grid
                      item
                      xs={6}
                      md={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        paddingRight: 14,
                        flexDirection: 'column',
                      }}
                    >
                      <CustomTextField
                        id='smin'
                        label={'Minimum size' + '\xa0'}
                        type='string'
                        fullWidth
                        inputPlaceholder={'Type in size in sq.ft.'}
                        value={smin}
                        onChange={(event) => {
                          const val = event.target.value;
                          if (validNumber.test(val)) {
                            let a = val ? parseInt(val) : '';
                            setSmin(a);
                            checkRent(a, smax, false);
                            setError('');
                          } else {
                            setError('minSize');
                          }
                        }}
                        variant='outlined'
                        onBlur={handleOnBlurSMin}
                        error={IsValueInvalid(smin) && !sizeError ? false : true}
                      />
                      {(!IsValueInvalid(smin) || sizeError || error === 'minSize') && (
                        <CustomTypography
                          text={sizeError ? 'Minimum size must be less than Maximum size' : 'Invalid value'}
                          fontfamily='Calibre-R'
                          fontsize='11px'
                          fontweight='400'
                          lineheight='16px'
                          fontcolor='#FF543E'
                          marginLeft={12}
                          marginTop={12}
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        paddingLeft: 14,
                        flexDirection: 'column',
                      }}
                    >
                      <CustomTextField
                        id='smax'
                        label={'Maximum size' + '\xa0'}
                        type='string'
                        fullWidth
                        inputPlaceholder={'Type in size in sq.ft.'}
                        value={smax}
                        onChange={(event) => {
                          const val = event.target.value;
                          if (validNumber.test(val)) {
                            let a = val ? parseInt(val) : '';

                            setSmax(a);
                            checkRent(smin, a, false);
                            setError('');
                          } else {
                            setError('maxSize');
                          }
                        }}
                        onBlur={handleOnBlurSMax}
                        variant='outlined'
                        error={IsValueInvalid(smax) && !sizeError ? false : true}
                      />
                      {(!IsValueInvalid(smax) || sizeError || error === 'maxSize') && (
                        <CustomTypography
                          text={sizeError ? 'Minimum size must be less than Maximum size' : 'Invalid value'}
                          fontfamily='Calibre-R'
                          fontsize='11px'
                          fontweight='400'
                          lineheight='16px'
                          fontcolor='#FF543E'
                          marginLeft={12}
                          marginTop={12}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ paddingBottom: 25 }}
                >
                  <Grid
                    container
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <Grid
                      item
                      xs={6}
                      md={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        paddingRight: 14,
                        flexDirection: 'column',
                      }}
                    >
                      <CustomTypography
                        text='Select delivery timeline'
                        fontfamily='Calibre-SB'
                        fontsize='14px'
                        fontweight='500'
                        lineheight='16px'
                        fontcolor='#1A1A1A'
                        marginBottm={10}
                      />
                      <DatePicker
                        label={'Available from' + '\xa0'}
                        width={'100%'}
                        value={fromDate}
                        height={54}
                        border={false}
                        callback={handleFromDateCallback}
                      />
                      {isInvalidFromDate && (
                        <CustomTypography
                          text='Invalid date'
                          fontfamily='Calibre-R'
                          fontsize='11px'
                          fontweight='400'
                          lineheight='16px'
                          fontcolor='#FF543E'
                          marginLeft={12}
                          marginTop={12}
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        paddingLeft: 14,
                        flexDirection: 'column',
                      }}
                    >
                      <CustomTypography
                        text='-'
                        fontfamily='Calibre-SB'
                        fontsize='14px'
                        fontweight='500'
                        lineheight='16px'
                        fontcolor='#fff'
                        marginBottm={10}
                      />
                      <DatePicker
                        width={'100%'}
                        label={'To' + '\xa0'}
                        value={toDate}
                        height={54}
                        minDate={fromDate}
                        border={false}
                        callback={handleToDateCallback}
                      />
                      {isInvalidToDate && (
                        <CustomTypography
                          text={
                            fromDate != '' && fromDate > toDate && toDate != ''
                              ? 'To date must be greater than from date'
                              : 'Invalid date'
                          }
                          fontfamily='Calibre-R'
                          fontsize='11px'
                          fontweight='400'
                          lineheight='16px'
                          fontcolor='#FF543E'
                          marginLeft={12}
                          marginTop={12}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              bottom: 30,
              width: '100%',
              height: '10%',
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexDirection: 'row',
                }}
              >
                <Button
                  className={
                    !IsValueInvalid(min) ||
                    !IsValueInvalid(max) ||
                    !IsValueInvalid(smin) ||
                    !IsValueInvalid(smax) ||
                    rentError ||
                    sizeError ||
                    isInvalidFromDate ||
                    isInvalidToDate ||
                    (fromDate != '' && fromDate > toDate && toDate != '')
                      ? classes.disablebtnClass
                      : classes.btn
                  }
                  onClick={handleSubmitResponse}
                  disabled={
                    !IsValueInvalid(min) ||
                    !IsValueInvalid(max) ||
                    !IsValueInvalid(smin) ||
                    !IsValueInvalid(smax) ||
                    rentError ||
                    sizeError ||
                    isInvalidFromDate ||
                    isInvalidToDate ||
                    (fromDate != '' && fromDate > toDate && toDate != '')
                  }
                >
                  <CustomTypography
                    text='Continue'
                    fontfamily='Calibre-SB'
                    fontsize='16px'
                    lineheight='16px'
                    fontweight='500'
                    fontcolor={
                      !IsValueInvalid(min) ||
                      !IsValueInvalid(max) ||
                      !IsValueInvalid(smin) ||
                      !IsValueInvalid(smax) ||
                      rentError ||
                      sizeError ||
                      isInvalidFromDate ||
                      isInvalidToDate ||
                      (fromDate != '' && fromDate > toDate && toDate != '')
                        ? '#7f8080'
                        : 'white'
                    }
                  />
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default SearchCriteriaModal;
