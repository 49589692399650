import React, { useState, useEffect, useMemo } from 'react';
import { Autocomplete, TextField, Box, Stack } from '@mui/material';
import { debounce } from 'lodash';
import Searchicon from '../icons/search_icon';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import WarningMessage from '../components/warning_snackbar';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import CustomTypography from '../utils/text';
import axios from 'axios';
import dataProvision from '../utils/data_provision';

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    '&:-webkit-autofill': {
      backgroundColor: '#FFFFFF !important;',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1a1a1a',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#205747',
      },
      '& input::placeholder': {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: 21,
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      },
      '& input': {
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        fontSize: '16px !important',
        fontWeight: 400,
        lineHeight: 21,
      },
    },
  },
  notchedOutline: {
    '&.MuiOutlinedInput-root.Mui-error': {
      borderColor: '#AB1212',
    },
  },
  inputPlaceholderColor: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        color: '#1a1a1a',
      },
      '&:hover fieldset': {
        color: '#205747',
      },
      '&.Mui-focused fieldset': {
        color: '#205747',
      },
    },
  },
}));

const useStyles = makeStyles({
  option: {
    '&:hover': {
      backgroundColor: '#E9F1F0 !important',
    },
    fontFamily: 'Calibre-R',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
  },
});
const SearchComponent = ({ handlechangeCompanyName, error = false, companyName = '' }) => {
  const [input, setInput] = useState('');
  const styles = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const [searchstring, setSearchstring] = useState('');
  const [filteredList, updateFilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [open, setOpen] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const styles1 = {
    autocomplete: {
      userSelect: 'none',
    },
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(async (str) => {
        try {
          const response = await dataProvision(str);
          console.log('response data==', response);
          if (response.status === 200 && response.data && response.data.length) {
            console.log('response.data', response.data);
            const getLabel = (name) => (name ? name : '-');
            updateFilteredList(
              response.data.map((el) => {
                const label =
                  getLabel(el.name) +
                  '|' +
                  getLabel(el.parentAccount) +
                  '|' +
                  getLabel(el.topParentAccount) +
                  '|' +
                  getLabel(el.country) +
                  '|' +
                  getLabel(el.city);
                return { label, value: el.name, code: el.customerId };
              })
            );
          }
        } catch (error) {
          console.log('error while loading comapny name search', error);
          setWarningSnackbar({
            name: 'Company Info:',
            message: 'Unable to fetch Company name',
            severity: 'error',
          });
        } finally {
          setLoading(false);
        }
      }, 500),
    []
  );

  useEffect(() => {
    if (searchstring.length < 3) return;
    setLoading(true);
    debouncedSearch(searchstring);
  }, [searchstring, debouncedSearch]);

  useEffect(() => {
    setInput(companyName);
  }, [companyName]);
  const handleInput = (e) => {
    setSearchstring(e.target.value);
  };

  return (
    <>
      <Autocomplete
        id='virtualize-demo'
        options={filteredList}
        getOptionLabel={(option) => {
          if (typeof option === 'string') return option;
          return option?.value ? option.value : '';
        }}
        noOptionsText={
          loading ? (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  paddingTop: '90px',
                  paddingBottom: '90px',
                  fontSize: '14px',
                  lineHeight: '21px',
                  fontWeight: 400,
                  fontFamily: 'Calibre-R',
                }}
              >
                <div style={{ paddingBottom: '10px' }}>
                  <CircularProgress style={{ color: '#003F2D' }} size={36} />
                </div>
                <div>Loading company names... </div>
              </div>
            </>
          ) : searchstring.length > 2 ? (
            'No company found'
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  paddingTop: '90px',
                  paddingBottom: '90px',
                  fontSize: '14px',
                  lineHeight: '21px',
                  fontWeight: 400,
                  fontFamily: 'Calibre-R',
                }}
              >
                <div>Search for companies </div>
              </div>
            </>
          )
        }
        classes={{
          option: styles.option,
        }}
        onOpen={() => setOpen(true)}
        onChange={(event, newValue) => {
          if (event.type === 'click') {
            handlechangeCompanyName(newValue);
          }
        }}
        value={input}
        popupIcon={null}
        clearIcon={null}
        sx={{
          '& .MuiAutocomplete-popupIndicator': {
            transform: 'none',
            paddingTop: 0.75,
          },
          '& .MuiAutocomplete-clearIndicator': {
            paddingTop: 0.75,
          },
        }}
        onClose={() => {
          setSearchstring('');
          setOpen(false);
        }}
        onBlur={() => setSearchstring('')}
        ListboxProps={{ style: { Height: 150 } }}
        renderOption={(props, option, { selected = false }) => {
          const companyName = option.label.split('|');
          return (
            <Box
              component='li'
              {...props}
              style={{
                color: '#1A1A1A',
                padding: '10px 16px',
              }}
            >
              <Stack direction='column' spacing={0.5}>
                <CustomTypography
                  text={companyName[0]}
                  fontfamily='Calibre-R'
                  fontsize='14px'
                  lineheight='20px'
                  fontweight='500'
                  fontcolor={'#1A1A1A'}
                  variant='body1'
                />
                <CustomTypography
                  text={companyName.slice(1).join(' | ')}
                  fontfamily='Calibre-R'
                  fontsize='12px'
                  lineheight='20px'
                  fontweight='500'
                  fontcolor={'#7f8080'}
                  variant='body2'
                />
              </Stack>
            </Box>
          );
        }}
        renderInput={(params) => (
          <div>
            <TextField
              error={error}
              style={{
                borderRadius: 4,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
              }}
              {...params}
              label={'Company name*' + '\xa0'}
              variant='outlined'
              className={outlinedInputClasses.root}
              onSelect={handleInput}
              InputLabelProps={{
                shrink: true,
                style: {
                  color: error ? '#FF543E' : '#1A1A1A',
                  fontSize: '20px',
                  marginTop: '-4px',
                  paddingRight: '16px',
                  lineHeight: '24px',
                  fontFamily: 'Calibre-R',
                },
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: open && <Searchicon />,
                endAdornment: !open && <Searchicon />,
              }}
              placeholder={'Search by keyword '}
            />
          </div>
        )}
        style={styles1.autocomplete}
      />
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
    </>
  );
};

export default SearchComponent;
