const Trophy = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.5833 1.83854H11.9167V0.171875H3.58333V1.83854H1.91667C1 1.83854 0.25 2.58854 0.25 3.50521V4.33854C0.25 6.46354 1.85 8.19687 3.90833 8.45521C4.43333 9.70521 5.55833 10.6469 6.91667 10.9219V13.5052H3.58333V15.1719H11.9167V13.5052H8.58333V10.9219C9.94167 10.6469 11.0667 9.70521 11.5917 8.45521C13.65 8.19687 15.25 6.46354 15.25 4.33854V3.50521C15.25 2.58854 14.5 1.83854 13.5833 1.83854ZM1.91667 4.33854V3.50521H3.58333V6.68854C2.61667 6.33854 1.91667 5.42188 1.91667 4.33854ZM7.75 9.33854C6.375 9.33854 5.25 8.21354 5.25 6.83854V1.83854H10.25V6.83854C10.25 8.21354 9.125 9.33854 7.75 9.33854ZM13.5833 4.33854C13.5833 5.42188 12.8833 6.33854 11.9167 6.68854V3.50521H13.5833V4.33854Z'
      fill='#F59506'
    />
  </svg>
);

export default Trophy;
