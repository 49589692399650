import React, { useMemo } from 'react';
import CurrencyFormatter from './currency_formatter';

export default (props) => {
  const data = useMemo(() => {
    return props.api.getDisplayedRowAtIndex(props.rowIndex).data;
  }, []);
  console.log('data.....', data);
  return (
    <div>
      {data.OCCUPIER ? (
        <div
          style={{
            backgroundColor: props.color || 'white',
            padding: '10px 20px',
            background: '#FFFFFF',
            borderWidth: 1,
            boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.24)',
            borderRadius: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: '10px',
              width: '280px',
              borderBottom: '1px solid rgba(0, 63, 45, 0.1)',
            }}
          >
            <div
              style={{
                color: 'rgba(26, 26, 26, 1);',
                fontSize: '20px',
                fontWeight: '400',
                lineHeight: '24px',
                fontFamily: 'Calibre-R',
              }}
            >
              {data.FLOOR_NUMBER}
            </div>
            <div
              style={{
                color: 'rgba(26, 26, 26, 1);',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '24px',
                fontFamily: 'Calibre-R',
              }}
            >
              {CurrencyFormatter(data.AVAILABLE_SPACE_SQFT ?? '')} sq.ft
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '10px',
              width: '280px',
            }}
          >
            <div
              style={{
                color: 'rgba(26, 26, 26, 0.6)',
                fontSize: '12px',
                lineHeight: '24px',
                fontFamily: 'Calibre-R',
                width: '100px',
                fontWeight: '400',
              }}
            >
              Lease expiry
            </div>
            <div
              style={{
                color: 'rgba(26, 26, 26, 1)',
                fontSize: '12px',
                lineHeight: '24px',
                fontFamily: 'Calibre-R',
                fontWeight: '500',
              }}
            >
              {data.LEASE_EXPIRY_DATE}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '10px',
              width: '280px',
            }}
          >
            <div
              style={{
                color: 'rgba(26, 26, 26, 0.6)',
                fontSize: '12px',
                lineHeight: '24px',
                fontFamily: 'Calibre-R',
                width: '100px',
                fontWeight: '400',
              }}
            >
              Landlord
            </div>
            <div
              style={{
                color: 'rgba(26, 26, 26, 1)',
                fontSize: '12px',
                lineHeight: '24px',
                fontFamily: 'Calibre-R',
                fontWeight: '500',
              }}
            >
              {data?.LANDLORD}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '10px',
              width: '280px',
            }}
          >
            <div
              style={{
                color: 'rgba(26, 26, 26, 0.6)',
                fontSize: '12px',
                lineHeight: '24px',
                fontFamily: 'Calibre-R',
                width: '100px',
                fontWeight: '400',
              }}
            >
              Occupier
            </div>
            <div
              style={{
                color: 'rgba(26, 26, 26, 1)',
                fontSize: '12px',
                lineHeight: '24px',
                fontFamily: 'Calibre-R',
                fontWeight: '500',
              }}
            >
              {data.OCCUPIER}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
