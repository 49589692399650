import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import CustomTypography from '../utils/text';
const CustomCheckbox = ({
  label,
  checkedColor = '#003f2d',
  unCheckColor = '#767676',
  marginLeft,
  marginRight = 0,
  checked,
  isCharts = false,
  ...props
}) => {
  return (
    <FormControlLabel
      style={{ marginLeft: marginLeft, marginRight: marginRight }}
      control={
        <Checkbox
          {...props}
          checked={checked}
          checkedIcon={<MdCheckBox color={props.disabled ? unCheckColor : checkedColor} />}
          icon={<MdCheckBoxOutlineBlank color={unCheckColor} />}
        />
      }
      label={
        !label?.includes('REGIONAL') ? (
          <CustomTypography
            text={label}
            fontsize='16px'
            fontweight='400'
            lineheight='24px'
            fontcolor={isCharts ? '#1A1A1A' : checked ? '#363636' : '#1a1a1a99'}
          />
        ) : (
          label
            ?.split(' ')
            .map((el) => (
              <CustomTypography
                text={el + ' '}
                fontsize='16px'
                fontweight={el === 'REGIONAL' ? 'bold' : '400'}
                lineheight='24px'
                fontcolor={isCharts ? '#1A1A1A' : checked ? '#363636' : '#1a1a1a99'}
                display='inline'
              />
            ))
        )
      }
    />
  );
};

export default CustomCheckbox;
