import React, { useEffect, useState } from 'react';
import ImgsViewer from 'react-images-viewer';

const ImageViewer = ({ open, onClick, indexValue, imgsData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    setCurrentIndex(indexValue);
  }, [indexValue]);
  const onClickPrvious = () => {
    setCurrentIndex(currentIndex - 1);
  };
  const onClickNext = () => {
    setCurrentIndex(currentIndex + 1);
  };
  const handleClose = () => {
    setCurrentIndex(0);
    onClick();
  };
  return (
    <ImgsViewer
      imgs={imgsData}
      isOpen={open}
      onClose={handleClose}
      spinnerDisabled={false}
      disableScroll={false}
      closeOnClickOutside={true}
      currImg={currentIndex}
      onClickNext={onClickNext}
      onClickPrev={onClickPrvious}
    />
  );
};
export default ImageViewer;
