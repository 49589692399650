import React from "react";

function MapFullScreenIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="none"
            viewBox="0 0 48 48"
        >
            <g filter="url(#filter0_d_445_5461)">
                <rect width="32" height="32" x="8" y="5" fill="#fff" rx="5"></rect>
            </g>
            <path
                fill="#1A1A1A"
                d="M18 23c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1h-2v-2c0-.55-.45-1-1-1zm0-4c.55 0 1-.45 1-1v-2h2c.55 0 1-.45 1-1s-.45-1-1-1h-3c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1zm11 7h-2c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1s-1 .45-1 1v2zm-3-11c0 .55.45 1 1 1h2v2c0 .55.45 1 1 1s1-.45 1-1v-3c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1z"
            ></path>
            <defs>
                <filter
                    id="filter0_d_445_5461"
                    width="48"
                    height="48"
                    x="0"
                    y="0"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="3"></feOffset>
                    <feGaussianBlur stdDeviation="4"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_445_5461"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_445_5461"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
    );
}

export default MapFullScreenIcon;
