import React from "react";

function Calculator() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="none" viewBox="0 0 20 18">
      <path
        fill="#fff"
        d="M17.063 0h-15c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm0 2v3h-15V2h15zm-5 14h-5V7h5v9zm-10-9h3v9h-3V7zm12 9V7h3v9h-3z"
      ></path>
    </svg>
  );
}

export default Calculator;
