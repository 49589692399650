import React, { useState, useEffect, useMemo } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Grid } from '@material-ui/core';
import CircleIcon from '../icons/circle_icon';
import CustomTypography from '../utils/text';
import TooltipMt, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { labels } from '../utils/constants';
ChartJS.register(ArcElement, Tooltip, Legend);
const DoughnutComponet = ({ data, subCategory = false }) => {
  const [dataObj, setDataObj] = useState({});
  const [circleData, setCircleData] = useState();
  useEffect(() => {
    var labels = [];
    var dataset = [];
    var circleArray = [];
    var backgroundColor = !subCategory
      ? {
          Transport: '#DBD99A',
          Building: '#3E7CA6',
          'Location & Amenity': '#A388BF',
          ESG: '#80BBAD',
          'Deliverability & Risk': '#885073',
          'Custom Criteria': '#96B0BF',
        }
      : [
          '#093682',
          '#3565A6',
          '#4C99A9',
          '#7BB7BD',
          '#BFE1C9',
          '#DFDAA7',
          '#E8C0B4',
          '#D3A8BD',
          '#DA919E',
          '#E17E6A',
          '#D85960',
          '#B466A8',
          '#A83478',
          '#9563B5',
          '#8F44AA',
          '#8146BC',
          '#8071DA',
          '#6354BA',
          '#3646DA',
          '#14460a',
        ];
    var colorArray = [];
    var total = 0;
    data?.map((obj, index) => {
      if (!subCategory) {
        labels.push(obj.header);
        var object = {
          name: obj.header,
          color: backgroundColor[obj.header],
        };
        colorArray.push(backgroundColor[obj.header]);
        circleArray.push(object);
      } else {
        labels.push(obj.item);
        var object = {
          name: obj.item,
          color: backgroundColor[index],
        };
        colorArray.push(backgroundColor[index]);
        circleArray.push(object);
      }
      total = total + obj.score;
      dataset.push(obj.score || 0);
    });

    labels.push('Available points');
    dataset.push(100 - total);
    colorArray.push('#E8F3EC');
    var object = {
      name: 'Available points',
      color: '#E8F3EC',
    };
    circleArray.push(object);
    var datasets = [
      {
        data: dataset,
        backgroundColor: colorArray,
        hoverBackgroundColor: colorArray,
        borderWidth: 1,
      },
    ];
    var dataObject = {
      labels: labels,
      maintainAspectRatio: false,
      responsive: false,
      datasets: datasets,
    };
    setDataObj(dataObject);
    setCircleData(circleArray);
  }, [data]);
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    tooltips: {
      callbacks: {
        label: function (context, data) {
          let index = context?.index;
          let label = '';
          if (index > -1) {
            label += data.labels[index];
            let val = data.datasets[0].data[index];
            val = parseInt(val);
            label += ': ' + val;
          }
          return label;
        },
      },
    },
    // animations: {
    //   startAngle: {
    //     from: Math.PI * 2,
    //   },
    //   endAngle: {
    //     from: Math.PI * 2,
    //   },
    // },
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <TooltipMt {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontFamily: 'Calibre-R',
      fontSize: '14px',
      fontWeight: 400,
      maxWidth: 350,
    },
  }));

  return (
    <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
      <Grid item xs={2} md={2}></Grid>
      <Grid item xs={5} md={5}>
        {dataObj && dataObj?.datasets?.length > 0 && (
          <Doughnut data={dataObj} options={options} />
        )}
      </Grid>
      <Grid item xs={2} md={1}></Grid>
      <Grid
        item
        xs={3}
        md={4}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <div style={{ maxHeight: '450px', paddingRight: 50, overflow: 'auto' }}>
          {circleData &&
            circleData?.map((itemObj, ind) => {
              return (
                <Grid
                  key={ind}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 8,
                    alignItems: 'center',
                  }}
                >
                  <Grid>
                    <CircleIcon color={itemObj.color} />
                  </Grid>
                  <Grid>
                    {labels.includes(itemObj.name) ? (
                      <CustomTypography
                        text={itemObj.name}
                        fontsize='14px'
                        fontweight='400'
                        lineheight='21px'
                        fontcolor={'#00000'}
                        marginLeft={8}
                      />
                    ) : (
                      <span
                        style={{
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '21px',
                          fontFamily: 'Calibre-R',
                        }}
                      >
                        <LightTooltip
                          enterNextDelay={100}
                          enterDelay={100}
                          title={itemObj.name}
                          placement='top'
                          followCursor
                          style={{
                            margin: 0,
                            marginLeft: 8,
                          }}
                        >
                          <span>
                            {itemObj.name.length > 25
                              ? itemObj.name.slice(0, 24) + '...'
                              : itemObj.name}
                          </span>
                        </LightTooltip>
                      </span>
                    )}
                  </Grid>
                </Grid>
              );
            })}
        </div>
      </Grid>
    </Grid>
  );
};
export default DoughnutComponet;
