import * as React from 'react';
import Menu from '@mui/material/Menu';
import ChartsIcon from '../icons/charts_icon';
import { Grid, Button } from '@material-ui/core';
import CustomTypography from '../utils/text';
import CustomCheckbox from './custom_checkbox';
export default function SimpleListMenu({ handleCallback, options }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSelecetMenu = (index, value) => {
        var menuList = [...options];
        menuList[index] = { ...menuList[index], checked: !value }
        handleCallback(menuList);
    }
    return (
        <div style={{ width: 150, }}>
            <Button
                onClick={handleClickListItem}
                style={{
                    width: 150,
                    height: 36,
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #003F2D',
                    marginRight: 20,
                }}
            >
                <ChartsIcon />
                <CustomTypography
                    text='Charts'
                    fontfamily='Calibre-R'
                    fontsize='16px'
                    lineheight='16px'
                    fontweight='500'
                    fontcolor='#003F2D'
                    marginLeft={13}
                />
            </Button>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                <Grid style={{
                    width: 350, height: 285,
                    display: 'flex', flexDirection: 'column',
                    paddingTop: 8, paddingLeft: 30,
                    paddingBottom: 20, overflow: 'none'
                }}>
                    <Grid style={{ height: 40 }}>
                        <CustomTypography
                            text='Charts'
                            fontfamily='Calibre-R'
                            fontsize='24px'
                            lineheight='40px'
                            fontweight='400'
                            fontcolor='#1A1A1A'
                        />
                    </Grid>
                    <Grid style={{ height: 245, display: 'flex', overflowX: 'auto', flexDirection: 'column' }}>
                        {options.map((option, index) => (
                            <CustomCheckbox
                                checkedColor={'#003f2d'}
                                unCheckColor={'#DADADA'}
                                key={option.name}
                                label={option.name}
                                checked={option.checked}
                                isCharts={true}
                                onChange={() => handleSelecetMenu(index, option.checked)}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Menu>
        </div>
    );
}