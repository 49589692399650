import { makeStyles } from '@material-ui/core';
const textfeildstyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    '&:-webkit-autofill': {
      backgroundColor: '#FFFFFF !important;',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1a1a1a',
      },
      '&:hover fieldset': {
        borderColor: '#205747',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#205747',
      },
    },
  },
  focused: {},
  notchedOutline: {
    '&.MuiOutlinedInput-root.Mui-error': {
      borderColor: '#AB1212',
    },
  },
  inputPlaceholderColor: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        color: '#1a1a1a',
      },
      '&:hover fieldset': {
        color: '#205747',
      },
      '&.Mui-focused fieldset': {
        color: '#205747',
      },
    },
  },
}));

export default textfeildstyles;
