import { TextField, Grid, Slider } from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import CustomTypography from '../utils/text';
import LeftVectorIcon from '../icons/scoring_vector_icon';
import useOutlinedInputStyles from '../styles/textfeild_style';
import CheckedIcon from '../icons/grencheck_icon';
import DisableCheckedIcon from '../icons/disablecheck_icon';
import Lottie from 'lottie-react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { labels } from '../utils/constants';
import * as animationData from '../utils/fancy-tick.json';

const SliderComponent = ({
  name = 'demo',
  onChangeValue,
  data,
  onClickCategory,
  subCategory = false,
  score = 0,
  paddingTop,
  paddingRight = 0,
  projectStatus = '',
  isMainCategory = false,
  rowData = [],
}) => {
  const outlinedInputClasses = useOutlinedInputStyles();
  const [value, setValue] = useState(0);
  const lotRef = useRef();
  useEffect(() => {
    setValue(score);
  }, [score]);
  const handleSliderChange = (event, newValue) => {
    if (newValue === '') {
      setValue('');
      onChangeValue(0, name);
    } else {
      if (Number(newValue) <= scoringData()) {
        setValue(Number(newValue));
        onChangeValue(Number(newValue), name);
      }
    }
  };
  const handleInputChange = (event) => {
    if (event.target.value === '') {
      setValue('');
      onChangeValue(0, name);
    } else {
      if (Number(event.target.value) <= scoringData() && Number(event.target.value) > -1) {
        setValue(Number(event.target.value));
        onChangeValue(Number(event.target.value), name);
      }
    }
  };
  const scoringData = () => {
    var filterData = [];
    if (!subCategory) {
      filterData = data.filter((e) => e.header != name);
    } else {
      filterData = data.filter((e) => e.item != name);
    }
    let total = filterData.reduce(function (prev, current) {
      return prev + +current.score;
    }, 0);
    return 100 - total;
  };
  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (scoringData() >= value) {
      setValue(value);
    }
  };
  const handleOnclickVector = () => {
    if (!subCategory) {
      onClickCategory(name);
    }
  };
  const isFilledAll = React.useMemo(() => {
    var returnValue = true;
    if (value != 0) {
      var selectedArray = rowData.filter((e) => e.value == true);
      var totalscore = 0;
      selectedArray?.forEach((obj) => {
        if (obj.score == 0) {
          returnValue = false;
        }
        totalscore = totalscore + obj.score;
      });
      if (totalscore != 100) {
        returnValue = false;
      }
    } else {
      returnValue = false;
    }
    return returnValue;
  }, [JSON.stringify(rowData), value]);
  const returnSubCategoryScore = React.useMemo(() => {
    var selectSubCategoryArray = rowData?.filter((obj) => obj.value == true);
    var enterObjectArray = selectSubCategoryArray?.filter((e) => e.score > 0);
    var returnValue = enterObjectArray?.length + '/' + selectSubCategoryArray?.length;
    return returnValue;
  }, [JSON.stringify(rowData)]);

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontFamily: 'Calibre-R',
        fontSize: '14px',
        fontWeight: 400,
        maxWidth: 350,
      },
    })
  );

  return (
    <Grid
      container
      alignItems='center'
      style={{
        paddingLeft: 32,
        paddingTop: paddingTop,
        paddingRight: paddingRight,
      }}
    >
      <Grid item xs={10} md={10} style={{ display: 'flex', flexDirection: 'column', paddingRight: 5 }}>
        <Grid
          item
          xs
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={handleOnclickVector}
        >
          {isMainCategory ? (
            <>
              <>
                {isFilledAll ? (
                  <Lottie
                    animationData={animationData}
                    lottieRef={lotRef}
                    loop={false}
                    onComplete={() => {}}
                    style={{
                      margin: '-20px',
                      marginLeft: '-18px',
                      width: '58px',
                    }}
                  />
                ) : (
                  <DisableCheckedIcon />
                )}
              </>
              <CustomTypography
                text={returnSubCategoryScore + '\xa0'}
                fontsize='14px'
                fontweight='500'
                fontcolor='#767676'
                lineheight='16px'
                justifycontent='flex-start'
                paddingLeft='10px'
                paddingRight='7px'
              />
            </>
          ) : (
            <>
              {value > 0 ? (
                <Lottie
                  animationData={animationData}
                  loop={false}
                  onComplete={() => {}}
                  style={{
                    margin: '-20px',
                    marginLeft: '-18px',
                    width: '58px',
                  }}
                />
              ) : (
                <DisableCheckedIcon />
              )}
            </>
          )}
          {labels.includes(name) && name !== 'REGIONAL - Proximity to mainline train station' ? (
            <CustomTypography
              text={name}
              fontsize='24px'
              fontweight='400'
              fontcolor='#1A1A1A'
              lineheight='40px'
              justifycontent='center'
              paddingLeft='10px'
              marginBottm={5}
            />
          ) : (
            <span
              style={{
                fontSize: '24px',
                fontWeight: 400,
                lineHeight: '40px',
                fontFamily: 'Calibre-R',
                marginBottom: 5,
              }}
            >
              <LightTooltip
                enterNextDelay={100}
                enterDelay={100}
                title={name}
                placement='top'
                followCursor
                style={{
                  margin: 0,
                  paddingLeft: 10,
                }}
              >
                <span>{name.length > 31 ? name.slice(0, 31) + '...' : name}</span>
              </LightTooltip>
            </span>
          )}
          {!subCategory && <LeftVectorIcon style={{ marginLeft: 10, cursor: 'pointer' }} />}
        </Grid>
        <Slider
          disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
          value={value ? Number(value) : 0}
          onChange={handleSliderChange}
          aria-labelledby='input-slider'
        />
      </Grid>
      <Grid
        item
        xs={2}
        md={2}
        style={{
          marginTop: 40,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TextField
          disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
          style={{ width: 52, margin: 4, paddingLeft: 5 }}
          value={value}
          className={outlinedInputClasses.root}
          size='small'
          variant='outlined'
          onChange={handleInputChange}
          onBlur={handleBlur}
          inputProps={{
            type: 'tel',
            style: {
              paddingLeft:
                paddingRight == 32
                  ? parseInt(value) < 100
                    ? parseInt(value < 10)
                      ? '25px'
                      : '14px'
                    : '6px'
                  : parseInt(value) < 100
                  ? parseInt(value < 10)
                    ? '22px'
                    : '17px'
                  : '11px',
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
export default SliderComponent;
