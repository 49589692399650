const FireIcon = () => (
  <svg width='16' height='21' viewBox='0 0 16 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.48 11.274C13.91 7.19397 8.32 6.97397 9.67 1.04397C9.77 0.603968 9.3 0.263969 8.92 0.493969C5.29 2.63397 2.68 6.92397 4.87 12.544C5.05 13.004 4.51 13.434 4.12 13.134C2.31 11.764 2.12 9.79397 2.28 8.38397C2.34 7.86397 1.66 7.61397 1.37 8.04397C0.69 9.08397 0 10.764 0 13.294C0.38 18.894 5.11 20.614 6.81 20.834C9.24 21.144 11.87 20.694 13.76 18.964C15.84 17.034 16.6 13.954 15.48 11.274ZM6.2 16.304C7.64 15.954 8.38 14.914 8.58 13.994C8.91 12.564 7.62 11.164 8.49 8.90397C8.82 10.774 11.76 11.944 11.76 13.984C11.84 16.514 9.1 18.684 6.2 16.304Z'
      fill='#FFA800'
    />
  </svg>
);

export default FireIcon;
