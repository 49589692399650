const quickSetup = [
  {
    header: 'Transport',
    data: [
      {
        item: 'Proximity to nearest tube station',
        value: true,
        score: 30,
        tooltip_text: 'Measured in km, ranging from under 0.2km (score of 4) to over 0.6km (score of 1)',
      },
      {
        item: 'Proximity to mainline train station',
        value: true,
        score: 15,
        tooltip_text: 'Measured in km, ranging from under 0.5km (score of 4) to over 1.5km (score of 1)',
      },
      {
        item: 'Proximity to Elizabeth Line',
        value: true,
        score: 25,
        tooltip_text:
          'Distance from the newly formed Elizabeth trainline for London and surrounding areas. Measured in kilometres, ranging from under 1km (score of 4) to over 3km (score of 1)',
      },
      {
        item: 'Tube lines within 1km',
        value: true,
        score: 30,
        tooltip_text: 'Number of London underground lines within 1km. Measured from 3+ (score of 4) to 0 (score of 1)',
      },
      {
        item: 'REGIONAL - Proximity to mainline train station',
        value: false,
        score: 0,
        tooltip_text: 'Measured in metres, ranging from <100m (score of 4) to 501m+ (score of 1)',
      },
      {
        item: 'REGIONAL - Proximity to tram station',
        value: false,
        score: 0,
        tooltip_text: 'Measured in metres, ranging from <100m (score of 4) to 501m+ (score of 1)',
      },
    ],
    score: 30,
  },
  {
    header: 'Building',
    data: [
      {
        item: 'On-site Dedicated Gym',
        value: false,
        score: 0,
        tooltip_text:
          'Availability of gym for occupiers to use, or a dedicated space that can be fitted out with a gym.',
      },
      {
        item: 'On-site Catering Available',
        value: false,
        score: 0,
        tooltip_text:
          'Availability of a canteen for occupiers, covering options for cafeteria, coffee / tea / beer, or a restaurant',
      },
      {
        item: 'Terraces',
        value: true,
        score: 5,
        tooltip_text: 'Availability of outdoor balconies or small ground floor outdoor space',
      },
      {
        item: 'Floor plate suitability',
        value: true,
        score: 25,
        tooltip_text:
          'If the required amount of leasable square footage (to accommodate the brief) sits on one floor (score of 4) vs across multiple floors in the building (score of 1)',
      },
      {
        item: 'Look and Feel',
        value: false,
        score: 0,
        tooltip_text: 'On a scale of excellent to average. No formal / objective scale',
      },
      {
        item: 'Floor to ceiling height',
        value: true,
        score: 20,
        tooltip_text: 'Measured in metres, ranging from 3m+ (score of 4) to under 2.5m (score of 1)',
      },
      {
        item: 'Occupational Density',
        value: true,
        score: 20,
        tooltip_text:
          'Measured in a ratio of person to squared metres ranging from 1:8 (score of 4) to 1:12 (score of 1)',
      },
      {
        item: 'Columns',
        value: false,
        score: 0,
        tooltip_text:
          'Presence of columns on the floor (possibly obstructing view / function), ranging from column free (score of 4) to heavy column grid (score of 1)',
      },
      {
        item: 'Natural Light',
        value: false,
        score: 0,
        tooltip_text: 'Availability of natural light, ranging from excellent to average. No formal / objective scale',
      },
      {
        item: 'On-site Amenity',
        value: false,
        score: 0,
        tooltip_text:
          'Availability of amenities, such as coffee shops, restaurants, business lounge. Ranging from excellent to average. ',
      },
      {
        item: 'On-site shower facilities',
        value: false,
        score: 0,
        tooltip_text: 'Availability of shower facilities within the building',
      },
      {
        item: 'Self-contained',
        value: false,
        score: 0,
        tooltip_text:
          'Whether the occupier would have the whole building, or a section of the building that has its own entrance',
      },
      {
        item: 'WiredScore Rating',
        value: true,
        score: 15,
        tooltip_text:
          'A third party global digital connectivity certification. Ranging from platinum (score of 4) to none (score of 1).',
      },
      {
        item: 'Building Technology',
        value: false,
        score: 0,
        tooltip_text: 'Availability of technology such as a building engagement app. Yes / no rating.',
      },
      {
        item: 'Branding Rights',
        value: false,
        score: 0,
        tooltip_text:
          "Permission to add occupier's branding (logo and name) to the building - internally and externally.",
      },
      {
        item: 'Air Conditioning',
        value: true,
        score: 5,
        tooltip_text: 'Availability of air conditioning.',
      },
      {
        item: 'Raised Floors',
        value: true,
        score: 5,
        tooltip_text:
          'Whether the floor is raised above the solid floor slab, leaving an open void between the two, to be used to cables etc. ',
      },
      {
        item: 'Car Park Spaces',
        value: false,
        score: 0,
        tooltip_text: 'Number of car park spaces, if any',
      },
      {
        item: 'Lockers',
        value: true,
        score: 5,
        tooltip_text: 'Availability of lockers',
      },
      {
        item: 'Single-let building',
        value: false,
        score: 0,
        tooltip_text: 'A single-let building is where the client is the only tenant in the building.',
      },
    ],
    score: 25,
  },
  {
    header: 'Location & Amenity',
    data: [
      {
        item: 'Proximity to transport hubs',
        value: true,
        score: 100,
        tooltip_text:
          'Distance from your chosen transport hubs (e.g. Oxford Circus station).If you choose more than one hub (e.g. Oxford Circus and Bond Street), it will score on distance from the closest hub, i.e., it will not try and find a mid point between them.Ranked from top or closest 25% (4 points) to bottom or furthest 25% (1 point)',
      },
      {
        item: 'Level of local amenity',
        value: false,
        score: 0,
        tooltip_text:
          'Availability and quality of amenities in the local area e.g. shops, restaurants, gyms. Range from excellent to average',
      },
      {
        item: 'Green Spaces',
        value: false,
        score: 0,
        tooltip_text:
          'Proximity to and quality of green spaces (e.g. parks) in the local area. Range from excellent to average.',
      },
      {
        item: 'Personal safety',
        value: false,
        score: 0,
        tooltip_text:
          "Rating of area's safety. Factors include local crime, street lighting. Range from excellent to average. ",
      },
      {
        item: 'Proximity to Clients/Competitors',
        value: false,
        score: 0,
        tooltip_text:
          "Distance from key clients or competitors of occupier's interest. Range from excellent to average.",
      },
      {
        item: 'Proximity to gyms/hotels',
        value: false,
        score: 0,
        tooltip_text: 'Distance from local gyms and hotels. Range from excellent to average.',
      },
      {
        item: 'Flex options in the immediate area',
        value: false,
        score: 0,
        tooltip_text:
          "Availability of flexible ('pay-as-you-go') office spaces in the immediate area. Range from excellent to average.",
      },
    ],
    score: 10,
  },
  {
    header: 'ESG',
    data: [
      {
        item: 'BREEAM Rating',
        value: true,
        score: 20,
        tooltip_text:
          'Third party, global sustainability rating scheme. Based on building design, construction, and maintenance. Ranges from outstanding (score of 4) to good or lower (score of 1).',
      },
      {
        item: 'Nabers Rating',
        value: true,
        score: 5,
        tooltip_text:
          'Third party, national rating scheme for the energy efficiency of office buildings. Ranged from 6 stars (score of 4) to 2 stars or less (score of 1)',
      },
      {
        item: 'EPC Rating',
        value: true,
        score: 30,
        tooltip_text:
          'Energy Performance Certificate - UK Government rating for a building. Ranges from A to G. At or above a B gets a score of 4, below a B gets a score of 1.',
      },
      {
        item: 'All-Electric Building',
        value: true,
        score: 10,
        tooltip_text:
          'Whether the building is completely powered by electricity, with no need for gas heating etc. Higher chance of being sustainable.',
      },
      {
        item: 'Net Carbon Zero',
        value: true,
        score: 20,
        tooltip_text:
          'Whether the building (in construction or operation) is highly energy efficient and powered from on-site and/or off-site renewable energy sources, with any remaining carbon balance offset. Scored 4 if building is NCQ, 3 if NCZ, and 1 if not Net Carbon Zero.',
      },
      {
        item: 'Naturally Ventilated',
        value: false,
        score: 0,
        tooltip_text:
          'Whether the building is ventilated via fresh air by means of passive forces, typically by wind speed or differences in pressure internally and externally. As opposed to air conditioning only.',
      },
      {
        item: 'WELL Rating',
        value: true,
        score: 10,
        tooltip_text:
          'Third party, international system that measures, certifies and monitors building features that impact on health and wellbeing. Ranges from Platinum (score of 4) to none (score of 1).',
      },
      {
        item: 'Cycle Spaces',
        value: true,
        score: 5,
        tooltip_text:
          'Availability of secure cycle storage. Scores range from more than 1 per 1,000 sqft (score of 4) to less than 1 per 5,000 sqft (score of 2) and score of 1 for no spaces.',
      },
    ],
    score: 25,
  },
  {
    header: 'Deliverability & Risk',
    data: [
      {
        item: 'Delivery status',
        value: true,
        score: 100,
        tooltip_text: 'If building is in construction / refurbishment, how far away it is until target completion date',
      },
      {
        item: 'Landlord Credibility',
        value: false,
        score: 0,
        tooltip_text:
          "View on the landlord's reputation and ability to deliver the proposed scheme as intended on time. Range from excellent to average.",
      },
    ],
    score: 10,
  },
  {
    header: 'Custom Criteria',
    data: [],
    score: 0,
  },
];

export default quickSetup;
