import { useState, useRef, useEffect, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import CustomTypography from '../utils/text';
import ComboBox from './ComboBox';
import CloseIcon from '../icons/close_icon';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { MdOutlineExpandMore, MdOutlineExpandLess, MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import { Buffer } from 'buffer';
import StarSelected from '../icons/star_selected';
import StarNotSelected from '../icons/star_not_selected';
import './CompareProperties.css';
import FallbackImg from '../img/image.png';
import { getFormattedValue } from '../pages/chart/charts';
import LoadingOverlayComponent from '../components/lodding_component';
import CurrencyFormatter from './currency_formatter';
import CurrentIcon from '../icons/current_site';
import OffmarketIcon from '../icons/off_market';
import UnderofferIcon from '../icons/under_offer';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { getCapitalized } from '../utils/utilities';
import ChartsContext from '../Providers/ChartsProvider';
import TopRanked from '../icons/TopRanked';
import Trophy from '../icons/Trophy';
import FireIcon from '../icons/FireIcon';
import DownIcon from '../icons/DownIcon';
import Checkbox from './custom_checkbox';
import TopFinancial from '../icons/TopFinancial';
import { attributes } from '../utils/constants';

const attributeValueScore = { fontSize: 36, letterSpacing: '-0.01em' };
const attributeValue = { fontSize: 32, letterSpacing: '-0.01em' };
const CompareProperties = ({
  open,
  setOpen,
  buildings,
  data = [],
  allowedLabels,
  loading,
  retained,
  retainedLoading,
  setProperties,
}) => {
  const [popupOne, setPopupOne] = useState(false);
  const [popupTwo, setPopupTwo] = useState(false);
  const [popupThree, setPopupThree] = useState(false);
  const [popupFour, setPopupFour] = useState(false);
  const [propertyOne, setPropertyOne] = useState();
  const [propertyTwo, setPropertyTwo] = useState();
  const [propertyThree, setPropertyThree] = useState();
  const [propertyFour, setPropertyFour] = useState();
  const [showHeader, setShowHeader] = useState(true);
  const [quickCompareExp, setQuickCompareExp] = useState(true);
  const [transportExp, setTransportExp] = useState(false);
  const [buildingExp, setBuildingExp] = useState(false);
  const [locationExp, setLocationExp] = useState(false);
  const [esgExp, setEsgExp] = useState(false);
  const [deliverabilityExp, setDeliverabilityExp] = useState(false);
  const [financialsExp, setFinancialsExp] = useState(false);
  const [custCriteriaExp, setCustCriteriaExp] = useState(false);
  const [keyDifferences, setKeyDifferences] = useState(false);
  const [showAttributes, setShowAttributes] = useState({
    ...attributes,
  });
  const hkey = useSelector((state) => state.mainReducer.hkey);
  const scrollRef = useRef(null);
  const axiosPrivate = useAxiosPrivate();
  const {
    charts: { custWeightings },
  } = useContext(ChartsContext);

  const getMaxValues = (list, key) => {
    const sorted = list?.sort((a, b) => b[key] - a[key]);
    const maxVal = sorted && sorted[0] ? sorted[0][key] : 0;
    return sorted.filter((el) => el[key] === maxVal).map((elm) => elm.BUILDING_NAME);
  };

  const highScoreQuick = useMemo(() => {
    const notNullprops = [propertyOne, propertyTwo, propertyThree, propertyFour].filter((el) => el);
    return getMaxValues(notNullprops, 'SCORE');
  }, [propertyOne, propertyTwo, propertyThree, propertyFour]);

  const highBuildingScore = useMemo(() => {
    const notNullprops = [propertyOne, propertyTwo, propertyThree, propertyFour].filter((el) => el);
    return getMaxValues(notNullprops, 'BUILDING_SCORE');
  }, [propertyOne, propertyTwo, propertyThree, propertyFour]);

  const highLocationAmenityScore = useMemo(() => {
    const notNullprops = [propertyOne, propertyTwo, propertyThree, propertyFour].filter((el) => el);
    return getMaxValues(notNullprops, 'LOCAL_AMENITY_SCORE');
  }, [propertyOne, propertyTwo, propertyThree, propertyFour]);

  const highDeliverScore = useMemo(() => {
    const notNullprops = [propertyOne, propertyTwo, propertyThree, propertyFour].filter((el) => el);
    return getMaxValues(notNullprops, 'DELIVERABILITY_RISK_SCORE');
  }, [propertyOne, propertyTwo, propertyThree, propertyFour]);

  const highTransportScore = useMemo(() => {
    const notNullprops = [propertyOne, propertyTwo, propertyThree, propertyFour].filter((el) => el);
    return getMaxValues(notNullprops, 'TRANSPORT_SCORE');
  }, [propertyOne, propertyTwo, propertyThree, propertyFour]);

  const highESGScore = useMemo(() => {
    const notNullprops = [propertyOne, propertyTwo, propertyThree, propertyFour].filter((el) => el);
    return getMaxValues(notNullprops, 'ESG_SCORE');
  }, [propertyOne, propertyTwo, propertyThree, propertyFour]);

  const accordHeader = {
    fontcolor: '#1A1A1A',
    lineheight: '40px',
    justifycontent: 'center',
    fontsize: '36px',
    fontweight: '400',
  };

  const handleScroll = (e) => {
    if (scrollRef.current.scrollTop < 10) {
      setShowHeader(true);
    }
    if (scrollRef.current.scrollTop > 10) {
      setShowHeader(false);
    }
  };

  useEffect(() => {
    retained.forEach((el) => {
      const property = {
        ...el,
        label: getFormattedValue(el.BUILDING_NAME),
        value: el.BUILDING_NAME,
      };

      switch (el.PROPERTY_COMPARE) {
        case 1:
          setPropertyOne(property);
          break;
        case 2:
          setPropertyTwo(property);
          break;
        case 3:
          setPropertyThree(property);
          break;
        case 4:
          setPropertyFour(property);
          break;
        default:
          break;
      }
    });
  }, [retained]);

  const changeHandler = (event, value, id) => {
    let position = 0,
      property = null;

    if (value) {
      const el = data.find((el) => el.PROPERTY_CODE === value.id);
      property = {
        ...el,
        label: getFormattedValue(el.BUILDING_NAME),
        value: el.BUILDING_NAME,
      };
    }
    switch (id) {
      case 'propertyOne':
        position = 1;
        setPropertyOne(property);
        break;
      case 'propertyTwo':
        position = 2;
        setPropertyTwo(property);
        break;
      case 'propertyThree':
        position = 3;
        setPropertyThree(property);
        break;
      case 'propertyFour':
        position = 4;
        setPropertyFour(property);
        break;
      default:
        break;
    }
    let propertyKey = '',
      flag = 'FALSE';
    if (property?.HKEY_HUB_PROPERTY) {
      flag = 'TRUE';
      propertyKey = Buffer.from(property.HKEY_HUB_PROPERTY.data).toString('hex').toUpperCase();
    }
    const reqObj = {
      hkey: hkey,
      propKey: propertyKey,
      position: position,
      isCompared: flag,
    };
    updateComparedQuery(reqObj);
  };

  const updateComparedQuery = async (reqObj) => {
    try {
      await axiosPrivate.post(`/charts-ranking/compare`, reqObj);
    } catch (error) {
      console.log('Error while executing the compare properties...!', error);
    }
  };

  const pivot = useMemo(
    () =>
      Math.max(
        ...[
          propertyOne?.BADGES ? Object.values(propertyOne.BADGES).filter(Boolean).length : 0,
          propertyTwo?.BADGES ? Object.values(propertyTwo.BADGES).filter(Boolean).length : 0,
          propertyThree?.BADGES ? Object.values(propertyThree.BADGES).filter(Boolean).length : 0,
          propertyFour?.BADGES ? Object.values(propertyFour.BADGES).filter(Boolean).length : 0,
        ]
      ),
    [propertyOne, propertyTwo, propertyThree, propertyFour]
  );

  const topFinFlag =
    propertyOne?.BADGES.topFinancial ||
    propertyTwo?.BADGES.topFinancial ||
    propertyThree?.BADGES.topFinancial ||
    propertyFour?.BADGES.topFinancial;

  const legendStyling = { fontsize: '16px', lineheight: '18px', fontweight: '400', fontcolor: '#1A1A1A' };
  const showWeighting = (list, max) => list.some((el) => 100 - (el / max) * 100 > 10);
  useEffect(() => {
    if (!keyDifferences) return;
    const transportScoreList = [
      propertyOne?.TRANSPORT_SCORE,
      propertyTwo?.TRANSPORT_SCORE,
      propertyThree?.TRANSPORT_SCORE,
      propertyFour?.TRANSPORT_SCORE,
    ].filter(Boolean);
    const transportMax = Math.max(...transportScoreList);
    const showKeyTransport = showWeighting(transportScoreList, transportMax);

    const buildingScoreList = [
      propertyOne?.BUILDING_SCORE,
      propertyTwo?.BUILDING_SCORE,
      propertyThree?.BUILDING_SCORE,
      propertyFour?.BUILDING_SCORE,
    ].filter(Boolean);
    const buildingMax = Math.max(...buildingScoreList);
    const showKeyBuilding = showWeighting(buildingScoreList, buildingMax);

    const esgScoreList = [
      propertyOne?.ESG_SCORE,
      propertyTwo?.ESG_SCORE,
      propertyThree?.ESG_SCORE,
      propertyFour?.ESG_SCORE,
    ].filter(Boolean);
    const esgMax = Math.max(...esgScoreList);
    const showKeyESG = showWeighting(esgScoreList, esgMax);

    const deliverabiliyScoreList = [
      propertyOne?.DELIVERABILITY_RISK_SCORE,
      propertyTwo?.DELIVERABILITY_RISK_SCORE,
      propertyThree?.DELIVERABILITY_RISK_SCORE,
      propertyFour?.DELIVERABILITY_RISK_SCORE,
    ].filter(Boolean);
    const deliverabilityMax = Math.max(...deliverabiliyScoreList);
    const showKeyDeliverabiliy = showWeighting(deliverabiliyScoreList, deliverabilityMax);

    const locationScoreList = [
      propertyOne?.LOCAL_AMENITY_SCORE,
      propertyTwo?.LOCAL_AMENITY_SCORE,
      propertyThree?.LOCAL_AMENITY_SCORE,
      propertyFour?.LOCAL_AMENITY_SCORE,
    ].filter(Boolean);
    const locationMax = Math.max(...locationScoreList);
    const showKeyLocation = showWeighting(locationScoreList, locationMax);

    const customScoreList = [
      propertyOne?.CUSTOM_SCORE,
      propertyTwo?.CUSTOM_SCORE,
      propertyThree?.CUSTOM_SCORE,
      propertyFour?.CUSTOM_SCORE,
    ].filter(Boolean);
    const customMax = Math.max(...customScoreList);
    const showKeyCustom = showWeighting(customScoreList, customMax);

    setShowAttributes({
      showKeyTransport,
      showKeyBuilding,
      showKeyESG,
      showKeyDeliverabiliy,
      showKeyLocation,
      showKeyCustom,
    });
  }, [propertyOne, propertyTwo, propertyThree, propertyFour, keyDifferences]);

  const keyDifferencesHandler = () => {
    setKeyDifferences(!keyDifferences);
    if (keyDifferences) {
      setShowAttributes({
        ...attributes,
      });
      setTransportExp(false);
      setBuildingExp(false);
      setLocationExp(false);
      setEsgExp(false);
      setDeliverabilityExp(false);
      setCustCriteriaExp(false);
    }
    if (!keyDifferences) {
      setTransportExp(true);
      setBuildingExp(true);
      setLocationExp(true);
      setEsgExp(true);
      setDeliverabilityExp(true);
      setCustCriteriaExp(true);
    }
  };

  return (
    <Dialog open={open} fullWidth fullScreen maxWidth={'lg'}>
      <div
        className='properties-header'
        style={{
          padding: '10px 40px',
          paddingRight: propertyOne || propertyTwo || propertyThree || propertyFour ? '56px' : '50px',
          paddingBottom: 0,
          boxShadow: !showHeader ? '0px 3px 8px rgba(0, 0, 0, 0.2)' : 'none',
          zIndex: 4,
        }}
      >
        <DialogTitle>
          {showHeader && (
            <Grid container style={{ justifyContent: 'center' }}>
              <CustomTypography
                text='Compare properties'
                fontsize='36px'
                fontweight='400'
                lineheight='40px'
                fontcolor='#1A1A1A'
                letterSpacing='-0.01em'
              />
            </Grid>
          )}

          <CloseIcon
            onClick={() => {
              setOpen(false);
              setQuickCompareExp(true);
              setShowHeader(true);
            }}
            style={{
              position: 'absolute',
              right: '30px',
              top: !showHeader ? '55px' : '45px',
              cursor: 'pointer',
              opacity: 0.8,
              transition: 'top 0.1s',
            }}
          />
        </DialogTitle>
        <Grid
          style={{
            display: 'flex',
          }}
        >
          <Grid md={2} style={{ backgroundColor: showHeader ? '#F5F7F7' : '#fff' }}>
            <Grid
              style={{
                display: 'flex',
                paddingLeft: 20,
                paddingTop: 5,
              }}
            >
              {showHeader && (
                <Checkbox
                  label='Show key differences'
                  checked={keyDifferences}
                  checkedIcon={<MdCheckBox color='#003f2d' />}
                  icon={<MdCheckBoxOutlineBlank color='#767676' />}
                  onChange={keyDifferencesHandler}
                />
              )}
            </Grid>
          </Grid>
          <Grid md={10} style={{ display: 'flex' }}>
            <Grid item md={3} style={{ marginBottom: 10 }}>
              <ComboBox
                id='propertyOne'
                popup={popupOne}
                setPopup={setPopupOne}
                options={buildings?.filter(
                  (el) =>
                    el.id !== propertyTwo?.PROPERTY_CODE &&
                    el.id !== propertyThree?.PROPERTY_CODE &&
                    el.id !== propertyFour?.PROPERTY_CODE
                )}
                changeHandler={changeHandler}
                value={propertyOne ? propertyOne.label : null}
                loading={loading}
                setOptions={setProperties}
                data={data}
              />
            </Grid>
            <Grid item md={3}>
              <ComboBox
                id='propertyTwo'
                popup={popupTwo}
                setPopup={setPopupTwo}
                options={buildings?.filter(
                  (el) =>
                    el.id !== propertyOne?.PROPERTY_CODE &&
                    el.id !== propertyThree?.PROPERTY_CODE &&
                    el.id !== propertyFour?.PROPERTY_CODE
                )}
                changeHandler={changeHandler}
                value={propertyTwo ? propertyTwo.label : null}
                loading={loading}
                setOptions={setProperties}
                data={data}
              />
            </Grid>
            <Grid item md={3}>
              <ComboBox
                id='propertyThree'
                popup={popupThree}
                setPopup={setPopupThree}
                options={buildings?.filter(
                  (el) =>
                    el.id !== propertyTwo?.PROPERTY_CODE &&
                    el.id !== propertyOne?.PROPERTY_CODE &&
                    el.id !== propertyFour?.PROPERTY_CODE
                )}
                changeHandler={changeHandler}
                value={propertyThree ? propertyThree.label : null}
                loading={loading}
                setOptions={setProperties}
                data={data}
              />
            </Grid>
            <Grid item md={3}>
              <ComboBox
                id='propertyFour'
                popup={popupFour}
                setPopup={setPopupFour}
                options={buildings?.filter(
                  (el) =>
                    el.id !== propertyTwo?.PROPERTY_CODE &&
                    el.id !== propertyThree?.PROPERTY_CODE &&
                    el.id !== propertyOne?.PROPERTY_CODE
                )}
                changeHandler={changeHandler}
                value={propertyFour ? propertyFour.label : null}
                loading={loading}
                setOptions={setProperties}
                data={data}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>

      {(propertyOne || propertyTwo || propertyThree || propertyFour) && (
        <DialogContent
          onScroll={handleScroll}
          ref={scrollRef}
          className='properties-content'
          style={{
            padding: '10px 40px',
            paddingTop: 0,
            marginRight: '10px',
          }}
        >
          <Grid
            container
            style={{
              display: 'flex',
            }}
          >
            <Grid
              md={2}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                backgroundColor: '#F5F7F7',
              }}
            >
              <Grid style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
                <CustomTypography text='Legend:' {...legendStyling} />
                <Grid style={{ display: 'flex', alignItems: 'center', paddingTop: 25 }}>
                  <Grid md={2}>
                    <FireIcon />
                  </Grid>
                  <Grid md={10}>
                    <CustomTypography text='Significantly better' {...legendStyling} />
                  </Grid>
                </Grid>
                <Grid style={{ display: 'flex', alignItems: 'center', paddingTop: 10 }}>
                  <Grid md={2}>
                    <DownIcon />
                  </Grid>
                  <Grid md={10}>
                    <CustomTypography text='Significantly lower' {...legendStyling} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid md={10} style={{ display: 'flex' }}>
              <Grid item md={3}>
                {propertyOne && (
                  <CardComponent
                    media={propertyOne.MEDIA}
                    buildingName={propertyOne.BUILDING_NAME}
                    isShortListed={propertyOne.LISTED}
                    clientProperty={propertyOne.TAG_PROPERTY}
                    postalCode={propertyOne.POSTAL_CODE}
                    badges={propertyOne?.BADGES}
                    pivot={pivot}
                    topFinFlag={topFinFlag}
                  />
                )}
              </Grid>
              <Grid item md={3}>
                {propertyTwo && (
                  <CardComponent
                    media={propertyTwo.MEDIA}
                    buildingName={propertyTwo.BUILDING_NAME}
                    isShortListed={propertyTwo.LISTED}
                    clientProperty={propertyTwo.TAG_PROPERTY}
                    postalCode={propertyTwo.POSTAL_CODE}
                    badges={propertyTwo?.BADGES}
                    pivot={pivot}
                    topFinFlag={topFinFlag}
                  />
                )}
              </Grid>
              <Grid item md={3}>
                {propertyThree && (
                  <CardComponent
                    media={propertyThree.MEDIA}
                    buildingName={propertyThree.BUILDING_NAME}
                    isShortListed={propertyThree.LISTED}
                    clientProperty={propertyThree.TAG_PROPERTY}
                    postalCode={propertyThree.POSTAL_CODE}
                    badges={propertyThree?.BADGES}
                    pivot={pivot}
                    topFinFlag={topFinFlag}
                  />
                )}
              </Grid>
              <Grid item md={3}>
                {propertyFour && (
                  <CardComponent
                    media={propertyFour.MEDIA}
                    buildingName={propertyFour.BUILDING_NAME}
                    isShortListed={propertyFour.LISTED}
                    clientProperty={propertyFour.TAG_PROPERTY}
                    postalCode={propertyFour.POSTAL_CODE}
                    badges={propertyFour?.BADGES}
                    pivot={pivot}
                    topFinFlag={topFinFlag}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ margin: '20px 0' }}>
            <Accordion
              style={{
                boxShadow: 'none',
                borderRadius: 0,
                borderBottom: !quickCompareExp ? '1px solid darkgray' : 'none',
              }}
              onChange={() => setQuickCompareExp((flag) => !flag)}
              expanded={quickCompareExp}
            >
              <AccordionSummary id='panel1-header'>
                <Grid
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <Grid
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {quickCompareExp ? (
                      <MdOutlineExpandLess style={{ fontSize: '28px', opacity: 0.87 }} />
                    ) : (
                      <MdOutlineExpandMore style={{ fontSize: '28px', opacity: 0.87 }} />
                    )}
                  </Grid>
                  <CustomTypography text='Quick Compare' {...accordHeader} />
                </Grid>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                <Grid
                  style={{
                    display: 'flex',
                  }}
                >
                  <Grid md={2}>
                    <Grid
                      style={{
                        backgroundColor: '#F5F7F7',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        paddingLeft: 20,
                        minHeight: '100%',
                        fontSize: 20,
                        fontWeight: 500,
                      }}
                    >
                      <span>Category score</span>
                      <span>Rank</span>
                      <span>Space available (sq.ft.)</span>
                      <span>Rent from</span>
                    </Grid>
                  </Grid>
                  <Grid md={10} style={{ display: 'flex' }}>
                    <Grid item md={3}>
                      {propertyOne && (
                        <QuickCompare
                          score={propertyOne.SCORE}
                          rank={propertyOne.RANK}
                          spaceAvailable={propertyOne.AVAILABLE_SPACE_SQFT}
                          rent={propertyOne.RENTAMT}
                          buildingName={propertyOne.BUILDING_NAME}
                          highScoreQuick={highScoreQuick}
                        />
                      )}
                    </Grid>
                    <Grid item md={3}>
                      {propertyTwo && (
                        <QuickCompare
                          score={propertyTwo.SCORE}
                          rank={propertyTwo.RANK}
                          spaceAvailable={propertyTwo.AVAILABLE_SPACE_SQFT}
                          rent={propertyTwo.RENTAMT}
                          buildingName={propertyTwo.BUILDING_NAME}
                          highScoreQuick={highScoreQuick}
                        />
                      )}
                    </Grid>
                    <Grid item md={3}>
                      {propertyThree && (
                        <QuickCompare
                          score={propertyThree.SCORE}
                          rank={propertyThree.RANK}
                          spaceAvailable={propertyThree.AVAILABLE_SPACE_SQFT}
                          rent={propertyThree.RENTAMT}
                          buildingName={propertyThree.BUILDING_NAME}
                          highScoreQuick={highScoreQuick}
                        />
                      )}
                    </Grid>
                    <Grid item md={3}>
                      {propertyFour && (
                        <QuickCompare
                          score={propertyFour.SCORE}
                          rank={propertyFour.RANK}
                          spaceAvailable={propertyFour.AVAILABLE_SPACE_SQFT}
                          rent={propertyFour.RENTAMT}
                          buildingName={propertyFour.BUILDING_NAME}
                          highScoreQuick={highScoreQuick}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            {!quickCompareExp && (
              <Score
                scoreOne={propertyOne?.SCORE}
                scoreTwo={propertyTwo?.SCORE}
                scoreThree={propertyThree?.SCORE}
                scoreFour={propertyFour?.SCORE}
              />
            )}
          </Grid>
          {showAttributes.showKeyTransport && allowedLabels?.find((el) => el.includes('NEAREST_' || 'TUBES_')) && (
            <Grid style={{ margin: '20px 0' }}>
              <Accordion
                style={{
                  boxShadow: 'none',
                  borderRadius: 0,
                  borderBottom: !transportExp ? '1px solid darkgray' : 'none',
                }}
                onChange={() => setTransportExp((flag) => !flag)}
                expanded={transportExp}
              >
                <AccordionSummary id='panel2-header'>
                  <Grid
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <Grid
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {transportExp ? (
                        <MdOutlineExpandLess style={{ fontSize: '28px', opacity: 0.87 }} />
                      ) : (
                        <MdOutlineExpandMore style={{ fontSize: '28px', opacity: 0.87 }} />
                      )}
                    </Grid>
                    <CustomTypography text='Transport' {...accordHeader} />
                  </Grid>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0 }}>
                  <Grid
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Grid md={2}>
                      <Grid
                        style={{
                          backgroundColor: '#F6F5E6',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                          paddingLeft: 20,
                          minHeight: '100%',
                          fontSize: 18,
                          paddingRight: 20,
                        }}
                      >
                        <span>Category score</span>
                        {allowedLabels.includes('NEAREST_TUBE_IN_METER') && (
                          <span>Proximity to nearest tube station</span>
                        )}
                        {allowedLabels.includes('NEAREST_MAIN_TRAIN_STATION_IN_KM') && (
                          <span>Proximity to mainline train station</span>
                        )}
                        {allowedLabels.includes('NEAREST_ELIZABETH_LINE_STATION_IN_KM') && (
                          <span>Proximity to Elizabeth line</span>
                        )}
                        {allowedLabels.includes('TUBES_WITHIN_1_KM') && <span>Tube lines within 1km</span>}
                        {allowedLabels.includes('NEAREST_MAIN_RTRAIN_STATION_IN_KM') && (
                          <span>REGIONAL - Proximity to mainline train station</span>
                        )}
                        {allowedLabels.includes('NEAREST_TRAM_IN_METER') && (
                          <span>REGIONAL - Proximity to tram station</span>
                        )}
                      </Grid>
                    </Grid>
                    <Grid md={10} style={{ display: 'flex' }}>
                      <Grid item md={3}>
                        {propertyOne && (
                          <Transport
                            score={propertyOne.TRANSPORT_SCORE}
                            nearestTube={propertyOne.NEAREST_TUBE_IN_METER}
                            nearestTrainStation={propertyOne.NEAREST_MAIN_TRAIN_STATION_IN_KM}
                            nearestElizabethLine={propertyOne.NEAREST_ELIZABETH_LINE_STATION_IN_KM}
                            tubesWithInKM={propertyOne.TUBES_WITHIN_1_KM}
                            // tramsWithInKM={propertyOne.TRAMS_WITHIN_1_KM}
                            nearestTram={propertyOne.NEAREST_TRAM_IN_METER}
                            regionalTrain={propertyOne.NEAREST_MAIN_TRAIN_STATION_IN_KM}
                            allowedLabels={allowedLabels}
                            buildingName={propertyOne.BUILDING_NAME}
                            highTransportScore={highTransportScore}
                            badges={propertyOne?.BADGES}
                            bottomFlags={propertyOne?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyTwo && (
                          <Transport
                            score={propertyTwo.TRANSPORT_SCORE}
                            nearestTube={propertyTwo.NEAREST_TUBE_IN_METER}
                            nearestTrainStation={propertyTwo.NEAREST_MAIN_TRAIN_STATION_IN_KM}
                            nearestElizabethLine={propertyTwo.NEAREST_ELIZABETH_LINE_STATION_IN_KM}
                            tubesWithInKM={propertyTwo.TUBES_WITHIN_1_KM}
                            // tramsWithInKM={propertyTwo.TRAMS_WITHIN_1_KM}
                            nearestTram={propertyTwo.NEAREST_TRAM_IN_METER}
                            regionalTrain={propertyTwo.NEAREST_MAIN_TRAIN_STATION_IN_KM}
                            allowedLabels={allowedLabels}
                            buildingName={propertyTwo.BUILDING_NAME}
                            highTransportScore={highTransportScore}
                            badges={propertyTwo?.BADGES}
                            bottomFlags={propertyTwo?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyThree && (
                          <Transport
                            score={propertyThree.TRANSPORT_SCORE}
                            nearestTube={propertyThree.NEAREST_TUBE_IN_METER}
                            nearestTrainStation={propertyThree.NEAREST_MAIN_TRAIN_STATION_IN_KM}
                            nearestElizabethLine={propertyThree.NEAREST_ELIZABETH_LINE_STATION_IN_KM}
                            tubesWithInKM={propertyThree.TUBES_WITHIN_1_KM}
                            // tramsWithInKM={propertyThree.TRAMS_WITHIN_1_KM}
                            nearestTram={propertyThree.NEAREST_TRAM_IN_METER}
                            regionalTrain={propertyThree.NEAREST_MAIN_TRAIN_STATION_IN_KM}
                            allowedLabels={allowedLabels}
                            buildingName={propertyThree.BUILDING_NAME}
                            highTransportScore={highTransportScore}
                            badges={propertyThree?.BADGES}
                            bottomFlags={propertyThree?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyFour && (
                          <Transport
                            score={propertyFour.TRANSPORT_SCORE}
                            nearestTube={propertyFour.NEAREST_TUBE_IN_METER}
                            nearestTrainStation={propertyFour.NEAREST_MAIN_TRAIN_STATION_IN_KM}
                            nearestElizabethLine={propertyFour.NEAREST_ELIZABETH_LINE_STATION_IN_KM}
                            tubesWithInKM={propertyFour.TUBES_WITHIN_1_KM}
                            // tramsWithInKM={propertyFour.TRAMS_WITHIN_1_KM}
                            nearestTram={propertyFour.NEAREST_TRAM_IN_METER}
                            regionalTrain={propertyFour.NEAREST_MAIN_TRAIN_STATION_IN_KM}
                            allowedLabels={allowedLabels}
                            buildingName={propertyFour.BUILDING_NAME}
                            highTransportScore={highTransportScore}
                            badges={propertyFour?.BADGES}
                            bottomFlags={propertyFour?.bottomFlags}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {!transportExp && (
                <Score
                  scoreOne={propertyOne?.TRANSPORT_SCORE}
                  scoreTwo={propertyTwo?.TRANSPORT_SCORE}
                  scoreThree={propertyThree?.TRANSPORT_SCORE}
                  scoreFour={propertyFour?.TRANSPORT_SCORE}
                  bgColor='#F6F5E6'
                  highScore={highTransportScore}
                  building1={propertyOne?.BUILDING_NAME}
                  building2={propertyTwo?.BUILDING_NAME}
                  building3={propertyThree?.BUILDING_NAME}
                  building4={propertyFour?.BUILDING_NAME}
                  badge1={propertyOne?.BADGES.Transport}
                  bottomFlag1={propertyOne?.bottomFlags.transport}
                  badge2={propertyTwo?.BADGES.Transport}
                  bottomFlag2={propertyTwo?.bottomFlags.transport}
                  badge3={propertyThree?.BADGES.Transport}
                  bottomFlag3={propertyThree?.bottomFlags.transport}
                  badge4={propertyFour?.BADGES.Transport}
                  bottomFlag4={propertyFour?.bottomFlags.transport}
                />
              )}
            </Grid>
          )}
          {showAttributes.showKeyBuilding && allowedLabels?.find((el) => el.includes('BI_')) && (
            <Grid style={{ margin: '20px 0' }}>
              <Accordion
                style={{
                  boxShadow: 'none',
                  borderRadius: 0,
                  borderBottom: !buildingExp ? '1px solid darkgray' : 'none',
                }}
                onChange={() => setBuildingExp((flag) => !flag)}
                expanded={buildingExp}
              >
                <AccordionSummary id='panel3-header'>
                  <Grid
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <Grid
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {buildingExp ? (
                        <MdOutlineExpandLess style={{ fontSize: '28px', opacity: 0.87 }} />
                      ) : (
                        <MdOutlineExpandMore style={{ fontSize: '28px', opacity: 0.87 }} />
                      )}
                    </Grid>
                    <CustomTypography text='Building' {...accordHeader} />
                  </Grid>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0 }}>
                  <Grid
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Grid md={2}>
                      <Grid
                        style={{
                          backgroundColor: '#CFDEE9',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                          paddingLeft: 20,
                          minHeight: '100%',
                          fontSize: 18,
                          paddingRight: 20,
                        }}
                      >
                        <span>Category score</span>
                        {allowedLabels.includes('BI_ON_SITE_DEDICATED_GYM') && <span>On-site Dedicated Gym</span>}
                        {allowedLabels.includes('BI_ON_SITE_CATERING_AVAILABLE') && (
                          <span>On-site Catering Available</span>
                        )}
                        {allowedLabels.includes('BI_TERRACES') && <span>Terraces</span>}
                        {allowedLabels.includes('BI_LOOK_AND_FEEL') && <span>Look and Feel</span>}
                        {allowedLabels.includes('BI_BRANDING_RIGHTS') && <span>Branding Rights</span>}
                        {allowedLabels.includes('BI_LOCKERS') && <span>Lockers</span>}
                        {allowedLabels.includes('BI_FLOOR_PLATE_SIZE') && <span>Floor plate suitability</span>}
                        {allowedLabels.includes('BI_FLOOR_TO_CEILING_HEIGHT') && <span>Floor to ceiling height</span>}
                        {allowedLabels.includes('BI_OCCUPATIONAL_DENSITYT') && <span>Occupational Density</span>}
                        {allowedLabels.includes('BI_COLUMNS') && <span>Columns</span>}
                        {allowedLabels.includes('BI_NATURAL_LIGHT') && <span>Natural Light</span>}
                        {allowedLabels.includes('BI_ON_SITE_AMENITY') && <span>On-site Amenity</span>}
                        {allowedLabels.includes('BI_ON_SITE_SHOWER_FACILITIES') && (
                          <span>On-site shower facilities</span>
                        )}
                        {allowedLabels.includes('BI_SELF_CONTAINED') && <span>Self-contained</span>}
                        {allowedLabels.includes('BI_WIREDSCORE_RATING') && <span>Wired score rating</span>}
                        {allowedLabels.includes('BI_BUILDING_TECHNOLOGY') && <span>Building Technology</span>}
                        {allowedLabels.includes('BI_OUTDOOR_SPACE') && <span>Outdoor Space</span>}
                        {allowedLabels.includes('BI_SHELL_CORE_DELIVERY') && <span>Shell Core Delivery</span>}
                        {allowedLabels.includes('BI_AIR_CONDITIONING') && <span>Air Conditioning</span>}
                        {allowedLabels.includes('BI_RAISED_FLOORS') && <span>Raised Floors</span>}
                        {allowedLabels.includes('BI_ROOF_TERRACE') && <span>Roof Terrace</span>}
                        {allowedLabels.includes('BI_CAR_PARKING_SPACES') && <span>Car Parking Spaces</span>}
                        {allowedLabels.includes('BI_CAR_PARKING_RATIO') && <span>Car Parking Ratio</span>}
                        {allowedLabels.includes('BI_VIEWED') && <span>Viewed</span>}
                        {allowedLabels.includes('BI_FLOOR') && <span>Floor</span>}
                        {allowedLabels.includes('BI_TOTAL_COST_PER_SQ_FT') && <span>Total Cost Per SQ FT</span>}
                        {allowedLabels.includes('BI_TOTAL_ANNUAL_COST_PA') && <span>Total Annual Cost PA</span>}
                        {allowedLabels.includes('BI_END_OF_TRIP_FACILITIES') && <span>End of Trip Facilities</span>}
                        {allowedLabels.includes('BI_TOTAL_COST_OF_OCCUPANCY') && <span>Total Cost of Occupancy</span>}
                        {allowedLabels.includes('BI_MULTI_LET_AND_SELF_CONTAINED') && (
                          <span>Multi Let & Self Contained</span>
                        )}
                        {allowedLabels.includes('BI_SINGLE_LET_BUILDING') && <span>Single-let building</span>}
                      </Grid>
                    </Grid>
                    <Grid md={10} style={{ display: 'flex' }}>
                      <Grid item md={3}>
                        {propertyOne && (
                          <Building
                            score={propertyOne.BUILDING_SCORE}
                            dedicatedGym={propertyOne.BI_ON_SITE_DEDICATED_GYM}
                            cateringAvailable={propertyOne.BI_ON_SITE_CATERING_AVAILABLE}
                            terraces={propertyOne.BI_TERRACES}
                            lookAndFeel={propertyOne.BI_LOOK_AND_FEEL}
                            allowedLabels={allowedLabels}
                            brandingRights={propertyOne.BI_BRANDING_RIGHTS}
                            lockers={propertyOne.BI_LOCKERS}
                            floorPlateSize={propertyOne.BI_FLOOR_PLATE_SIZE}
                            floorToCeilingHeight={propertyOne.BI_FLOOR_TO_CEILING_HEIGHT}
                            occupationalDensity={propertyOne.BI_OCCUPATIONAL_DENSITY}
                            columns={propertyOne.BI_COLUMNS}
                            naturalLight={propertyOne.BI_NATURAL_LIGHT}
                            onSiteAmenity={propertyOne.BI_ON_SITE_AMENITY}
                            onSiteShowerFacility={propertyOne.BI_ON_SITE_SHOWER_FACILITIES}
                            selfContained={propertyOne.BI_SELF_CONTAINED}
                            wiredscoreRating={propertyOne.BI_WIREDSCORE_RATING}
                            buildingTechnology={propertyOne.BI_BUILDING_TECHNOLOGY}
                            outdoorSpace={propertyOne.BI_OUTDOOR_SPACE}
                            coreDelivery={propertyOne.BI_SHELL_CORE_DELIVERY}
                            airConditioning={propertyOne.BI_AIR_CONDITIONING}
                            raisedFloors={propertyOne.BI_RAISED_FLOORS}
                            roofTerrace={propertyOne.BI_ROOF_TERRACE}
                            carParkingSpaces={propertyOne.BI_CAR_PARKING_SPACES}
                            carParkingRatio={propertyOne.BI_CAR_PARKING_RATIO}
                            viewed={propertyOne.BI_VIEWED}
                            floor={propertyOne.BI_FLOOR}
                            totalCostPerSFT={propertyOne.BI_TOTAL_COST_PER_SQ_FT}
                            totAnnualCost={propertyOne.BI_TOTAL_ANNUAL_COST_PA}
                            endOfTripFacilities={propertyOne.BI_END_OF_TRIP_FACILITIES}
                            totCostofOccupancy={propertyOne.BI_TOTAL_COST_OF_OCCUPANCY}
                            multiLetNSelfContained={propertyOne.BI_MULTI_LET_AND_SELF_CONTAINED}
                            singleLetBuilding={propertyOne.BI_SINGLE_LET_BUILDING}
                            buildingName={propertyOne.BUILDING_NAME}
                            highBuildingScore={highBuildingScore}
                            badges={propertyOne?.BADGES}
                            bottomFlags={propertyOne?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyTwo && (
                          <Building
                            score={propertyTwo.BUILDING_SCORE}
                            dedicatedGym={propertyTwo.BI_ON_SITE_DEDICATED_GYM}
                            cateringAvailable={propertyTwo.BI_ON_SITE_CATERING_AVAILABLE}
                            terraces={propertyTwo.BI_TERRACES}
                            lookAndFeel={propertyTwo.BI_LOOK_AND_FEEL}
                            allowedLabels={allowedLabels}
                            brandingRights={propertyTwo.BI_BRANDING_RIGHTS}
                            lockers={propertyTwo.BI_LOCKERS}
                            floorPlateSize={propertyTwo.BI_FLOOR_PLATE_SIZE}
                            floorToCeilingHeight={propertyTwo.BI_FLOOR_TO_CEILING_HEIGHT}
                            occupationalDensity={propertyTwo.BI_OCCUPATIONAL_DENSITY}
                            columns={propertyTwo.BI_COLUMNS}
                            naturalLight={propertyTwo.BI_NATURAL_LIGHT}
                            onSiteAmenity={propertyTwo.BI_ON_SITE_AMENITY}
                            onSiteShowerFacility={propertyTwo.BI_ON_SITE_SHOWER_FACILITIES}
                            selfContained={propertyTwo.BI_SELF_CONTAINED}
                            wiredscoreRating={propertyTwo.BI_WIREDSCORE_RATING}
                            buildingTechnology={propertyTwo.BI_BUILDING_TECHNOLOGY}
                            outdoorSpace={propertyTwo.BI_OUTDOOR_SPACE}
                            coreDelivery={propertyTwo.BI_SHELL_CORE_DELIVERY}
                            airConditioning={propertyTwo.BI_AIR_CONDITIONING}
                            raisedFloors={propertyTwo.BI_RAISED_FLOORS}
                            roofTerrace={propertyTwo.BI_ROOF_TERRACE}
                            carParkingSpaces={propertyTwo.BI_CAR_PARKING_SPACES}
                            carParkingRatio={propertyTwo.BI_CAR_PARKING_RATIO}
                            viewed={propertyTwo.BI_VIEWED}
                            floor={propertyTwo.BI_FLOOR}
                            totalCostPerSFT={propertyTwo.BI_TOTAL_COST_PER_SQ_FT}
                            totAnnualCost={propertyTwo.BI_TOTAL_ANNUAL_COST_PA}
                            endOfTripFacilities={propertyTwo.BI_END_OF_TRIP_FACILITIES}
                            totCostofOccupancy={propertyTwo.BI_TOTAL_COST_OF_OCCUPANCY}
                            multiLetNSelfContained={propertyTwo.BI_MULTI_LET_AND_SELF_CONTAINED}
                            singleLetBuilding={propertyTwo.BI_SINGLE_LET_BUILDING}
                            buildingName={propertyTwo.BUILDING_NAME}
                            highBuildingScore={highBuildingScore}
                            badges={propertyTwo?.BADGES}
                            bottomFlags={propertyTwo?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyThree && (
                          <Building
                            score={propertyThree.BUILDING_SCORE}
                            dedicatedGym={propertyThree.BI_ON_SITE_DEDICATED_GYM}
                            cateringAvailable={propertyThree.BI_ON_SITE_CATERING_AVAILABLE}
                            terraces={propertyThree.BI_TERRACES}
                            lookAndFeel={propertyThree.BI_LOOK_AND_FEEL}
                            allowedLabels={allowedLabels}
                            brandingRights={propertyThree.BI_BRANDING_RIGHTS}
                            lockers={propertyThree.BI_LOCKERS}
                            floorPlateSize={propertyThree.BI_FLOOR_PLATE_SIZE}
                            floorToCeilingHeight={propertyThree.BI_FLOOR_TO_CEILING_HEIGHT}
                            occupationalDensity={propertyThree.BI_OCCUPATIONAL_DENSITY}
                            columns={propertyThree.BI_COLUMNS}
                            naturalLight={propertyThree.BI_NATURAL_LIGHT}
                            onSiteAmenity={propertyThree.BI_ON_SITE_AMENITY}
                            onSiteShowerFacility={propertyThree.BI_ON_SITE_SHOWER_FACILITIES}
                            selfContained={propertyThree.BI_SELF_CONTAINED}
                            wiredscoreRating={propertyThree.BI_WIREDSCORE_RATING}
                            buildingTechnology={propertyThree.BI_BUILDING_TECHNOLOGY}
                            outdoorSpace={propertyThree.BI_OUTDOOR_SPACE}
                            coreDelivery={propertyThree.BI_SHELL_CORE_DELIVERY}
                            airConditioning={propertyThree.BI_AIR_CONDITIONING}
                            raisedFloors={propertyThree.BI_RAISED_FLOORS}
                            roofTerrace={propertyThree.BI_ROOF_TERRACE}
                            carParkingSpaces={propertyThree.BI_CAR_PARKING_SPACES}
                            carParkingRatio={propertyThree.BI_CAR_PARKING_RATIO}
                            viewed={propertyThree.BI_VIEWED}
                            floor={propertyThree.BI_FLOOR}
                            totalCostPerSFT={propertyThree.BI_TOTAL_COST_PER_SQ_FT}
                            totAnnualCost={propertyThree.BI_TOTAL_ANNUAL_COST_PA}
                            endOfTripFacilities={propertyThree.BI_END_OF_TRIP_FACILITIES}
                            totCostofOccupancy={propertyThree.BI_TOTAL_COST_OF_OCCUPANCY}
                            multiLetNSelfContained={propertyThree.BI_MULTI_LET_AND_SELF_CONTAINED}
                            singleLetBuilding={propertyThree.BI_SINGLE_LET_BUILDING}
                            buildingName={propertyThree.BUILDING_NAME}
                            highBuildingScore={highBuildingScore}
                            badges={propertyThree?.BADGES}
                            bottomFlags={propertyThree?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyFour && (
                          <Building
                            score={propertyFour.BUILDING_SCORE}
                            dedicatedGym={propertyFour.BI_ON_SITE_DEDICATED_GYM}
                            cateringAvailable={propertyFour.BI_ON_SITE_CATERING_AVAILABLE}
                            terraces={propertyFour.BI_TERRACES}
                            lookAndFeel={propertyFour.BI_LOOK_AND_FEEL}
                            allowedLabels={allowedLabels}
                            brandingRights={propertyFour.BI_BRANDING_RIGHTS}
                            lockers={propertyFour.BI_LOCKERS}
                            floorPlateSize={propertyFour.BI_FLOOR_PLATE_SIZE}
                            floorToCeilingHeight={propertyFour.BI_FLOOR_TO_CEILING_HEIGHT}
                            occupationalDensity={propertyFour.BI_OCCUPATIONAL_DENSITY}
                            columns={propertyFour.BI_COLUMNS}
                            naturalLight={propertyFour.BI_NATURAL_LIGHT}
                            onSiteAmenity={propertyFour.BI_ON_SITE_AMENITY}
                            onSiteShowerFacility={propertyFour.BI_ON_SITE_SHOWER_FACILITIES}
                            selfContained={propertyFour.BI_SELF_CONTAINED}
                            wiredscoreRating={propertyFour.BI_WIREDSCORE_RATING}
                            buildingTechnology={propertyFour.BI_BUILDING_TECHNOLOGY}
                            outdoorSpace={propertyFour.BI_OUTDOOR_SPACE}
                            coreDelivery={propertyFour.BI_SHELL_CORE_DELIVERY}
                            airConditioning={propertyFour.BI_AIR_CONDITIONING}
                            raisedFloors={propertyFour.BI_RAISED_FLOORS}
                            roofTerrace={propertyFour.BI_ROOF_TERRACE}
                            carParkingSpaces={propertyFour.BI_CAR_PARKING_SPACES}
                            carParkingRatio={propertyFour.BI_CAR_PARKING_RATIO}
                            viewed={propertyFour.BI_VIEWED}
                            floor={propertyFour.BI_FLOOR}
                            totalCostPerSFT={propertyFour.BI_TOTAL_COST_PER_SQ_FT}
                            totAnnualCost={propertyFour.BI_TOTAL_ANNUAL_COST_PA}
                            endOfTripFacilities={propertyFour.BI_END_OF_TRIP_FACILITIES}
                            totCostofOccupancy={propertyFour.BI_TOTAL_COST_OF_OCCUPANCY}
                            multiLetNSelfContained={propertyFour.BI_MULTI_LET_AND_SELF_CONTAINED}
                            singleLetBuilding={propertyFour.BI_SINGLE_LET_BUILDING}
                            buildingName={propertyFour.BUILDING_NAME}
                            highBuildingScore={highBuildingScore}
                            badges={propertyFour?.BADGES}
                            bottomFlags={propertyFour?.bottomFlags}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {!buildingExp && (
                <Score
                  scoreOne={propertyOne?.BUILDING_SCORE}
                  scoreTwo={propertyTwo?.BUILDING_SCORE}
                  scoreThree={propertyThree?.BUILDING_SCORE}
                  scoreFour={propertyFour?.BUILDING_SCORE}
                  bgColor='#CFDEE9'
                  building1={propertyOne?.BUILDING_NAME}
                  building2={propertyTwo?.BUILDING_NAME}
                  building3={propertyThree?.BUILDING_NAME}
                  building4={propertyFour?.BUILDING_NAME}
                  highScore={highBuildingScore}
                  badge1={propertyOne?.BADGES.Building}
                  bottomFlag1={propertyOne?.bottomFlags.building}
                  badge2={propertyTwo?.BADGES.Building}
                  bottomFlag2={propertyTwo?.bottomFlags.building}
                  badge3={propertyThree?.BADGES.Building}
                  bottomFlag3={propertyThree?.bottomFlags.building}
                  badge4={propertyFour?.BADGES.Building}
                  bottomFlag4={propertyFour?.bottomFlags.building}
                />
              )}
            </Grid>
          )}
          {showAttributes.showKeyLocation && allowedLabels?.find((el) => el.includes('LA_')) && (
            <Grid style={{ margin: '20px 0' }}>
              <Accordion
                style={{
                  boxShadow: 'none',
                  borderRadius: 0,
                  borderBottom: !locationExp ? '1px solid darkgray' : 'none',
                }}
                onChange={() => setLocationExp((flag) => !flag)}
                expanded={locationExp}
              >
                <AccordionSummary id='panel4-header'>
                  <Grid
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <Grid
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {locationExp ? (
                        <MdOutlineExpandLess style={{ fontSize: '28px', opacity: 0.87 }} />
                      ) : (
                        <MdOutlineExpandMore style={{ fontSize: '28px', opacity: 0.87 }} />
                      )}
                    </Grid>
                    <CustomTypography text='Location & Amenity' {...accordHeader} />
                  </Grid>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0 }}>
                  <Grid
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Grid md={2}>
                      <Grid
                        style={{
                          backgroundColor: '#DFEEEB',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                          paddingLeft: 20,
                          minHeight: '100%',
                          fontSize: 18,
                          paddingRight: 20,
                        }}
                      >
                        <span>Category score</span>
                        {allowedLabels.includes('LA_TRANSPORT_HUB') && <span>Proximity to transport hubs</span>}
                        {allowedLabels.includes('LA_LEVEL_LOCAL_AMENITY') && <span>Level of local amenity</span>}
                        {allowedLabels.includes('LA_GREEN_SPACE') && <span>Green Spaces</span>}
                        {allowedLabels.includes('LA_PERSONAL_SAFETY') && <span>Personal safety</span>}
                        {allowedLabels.includes('LA_PROX_CLIENT_COMPETITOR') && (
                          <span>Proximity to Clients/Competitors</span>
                        )}
                        {allowedLabels.includes('LA_PROX_GYM_HOTEL') && <span>Proximity to gym/hotels</span>}
                        {allowedLabels.includes('LA_FLEX_OPTIONS') && <span>Flex options in the immediate area</span>}
                      </Grid>
                    </Grid>
                    <Grid md={10} style={{ display: 'flex' }}>
                      <Grid item md={3}>
                        {propertyOne && (
                          <LocationAmenity
                            score={propertyOne.LOCAL_AMENITY_SCORE}
                            transporthub={propertyOne.LA_TRANSPORT_HUB}
                            level={propertyOne.LA_LEVEL_LOCAL_AMENITY}
                            greenspace={propertyOne.LA_GREEN_SPACE}
                            safety={propertyOne.LA_PERSONAL_SAFETY}
                            competitor={propertyOne.PROX_CLIENT_COMPETITOR}
                            gymHotel={propertyOne.PROX_GYM_HOTEL}
                            flexOptions={propertyOne.LA_FLEX_OPTIONS}
                            allowedLabels={allowedLabels}
                            highLocationAmenityScore={highLocationAmenityScore}
                            buildingName={propertyOne.BUILDING_NAME}
                            badges={propertyOne?.BADGES}
                            bottomFlags={propertyOne?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyTwo && (
                          <LocationAmenity
                            score={propertyTwo.LOCAL_AMENITY_SCORE}
                            transporthub={propertyTwo.LA_TRANSPORT_HUB}
                            level={propertyTwo.LA_LEVEL_LOCAL_AMENITY}
                            greenspace={propertyTwo.LA_GREEN_SPACE}
                            safety={propertyTwo.LA_PERSONAL_SAFETY}
                            competitor={propertyTwo.PROX_CLIENT_COMPETITOR}
                            gymHotel={propertyTwo.PROX_GYM_HOTEL}
                            flexOptions={propertyTwo.LA_FLEX_OPTIONS}
                            allowedLabels={allowedLabels}
                            highLocationAmenityScore={highLocationAmenityScore}
                            buildingName={propertyTwo.BUILDING_NAME}
                            badges={propertyTwo?.BADGES}
                            bottomFlags={propertyTwo?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyThree && (
                          <LocationAmenity
                            score={propertyThree.LOCAL_AMENITY_SCORE}
                            transporthub={propertyThree.LA_TRANSPORT_HUB}
                            level={propertyThree.LA_LEVEL_LOCAL_AMENITY}
                            greenspace={propertyThree.LA_GREEN_SPACE}
                            safety={propertyThree.LA_PERSONAL_SAFETY}
                            competitor={propertyThree.PROX_CLIENT_COMPETITOR}
                            gymHotel={propertyThree.PROX_GYM_HOTEL}
                            flexOptions={propertyThree.LA_FLEX_OPTIONS}
                            allowedLabels={allowedLabels}
                            highLocationAmenityScore={highLocationAmenityScore}
                            buildingName={propertyThree.BUILDING_NAME}
                            badges={propertyThree?.BADGES}
                            bottomFlags={propertyThree?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyFour && (
                          <LocationAmenity
                            score={propertyFour.LOCAL_AMENITY_SCORE}
                            transporthub={propertyFour.LA_TRANSPORT_HUB}
                            level={propertyFour.LA_LEVEL_LOCAL_AMENITY}
                            greenspace={propertyFour.LA_GREEN_SPACE}
                            safety={propertyFour.LA_PERSONAL_SAFETY}
                            competitor={propertyFour.PROX_CLIENT_COMPETITOR}
                            gymHotel={propertyFour.PROX_GYM_HOTEL}
                            flexOptions={propertyFour.LA_FLEX_OPTIONS}
                            allowedLabels={allowedLabels}
                            highLocationAmenityScore={highLocationAmenityScore}
                            buildingName={propertyFour.BUILDING_NAME}
                            badges={propertyFour?.BADGES}
                            bottomFlags={propertyFour?.bottomFlags}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {!locationExp && (
                <Score
                  scoreOne={propertyOne?.LOCAL_AMENITY_SCORE}
                  scoreTwo={propertyTwo?.LOCAL_AMENITY_SCORE}
                  scoreThree={propertyThree?.LOCAL_AMENITY_SCORE}
                  scoreFour={propertyFour?.LOCAL_AMENITY_SCORE}
                  bgColor='#DFEEEB'
                  highScore={highLocationAmenityScore}
                  building1={propertyOne?.BUILDING_NAME}
                  building2={propertyTwo?.BUILDING_NAME}
                  building3={propertyThree?.BUILDING_NAME}
                  building4={propertyFour?.BUILDING_NAME}
                  badge1={propertyOne?.BADGES['Location & Amenity']}
                  bottomFlag1={propertyOne?.bottomFlags.location}
                  badge2={propertyTwo?.BADGES['Location & Amenity']}
                  bottomFlag2={propertyTwo?.bottomFlags.location}
                  badge3={propertyThree?.BADGES['Location & Amenity']}
                  bottomFlag3={propertyThree?.bottomFlags.location}
                  badge4={propertyFour?.BADGES['Location & Amenity']}
                  bottomFlag4={propertyFour?.bottomFlags.location}
                />
              )}
            </Grid>
          )}
          {showAttributes.showKeyESG && allowedLabels?.find((el) => el.includes('ESG_')) && (
            <Grid style={{ margin: '20px 0' }}>
              <Accordion
                style={{
                  boxShadow: 'none',
                  borderRadius: 0,
                  borderBottom: !esgExp ? '1px solid darkgray' : 'none',
                }}
                onChange={() => setEsgExp((flag) => !flag)}
                expanded={esgExp}
              >
                <AccordionSummary id='panel5-header'>
                  <Grid
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <Grid
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {esgExp ? (
                        <MdOutlineExpandLess style={{ fontSize: '28px', opacity: 0.87 }} />
                      ) : (
                        <MdOutlineExpandMore style={{ fontSize: '28px', opacity: 0.87 }} />
                      )}
                    </Grid>
                    <CustomTypography text='ESG' {...accordHeader} />
                  </Grid>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0 }}>
                  <Grid
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Grid md={2}>
                      <Grid
                        style={{
                          backgroundColor: '#E0D9E7',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                          paddingLeft: 20,
                          minHeight: '100%',
                          fontSize: 18,
                          paddingRight: 20,
                        }}
                      >
                        <span>Category score</span>
                        {allowedLabels.includes('ESG_BREEAM_RATING') && <span>BREEAM Rating</span>}
                        {allowedLabels.includes('ESG_NABERS_RATING') && <span>Nabers Rating</span>}
                        {allowedLabels.includes('ESG_EPC_RATING') && <span>EPC Rating</span>}
                        {allowedLabels.includes('ESG_ALL_ELECTRIC_BUILDING') && <span>All-Electric Building</span>}
                        {allowedLabels.includes('ESG_NET_CARBON_ZERO') && <span>Net Carbon Zero</span>}
                        {allowedLabels.includes('ESG_NATURALLY_VENTILATED') && <span>Naturally Ventilated</span>}
                        {allowedLabels.includes('ESG_WELL_RATING') && <span>WELL Rating</span>}
                        {allowedLabels.includes('ESG_CYCLE_SPACES') && <span>Cycle Spaces</span>}
                      </Grid>
                    </Grid>
                    <Grid md={10} style={{ display: 'flex' }}>
                      <Grid item md={3}>
                        {propertyOne && (
                          <ESG
                            score={propertyOne.ESG_SCORE}
                            BREEAMRating={propertyOne.ESG_BREEAM_RATING}
                            nabersRating={propertyOne.ESG_NABERS_RATING}
                            EPCRating={propertyOne.ESG_EPC_RATING}
                            allElectricBuilding={propertyOne.ESG_ALL_ELECTRIC_BUILDING}
                            netCarbonZero={propertyOne.ESG_NET_CARBON_ZERO}
                            naturallyVentilated={propertyOne.ESG_NATURALLY_VENTILATED}
                            wellRating={propertyOne.ESG_WELL_RATING}
                            cycleSpaces={propertyOne.ESG_CYCLE_SPACES}
                            allowedLabels={allowedLabels}
                            highESGScore={highESGScore}
                            buildingName={propertyOne.BUILDING_NAME}
                            badges={propertyOne?.BADGES}
                            bottomFlags={propertyOne?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyTwo && (
                          <ESG
                            score={propertyTwo.ESG_SCORE}
                            BREEAMRating={propertyTwo.ESG_BREEAM_RATING}
                            nabersRating={propertyTwo.ESG_NABERS_RATING}
                            EPCRating={propertyTwo.ESG_EPC_RATING}
                            allElectricBuilding={propertyTwo.ESG_ALL_ELECTRIC_BUILDING}
                            netCarbonZero={propertyTwo.ESG_NET_CARBON_ZERO}
                            naturallyVentilated={propertyTwo.ESG_NATURALLY_VENTILATED}
                            wellRating={propertyTwo.ESG_WELL_RATING}
                            cycleSpaces={propertyTwo.ESG_CYCLE_SPACES}
                            allowedLabels={allowedLabels}
                            highESGScore={highESGScore}
                            buildingName={propertyTwo.BUILDING_NAME}
                            badges={propertyTwo?.BADGES}
                            bottomFlags={propertyTwo?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyThree && (
                          <ESG
                            score={propertyThree.ESG_SCORE}
                            BREEAMRating={propertyThree.ESG_BREEAM_RATING}
                            nabersRating={propertyThree.ESG_NABERS_RATING}
                            EPCRating={propertyThree.ESG_EPC_RATING}
                            allElectricBuilding={propertyThree.ESG_ALL_ELECTRIC_BUILDING}
                            netCarbonZero={propertyThree.ESG_NET_CARBON_ZERO}
                            naturallyVentilated={propertyThree.ESG_NATURALLY_VENTILATED}
                            wellRating={propertyThree.ESG_WELL_RATING}
                            cycleSpaces={propertyThree.ESG_CYCLE_SPACES}
                            allowedLabels={allowedLabels}
                            highESGScore={highESGScore}
                            buildingName={propertyThree.BUILDING_NAME}
                            badges={propertyThree?.BADGES}
                            bottomFlags={propertyThree?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyFour && (
                          <ESG
                            score={propertyFour.ESG_SCORE}
                            BREEAMRating={propertyFour.ESG_BREEAM_RATING}
                            nabersRating={propertyFour.ESG_NABERS_RATING}
                            EPCRating={propertyFour.ESG_EPC_RATING}
                            allElectricBuilding={propertyFour.ESG_ALL_ELECTRIC_BUILDING}
                            netCarbonZero={propertyFour.ESG_NET_CARBON_ZERO}
                            naturallyVentilated={propertyFour.ESG_NATURALLY_VENTILATED}
                            wellRating={propertyFour.ESG_WELL_RATING}
                            cycleSpaces={propertyFour.ESG_CYCLE_SPACES}
                            allowedLabels={allowedLabels}
                            highESGScore={highESGScore}
                            buildingName={propertyFour.BUILDING_NAME}
                            badges={propertyFour?.BADGES}
                            bottomFlags={propertyFour?.bottomFlags}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {!esgExp && (
                <Score
                  scoreOne={propertyOne?.ESG_SCORE}
                  scoreTwo={propertyTwo?.ESG_SCORE}
                  scoreThree={propertyThree?.ESG_SCORE}
                  scoreFour={propertyFour?.ESG_SCORE}
                  bgColor='#E0D9E7'
                  highScore={highESGScore}
                  building1={propertyOne?.BUILDING_NAME}
                  building2={propertyTwo?.BUILDING_NAME}
                  building3={propertyThree?.BUILDING_NAME}
                  building4={propertyFour?.BUILDING_NAME}
                  badge1={propertyOne?.BADGES.ESG}
                  bottomFlag1={propertyOne?.bottomFlags.esg}
                  badge2={propertyTwo?.BADGES.ESG}
                  bottomFlag2={propertyTwo?.bottomFlags.esg}
                  badge3={propertyThree?.BADGES.ESG}
                  bottomFlag3={propertyThree?.bottomFlags.esg}
                  badge4={propertyFour?.BADGES.ESG}
                  bottomFlag4={propertyFour?.bottomFlags.esg}
                />
              )}
            </Grid>
          )}
          {showAttributes.showKeyDeliverabiliy && allowedLabels?.find((el) => el.includes('DR_')) && (
            <Grid style={{ margin: '20px 0' }}>
              <Accordion
                style={{
                  boxShadow: 'none',
                  borderRadius: 0,
                  borderBottom: !deliverabilityExp ? '1px solid darkgray' : 'none',
                }}
                onChange={() => setDeliverabilityExp((flag) => !flag)}
                expanded={deliverabilityExp}
              >
                <AccordionSummary id='panel6-header'>
                  <Grid
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <Grid
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {deliverabilityExp ? (
                        <MdOutlineExpandLess style={{ fontSize: '28px', opacity: 0.87 }} />
                      ) : (
                        <MdOutlineExpandMore style={{ fontSize: '28px', opacity: 0.87 }} />
                      )}
                    </Grid>
                    <CustomTypography text='Deliverability & Risk' {...accordHeader} />
                  </Grid>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0 }}>
                  <Grid
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Grid md={2}>
                      <Grid
                        style={{
                          backgroundColor: '#D9CAD3',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                          paddingLeft: 20,
                          minHeight: '100%',
                          fontSize: 18,
                          paddingRight: 20,
                        }}
                      >
                        <span>Category score</span>
                        {allowedLabels.includes('DR_DELIVERY_STATUS') && <span>Delivery Status</span>}
                        {allowedLabels.includes('DR_LANDLORD_CREDIBILITY') && <span>Landlord Credibility</span>}
                        {allowedLabels.includes('DR_PLANNING_DELIVERY_STATUS') && <span>Planning Delivery Status</span>}
                        {allowedLabels.includes('DR_TARGET_DELIVERY_DATE') && <span>Target Delivery Date</span>}
                        {allowedLabels.includes('DR_LANDLORD_PROPERTY_OPPORTUNITY') && (
                          <span>Landlord Property Opportunity</span>
                        )}
                        {allowedLabels.includes('DR_DELIVERY_CONDITION') && <span>Delivery Condition</span>}
                        {allowedLabels.includes('DR_DELIVERY_DATE') && <span>Delivery Date</span>}
                      </Grid>
                    </Grid>
                    <Grid md={10} style={{ display: 'flex' }}>
                      <Grid item md={3}>
                        {propertyOne && (
                          <Deliverability
                            score={propertyOne.DELIVERABILITY_RISK_SCORE}
                            deliveryStatus={propertyOne.DR_DELIVERY_STATUS}
                            landlordCredibility={propertyOne.DR_LANDLORD_CREDIBILITY}
                            planningDeliveryStatus={propertyOne.DR_PLANNING_DELIVERY_STATUS}
                            targetDeliveryDate={propertyOne.DR_TARGET_DELIVERY_DATE}
                            landlordPropertyOpportunity={propertyOne.DR_LANDLORD_PROPERTY_OPPORTUNITY}
                            deliveryCondition={propertyOne.DR_DELIVERY_CONDITION}
                            deliveryDate={propertyOne.DR_DELIVERY_DATE}
                            allowedLabels={allowedLabels}
                            buildingName={propertyOne.BUILDING_NAME}
                            highDeliverScore={highDeliverScore}
                            badges={propertyOne?.BADGES}
                            bottomFlags={propertyOne?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyTwo && (
                          <Deliverability
                            score={propertyTwo.DELIVERABILITY_RISK_SCORE}
                            deliveryStatus={propertyTwo.DR_DELIVERY_STATUS}
                            landlordCredibility={propertyTwo.DR_LANDLORD_CREDIBILITY}
                            planningDeliveryStatus={propertyTwo.DR_PLANNING_DELIVERY_STATUS}
                            targetDeliveryDate={propertyTwo.DR_TARGET_DELIVERY_DATE}
                            landlordPropertyOpportunity={propertyTwo.DR_LANDLORD_PROPERTY_OPPORTUNITY}
                            deliveryCondition={propertyTwo.DR_DELIVERY_CONDITION}
                            deliveryDate={propertyTwo.DR_DELIVERY_DATE}
                            allowedLabels={allowedLabels}
                            buildingName={propertyTwo.BUILDING_NAME}
                            highDeliverScore={highDeliverScore}
                            badges={propertyTwo?.BADGES}
                            bottomFlags={propertyTwo?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyThree && (
                          <Deliverability
                            score={propertyThree.DELIVERABILITY_RISK_SCORE}
                            deliveryStatus={propertyThree.DR_DELIVERY_STATUS}
                            landlordCredibility={propertyThree.DR_LANDLORD_CREDIBILITY}
                            planningDeliveryStatus={propertyThree.DR_PLANNING_DELIVERY_STATUS}
                            targetDeliveryDate={propertyThree.DR_TARGET_DELIVERY_DATE}
                            landlordPropertyOpportunity={propertyThree.DR_LANDLORD_PROPERTY_OPPORTUNITY}
                            deliveryCondition={propertyThree.DR_DELIVERY_CONDITION}
                            deliveryDate={propertyThree.DR_DELIVERY_DATE}
                            allowedLabels={allowedLabels}
                            buildingName={propertyThree.BUILDING_NAME}
                            highDeliverScore={highDeliverScore}
                            badges={propertyThree?.BADGES}
                            bottomFlags={propertyThree?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyFour && (
                          <Deliverability
                            score={propertyFour.DELIVERABILITY_RISK_SCORE}
                            deliveryStatus={propertyFour.DR_DELIVERY_STATUS}
                            landlordCredibility={propertyFour.DR_LANDLORD_CREDIBILITY}
                            planningDeliveryStatus={propertyFour.DR_PLANNING_DELIVERY_STATUS}
                            targetDeliveryDate={propertyFour.DR_TARGET_DELIVERY_DATE}
                            landlordPropertyOpportunity={propertyFour.DR_LANDLORD_PROPERTY_OPPORTUNITY}
                            deliveryCondition={propertyFour.DR_DELIVERY_CONDITION}
                            deliveryDate={propertyFour.DR_DELIVERY_DATE}
                            allowedLabels={allowedLabels}
                            buildingName={propertyFour.BUILDING_NAME}
                            highDeliverScore={highDeliverScore}
                            badges={propertyFour?.BADGES}
                            bottomFlags={propertyFour?.bottomFlags}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {!deliverabilityExp && (
                <Score
                  scoreOne={propertyOne?.DELIVERABILITY_RISK_SCORE}
                  scoreTwo={propertyTwo?.DELIVERABILITY_RISK_SCORE}
                  scoreThree={propertyThree?.DELIVERABILITY_RISK_SCORE}
                  scoreFour={propertyFour?.DELIVERABILITY_RISK_SCORE}
                  bgColor='#D9CAD3'
                  highScore={highDeliverScore}
                  building1={propertyOne?.BUILDING_NAME}
                  building2={propertyTwo?.BUILDING_NAME}
                  building3={propertyThree?.BUILDING_NAME}
                  building4={propertyFour?.BUILDING_NAME}
                  badge1={propertyOne?.BADGES['Deliverability & Risk']}
                  bottomFlag1={propertyOne?.bottomFlags.deliverability}
                  badge2={propertyTwo?.BADGES['Deliverability & Risk']}
                  bottomFlag2={propertyTwo?.bottomFlags.deliverability}
                  badge3={propertyThree?.BADGES['Deliverability & Risk']}
                  bottomFlag3={propertyThree?.bottomFlags.deliverability}
                  badge4={propertyFour?.BADGES['Deliverability & Risk']}
                  bottomFlag4={propertyFour?.bottomFlags.deliverability}
                />
              )}
            </Grid>
          )}
          {showAttributes.showKeyCustom && allowedLabels?.includes('CUSTOM_WEIGHTAGE') && (
            <Grid style={{ margin: '20px 0' }}>
              <Accordion
                style={{
                  boxShadow: 'none',
                  borderRadius: 0,
                  borderBottom: !custCriteriaExp ? '1px solid darkgray' : 'none',
                }}
                onChange={() => setCustCriteriaExp((flag) => !flag)}
                expanded={custCriteriaExp}
              >
                <AccordionSummary id='panel7-header'>
                  <Grid
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <Grid
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {custCriteriaExp ? (
                        <MdOutlineExpandLess style={{ fontSize: '28px', opacity: 0.87 }} />
                      ) : (
                        <MdOutlineExpandMore style={{ fontSize: '28px', opacity: 0.87 }} />
                      )}
                    </Grid>
                    <CustomTypography text='Custom Criteria' {...accordHeader} />
                  </Grid>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0 }}>
                  <Grid
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Grid md={2}>
                      <Grid
                        style={{
                          backgroundColor: '#96b0bf',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                          paddingLeft: 20,
                          minHeight: '100%',
                          paddingRight: 20,
                          fontSize: 18,
                        }}
                      >
                        <span>Category score</span>
                        {Object.keys(
                          propertyOne?.CUSTOM_SUB_CAT ||
                            propertyTwo?.CUSTOM_SUB_CAT ||
                            propertyThree?.CUSTOM_SUB_CAT ||
                            propertyFour?.CUSTOM_SUB_CAT ||
                            {}
                        )
                          .filter((elm) => custWeightings.includes(elm))
                          .map((el) => (
                            <span key={el}>{getCapitalized(el.toLowerCase())}</span>
                          ))}
                      </Grid>
                    </Grid>
                    <Grid md={10} style={{ display: 'flex' }}>
                      <Grid item md={3}>
                        {propertyOne && (
                          <CustomCriteia
                            score={propertyOne.CUSTOM_SCORE}
                            customData={propertyOne.CUSTOM_SUB_CAT}
                            custWeightings={custWeightings}
                            badges={propertyOne?.BADGES}
                            bottomFlags={propertyOne?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyTwo && (
                          <CustomCriteia
                            score={propertyTwo.CUSTOM_SCORE}
                            customData={propertyTwo.CUSTOM_SUB_CAT}
                            custWeightings={custWeightings}
                            badges={propertyTwo?.BADGES}
                            bottomFlags={propertyTwo?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyThree && (
                          <CustomCriteia
                            score={propertyThree.CUSTOM_SCORE}
                            customData={propertyThree.CUSTOM_SUB_CAT}
                            custWeightings={custWeightings}
                            badges={propertyThree?.BADGES}
                            bottomFlags={propertyThree?.bottomFlags}
                          />
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {propertyFour && (
                          <CustomCriteia
                            score={propertyFour.CUSTOM_SCORE}
                            customData={propertyFour.CUSTOM_SUB_CAT}
                            custWeightings={custWeightings}
                            badges={propertyFour?.BADGES}
                            bottomFlags={propertyFour?.bottomFlags}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {!custCriteriaExp && (
                <Score
                  scoreOne={propertyOne?.CUSTOM_SCORE}
                  scoreTwo={propertyTwo?.CUSTOM_SCORE}
                  scoreThree={propertyThree?.CUSTOM_SCORE}
                  scoreFour={propertyFour?.CUSTOM_SCORE}
                  bgColor='#96b0bf'
                  badge1={propertyOne?.BADGES['Custom Criteria']}
                  bottomFlag1={propertyOne?.bottomFlags.custCriteria}
                  badge2={propertyTwo?.BADGES['Custom Criteria']}
                  bottomFlag2={propertyTwo?.bottomFlags.custCriteria}
                  badge3={propertyThree?.BADGES['Custom Criteria']}
                  bottomFlag3={propertyThree?.bottomFlags.custCriteria}
                  badge4={propertyFour?.BADGES['Custom Criteria']}
                  bottomFlag4={propertyFour?.bottomFlags.custCriteria}
                />
              )}
            </Grid>
          )}
          <Grid style={{ margin: '20px 0 50px 0' }}>
            <Accordion
              style={{
                boxShadow: 'none',
                borderRadius: 0,
                borderBottom: !financialsExp ? '1px solid darkgray' : 'none',
              }}
              onChange={() => setFinancialsExp((flag) => !flag)}
              expanded={financialsExp}
            >
              <AccordionSummary id='panel8-header'>
                <Grid
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <Grid
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {financialsExp ? (
                      <MdOutlineExpandLess style={{ fontSize: '28px', opacity: 0.87 }} />
                    ) : (
                      <MdOutlineExpandMore style={{ fontSize: '28px', opacity: 0.87 }} />
                    )}
                  </Grid>
                  <CustomTypography text='Financials' {...accordHeader} />
                </Grid>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                <Grid
                  style={{
                    display: 'flex',
                  }}
                >
                  <Grid md={2}>
                    <Grid
                      style={{
                        backgroundColor: '#E1E7E8',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        paddingLeft: 20,
                        minHeight: '100%',
                        fontSize: 18,
                        paddingRight: 20,
                      }}
                    >
                      <span>Rent from (£/sq.ft.)</span>
                      <span>Business Rates from (£/sq.ft.)</span>
                      <span>Service Charge from (£/sq.ft.)</span>
                      <span>Estate Charge from (£/sq.ft.)</span>
                      <span>All in Cost (£/sq.ft.)</span>
                      <span>Total Annual Cost (£/Annum)</span>
                    </Grid>
                  </Grid>
                  <Grid md={10} style={{ display: 'flex' }}>
                    <Grid item md={3}>
                      {propertyOne && (
                        <Financials
                          rent={propertyOne.FI_RENT}
                          businessRates={propertyOne.FI_BUSINESS_RATES}
                          serviceCharge={propertyOne.FI_SERVICE_CHARGE}
                          estateCharge={propertyOne.FI_ESTATE_CHARGE}
                          allInCost={propertyOne.FI_ALL_COST}
                          totalAnnualCost={propertyOne.FI_TOTAL_ANNUAL_COST}
                        />
                      )}
                    </Grid>
                    <Grid item md={3}>
                      {propertyTwo && (
                        <Financials
                          rent={propertyTwo.FI_RENT}
                          businessRates={propertyTwo.FI_BUSINESS_RATES}
                          serviceCharge={propertyTwo.FI_SERVICE_CHARGE}
                          estateCharge={propertyTwo.FI_ESTATE_CHARGE}
                          allInCost={propertyTwo.FI_ALL_COST}
                          totalAnnualCost={propertyTwo.FI_TOTAL_ANNUAL_COST}
                        />
                      )}
                    </Grid>
                    <Grid item md={3}>
                      {propertyThree && (
                        <Financials
                          rent={propertyThree.FI_RENT}
                          businessRates={propertyThree.FI_BUSINESS_RATES}
                          serviceCharge={propertyThree.FI_SERVICE_CHARGE}
                          estateCharge={propertyThree.FI_ESTATE_CHARGE}
                          allInCost={propertyThree.FI_ALL_COST}
                          totalAnnualCost={propertyThree.FI_TOTAL_ANNUAL_COST}
                        />
                      )}
                    </Grid>
                    <Grid item md={3}>
                      {propertyFour && (
                        <Financials
                          rent={propertyFour.FI_RENT}
                          businessRates={propertyFour.FI_BUSINESS_RATES}
                          serviceCharge={propertyFour.FI_SERVICE_CHARGE}
                          estateCharge={propertyFour.FI_ESTATE_CHARGE}
                          allInCost={propertyFour.FI_ALL_COST}
                          totalAnnualCost={propertyFour.FI_TOTAL_ANNUAL_COST}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </DialogContent>
      )}
      {retainedLoading && <LoadingOverlayComponent open={open} />}
    </Dialog>
  );
};

const CardComponent = ({
  media,
  buildingName,
  clientProperty,
  isShortListed,
  postalCode,
  badges,
  pivot,
  topFinFlag,
}) => {
  const categories = ['Transport', 'Building', 'ESG', 'Custom Criteria', 'Location & Amenity', 'Deliverability & Risk'];
  const heightList = { 8: 486, 7: 453, 6: 420, 5: 386, 4: 386, 3: 386, 2: 353, 1: 320 };
  const badgeStyle = {
    display: 'flex',
    backgroundColor: '#DFE6E5',
    padding: '5px 7px',
    alignItems: 'center',
    borderRadius: 5,
    marginRight: 7,
    marginBottom: 7,
  };
  let heroImage, heroObj;
  if (media?.length) {
    heroObj = media.find((e) => e.PRIMARY_IMAGE_F == 'Y');
    heroImage = heroObj?.MEDIA_PATH;
  }
  if (heroObj?.MEDIA_PATH?.toLowerCase().includes('.pdf') || !heroObj) {
    heroImage = FallbackImg;
  }
  const getTitle = (str = '') => {
    let number = clientProperty ? 10 : 16;
    let result = str.length > number ? str.slice(0, number) + '...' : str;
    return result;
  };
  const TagPropertyComponent = () => {
    if (clientProperty === 'CURRENT_SITE') {
      return <CurrentIcon style={{ alignItems: 'center', display: 'flex', marginTop: 2 }} />;
    } else if (clientProperty === 'OFF_MARKET') {
      return <OffmarketIcon style={{ alignItems: 'center', display: 'flex', marginTop: 2 }} />;
    } else if (clientProperty === 'UNDER_OFFER') {
      return <UnderofferIcon style={{ alignItems: 'center', display: 'flex', marginTop: 2 }} />;
    } else {
      return <></>;
    }
  };
  let height = heightList[pivot];
  if (topFinFlag) {
    // height += 33;
  }
  return (
    <Card
      style={{
        height,
        padding: 0,
        borderRadius: 5,
        margin: '0 10px',
      }}
    >
      <CardMedia image={heroImage} src={heroImage} alt={buildingName} component='img' height='217 px' />
      <CardContent style={{ padding: 0 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            padding: '0px 18px',
            height: '20px',
            marginTop: '5px',
            whiteSpace: 'nowrap',
          }}
        >
          <CustomTypography
            text={getTitle(buildingName)}
            fontsize='20px'
            fontweight='400'
            fontcolor='#1A1A1A'
            lineheight='24px'
          />
          &nbsp;
          {isShortListed === 'Short' ? (
            <StarSelected
              width='17.11px'
              height='16.36px'
              style={{
                marginTop: '3px',
                marginLeft: '5px',
              }}
            />
          ) : (
            <StarNotSelected
              width='17.11px'
              height='16.36px'
              style={{
                marginTop: '3px',
                marginLeft: '5px',
              }}
            />
          )}
          &nbsp;
          {TagPropertyComponent()}
        </div>
        <span
          style={{
            display: 'inline-block',
            fontSize: '14px',
            color: 'rgba(26, 26, 26, 0.6)',
            margin: '5px 18px 0px 18px',
          }}
        >
          {postalCode}
        </span>
        <Grid style={{ display: 'flex', flexWrap: 'wrap', margin: 10 }}>
          {badges.topRanked && (
            <Grid style={badgeStyle}>
              <TopRanked color={badges.topRanked} />
              <CustomTypography
                text='Top ranked'
                fontsize='12px'
                fontweight='400'
                fontcolor='#1A1A1A'
                paddingLeft={5}
              />
            </Grid>
          )}
          {categories
            .filter((elm) => badges[elm])
            .map((el) => (
              <Grid style={badgeStyle}>
                <Trophy />
                <CustomTypography
                  text={`Best - ${el}`}
                  fontsize='12px'
                  fontweight='400'
                  fontcolor='#1A1A1A'
                  paddingLeft={5}
                />
              </Grid>
            ))}
          {badges.topFinancial && (
            <Grid style={badgeStyle}>
              <TopFinancial />
              <CustomTypography
                text='Best financials'
                fontsize='12px'
                fontweight='400'
                fontcolor='#1A1A1A'
                paddingLeft={5}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

const QuickCompare = ({ score, rank, spaceAvailable, rent, highScoreQuick, buildingName = '' }) => (
  <>
    <div className='property-values' style={{ position: 'relative' }}>
      {/* {highScoreQuick?.find((el) => el === buildingName) && (
        <img src={Meter} alt='Rating' width='66px' className='rating' />
      )} */}
      <span style={{ ...attributeValueScore }}>{score ? Math.round(score) : '-'}</span>
    </div>
    <div className='property-values'>
      <span style={{ ...attributeValueScore }}>{rank ? rank : '-'}</span>
    </div>
    <div className='property-values'>
      <span style={{ ...attributeValueScore }}>{spaceAvailable ? CurrencyFormatter(spaceAvailable) : '-'}</span>
    </div>
    <div className='property-values'>
      <span style={{ ...attributeValueScore }}>{rent ? '£' + CurrencyFormatter(rent.toFixed(2)) : '-'}</span>
    </div>
  </>
);

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontFamily: 'Calibre-R',
      fontSize: '14px',
      fontWeight: 400,
    },
  })
);

const Transport = ({
  score,
  nearestTube,
  nearestTrainStation,
  nearestElizabethLine,
  tubesWithInKM,
  allowedLabels,
  tramsWithInKM,
  nearestTram,
  regionalTrain,
  buildingName,
  highTransportScore,
  badges = {},
  bottomFlags = {},
}) => (
  <>
    <div className='property-values' style={{ position: 'relative' }}>
      <span style={{ ...attributeValue }}>{score ? Math.round(score) : '-'}</span>
      {!(bottomFlags.transport === badges['Transport']) && (
        <div className='rating'>
          {badges.Transport && <FireIcon />}
          {bottomFlags.transport && <DownIcon />}
        </div>
      )}
    </div>
    {allowedLabels.includes('NEAREST_TUBE_IN_METER') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{nearestTube ? nearestTube : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('NEAREST_MAIN_TRAIN_STATION_IN_KM') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{nearestTrainStation ? nearestTrainStation : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('NEAREST_ELIZABETH_LINE_STATION_IN_KM') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{nearestElizabethLine ? nearestElizabethLine : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('TUBES_WITHIN_1_KM') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{tubesWithInKM ? tubesWithInKM : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('NEAREST_MAIN_RTRAIN_STATION_IN_KM') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{regionalTrain ? regionalTrain : '-'}</span>
      </div>
    )}
    {/* {allowedLabels.includes('NEAREST_TRAM_IN_METER') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{tramsWithInKM ? tramsWithInKM : '-'}</span>
      </div>
    )} */}
    {allowedLabels.includes('NEAREST_TRAM_IN_METER') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{nearestTram ? nearestTram : '-'}</span>
      </div>
    )}
  </>
);

const Building = ({
  score,
  dedicatedGym,
  cateringAvailable,
  terraces,
  lookAndFeel,
  allowedLabels,
  brandingRights,
  lockers,
  floorPlateSize,
  floorToCeilingHeight,
  occupationalDensity,
  columns,
  naturalLight,
  onSiteAmenity,
  onSiteShowerFacility,
  selfContained,
  wiredscoreRating,
  buildingTechnology,
  outdoorSpace,
  coreDelivery,
  airConditioning,
  raisedFloors,
  roofTerrace,
  carParkingSpaces,
  carParkingRatio,
  viewed,
  floor,
  totalCostPerSFT,
  totAnnualCost,
  endOfTripFacilities,
  totCostofOccupancy,
  multiLetNSelfContained,
  singleLetBuilding,
  buildingName = '',
  highBuildingScore,
  badges = {},
  bottomFlags = {},
}) => (
  <>
    <div className='property-values' style={{ position: 'relative' }}>
      <span style={{ ...attributeValue }}>{score ? Math.round(score) : '-'}</span>
      {!(bottomFlags.building === badges.Building) && (
        <div className='rating'>
          {badges.Building && <FireIcon />}
          {bottomFlags.building && <DownIcon />}
        </div>
      )}
    </div>
    {allowedLabels.includes('BI_ON_SITE_DEDICATED_GYM') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{dedicatedGym ? dedicatedGym : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_ON_SITE_CATERING_AVAILABLE') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{cateringAvailable ? cateringAvailable : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_TERRACES') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{terraces ? terraces : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_LOOK_AND_FEEL') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{lookAndFeel && lookAndFeel !== '0' ? lookAndFeel : '-'}</span>
      </div>
    )}

    {allowedLabels.includes('BI_BRANDING_RIGHTS') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{brandingRights && brandingRights !== '0' ? brandingRights : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_LOCKERS') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{lockers ? lockers : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_FLOOR_PLATE_SIZE') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{floorPlateSize ? floorPlateSize : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_FLOOR_TO_CEILING_HEIGHT') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{floorToCeilingHeight ? floorToCeilingHeight : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_OCCUPATIONAL_DENSITYT') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{occupationalDensity ? occupationalDensity : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_COLUMNS') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>
          <LightTooltip
            enterNextDelay={100}
            enterDelay={100}
            title={columns ? columns : '-'}
            placement='top'
            followCursor
            style={{ margin: 0 }}
          >
            <span>
              {columns && columns !== '0' ? (columns.length > 11 ? columns.slice(0, 9) + '...' : columns) : '-'}
            </span>
          </LightTooltip>
        </span>
      </div>
    )}
    {allowedLabels.includes('BI_NATURAL_LIGHT') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{naturalLight && naturalLight !== '0' ? naturalLight : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_ON_SITE_AMENITY') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{onSiteAmenity ? onSiteAmenity : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_ON_SITE_SHOWER_FACILITIES') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{onSiteShowerFacility ? onSiteShowerFacility : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_SELF_CONTAINED') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{selfContained ? selfContained : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_WIREDSCORE_RATING') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>
          <LightTooltip
            enterNextDelay={100}
            enterDelay={100}
            title={wiredscoreRating ? wiredscoreRating : '-'}
            placement='top'
            followCursor
            style={{ margin: 0 }}
          >
            <span>
              {wiredscoreRating
                ? wiredscoreRating.length > 11
                  ? wiredscoreRating.slice(0, 9) + '...'
                  : wiredscoreRating
                : '-'}
            </span>
          </LightTooltip>
        </span>
      </div>
    )}
    {allowedLabels.includes('BI_BUILDING_TECHNOLOGY') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>
          <LightTooltip
            enterNextDelay={100}
            enterDelay={100}
            title={buildingTechnology ? buildingTechnology : '-'}
            placement='top'
            followCursor
            style={{ margin: 0 }}
          >
            <span>
              {buildingTechnology && buildingTechnology !== '0'
                ? buildingTechnology.length > 11
                  ? buildingTechnology.slice(0, 9) + '...'
                  : buildingTechnology
                : '-'}
            </span>
          </LightTooltip>
        </span>
      </div>
    )}
    {allowedLabels.includes('BI_OUTDOOR_SPACE') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{outdoorSpace ? outdoorSpace : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_SHELL_CORE_DELIVERY') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{coreDelivery ? coreDelivery : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_AIR_CONDITIONING') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{airConditioning ? airConditioning : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_RAISED_FLOORS') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{raisedFloors ? raisedFloors : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_ROOF_TERRACE') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{roofTerrace ? roofTerrace : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_CAR_PARKING_SPACES') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{carParkingSpaces ? carParkingSpaces : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_CAR_PARKING_RATIO') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{carParkingRatio ? carParkingRatio : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_VIEWED') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{viewed ? viewed : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_FLOOR') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{floor ? floor : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_TOTAL_COST_PER_SQ_FT') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{totalCostPerSFT ? totalCostPerSFT : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_TOTAL_ANNUAL_COST_PA') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{totAnnualCost ? totAnnualCost : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_END_OF_TRIP_FACILITIES') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{endOfTripFacilities ? endOfTripFacilities : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_TOTAL_COST_OF_OCCUPANCY') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{totCostofOccupancy ? totCostofOccupancy : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_MULTI_LET_AND_SELF_CONTAINED') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{multiLetNSelfContained ? multiLetNSelfContained : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('BI_SINGLE_LET_BUILDING') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{singleLetBuilding ? singleLetBuilding : '-'}</span>
      </div>
    )}
  </>
);

const LocationAmenity = ({
  score,
  transporthub,
  level,
  greenspace,
  safety,
  competitor,
  gymHotel,
  flexOptions,
  allowedLabels,
  buildingName = '',
  highLocationAmenityScore,
  badges = {},
  bottomFlags = {},
}) => (
  <>
    <div className='property-values' style={{ position: 'relative' }}>
      <span style={{ ...attributeValue }}>{score ? Math.round(score) : '-'}</span>
      {!(bottomFlags.location === badges['Location & Amenity']) && (
        <div className='rating'>
          {badges['Location & Amenity'] && <FireIcon />}
          {bottomFlags.location && <DownIcon />}
        </div>
      )}
    </div>
    {allowedLabels.includes('LA_TRANSPORT_HUB') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{transporthub ? transporthub : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('LA_LEVEL_LOCAL_AMENITY') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{level && level !== '0' ? level : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('LA_GREEN_SPACE') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{greenspace && greenspace !== '0' ? greenspace : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('LA_PERSONAL_SAFETY') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{safety && safety !== '0' ? safety : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('LA_PROX_CLIENT_COMPETITOR') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{competitor && competitor !== '0' ? competitor : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('LA_PROX_GYM_HOTEL') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{gymHotel && gymHotel !== '0' ? gymHotel : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('LA_FLEX_OPTIONS') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{flexOptions && flexOptions !== '0' ? flexOptions : '-'}</span>
      </div>
    )}
  </>
);

const ESG = ({
  score,
  BREEAMRating,
  nabersRating,
  EPCRating,
  allElectricBuilding,
  netCarbonZero,
  naturallyVentilated,
  wellRating,
  cycleSpaces,
  allowedLabels,
  highESGScore,
  buildingName = '',
  badges = {},
  bottomFlags = {},
}) => (
  <>
    <div className='property-values' style={{ position: 'relative' }}>
      <span style={{ ...attributeValue }}>{score ? Math.round(score) : '-'}</span>
      {!(bottomFlags.esg === badges['ESG']) && (
        <div className='rating'>
          {badges.ESG && <FireIcon />}
          {bottomFlags.esg && <DownIcon />}
        </div>
      )}
    </div>
    {allowedLabels.includes('ESG_BREEAM_RATING') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>
          <LightTooltip
            enterNextDelay={100}
            enterDelay={100}
            title={BREEAMRating ? BREEAMRating : '-'}
            placement='top'
            followCursor
            style={{ margin: 0 }}
          >
            <span>
              {BREEAMRating ? (BREEAMRating.length > 11 ? BREEAMRating.slice(0, 9) + '...' : BREEAMRating) : '-'}
            </span>
          </LightTooltip>
        </span>
      </div>
    )}
    {allowedLabels.includes('ESG_NABERS_RATING') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>
          <LightTooltip
            enterNextDelay={100}
            enterDelay={100}
            title={nabersRating ? nabersRating : '-'}
            placement='top'
            followCursor
            style={{ margin: 0 }}
          >
            <span>
              {nabersRating ? (nabersRating.length > 11 ? nabersRating.slice(0, 9) + '...' : nabersRating) : '-'}
            </span>
          </LightTooltip>
        </span>
      </div>
    )}
    {allowedLabels.includes('ESG_EPC_RATING') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>
          <LightTooltip
            enterNextDelay={100}
            enterDelay={100}
            title={EPCRating ? EPCRating : '-'}
            placement='top'
            followCursor
            style={{ margin: 0 }}
          >
            <span>{EPCRating ? (EPCRating.length > 11 ? EPCRating.slice(0, 9) + '...' : EPCRating) : '-'}</span>
          </LightTooltip>
        </span>
      </div>
    )}
    {allowedLabels.includes('ESG_ALL_ELECTRIC_BUILDING') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>
          {allElectricBuilding && allElectricBuilding !== '0' ? allElectricBuilding : '-'}
        </span>
      </div>
    )}
    {allowedLabels.includes('ESG_NET_CARBON_ZERO') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>
          <LightTooltip
            enterNextDelay={100}
            enterDelay={100}
            title={netCarbonZero ? netCarbonZero : '-'}
            placement='top'
            followCursor
            style={{ margin: 0 }}
          >
            <span>
              {netCarbonZero && netCarbonZero !== '0'
                ? netCarbonZero.length > 11
                  ? netCarbonZero.slice(0, 9) + '...'
                  : netCarbonZero
                : '-'}
            </span>
          </LightTooltip>
        </span>
      </div>
    )}
    {allowedLabels.includes('ESG_NATURALLY_VENTILATED') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>
          {naturallyVentilated && naturallyVentilated !== '0' ? naturallyVentilated : '-'}
        </span>
      </div>
    )}
    {allowedLabels.includes('ESG_WELL_RATING') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>
          <LightTooltip
            enterNextDelay={100}
            enterDelay={100}
            title={wellRating ? wellRating : '-'}
            placement='top'
            followCursor
            style={{ margin: 0 }}
          >
            <span>{wellRating ? (wellRating.length > 11 ? wellRating.slice(0, 9) + '...' : wellRating) : '-'}</span>
          </LightTooltip>
        </span>
      </div>
    )}
    {allowedLabels.includes('ESG_CYCLE_SPACES') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{cycleSpaces ? cycleSpaces : '-'}</span>
      </div>
    )}
  </>
);

const Deliverability = ({
  score,
  deliveryStatus,
  landlordCredibility,
  planningDeliveryStatus,
  targetDeliveryDate,
  landlordPropertyOpportunity,
  deliveryCondition,
  deliveryDate,
  allowedLabels,
  buildingName,
  highDeliverScore,
  badges = {},
  bottomFlags = {},
}) => (
  <>
    <div className='property-values' style={{ position: 'relative' }}>
      <span style={{ ...attributeValue }}>{score ? Math.round(score) : '-'}</span>
      {!(bottomFlags.deliverability === badges['Deliverability & Risk']) && (
        <div className='rating'>
          {badges['Deliverability & Risk'] && <FireIcon />}
          {bottomFlags.deliverability && <DownIcon />}
        </div>
      )}
    </div>
    {allowedLabels.includes('DR_DELIVERY_STATUS') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{deliveryStatus ? deliveryStatus : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('DR_LANDLORD_CREDIBILITY') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>
          {landlordCredibility && landlordCredibility !== '0' ? landlordCredibility : '-'}
        </span>
      </div>
    )}
    {allowedLabels.includes('DR_PLANNING_DELIVERY_STATUS') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{planningDeliveryStatus ? planningDeliveryStatus : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('DR_TARGET_DELIVERY_DATE') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{targetDeliveryDate ? targetDeliveryDate : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('DR_LANDLORD_PROPERTY_OPPORTUNITY') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{landlordPropertyOpportunity ? landlordPropertyOpportunity : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('DR_DELIVERY_CONDITION') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{deliveryCondition ? deliveryCondition : '-'}</span>
      </div>
    )}
    {allowedLabels.includes('DR_DELIVERY_DATE') && (
      <div className='property-values'>
        <span style={{ ...attributeValue }}>{deliveryDate ? deliveryDate : '-'}</span>
      </div>
    )}
  </>
);

const CustomCriteia = ({ score, customData, custWeightings, badges = {}, bottomFlags = {} }) => {
  const customSubKeys = Object.keys(customData ? customData : {});
  return (
    <>
      <div className='property-values' style={{ position: 'relative' }}>
        <span style={{ ...attributeValue }}>{score ? Math.round(score) : '-'}</span>
        {!(bottomFlags.custCriteria === badges['Custom Criteria']) && (
          <div className='rating'>
            {badges['Custom Criteria'] && <FireIcon />}
            {bottomFlags.custCriteria && <DownIcon />}
          </div>
        )}
      </div>
      {customSubKeys
        .filter((elm) => custWeightings.includes(elm))
        .map((el) => (
          <div className='property-values' key={el}>
            <span style={{ ...attributeValue }}>{customData[el] && customData[el] !== '0' ? customData[el] : '-'}</span>
          </div>
        ))}
    </>
  );
};

const Financials = ({ rent, businessRates, serviceCharge, estateCharge, allInCost, totalAnnualCost }) => (
  <>
    <div className='property-values'>
      <span style={{ ...attributeValue }}>{rent ? CurrencyFormatter(rent.toFixed(2)) : '-'}</span>
    </div>
    <div className='property-values'>
      <span style={{ ...attributeValue }}>{businessRates ? CurrencyFormatter(businessRates.toFixed(2)) : '-'}</span>
    </div>
    <div className='property-values'>
      <span style={{ ...attributeValue }}>{serviceCharge ? CurrencyFormatter(serviceCharge.toFixed(2)) : '-'}</span>
    </div>
    <div className='property-values'>
      <span style={{ ...attributeValue }}>{estateCharge ? CurrencyFormatter(estateCharge.toFixed(2)) : '-'}</span>
    </div>
    <div className='property-values'>
      <span style={{ ...attributeValue }}>{allInCost ? CurrencyFormatter(allInCost.toFixed(2)) : '-'}</span>
    </div>
    <div className='property-values'>
      <span style={{ ...attributeValue }}>
        {totalAnnualCost ? CurrencyFormatter(Math.round(totalAnnualCost)) : '-'}
      </span>
    </div>
  </>
);

const Score = ({
  scoreOne,
  scoreTwo,
  scoreThree,
  scoreFour,
  bgColor = '#F5F7F7',
  highScore,
  building1,
  building2,
  building3,
  building4,
  badge1,
  badge2,
  badge3,
  badge4,
  bottomFlag1,
  bottomFlag2,
  bottomFlag3,
  bottomFlag4,
}) => (
  <Grid
    container
    style={{
      display: 'flex',
      minHeight: 90,
    }}
  >
    <Grid
      md={2}
      style={{
        backgroundColor: bgColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 20,
        fontWeight: 500,
      }}
    >
      Category score
    </Grid>
    <Grid md={10} style={{ display: 'flex', alignItems: 'center' }}>
      <Grid item md={3}>
        {scoreOne && (
          <div className='property-values' style={{ position: 'relative' }}>
            <span style={{ ...attributeValueScore }}>{scoreOne ? Math.round(scoreOne) : '-'}</span>
            {!(badge1 === bottomFlag1) && (
              <div className='rating'>
                {badge1 && <FireIcon />}
                {bottomFlag1 && <DownIcon />}
              </div>
            )}
          </div>
        )}
      </Grid>
      <Grid item md={3}>
        {scoreTwo && (
          <div className='property-values' style={{ position: 'relative' }}>
            <span style={{ ...attributeValueScore }}>{scoreTwo ? Math.round(scoreTwo) : '-'}</span>
            {!(badge2 === bottomFlag2) && (
              <div className='rating'>
                {badge2 && <FireIcon />}
                {bottomFlag2 && <DownIcon />}
              </div>
            )}
          </div>
        )}
      </Grid>
      <Grid item md={3}>
        {scoreThree && (
          <div className='property-values' style={{ position: 'relative' }}>
            <span style={{ ...attributeValueScore }}>{scoreThree ? Math.round(scoreThree) : '-'}</span>
            {!(badge3 === bottomFlag3) && (
              <div className='rating'>
                {badge3 && <FireIcon />}
                {bottomFlag3 && <DownIcon />}
              </div>
            )}
          </div>
        )}
      </Grid>
      <Grid item md={3}>
        {scoreFour && (
          <div className='property-values' style={{ position: 'relative' }}>
            <span style={{ ...attributeValueScore }}>{scoreFour ? Math.round(scoreFour) : '-'}</span>
            {!(badge4 === bottomFlag4) && (
              <div className='rating'>
                {badge4 && <FireIcon />}
                {bottomFlag4 && <DownIcon />}
              </div>
            )}
          </div>
        )}
      </Grid>
    </Grid>
  </Grid>
);

export default CompareProperties;