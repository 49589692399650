import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import RightBottomBarChart from '../rightBottomBarChart';

const IndividualBarChart = (props) => {
  const [criteria, setCriteria] = useState([]);
  const [scoreList, setScoreList] = useState([]);

  useEffect(() => {
    if (props.subCategoryScore) {
      let item = props.subCategoryScore;
      let criteriaList = [];
      let scoreData = [];
      Object.entries(item).forEach(([key, value], index) => {
        if (value) {
          let labelName = '';
          switch (key) {
            case 'TRANSPORT_SCORE':
              labelName = 'Transport';
              break;
            case 'LOCAL_AMENITY_SCORE':
              labelName = 'Location & Amenity';
              break;
            case 'BUILDING_SCORE':
              labelName = 'Building';
              break;
            case 'ESG_SCORE':
              labelName = 'ESG';
              break;
            case 'DELIVERABILITY_RISK_SCORE':
              labelName = 'Deliverability & Risk';
              break;

            case 'TR_PROXIMITY_NEAREST_TUBE_STATION_SCORE':
              labelName = 'Proximity to nearest tube station';
              break;
            case 'TR_PROXIMITY_MAINLINE_TRAIN_STATION_SCORE':
              labelName = 'Proximity to mainline train station';
              break;
            case 'TR_PROXIMITY_ELIZABETH_LINE_SCORE':
              labelName = 'Proximity to Elizabeth Line';
              break;
            case 'TR_TUBES_WITHIN_1KM_SCORE':
              labelName = 'Tube lines within 1km';
              break;
            case 'TR_PROXIMITY_REGIONAL_TRAIN_STATION_SCORE':
              labelName = 'REGIONAL - Proximity to mainline train station';
              break;
            case 'TR_PROXIMITY_REGIONAL_TRAM_STATION_SCORE':
              labelName = 'REGIONAL - Proximity to tram station';
              break;
            case 'BI_ON_SITE_DEDICATED_GYM_SCORE':
              labelName = 'On-site Dedicated Gym';
              break;
            case 'BI_ON_SITE_CATERING_AVAILABLE_SCORE':
              labelName = 'On-site Catering Available';
              break;
            case 'BI_TERRACES_SCORE':
              labelName = 'Terraces';
              break;
            case 'BI_FLOOR_SIZE_SCORE':
              labelName = 'Floor plate suitability';
              break;
            case 'BI_LOOK_AND_FEEL_SCORE':
              labelName = 'Look and Feel';
              break;
            case 'BI_FLOOR_TO_CEILING_HEIGHT_SCORE':
              labelName = 'Floor to ceiling height';
              break;
            case 'BI_OCCUPATIONAL_DENSITY_SCORE':
              labelName = 'Occupational Density';
              break;
            case 'BI_COLUMNS_SCORE':
              labelName = 'Columns';
              break;
            case 'BI_NATURAL_LIGHT_SCORE':
              labelName = 'Natural Light';
              break;
            case 'BI_ON_SITE_AMENITY_SCORE':
              labelName = 'On-site Amenity';
              break;
            case 'BI_ON_SITE_SHOWER_FACILITIES_SCORE':
              labelName = 'On-site shower facilities';
              break;
            case 'BI_SELF_CONTAINED_SCORE':
              labelName = 'Self-contained';
              break;
            case 'BI_WIREDSCORE_RATING_SCORE':
              labelName = 'WiredScore Rating';
              break;
            case 'BI_BUILDING_TECHNOLOGY_SCORE':
              labelName = 'Building Technology';
              break;
            case 'BI_BRANDING_RIGHTS_SCORE':
              labelName = 'Branding Rights';
              break;
            case 'BI_AIR_CONDITIONING_SCORE':
              labelName = 'Air Conditioning';
              break;
            case 'BI_RAISED_FLOORS_SCORE':
              labelName = 'Raised Floors';
              break;
            case 'BI_CAR_PARKING_SCORE':
              labelName = 'Car Park Spaces';
              break;
            case 'BI_LOCKERS_SCORE':
              labelName = 'Lockers';
              break;
            case 'BI_SINGLE_LET_BUILDING_SCORE':
              labelName = 'Single let Building';
              break;
            case 'LA_TRANSPORT_HUB_SCORE':
              labelName = 'Proximity to transport hubs';
              break;
            case 'LA_LEVEL_LOCAL_AMENITY_SCORE':
              labelName = 'Level of local amenity';
              break;
            case 'LA_GREEN_SPACE_SCORE':
              labelName = 'Green Spaces';
              break;
            case 'LA_PERSONAL_SAFETY_SCORE':
              labelName = 'Personal safety';
              break;
            case 'LA_PROX_GYM_HOTEL_SCORE':
              labelName = 'Proximity to gyms/hotels';
              break;
            case 'LA_PROX_CLIENT_COMPETITOR_SCORE':
              labelName = 'Proximity to Clients/Competitors';
              break;
            case 'LA_FLEX_OPTIONS_SCORE':
              labelName = 'Flex options in the immediate area';
              break;
            case 'ESG_BREEAM_RATING_SCORE':
              labelName = 'BREEAM Rating';
              break;
            case 'ESG_NABERS_RATING_SCORE':
              labelName = 'Nabers Rating';
              break;
            case 'ESG_EPC_RATING_SCORE':
              labelName = 'EPC Rating';
              break;
            case 'ESG_ALL_ELECTRIC_BUILDING_SCORE':
              labelName = 'All-Electric Building';
              break;
            case 'ESG_NET_CARBON_ZERO_SCORE':
              labelName = 'Net Carbon Zero';
              break;
            case 'ESG_NATURALLY_VENTILATED_SCORE':
              labelName = 'Naturally Ventilated';
              break;
            case 'ESG_WELL_RATING_SCORE':
              labelName = 'WELL Rating';
              break;
            case 'ESG_CYCLE_SPACE_SCORE':
              labelName = 'Cycle Spaces';
              break;
            case 'DR_DELIVERY_DATE_SCORE':
              labelName = 'Delivery status';
              break;
            case 'DR_LANDLORD_CREDIBILITY_SCORE':
              labelName = 'Landlord Credibility';
              break;
            default:
              labelName = key;
              break;
          }
          let str =
            `${index + 1}` +
            '\u00A0' +
            '\u00A0' +
            '\u00A0' +
            '\u00A0' +
            '\u00A0' +
            `${index < 9 ? '\u00A0' + '\u00A0' : ''}` +
            `${labelName}`;
          criteriaList.push(str);
          if (value < 1) {
            scoreData.push(1);
          } else {
            scoreData.push(value);
          }
        }
      });
      let stackBarChartScoring = [
        {
          data: scoreData,
          color: '#1F3765',
          name: 'Score',
        },
      ];
      setScoreList(stackBarChartScoring);
      setCriteria(criteriaList);
    }
  }, [props.subCategoryScore]);

  return (
    <Grid
      style={{
        height: '35vh',
        overflowY: 'scroll',
      }}
    >
      <RightBottomBarChart
        scoreList={scoreList}
        criteria={criteria}
        ht={criteria.length <= 10 ? 250 : criteria.length * 20}
      />
    </Grid>
  );
};
export default IndividualBarChart;
