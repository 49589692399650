export const getCapitalized = (str) => {
  if (!str) return '';
  return str
    .split(' ')
    .map((el) => el.charAt(0).toUpperCase() + el.slice(1))
    .join(' ');
};

export const replaceQuote = (el) => el.map((el) => el.id.replace("'", "''''"));

export const getFormattedValue = (str) => {
  const strng = str
    .toLowerCase()
    .split(' ')
    .map((el) => el.charAt(0).toUpperCase() + el.slice(1))
    .join(' ');
  return strng.length > 17 ? strng?.slice(0, 16) + '...' : strng;
};

export const getObject = (str) => {
  if (!str) return {};
  let temp = {};
  str
    .split(';')
    .map((el) => el.split('-'))
    .map((el) => ({
      [el[0]]: Number(el[1]),
    }))
    .forEach((e) => {
      temp = {
        ...temp,
        ...e,
      };
    });
  return temp;
};