import React from "react";

function CheckedRadioIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 25 25"
        >
            <path
                fill="#003F2D"
                fillRule="evenodd"
                d="M12.938 2.5c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-5-8a5 5 0 1110 0 5 5 0 01-10 0z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default CheckedRadioIcon;
