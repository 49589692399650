import React from 'react';

function FilterIcon({ color = '#1A1A1A', width = 20, height = 20, props }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 16 16'
      {...props}
    >
      <path
        fill={color}
        d='M.25 1.61C2.57 4.59 6 9 6 9v5c0 1.1.9 2 2 2s2-.9 2-2V9s3.43-4.41 5.75-7.39c.51-.66.04-1.61-.8-1.61H1.04C.21 0-.26.95.25 1.61z'
      ></path>
    </svg>
  );
}

export default FilterIcon;
