import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import PluseIcon from '../icons/pluse_icon';
import Checkboxcomponent from './custom_checkbox';
import CloseFillIcon from '../icons/close_fill_icon';
import ClearTextIcon from '../icons/clear_text';
const useAutoCompliteStyles = makeStyles(() => ({
  root: {
    '&:hover': {
      borderColor: '#c4c4c4',
    },
    '&$focused ': {
      borderColor: '#c4c4c4',
    },
    '&.Mui-error': {
      borderColor: '#AB1212',
    },
  },
  focused: {},
  notchedOutline: {},
}));
const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    '&:-webkit-autofill': {
      backgroundColor: '#FFFFFF !important;',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1a1a1a',
      },
      '&:hover fieldset': {
        borderColor: '#205747',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#205747',
      },
    },
  },
  focused: {},
  notchedOutline: {
    '&.MuiOutlinedInput-root.Mui-error': {
      borderColor: '#AB1212',
    },
  },
  inputPlaceholderColor: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        color: '#1a1a1a',
      },
      '&:hover fieldset': {
        color: '#205747',
      },
      '&.Mui-focused fieldset': {
        color: '#205747',
      },
    },
  },
}));
const AutocompleteComponent = ({
  data,
  title,
  inputPlaceholder,
  handleChange,
  selectedValue,
  disabled = false,
  limitTags = 4,
  valueKey = 'address',
  setFlag = () => { },
}) => {
  const autoCompliteStyles = useAutoCompliteStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const [selectedList, setSelectedList] = useState([]);
  const isArraysEqual = (a, b) => a.id === b.id;
  const onlyInLeft = (left, right, compareFunction) =>
    left.filter(
      (leftValue) =>
        !right.some((rightValue) => compareFunction(leftValue, rightValue))
    );
  const handlechangeSelect = (event, value) => {
    var listarray = [];
    const onlyInselectedList = onlyInLeft(selectedList, value, isArraysEqual);
    const onlyInvalue = onlyInLeft(value, selectedList, isArraysEqual);
    if (onlyInselectedList.length > onlyInvalue.length) {
      if (
        onlyInselectedList.length > 0 ? onlyInselectedList[0].id == 0 : false
      ) {
      } else {
        var array = value.filter((e) => e.id != 0);
        listarray = array;
      }
    } else {
      if (onlyInvalue.length > 0 && onlyInvalue[0].id == 0) {
        listarray = data;
      } else {
        if (valueKey == 'EPC') {
          var array = value.filter((e) => e.id != 0);
          listarray = array;
        } else if (valueKey == 'Benchmarking') {
          if (value.length == data.length) {
            listarray = data;
          } else {
            var array = value.filter((e) => e.id != 0);
            listarray = array;
          }
        } else {
          if (value.length == data.length - 1) {
            listarray = data;
          } else {
            var array = value.filter((e) => e.id != 0);
            listarray = array;
          }
        }
      }
    }
    setSelectedList(listarray);
    handleChange(listarray);
  };
  const handleDelete = (id) => {
    let data = [...selectedList];
    if (id != 0) {
      let filterList = data.filter((e) => e.id != id && e.id != 0);
      setSelectedList(filterList);
      handleChange(filterList);
    } else {
      setSelectedList([]);
      handleChange([]);
    }
  };
  useEffect(() => {
    setSelectedList(selectedValue);
    handleChange(selectedValue);
  }, [selectedValue]);
  return (
    <Autocomplete
      value={selectedList}
      multiple
      limitTags={limitTags}
      id={`checkboxes-tags-demo${title}`}
      disableCloseOnSelect
      disabled={disabled}
      options={data}
      popupIcon={<PluseIcon color='#1a1a1a99' style={{ marginRight: 12 }} />}
      clearIcon={<ClearTextIcon style={{ marginRight: 13 }} />}
      onChange={(event, value) => {
        handlechangeSelect(event, value);
      }}
      getOptionLabel={(option) => option.title}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          if (option.title !== 'All') {
            return (
              <Chip
                key={option.id}
                style={{ backgroundColor: '#e6ecea', paddingRight: 6 }}
                size='small'
                label={option.title}
                onDelete={() => {
                  handleDelete(option.id);
                }}
                onClick={() => {
                  handleDelete(option.id);
                }}
                deleteIcon={<CloseFillIcon />}
              />
            );
          }
        })
      }
      isOptionEqualToValue={(option, value) => option.title === value.title}
      renderOption={(props, option, { selected = false }) => (
        <li {...props}>
          <Checkboxcomponent
            id={option.title}
            label={option.title}
            checked={selected}
          />
        </li>
      )}
      renderInput={(params) => {
        return (
          <TextField
            style={{
              borderRadius: 4,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'white',
            }}
            {...params}
            variant='outlined'
            className={outlinedInputClasses.root}
            label={title}
            placeholder={selectedList.length == 0 ? inputPlaceholder : ''}
            InputLabelProps={{
              shrink: true,
              style: {
                color: '#1A1A1A',
                fontSize: '20px',
                marginTop: '0px',
                paddingRight: '16px',
                lineHeight: '24px',
                fontFamily: 'Calibre-R',
              },
            }}
            InputProps={{
              classes: {
                root: autoCompliteStyles.root,
                focused: autoCompliteStyles.focused,
                notchedOutline: autoCompliteStyles.notchedOutline,
              },
              ...params.InputProps,
              endAdornment: (
                <div style={{ display: 'flex' }}>
                  {(!!selectedList.length &&
                    params.InputProps.endAdornment.props.children[1].props[
                    'aria-label'
                    ] === 'Close') ||
                    (!!selectedList.length &&
                      params.InputProps.endAdornment.props.ownerState.focused) ? (
                    <>
                      <span
                        style={{
                          position: 'absolute',
                          right: '10px',
                          bottom: '-2px',
                          cursor: 'pointer',
                          fontSize: '14px',
                          color: '#003F2D',
                        }}
                        onClick={() => {
                          params.InputProps.endAdornment?.props?.children[0]?.props?.onClick();
                        }}
                      >
                        Clear
                      </span>
                      {{
                        ...params.InputProps.endAdornment,
                        props: {
                          ...params.InputProps.endAdornment.props,
                          children:
                            params.InputProps.endAdornment.props.children.slice(
                              1
                            ),
                        },
                      }}
                    </>
                  ) : (
                    <>{params.InputProps.endAdornment}</>
                  )}
                </div>
              ),
              type: 'search',
            }}
          />
        );
      }}
    />
  );
};
export default AutocompleteComponent;
