import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  memo,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import $ from 'jquery';
import { Button, Grid, makeStyles } from '@material-ui/core';
import Searchicon from '../icons/search_icon';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import _ from 'lodash';
import CustomTypography from '../utils/text';
import CurrencyFormatter from './currency_formatter';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    '&:-webkit-autofill': {
      backgroundColor: '#FFFFFF !important;',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1a1a1a',
      },
      '&:hover fieldset': {
        borderColor: '#003F2D',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#003F2D',
      },
      '&.Mui-error fieldset': {
        borderColor: '#003F2D',
      },
    },
  },
  focused: {},
  notchedOutline: {
    '&.MuiOutlinedInput-root.Mui-error': {
      borderColor: '#003F2D',
    },
  },
  inputPlaceholderColor: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        color: '#003F2D !important',
      },
      '&:hover fieldset': {
        color: '#003F2D',
      },
      '&.Mui-focused fieldset': {
        color: '#003F2D',
      },
    },
  },
}));
const InfinateTableComponent = memo(
  ({ data, handleAddProperty, isLoading }) => {
    const gridRef = useRef();
    const dispatch = useDispatch();
    const searchValue = useSelector(
      (state) => state.mainReducer.searchValueinAddProp
    );
    const containerStyle = useMemo(
      () => ({ width: '100%', height: '90%' }),
      []
    );
    const gridStyle = useMemo(() => ({ height: '90%', width: '100%' }), []);
    const [search, setSearch] = useState('');
    const [rowData, setRowData] = useState([]);
    const [searchedArray, setSearchedArray] = useState([]);
    const outlinedInputClasses = useOutlinedInputStyles();
    const myRefs = useRef([]);
    useEffect(() => {
      setRowData(data);
    }, [data, gridRef]);
    const columnWidth = (percentage) => {
      var div = $('#' + 'myGrid');
      var parent = div.parent();
      var width = (parent.width() * percentage) / 100;
      return width;
    };
    useEffect(() => {
      handlesearchArray(searchValue);
    }, [rowData, searchValue]);
    const defaultColDef = useMemo(() => {
      return {
        flex: 1,
        sortable: true,
        tooltipComponent: ({ value }) => (
          <div
            style={{
              backgroundColor: 'white',
              color: 'rgba(0, 0, 0, 0.87)',
              boxShadow: 'rgba(0, 0, 0, 0.3) 0 2px 10px',
              padding: '7px 10px',
              borderRadius: '3px',
              fontFamily: 'Calibre-R',
              fontSize: '14px',
              fontWeight: 400,
            }}
          >
            {value}
          </div>
        ),
      };
    }, []);
    const BtnCellRenderer = (props) => {
      return (
        <Button
          ref={(el) => (myRefs.current[props.data.id] = el)}
          style={{
            textTransform: 'inherit',
            backgroundColor: props.data.ACTIVE ? '#003F2D' : '#d4d7d7',
            borderRadius: 4,
            width: 65,
            height: 30,
            marginBottom: 6,
          }}
          onClick={() => btnClickedHandler(props.data)}
          disabled={!props.data.ACTIVE}
        >
          <CustomTypography
            text='Select'
            fontfamily='Calibre-R'
            fontsize='16px'
            fontweight='500'
            fontcolor='#FFFFFF'
            lineheight='16px'
          />
        </Button>
      );
    };
    const [columnDefs, setColumnDefs] = useState([
      {
        field: 'BUILDING_NAME',
        headerName: 'Building name',
        resizable: true,
        width: columnWidth(30),
        tooltipField: 'BUILDING_NAME',
      },
      {
        field: 'CALCULATED_ADDRESS',
        headerName: 'Address',
        resizable: true,
        tooltipField: 'CALCULATED_ADDRESS',
        width: columnWidth(30),
      },
      {
        field: 'POSTAL_CODE',
        headerName: 'Postcode',
        resizable: true,
        width: columnWidth(25),
      },
      {
        field: 'AVAILABLE_SPACE_SQFT',
        headerName: 'Availability',
        valueFormatter: (params) => CurrencyFormatter(params.value, '$'),
        resizable: true,
        width: columnWidth(18),
      },
      {
        field: 'AVAILABLE_FROM',
        headerName: 'Available from',
        resizable: true,
        width: columnWidth(12),
      },
      {
        field: 'ACTIVE',
        headerName: 'Action',
        maxWidth: 110,
        minWidth: 110,
        cellRenderer: BtnCellRenderer,
      },
    ]);
    const handlesearchArray = useCallback(
      (searchValue) => {
        setSearch(searchValue);
        dispatch({
          type: 'SET_SEARCH_VALUE_ADD_PROPERTY',
          payload: searchValue,
        });
        if (rowData?.length > 0) {
          var searchArray = _.filter(
            rowData,
            (p) =>
              String(p['BUILDING_NAME'])
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              String(p['CALCULATED_ADDRESS'])
                .toLowerCase()
                .includes(String(searchValue).toLowerCase()) ||
              String(p['POSTAL_CODE'])
                .toLowerCase()
                .includes(String(searchValue).toLowerCase())
          );

          setSearchedArray(searchArray);
        }
      },
      [rowData]
    );
    const getRowId = useMemo(() => {
      return (params) => params.data.id;
    }, []);
    const btnClickedHandler = useCallback((value) => {
      if (!myRefs.current[value.id].disabled) {
        myRefs.current[value.id].setAttribute('disabled', 'disabled');
        if (value.ACTIVE) {
          handleAddProperty(value, gridRef?.current?.props?.sourceData);
        }
      }
    }, []);
    return (
      <div style={containerStyle} id={'myGrid'}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            backgroundColor: '#FFFFFF',
            alignItems: 'center',
            paddingBottom: 10,
            paddingTop: 30,
          }}
        >
          <TextField
            data-qaid='inputtext_outlinedinput'
            variant='outlined'
            onChange={(event) => handlesearchArray(event.target.value)}
            label={'Search properties'}
            type={'text'}
            value={search}
            style={{ width: '100%', height: 48, backgroundColor: 'white' }}
            className={outlinedInputClasses.root}
            spellCheck={true}
            InputLabelProps={{
              style: {
                color: '#1A1A1A',
                fontSize: '16px ',
                marginTop: 0,
                lineHeight: '21px ',
                fontFamily: 'Calibre-R ',
              },
            }}
            inputProps={{
              spellCheck: true,
              lang: 'en',
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
            }}
            aria-describedby={`search-text`}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Searchicon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {!isLoading ? (
          <div style={gridStyle} className='ag-theme-alpine'>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={searchedArray}
              sourceData={rowData}
              getRowId={getRowId}
              getRowNodeId={(data) => data.id}
              suppressDragLeaveHidesColumns={true}
              suppressPaginationPanel={true}
              suppressScrollOnNewData={true}
              suppressAggFuncInHeader={true}
              suppressMaintainUnsortedOrder={true}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              overlayNoRowsTemplate={` <span style="display:flex; flex-direction: column">
                        <i class="fa fa-circle-o-notch fa-spin" style="font-size:24px"></i>
                        <span style="padding: 10px; background: white">
                        ${
                          search == '' && !searchedArray.length
                            ? 'Loading properties...'
                            : 'No properties found...'
                        }
                        </span>
                        </span>`}
            />
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              height: '70%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <CircularProgress
              style={{
                color: !search ? '#fff' : '#003F2D',
              }}
              size={36}
            />
            <CustomTypography
              text={!search ? 'Search properties' : 'Searching properties...'}
              fontsize='13px'
              fontweight='normal'
              lineheight='15px'
              fontcolor='#181d1f'
              paddingTop='10px'
            />
          </div>
        )}
      </div>
    );
  }
);

export default InfinateTableComponent;
