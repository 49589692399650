import React, { useEffect, useState, useMemo, useCallback } from 'react';
import CustomTypography from '../utils/text';
import { Grid, Button, makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Stack from '@mui/material/Stack';
import VerticalLineIcon from '../icons/verticalline';
import { useSelector, useDispatch } from 'react-redux';
import FilterIcon from '../icons/filter_icon';
import BenchMarkingFilter from '../components/benchMarkingFilter';
import LoadingOverlayComponent from '../components/lodding_component';
import WarningMessage from '../components/warning_snackbar';
import MultiSelectDropDown from '../components/multi_select_dropdown';
import CustomBarComponent from '../components/custom_bar_component';
import CustomTimeBarComponent from '../components/customTimeLineCharts';
import CustomeTimeLineMedianChart from '../components/customTimeLineMedianChart';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import PropertyScoreBar from '../components/custom_score_bar';
import _ from 'lodash';
import BoxPlot from 'react-plotly.js';
import { DensityChartWithAxis } from '../components/DensityPlot';
import * as d3 from 'd3';
import { Buffer } from 'buffer';
import ButtonSwitch from '../components/custome_switch';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import NoDataIcon from '../icons/no_annual_cost_icon';
import { MdOutlineSearch } from 'react-icons/md';
import FallbackImg from '../img/SingleProperty.png';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { BM_OPTIONS, BM_GLOBALWEIGHTINGS, BM_GLOBALSCORES, BM_SP_OPTIONS } from '../utils/constants';
import SelectProperty from '../components/SelectProperty';
import SnackBar from '../components/SnackBar';
import SinglePropFilter from '../components/Benchmarking/SinglePropFilter';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontFamily: 'Calibre-R',
    fontWeight: 'bold',
    backgroundColor: 'inherit !important',
    fontSize: '14px',
    lineHeight: '21px',
  },
}));

const Benchmarking = () => {
  const hubUserKey = useSelector((state) => state.mainReducer.hubUser);
  const dispatch = useDispatch();
  const [toggleButtonIndex, setToggleButtonIndex] = useState(1);
  const [projectObject, setProjectObject] = useState({});
  const [OpenFilter, setOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [filterCount, setFilterCount] = useState(0);
  const [benchMarkingFilterObject, setBenchMarkingFilterObject] = useState({});
  const [showWeightingsOutliers, setShowWeightingsOutliers] = useState(false);
  const [showScoresOutliers, setShowScoresOutliers] = useState(false);
  const [weightingsD3, setWeightingsD3] = useState(BM_GLOBALWEIGHTINGS);
  const [scoresD3, setScoresD3] = useState(BM_GLOBALSCORES);
  const [globalWeightings, setGlobalWeightings] = useState(BM_GLOBALWEIGHTINGS);
  const [globalScores, setGlobalScores] = useState(BM_GLOBALSCORES);
  const [options, setOptions] = useState(BM_OPTIONS);
  const [spOptions, setSpOptions] = useState(BM_SP_OPTIONS);
  const [mostActiveAreasData, setMostActiveAreasData] = useState([]);
  const [mostActiveAreassortBy, setMostActiveAreassortBy] = useState('Shortlisting events');
  const [mostActiveIndustriesData, setMostActiveIndustriesData] = useState([]);
  const [mostActiveIndustriessortBy, setMostActiveIndustriessortBy] = useState('Shortlisting events');
  const [mostPopularTransportHubsData, setMostPopularTransportHubsData] = useState([]);
  const [mostPopularTransportHubssortBy, setMostPopularTransportHubssortBy] = useState('Shortlisting events');
  const [topPropertiesByScoreData, setTopPropertiesByScoreData] = useState([]);
  const [topPropertiesByScoresortBy, setTopPropertiesByScoreHubssortBy] = useState('Median score');
  const [topPropertiesByValueInPoundData, setTopPropertiesByValueInPoundData] = useState([]);
  const [topPropertiesByValueInPoundsortBy, setTopPropertiesByValueInPoundsortBy] = useState('Value in points/£');
  const [topMostInfluentialScoringCriteriaData, setTopMostInfluentialScoringCriteriaData] = useState([]);
  const [weightingsOverTimeData, setWeightingsOverTimeData] = useState([]);
  const [scoreOverTimeData, setScoreOverTimeData] = useState([]);
  const [weightingsOverTimeObject, setWeightingsOverTimeObject] = useState({});
  const [scoreOverTimeObject, setScoreOverTimeObject] = useState();
  const [weightingsOverTimeTrend, setWeightingsOverTimeTrend] = useState('Weekly');
  const [scoreOverTimeTrend, setScoreOverTimeTrend] = useState('Weekly');
  const [weightingsOverTimeDisplayBy, setWeightingsOverTimeDisplayBy] = useState('Category');
  const [scoreOverTimeDisplayBy, setScoreOverTimeDisplayBy] = useState('Category');
  const [propertiesData, setPropertiesData] = useState([]);
  const [propertyHkey, setPropertyHkey] = useState();
  const [propertiesLoading, setPropertiesLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [isOpenSpFilter, setIsOpenSpFilter] = useState(false);
  const [propertyCard, setPropertyCard] = useState();
  const [topMostInfluentialSP, setTopMostInfluentialSP] = useState([]);
  const [activeIndustriesSP, setActiveIndustriesSP] = useState([]);
  const [activeIndustriesSortBySP, setActiveIndustriesSortBySP] = useState('Shortlisting events');
  const [topPropsByScoresortBySP, setTopPropsByScoresortBySP] = useState('Median score');
  const [topPropsByScoreDataSP, setTopPropsByScoreDataSP] = useState([]);
  const [topPropsByValueDataSP, setTopPropsByValueDataSP] = useState([]);
  const [filterCountSP, setFilterCountSP] = useState(0);
  const [globalWeightings_SP, setGlobalWeightings_SP] = useState(BM_GLOBALWEIGHTINGS);
  const [globalWeightings_VS_SP, setGlobalWeightings_VS_SP] = useState(BM_GLOBALWEIGHTINGS);
  const [weightingsD3_SP, setWeightingsD3_SP] = useState(BM_GLOBALWEIGHTINGS);
  const [weightingsD3_VS_SP, setWeightingsD3_VS_SP] = useState(BM_GLOBALWEIGHTINGS);
  const [scoresD3_VS_SP, setScoresD3_VS_SP] = useState(BM_GLOBALSCORES);
  const [showWeightingsOutliersSP, setShowWeightingsOutliersSP] = useState(false);
  const [dataWtSP, setDataWtSP] = useState([]);
  const [dataWt, setDataWt] = useState([]);
  const [dataWtMarket, setDataWtMarket] = useState([]);
  const [weightingsPlotTypeSP, setWeightingsPlotTypeSP] = useState('Density plot');
  const [showVsMarket, setShowVsMarket] = useState(false);
  const [dataWtDensity, setDataWtDensity] = useState();

  const [globalScores_SP, setGlobalScores_SP] = useState(BM_GLOBALSCORES);
  const [globalScores_VS_SP, setGlobalScores_VS_SP] = useState(BM_GLOBALSCORES);
  const [scoresD3_SP, setScoresD3_SP] = useState(BM_GLOBALSCORES);
  const [showScoresOutliersSP, setShowScoresOutliersSP] = useState(false);
  const [dataScSP, setDataScSP] = useState([]);
  const [dataSc, setDataSc] = useState([]);
  const [dataScMarket, setDataScMarket] = useState([]);
  const [scoresPlotTypeSP, setScoresPlotTypeSP] = useState('Density plot');
  const [showVsMarketSc, setShowVsMarketSc] = useState(false);
  const [dataScDensity, setDataScDensity] = useState();
  const hkey = useSelector((state) => state.mainReducer.hkey);
  const axiosPrivate = useAxiosPrivate();
  const useStyles = useMemo(
    () =>
      makeStyles({
        btn: {
          '&.MuiButton-outlined': {
            background: '#fff',
            border: '1px solid #003F2D',
          },
        },
        disabledBtn: {
          '&.MuiButton-outlined': {
            background: '#fff',
            border: '1px solid #565656',
          },
        },
      }),
    []
  );
  const classes = useStyles();
  const median = (inputArray) => {
    let numbers = inputArray.filter(function (e) {
      return e != 0 && e != null;
    });
    const sorted = Array.from(numbers).sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);
    if (sorted.length == 0) {
      return 0;
    } else if (sorted.length % 2 === 0) {
      return (sorted[middle - 1] + sorted[middle]) / 2;
    }
    return sorted[middle];
  };
  const [weightingsPlotType, setWeightingsPlotType] = useState('Box and whisker');
  const [scoresPlotType, setScoresPlotType] = useState('Density plot');
  const [scoreOverTimeDataSP, setScoreOverTimeDataSP] = useState([]);
  const [scoreOverTimeObjectSP, setScoreOverTimeObjectSP] = useState();
  const [scoreOverTimeDisplayBySP, setScoreOverTimeDisplayBySP] = useState('Category');
  const [scoreOverTimeTrendSP, setScoreOverTimeTrendSP] = useState('Weekly');
  const [weightingsOverTimeDataSP, setWeightingsOverTimeDataSP] = useState([]);
  const [weightingsOverTimeTrendSP, setWeightingsOverTimeTrendSP] = useState('Weekly');

  const [weightingsOverTimeDisplayBySP, setWeightingsOverTimeDisplayBySP] = useState('Category');
  const [weightingsOverTimeObjectSP, setWeightingsOverTimeObjectSP] = useState({});

  useEffect(() => {
    const traceTrWt = {
      x: globalWeightings.TRANSPORT,
      type: 'box',
      name: 'Transport               ',
      marker: {
        color: '#B1AC0E',
        symbol: 'circle-open',
      },
      // boxmean: true,
      // selectedpoints: [],
      boxpoints: showWeightingsOutliers ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceLaWt = {
      x: globalWeightings.LOCAL_AMENITY,
      type: 'box',
      name: 'Location & Amenity ',
      marker: { color: '#A388BF', symbol: 'circle-open' },
      boxpoints: showWeightingsOutliers ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceBlWt = {
      x: globalWeightings.BUILDING,
      type: 'box',
      name: 'Building                 ',
      marker: { color: '#4D7CA2', symbol: 'circle-open' },
      boxpoints: showWeightingsOutliers ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };

    const traceEsWt = {
      x: globalWeightings.ESG,
      type: 'box',
      name: 'ESG                       ',
      marker: { color: '#80BBAD', symbol: 'circle-open' },
      boxpoints: showWeightingsOutliers ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceDrWt = {
      x: globalWeightings.DELIVERABILITY_RISK,
      type: 'box',
      name: 'Deliverability & Risk',
      marker: { color: '#885073', symbol: 'circle-open' },
      boxpoints: showWeightingsOutliers ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    if (globalWeightings.BUILDING.length) setDataWt([traceDrWt, traceEsWt, traceLaWt, traceBlWt, traceTrWt]);
  }, [showWeightingsOutliers, globalWeightings]);

  useEffect(() => {
    const traceTrWt_SP = {
      x: globalWeightings_SP.TRANSPORT,
      type: 'box',
      name: 'Transport               ',
      marker: {
        // color: '#8ecae6',
        color: '#B1AC0E',
        symbol: 'circle-open',
      },
      boxpoints: showWeightingsOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };

    const traceLaWt_SP = {
      x: globalWeightings_SP.LOCAL_AMENITY,
      type: 'box',
      name: 'Location & Amenity ',
      marker: { color: '#A388BF', symbol: 'circle-open' },
      boxpoints: showWeightingsOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };

    const traceBlWt_SP = {
      x: globalWeightings_SP.BUILDING,
      type: 'box',
      name: 'Building                 ',
      marker: { color: '#4D7CA2', symbol: 'circle-open' },
      boxpoints: showWeightingsOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceEsWt_SP = {
      x: globalWeightings_SP.ESG,
      type: 'box',
      name: 'ESG                       ',
      marker: { color: '#80BBAD', symbol: 'circle-open' },
      boxpoints: showWeightingsOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceDrWt_SP = {
      x: globalWeightings_SP.DELIVERABILITY_RISK,
      type: 'box',
      name: 'Deliverability & Risk',
      marker: { color: '#885073', symbol: 'circle-open' },
      boxpoints: showWeightingsOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const marker = { color: '#73d2de', symbol: 'circle-open' };

    if (dataWtMarket.at(0) && showVsMarket) {
      setDataWtSP([
        {
          ...dataWtMarket.at(0),
          name: '\xa0',
          marker,
        },
        traceDrWt_SP,
        {
          ...dataWtMarket.at(1),
          name: '\xa0\xa0',
          marker,
        },
        traceEsWt_SP,
        {
          ...dataWtMarket.at(2),
          name: '\xa0\xa0\xa0',
          marker,
        },
        traceLaWt_SP,
        {
          ...dataWtMarket.at(3),
          name: '\xa0\xa0\xa0\xa0',
          marker,
        },
        traceBlWt_SP,
        {
          ...dataWtMarket.at(4),
          name: '\xa0\xa0\xa0\xa0\xa0',
          marker,
        },
        traceTrWt_SP,
      ]);
      const updated = {
        ...globalWeightings_SP,
        '\xa0': globalWeightings_VS_SP.DELIVERABILITY_RISK,
        '\xa0\xa0': globalWeightings_VS_SP.ESG,
        '\xa0\xa0\xa0': globalWeightings_VS_SP.LOCAL_AMENITY,
        '\xa0\xa0\xa0\xa0': globalWeightings_VS_SP.BUILDING,
        '\xa0\xa0\xa0\xa0\xa0': globalWeightings_VS_SP.TRANSPORT,
      };
      setDataWtDensity(updated);
      let stats = {};
      for (const el of Object.keys(updated)) {
        stats = {
          ...stats,
          [el]: {
            mean: d3.mean(updated[el])?.toFixed(1),
            median: d3.median(updated[el])?.toFixed(1),
            mode: d3.mode(updated[el])?.toFixed(1),
            variance: updated[el].length > 1 ? d3.variance(updated[el])?.toFixed(1) : '-',
            deviation: updated[el].length > 1 ? d3.deviation(updated[el])?.toFixed(1) : '-',
          },
        };
      }

      const statsVS = {};
      for (const el of Object.keys(globalWeightings_SP)) {
        if (el === 'TRANSPORT') {
          statsVS['TRANSPORT'] = [stats[el], stats['\xa0\xa0\xa0\xa0\xa0']];
          statsVS['\xa0\xa0\xa0\xa0\xa0'] = [stats[el], stats['\xa0\xa0\xa0\xa0\xa0']];
        } else if (el === 'BUILDING') {
          statsVS['BUILDING'] = [stats[el], stats['\xa0\xa0\xa0\xa0']];
          statsVS['\xa0\xa0\xa0\xa0'] = [stats[el], stats['\xa0\xa0\xa0\xa0']];
        } else if (el === 'LOCAL_AMENITY') {
          statsVS['LOCAL_AMENITY'] = [stats[el], stats['\xa0\xa0\xa0']];
          statsVS['\xa0\xa0\xa0'] = [stats[el], stats['\xa0\xa0\xa0']];
        } else if (el === 'ESG') {
          statsVS['ESG'] = [stats[el], stats['\xa0\xa0']];
          statsVS['\xa0\xa0'] = [stats[el], stats['\xa0\xa0']];
        } else {
          statsVS['DELIVERABILITY_RISK'] = [stats[el], stats['\xa0']];
          statsVS['\xa0'] = [stats[el], stats['\xa0']];
        }
      }
      setWeightingsD3_VS_SP(statsVS);
    }
    if (dataWtMarket.at(0) && !showVsMarket) {
      setDataWtSP([traceDrWt_SP, traceEsWt_SP, traceLaWt_SP, traceBlWt_SP, traceTrWt_SP]);
      setDataWtDensity({
        ...globalWeightings_SP,
      });
    }
  }, [globalWeightings_SP, globalWeightings_VS_SP, showWeightingsOutliersSP, showVsMarket, dataWtMarket]);

  useEffect(() => {
    const traceTrWt = {
      x: globalWeightings_VS_SP.TRANSPORT,
      type: 'box',
      name: 'Transport               ',
      marker: {
        color: '#B1AC0E',
        symbol: 'circle-open',
      },
      boxpoints: showWeightingsOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceLaWt = {
      x: globalWeightings_VS_SP.LOCAL_AMENITY,
      type: 'box',
      name: 'Location & Amenity ',
      marker: { color: '#A388BF', symbol: 'circle-open' },
      boxpoints: showWeightingsOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceBlWt = {
      x: globalWeightings_VS_SP.BUILDING,
      type: 'box',
      name: 'Building                 ',
      marker: { color: '#4D7CA2', symbol: 'circle-open' },
      boxpoints: showWeightingsOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };

    const traceEsWt = {
      x: globalWeightings_VS_SP.ESG,
      type: 'box',
      name: 'ESG                       ',
      marker: { color: '#80BBAD', symbol: 'circle-open' },
      boxpoints: showWeightingsOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceDrWt = {
      x: globalWeightings_VS_SP.DELIVERABILITY_RISK,
      type: 'box',
      name: 'Deliverability & Risk',
      marker: { color: '#885073', symbol: 'circle-open' },
      boxpoints: showWeightingsOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    if (globalWeightings_VS_SP.BUILDING.length)
      setDataWtMarket([traceDrWt, traceEsWt, traceLaWt, traceBlWt, traceTrWt]);
  }, [showWeightingsOutliersSP, globalWeightings_VS_SP]);

  useEffect(() => {
    const traceTrSc = {
      x: globalScores_VS_SP.TRANSPORT_SCORE,
      type: 'box',
      name: 'Transport               ',
      marker: { color: '#B1AC0E', symbol: 'circle-open' },
      boxpoints: showScoresOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceLaSc = {
      x: globalScores_VS_SP.LOCAL_AMENITY_SCORE,
      type: 'box',
      name: 'Location & Amenity ',
      marker: { color: '#A388BF', symbol: 'circle-open' },
      boxpoints: showScoresOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceBlSc = {
      x: globalScores_VS_SP.BUILDING_SCORE,
      type: 'box',
      name: 'Building                 ',
      marker: { color: '#4D7CA2', symbol: 'circle-open' },
      boxpoints: showScoresOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceEsSc = {
      x: globalScores_VS_SP.ESG_SCORE,
      type: 'box',
      name: 'ESG                       ',
      marker: { color: '#80BBAD', symbol: 'circle-open' },
      boxpoints: showScoresOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceDrSc = {
      x: globalScores_VS_SP.DELIVERABILITY_RISK_SCORE,
      type: 'box',
      name: 'Deliverability & Risk',
      marker: { color: '#885073', symbol: 'circle-open' },
      boxpoints: showScoresOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    if (globalScores_VS_SP.BUILDING_SCORE.length)
      setDataScMarket([traceDrSc, traceEsSc, traceLaSc, traceBlSc, traceTrSc]);
  }, [showScoresOutliersSP, globalScores_VS_SP]);

  // const maxWtValue = useMemo(() => {
  //   let data = [];
  //   for (const k in globalWeightings) {
  //     data = data.concat(globalWeightings[k]);
  //   }
  //   const flatted = data.flat();
  //   if (!flatted.length) return 10;
  //   let pivot = Math.ceil(Math.max(...flatted) / 10) * 10;
  //   if (pivot <= 90) return pivot + 10;
  //   return 100;
  // }, [globalWeightings]);

  useEffect(() => {
    const traceTrSc = {
      x: globalScores.TRANSPORT_SCORE,
      type: 'box',
      name: 'Transport               ',
      marker: { color: '#B1AC0E', symbol: 'circle-open' },
      boxpoints: showScoresOutliers ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceLaSc = {
      x: globalScores.LOCAL_AMENITY_SCORE,
      type: 'box',
      name: 'Location & Amenity ',
      marker: { color: '#A388BF', symbol: 'circle-open' },
      boxpoints: showScoresOutliers ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceBlSc = {
      x: globalScores.BUILDING_SCORE,
      type: 'box',
      name: 'Building                 ',
      marker: { color: '#4D7CA2', symbol: 'circle-open' },
      boxpoints: showScoresOutliers ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceEsSc = {
      x: globalScores.ESG_SCORE,
      type: 'box',
      name: 'ESG                       ',
      marker: { color: '#80BBAD', symbol: 'circle-open' },
      boxpoints: showScoresOutliers ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceDrSc = {
      x: globalScores.DELIVERABILITY_RISK_SCORE,
      type: 'box',
      name: 'Deliverability & Risk',
      marker: { color: '#885073', symbol: 'circle-open' },
      boxpoints: showScoresOutliers ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    if (globalScores.BUILDING_SCORE.length) setDataSc([traceDrSc, traceEsSc, traceLaSc, traceBlSc, traceTrSc]);
  }, [showScoresOutliers, globalScores]);

  // const maxScValue = useMemo(() => {
  //   let data = [];
  //   for (const k in globalScores) {
  //     data = data.concat(globalScores[k]);
  //   }
  //   const flatted = data.flat();
  //   if (!flatted.length) return 10;
  //   let pivot = Math.ceil(Math.max(...flatted) / 10) * 10;
  //   if (pivot <= 90) return pivot + 10;
  //   return 100;
  // }, [globalScores]);

  useEffect(() => {
    const traceTrSc_SP = {
      x: globalScores_SP.TRANSPORT_SCORE,
      type: 'box',
      name: 'Transport               ',
      marker: {
        // color: '#8ecae6',
        color: '#B1AC0E',
        symbol: 'circle-open',
      },
      boxpoints: showScoresOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };

    const traceLaSc_SP = {
      x: globalScores_SP.LOCAL_AMENITY_SCORE,
      type: 'box',
      name: 'Location & Amenity ',
      marker: { color: '#A388BF', symbol: 'circle-open' },
      boxpoints: showScoresOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };

    const traceBlSc_SP = {
      x: globalScores_SP.BUILDING_SCORE,
      type: 'box',
      name: 'Building                 ',
      marker: { color: '#4D7CA2', symbol: 'circle-open' },
      boxpoints: showScoresOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceEsSc_SP = {
      x: globalScores_SP.ESG_SCORE,
      type: 'box',
      name: 'ESG                       ',
      marker: { color: '#80BBAD', symbol: 'circle-open' },
      boxpoints: showScoresOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const traceDrSc_SP = {
      x: globalScores_SP.DELIVERABILITY_RISK_SCORE,
      type: 'box',
      name: 'Deliverability & Risk',
      marker: { color: '#885073', symbol: 'circle-open' },
      boxpoints: showScoresOutliersSP ? 'all' : false,
      pointpos: 0,
      jitter: 0,
      hoverinfo: 'none',
    };
    const marker = { color: '#73d2de', symbol: 'circle-open' };

    if (dataScMarket.at(0) && showVsMarketSc) {
      setDataScSP([
        {
          ...dataScMarket.at(0),
          name: '\xa0',
          marker,
        },
        traceDrSc_SP,
        {
          ...dataScMarket.at(1),
          name: '\xa0\xa0',
          marker,
        },
        traceEsSc_SP,
        {
          ...dataScMarket.at(2),
          name: '\xa0\xa0\xa0',
          marker,
        },
        traceLaSc_SP,
        {
          ...dataScMarket.at(3),
          name: '\xa0\xa0\xa0\xa0',
          marker,
        },
        traceBlSc_SP,
        {
          ...dataScMarket.at(4),
          name: '\xa0\xa0\xa0\xa0\xa0',
          marker,
        },
        traceTrSc_SP,
      ]);
      const updated = {
        ...globalScores_SP,
        '\xa0': globalScores_VS_SP.DELIVERABILITY_RISK_SCORE,
        '\xa0\xa0': globalScores_VS_SP.ESG_SCORE,
        '\xa0\xa0\xa0': globalScores_VS_SP.LOCAL_AMENITY_SCORE,
        '\xa0\xa0\xa0\xa0': globalScores_VS_SP.BUILDING_SCORE,
        '\xa0\xa0\xa0\xa0\xa0': globalScores_VS_SP.TRANSPORT_SCORE,
      };
      setDataScDensity(updated);
      let stats = {};
      for (const el of Object.keys(updated)) {
        stats = {
          ...stats,
          [el]: {
            mean: d3.mean(updated[el])?.toFixed(1),
            median: d3.median(updated[el])?.toFixed(1),
            mode: d3.mode(updated[el])?.toFixed(1),
            variance: updated[el].length > 1 ? d3.variance(updated[el])?.toFixed(1) : '-',
            deviation: updated[el].length > 1 ? d3.deviation(updated[el])?.toFixed(1) : '-',
          },
        };
      }

      const statsVS = {};
      for (const el of Object.keys(globalScores_SP)) {
        if (el === 'TRANSPORT_SCORE') {
          statsVS['TRANSPORT_SCORE'] = [stats[el], stats['\xa0\xa0\xa0\xa0\xa0']];
          statsVS['\xa0\xa0\xa0\xa0\xa0'] = [stats[el], stats['\xa0\xa0\xa0\xa0\xa0']];
        } else if (el === 'BUILDING_SCORE') {
          statsVS['BUILDING_SCORE'] = [stats[el], stats['\xa0\xa0\xa0\xa0']];
          statsVS['\xa0\xa0\xa0\xa0'] = [stats[el], stats['\xa0\xa0\xa0\xa0']];
        } else if (el === 'LOCAL_AMENITY_SCORE') {
          statsVS['LOCAL_AMENITY_SCORE'] = [stats[el], stats['\xa0\xa0\xa0']];
          statsVS['\xa0\xa0\xa0'] = [stats[el], stats['\xa0\xa0\xa0']];
        } else if (el === 'ESG_SCORE') {
          statsVS['ESG_SCORE'] = [stats[el], stats['\xa0\xa0']];
          statsVS['\xa0\xa0'] = [stats[el], stats['\xa0\xa0']];
        } else {
          statsVS['DELIVERABILITY_RISK_SCORE'] = [stats[el], stats['\xa0']];
          statsVS['\xa0'] = [stats[el], stats['\xa0']];
        }
      }
      setScoresD3_VS_SP(statsVS);
    }

    if (dataScMarket.at(0) && !showVsMarketSc) {
      setDataScSP([traceDrSc_SP, traceEsSc_SP, traceLaSc_SP, traceBlSc_SP, traceTrSc_SP]);
      setDataScDensity({
        ...globalScores_SP,
      });
    }
  }, [globalScores_VS_SP, globalScores_SP, showScoresOutliersSP, showVsMarketSc, dataScMarket]);

  const innerWidth = useMemo(() => window.innerWidth / 2, []);

  useEffect(() => {
    fecthWeightingsOverTime();
    fecthScoreOverTime();
    fetchBenchmarkingResults();
    fetchLandlards();
    fecthMostActiveAreas();
    fecthMostActiveIndustries();
    fecthMostPopularTransportHubs();
    fecthTopPropertiesByScore();
    fecthTopPropertiesByValueInPound();
    fecthTopMostInfluentialScoringCriteria();
  }, []);
  const getSorted = (data) => {
    var sortedArray = data?.sort((a, b) => (a.labelValue < b.labelValue ? 1 : -1));
    var rankValue = 1;
    var arrayset = [];
    sortedArray.map((v, index) => {
      if (index == 0) {
        rankValue = 1;
      } else if (Math.round(v.labelValue) == Math.round(sortedArray[index - 1].labelValue)) {
        rankValue = rankValue;
      } else {
        rankValue = rankValue + 1;
      }
      arrayset.push({
        ...v,
        RANK: rankValue,
      });
    });
    return arrayset;
  };
  const getSortedCurrency = (data) => {
    const sortedArray = data?.sort((a, b) => (a.labelValue < b.labelValue ? 1 : -1));
    let rankValue;
    const arrayset = sortedArray.map((v, index) => {
      if (!index) rankValue = 1;
      else if (v.labelValue !== sortedArray[index - 1].labelValue) {
        rankValue = rankValue + 1;
      }
      return {
        ...v,
        RANK: rankValue,
      };
    });
    return arrayset;
  };

  const fetchLandlards = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/market-overview/landlods`);
      if (response.status === 200 && response.data.length > 0) {
        var array = [];
        response.data.forEach((obj) => {
          array.push({
            id: obj.ORGANIZATION_NAME,
            title: obj.ORGANIZATION_NAME,
          });
        });
        dispatch({ type: 'SET_LANDLARDS', payload: array });
      }
    } catch (error) {
      erroHandlingFunction('Unable to fetch landlords details');
    }
  };

  const fetchBenchmarkingResults = async () => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.get(`/benchmarking/market-overview/projects/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        setProjectObject(response.data[0]);
        fetchBenchmarkingFilter();
      }
    } catch (error) {
      erroHandlingFunction('Unable to fetch benchmarking filter details');
    }
  };

  const fetchBenchmarkingFilter = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/market-overview/filter/${hubUserKey}`);
      if (response.status === 200) {
        handleBenchMarkingCallback(response.data);
      }
    } catch (error) {
      erroHandlingFunction('Unable to insert last project viewed details');
    }
  };

  const handleBenchMarkingCallback = (response) => {
    setIsLoading(false);
    if (response?.length > 0) {
      setBenchMarkingFilterObject(response);
      var count = 0;
      if (response[0].LANDLORDS) {
        count = count + 1;
      }
      if (response[0].MIN_AVAILABLE_SPACE || response[0].MAX_AVAILABLE_SPACE) {
        count = count + 1;
      }
      if (response[0].START_DATE || response[0].END_DATE) {
        count = count + 1;
      }
      if (response[0].MIN_FLOOR_SIZE || response[0].MAX_FLOOR_SIZE) {
        count = count + 1;
      }
      if (response[0].MIN_RENT || response[0].MAX_RENT) {
        count = count + 1;
      }
      if (response[0]?.MARKETS?.length > 0) {
        count = count + 1;
      }
      if (response[0]?.SUB_MARKETS?.length > 0) {
        count = count + 1;
      }
      if (response[0]?.DISTRICTS?.length > 0) {
        count = count + 1;
      }
      if (response[0]?.NEIGHBOURHOODS?.length > 0) {
        count = count + 1;
      }
      if (response[0]?.EPC_RATINGS?.length > 0) {
        count = count + 1;
      }
      if (response[0]?.INDUSTRIES?.length > 0) {
        count = count + 1;
      }
      setFilterCount(count);
    }
  };
  const numberWithCommas = (x) => {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0;
  };
  const handleCallbackFilterCount = (value) => {
    setFilterCount(value);
  };
  const handleApplyCallback = () => {
    setOpenFilter(false);
    getTypicalWeightings();
    getTypicalScore();
    fetchBenchmarkingResults();
    fecthMostActiveAreas();
    fecthMostActiveIndustries();
    fecthMostPopularTransportHubs();
    fecthTopPropertiesByScore();
    fecthTopPropertiesByValueInPound();
    fecthTopMostInfluentialScoringCriteria();
    fecthWeightingsOverTime();
    fecthScoreOverTime();
    // reseting all the dropdowns to default
    setMostActiveAreassortBy('Shortlisting events');
    setMostActiveIndustriessortBy('Shortlisting events');
    setMostPopularTransportHubssortBy('Shortlisting events');
    setTopPropertiesByScoreHubssortBy('Median score');
    setTopPropertiesByValueInPoundsortBy('Value in points/£');
    setWeightingsOverTimeDisplayBy('Category');
    setWeightingsOverTimeTrend('Weekly');
    setScoreOverTimeDisplayBy('Category');
    setScoreOverTimeTrend('Weekly');
    setWeightingsPlotType('Box and whisker');
    setScoresPlotType('Density plot');
    setShowWeightingsOutliers(false);
    setShowScoresOutliers(false);
  };
  const callbackSPFilter = () => {
    getTopMostInfluentialSP();
    getMostActiveIndustriesSP();
    getTopPropsByScoreSP();
    getCompetitorsByValueSP();
    getTypicalWeightingsSP();
    fecthWeightingsOverTimeSP();
    // fecthScoreOverTimeSP();
    getTypicalScoresSP();
  };
  const handleCallback = (chartsList) => {
    setOptions(chartsList);
  };
  const fecthMostActiveAreas = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/market-overview/active-areas/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        handleMostActiveAreasCallBack(response.data, 'Shortlisting events');
      }
    } catch (error) {
      erroHandlingFunction('Unable to fetch most active areas details');
    }
  };

  const handleMostActiveAreasCallBack = (response, mostActiveAreassortByValue) => {
    console.log('response for sorting.....', mostActiveAreassortByValue);
    var areasData = [];
    var key = '';
    if (response?.length > 0) {
      key =
        mostActiveAreassortByValue === 'Shortlisting events'
          ? 'COUNT_SHORTLIST_EVENTS'
          : mostActiveAreassortByValue === 'Longlisting events'
          ? 'COUNT_LONGLIST_EVENTS'
          : 'COUNT_VIEWED';
      response.forEach((obj) => {
        areasData.push({
          ...obj,
          labelName: obj.DISTRICT,
          labelValue: obj[key],
          tooltipLabel: obj.DISTRICT + '( ' + obj.MARKET + ' )',
          keyLabel: mostActiveAreassortByValue,
        });
      });
    }
    var dataset = getSorted(areasData);
    setMostActiveAreasData(dataset);
  };

  const fecthMostActiveIndustries = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/market-overview/active-industries/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        handleMostActiveIndustriesCallBack(response.data, 'Shortlisting events');
      }
    } catch (error) {
      erroHandlingFunction('Unable to fetch most active industries details');
    }
  };

  const handleMostActiveIndustriesCallBack = (response, mostActiveIndustriessortByValue) => {
    var industriesData = [];
    var key = '';
    if (response?.length > 0) {
      key =
        mostActiveIndustriessortByValue === 'Shortlisting events'
          ? 'COUNT_SHORTLIST_EVENTS'
          : mostActiveIndustriessortByValue === 'Longlisting events'
          ? 'COUNT_LONGLIST_EVENTS'
          : 'COUNT_VIEWED';
      response.forEach((obj) => {
        industriesData.push({
          ...obj,
          labelName: obj.INDUSTRY_DESC,
          labelValue: obj[key],
          tooltipLabel: obj.INDUSTRY_DESC,
          keyLabel: mostActiveIndustriessortByValue,
        });
      });
    }
    var industriesDataSet = getSorted(industriesData);
    setMostActiveIndustriesData(industriesDataSet);
  };
  const fecthMostPopularTransportHubs = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/market-overview/popular-transport-hubs/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        handleMostPopularTransportHubsCallBack(response.data, 'Shortlisting events');
      }
    } catch (error) {
      erroHandlingFunction('Unable to fetch most popular transport hubs details');
    }
  };

  const handleMostPopularTransportHubsCallBack = (response, mostPopularTransportHubssortByValue) => {
    var transportData = [];
    var key = '';
    if (response?.length > 0) {
      key =
        mostPopularTransportHubssortByValue === 'Shortlisting events'
          ? 'COUNT_SHORTLIST_EVENTS'
          : mostPopularTransportHubssortByValue === 'Longlisting events'
          ? 'COUNT_LONGLIST_EVENTS'
          : 'COUNT_VIEWED';
      response.forEach((obj) => {
        transportData.push({
          ...obj,
          labelName: obj.TRANSPORT_HUB,
          labelValue: obj[key],
          tooltipLabel: obj.TRANSPORT_HUB,
          keyLabel: mostPopularTransportHubssortByValue,
        });
      });
    }
    var transportDataSet = getSorted(transportData);
    setMostPopularTransportHubsData(transportDataSet);
  };

  const erroHandlingFunction = (errorMessage) => {
    setIsLoading(false);
    setWarningSnackbar({
      name: 'Error:',
      message: errorMessage,
      severity: 'error',
    });
  };

  const fecthTopPropertiesByScore = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/market-overview/properties-by-score/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        handleTopPropertiesByScoreCallBack(response.data, 'Median score');
      }
    } catch (error) {
      erroHandlingFunction('Unable to fetch top properties by score details');
    }
  };

  const handleTopPropertiesByScoreCallBack = (response, topPropertiesByScoresortByValue) => {
    var propertiesData = [];
    var key = '';
    if (response?.length > 0) {
      if (topPropertiesByScoresortByValue === 'Median score') {
        key = 'SCORE';
      } else if (topPropertiesByScoresortByValue === 'Best Transport') {
        key = 'TRANSPORT_SCORE';
      } else if (topPropertiesByScoresortByValue === 'Best Building') {
        key = 'BUILDING_SCORE';
      } else if (topPropertiesByScoresortByValue === 'Best Deliverability & Risk') {
        key = 'DELIVERABILITY_RISK_SCORE';
      } else if (topPropertiesByScoresortByValue === 'Best Location & Amenity') {
        key = 'LOCAL_AMENITY_SCORE';
      } else if (topPropertiesByScoresortByValue === 'Best ESG') {
        key = 'ESG_SCORE';
      }
      response.forEach((obj) => {
        propertiesData.push({
          ...obj,
          ADDRESS_LINE1: obj.NAME,
          labelName: obj.NAME,
          labelValue: obj[key],
          tooltipLabel: obj.NAME,
          keyLabel:
            topPropertiesByScoresortByValue === 'Best Transport'
              ? 'Transport'
              : topPropertiesByScoresortByValue === 'Best Building'
              ? 'Building'
              : topPropertiesByScoresortByValue === 'Best Deliverability & Risk'
              ? 'Deliverability & Risk'
              : topPropertiesByScoresortByValue === 'Best Location & Amenity'
              ? 'Location & Amenity'
              : 'ESG',
        });
      });
    }
    var propertiesDataSet = getSorted(propertiesData);
    setTopPropertiesByScoreData(propertiesDataSet);
  };

  const fecthTopPropertiesByValueInPound = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/market-overview/properties-by-value/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        handleTopPropertiesByValueInPoundCallBack(response.data, 'Value in points/£');
      }
    } catch (error) {
      erroHandlingFunction('Unable to fetch top properties by value in pound details');
    }
  };

  const handleTopPropertiesByValueInPoundCallBack = (response, topPropertiesByValueInPoundsortByValue) => {
    var topPropertiesByValueInPoundArray = [];
    var key = '';
    if (response?.length > 0) {
      key = topPropertiesByValueInPoundsortByValue === 'Value in points/£' ? 'POINTS_PER_POUND' : 'SQFT_COST';
      response.forEach((obj) => {
        topPropertiesByValueInPoundArray.push({
          ...obj,
          labelName: obj.NAME,
          labelValue: obj[key],
          tooltipLabel: obj.NAME,
          keyLabel: topPropertiesByValueInPoundsortByValue,
        });
      });
    }
    var topPropertiesByValueInPoundDataSet = getSorted(topPropertiesByValueInPoundArray);
    setTopPropertiesByValueInPoundData(topPropertiesByValueInPoundDataSet);
  };

  const fecthTopMostInfluentialScoringCriteria = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/market-overview/influential-criteria/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        handleTopMostInfluentialScoringCriteriaCallBack(response.data);
      }
    } catch (error) {
      erroHandlingFunction('Unable to fetch top most influential scoring criteria details');
    }
  };

  const handleTopMostInfluentialScoringCriteriaCallBack = (response) => {
    var MostInfluentialScoringData = [];
    if (response?.length > 0) {
      response.forEach((obj) => {
        MostInfluentialScoringData.push({
          ...obj,
          labelName: obj.NAME,
          labelValue: obj.INFLUENCE,
          tooltipLabel: obj.NAME,
          keyLabel: 'Median score',
        });
      });
    }
    var MostInfluentialScoringDataSet = getSorted(MostInfluentialScoringData);
    setTopMostInfluentialScoringCriteriaData(MostInfluentialScoringDataSet);
  };

  const segregateDataset = (type, response) => {
    let weeklyDataset = [];
    let monthlyDataset = [];
    let quarterlyDataset = [];

    if (response?.length) {
      weeklyDataset = response
        .filter((el) => el.TIME_BUCKET === 'WEEK')
        .sort((a, b) => b.RANK - a.RANK)
        .slice(-14)
        .map((elm) => ({
          Building: elm.BUILDING_MEDIAN,
          'Deliverability & Risk': elm.DELIVERABILITY_RISK_MEDIAN,
          ESG: elm.ESG_MEDIAN,
          Transport: elm.TRANSPORT_MEDIAN,
          'Location & Amenity': elm.LOCAL_AMENITY_MEDIAN,
          tooltipLabelName:
            moment(moment().week(elm.WEEK)).clone().startOf('week').format('MMM DD') +
            ' - ' +
            moment(moment().week(elm.WEEK)).clone().endOf('week').format('MMM DD') +
            ' (Week' +
            ' ' +
            elm.WEEK +
            ', ' +
            moment().year(elm.YEAR).format('YYYY') +
            ')',
          labelName: 'W' + elm.WEEK,
        }));

      monthlyDataset = response
        .filter((el) => el.TIME_BUCKET === 'MONTH')
        .sort((a, b) => b.RANK - a.RANK)
        .slice(-12)
        .map((elm) => ({
          Building: elm.BUILDING_MEDIAN,
          'Deliverability & Risk': elm.DELIVERABILITY_RISK_MEDIAN,
          ESG: elm.ESG_MEDIAN,
          Transport: elm.TRANSPORT_MEDIAN,
          'Location & Amenity': elm.LOCAL_AMENITY_MEDIAN,
          tooltipLabelName:
            moment()
              .month(elm.MONTH - 1)
              .format('MMM') +
            ' ' +
            moment().year(elm.YEAR).format('YYYY'),
          labelName:
            moment()
              .month(elm.MONTH - 1)
              .format('MMM') +
            ' ' +
            moment().year(elm.YEAR).format('YY'),
        }));

      quarterlyDataset = response
        .filter((el) => el.TIME_BUCKET === 'QUARTER')
        .sort((a, b) => b.RANK - a.RANK)
        .slice(-8)
        .map((elm) => ({
          Building: elm.BUILDING_MEDIAN,
          'Deliverability & Risk': elm.DELIVERABILITY_RISK_MEDIAN,
          ESG: elm.ESG_MEDIAN,
          Transport: elm.TRANSPORT_MEDIAN,
          'Location & Amenity': elm.LOCAL_AMENITY_MEDIAN,
          tooltipLabelName: 'Q' + elm.QUARTER + ' ' + moment().year(elm.YEAR).format('YYYY'),
          labelName: 'Q' + elm.QUARTER,
        }));
    }
    if (type === 'weightings') {
      setWeightingsOverTimeObject({
        weekly: weeklyDataset,
        monthly: monthlyDataset,
        quarterly: quarterlyDataset,
      });
      setWeightingsOverTimeData(weeklyDataset);
    } else if (type === 'score') {
      setScoreOverTimeObject({
        weekly: weeklyDataset,
        monthly: monthlyDataset,
        quarterly: quarterlyDataset,
      });
      setScoreOverTimeData(weeklyDataset);
    }
  };
  const segregateDatasetSP = (type, response) => {
    let weeklyDatasetSP = [];
    let monthlyDatasetSP = [];
    let quarterlyDatasetSP = [];

    if (response?.length) {
      weeklyDatasetSP = response
        .filter((el) => el.TIME_BUCKET === 'WEEK')
        .sort((a, b) => b.RANK - a.RANK)
        .slice(-14)
        .map((elm) => ({
          Building: elm.BUILDING_MEDIAN,
          'Deliverability & Risk': elm.DELIVERABILITY_RISK_MEDIAN,
          ESG: elm.ESG_MEDIAN,
          Transport: elm.TRANSPORT_MEDIAN,
          'Location & Amenity': elm.LOCAL_AMENITY_MEDIAN,
          tooltipLabelName:
            moment(moment().week(elm.WEEK)).clone().startOf('week').format('MMM DD') +
            ' - ' +
            moment(moment().week(elm.WEEK)).clone().endOf('week').format('MMM DD') +
            ' (Week' +
            ' ' +
            elm.WEEK +
            ', ' +
            moment().year(elm.YEAR).format('YYYY') +
            ')',
          labelName: 'W' + elm.WEEK,
        }));

      monthlyDatasetSP = response
        .filter((el) => el.TIME_BUCKET === 'MONTH')
        .sort((a, b) => b.RANK - a.RANK)
        .slice(-12)
        .map((elm) => ({
          Building: elm.BUILDING_MEDIAN,
          'Deliverability & Risk': elm.DELIVERABILITY_RISK_MEDIAN,
          ESG: elm.ESG_MEDIAN,
          Transport: elm.TRANSPORT_MEDIAN,
          'Location & Amenity': elm.LOCAL_AMENITY_MEDIAN,
          tooltipLabelName:
            moment()
              .month(elm.MONTH - 1)
              .format('MMM') +
            ' ' +
            moment().year(elm.YEAR).format('YYYY'),
          labelName:
            moment()
              .month(elm.MONTH - 1)
              .format('MMM') +
            ' ' +
            moment().year(elm.YEAR).format('YY'),
        }));

      quarterlyDatasetSP = response
        .filter((el) => el.TIME_BUCKET === 'QUARTER')
        .sort((a, b) => b.RANK - a.RANK)
        .slice(-8)
        .map((elm) => ({
          Building: elm.BUILDING_MEDIAN,
          'Deliverability & Risk': elm.DELIVERABILITY_RISK_MEDIAN,
          ESG: elm.ESG_MEDIAN,
          Transport: elm.TRANSPORT_MEDIAN,
          'Location & Amenity': elm.LOCAL_AMENITY_MEDIAN,
          tooltipLabelName: 'Q' + elm.QUARTER + ' ' + moment().year(elm.YEAR).format('YYYY'),
          labelName: 'Q' + elm.QUARTER,
        }));
    }
    if (type === 'weightings') {
      setWeightingsOverTimeObjectSP({
        weekly: weeklyDatasetSP,
        monthly: monthlyDatasetSP,
        quarterly: quarterlyDatasetSP,
      });
      setWeightingsOverTimeDataSP(weeklyDatasetSP);
    } else if (type === 'score') {
      setScoreOverTimeObjectSP({
        weekly: weeklyDatasetSP,
        monthly: monthlyDatasetSP,
        quarterly: quarterlyDatasetSP,
      });
      setScoreOverTimeDataSP(weeklyDatasetSP);
    }
  };

  const fecthWeightingsOverTime = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/market-overview/weightings-over-time/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        segregateDataset('weightings', response.data);
      }
    } catch (error) {
      erroHandlingFunction('Unable to fetch weightings over time details');
    }
  };

  const fecthWeightingsOverTimeSP = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/single-property/weightings-over-time/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        segregateDatasetSP('weightings', response.data);
      }
    } catch (error) {
      erroHandlingFunction('Unable to fetch weightings over time details');
    }
  };

  const fecthScoreOverTime = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/market-overview/score-over-time/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        segregateDataset('score', response.data);
      }
    } catch (error) {
      erroHandlingFunction('Unable to fetch score over time details');
    }
  };

  const fecthScoreOverTimeSP = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/single-property/score-over-time/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        segregateDatasetSP('score', response.data);
      }
    } catch (error) {
      erroHandlingFunction('Unable to fetch score over time details');
    }
  };

  const DefaultMessage = useCallback(
    ({ message = 'No data available' }) => (
      <Grid
        style={{
          width: '100%',
          height: '52vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <NoDataIcon />
        <CustomTypography
          text={message}
          fontsize='25px'
          fontweight='400'
          lineheight='35px'
          fontcolor='#565656'
          marginTop='5px'
          marginLeft='10px'
        />
      </Grid>
    ),
    []
  );
  useEffect(() => {
    handleMostActiveAreasCallBack(mostActiveAreasData, mostActiveAreassortBy);
  }, [mostActiveAreassortBy]);
  useEffect(() => {
    handleMostActiveIndustriesCallBack(mostActiveIndustriesData, mostActiveIndustriessortBy);
  }, [mostActiveIndustriessortBy]);
  useEffect(() => {
    handleMostPopularTransportHubsCallBack(mostPopularTransportHubsData, mostPopularTransportHubssortBy);
  }, [mostPopularTransportHubssortBy]);
  useEffect(() => {
    handleTopPropertiesByScoreCallBack(topPropertiesByScoreData, topPropertiesByScoresortBy);
  }, [topPropertiesByScoresortBy]);
  useEffect(() => {
    handleTopPropertiesByValueInPoundCallBack(topPropertiesByValueInPoundData, topPropertiesByValueInPoundsortBy);
  }, [topPropertiesByValueInPoundsortBy]);

  useEffect(() => {
    if (weightingsOverTimeTrend) {
      if (weightingsOverTimeDisplayBy == 'Category') {
        if (weightingsOverTimeTrend == 'Weekly') {
          setWeightingsOverTimeData(weightingsOverTimeObject?.weekly);
        } else if (weightingsOverTimeTrend == 'Monthly') {
          setWeightingsOverTimeData(weightingsOverTimeObject?.monthly);
        } else if (weightingsOverTimeTrend == 'Quarterly') {
          setWeightingsOverTimeData(weightingsOverTimeObject?.quarterly);
        } else {
          setWeightingsOverTimeData(weightingsOverTimeObject?.yearly);
        }
      }
    }
  }, [weightingsOverTimeTrend, weightingsOverTimeDisplayBy]);

  useEffect(() => {
    if (weightingsOverTimeTrendSP) {
      if (weightingsOverTimeDisplayBySP === 'Category') {
        if (weightingsOverTimeTrendSP === 'Weekly') {
          setWeightingsOverTimeDataSP(weightingsOverTimeObjectSP?.weekly);
        } else if (weightingsOverTimeTrendSP === 'Monthly') {
          setWeightingsOverTimeDataSP(weightingsOverTimeObjectSP?.monthly);
        } else if (weightingsOverTimeTrendSP === 'Quarterly') {
          setWeightingsOverTimeDataSP(weightingsOverTimeObjectSP?.quarterly);
        } else {
          setWeightingsOverTimeDataSP(weightingsOverTimeObjectSP?.yearly);
        }
      }
    }
  }, [weightingsOverTimeTrendSP, weightingsOverTimeDisplayBySP]);

  useEffect(() => {
    if (scoreOverTimeObject?.weekly) {
      let medianData = [];
      medianData = [...scoreOverTimeObject[scoreOverTimeTrend.toLocaleLowerCase()]];

      medianData = medianData.map((el) => ({
        ...el,
        MedianScore:
          el['Building'] + el['Deliverability & Risk'] + el['ESG'] + el['Transport'] + el['Location & Amenity'],
      }));
      setScoreOverTimeData(medianData);
    }
  }, [scoreOverTimeTrend, scoreOverTimeDisplayBy, scoreOverTimeObject]);

  useEffect(() => {
    if (scoreOverTimeObjectSP?.weekly) {
      let medianData = [];
      medianData = [...scoreOverTimeObjectSP[scoreOverTimeTrendSP.toLocaleLowerCase()]];

      medianData = medianData.map((el) => ({
        ...el,
        MedianScore:
          el['Building'] + el['Deliverability & Risk'] + el['ESG'] + el['Transport'] + el['Location & Amenity'],
      }));
      setScoreOverTimeDataSP(medianData);
    }
  }, [scoreOverTimeTrendSP, scoreOverTimeDisplayBySP, scoreOverTimeObjectSP]);

  const getTypicalWeightings = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/market-overview/typical-weightings/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        const item = {
          ...BM_GLOBALWEIGHTINGS,
        };
        response.data.forEach((el) => {
          item.BUILDING = item.BUILDING.concat(el.BUILDING).filter((elm) => elm >= 3);
          item.DELIVERABILITY_RISK = item.DELIVERABILITY_RISK.concat(el.DELIVERABILITY_RISK).filter((elm) => elm >= 3);
          item.ESG = item.ESG.concat(el.ESG).filter((elm) => elm >= 3);
          item.LOCAL_AMENITY = item.LOCAL_AMENITY.concat(el.LOCAL_AMENITY).filter((elm) => elm >= 3);
          item.TRANSPORT = item.TRANSPORT.concat(el.TRANSPORT).filter((elm) => elm >= 3);
        });

        let updated = {};
        for (const key of Object.keys(item)) {
          updated = { ...updated, [key]: item[key].filter((el) => el) };
        }
        setGlobalWeightings(updated);
        let stats = {};
        for (const el of Object.keys(updated)) {
          stats = {
            ...stats,
            [el]: {
              mean: d3.mean(updated[el])?.toFixed(1),
              median: d3.median(updated[el])?.toFixed(1),
              mode: d3.mode(updated[el])?.toFixed(1),
              variance: updated[el].length > 1 ? d3.variance(updated[el])?.toFixed(1) : '-',
              deviation: updated[el].length > 1 ? d3.deviation(updated[el])?.toFixed(1) : '-',
            },
          };
        }
        setWeightingsD3(stats);
      }
    } catch (error) {
      console.log('Error while fetching the typical weightings...!', error);
    }
  };

  const getTypicalScore = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/market-overview/typical-score/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        const item = {
          ...BM_GLOBALSCORES,
        };
        response.data.forEach((el) => {
          item.BUILDING_SCORE = item.BUILDING_SCORE.concat(el.BUILDING_SCORE).filter((elm) => elm >= 3);
          item.DELIVERABILITY_RISK_SCORE = item.DELIVERABILITY_RISK_SCORE.concat(el.DELIVERABILITY_RISK_SCORE).filter(
            (elm) => elm >= 3
          );
          item.ESG_SCORE = item.ESG_SCORE.concat(el.ESG_SCORE).filter((elm) => elm >= 3);
          item.LOCAL_AMENITY_SCORE = item.LOCAL_AMENITY_SCORE.concat(el.LOCAL_AMENITY_SCORE).filter((elm) => elm >= 3);
          item.TRANSPORT_SCORE = item.TRANSPORT_SCORE.concat(el.TRANSPORT_SCORE).filter((elm) => elm >= 3);
        });

        let updated = {};
        for (const key of Object.keys(item)) {
          updated = { ...updated, [key]: item[key].filter((el) => el) };
        }
        setGlobalScores(updated);

        let stats = {};
        for (const el of Object.keys(updated)) {
          stats = {
            ...stats,
            [el]: {
              mean: d3.mean(updated[el])?.toFixed(1),
              median: d3.median(updated[el])?.toFixed(1),
              mode: d3.mode(updated[el])?.toFixed(1),
              variance: updated[el].length > 1 ? d3.variance(updated[el])?.toFixed(1) : '-',
              deviation: updated[el].length > 1 ? d3.deviation(updated[el])?.toFixed(1) : '-',
            },
          };
        }
        setScoresD3(stats);
      }
    } catch (error) {
      console.log('Error while fetching the typical score...!', error);
    }
  };

  const getTopMostInfluentialSP = async () => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.get(`/benchmarking/single-property/influential-criteria/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        let mostInfluential = [];
        mostInfluential = response.data
          .filter((el) => el.INFLUENCE >= 0.5)
          .map((el) => ({
            ...el,
            labelName: el.NAME,
            labelValue: el.INFLUENCE,
            tooltipLabel: el.NAME,
            keyLabel: 'Median score',
          }));
        setTopMostInfluentialSP(mostInfluential);
      }
    } catch (error) {
      console.log('Error while fetching the influential criteria...!', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMostActiveIndustriesSP = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/single-property/active-industries/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        let activeIndustries = [];
        let key;
        if (activeIndustriesSortBySP === 'Shortlisting events') key = 'COUNT_SHORTLIST_EVENTS';
        else if (activeIndustriesSortBySP === 'Longlisting events') key = 'COUNT_LONGLIST_EVENTS';
        else key = 'COUNT_VIEWED';
        activeIndustries = response.data.map((el) => ({
          ...el,
          labelName: el.INDUSTRY_DESC,
          labelValue: el[key],
          tooltipLabel: el.INDUSTRY_DESC,
          keyLabel: activeIndustriesSortBySP,
        }));
        setActiveIndustriesSP(getSorted(activeIndustries));
      }
    } catch (error) {
      console.log('Error while fetching the active industries...!', error);
    }
  };

  const getTopPropsByScoreSP = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/single-property/properties-by-score/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        let topPropsSP = [];
        topPropsSP = getTopPropsData(response.data);
        setTopPropsByScoreDataSP(getSorted(topPropsSP));
      }
    } catch (error) {
      console.log('Error while fetching the properties by score...!', error);
    }
  };

  const getCompetitorsByValueSP = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/single-property/properties-by-value/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        let topPropsSP = [];
        topPropsSP = response.data
          .filter((e) => e.POINTS_PER_POUND)
          .map((el) => ({
            ...el,
            labelName: el.NAME,
            labelValue: el.POINTS_PER_POUND,
            tooltipLabel: el.NAME,
            keyLabel: 'Value in points/£',
          }));
        setTopPropsByValueDataSP(getSortedCurrency(topPropsSP));
      }
    } catch (error) {
      console.log('Error while fetching the properties by value...!', error);
    }
  };

  const getTypicalWeightingsSP = () => {
    typicalWeightingsSP();
    typicalMarketWeightingsSP();
  };

  const typicalWeightingsSP = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/single-property/typical-weightings/${hubUserKey}`);
      if (response.status === 200) {
        if (!response.data.length) {
          setDataWtDensity([]);
          setDataWtSP([]);
          setDataWtMarket([]);
          setGlobalWeightings_SP(BM_GLOBALWEIGHTINGS);
          setGlobalWeightings_VS_SP(BM_GLOBALWEIGHTINGS);
          setWeightingsD3_SP(BM_GLOBALWEIGHTINGS);
          return;
        }
        const item = {
          ...BM_GLOBALWEIGHTINGS,
        };
        response.data.forEach((el) => {
          item.BUILDING = item.BUILDING.concat(el.BUILDING).filter((elm) => elm >= 3);
          item.DELIVERABILITY_RISK = item.DELIVERABILITY_RISK.concat(el.DELIVERABILITY_RISK).filter((elm) => elm >= 3);
          item.ESG = item.ESG.concat(el.ESG).filter((elm) => elm >= 3);
          item.LOCAL_AMENITY = item.LOCAL_AMENITY.concat(el.LOCAL_AMENITY).filter((elm) => elm >= 3);
          item.TRANSPORT = item.TRANSPORT.concat(el.TRANSPORT).filter((elm) => elm >= 3);
        });

        let updated = {};
        for (const key of Object.keys(item)) {
          updated = { ...updated, [key]: item[key].filter((el) => el) };
        }

        setGlobalWeightings_SP(updated);
        let stats = {};
        for (const el of Object.keys(updated)) {
          stats = {
            ...stats,
            [el]: {
              mean: d3.mean(updated[el])?.toFixed(1),
              median: d3.median(updated[el])?.toFixed(1),
              mode: d3.mode(updated[el])?.toFixed(1),
              variance: updated[el].length > 1 ? d3.variance(updated[el])?.toFixed(1) : '-',
              deviation: updated[el].length > 1 ? d3.deviation(updated[el])?.toFixed(1) : '-',
            },
          };
        }
        setWeightingsD3_SP(stats);
      }
    } catch (error) {
      console.log('Error while fetching the typical weightings...!', error);
    }
  };

  const typicalMarketWeightingsSP = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/single-property/typical-market-weightings/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        const item = {
          ...BM_GLOBALWEIGHTINGS,
        };
        response.data.forEach((el) => {
          item.BUILDING = item.BUILDING.concat(el.BUILDING).filter((elm) => elm >= 3);
          item.DELIVERABILITY_RISK = item.DELIVERABILITY_RISK.concat(el.DELIVERABILITY_RISK).filter((elm) => elm >= 3);
          item.ESG = item.ESG.concat(el.ESG).filter((elm) => elm >= 3);
          item.LOCAL_AMENITY = item.LOCAL_AMENITY.concat(el.LOCAL_AMENITY).filter((elm) => elm >= 3);
          item.TRANSPORT = item.TRANSPORT.concat(el.TRANSPORT).filter((elm) => elm >= 3);
        });

        let updated = {};
        for (const key of Object.keys(item)) {
          updated = { ...updated, [key]: item[key].filter((el) => el) };
        }
        setGlobalWeightings_VS_SP(updated);
      }
    } catch (error) {
      console.log('Error while fetching the typical markets weightings ...!', error);
    }
  };

  const getTypicalScoresSP = () => {
    typicalScoreSP();
    typicalMarketsScoreSP();
  };

  const typicalScoreSP = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/single-property/typical-score/${hubUserKey}`);
      if (response.status === 200) {
        if (!response.data.length) {
          setDataScDensity([]);
          setDataScSP([]);
          setDataScMarket([]);
          setGlobalScores_SP(BM_GLOBALSCORES);
          setGlobalScores_VS_SP(BM_GLOBALSCORES);
          setScoresD3_SP(BM_GLOBALSCORES);
          return;
        }
        const item = {
          ...BM_GLOBALSCORES,
        };
        response.data.forEach((el) => {
          item.BUILDING_SCORE = item.BUILDING_SCORE.concat(el.BUILDING_SCORE).filter((elm) => elm >= 3);
          item.DELIVERABILITY_RISK_SCORE = item.DELIVERABILITY_RISK_SCORE.concat(el.DELIVERABILITY_RISK_SCORE).filter(
            (elm) => elm >= 3
          );
          item.ESG_SCORE = item.ESG_SCORE.concat(el.ESG_SCORE).filter((elm) => elm >= 3);
          item.LOCAL_AMENITY_SCORE = item.LOCAL_AMENITY_SCORE.concat(el.LOCAL_AMENITY_SCORE).filter((elm) => elm >= 3);
          item.TRANSPORT_SCORE = item.TRANSPORT_SCORE.concat(el.TRANSPORT_SCORE).filter((elm) => elm >= 3);
        });

        let updated = {};
        for (const key of Object.keys(item)) {
          updated = { ...updated, [key]: item[key].filter((el) => el) };
        }
        setGlobalScores_SP(updated);

        let stats = {};
        for (const el of Object.keys(updated)) {
          stats = {
            ...stats,
            [el]: {
              mean: d3.mean(updated[el])?.toFixed(1),
              median: d3.median(updated[el])?.toFixed(1),
              mode: d3.mode(updated[el])?.toFixed(1),
              variance: updated[el].length > 1 ? d3.variance(updated[el])?.toFixed(1) : '-',
              deviation: updated[el].length > 1 ? d3.deviation(updated[el])?.toFixed(1) : '-',
            },
          };
        }
        setScoresD3_SP(stats);
      }
    } catch (error) {
      console.log('Error while fetching the typical score...!', error);
    }
  };

  const typicalMarketsScoreSP = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/single-property/typical-market-score/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        const item = {
          ...BM_GLOBALSCORES,
        };
        response.data.forEach((el) => {
          item.BUILDING_SCORE = item.BUILDING_SCORE.concat(el.BUILDING_SCORE).filter((elm) => elm >= 3);
          item.DELIVERABILITY_RISK_SCORE = item.DELIVERABILITY_RISK_SCORE.concat(el.DELIVERABILITY_RISK_SCORE).filter(
            (elm) => elm >= 3
          );
          item.ESG_SCORE = item.ESG_SCORE.concat(el.ESG_SCORE).filter((elm) => elm >= 3);
          item.LOCAL_AMENITY_SCORE = item.LOCAL_AMENITY_SCORE.concat(el.LOCAL_AMENITY_SCORE).filter((elm) => elm >= 3);
          item.TRANSPORT_SCORE = item.TRANSPORT_SCORE.concat(el.TRANSPORT_SCORE).filter((elm) => elm >= 3);
        });

        let updated = {};
        for (const key of Object.keys(item)) {
          updated = { ...updated, [key]: item[key].filter((el) => el) };
        }
        setGlobalScores_VS_SP(updated);
      }
    } catch (error) {
      console.log('Error while fetching the typical markets score...!', error);
    }
  };

  const getTopPropsData = (data) => {
    const keyFields = {
      'Median score': 'SCORE',
      'Best Transport': 'TRANSPORT_SCORE',
      'Best Building': 'BUILDING_SCORE',
      'Best Deliverability & Risk': 'DELIVERABILITY_RISK_SCORE',
      'Best Location & Amenity': 'LOCAL_AMENITY_SCORE',
      'Best ESG': 'ESG_SCORE',
    };

    const keyLabel = {
      'Best Transport': 'Transport',
      'Best Building': 'Building',
      'Best Deliverability & Risk': 'Deliverability & Risk',
      'Best Location & Amenity': 'Location & Amenity',
      'Best ESG': 'ESG',
    };
    return data.map((obj) => ({
      ...obj,
      ADDRESS_LINE1: obj.NAME,
      labelName: obj.NAME,
      labelValue: obj[keyFields[topPropsByScoresortBySP]],
      tooltipLabel: obj.NAME,
      keyLabel: keyLabel[topPropsByScoresortBySP],
    }));
  };
  useEffect(() => {
    getTypicalWeightings();
    getTypicalScore();
  }, []);

  useEffect(() => {
    let key;
    if (activeIndustriesSortBySP === 'Shortlisting events') key = 'COUNT_SHORTLIST_EVENTS';
    else if (activeIndustriesSortBySP === 'Longlisting events') key = 'COUNT_LONGLIST_EVENTS';
    else key = 'COUNT_VIEWED';
    if (activeIndustriesSP.length)
      setActiveIndustriesSP((prev) => {
        const updated = prev.map((el) => ({
          ...el,
          labelName: el.INDUSTRY_DESC,
          labelValue: el[key],
          tooltipLabel: el.INDUSTRY_DESC,
          keyLabel: activeIndustriesSortBySP,
        }));

        return getSorted(updated);
      });
  }, [activeIndustriesSortBySP]);

  useEffect(() => {
    fetchSingleProperties();
    fetchSinglePropertyFilter();
  }, []);

  useEffect(() => {
    if (!propertyHkey) return;
    fetchSinglePropertyCard();
    callbackSPFilter();
  }, [propertyHkey]);

  useEffect(() => {
    if (topPropsByScoreDataSP.length) setTopPropsByScoreDataSP(getSorted(getTopPropsData(topPropsByScoreDataSP)));
  }, [topPropsByScoresortBySP]);

  useEffect(() => {
    setPropertiesLoading(true);
    fetchActiveProperties();
  }, [hkey]);

  const fetchSingleProperties = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/single-property/properties/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        let key = response.data.at(0)?.HKEY_HUB_PROPERTY.data;
        if (key) {
          key = Buffer.from(key).toString('hex').toUpperCase();
          setPropertyHkey(key);
        }
      }
    } catch (error) {
      console.log('Error while fetching the properties...!', error);
    }
  };

  const fetchSinglePropertyFilter = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/single-property/filter/${hubUserKey}`);
      if (response.status === 200 && response.data.length > 0) {
        let count = 0;
        const data = response.data[0];
        if (data?.INDUSTRIES?.length) count++;
        if (data?.START_DATE) count++;
        if (data?.END_DATE) count++;
        setFilterCountSP(count);
      }
    } catch (error) {
      console.log('Error while fetching the properties filter...!', error);
    }
  };

  const fetchSinglePropertyCard = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/single-property/card/${hubUserKey}/${propertyHkey}`);
      if (response.status === 200 && response.data.length > 0) {
        setPropertyCard(response.data[0]);
      }
    } catch (error) {
      console.log('Error while fetching single properties card...!', error);
    }
  };

  const fetchActiveProperties = async () => {
    try {
      const response = await axiosPrivate.get(`/benchmarking/single-property/active-properties`);
      if (response.status === 200 && response.data.length > 0) {
        const data = response.data.map((item, index) => ({
          id: index + 1,
          ACTIVE: true,
          ...item,
        }));
        setPropertiesData([...data]);
      }
    } catch (error) {
      setWarningSnackbar({
        name: 'Error:',
        message: 'Unable to fetch properties',
        severity: 'error',
      });
    } finally {
      setPropertiesLoading(false);
    }
  };
  useEffect(() => {
    setShowVsMarket(false);
  }, [weightingsPlotTypeSP]);

  useEffect(() => {
    setShowVsMarketSc(false);
  }, [scoresPlotTypeSP]);

  useEffect(() => {
    setTimeout(() => {
      const plots = document.querySelectorAll('.js-plotly-plot');
      if (plots.length > 0) {
        const boxplot = plots[0];
        boxplot
          .on('plotly_hover', function (data) {
            createTooltip(data, boxplot);
          })
          .on('plotly_unhover', function (data) {
            const a = document.querySelector('.boxplot-tip');
            a.remove();
          });
      }
    }, 10);
  }, [globalWeightings, weightingsPlotType, toggleButtonIndex, dataWt, dataWtSP, scoresPlotType, globalWeightings_SP]);

  useEffect(() => {
    const plots = document.querySelectorAll('.js-plotly-plot');
    if (plots.length > 1) {
      const boxplot = plots[1];
      boxplot
        .on('plotly_hover', function (data) {
          createTooltip(data, boxplot);
        })
        .on('plotly_unhover', function (data) {
          const a = document.querySelector('.boxplot-tip');
          a.remove();
        });
    }
  }, [globalScores, scoresPlotType, toggleButtonIndex, dataSc, dataScSP, weightingsPlotType, globalScores_SP]);

  const createTooltip = (data, boxplot) => {
    const names = {
      '\xa0\xa0\xa0\xa0\xa0': 'Transport (Market)',
      '\xa0\xa0\xa0\xa0': 'Building (Market)',
      '\xa0\xa0\xa0': 'Location & Amenity (Market)',
      '\xa0\xa0': 'ESG (Market)',
      '\xa0': 'Deliverability & Risk (Market)',
      'Transport               ': 'Transport',
      'Building                 ': 'Building',
      'ESG                       ': 'ESG',
      'Location & Amenity ': 'Location & Amenity',
      'Deliverability & Risk': 'Deliverability & Risk',
    };
    const q3 = d3.quantile(data.points[0].data.x, 0.75);
    const q1 = d3.quantile(data.points[0].data.x, 0.25);
    const iqr = q3 - q1;
    const tempDiv = document.createElement('div');
    tempDiv.classList.add('boxplot-tip');
    tempDiv.style.backgroundColor = '#fff';

    tempDiv.style.position = 'absolute';
    var x = data.event.clientX;
    var y = data.event.clientY;
    tempDiv.style.top = y + 'px';
    tempDiv.style.left = x + 'px';
    tempDiv.style.zIndex = '1000000';
    tempDiv.innerHTML = `
    <div class='boxplot-tip-1'>
      <div class='plot-header'>
      ${toggleButtonIndex === 1 ? data.points[0].data.name : names[data.points[0].data.name]}
      </div>
      <div class='plot-row'>
        <div class='plot-row-head'>Typically high</div>
        <div>${q3.toFixed(1)}</div>
      </div>
      <div class='plot-row'>
        <div class='plot-row-head'>Typical (median)</div>
        <div>
          ${d3.quantile(data.points[0].data.x, 0.5).toFixed(1)}
        </div>
      </div>
      <div class='plot-row'>
        <div class='plot-row-head'>Typically Low</div>
        <div>${q1.toFixed(1)}</div>
      </div>
      <div class='plot-row'>
        <div class='plot-row-head'>Interquartile range</div>
        <div>${iqr.toFixed(1)}</div>
      </div>
    </div>
    `;
    boxplot.insertAdjacentElement('beforebegin', tempDiv);
  };

  const displayByModal = (sortKey, index, sortBylist) => {
    const handelChangeSortBy = (value) => {
      if (toggleButtonIndex === 1) {
        if (index === 1) {
          setWeightingsPlotType(value);
        } else if (index === 2) {
          setMostActiveAreassortBy(value);
        } else if (index === 3) {
          setScoresPlotType(value);
        } else if (index == 4) {
          setMostActiveIndustriessortBy(value);
        } else if (index == 5) {
          setTopPropertiesByScoreHubssortBy(value);
        } else if (index == 6) {
          setTopPropertiesByValueInPoundsortBy(value);
        } else if (index == 8) {
          setMostPopularTransportHubssortBy(value);
        } else if (index == 9) {
          setWeightingsOverTimeDisplayBy(value);
        } else if (index == 10) {
          setScoreOverTimeDisplayBy(value);
        }
      } else {
        if (index === 8) {
          setActiveIndustriesSortBySP(value);
        } else if (index === 3) {
          setWeightingsPlotTypeSP(value);
        } else if (index === 4) {
          setScoresPlotTypeSP(value);
        } else if (index === 5) {
          setTopPropsByScoresortBySP(value);
        } else if (index === 7) {
          setScoreOverTimeDisplayBySP(value);
        } else if (index === 9) {
          setWeightingsOverTimeDisplayBySP(value);
        }
      }
    };
    return (
      <FormControl>
        <Select
          value={sortKey}
          onChange={(e) => handelChangeSortBy(e.target.value)}
          disableUnderline
          variant='standard'
          renderValue={(selected) => selected}
          input={<BootstrapInput />}
          MenuProps={{
            PaperProps: {
              sx: {
                width: 215,
                borderRadius: '4px',
                paddingLeft: 1,
                paddingBottom: 1,
              },
            },
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingLeft: 17,
              paddingTop: 18,
              fontWeight: 400,
              fontSize: '24px',
              lineHeight: '40px',
              fontFamily: 'Calibre-R',
              marginBottom: '11px',
            }}
          >
            Display by
          </div>
          {sortBylist.map((name) => (
            <MenuItem
              key={name}
              value={name}
              className={`dropdown_dashboard ${name === sortKey ? 'dropdown_option' : ''}`}
            >
              <Radio
                checked={sortKey === name}
                sx={{
                  '&.Mui-checked': {
                    color: '#003F2D',
                  },
                  '&.MuiRadio-colorPrimary': {
                    color: '#003F2D',
                  },
                }}
              />
              <CustomTypography
                text={name}
                fontsize='14px'
                fontweight='400'
                lineheight='21px'
                fontcolor='#1A1A1A'
                fontfamily='Calibre-R'
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  const displayByTrend = (sortKey, index) => {
    const sortBylist = ['Weekly', 'Monthly', 'Quarterly'];
    const handelChangeSortBy = (value) => {
      if (index == 9) {
        setWeightingsOverTimeTrend(value);
      } else if (index == 10) {
        setScoreOverTimeTrend(value);
      }
    };
    return (
      <FormControl>
        <Select
          value={sortKey}
          onChange={(e) => handelChangeSortBy(e.target.value)}
          variant='standard'
          renderValue={(selected) => selected}
          input={<BootstrapInput />}
          MenuProps={{
            PaperProps: {
              sx: {
                width: 215,
                borderRadius: '4px',
                paddingLeft: 1,
                paddingBottom: 1,
              },
            },
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingLeft: 17,
              paddingTop: 18,
              fontWeight: 400,
              fontSize: '24px',
              lineHeight: '40px',
              fontFamily: 'Calibre-R',
              marginBottom: '11px',
            }}
          >
            {[1, 3].includes(index) ? 'Visualisation' : 'Display by'}
          </div>
          {sortBylist.map((name) => (
            <MenuItem
              key={name}
              value={name}
              className={`dropdown_dashboard ${name === sortKey ? 'dropdown_option' : ''}`}
            >
              <Radio
                checked={sortKey === name}
                sx={{
                  '&.Mui-checked': {
                    color: '#003F2D',
                  },
                  '&.MuiRadio-colorPrimary': {
                    color: '#003F2D',
                  },
                }}
              />
              <CustomTypography
                text={name}
                fontsize='14px'
                fontweight='400'
                lineheight='21px'
                fontcolor='#1A1A1A'
                fontfamily='Calibre-R'
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  const displayByTrendSP = (sortKey, index) => {
    const sortBylist = ['Weekly', 'Monthly', 'Quarterly'];
    const handelChangeSortBy = (value) => {
      if (index === 9) {
        setWeightingsOverTimeTrendSP(value);
      } else if (index === 7) {
        setScoreOverTimeTrendSP(value);
      }
    };
    return (
      <FormControl>
        <Select
          value={sortKey}
          onChange={(e) => handelChangeSortBy(e.target.value)}
          variant='standard'
          renderValue={(selected) => selected}
          input={<BootstrapInput />}
          MenuProps={{
            PaperProps: {
              sx: {
                width: 215,
                borderRadius: '4px',
                paddingLeft: 1,
                paddingBottom: 1,
              },
            },
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingLeft: 17,
              paddingTop: 18,
              fontWeight: 400,
              fontSize: '24px',
              lineHeight: '40px',
              fontFamily: 'Calibre-R',
              marginBottom: '11px',
            }}
          >
            {[1, 3].includes(index) ? 'Visualisation' : 'Display by'}
          </div>
          {sortBylist.map((name) => (
            <MenuItem
              key={name}
              value={name}
              className={`dropdown_dashboard ${name === sortKey ? 'dropdown_option' : ''}`}
            >
              <Radio
                checked={sortKey === name}
                sx={{
                  '&.Mui-checked': {
                    color: '#003F2D',
                  },
                  '&.MuiRadio-colorPrimary': {
                    color: '#003F2D',
                  },
                }}
              />
              <CustomTypography
                text={name}
                fontsize='14px'
                fontweight='400'
                lineheight='21px'
                fontcolor='#1A1A1A'
                fontfamily='Calibre-R'
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontFamily: 'Calibre-R',
        fontSize: '14px',
        fontWeight: 400,
      },
    })
  );

  const renderChartsUi = (index, object) => {
    const width = Math.ceil(innerWidth * 0.9);
    const layout = {
      xaxis: {
        dtick: 10,
        zeroline: false,
        range: [-1, 110],
        tickfont: {
          family: 'Calibre-R',
          size: 12,
        },
      },
      yaxis: {
        zeroline: false,
      },
      // hovermode: 'y unified',
      autosize: true,
      dragmode: false,
      height: 350,
      width,
      margin: {
        l: 140,
        r: 0,
        b: 50,
        t: 50,
      },
      showlegend: false,

      hoverlabel: {
        // bordercolor: '#f2f2f2',
        font: {
          family: 'Calibre-R',
          // color: 'transparent',
          size: 0,
        },
        namelength: 0,
      },
    };
    const config = {
      displayModeBar: false,
    };
    const keyValues =
      index === 3
        ? {
            Transport: ['TRANSPORT_SCORE', '#DBD99A'],
            Building: ['BUILDING_SCORE', '#4D7CA2'],
            'Location & Amenity': ['LOCAL_AMENITY_SCORE', '#A388BF'],
            ESG: ['ESG_SCORE', '#80BBAD'],
            'Deliverability & Risk': ['DELIVERABILITY_RISK_SCORE', '#885073'],
          }
        : {
            Transport: ['TRANSPORT', '#DBD99A'],
            Building: ['BUILDING', '#4D7CA2'],
            'Location & Amenity': ['LOCAL_AMENITY', '#A388BF'],
            ESG: ['ESG', '#80BBAD'],
            'Deliverability & Risk': ['DELIVERABILITY_RISK', '#885073'],
          };
    const values = Object.keys(keyValues);
    switch (index) {
      case 1:
        return (
          <Grid
            item
            md={6}
            sm={6}
            lg={6}
            style={{
              padding: 20,
              height: 480,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                width: '100%',
                height: '100%',
                // justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  width: '97%',
                  paddingLeft: '3%',
                }}
              >
                <Grid
                  item
                  md={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <CustomTypography
                    text={object.name}
                    fontfamily='Calibre-R'
                    fontsize='24px'
                    lineheight='40px'
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    marginTop={15}
                  />
                </Grid>
                <Grid
                  item
                  md={8}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: 20,
                    paddingTop: 20,
                  }}
                >
                  {weightingsPlotType === 'Box and whisker' && (
                    <Grid style={{ display: 'flex', alignItems: 'center' }}>
                      <CustomTypography
                        text='Show values'
                        fontfamily='Calibre-R'
                        fontsize='12px'
                        lineheight='40px'
                        fontweight='400'
                        fontcolor='#1A1A1A'
                      />
                      <div
                        style={{
                          marginRight: 20,
                          marginBottom: -5,
                          marginLeft: 8,
                        }}
                        onClick={() => {
                          setShowWeightingsOutliers((flag) => !flag);
                        }}
                      >
                        <ButtonSwitch value={showWeightingsOutliers} />
                      </div>
                    </Grid>
                  )}
                  <CustomTypography text={`Visualisation:`} fontweight='400' lineheight='21px' fontcolor={'#1A1A1A'} />
                  &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {displayByModal(weightingsPlotType, 1, ['Box and whisker', 'Density plot'])}
                  </div>
                </Grid>
              </Grid>

              <div
                style={{
                  display: weightingsPlotType !== 'Box and whisker' ? 'none' : 'block',
                  marginLeft: 10,
                  overflow: 'hidden',
                }}
              >
                {!!dataWt.length ? <BoxPlot data={[...dataWt]} layout={layout} config={config} /> : <DefaultMessage />}
              </div>
              <div
                style={{
                  display: weightingsPlotType !== 'Density plot' ? 'none' : 'block',
                }}
              >
                {!!dataWt.length ? (
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: 30,
                      width: '100%',
                    }}
                  >
                    {values.map((elm) => (
                      <Grid
                        key={elm}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Grid md={3}>
                          <CustomTypography
                            text={elm}
                            fontfamily='Calibre-R'
                            fontsize='14px'
                            lineheight='40px'
                            fontweight='400'
                            fontcolor='#1A1A1A'
                          />
                        </Grid>
                        <Grid md={9}>
                          <DensityChartWithAxis
                            data={globalWeightings[keyValues[elm][0]].filter((el) => el)}
                            bottom={elm === 'Deliverability & Risk' ? 20 : 10}
                            color={keyValues[elm][1]}
                            stats={weightingsD3[keyValues[elm][0]]}
                            type={elm}
                            pivot={2}
                            maxVal={100}
                            width={Math.ceil(innerWidth * 0.7)}
                            tab={toggleButtonIndex}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <DefaultMessage />
                )}
              </div>
            </Grid>
          </Grid>
        );
      case 2:
        let activeAreaskey;
        if (mostActiveAreassortBy === 'Shortlisting events') activeAreaskey = 'COUNT_SHORTLIST_EVENTS';
        else if (mostActiveAreassortBy === 'Longlisting events') activeAreaskey = 'COUNT_LONGLIST_EVENTS';
        else activeAreaskey = 'COUNT_VIEWED';
        return (
          <Grid
            item
            md={6}
            sm={6}
            lg={6}
            style={{
              padding: 20,
              height: 480,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  width: '97%',
                  paddingLeft: '3%',
                  flexDirection: 'row',
                }}
              >
                <Grid
                  item
                  md={6}
                  sm={6}
                  lg={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <CustomTypography
                    text={object.name}
                    fontfamily='Calibre-R'
                    fontsize='24px'
                    lineheight='40px'
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    marginTop={15}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={6}
                  lg={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: 20,
                    paddingTop: 20,
                  }}
                >
                  <CustomTypography text={`Display by:`} fontweight='400' lineheight='21px' fontcolor={'#1A1A1A'} />
                  &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {displayByModal(mostActiveAreassortBy, 2, [
                      'Shortlisting events',
                      'Longlisting events',
                      'Viewed count',
                    ])}
                  </div>
                </Grid>
              </Grid>
              <div
                style={{
                  width: '96%',
                  paddingLeft: '2%',
                  paddingRight: '2%',
                  paddingTop: '1%',
                }}
              >
                {mostActiveAreasData.length > 0 ? (
                  <CustomBarComponent
                    data={mostActiveAreasData?.filter((el) => el[activeAreaskey]).slice(0, 10)}
                    tab={true}
                  />
                ) : (
                  <Grid
                    style={{
                      width: '100%',
                      height: '52vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <NoDataIcon />
                    <CustomTypography
                      text='No data available'
                      fontsize='25px'
                      fontweight='400'
                      lineheight='35px'
                      fontcolor='#565656'
                      marginTop='5px'
                      marginLeft='10px'
                    />
                  </Grid>
                )}
              </div>
            </Grid>
          </Grid>
        );
      case 3:
        return (
          <Grid
            item
            md={6}
            sm={6}
            lg={6}
            style={{
              padding: 20,
              height: 480,
              display: 'flex',
            }}
          >
            <Grid
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                width: '100%',
                height: '100%',
                flexDirection: 'column',
              }}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  width: '97%',
                  paddingLeft: '3%',
                }}
              >
                <Grid
                  item
                  md={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <CustomTypography
                    text={object.name}
                    fontfamily='Calibre-R'
                    fontsize='24px'
                    lineheight='40px'
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    marginTop={15}
                  />
                </Grid>
                <Grid
                  item
                  md={8}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: 20,
                    paddingTop: 20,
                  }}
                >
                  {scoresPlotType === 'Box and whisker' && (
                    <Grid style={{ display: 'flex', alignItems: 'center' }}>
                      <CustomTypography
                        text='Show values'
                        fontfamily='Calibre-R'
                        fontsize='12px'
                        lineheight='40px'
                        fontweight='400'
                        fontcolor='#1A1A1A'
                      />
                      <div
                        style={{
                          marginRight: 20,
                          marginBottom: -5,
                          marginLeft: 8,
                        }}
                        onClick={() => {
                          setShowScoresOutliers((flag) => !flag);
                        }}
                      >
                        <ButtonSwitch value={showScoresOutliers} />
                      </div>
                    </Grid>
                  )}
                  <CustomTypography text={`Visualisation:`} fontweight='400' lineheight='21px' fontcolor={'#1A1A1A'} />
                  &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {displayByModal(scoresPlotType, 3, ['Box and whisker', 'Density plot'])}
                  </div>
                </Grid>
              </Grid>

              <div
                style={{
                  display: scoresPlotType === 'Box and whisker' ? 'none' : 'block',
                }}
              >
                {!!dataSc.length ? (
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: 30,
                      width: '100%',
                    }}
                  >
                    {values.map((el) => (
                      <Grid
                        key={el}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Grid md={3}>
                          <CustomTypography
                            text={el}
                            fontfamily='Calibre-R'
                            fontsize='14px'
                            lineheight='40px'
                            fontweight='400'
                            fontcolor='#1A1A1A'
                          />
                        </Grid>
                        <Grid md={9}>
                          <DensityChartWithAxis
                            data={globalScores[keyValues[el][0]].filter((el) => el)}
                            bottom={el === 'Deliverability & Risk' ? 20 : 10}
                            color={keyValues[el][1]}
                            stats={scoresD3[keyValues[el][0]]}
                            type={el}
                            pivot={2}
                            maxVal={100}
                            width={Math.ceil(innerWidth * 0.7)}
                            tab={toggleButtonIndex}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <DefaultMessage />
                )}
              </div>

              <div
                style={{
                  display: scoresPlotType === 'Density plot' ? 'none' : 'block',
                  marginLeft: 10,
                  overflow: 'hidden',
                }}
              >
                {!!dataSc.length ? <BoxPlot data={[...dataSc]} layout={layout} config={config} /> : <DefaultMessage />}
              </div>
            </Grid>
          </Grid>
        );
      case 4:
        let key;
        if (mostActiveIndustriessortBy === 'Shortlisting events') key = 'COUNT_SHORTLIST_EVENTS';
        else if (mostActiveIndustriessortBy === 'Longlisting events') key = 'COUNT_LONGLIST_EVENTS';
        else key = 'COUNT_VIEWED';
        return (
          <Grid
            item
            md={6}
            sm={6}
            lg={6}
            style={{
              padding: 20,
              height: 480,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  width: '97%',
                  paddingLeft: '3%',
                  flexDirection: 'row',
                }}
              >
                <Grid
                  item
                  md={6}
                  sm={6}
                  lg={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <CustomTypography
                    text={object.name}
                    fontfamily='Calibre-R'
                    fontsize='24px'
                    lineheight='40px'
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    marginTop={15}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={6}
                  lg={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: 20,
                    paddingTop: 20,
                  }}
                >
                  <CustomTypography text={`Display by:`} fontweight='400' lineheight='21px' fontcolor={'#1A1A1A'} />
                  &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {displayByModal(mostActiveIndustriessortBy, 4, [
                      'Shortlisting events',
                      'Longlisting events',
                      'Viewed count',
                    ])}
                  </div>
                </Grid>
              </Grid>
              <div style={{ width: '96%', paddingLeft: '2%', paddingRight: '2%' }}>
                {mostActiveIndustriesData.length > 0 ? (
                  <CustomBarComponent
                    data={mostActiveIndustriesData?.filter((el) => el[key]).slice(0, 10)}
                    sortKey={mostActiveIndustriessortBy}
                    tab={true}
                  />
                ) : (
                  <Grid
                    style={{
                      width: '100%',
                      height: '52vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <NoDataIcon />
                    <CustomTypography
                      text='No data available'
                      fontsize='25px'
                      fontweight='400'
                      lineheight='35px'
                      fontcolor='#565656'
                      marginTop='5px'
                      marginLeft='10px'
                    />
                  </Grid>
                )}
              </div>
            </Grid>
          </Grid>
        );
      case 5:
        return (
          <Grid
            item
            md={6}
            sm={6}
            lg={6}
            style={{
              padding: 20,
              height: 480,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  width: '97%',
                  paddingLeft: '3%',
                  flexDirection: 'row',
                }}
              >
                <Grid
                  item
                  md={7}
                  sm={7}
                  lg={7}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <CustomTypography
                    text={
                      topPropertiesByScoresortBy == 'Median score'
                        ? 'Top properties by score'
                        : topPropertiesByScoresortBy == 'Best Transport'
                        ? 'Top properties by Transport score'
                        : topPropertiesByScoresortBy == 'Best Building'
                        ? 'Top properties by Building score'
                        : topPropertiesByScoresortBy == 'Best Deliverability & Risk'
                        ? 'Top properties by Deliverability & Risk score'
                        : topPropertiesByScoresortBy == 'Best Location & Amenity'
                        ? 'Top properties by Location & Amenity score'
                        : 'Top properties by ESG score'
                    }
                    fontfamily='Calibre-R'
                    fontsize={
                      topPropertiesByScoresortBy == 'Best Deliverability & Risk' ||
                      topPropertiesByScoresortBy == 'Best Location & Amenity'
                        ? '20px'
                        : '24px'
                    }
                    lineheight='40px'
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    marginTop={15}
                  />
                </Grid>
                <Grid
                  item
                  md={5}
                  sm={5}
                  lg={5}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: 20,
                    paddingTop: 20,
                  }}
                >
                  <CustomTypography text={`Display by:`} fontweight='400' lineheight='21px' fontcolor={'#1A1A1A'} />
                  &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {displayByModal(topPropertiesByScoresortBy, 5, [
                      'Median score',
                      'Best Transport',
                      'Best Building',
                      'Best Deliverability & Risk',
                      'Best Location & Amenity',
                      'Best ESG',
                    ])}
                  </div>
                </Grid>
              </Grid>
              {topPropertiesByScoresortBy == 'Median score' ? (
                <div
                  style={{
                    width: '96%',
                    paddingLeft: '1%',
                    paddingRight: '3%',
                  }}
                >
                  {topPropertiesByScoreData.length > 0 ? (
                    <PropertyScoreBar data={topPropertiesByScoreData?.slice(0, 10)} tab={true} />
                  ) : (
                    <Grid
                      style={{
                        width: '100%',
                        height: '52vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <NoDataIcon />
                      <CustomTypography
                        text='No data available'
                        fontsize='25px'
                        fontweight='400'
                        lineheight='35px'
                        fontcolor='#565656'
                        marginTop='5px'
                        marginLeft='10px'
                      />
                    </Grid>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    width: '96%',
                    paddingLeft: '1%',
                    paddingRight: '3%',
                  }}
                >
                  <CustomBarComponent
                    data={topPropertiesByScoreData?.slice(0, 10)}
                    color={
                      topPropertiesByScoresortBy == 'Best Transport'
                        ? '#DBD99A'
                        : topPropertiesByScoresortBy == 'Best Building'
                        ? '#3E7CA6'
                        : topPropertiesByScoresortBy == 'Best Deliverability & Risk'
                        ? '#885073'
                        : topPropertiesByScoresortBy == 'Best Location & Amenity'
                        ? '#A388BF'
                        : '#80BBAD'
                    }
                    tab={true}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        );
      case 6:
        return (
          <Grid
            item
            md={6}
            sm={6}
            lg={6}
            style={{
              padding: 20,
              height: 480,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  width: '97%',
                  paddingLeft: '3%',
                  flexDirection: 'row',
                }}
              >
                <Grid
                  item
                  md={8}
                  sm={8}
                  lg={8}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <CustomTypography
                    text={
                      topPropertiesByValueInPoundsortBy == 'Value in points/£'
                        ? 'Top properties by value in points/£'
                        : 'Top properties by per sq.ft. cost'
                    }
                    fontfamily='Calibre-R'
                    fontsize='24px'
                    lineheight='40px'
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    marginTop={15}
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  sm={4}
                  lg={4}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: 20,
                    paddingTop: 20,
                  }}
                >
                  <CustomTypography text={`Display by:`} fontweight='400' lineheight='21px' fontcolor={'#1A1A1A'} />
                  &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {displayByModal(topPropertiesByValueInPoundsortBy, 6, ['Value in points/£', 'Per sq.ft. cost'])}
                  </div>
                </Grid>
              </Grid>
              <div style={{ width: '96%', paddingLeft: '1%', paddingRight: '3%' }}>
                {topPropertiesByValueInPoundData.length > 0 ? (
                  <CustomBarComponent
                    data={topPropertiesByValueInPoundData?.slice(0, 10)}
                    isCurrency={true}
                    tab={true}
                  />
                ) : (
                  <Grid
                    style={{
                      width: '100%',
                      height: '52vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <NoDataIcon />
                    <CustomTypography
                      text='No data available'
                      fontsize='25px'
                      fontweight='400'
                      lineheight='35px'
                      fontcolor='#565656'
                      marginTop='5px'
                      marginLeft='10px'
                    />
                  </Grid>
                )}
              </div>
            </Grid>
          </Grid>
        );
      case 7:
        return (
          <Grid
            item
            md={6}
            sm={6}
            lg={6}
            style={{
              padding: 20,
              height: 480,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  width: '97%',
                  paddingLeft: '3%',
                  flexDirection: 'row',
                }}
              >
                <Grid
                  item
                  md={12}
                  sm={12}
                  lg={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <CustomTypography
                    text={object.name}
                    fontfamily='Calibre-R'
                    fontsize='24px'
                    lineheight='40px'
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    marginTop={15}
                  />
                </Grid>
              </Grid>
              <div style={{ width: '95%', paddingLeft: '2%', paddingRight: '3%' }}>
                {topMostInfluentialScoringCriteriaData.length > 0 ? (
                  <CustomBarComponent
                    data={topMostInfluentialScoringCriteriaData?.slice(0, 10).map((el) => {
                      if (el.labelName === 'Proximity to regional Train station') {
                        return {
                          ...el,
                          labelName: 'REGIONAL - Proximity to mainline train station',
                          tooltipLabel: 'REGIONAL - Proximity to mainline train station',
                        };
                      } else if (el.labelName === 'Proximity to regional Tram station') {
                        return {
                          ...el,
                          labelName: 'REGIONAL - Proximity to tram station',
                          tooltipLabel: 'REGIONAL - Proximity to tram station',
                        };
                      } else return { ...el };
                    })}
                    tab={true}
                  />
                ) : (
                  <Grid
                    style={{
                      width: '100%',
                      height: '52vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <NoDataIcon />
                    <CustomTypography
                      text='No data available'
                      fontsize='25px'
                      fontweight='400'
                      lineheight='35px'
                      fontcolor='#565656'
                      marginTop='5px'
                      marginLeft='10px'
                    />
                  </Grid>
                )}
              </div>
            </Grid>
          </Grid>
        );
      case 8:
        let transportKey;
        if (mostPopularTransportHubssortBy === 'Shortlisting events') transportKey = 'COUNT_SHORTLIST_EVENTS';
        else if (mostPopularTransportHubssortBy === 'Longlisting events') transportKey = 'COUNT_LONGLIST_EVENTS';
        else transportKey = 'COUNT_VIEWED';

        return (
          <Grid
            item
            md={6}
            sm={6}
            lg={6}
            style={{
              padding: 20,
              height: 480,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  width: '97%',
                  paddingLeft: '3%',
                  flexDirection: 'row',
                }}
              >
                <Grid
                  item
                  md={6}
                  sm={6}
                  lg={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <CustomTypography
                    text={object.name}
                    fontfamily='Calibre-R'
                    fontsize='24px'
                    lineheight='40px'
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    marginTop={15}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={6}
                  lg={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: 20,
                    paddingTop: 20,
                  }}
                >
                  <CustomTypography text={`Display by:`} fontweight='400' lineheight='21px' fontcolor={'#1A1A1A'} />
                  &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {displayByModal(mostPopularTransportHubssortBy, 8, [
                      'Shortlisting events',
                      'Longlisting events',
                      'Viewed count',
                    ])}
                  </div>
                </Grid>
              </Grid>
              <div style={{ width: '95%', paddingLeft: '2%', paddingRight: '3%' }}>
                {mostPopularTransportHubsData.length > 0 ? (
                  <CustomBarComponent
                    data={mostPopularTransportHubsData?.filter((el) => el[transportKey]).slice(0, 10)}
                    tab={true}
                  />
                ) : (
                  <Grid
                    style={{
                      width: '100%',
                      height: '52vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <NoDataIcon />
                    <CustomTypography
                      text='No data available'
                      fontsize='25px'
                      fontweight='400'
                      lineheight='35px'
                      fontcolor='#565656'
                      marginTop='5px'
                      marginLeft='10px'
                    />
                  </Grid>
                )}
              </div>
            </Grid>
          </Grid>
        );
      case 9:
        return (
          <Grid
            item
            md={6}
            sm={6}
            lg={6}
            style={{
              padding: 20,
              height: 480,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  width: '97%',
                  paddingLeft: '3%',
                  flexDirection: 'row',
                }}
              >
                <Grid
                  item
                  md={5}
                  sm={5}
                  lg={5}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <CustomTypography
                    text={object.name}
                    fontfamily='Calibre-R'
                    fontsize='24px'
                    lineheight='40px'
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    marginTop={15}
                  />
                </Grid>
                <Grid
                  item
                  md={7}
                  sm={7}
                  lg={7}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: 20,
                    paddingTop: 20,
                  }}
                >
                  <CustomTypography text={`Display by:`} fontweight='400' lineheight='21px' fontcolor={'#1A1A1A'} />
                  &nbsp;
                  <CustomTypography text={`Category`} fontweight='bold' lineheight='21px' fontcolor={'#1A1A1A'} />
                  <CustomTypography
                    text={`Trend:`}
                    fontweight='400'
                    lineheight='21px'
                    fontcolor={'#1A1A1A'}
                    paddingLeft='10px'
                  />
                  &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {displayByTrend(weightingsOverTimeTrend, 9)}
                  </div>
                </Grid>
              </Grid>
              {!!weightingsOverTimeData?.length ? (
                <div
                  style={{
                    width: '95%',
                    paddingLeft: '2%',
                    paddingRight: '3%',
                  }}
                >
                  {weightingsOverTimeDisplayBy === 'Category' ? (
                    <CustomTimeBarComponent data={weightingsOverTimeData} tab={true} />
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <DefaultMessage />
              )}
            </Grid>
          </Grid>
        );
      case 10:
        return (
          <Grid
            item
            md={6}
            sm={6}
            lg={6}
            style={{
              padding: 20,
              height: 480,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  width: '97%',
                  paddingLeft: '3%',
                  flexDirection: 'row',
                }}
              >
                <Grid
                  item
                  md={5}
                  sm={5}
                  lg={5}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <CustomTypography
                    text={object.name}
                    fontfamily='Calibre-R'
                    fontsize='24px'
                    lineheight='40px'
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    marginTop={15}
                  />
                </Grid>

                <Grid
                  item
                  md={7}
                  sm={7}
                  lg={7}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: 20,
                    paddingTop: 20,
                  }}
                >
                  <CustomTypography text={`Display by:`} fontweight='400' lineheight='21px' fontcolor={'#1A1A1A'} />
                  &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {displayByModal(scoreOverTimeDisplayBy, 10, ['Category', 'Median score'])}
                  </div>
                  <CustomTypography
                    text={`Trend:`}
                    fontweight='400'
                    lineheight='21px'
                    fontcolor={'#1A1A1A'}
                    paddingLeft='10px'
                  />
                  &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {displayByTrend(scoreOverTimeTrend, 10)}
                  </div>
                </Grid>
              </Grid>
              <div style={{ width: '95%', paddingLeft: '2%', paddingRight: '3%' }}>
                {!!scoreOverTimeData?.length ? (
                  <>
                    {scoreOverTimeDisplayBy === 'Category' ? (
                      <CustomTimeBarComponent data={scoreOverTimeData} flag={true} tab={true} />
                    ) : (
                      <CustomeTimeLineMedianChart data={scoreOverTimeData} tab={true} />
                    )}
                  </>
                ) : (
                  <DefaultMessage />
                )}
              </div>
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid
            item
            md={6}
            sm={6}
            lg={6}
            style={{
              padding: 20,
              height: 480,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CustomTypography
                text={object.name}
                fontfamily='Calibre-R'
                fontsize='24px'
                lineheight='40px'
                fontweight='500'
                fontcolor='#003F2D'
                marginLeft={13}
              />
            </Grid>
          </Grid>
        );
    }
  };

  const singlePropCharts = (index, object) => {
    const width = Math.ceil(innerWidth * 0.9);
    const layout = {
      xaxis: {
        dtick: 10,
        zeroline: false,
        range: [-1, 110],
        tickfont: {
          family: 'Calibre-R',
          size: 12,
        },
      },
      yaxis: {
        zeroline: false,
      },
      // hovermode: 'y unified',
      autosize: true,
      dragmode: false,
      height: 430,
      width,
      margin: {
        l: 140,
        r: 0,
        b: 50,
        t: 50,
      },
      showlegend: false,
      hoverlabel: {
        // bordercolor: '#f2f2f2',
        font: {
          family: 'Calibre-R',
          size: 0,
        },
        namelength: 0,
      },
    };
    const config = {
      displayModeBar: false,
    };
    let keyValues;
    if (index === 3) {
      keyValues = showVsMarket
        ? {
            Transport: ['TRANSPORT', '#DBD99A'],
            '\xa0\xa0\xa0\xa0\xa0': ['\xa0\xa0\xa0\xa0\xa0', '#000'],
            Building: ['BUILDING', '#4D7CA2'],
            '\xa0\xa0\xa0\xa0': ['\xa0\xa0\xa0\xa0', '#000'],
            'Location & Amenity': ['LOCAL_AMENITY', '#A388BF'],
            '\xa0\xa0\xa0': ['\xa0\xa0\xa0', '#000'],
            ESG: ['ESG', '#80BBAD'],
            '\xa0\xa0': ['\xa0\xa0', '#000'],
            'Deliverability & Risk': ['DELIVERABILITY_RISK', '#885073'],
            '\xa0': ['\xa0', '#000'],
          }
        : {
            Transport: ['TRANSPORT', '#DBD99A'],
            Building: ['BUILDING', '#4D7CA2'],
            'Location & Amenity': ['LOCAL_AMENITY', '#A388BF'],
            ESG: ['ESG', '#80BBAD'],
            'Deliverability & Risk': ['DELIVERABILITY_RISK', '#885073'],
          };
    } else {
      keyValues = showVsMarketSc
        ? {
            Transport: ['TRANSPORT_SCORE', '#DBD99A'],
            '\xa0\xa0\xa0\xa0\xa0': ['\xa0\xa0\xa0\xa0\xa0', '#000'],
            Building: ['BUILDING_SCORE', '#4D7CA2'],
            '\xa0\xa0\xa0\xa0': ['\xa0\xa0\xa0\xa0', '#000'],
            'Location & Amenity': ['LOCAL_AMENITY_SCORE', '#A388BF'],
            '\xa0\xa0\xa0': ['\xa0\xa0\xa0', '#000'],
            ESG: ['ESG_SCORE', '#80BBAD'],
            '\xa0\xa0': ['\xa0\xa0', '#000'],
            'Deliverability & Risk': ['DELIVERABILITY_RISK_SCORE', '#885073'],
            '\xa0': ['\xa0', '#000'],
          }
        : {
            Transport: ['TRANSPORT_SCORE', '#DBD99A'],
            Building: ['BUILDING_SCORE', '#4D7CA2'],
            'Location & Amenity': ['LOCAL_AMENITY_SCORE', '#A388BF'],
            ESG: ['ESG_SCORE', '#80BBAD'],
            'Deliverability & Risk': ['DELIVERABILITY_RISK_SCORE', '#885073'],
          };
    }

    const values = Object.keys(keyValues);

    const cardStyle = {
      minHeight: 480,
      background: '#fff',
      margin: 20,
      boxSizing: 'border-box',
      display: 'flex',
      borderRadius: 10,
    };
    const cardConStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: 14,
      marginBottom: 12,
    };
    let cardName = '';
    if (propertyCard?.BUILDING_NAME && propertyCard.BUILDING_NAME.length > 16)
      cardName = `${propertyCard.BUILDING_NAME.slice(0, 15)}...`;
    else if (propertyCard?.BUILDING_NAME) cardName = propertyCard.BUILDING_NAME;
    else if (propertyCard?.ADDRESS && propertyCard.ADDRESS.length > 16)
      cardName = `${propertyCard.ADDRESS.slice(0, 15)}...`;
    else cardName = propertyCard?.ADDRESS;
    const imgFormat = ['JPG', 'jpg', 'jpeg'];
    let validImage = true;
    if (!propertyCard?.IMAGE || !imgFormat.includes(propertyCard?.IMAGE?.split('.').at(-1))) validImage = false;
    switch (index) {
      case 1:
        return (
          <Grid md={6}>
            <Grid style={{ ...cardStyle, maxHeight: 480 }}>
              {propertyCard && (
                <>
                  <Grid md={6} style={{ position: 'relative' }}>
                    <img
                      src={validImage ? propertyCard.IMAGE : FallbackImg}
                      alt={propertyCard.BUILDING_NAME}
                      style={{
                        objectFit: validImage ? 'cover' : 'fill',
                        width: '100%',
                        height: '100%',
                        borderRadius: 10,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        background: '#fff',
                        fontSize: 32,
                        right: 20,
                        top: 20,
                        borderRadius: 10,
                        width: 55,
                        height: 55,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {propertyCard.SCORE ? Math.round(propertyCard.SCORE) : '-'}
                    </div>
                  </Grid>

                  <Grid
                    md={6}
                    style={{
                      padding: '25px 25px 0px 25px',
                      boxSizing: 'border-box',
                    }}
                  >
                    <section>
                      <div style={{ fontSize: 32, paddingBottom: 5 }}>
                        <LightTooltip
                          enterNextDelay={100}
                          enterDelay={100}
                          title={propertyCard.BUILDING_NAME ? propertyCard.BUILDING_NAME : propertyCard.ADDRESS}
                          placement='top'
                          followCursor
                          style={{ margin: 0 }}
                        >
                          <span> {cardName ?? '-'}</span>
                        </LightTooltip>
                      </div>
                      <div style={{ fontSize: 18, opacity: 0.6 }}>{propertyCard.POSTAL_CODE ?? '-'}</div>
                    </section>
                    <Divider style={{ marginTop: 10, marginBottom: 20 }} />
                    <section>
                      <Grid style={cardConStyle}>
                        <span style={{ opacity: 0.6 }}>Overall market rank</span>
                        <span>{propertyCard.RANK ?? '-'}</span>
                      </Grid>
                      <Grid style={cardConStyle}>
                        <span style={{ opacity: 0.6 }}>Space available (sq.ft.)</span>
                        <span>{propertyCard.AVAILABLE_SPACE_SQFT ?? '-'}</span>
                      </Grid>
                      <Grid style={cardConStyle}>
                        <span style={{ opacity: 0.6 }}>Number of spaces available</span>
                        <span>{propertyCard.NUMBER_OF_SPACES ?? '-'}</span>
                      </Grid>
                      <Grid style={cardConStyle}>
                        <span style={{ opacity: 0.6 }}>Rent from (in £/sq.ft.)</span>
                        <span>{propertyCard.RENT ?? '-'}</span>
                      </Grid>
                      <Grid style={cardConStyle}>
                        <span style={{ opacity: 0.6 }}>Available from</span>
                        <span>{propertyCard.AVAILABLE_FROM ?? '-'}</span>
                      </Grid>
                      <Grid style={cardConStyle}>
                        <span style={{ opacity: 0.6 }}>Landlord</span>
                        <LightTooltip
                          enterNextDelay={100}
                          enterDelay={100}
                          title={propertyCard.LANDLORD ? propertyCard.LANDLORD : '-'}
                          placement='top'
                          followCursor
                          style={{ margin: 0 }}
                        >
                          <span>
                            {propertyCard.LANDLORD?.length > 17
                              ? propertyCard.LANDLORD.slice(0, 16) + '...'
                              : propertyCard.LANDLORD
                              ? propertyCard.LANDLORD
                              : '-'}
                          </span>
                        </LightTooltip>
                      </Grid>
                      <Grid style={cardConStyle}>
                        <span style={{ opacity: 0.6 }}>Condition</span>
                        <span>{propertyCard.CONDITION ?? '-'}</span>
                      </Grid>
                    </section>
                    <Divider style={{ marginTop: 10, marginBottom: 20 }} />
                    <section>
                      <Grid style={cardConStyle}>
                        <span style={{ opacity: 0.6 }}>No. of times longlisted</span>
                        <span>{propertyCard.COUNT_LONGLIST_EVENTS ?? '-'}</span>
                      </Grid>
                      <Grid style={cardConStyle}>
                        <span style={{ opacity: 0.6 }}>No. of times shortlisted</span>
                        <span>{propertyCard.COUNT_SHORTLIST_EVENTS ?? '-'}</span>
                      </Grid>
                      <Grid style={cardConStyle}>
                        <span style={{ opacity: 0.6 }}>No. of times viewed</span>
                        <span>{propertyCard.COUNT_VIEWED ?? '-'}</span>
                      </Grid>
                    </section>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid md={6}>
            <Grid
              style={{
                ...cardStyle,
                flexDirection: 'column',
                maxHeight: 480,
              }}
            >
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 20,
                  paddingRight: 30,
                  paddingBottom: 0,
                }}
              >
                <CustomTypography
                  text={object.name}
                  fontfamily='Calibre-R'
                  fontsize='24px'
                  lineheight='40px'
                  fontweight='400'
                  fontcolor='#1A1A1A'
                  marginTop={0}
                />
                <CustomTypography
                  text={`Display all criteria`}
                  fontweight='bold'
                  lineheight='21px'
                  fontcolor={'#1A1A1A'}
                />
              </Grid>
              <Grid
                style={{
                  padding: '0 30px 0 0',
                  marginTop: -10,
                  marginBottom: 10,
                  overflowY: 'auto',
                }}
              >
                {!!topMostInfluentialSP.length ? (
                  <CustomBarComponent
                    data={topMostInfluentialSP.map((el) => {
                      if (el.labelName === 'Proximity to regional Train station') {
                        return {
                          ...el,
                          labelName: 'REGIONAL - Proximity to mainline train station',
                          tooltipLabel: 'REGIONAL - Proximity to mainline train station',
                        };
                      } else if (el.labelName === 'Proximity to regional Tram station') {
                        return {
                          ...el,
                          labelName: 'REGIONAL - Proximity to tram station',
                          tooltipLabel: 'REGIONAL - Proximity to tram station',
                        };
                      } else return { ...el };
                    })}
                    height={topMostInfluentialSP.length <= 10 ? 350 : topMostInfluentialSP.length * 30}
                  />
                ) : (
                  <DefaultMessage />
                )}
              </Grid>
            </Grid>
          </Grid>
        );
      case 4:
        return (
          <Grid md={6}>
            <Grid
              style={{
                ...cardStyle,
                flexDirection: 'column',
                maxHeight: 480,
              }}
            >
              <Grid
                style={{
                  display: 'flex',
                  padding: '0 20px',
                  marginBottom: -10,
                }}
              >
                <Grid
                  item
                  md={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <CustomTypography
                    text={object.name}
                    fontfamily='Calibre-R'
                    fontsize='24px'
                    lineheight='40px'
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    marginTop={15}
                  />
                </Grid>
                <Grid
                  item
                  md={8}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: 0,
                    paddingTop: 20,
                  }}
                >
                  <Grid style={{ display: 'flex', alignItems: 'center' }}>
                    <CustomTypography
                      text='Show vs Market'
                      fontfamily='Calibre-R'
                      fontsize='12px'
                      lineheight='40px'
                      fontweight='400'
                      fontcolor='#1A1A1A'
                    />
                    <div
                      style={{
                        marginRight: 8,
                        marginBottom: -5,
                        marginLeft: 2,
                      }}
                      onClick={() => {
                        setShowVsMarketSc((flag) => !flag);
                      }}
                    >
                      <ButtonSwitch value={showVsMarketSc} />
                    </div>
                  </Grid>
                  {scoresPlotTypeSP === 'Box and whisker' && (
                    <Grid style={{ display: 'flex', alignItems: 'center' }}>
                      <CustomTypography
                        text='Show values'
                        fontfamily='Calibre-R'
                        fontsize='12px'
                        lineheight='40px'
                        fontweight='400'
                        fontcolor='#1A1A1A'
                      />
                      <div
                        style={{
                          marginRight: 8,
                          marginBottom: -5,
                          marginLeft: 2,
                        }}
                        onClick={() => {
                          setShowScoresOutliersSP((flag) => !flag);
                        }}
                      >
                        <ButtonSwitch value={showScoresOutliersSP} />
                      </div>
                    </Grid>
                  )}
                  <CustomTypography text={`Visualisation:`} fontweight='400' lineheight='21px' fontcolor={'#1A1A1A'} />
                  &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {displayByModal(scoresPlotTypeSP, 4, ['Box and whisker', 'Density plot'])}
                  </div>
                </Grid>
              </Grid>
              <div
                style={{
                  display: scoresPlotTypeSP !== 'Box and whisker' ? 'none' : 'block',
                  marginLeft: 10,
                  overflow: 'hidden',
                  borderBottomRightRadius: 10,
                }}
              >
                {!!dataScSP.length ? (
                  <BoxPlot data={[...dataScSP]} layout={layout} config={config} />
                ) : (
                  <DefaultMessage />
                )}
              </div>
              <div
                style={{
                  display: scoresPlotTypeSP !== 'Density plot' ? 'none' : 'block',
                  overflow: 'hidden',
                }}
              >
                {!!dataScSP.length ? (
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: 30,
                      width: '100%',
                      position: 'relative',
                    }}
                  >
                    {values.map((elm, i) => {
                      const validData = dataScDensity[keyValues[elm][0]]?.filter((el) => el);
                      let left;
                      const innerWidth1 = window.innerWidth;
                      if (innerWidth1 >= 2048) left = 85;
                      else if (innerWidth1 >= 1920) left = 80;
                      else if (innerWidth1 >= 1707) left = 74;
                      else if (innerWidth1 >= 1536) left = 65;
                      else left = 65;
                      let plotStyle = {
                        display: 'flex',
                        alignItems: 'center',
                      };

                      let transparent = false;
                      let title = elm;
                      if (showVsMarketSc) {
                        switch (elm) {
                          case '\xa0\xa0\xa0\xa0\xa0':
                            title = 'Transport';
                            break;
                          case '\xa0\xa0\xa0\xa0':
                            title = 'Building';
                            break;
                          case '\xa0\xa0\xa0':
                            title = 'Location & Amenity';
                            break;
                          case '\xa0\xa0':
                            title = 'ESG';
                            break;
                          default:
                            title = 'Deliverability & Risk';
                            break;
                        }
                        let top;

                        switch (elm) {
                          case '\xa0\xa0\xa0\xa0':
                            top = 78;
                            break;
                          case '\xa0\xa0\xa0':
                            top = 158;
                            break;
                          case '\xa0\xa0':
                            top = 237;
                            break;
                          default:
                            top = 306;
                            break;
                        }
                        plotStyle = {
                          display: 'flex',
                          alignItems: 'center',
                          position: i % 2 === 0 ? 'static' : 'absolute',
                          top: i % 2 === 0 || i === 1 ? 0 : top,
                          left,
                        };
                        transparent = i % 2 !== 0;
                      }
                      return (
                        <Grid key={elm} style={plotStyle}>
                          <Grid md={3}>
                            <CustomTypography
                              text={elm}
                              fontfamily='Calibre-R'
                              fontsize='14px'
                              lineheight='40px'
                              fontweight='400'
                              fontcolor='#1A1A1A'
                            />
                          </Grid>
                          <Grid md={9}>
                            <DensityChartWithAxis
                              data={Array.isArray(validData) ? validData : []}
                              bottom={elm === 'Deliverability & Risk' ? 20 : 10}
                              color={keyValues[elm][1]}
                              stats={
                                showVsMarketSc ? scoresD3_VS_SP[keyValues[elm][0]] : scoresD3_SP[keyValues[elm][0]]
                              }
                              showMarket={showVsMarketSc}
                              type={title}
                              pivot={2}
                              maxVal={100}
                              width={Math.ceil(innerWidth * 0.7)}
                              transparent={transparent}
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <DefaultMessage />
                )}
              </div>
            </Grid>
          </Grid>
        );
      case 3:
        return (
          <Grid md={6}>
            <Grid
              style={{
                ...cardStyle,
                flexDirection: 'column',
                maxHeight: 480,
              }}
            >
              <Grid
                style={{
                  display: 'flex',
                  padding: '0 20px',
                  marginBottom: -10,
                }}
              >
                <Grid
                  item
                  md={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <CustomTypography
                    text={object.name}
                    fontfamily='Calibre-R'
                    fontsize='24px'
                    lineheight='40px'
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    marginTop={15}
                  />
                </Grid>
                <Grid
                  item
                  md={8}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: 0,
                    paddingTop: 20,
                  }}
                >
                  <Grid style={{ display: 'flex', alignItems: 'center' }}>
                    <CustomTypography
                      text='Show vs Market'
                      fontfamily='Calibre-R'
                      fontsize='12px'
                      lineheight='40px'
                      fontweight='400'
                      fontcolor='#1A1A1A'
                    />
                    <div
                      style={{
                        marginRight: 8,
                        marginBottom: -5,
                        marginLeft: 2,
                      }}
                      onClick={() => {
                        setShowVsMarket((flag) => !flag);
                      }}
                    >
                      <ButtonSwitch value={showVsMarket} />
                    </div>
                  </Grid>
                  {weightingsPlotTypeSP === 'Box and whisker' && (
                    <Grid style={{ display: 'flex', alignItems: 'center' }}>
                      <CustomTypography
                        text='Show values'
                        fontfamily='Calibre-R'
                        fontsize='12px'
                        lineheight='40px'
                        fontweight='400'
                        fontcolor='#1A1A1A'
                      />
                      <div
                        style={{
                          marginRight: 8,
                          marginBottom: -5,
                          marginLeft: 2,
                        }}
                        onClick={() => {
                          setShowWeightingsOutliersSP((flag) => !flag);
                        }}
                      >
                        <ButtonSwitch value={showWeightingsOutliersSP} />
                      </div>
                    </Grid>
                  )}
                  <CustomTypography text={`Visualisation:`} fontweight='400' lineheight='21px' fontcolor={'#1A1A1A'} />
                  &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {displayByModal(weightingsPlotTypeSP, 3, ['Box and whisker', 'Density plot'])}
                  </div>
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: weightingsPlotTypeSP !== 'Box and whisker' ? 'none' : 'block',
                  marginLeft: 10,
                  overflow: 'hidden',
                  borderBottomRightRadius: 10,
                }}
              >
                {!!dataWtSP.length ? (
                  <BoxPlot data={[...dataWtSP]} layout={layout} config={config} />
                ) : (
                  <DefaultMessage />
                )}
              </Grid>
              <Grid
                style={{
                  display: weightingsPlotTypeSP !== 'Density plot' ? 'none' : 'block',
                  overflow: 'hidden',
                }}
              >
                {!!dataWtSP.length ? (
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: 30,
                      width: '100%',
                      position: 'relative',
                    }}
                  >
                    {values.map((elm, i) => {
                      const validData = dataWtDensity[keyValues[elm][0]]?.filter((el) => el);
                      let left;
                      const innerWidth1 = window.innerWidth;
                      if (innerWidth1 >= 2048) left = 85;
                      else if (innerWidth1 >= 1920) left = 80;
                      else if (innerWidth1 >= 1707) left = 74;
                      else if (innerWidth1 >= 1536) left = 65;
                      else left = 65;
                      let plotStyle = {
                        display: 'flex',
                        alignItems: 'center',
                      };

                      let transparent = false;
                      let title = elm;
                      if (showVsMarket) {
                        switch (elm) {
                          case '\xa0\xa0\xa0\xa0\xa0':
                            title = 'Transport';
                            break;
                          case '\xa0\xa0\xa0\xa0':
                            title = 'Building';
                            break;
                          case '\xa0\xa0\xa0':
                            title = 'Location & Amenity';
                            break;
                          case '\xa0\xa0':
                            title = 'ESG';
                            break;
                          default:
                            title = 'Deliverability & Risk';
                            break;
                        }
                        let top;
                        switch (elm) {
                          case '\xa0\xa0\xa0\xa0':
                            top = 78;
                            break;
                          case '\xa0\xa0\xa0':
                            top = 158;
                            break;
                          case '\xa0\xa0':
                            top = 237;
                            break;
                          default:
                            top = 306;
                            break;
                        }
                        plotStyle = {
                          display: 'flex',
                          alignItems: 'center',
                          position: i % 2 === 0 ? 'static' : 'absolute',
                          top: i % 2 === 0 || i === 1 ? 0 : top,
                          left,
                        };
                        transparent = i % 2 !== 0;
                      }
                      return (
                        <Grid key={elm} style={plotStyle}>
                          <Grid md={3}>
                            <CustomTypography
                              text={elm}
                              fontfamily='Calibre-R'
                              fontsize='14px'
                              lineheight='40px'
                              fontweight='400'
                              fontcolor='#1A1A1A'
                            />
                          </Grid>
                          <Grid md={9}>
                            <DensityChartWithAxis
                              data={Array.isArray(validData) ? validData : []}
                              bottom={elm === 'Deliverability & Risk' ? 20 : 10}
                              color={keyValues[elm][1]}
                              stats={
                                showVsMarket
                                  ? weightingsD3_VS_SP[keyValues[elm][0]]
                                  : weightingsD3_SP[keyValues[elm][0]]
                              }
                              showMarket={showVsMarket}
                              type={title}
                              pivot={2}
                              maxVal={100}
                              width={Math.ceil(innerWidth * 0.7)}
                              transparent={transparent}
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <DefaultMessage />
                )}
              </Grid>
            </Grid>
          </Grid>
        );
      case 5:
        const colors = {
          'Best Transport': '#DBD99A',
          'Best Building': '#3E7CA6',
          'Best Deliverability & Risk': '#885073',
          'Best Location & Amenity': '#A388BF',
          'Best ESG': '#80BBAD',
        };
        return (
          <Grid md={6}>
            <Grid
              style={{
                ...cardStyle,
                flexDirection: 'column',
                maxHeight: 480,
              }}
            >
              <Grid
                style={{
                  padding: 20,
                  paddingRight: 30,
                  paddingBottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <CustomTypography
                  text={object.name}
                  fontfamily='Calibre-R'
                  fontsize='24px'
                  lineheight='40px'
                  fontweight='400'
                  fontcolor='#1A1A1A'
                  marginTop={0}
                />
                <Grid style={{ display: 'flex', alignItems: 'center' }}>
                  <CustomTypography text={`Display by:`} fontweight='400' lineheight='21px' fontcolor={'#1A1A1A'} />
                  &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {displayByModal(topPropsByScoresortBySP, 5, [
                      'Median score',
                      'Best Transport',
                      'Best Building',
                      'Best Deliverability & Risk',
                      'Best Location & Amenity',
                      'Best ESG',
                    ])}
                  </div>
                </Grid>
              </Grid>

              <Grid
                style={{
                  padding: '0 30px 20px 20px',
                  marginTop: -10,
                }}
              >
                {!!topPropsByScoreDataSP.length ? (
                  topPropsByScoresortBySP === 'Median score' ? (
                    <PropertyScoreBar data={topPropsByScoreDataSP?.slice(0, 10)} />
                  ) : (
                    <CustomBarComponent
                      data={topPropsByScoreDataSP?.slice(0, 10)}
                      color={colors[topPropsByScoresortBySP]}
                    />
                  )
                ) : (
                  <DefaultMessage />
                )}
              </Grid>
            </Grid>
          </Grid>
        );
      case 6:
        return (
          <Grid md={6}>
            <Grid
              style={{
                ...cardStyle,
                flexDirection: 'column',
                maxHeight: 480,
              }}
            >
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 20,
                  paddingRight: 30,
                  paddingBottom: 0,
                }}
              >
                <CustomTypography
                  text={object.name}
                  fontfamily='Calibre-R'
                  fontsize='24px'
                  lineheight='40px'
                  fontweight='400'
                  fontcolor='#1A1A1A'
                  marginTop={0}
                />
                <Grid style={{ display: 'flex' }}>
                  <CustomTypography text={`Display by:`} fontweight='400' lineheight='21px' fontcolor={'#1A1A1A'} />
                  &nbsp;
                  <CustomTypography
                    text={`Value in points/£`}
                    fontweight='bold'
                    lineheight='21px'
                    fontcolor={'#1A1A1A'}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  padding: '0 30px 20px 0',
                  marginTop: -10,
                  overflow: 'hidden',
                }}
              >
                {!!topPropsByValueDataSP.length ? (
                  <CustomBarComponent
                    data={topPropsByValueDataSP.slice(0, 10)}
                    // height={topPropsByValueDataSP.length * 30}
                    isCurrency={true}
                  />
                ) : (
                  <DefaultMessage />
                )}
              </Grid>
            </Grid>
          </Grid>
        );
      case 7:
        return (
          <Grid md={6}>
            <Grid
              style={{
                ...cardStyle,
                flexDirection: 'column',
                maxHeight: 480,
              }}
            >
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 20,
                  paddingRight: 30,
                  paddingBottom: 0,
                }}
              >
                <Grid
                  item
                  md={5}
                  sm={5}
                  lg={5}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <CustomTypography
                    text={object.name}
                    fontfamily='Calibre-R'
                    fontsize='24px'
                    lineheight='40px'
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    marginTop={15}
                  />
                </Grid>

                <Grid
                  item
                  md={7}
                  sm={7}
                  lg={7}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: 20,
                    paddingTop: 20,
                  }}
                >
                  <CustomTypography text={`Display by:`} fontweight='400' lineheight='21px' fontcolor={'#1A1A1A'} />
                  &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {displayByModal(scoreOverTimeDisplayBySP, 7, ['Category', 'Median score'])}
                  </div>
                  <CustomTypography
                    text={`Trend:`}
                    fontweight='400'
                    lineheight='21px'
                    fontcolor={'#1A1A1A'}
                    paddingLeft='10px'
                  />
                  &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {displayByTrendSP(scoreOverTimeTrendSP, 7)}
                  </div>
                </Grid>
              </Grid>
              <div style={{ width: '95%', paddingLeft: '2%', paddingRight: '3%' }}>
                {!!scoreOverTimeDataSP?.length ? (
                  <>
                    {scoreOverTimeDisplayBySP === 'Category' ? (
                      <CustomTimeBarComponent data={scoreOverTimeDataSP} flag={true} />
                    ) : (
                      <CustomeTimeLineMedianChart data={scoreOverTimeDataSP} />
                    )}
                  </>
                ) : (
                  <DefaultMessage />
                )}
              </div>
            </Grid>
          </Grid>
        );
      case 8:
        let key;
        if (activeIndustriesSortBySP === 'Shortlisting events') key = 'COUNT_SHORTLIST_EVENTS';
        else if (activeIndustriesSortBySP === 'Longlisting events') key = 'COUNT_LONGLIST_EVENTS';
        else key = 'COUNT_VIEWED';
        const activeIndustriesDataSP = activeIndustriesSP.filter((el) => el[key]).slice(0, 10);
        return (
          <Grid md={6}>
            <Grid
              style={{
                ...cardStyle,
                flexDirection: 'column',
                maxHeight: 480,
              }}
            >
              <Grid
                style={{
                  padding: 20,
                  paddingRight: 30,
                  paddingBottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <CustomTypography
                  text={object.name}
                  fontfamily='Calibre-R'
                  fontsize='24px'
                  lineheight='40px'
                  fontweight='400'
                  fontcolor='#1A1A1A'
                  marginTop={0}
                />
                <Grid style={{ display: 'flex', alignItems: 'center' }}>
                  <CustomTypography text={`Display by:`} fontweight='400' lineheight='21px' fontcolor={'#1A1A1A'} />
                  &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {displayByModal(activeIndustriesSortBySP, 8, [
                      'Shortlisting events',
                      'Longlisting events',
                      'Viewed count',
                    ])}
                  </div>
                </Grid>
              </Grid>

              <Grid
                style={{
                  padding: '0 30px 20px 20px',
                  marginTop: -10,
                }}
              >
                {!!activeIndustriesSP.length && !!activeIndustriesDataSP.length ? (
                  <CustomBarComponent data={activeIndustriesDataSP} sortKey={activeIndustriesSortBySP} />
                ) : (
                  <DefaultMessage />
                )}
              </Grid>
            </Grid>
          </Grid>
        );
      case 9:
        return (
          <Grid md={6}>
            <Grid
              style={{
                ...cardStyle,
                flexDirection: 'column',
                maxHeight: 480,
              }}
            >
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 20,
                  paddingRight: 30,
                  paddingBottom: 0,
                }}
              >
                <Grid
                  item
                  md={5}
                  sm={5}
                  lg={5}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <CustomTypography
                    text={object.name}
                    fontfamily='Calibre-R'
                    fontsize='24px'
                    lineheight='40px'
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    marginTop={15}
                  />
                </Grid>
                <Grid
                  item
                  md={7}
                  sm={7}
                  lg={7}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: 20,
                    paddingTop: 20,
                  }}
                >
                  <CustomTypography text={`Display by:`} fontweight='400' lineheight='21px' fontcolor={'#1A1A1A'} />
                  &nbsp;
                  <CustomTypography text={`Category`} fontweight='bold' lineheight='21px' fontcolor={'#1A1A1A'} />
                  <CustomTypography
                    text={`Trend:`}
                    fontweight='400'
                    lineheight='21px'
                    fontcolor={'#1A1A1A'}
                    paddingLeft='10px'
                  />
                  &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {displayByTrendSP(weightingsOverTimeTrendSP, 9)}
                  </div>
                </Grid>
              </Grid>
              {!!weightingsOverTimeDataSP?.length ? (
                <div
                  style={{
                    width: '95%',
                    paddingLeft: '2%',
                    paddingRight: '3%',
                  }}
                >
                  {weightingsOverTimeDisplayBySP === 'Category' ? (
                    <CustomTimeBarComponent data={weightingsOverTimeDataSP} />
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <DefaultMessage />
              )}
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid md={6}>
            <Grid
              style={{
                ...cardStyle,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {object.name}
            </Grid>
          </Grid>
        );
    }
  };
  return (
    <Grid
      container
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        backgroundColor: '#F5F5F5',
        height: '100%',
      }}
    >
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'end',
          paddingBottom: 30,
          height: 130,
        }}
      >
        <Grid
          item
          md={6}
          sm={6}
          lg={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            paddingLeft: 60,
          }}
        >
          <CustomTypography
            text='Benchmarking'
            fontsize='36px'
            fontweight='400'
            lineheight='40px'
            fontcolor='#1A1A1A'
          />
        </Grid>
        {/* <Grid
          item
          md={6}
          sm={6}
          lg={6}
          x
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: 60,
          }}
        >
          <Button
            style={{ width: 240, height: 36, backgroundColor: '#003F2D' }}
          >
            <DownloadIcon />
            <CustomTypography
              text='Export'
              fontfamily='Calibre-R'
              fontsize='16px'
              lineheight='16px'
              fontweight='500'
              fontcolor='white'
              marginLeft={13}
            />
          </Button>
        </Grid> */}
      </Grid>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          height: 140,
          backgroundColor: '#FFFFFF',
          paddingLeft: 60,
          flexDirection: 'column',
          paddingRight: 60,
        }}
      >
        <Grid className='ag-theme-alpine' style={{ paddingTop: 22 }}>
          <List component={Stack} direction='row'>
            <ListItem
              style={{
                textAlign: 'center',
                fontSize: '20px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                borderBottom: toggleButtonIndex === 1 ? '2px solid #003F2D' : '1px solid #d9e2e0',
                color: '#003F2D',
                height: '36px',
                width: '209px',
                fontFamily: 'Calibre-R',
              }}
              button
              onClick={() => setToggleButtonIndex(1)}
            >
              <ListItemText
                primary={
                  <CustomTypography
                    text='Market overview'
                    fontsize='20px'
                    fontweight='500'
                    lineheight='30px'
                    justifycontent='center'
                    fontcolor={toggleButtonIndex === 1 ? '#003F2D' : '#1A1A1A'}
                  />
                }
                style={{
                  fontFamily: 'Calibre-R',
                  fontSize: '20px',
                  color: toggleButtonIndex === 1 ? '#003F2D' : '#1A1A1A',
                }}
              />
            </ListItem>
            <ListItem
              style={{
                textAlign: 'center',
                fontSize: '20px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                borderBottom: toggleButtonIndex === 2 ? '2px solid #003F2D' : '1px solid #d9e2e0',
                color: '#003F2D',
                height: '36px',
                width: '247px',
                fontFamily: 'Calibre-R',
              }}
              button
              onClick={() => setToggleButtonIndex(2)}
            >
              <ListItemText
                primary={
                  <CustomTypography
                    text='Single property'
                    fontsize='20px'
                    fontweight='500'
                    lineheight='30px'
                    justifycontent='center'
                    fontcolor={toggleButtonIndex === 2 ? '#003F2D' : '#1A1A1A'}
                  />
                }
              />
            </ListItem>
          </List>
        </Grid>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: 15,
          }}
        >
          {toggleButtonIndex === 1 && (
            <Grid item md={8} sm={8} lg={8} style={{ display: 'flex', flexDirection: 'row' }}>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingLeft: 37,
                }}
              >
                <CustomTypography
                  text={'Projects:' + '\xa0'}
                  fontsize='18px'
                  fontweight='500'
                  lineheight='40px'
                  fontcolor='#1A1A1A'
                />
                <CustomTypography
                  text={numberWithCommas(projectObject?.NO_OF_PROJECTS)}
                  fontsize='18px'
                  fontweight='400'
                  lineheight='40px'
                  fontcolor='#003F2D'
                />
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  marginLeft: 20,
                  marginRight: 20,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <VerticalLineIcon />
              </Grid>
              <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                <CustomTypography
                  text={'Clients:' + '\xa0'}
                  fontsize='18px'
                  fontweight='500'
                  lineheight='40px'
                  fontcolor='#1A1A1A'
                />
                <CustomTypography
                  text={numberWithCommas(projectObject?.NO_OF_CLIENTS)}
                  fontsize='18px'
                  fontweight='400'
                  lineheight='40px'
                  fontcolor='#003F2D'
                />
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  marginLeft: 20,
                  marginRight: 20,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <VerticalLineIcon />
              </Grid>
              <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                <CustomTypography
                  text={'Properties:' + '\xa0'}
                  fontsize='18px'
                  fontweight='500'
                  lineheight='40px'
                  fontcolor='#1A1A1A'
                />
                <CustomTypography
                  text={numberWithCommas(projectObject?.NO_OF_PROPERTIES)}
                  fontsize='18px'
                  fontweight='400'
                  lineheight='40px'
                  fontcolor='#003F2D'
                />
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  marginLeft: 20,
                  marginRight: 20,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <VerticalLineIcon />
              </Grid>
              <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                <CustomTypography
                  text={'Popular:' + '\xa0'}
                  fontsize='18px'
                  fontweight='500'
                  lineheight='40px'
                  fontcolor='#1A1A1A'
                />
                <CustomTypography
                  text={numberWithCommas(projectObject?.NO_OF_LONGLISTED)}
                  fontsize='18px'
                  fontweight='400'
                  lineheight='40px'
                  fontcolor='#003F2D'
                />
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  marginLeft: 20,
                  marginRight: 20,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <VerticalLineIcon />
              </Grid>
              <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                <CustomTypography
                  text={'Shortlisted:' + '\xa0'}
                  fontsize='18px'
                  fontweight='500'
                  lineheight='40px'
                  fontcolor='#1A1A1A'
                />
                <CustomTypography
                  text={numberWithCommas(projectObject?.NO_OF_SHORTLISTED)}
                  fontsize='18px'
                  fontweight='400'
                  lineheight='40px'
                  fontcolor='#003F2D'
                />
              </Grid>
            </Grid>
          )}
          {toggleButtonIndex === 1 ? (
            <Grid
              item
              md={4}
              sm={4}
              lg={4}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                <MultiSelectDropDown handleCallback={handleCallback} options={options} />
                <Button
                  onClick={() => setOpenFilter(true)}
                  style={{
                    width: 150,
                    height: 36,
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #003F2D',
                    marginLeft: 20,
                  }}
                >
                  <FilterIcon color={'#003F2D'} width={16} height={16} />
                  <CustomTypography
                    text='Filters'
                    fontfamily='Calibre-R'
                    fontsize='16px'
                    lineheight='16px'
                    fontweight='500'
                    fontcolor='#003F2D'
                    marginLeft={13}
                  />
                  <CustomTypography
                    text={'(' + filterCount + ')'}
                    fontfamily='Calibre-R'
                    fontsize='16px'
                    lineheight='16px'
                    fontweight='500'
                    fontcolor='#50AF78'
                    marginLeft={3}
                  />
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  style={{
                    height: 36,
                    backgroundColor: '#003F2D',
                    marginRight: 20,
                    paddingRight: 30,
                    paddingLeft: 30,
                  }}
                  onClick={() => {
                    setOpenDrawer(true);
                  }}
                >
                  <MdOutlineSearch
                    color='#fff'
                    style={{
                      width: '24px',
                      height: '24px',
                      marginRight: '10px',
                    }}
                  />
                  <CustomTypography
                    text='Select Property'
                    fontfamily='Calibre-R'
                    fontsize='16px'
                    lineheight='16px'
                    fontweight='500'
                    fontcolor='white'
                  />
                </Button>
                <MultiSelectDropDown
                  handleCallback={(items) => {
                    setSpOptions(items);
                  }}
                  options={spOptions}
                />
                <Button
                  variant='outlined'
                  onClick={() => {
                    setIsOpenSpFilter(true);
                  }}
                  className={!propertyHkey ? classes.disabledBtn : classes.btn}
                  disabled={!propertyHkey}
                  style={{
                    width: 150,
                    height: 36,
                    marginLeft: 20,
                  }}
                >
                  <FilterIcon color={!propertyHkey ? '#565656' : '#003F2D'} width={16} height={16} />
                  <CustomTypography
                    text='Filters'
                    fontfamily='Calibre-R'
                    fontsize='16px'
                    lineheight='16px'
                    fontweight='500'
                    fontcolor={!propertyHkey ? '#565656' : '#003F2D'}
                    marginLeft={13}
                  />
                  <CustomTypography
                    text={'(' + filterCountSP + ')'}
                    fontfamily='Calibre-R'
                    fontsize='16px'
                    lineheight='16px'
                    fontweight='500'
                    fontcolor='#50AF78'
                    marginLeft={3}
                  />
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          // alignItems: 'center',
          height: window.innerHeight - 270,
          paddingBottom: 40,
          overflowX: 'hidden',
        }}
      >
        {toggleButtonIndex === 1 &&
          options.map((object, index) => {
            if (object.checked) {
              return renderChartsUi(index + 1, object);
            } else {
              return null;
            }
          })}

        {toggleButtonIndex === 2 &&
          (propertyHkey ? (
            spOptions.map((elm, index) => {
              if (elm.checked) {
                return singlePropCharts(index + 1, elm);
              } else {
                return null;
              }
            })
          ) : (
            <Grid
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <NoDataIcon />
              <CustomTypography
                text='No data to display'
                fontfamily='Calibre-R'
                fontsize='25px'
                lineheight='35px'
                fontweight='400'
                fontcolor='#565656'
                marginTop='10px'
              />
              <CustomTypography
                text='Activate the charts by selecting a property.'
                fontfamily='Calibre-R'
                fontsize='16px'
                lineheight='24px'
                fontweight='400'
                fontcolor='#565656'
              />
            </Grid>
          ))}
      </Grid>
      {isLoading && <LoadingOverlayComponent open={isLoading} />}
      {openDrawer && (
        <SelectProperty
          open={openDrawer}
          setOpenDrawer={setOpenDrawer}
          setSnackbar={setSnackbar}
          propertiesData={propertiesData}
          setPropertyHkey={setPropertyHkey}
          setPropertiesData={setPropertiesData}
          isDataLoading={propertiesLoading}
        />
      )}
      {isOpenSpFilter && (
        <SinglePropFilter
          open={isOpenSpFilter}
          setOpen={setIsOpenSpFilter}
          setCount={setFilterCountSP}
          callback={callbackSPFilter}
        />
      )}
      {OpenFilter && (
        <BenchMarkingFilter
          open={OpenFilter}
          onClose={() => setOpenFilter(false)}
          filterObject={benchMarkingFilterObject}
          onCallback={handleCallbackFilterCount}
          handleApplyCallback={handleApplyCallback}
        />
      )}
      {snackbar && (
        <SnackBar
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          name={snackbar.name}
          message={snackbar.message}
        />
      )}
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
    </Grid>
  );
};
export default Benchmarking;
