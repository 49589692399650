import React from "react";

function UncheckedRadioIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 25 25"
        >
            <path
                fill="#767676"
                fillRule="evenodd"
                d="M2.938 12.5c0-5.52 4.48-10 10-10s10 4.48 10 10-4.48 10-10 10-10-4.48-10-10zm2 0c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8-8 3.58-8 8z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default UncheckedRadioIcon;
