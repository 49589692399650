import React, { useEffect, useState } from 'react';
import { Button, makeStyles, Grid } from '@material-ui/core';
import CustomTypography from '../utils/text';
import Searchicon from '../icons/search_icon';
import useStyles from '../styles/index';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import PlusIcon from '../icons/pluse_icon';
import ClientReportsGrid from '../components/ClientReportsGrid';
import LoadingOverlayComponent from '../components/lodding_component';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import SnackBar from '../components/SnackBar';
import WarningMessage from '../components/warning_snackbar';
import GetReportModal from '../components/GetReportModal';
import { downloadFileS3 } from '../utils/DownloadFileS3';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      borderColor: '#1a1a1a',
    },
    '&:hover $notchedOutline': {
      borderColor: '#205747 !important',
    },
    '&$focused $notchedOutline': {
      borderColor: '#205747 !important',
    },
  },
  focused: {},
  notchedOutline: {},
}));
const ClientReports = () => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const [projectData, setProjectData] = useState([]);
  const [search, setSearch] = useState('');
  const [searchProjectList, setSearchProjectList] = useState();
  const [isLodding, setIsLodding] = useState(true);
  const [pptLoading, setPptLoading] = useState(false);
  const email = useSelector((state) => state.mainReducer.email);
  const [fullName, setFullName] = useState(false);
  const hkey = useSelector((state) => state.mainReducer.hkey);
  const hubUser = useSelector((state) => state.mainReducer.hubUser);
  const { projectName } = useSelector((state) => state.mainReducer);
  const [snackbar, setSnackbar] = useState(null);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [reportOpen, setReportOpen] = useState(false);
  const [shorlistCount, setShorlistCount] = useState();
  const hwgtkey = useSelector((state) => state.mainReducer.hwgtkey);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    fetchProjectdetails();
    fetchUserDetails();
    fetchPropertyCount();
  }, []);

  const fetchUserDetails = async () => {
    try {
      const response = await axiosPrivate.get(`/client-report/user/${hubUser}`);
      if (response.status === 200 && response.data.length > 0) {
        const { FIRST_NAME, LAST_NAME } = response.data[0];
        const fName = FIRST_NAME ? `, ${FIRST_NAME}` : '';
        setFullName(`${LAST_NAME}${fName}`);
      }
    } catch (error) {
      console.log('Error while fetching the user details...!', error);
    }
  };

  const fetchPropertyCount = async () => {
    try {
      const response = await axiosPrivate.get(`/client-report/property-count/${hkey}/${hwgtkey}`);
      if (response.status === 200) {
        setShorlistCount(response.data.at(0)?.COUNT);
        if (!response.data[0].COUNT)
          setWarningSnackbar({
            name: '',
            message: 'You have not shortlisted any properties',
            severity: 'error',
          });
      }
    } catch (error) {
      console.log('Error while fetching the user details...!', error);
    }
  };

  const fetchProjectdetails = async () => {
    try {
      const response = await axiosPrivate.get(`/client-report/details/${hkey}`);
      setIsLodding(false);
      if (response.status === 200 && response.data.length > 0) {
        let projects = [];
        if (response.data?.length) {
          projects = response.data.map((elm, i) => ({
            ...elm,
            id: i + 1,
            LAST_EXPORTED_ON: elm.LAST_EXPORTED_ON?.slice(0, 16),
          }));
          setProjectData(projects);
        }
      }
    } catch (error) {
      setWarningSnackbar({
        name: 'Project Summary:',
        message: 'Unable to fetch project summary details',
        severity: 'error',
      });
    }
  };

  const handleSearch = (val) => {
    const str = val.toLowerCase();
    setSearch(val);
    if (projectData.length) {
      const list = projectData.filter(
        (el) => el.REPORT_NAME.toLowerCase().includes(str) || el.EXPORTED_BY.toLowerCase().includes(str)
      );
      setSearchProjectList(list);
    }
  };

  const reportHandler = async () => {
    setReportOpen(true);
    setPptLoading(true);
    fetchClientReportJson();
  };

  const fetchClientReportJson = async () => {
    try {
      const response = await axiosPrivate.get(`/client-report/json/${hkey}`);
      if (response.status === 200 && response.data.length > 0) {
        let payload = {};
        let shortlisted = 0;
        if (!response.data[0]?.REPORT_JSON_GENERATOR?.PROJECT_DETAILS) {
          setReportOpen(false);
          setWarningSnackbar({
            name: '',
            message:
              "Project doesn't have complete info to generate PPT file, please try after updating search criteria.",
            severity: 'error',
          });
          return;
        }
        payload = {
          ...response.data[0].REPORT_JSON_GENERATOR,
          PROJECT_DETAILS: {
            ...response.data[0].REPORT_JSON_GENERATOR.PROJECT_DETAILS,
            USER_NAME: fullName ? fullName : email,
          },
        };
        shortlisted = Object.keys(response.data[0]?.REPORT_JSON_GENERATOR?.SHORTLISTED).length;
        const reqObj = {
          pptLoad: { payload },
          projectName: projectName,
          email: email,
        };
        fetchUserCount(reqObj, shortlisted); // here we have to send projectname with payload
      }
    } catch (error) {
      setReportOpen(false);
      setWarningSnackbar({
        name: 'Error',
        message: 'while generating PPT file.',
        severity: 'error',
      });
    }
  };

  const fetchUserCount = async (reqObj, shortlisted) => {
    try {
      const response = await axiosPrivate.post(`/client-report/user-count`, reqObj);
      if (response.status === 200) {
        const link = response.data.link;
        console.log('res::', response.data.link);
        const fileName = link.split('/').at(-1);
        const reqObject = {
          hkey: hkey,
          fileName: fileName,
          shortlisted: shortlisted,
          hubUser: hubUser,
          link: link,
        };
        logClientReport(reqObject);
      }
    } catch (error) {
      setReportOpen(false);
      setWarningSnackbar({
        name: 'Error',
        message: 'while generating PPT file.',
        severity: 'error',
      });
    }
  };

  const logClientReport = async (reqObj) => {
    try {
      const response = await axiosPrivate.post(`/client-report/log`, reqObj);
      if (response.status === 200 && response.data.length) {
        if (reqObj.fileName) {
          downloadFileS3(reqObj.fileName, axiosPrivate)
            .then(() => {
              setPptLoading(false);
            })
            .catch(() => {
              setReportOpen(false);
              setWarningSnackbar({
                name: 'Error',
                message: 'while downloading PPT file.',
                severity: 'error',
              });
            });
        }
        fetchClientReportDetails();
      }
    } catch (error) {
      setWarningSnackbar({
        name: 'Error',
        message: 'while logging report data.',
        severity: 'error',
      });
    }
  };

  const fetchClientReportDetails = async () => {
    try {
      const response = await axiosPrivate.get(`/client-report/details/${hkey}`);
      if (response.status === 200 && response.data.length > 0) {
        let projects = [];
        if (response.data?.length) {
          projects = response.data.map((elm, i) => ({
            ...elm,
            id: i + 1,
            LAST_EXPORTED_ON: elm.LAST_EXPORTED_ON?.slice(0, 16),
          }));
          setProjectData(projects);
        }
      }
    } catch (error) {
      setReportOpen(false);
      setWarningSnackbar({
        name: 'Error',
        message: 'while generating PPT file.',
        severity: 'error',
      });
    }
  };

  return (
    <div style={{ height: '100vh', background: '#eef1f1' }}>
      <Grid
        style={{
          backgroundColor: '#eef1f1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 60,
            paddingLeft: 45,
            paddingRight: 45,
            paddingBottom: 28,
          }}
        >
          <CustomTypography
            text='Reports'
            fontfamily='Calibre-R'
            fontsize='36px'
            fontweight='400'
            lineheight='40px'
            fontcolor='#1A1A1A'
          />
          <Button
            className={classes.createbtn}
            style={{
              marginLeft: '0px',
              background: !!shorlistCount ? '#003f2d' : '#d4d7d7',
            }}
            onClick={reportHandler}
            disabled={!shorlistCount}
          >
            <PlusIcon
              style={{ paddingRight: 13 }}
              color={!!shorlistCount ? '#fff' : '#7f8080'}
              width={18}
              height={28}
            />
            <CustomTypography
              text='Create'
              fontsize='16px'
              fontfamily='Calibre-M'
              fontweight='500'
              lineheight='16px'
              padding='4px, 12px, 4px, 12px'
              fontcolor={!!shorlistCount ? '#fff' : '#7f8080'}
              marginRight='30'
            />
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'row',
          paddingLeft: 30,
          paddingRight: 30,
        }}
      >
        <Grid
          item
          md={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            backgroundColor: '#FFFFFF',
            height: '100%',
            padding: 15,
            alignItems: 'center',
          }}
        >
          <OutlinedInput
            style={{ height: 40, width: 300 }}
            id='outlined-adornment-weight'
            value={search}
            onChange={(event) => handleSearch(event.target.value)}
            placeholder={'Search'}
            endAdornment={
              <InputAdornment position='end'>
                <Searchicon />
              </InputAdornment>
            }
            aria-describedby='outlined-weight-helper-text'
            inputProps={{
              'aria-label': 'weight',
            }}
            classes={outlinedInputClasses}
          />
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'row',
          paddingLeft: 30,
          paddingRight: 30,
          height: '75%',
        }}
      >
        <ClientReportsGrid data={search ? searchProjectList : projectData} />
      </Grid>
      <GetReportModal
        open={reportOpen}
        setOpen={setReportOpen}
        loading={pptLoading}
      />
      {isLodding && <LoadingOverlayComponent open={isLodding} />}
      {snackbar && (
        <SnackBar
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          name={snackbar.name}
          message={snackbar.message}
        />
      )}
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
    </div>
  );
};
export default ClientReports;
