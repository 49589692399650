import React from "react";

function LeftVectorIcon({ width = "10", height = "17", ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 10 17"
            {...props}
        >
            <path
                fill="#003F2D"
                d="M0 15.192l1.475 1.475 8.333-8.334L1.475 0 0 1.475l6.858 6.858L0 15.192z"
            ></path>
        </svg>
    );
}

export default LeftVectorIcon;
