import React from "react";

function CirclecheckIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            fill="none"
            viewBox="0 0 20 21"
        >
            <path
                fill="#50AF78"
                d="M10 .5C4.48.5 0 4.98 0 10.5s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm-2 15l-5-5 1.41-1.41L8 12.67l7.59-7.59L17 6.5l-9 9z"
            ></path>
        </svg>
    );
}

export default CirclecheckIcon;
