import AWS from 'aws-sdk';
AWS.config.update({ region: 'eu-west-2' });

export const downloadFileS3 = async (filename = '', axiosPrivate) => {
  try {
    const res = await axiosPrivate.get(`/client-report/download-url/${filename}`);
    if (res.data?.downLoadUrl) {
      const link = document.createElement('a');
      link.href = res.data.downLoadUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      console.log('Download URL not found');
    }
  } catch (error) {
    console.error(error);
  }
};
