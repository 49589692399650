import React from "react";

function Property_Type_List_Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <g filter="url(#filter0_d_8186_19123)">
        <rect width="32" height="32" x="8" y="5" fill="#fff" rx="5"></rect>
      </g>
      <path
        fill="#1A1A1A"
        d="M24 21c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-1.8c0-3.63-2.65-6.2-6-6.2s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM24 11c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8 0-4.98 3.8-8.2 8-8.2z"
      ></path>
      <defs>
        <filter
          id="filter0_d_8186_19123"
          width="48"
          height="48"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="3"></feOffset>
          <feGaussianBlur stdDeviation="4"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8186_19123"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_8186_19123"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default Property_Type_List_Icon;
