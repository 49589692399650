import React from "react";

function ClearTextIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="12"
            fill="none"
            viewBox="0 0 33 12"
            {...props}
        >
            <path
                fill="#003F2D"
                d="M7.602 8.032c-.406 1.176-1.246 1.946-2.66 1.946-1.918 0-3.192-1.624-3.192-4.004 0-2.394 1.274-4.004 3.192-4.004 1.414 0 2.254.756 2.66 1.918h1.26C8.47 2.026 7.07.794 4.942.794 2.422.794.448 2.838.448 5.974c0 3.136 1.974 5.18 4.494 5.18 2.114 0 3.528-1.232 3.92-3.122h-1.26zm4.18-7.686h-1.247V11h1.246V.346zm6.777 8.47c-.21.826-.882 1.274-1.792 1.274-1.218 0-2.086-.952-2.114-2.492h5.152V7.15c0-2.1-1.064-3.57-3.052-3.57s-3.318 1.624-3.318 3.794c0 2.254 1.358 3.78 3.332 3.78 1.652 0 2.674-.952 2.954-2.338H18.56zm.014-2.156h-3.878c.168-1.274.952-2.03 2.058-2.03 1.218 0 1.778.952 1.82 2.03zm6.974 2.534c-.21.546-.938.91-1.707.91-.883 0-1.75-.42-1.75-1.288 0-.868.867-1.288 1.75-1.288.77 0 1.497.378 1.707.91v.756zM26.724 11V6.156c0-1.596-1.037-2.576-2.759-2.576-1.4 0-2.477.84-2.715 2.072h1.19c.237-.686.727-1.008 1.497-1.008 1.05 0 1.61.644 1.61 1.554v.924c-.322-.28-1.078-.602-1.834-.602-1.568 0-2.771.924-2.771 2.296 0 1.428 1.203 2.31 2.701 2.31.854 0 1.624-.364 1.904-.658V11h1.177zm5.856-7.308c-.126-.07-.406-.112-.728-.112-.798 0-1.484.476-1.778 1.092v-.938h-1.246V11h1.246V6.114c.238-.854.91-1.358 1.68-1.358.294 0 .616.07.826.196v-1.26z"
            ></path>
        </svg>
    );
}

export default ClearTextIcon;
