import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomTypography from '../utils/text';
import { Button } from '@material-ui/core';
import useStyles from '../styles';
import SliderComponent from '../components/slider_component';
import Dounught from '../components/doughnut';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ScoringCriteriaModal from '../components/ScoringCriteria';
import { Buffer } from 'buffer';
import LoadingOverlayComponent from '../components/lodding_component';
import WarningMessage from '../components/warning_snackbar';
import AlertBar from '../components/alertBar';
import useWindowDimensions from '../hooks/useWindowDimensions';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { isEqual } from 'lodash';
const EditWeighingsScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const ScoringCriteriaData = useSelector((state) => state.mainReducer.selectionObject);
  const ScoringDataUser = useSelector((state) => state.mainReducer.setProjectScore);
  const hkey = useSelector((state) => state.mainReducer.hkey);
  const hubweightingKey = useSelector((state) => state.mainReducer.hwgtkey);
  const hubUser = useSelector((state) => state.mainReducer.hubUser);
  const availableTo = useSelector((state) => state.mainReducer.availableTo);
  const [scoringData, setScoringData] = useState([]);
  const [scoring, setScoring] = useState(100);
  const [isLodding, setIsLodding] = useState(false);
  const [openScoringCriteria, setOpenScoringCriteria] = useState(false);
  const [isContinueEnable, setIsContinueEnable] = useState(false);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const projectStatus = useSelector((state) => state.mainReducer.selectedProjectStatus);
  const projectCompletedDate = useSelector((state) => state.mainReducer.selectedProjectCompletedDate);
  const [openAlert, setAlert] = useState(false);

  const [alertMessage, setAlertMessage] = useState('');
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    if (projectStatus === 'Completed' || projectStatus === 'Archived') {
      setAlert(true);
      projectStatus === 'Completed'
        ? setAlertMessage('- This project was marked as completed on')
        : setAlertMessage('- This project was marked as archived on');
    }
  }, [projectStatus]);

  useEffect(() => {
    if (ScoringCriteriaData) {
      var array = [];
      var total = 0;
      ScoringCriteriaData?.map((obj) => {
        var isselected = false;
        obj?.data?.map((objItem) => {
          if (objItem.value) {
            isselected = true;
          }
        });
        if (isselected) {
          array.push(obj);
          total = total + obj.score;
        }
      });
      setScoringData(array);
      setScoring(100 - total);
    }
  }, [ScoringCriteriaData]);
  useEffect(() => {
    let isEnable = false;
    const temp = ScoringDataUser.map((el) => {
      const item = scoringData.find((elm) => elm.header === el.header);
      if (item) {
        return { ...item };
      } else return { ...el };
    });

    if (isEqual(ScoringDataUser, temp)) {
      setIsContinueEnable(isEnable);
      return;
    }
    if (scoringData.every((el) => el.score > 0) && scoringData.reduce((a, c) => a + c.score, 0) === 100) {
      const flag =
        scoringData.every((el) => el.data.filter((elm) => elm.value).every((e) => e.score > 0)) &&
        scoringData.every((el) => el.data.filter((elm) => elm.value).reduce((a, c) => a + c.score, 0) === 100);
      if (flag) isEnable = true;
    }
    setIsContinueEnable(isEnable);
  }, [scoringData, ScoringDataUser]);
  const handleOnChanage = (value, name) => {
    var data = [...scoringData];
    var index = data.findIndex((e) => e.header == name);
    if (index != -1) {
      data[index] = { ...data[index], score: value };
    }
    var value = 0;
    data?.forEach((obj) => {
      value = value + obj.score;
    });
    setScoringData(data);
    setScoring(100 - value);
    if (ScoringCriteriaData) {
      ScoringCriteriaData?.map((item) => {
        if (data) {
          var obj = data.filter((e) => e.header == item.header);
          if (obj.length > 0) {
            item['score'] = obj[0].score;
          }
        }
      });
    }
    dispatch({ type: 'SET_SELECTION_OBJECT', payload: ScoringCriteriaData });
  };
  const handleClickContinue = () => {
    if (ScoringCriteriaData) {
      ScoringCriteriaData?.map((item) => {
        if (scoringData) {
          var obj = scoringData.filter((e) => e.header == item.header);
          if (obj.length > 0) {
            item['score'] = obj[0].score;
          }
        }
      });
    }
    dispatch({ type: 'SET_SELECTION_OBJECT', payload: ScoringCriteriaData });
  };
  const handleOnClickCategory = (name) => {
    handleClickContinue();
    var obj = scoringData.filter((e) => e.header == name);
    navigate('/map/updatesubCategoryweighting', {
      state: { data: obj[0], isEdit: true },
    });
  };
  const handleScoringBack = () => {
    setOpenScoringCriteria(false);
  };
  const handleScoreSubmit = () => {
    setOpenScoringCriteria(false);
    navigate('/map/editweightingview', { state: { value: 'demo' } });
  };
  const handleClose = () => {
    setOpenScoringCriteria(false);
  };
  const handleClickContinueScore = async () => {
    setIsLodding(true);
    handleSaveScore(hkey, hubweightingKey);
  };
  const handleSaveScore = async (hkeyValue, hwkeyValue) => {
    const reqObj = {
      ScoringCriteriaData: ScoringCriteriaData,
      hkey: hkey,
      scoringData: scoringData,
      hubUser: hubUser,
    };

    try {
      const response = await axiosPrivate.post(`/weightings/score`, reqObj);

      if (response.status === 200) {
        dispatch({ type: 'SET_CLEAR_SCORING_OBJECT' });
        const b = Buffer.from(response.data[0]['anonymous block'].data);
        dispatch({
          type: 'SET_HWGT_HKEY',
          payload: b.toString('hex').toUpperCase(),
        });
        WeightingProcessCall(hkeyValue, b.toString('hex').toUpperCase());
        dispatch({
          type: 'SELECTED_PROJECT_STATUS',
          payload: 'In progress',
        });
      }
    } catch (error) {
      console.log('error-------------------', error);
      setWarningSnackbar({
        name: 'Scoring Info:',
        message: 'Issue in weight/scoring calculation',
        severity: 'error',
      });
    }
  };

  const updateMapFilterProcedure = async () => {
    const reqObj = {
      hkey: hkey,
      hubUser: hubUser,
    };

    try {
      await axiosPrivate.post(`/weightings/mapfilter`, reqObj);
    } catch (error) {
      console.log('error-------------------', error);
      setWarningSnackbar({
        name: 'Error:',
        message: 'Issue in calling procedure',
        severity: 'error',
      });
    }
  }; // End of updateMapFilterProcedure

  const WeightingProcessCall = async (projectKey, hubweightingKey) => {
    handleUpdateWeightingKey(projectKey, hubweightingKey);

    const date = availableTo ? "'" + availableTo + "'" : null;

    const reqObj = {
      projectKey: projectKey,
      hubweightingKey: hubweightingKey,
      date: date,
    };

    try {
      const response = await axiosPrivate.post(`/weightings/process-weighting`, reqObj);
      dispatch({ type: 'SET_CLEAR_SCORING_OBJECT' });
      setIsLodding(false);
      if (response.status === 200) {
        updateMapFilterProcedure();
        navigate('/map/mapview');
      }
    } catch (error) {
      console.log('error-------------------', error);
      setIsLodding(false);
      setOpenScoringCriteria(false);
      setWarningSnackbar({
        name: 'Scoring Info:',
        message: 'Issue in weight/scoring calculation',
        severity: 'error',
      });
    }
  };

  const handleUpdateWeightingKey = async (projectKey, hubweightingKey) => {
    const reqObj = {
      projectKey: projectKey,
      hubweightingKey: hubweightingKey,
      hubUser: hubUser,
    };

    try {
      const response = await axiosPrivate.put(`/weightings/update-weightingkey`, reqObj);

      if (response.status === 200) {
      }
    } catch (error) {
      console.log('error while update-weightingkey----------', error);
      setWarningSnackbar({
        name: 'Scoring Info:',
        message: 'Issue in updating weighting key',
        severity: 'error',
      });
    }
  }; // end-of-handleUpdateWeightingKey

  const mainCategoryScore = React.useMemo(() => {
    var enterObjectArray = scoringData?.filter((e) => e.score > 0);
    var returnValue = enterObjectArray?.length + '/' + scoringData?.length;
    return returnValue;
  }, [scoringData]);

  return (
    <Grid
      container
      style={{
        backgroundColor: '#F5F5F5',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <>
        <Grid
          container
          style={{ display: 'flex', height: '18vh' }}
        >
          <Grid
            container
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 60,
              marginLeft: 60,
              marginRight: 60,
            }}
          >
            <Grid
              item
              xs={6}
              md={6}
            >
              <CustomTypography
                text={`Weightings`}
                fontsize='36px'
                fontweight='400'
                fontcolor='#1A1A1A'
                lineheight='40px'
                justifycontent='flex-start'
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                className={
                  projectStatus === 'Completed' || projectStatus === 'Archived'
                    ? classes.disablebuttonClass
                    : classes.backbtn
                }
                disabled={projectStatus === 'Completed' || projectStatus === 'Archived' ? true : false}
                style={{
                  marginRight: 30,
                  width: 255,
                  textTransform: 'inherit',
                }}
                onClick={() => setOpenScoringCriteria(true)}
              >
                <CustomTypography
                  text='Edit scoring criteria'
                  fontfamily='Calibre-SB'
                  fontsize='16px'
                  fontweight='500'
                  fontcolor={projectStatus === 'Completed' || projectStatus === 'Archived' ? '#7f8080' : '#003F2D'}
                  lineheight='16px'
                />
              </Button>
              <Button
                className={!isContinueEnable ? classes.disablebuttonClass : classes.buttonClass}
                style={{ width: 150, textTransform: 'inherit' }}
                onClick={handleClickContinueScore}
                disabled={!isContinueEnable}
              >
                <CustomTypography
                  text={'Recalculate scores'}
                  fontsize='16px'
                  fontfamily='Calibre-SB'
                  fontweight='500'
                  lineheight='16px'
                  fontcolor={!isContinueEnable ? '#7f8080' : 'white'}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            display: 'flex',
            backgroundColor: '#FFFFFF',
            height: '82vh',
            marginLeft: 30,
            marginRight: 30,
          }}
        >
          <Grid
            container
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <Grid
              item
              xs={5}
              md={5}
              style={{
                borderRight: '2px solid #F5F5F5',
                display: 'flex',
                flexDirection: 'column',
                height: '90vh',
                paddingBottom: '10vh',
                paddingRight: 10,
              }}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingTop: 30,
                  paddingLeft: 32,
                  paddingRight: 55,
                }}
              >
                <Grid
                  item
                  xs={10}
                  md={10}
                >
                  <CustomTypography
                    text={`Available weighting points`}
                    fontsize={width > 1300 ? '30px' : '26px'}
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    lineheight='40px'
                    justifycontent='flex-start'
                  />
                  <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                    <CustomTypography
                      text={mainCategoryScore + '\xa0'}
                      fontsize='14px'
                      fontweight='500'
                      fontcolor='#1A1A1A'
                      lineheight='16px'
                      justifycontent='flex-start'
                    />

                    <CustomTypography
                      text={`completed`}
                      fontsize='14px'
                      fontweight='500'
                      fontcolor='#767676'
                      lineheight='16px'
                      justifycontent='flex-start'
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={2}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Grid
                    style={{
                      width: '100%',
                      height: 57,
                      backgroundColor: '#D2E8DA',
                      alignItems: 'center',
                    }}
                  >
                    <CustomTypography
                      text={scoring}
                      fontsize='36px'
                      fontweight='400'
                      fontcolor='#1A1A1A'
                      lineheight='25px'
                      justifycontent='center'
                      marginTop='15px'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  borderBottom: '2px solid #F5F5F5',
                  paddingTop: 10,
                }}
              />
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingBottom: 65,
                  paddingTop: 15,
                  overflow: 'auto',
                }}
              >
                {scoringData?.map((object, index) => {
                  return (
                    <SliderComponent
                      projectStatus={projectStatus}
                      key={object.header}
                      paddingTop={index == 0 ? 30 : 60}
                      paddingRight={width > 1300 ? 52 : 32}
                      name={object.header}
                      score={object.score}
                      isMainCategory={true}
                      onChangeValue={(value, obj) => {
                        handleOnChanage(value, obj);
                      }}
                      data={scoringData}
                      rowData={object.data}
                      onClickCategory={handleOnClickCategory}
                    />
                  );
                })}
              </Grid>
            </Grid>
            <Grid
              item
              xs={7}
              md={7}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Dounught
                style={{ width: 500, height: 500 }}
                data={scoringData}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
      {isLodding && <LoadingOverlayComponent open={isLodding} />}
      {openScoringCriteria && (
        <ScoringCriteriaModal
          open={openScoringCriteria}
          handleClose={handleClose}
          handleSubmit={handleScoreSubmit}
          handleBack={handleScoringBack}
          isEdit={true}
        ></ScoringCriteriaModal>
      )}
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
      {openAlert && (
        <AlertBar
          open={true}
          setOpen={() => setAlert(null)}
          name={'View only mode '}
          message={alertMessage}
          // date field is pending
          date={projectCompletedDate}
        />
      )}
    </Grid>
  );
};
export default EditWeighingsScreen;