import React from 'react';

function ArchiveIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='104'
      height='137'
      fill='none'
      viewBox='0 0 104 137'
    >
      <path
        stroke='#003F2D'
        strokeWidth='3'
        d='M8.5 75c0-8.008 6.492-14.5 14.5-14.5h58c8.008 0 14.5 6.492 14.5 14.5v46c0 8.008-6.492 14.5-14.5 14.5H23c-8.008 0-14.5-6.492-14.5-14.5V75z'
      ></path>
      <rect
        width='31'
        height='5'
        x='36.5'
        y='88.5'
        stroke='#003F2D'
        strokeWidth='3'
        rx='2.5'
      ></rect>
      <path
        fill='#fff'
        d='M102 65V52a6 6 0 00-6-6H8a6 6 0 00-6 6v13a6 6 0 006 6h88a6 6 0 006-6z'
      ></path>
      <circle cx='52' cy='42' r='42' fill='#E6ECEA'></circle>
      <path
        fill='#003F2D'
        d='M39.953 40.5v3.752c0 .795.672 1.44 1.5 1.44.829 0 1.5-.645 1.5-1.44v-3.753c-.05-1.695.863-3.283 2.384-4.145a5.026 5.026 0 014.932 0c1.522.862 2.435 2.45 2.384 4.145v8.65c0 .132-.112.24-.25.24h-3.132c-.404 0-.769.234-.924.592a.932.932 0 00.217 1.046l4.886 4.692a1.03 1.03 0 001.414 0l4.887-4.692a.932.932 0 00.217-1.046 1.001 1.001 0 00-.924-.592h-3.137a.245.245 0 01-.25-.24v-8.65c.064-2.733-1.418-5.285-3.873-6.67a8.135 8.135 0 00-7.954 0c-2.455 1.385-3.937 3.937-3.873 6.67h-.004z'
      ></path>
      <path
        stroke='#003F2D'
        strokeWidth='3'
        d='M26 46H8a6 6 0 00-6 6v13a6 6 0 006 6h88a6 6 0 006-6V52a6 6 0 00-6-6H74'
      ></path>
    </svg>
  );
}

export default ArchiveIcon;
