import React from 'react';

function RenameIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='20'
      fill='none'
      viewBox='0 0 18 20'
    >
      <path
        fill='#1A1A1A'
        d='M0 19.001h3.75l11.06-11.06-3.75-3.75L0 15.251v3.75zm2-2.92l9.06-9.06.92.92-9.06 9.06H2v-.92zM15.37 1.291a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 000-1.41l-2.34-2.34z'
      ></path>
    </svg>
  );
}

export default RenameIcon;
