import React from 'react';

function DeleteIconConfirmation() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='142'
      height='123'
      fill='none'
      viewBox='0 0 142 123'
    >
      <circle cx='100' cy='42' r='42' fill='#FFDBDB'></circle>
      <path
        stroke='#003F2D'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='3'
        d='M2.438 37.722h112.124M99.938 37.688v73.124c0 5.385-4.366 9.75-9.75 9.75H26.812c-5.384 0-9.75-4.365-9.75-9.75V37.688M12.188 37.688v-4.876c0-5.384 4.365-9.75 9.75-9.75h73.124c5.385 0 9.75 4.366 9.75 9.75v4.876'
      ></path>
      <path
        stroke='#003F2D'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='3'
        d='M43.875 23.063c0-8.078 6.548-14.625 14.625-14.625s14.625 6.547 14.625 14.624M58.5 54.75v46.312M80.438 54.75v46.312M36.563 54.75v46.312'
      ></path>
    </svg>
  );
}

export default DeleteIconConfirmation;
