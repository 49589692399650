import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import useWindowDimensions from '../hooks/useWindowDimensions';
import LineImage from '../img/line.png';
const ValuePointsComponent = ({
  data = [],
  handleCallback,
  handleMouseOnLeaveProperty,
}) => {
  const [addressLine, setAddressLine] = useState([]);
  const [costScoreList, setCostScoreList] = useState([]);
  const { height, width } = useWindowDimensions();
  const screenHeight = React.useMemo(() => {
    return height;
  }, [height]);
  const screenWidth = React.useMemo(() => {
    return width;
  }, [width]);
  useEffect(() => {
    var addressList = [];
    var PropertyScore = [];
    var PropertyRank = [];
    var PropertyValuePoints = [];
    var PropertyTotalCost = [];
    data.forEach((obj) => {
      addressList.push(obj.ADDRESS_LINE1);
      PropertyValuePoints.push(obj.VALUE_POINTS);
      PropertyRank.push(obj.RANK);
      PropertyScore.push(obj.SCORE);
    });
    setAddressLine(addressList);
    var stackBarChartScoring = [
      {
        data: PropertyValuePoints,
        color: '#1F3765',
      },
    ];
    setCostScoreList(stackBarChartScoring);
  }, [data]);
  const handleClickProperty = (indexValue) => {
    if (indexValue != -1 && addressLine.length > indexValue) {
      handleCallback(data[indexValue]);
    }
  };
  return (
    <React.Fragment>
      <div style={{ display: 'flex' }}>
        <Chart
          type='bar'
          width={screenWidth / 2 - 160}
          height={screenHeight / 2 - 80}
          stacktype='normal'
          series={costScoreList}
          options={{
            chart: {
              type: 'bar',
              stacked: true,
              // height: 450,
              events: {
                stacktype: 'normal',
                stacked: true,
                dataPointMouseEnter: function (event, chartContext, config) {
                  handleClickProperty(config?.dataPointIndex);
                },
                dataPointMouseLeave: function (event, chartContext, config) {
                  handleMouseOnLeaveProperty();
                },
              },
            },
            grid: {
              show: true,
              position: 'back',
              strokeDashArray: 2,
              borderColor: 'rgba(203, 209, 211, 1)',
              xaxis: {
                lines: {
                  show: true,
                },
              },
              yaxis: {
                lines: {
                  show: false,
                },
              },
              padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 20,
              },
            },
            plotOptions: {
              bar: {
                horizontal: true,
                barHeight: '60%',
              },
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: addressLine,
              type: 'category',
              labels: {
                formatter: function (val) {
                  return val.toFixed(2);
                },
              },
              axisBorder: {
                show: false,
              },
            },
            yaxis: {
              labels: {
                show: true,
                align: 'left',
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
            tooltip: {
              followCursor: false,
              shared: false,
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let obj = data[dataPointIndex];
                return `<style>
                                         .root{ 
                                            padding-right: 20px;
                                            padding-left: 20px;
                                            padding-bottom: 10px;
                                            background-color: #FFFFFF;
                                            }
                                            .top10costcontainer {
                                            position: relative;font-family:'Calibre-R';
                                            padding-top:15px;
                                            }
                                            div.c {
                                                overflow-wrap: anywhere;
                                              }
                                            .text-block {
                                                position: absolute; bottom: 5px;right: 10px;top: 20px;width: 25px;height: 28px;
                                                weight: 40px;background-color: #DFE6E5;padding-left: 7px;padding-right: 7px;
                                                border-radius: 10px;text-align: center;
                                            }
                                            span-cost {
                                                color:#1a1a1a99;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;font-style: normal;left: 1454px;right: 213.94px;top: 891px;                                               
                                            }
                                            span-address {
                                                color:#000000;font-style:normal;font-weight:400;line-height:24px;font-size:20px;font-family:'Calibre-R';text-align=left;left: 1454px;right: 211px;top: 816px;overflow-wrap: anywhere;
                                            }
                                            span-rank {
                                                color:#1a1a1a99;font-style:normal;font-weight:400;line-height:24px;font-size:14px;font-family:'Calibre-R';text-align=left;height: 24px;left: 1454px;right: 211px;top: 840px;
                                            }                                            
                                            span-right_value_points {
                                                color:#000000;font-weight:540;font-style: normal;line-height:24px;font-size:12px;font-family:'Calibre-R';left: 1674px;right: 206px;top: 891px;float:right;padding-right: 15px;
                                            }
                                            P {
                                                padding : 0;
                                                margin : 0;
                                                line-height : 22px;
                                            }
                                         </style>
                                         <div class="root">
                                            <div class="top10costcontainer">                                               
                                            <span-address>${
                                              obj.ADDRESS_LINE1.length > 20
                                                ? obj.ADDRESS_LINE1.substring(
                                                    0,
                                                    20
                                                  ) + '...'
                                                : obj.ADDRESS_LINE1
                                            }</span-address>
                                                <div class="text-block">
                                                    <span style="font-size:20px;font-family:'Calibre-R';text-align:center;font-style:normal;font-weight:400;height: 24px;left: 1674px; right: 206px;top: 308px;">${
                                                      Math.round(obj.SCORE) ||
                                                      ''
                                                    }</span>
                                                </div>
                                            </div>
                                            <p>
                                            <span-rank>Rank</span-rank>&nbsp;<span-rank>${
                                              obj.RANK || ''
                                            }</span-rank>
                                            </p>
                                            <p>
                                                <img src=${LineImage} style="width: 260px;height: 1px;left: 1454px;top: 362px;"/>
                                            </p>
                                            <p>
                                                <span-cost>Points per pound</span-cost>
                                                <span-right_value_points>${
                                                  obj.VALUE_POINTS || ''
                                                }</span-right_value_points>
                                                <span 
                                            </p>                                            
                                        </div > `;
              },
            },
          }}
        />
      </div>
    </React.Fragment>
  );
};
export default ValuePointsComponent;
