export const BM_OPTIONS = [
  {
    id: 1,
    name: 'Typical weightings',
    checked: true,
  },
  {
    id: 2,
    name: 'Most active areas',
    checked: true,
  },
  {
    id: 3,
    name: 'Typical score',
    checked: true,
  },
  {
    id: 4,
    name: 'Most active industries',
    checked: true,
  },
  {
    id: 5,
    name: 'Top properties by Score',
    checked: true,
  },
  {
    id: 6,
    name: 'Top properties by value in points/£',
    checked: true,
  },
  {
    id: 7,
    name: 'Top most influential scoring criteria',
    checked: true,
  },
  {
    id: 8,
    name: 'Top most popular transport hubs',
    checked: true,
  },
  {
    id: 9,
    name: 'Weighting over time',
    checked: true,
  },
  {
    id: 10,
    name: 'Score over time',
    checked: true,
  },
];

export const BM_GLOBALSCORES = {
  BUILDING_SCORE: [],
  DELIVERABILITY_RISK_SCORE: [],
  ESG_SCORE: [],
  LOCAL_AMENITY_SCORE: [],
  TRANSPORT_SCORE: [],
};
export const BM_GLOBALWEIGHTINGS = {
  BUILDING: [],
  DELIVERABILITY_RISK: [],
  ESG: [],
  LOCAL_AMENITY: [],
  TRANSPORT: [],
};

export const MV_TUBE_CONNECTIONS = [
  'Bakerloo',
  'Central',
  'Circle',
  'District',
  'Elizabeth',
  'Hammersmith & City',
  'Hammersmith and City',
  'Jubilee',
  'Metropolitan',
  'Northern',
  'Piccadilly',
  'Victoria',
  'Waterloo and City',
  'DLR',
  'Overground',
  'Heathrow Express',
];

export const BM_SP_OPTIONS = [
  {
    id: 1,
    name: 'Property Card',
    checked: true,
  },
  {
    id: 2,
    name: 'Top most influential scoring criteria',
    checked: true,
  },
  {
    id: 3,
    name: 'Typical weightings',
    checked: true,
  },
  {
    id: 4,
    name: 'Typical score',
    checked: true,
  },
  {
    id: 5,
    name: 'Competitors',
    checked: true,
  },
  {
    id: 6,
    name: 'Competitors by value in points/£',
    checked: true,
  },
  {
    id: 7,
    name: 'Score over time',
    checked: true,
  },
  {
    id: 8,
    name: 'Most active industries',
    checked: true,
  },
  {
    id: 9,
    name: 'Weightings over time',
    checked: true,
  },
];

export const allSubWeightings = {
  TR_PROXIMITY_NEAREST_TUBE_STATION: 'Proximity to nearest tube station',
  TR_PROXIMITY_MAINLINE_TRAIN_STATION: 'Proximity to mainline train station',
  TR_PROXIMITY_ELIZABETH_LINE: 'Proximity to Elizabeth Line',
  TR_TUBES_WITHIN_1KM: 'Tube lines within 1km',
  TR_PROXIMITY_REGIONAL_TRAIN_STATION: 'REGIONAL - Proximity to mainline train station',
  TR_PROXIMITY_REGIONAL_TRAM_STATION: 'REGIONAL - Proximity to tram station',
  BI_ON_SITE_DEDICATED_GYM: 'On-site Dedicated Gym',
  BI_ON_SITE_CATERING_AVAILABLE: 'On-site Catering Available',
  BI_TERRACES: 'Terraces',
  BI_FLOOR_PLATE_SIZE: 'Floor plate suitability',
  BI_LOOK_AND_FEEL: 'Look and Feel',
  BI_FLOOR_TO_CEILING_HEIGHT: 'Floor to ceiling height',
  BI_OCCUPATIONAL_DENSITY: 'Occupational Density',
  BI_COLUMNS: 'Columns',
  BI_NATURAL_LIGHT: 'Natural Light',
  BI_ON_SITE_AMENITY: 'On-site Amenity',
  BI_ON_SITE_SHOWER_FACILITIES: 'On-site shower facilities',
  BI_SELF_CONTAINED: 'Self-contained',
  BI_WIREDSCORE_RATING: 'WiredScore Rating',
  BI_BUILDING_TECHNOLOGY: 'Building Technology',
  BI_BRANDING_RIGHTS: 'Branding Rights',
  BI_AIR_CONDITIONING: 'Air Conditioning',
  BI_RAISED_FLOORS: 'Raised Floors',
  BI_CAR_PARKING_SPACES: 'Car Park Spaces',
  BI_LOCKERS: 'Lockers',
  BI_SINGLE_LET_BUILDING: 'Single let Building',
  LA_TRANSPORT_HUB: 'Proximity to transport hubs',
  LA_LEVEL_LOCAL_AMENITY: 'Level of local amenity',
  LA_GREEN_SPACE: 'Green Spaces',
  LA_PERSONAL_SAFETY: 'Personal safety',
  LA_PROX_GYM_HOTEL: 'Proximity to gyms/hotels',
  LA_PROX_CLIENT_COMPETITOR: 'Proximity to Clients/Competitors',
  LA_FLEX_OPTIONS: 'Flex options in the immediate area',
  ESG_BREEAM_RATING: 'BREEAM Rating',
  ESG_NABERS_RATING: 'Nabers Rating',
  ESG_EPC_RATING: 'EPC Rating',
  ESG_ALL_ELECTRIC_BUILDING: 'All-Electric Building',
  ESG_NET_CARBON_ZERO: 'Net Carbon Zero',
  ESG_NATURALLY_VENTILATED: 'Naturally Ventilated',
  ESG_WELL_RATING: 'WELL Rating',
  ESG_CYCLE_SPACES: 'Cycle Spaces',
  DR_DELIVERY_DATE: 'Delivery status',
  DR_LANDLORD_CREDIBILITY: 'Landlord Credibility',
};

export const labels = [
  'Proximity to nearest tube station',
  'Proximity to mainline train station',
  'Proximity to Elizabeth Line',
  'Tube lines within 1km',
  'REGIONAL - Proximity to mainline train station',
  'REGIONAL - Proximity to tram station',
  'On-site Dedicated Gym',
  'On-site Catering Available',
  'Terraces',
  'Floor plate suitability',
  'Look and Feel',
  'Floor to ceiling height',
  'Occupational Density',
  'Columns',
  'Natural Light',
  'On-site Amenity',
  'On-site shower facilities',
  'Self-contained',
  'WiredScore Rating',
  'Building Technology',
  'Branding Rights',
  'Air Conditioning',
  'Raised Floors',
  'Car Park Spaces',
  'Lockers',
  'Single-let building',
  'Proximity to transport hubs',
  'Level of local amenity',
  'Green Spaces',
  'Personal safety',
  'Proximity to Clients/Competitors',
  'Proximity to gyms/hotels',
  'Flex options in the immediate area',
  'BREEAM Rating',
  'Nabers Rating',
  'EPC Rating',
  'All-Electric Building',
  'Net Carbon Zero',
  'Naturally Ventilated',
  'WELL Rating',
  'Cycle Spaces',
  'Delivery status',
  'Landlord Credibility',
  'Transport',
  'Building',
  'Location & Amenity',
  'ESG',
  'Deliverability & Risk',
  'Custom Criteria',
  'Available points',
];

export const snowLink =
  'https://cbre.service-now.com/nav_to.do?uri=%2Fcom.glideapp.servicecatalog_cat_item_view.do%3Fv%3D1%26sysparm_id%3Da4d4eb563ce491803f4d47212e89e27a%26sysparm_link_parent%3Dc3d3e02b0a0a0b12005063c7b2fa4f93%26sysparm_catalog%3De0d08b13c3330100c8b837659bba8fb4%26sysparm_catalog_view%3Dcatalog_Service_Catalog%26sysparm_view%3Dcatalog_default';

export const samplePrompts = [
  {
    value: '“Show me top ranked Universities located near my shortlisted properties.”',
    helpText: 'This AI Assistant is an experimental feature, so results may vary depending on various aspects.',
  },
  {
    value: '“Show me the best restaurants near my shortlisted properties that are open for lunch.”',
    helpText: 'Answers may change based availability of data and how the system interprets your prompts.',
  },
  {
    value: '“Show me properties with a water front within walking distance of my properties.”',
    helpText: 'Currently, there is no guarantee that the data shown is accurate or up to date. ',
  },
];

export const EPC_RATINGS = [
  {
    id: 'A',
    title: 'A',
    isChecked: false,
  },
  {
    id: 'B',
    title: 'B',
    isChecked: false,
  },
  {
    id: 'C',
    title: 'C',
    isChecked: false,
  },
  {
    id: 'D',
    title: 'D',
    isChecked: false,
  },
  {
    id: 'E',
    title: 'E',
    isChecked: false,
  },
  {
    id: 'F',
    title: 'F',
    isChecked: false,
  },
  {
    id: 'G',
    title: 'G',
    isChecked: false,
  },
  {
    id: 'None',
    title: 'None',
    isChecked: false,
  },
];
export const attributes = {
  showKeyTransport: true,
  showKeyBuilding: true,
  showKeyESG: true,
  showKeyDeliverabiliy: true,
  showKeyLocation: true,
  showKeyCustom: true,
};
