import { useEffect, useMemo, useState } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, makeStyles, Button } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { MdOutlineCheckCircleOutline } from 'react-icons/md';
import Searchicon from '../icons/search_icon';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import CustomTypography from '../utils/text';
import CloseIcon from '../icons/close_icon';
import WarningMessage from '../components/warning_snackbar';
import { useSelector, useDispatch } from 'react-redux';

const GetReportModal = ({ open, setOpen, loading }) => {
  const [popup, setPopup] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const projectName = useSelector((state) => state.mainReducer.projectName);
  const useStyles = useMemo(
    () =>
      makeStyles({
        dialog: {
          height: !loading ? 301 : 405,
          width: 457,
          borderRadius: '10px',
        },
        paper: {
          '&.MuiAutocomplete-paper': {
            padding: '0px',
          },
        },
        btn: {
          '&:hover': {
            color: 'white',
            background: '#003F2D',
          },
          background: '#003F2D',
          color: 'white',
          height: 36,
          width: 180,
          '&.MuiButton-outlined': {
            border: '1px solid #003F2D',
          },
        },
      }),
    [loading]
  );
  const classes = useStyles();

  const cancelHandler = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle style={{ margin: '30px', marginBottom: 0, padding: 0 }}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CustomTypography
            text='Getting your report ready'
            fontsize='24px'
            fontweight='500'
            lineheight='24px'
            fontcolor='#1F1E1E'
            letterSpacing='-0.01em'
          />
        </Grid>
        <CloseIcon
          onClick={() => {
            setOpen(false);
          }}
          style={{
            position: 'absolute',
            right: 35,
            top: 40,
            cursor: 'pointer',
            opacity: 0.8,
            width: '12px',
          }}
        />
      </DialogTitle>
      <DialogContent style={{ marginTop: 50, padding: 0 }}>
        <Grid
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              width: 54,
              height: 54,
              background: '#D7F1F9',
              borderRadius: '50%',
              position: 'relative',
            }}
          >
            {!loading && (
              <Grid
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <MdOutlineCheckCircleOutline
                  style={{
                    color: '#003F2D',
                  }}
                  size={31}
                />
              </Grid>
            )}
          </div>
        </Grid>
        {loading && (
          <Grid
            style={{
              position: 'absolute',
              left: '50%',
              top: '91px',
              transform: 'translate(-50%, 0)',
            }}
          >
            <CircularProgress
              style={{
                color: '#003F2D',
              }}
              disableShrink
              size={80}
            />
          </Grid>
        )}
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '55px',
          }}
        >
          <CustomTypography
            text={loading ? 'Creating a new version of' : 'Your report is ready'}
            fontfamily='Calibre-R'
            fontsize='20px'
            lineheight='20px'
            fontweight='400'
            fontcolor='#000000'
            letterSpacing='-0.01em'
          />
          {loading && (
            <CustomTypography
              text={projectName}
              fontfamily='Calibre-R'
              fontsize='20px'
              lineheight='30px'
              fontweight='400'
              fontcolor='#000000'
              letterSpacing='-0.01em'
            />
          )}
        </Grid>
      </DialogContent>
      {loading && (
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 30,
            padding: 0,
          }}
        >
          <CustomTypography
            text='Please wait while we create your report'
            fontfamily='Calibre-R'
            fontsize='16px'
            lineheight='16px'
            fontweight='400'
            fontcolor='#767676'
          />
        </DialogActions>
      )}
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
    </Dialog>
  );
};

export default GetReportModal;
