import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect, useState } from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
import './App.css';
import routes from './routes';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Provider } from 'react-redux';
import StoreConfig from './redux/store';
import { useMsal, MsalProvider, useIsAuthenticated } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { datadogRum } from '@datadog/browser-rum';
import WarningMessagelert from './components/warning_snackbar';
const pca = new PublicClientApplication(msalConfig);

//Datadog Rum Code starts
datadogRum.init({
  applicationId: process.env.REACT_APP_APPLICATION_ID,
  clientToken: process.env.REACT_APP_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'uk-data-platform',

  // Specify a version number to identify the deployed version of your application in Datadog
  version: '1.0.0',
  sampleRate: 100,
  sessionReplaySampleRate: 20,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();
//Datadog Rum Code Ends

function ChildComponet() {
  const routing = useRoutes(routes);
  const location = useLocation();
  const { accounts } = useMsal();
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  useEffect(() => {
    if (StoreConfig.store.getState().mainReducer.isOauthTokenExpired) {
      setWarningSnackbar({
        name: 'OAuth access token expired:',
        message: 'Please login again...',
        severity: 'error',
      });
    }
  }, [StoreConfig.store.getState().mainReducer.isOauthTokenExpired, StoreConfig.store.getState().mainReducer]);
  return (
    <Provider store={StoreConfig.store}>
      <>
        <div
          className='App'
          style={{
            backgroundColor: location.pathname === '/map/charts' ? 'rgb(238, 241, 241)' : 'white',
            height: '100vh',
          }}
        >
          {routing}
        </div>
        {warningSnackbar && (
          <WarningMessagelert
            open={true}
            setOpen={() => setWarningSnackbar(null)}
            severity={warningSnackbar.severity}
            name={warningSnackbar.name}
            message={warningSnackbar.message}
          />
        )}
      </>
    </Provider>
  );
}
function App() {
  return (
    <MsalProvider instance={pca}>
      <ChildComponet />
    </MsalProvider>
  );
}

export default App;
