import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomTypography from '../utils/text';
import { Button } from '@material-ui/core';
import useStyles from '../styles';
import SliderComponent from '../components/slider_component';
import Dounught from '../components/doughnut';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SearchCriteriaModal from '../components/search_criteria';
import ScoringCriteriaModal from '../components/ScoringCriteria';
import { Buffer } from 'buffer';
import LoadingOverlayComponent from '../components/lodding_component';
import WarningMessage from '../components/warning_snackbar';
import useWindowDimensions from '../hooks/useWindowDimensions';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const WeighingsScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const ScoringCriteriaData = useSelector((state) => state.mainReducer.selectionObject);
  const SerachObjectData = useSelector((state) => state.mainReducer.serachObject);
  const hkey = useSelector((state) => state.mainReducer.hkey);
  const hubWorkspace = useSelector((state) => state.mainReducer.hubWorkspace);
  const hubUser = useSelector((state) => state.mainReducer.hubUser);
  const [scoringData, setScoringData] = useState([]);
  const [scoring, setScoring] = useState(100);
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isLodding, setIsLodding] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openScoringCriteria, setOpenScoringCriteria] = useState(false);
  const [projectNameError, setProjectNameError] = useState(false);
  const [isContinueEnable, setIsContinueEnable] = useState(false);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    saveScoreCacheData();
  }, []);
  const saveScoreCacheData = () => {
    let cacheData = JSON.parse(localStorage.getItem('cache_data'));
    if (cacheData?.score_obj) {
      dispatch({ type: 'SET_SELECTION_OBJECT', payload: cacheData?.score_obj });
      var array = [];
      var total = 0;
      cacheData?.score_obj?.map((obj) => {
        var isselected = false;
        obj?.data?.map((objItem) => {
          if (objItem.value) {
            isselected = true;
          }
        });
        if (isselected) {
          array.push(obj);
          total = total + obj.score;
        }
      });
      setScoringData(array);
      setScoring(100 - total);
    }
  };
  useEffect(() => {
    console.log('called ....');
    let cacheData = JSON.parse(localStorage.getItem('cache_data'));
    if (!cacheData?.score_obj && ScoringCriteriaData) {
      var array = [];
      var total = 0;
      ScoringCriteriaData?.map((obj) => {
        var isselected = false;
        obj?.data?.map((objItem) => {
          if (objItem.value) {
            isselected = true;
          }
        });
        if (isselected) {
          array.push(obj);
          total = total + obj.score;
        }
      });
      setScoringData(array);
      setScoring(100 - total);
    } else {
    }
  }, [ScoringCriteriaData]);
  useEffect(() => {
    if (scoringData) {
      var isEnable = true;
      var mainTOtal = 0;
      scoringData?.map((item) => {
        mainTOtal = mainTOtal + item.score;
        if (item.score == 0) {
          isEnable = false;
        }
        var total = 0;
        item?.data.map((object) => {
          if (object.value || object.value == 'true') {
            total = total + object.score;
            if (object.score == 0) {
              isEnable = false;
            }
          }
        });
        if (total != 100) {
          isEnable = false;
        }
      });
      if (mainTOtal != 100) {
        isEnable = false;
      }
      setIsContinueEnable(isEnable);
    }
  }, [scoringData]);
  const handleOnChanage = (value, name) => {
    var data = [...scoringData];
    var index = data.findIndex((e) => e.header == name);
    if (index != -1) {
      data[index] = { ...data[index], score: value };
    }
    var value = 0;
    data?.forEach((obj) => {
      value = value + obj.score;
    });
    setScoringData(data);
    setScoring(100 - value);
    if (ScoringCriteriaData) {
      ScoringCriteriaData?.map((item) => {
        if (data) {
          var obj = data.filter((e) => e.header == item.header);
          if (obj.length > 0) {
            item['score'] = obj[0].score;
          }
        }
      });
    }
    dispatch({ type: 'SET_SELECTION_OBJECT', payload: ScoringCriteriaData });
    let cacheData = JSON.parse(localStorage.getItem('cache_data'));
    var cache_data_obj = {
      ...cacheData,
      score_obj: ScoringCriteriaData,
    };
    localStorage.setItem('cache_data', JSON.stringify(cache_data_obj));
  };
  const handleClickContinue = () => {
    if (ScoringCriteriaData) {
      ScoringCriteriaData?.map((item) => {
        if (scoringData) {
          var obj = scoringData.filter((e) => e.header == item.header);
          if (obj.length > 0) {
            item['score'] = obj[0].score;
          }
        }
      });
    }
    dispatch({ type: 'SET_SELECTION_OBJECT', payload: ScoringCriteriaData });
  };
  const handleOnClickCategory = (name) => {
    handleClickContinue();
    var obj = scoringData.filter((e) => e.header == name);
    navigate('/SubCategoryWeighting', {
      state: { data: obj[0], isEdit: false },
    });
  };
  const handleSubmitSearchCriteria = () => {
    if (true) {
      setOpenScoringCriteria(true);
      setOpenSearch(false);
    }
  };
  const handleScoringBack = () => {
    saveScoreCacheData();
    setOpenScoringCriteria(false);
    setOpenSearch(true);
  };
  const handleSearchBack = () => {
    setOpen(true);
    setOpenSearch(false);
  };
  const handleScoreSubmit = () => {
    saveScoreCacheData();
    setOpenScoringCriteria(false);
    navigate('/weighting', { state: { value: 'demo' } });
  };
  const handleClose = () => {
    setItem('');
    setIsEdit(false);
    setProjectNameError(false);
    setOpen(false);
    setOpenSearch(false);
    setOpenScoringCriteria(false);
  };

  const handleClickContinueScore = async () => {
    setIsLodding(true);

    const reqObj = {
      ScoringCriteriaData: ScoringCriteriaData,
      hkey: hkey,
      scoringData: scoringData,
      hubUser: hubUser,
    };

    try {
      const response = await axiosPrivate.post(`/weightings/score`, reqObj);

      if (response.status === 200) {
        dispatch({ type: 'SET_CLEAR_SCORING_OBJECT' });
        const b = Buffer.from(response.data[0]['anonymous block'].data);
        dispatch({
          type: 'SET_HWGT_HKEY',
          payload: b.toString('hex').toUpperCase(),
        });
        handleInsertSearchCriteriaData(b.toString('hex').toUpperCase());
        dispatch({
          type: 'SELECTED_PROJECT_STATUS',
          payload: 'In progress',
        });
      }
    } catch (error) {
      console.log('error-------------------', error);
      setWarningSnackbar({
        name: 'Search criteria/scoring:',
        message: 'Unable to insert search criteria/scoring details',
        severity: 'error',
      });
    }
  };

  const handleInsertSearchCriteriaData = async (hwgtkey) => {
    const reqObj = {
      SerachObjectData: SerachObjectData,
      hkey: hkey,
      hwgtkey: hwgtkey,
      hubUser: hubUser,
    };

    try {
      const response = await axiosPrivate.post(`/weightings/search-criteria`, reqObj);

      if (response.status === 200) {
        navigate('/map/mapview');
      }
    } catch (error) {
      console.log('error-------------------', error);
      setWarningSnackbar({
        name: 'No properties to display - ',
        message: 'Please widen your search criteria',
        severity: 'error',
      });
      setTimeout(() => {
        navigate('/dashboard');
      }, 3000);
    }
  };

  const mainCategoryScore = React.useMemo(() => {
    var enterObjectArray = scoringData?.filter((e) => e.score > 0);
    var returnValue = enterObjectArray?.length + '/' + scoringData?.length;
    return returnValue;
  }, [scoringData]);
  return (
    <Grid
      container
      style={{
        backgroundColor: '#F5F5F5',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <>
        <Grid
          container
          style={{ display: 'flex', height: '18vh' }}
        >
          <Grid
            container
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 60,
              marginLeft: 60,
              marginRight: 60,
            }}
          >
            <Grid
              item
              xs={6}
              md={6}
            >
              <CustomTypography
                text={`Weightings`}
                fontsize='36px'
                fontweight='400'
                fontcolor='#1A1A1A'
                lineheight='40px'
                justifycontent='flex-start'
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                className={classes.backbtn}
                style={{
                  marginRight: 30,
                  width: 255,
                  textTransform: 'inherit',
                }}
                onClick={() => setOpenScoringCriteria(true)}
              >
                <CustomTypography
                  text='Back to scoring criteria'
                  fontfamily='Calibre-SB'
                  fontsize='16px'
                  fontweight='500'
                  fontcolor='#003F2D'
                  lineheight='16px'
                />
              </Button>
              <Button
                className={!isContinueEnable ? classes.disablebuttonClass : classes.buttonClass}
                style={{ width: 150, textTransform: 'inherit' }}
                onClick={handleClickContinueScore}
                disabled={!isContinueEnable}
              >
                <CustomTypography
                  text={'Continue'}
                  fontsize='16px'
                  fontfamily='Calibre-SB'
                  fontweight='500'
                  lineheight='16px'
                  fontcolor={!isContinueEnable ? '#7f8080' : 'white'}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            display: 'flex',
            backgroundColor: '#FFFFFF',
            height: '82vh',
            marginLeft: 30,
            marginRight: 30,
          }}
        >
          <Grid
            container
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <Grid
              item
              xs={5}
              md={5}
              style={{
                borderRight: '2px solid #F5F5F5',
                display: 'flex',
                flexDirection: 'column',
                height: '90vh',
                paddingBottom: '10vh',
              }}
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingTop: 30,
                  paddingLeft: 32,
                  paddingRight: 55,
                }}
              >
                <Grid
                  item
                  xs={10}
                  md={10}
                >
                  <CustomTypography
                    text={`Available weighting points`}
                    fontsize={width > 1300 ? '30px' : '26px'}
                    fontweight='400'
                    fontcolor='#1A1A1A'
                    lineheight='40px'
                    justifycontent='flex-start'
                  />
                  <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                    <CustomTypography
                      text={mainCategoryScore + '\xa0'}
                      fontsize='14px'
                      fontweight='500'
                      fontcolor='#1A1A1A'
                      lineheight='16px'
                      justifycontent='flex-start'
                    />

                    <CustomTypography
                      text={`completed`}
                      fontsize='14px'
                      fontweight='500'
                      fontcolor='#767676'
                      lineheight='16px'
                      justifycontent='flex-start'
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={2}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Grid
                    style={{
                      width: '100%',
                      height: 57,
                      backgroundColor: '#D2E8DA',
                      alignItems: 'center',
                    }}
                  >
                    <CustomTypography
                      text={scoring}
                      fontsize='36px'
                      fontweight='400'
                      fontcolor='#1A1A1A'
                      lineheight='25px'
                      justifycontent='center'
                      marginTop='15px'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  borderBottom: '2px solid #F5F5F5',
                  paddingTop: 10,
                }}
              />
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingBottom: 65,
                  paddingTop: 15,
                  marginLeft: -8,
                  overflow: 'auto',
                }}
              >
                {scoringData?.map((object, index) => {
                  return (
                    <SliderComponent
                      key={object.header}
                      paddingTop={index === 0 ? 30 : 60}
                      paddingRight={width > 1300 ? 52 : 32}
                      name={object.header}
                      score={object.score}
                      onChangeValue={handleOnChanage}
                      data={scoringData}
                      isMainCategory={true}
                      rowData={object.data}
                      onClickCategory={handleOnClickCategory}
                    />
                  );
                })}
              </Grid>
            </Grid>
            <Grid
              item
              xs={7}
              md={7}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Dounught
                style={{ width: 500, height: 500 }}
                data={scoringData}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
      {isLodding && <LoadingOverlayComponent open={isLodding} />}
      {openSearch && (
        <SearchCriteriaModal
          open={openSearch}
          handleClose={handleClose}
          handleSubmit={handleSubmitSearchCriteria}
          handleBack={handleSearchBack}
        ></SearchCriteriaModal>
      )}
      {openScoringCriteria && (
        <ScoringCriteriaModal
          open={openScoringCriteria}
          handleClose={handleClose}
          handleSubmit={handleScoreSubmit}
          handleBack={handleScoringBack}
        ></ScoringCriteriaModal>
      )}
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
    </Grid>
  );
};
export default WeighingsScreen;
