import React from 'react';
import { FormControl, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import InputAdornment from '@mui/material/InputAdornment';
import styles from '../styles/index';
import SendIcon from '../icons/send_icon';
const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    '&:-webkit-autofill': {
      backgroundColor: '#FFFFFF !important;',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1a1a1a',
      },
      '&:hover fieldset': {
        borderColor: '#205747',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#205747',
      },
      '&.Mui-error fieldset': {
        borderColor: '#FF543E',
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#003f2d',
    },
  },
  focused: {},
  notchedOutline: {
    '&.MuiOutlinedInput-root.Mui-error': {
      borderColor: '#AB1212',
    },
  },
  inputPlaceholderColor: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        color: '#1a1a1a',
      },
      '&:hover fieldset': {
        color: '#205747',
      },
      '&.Mui-focused fieldset': {
        color: '#205747',
      },
    },
  },
}));
const AssistantField = ({
  value = '',
  info = '',
  onChange,
  id,
  error,
  type = 'text',
  InputProps = null,
  disabled = false,
  onBlur = false,
  width = null,
  height = '48px',
  inputPlaceholder = '',
  paddingBottom,
  errorIcon = true,
  maxLength = null,
  multiline = false,
  rows = 1,
  maxRows = '',
  icon = 'null',
  variant = 'outlined',
  onKeyPress = () => {},
  onClick,
  ...props
}) => {
  const style = styles();
  const outlinedInputClasses = useOutlinedInputStyles();
  if (error) {
    props['error'] = true;
  }
  const onChangeValue = (e) => {
    if (props.multiline && props.rows == 1) {
      e.target.value = e.target.value.replace(/\n/g, '');
    }
    onChange(e);
  };
  const handleOnBlur = () => {
    if (onBlur) {
      onBlur(value);
    }
  };
  return (
    <FormControl data-qaid='inputtext_formcontrol' {...props} variant={variant} className={style.fullWidth}>
      <TextField
        multiline={multiline}
        minRows={rows}
        maxRows={maxRows}
        autoComplete='off'
        disabled={disabled}
        data-qaid='inputtext_outlinedinput'
        variant={variant}
        {...props}
        id={id}
        type={type}
        value={value}
        onBlur={handleOnBlur}
        onKeyPress={onKeyPress}
        style={{
          width: width !== 'null' ? width : '100%',
          height: height,
          backgroundColor: 'white',
          fontSize: '20px',
          fontFamily: 'Calibre-R',
        }}
        placeholder={inputPlaceholder}
        onChange={onChangeValue}
        className={outlinedInputClasses.root}
        spellCheck={true}
        InputLabelProps={{
          shrink: true,
          style: {
            color: error ? '#FF543E' : '#1A1A1A',
            fontSize: '20px',
            marginTop: '-4px',
            paddingRight: '16px',
            lineHeight: ' px',
            fontFamily: 'Calibre-R',
          },
        }}
        inputProps={{
          maxLength: maxLength,
          spellCheck: true,
          lang: 'en',
          autoComplete: 'off',
          form: {
            autoComplete: 'off',
          },
          ...InputProps,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end' style={{ cursor: 'pointer' }} onClick={onClick}>
              <SendIcon color={'#949494'} width='21' height='20' />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};
export default AssistantField;
