import { combineReducers } from 'redux';
import { getObject } from '../../utils/utilities';
import { cloneDeep } from 'lodash';
const intialState = {
  serachObject: {},
  selectionObject: [],
  setProjectScore: [],
  demo: '',
  locationData: [],
  hkey: '',
  hwgtkey: '',
  selectedProjectStatus: '',
  projectName: '',
  shortlisted: 0,
  selectedProjectCompletedDate: '',
  isMapNavigation: false,
  isMapFullScrren: false,
  hubWorkspace: '',
  hubUser: '',
  email: '',
  propertyData: [],
  projectsData: [],
  dataviewList: [],
  oauth_token: '',
  updateDataView: [],
  singlePropertyData: [],
  currentPropertyData: [],
  copyDataviewListData: [],
  nonScoringSwitch: false,
  mapCenter: [-0.118092, 51.5072],
  mapZoomLevel: 14.5,
  isOauthTokenExpired: false,
  industryCode: [],
  landlardData: [],
  selectedIndividualPropertyData: [],
  isIndividualProperty: false,
  sortByOption: 'Last viewed',
  showByOption: 'In progress',
  isUserClosedAlertBar: false,
  searchValueinAddProp: '',
  availableTo: null,
  shortlistSelected: false,
  assistantOpen: false,
};
function mainReducer(state = intialState, action) {
  switch (action.type) {
    case 'SET_OAUTH_TOKEN':
      return { ...state, oauth_token: action.payload };
    case 'SET_INDUSTRY_CODE':
      return { ...state, industryCode: action.payload };
    case 'SET_OAUTH_TOKEN_EXPIRED':
      return { ...state, isOauthTokenExpired: action.payload };
    case 'SET_MAP_ZOOM':
      return { ...state, mapZoomLevel: action.payload };
    case 'SET_MAP_CENTER':
      return { ...state, mapCenter: action.payload };
    case 'SET_LANDLARDS':
      return { ...state, landlardData: action.payload };
    case 'SET_PROJECTS_DATA':
      return { ...state, projectsData: action.payload };
    case 'SET_PROPERTY_DATA':
      return { ...state, propertyData: action.payload };
    case 'NON_SCORING_SWITCH':
      return { ...state, nonScoringSwitch: action.payload };
    case 'SET_SEARCH_OBJECT':
      return { ...state, serachObject: action.payload };
    case 'CLEAR_SEARCH_OBJECT':
      return { ...state, serachObject: {} };
    case 'SET_SELECTION_OBJECT':
      return { ...state, selectionObject: action.payload };
    case 'USER_SET_SELECTION_OBJECT':
      return { ...state, setProjectScore: action.payload };
    case 'SET_TEXT':
      return { ...state, demo: action.payload };
    case 'SET_HKEY':
      return { ...state, hkey: action.payload };
    case 'UPDATE_INDIVIDUAL_PROPERTY_DATA':
      return { ...state, selectedIndividualPropertyData: action.payload };
    case 'SHOW_INDIVIDUAL_PROPERTY':
      return { ...state, isIndividualProperty: action.payload };
    case 'SET_HWGT_HKEY':
      return { ...state, hwgtkey: action.payload };
    case 'SELECTED_PROJECT_STATUS':
      return {
        ...state,
        selectedProjectStatus: action.payload,
        isUserClosedAlertBar: false,
      };
    case 'SET_PROJECT_NAME':
      return {
        ...state,
        projectName: action.payload.projectName,
        shortlisted: action.payload.shortlisted,
      };
    case 'SET_AVAILABLE_TO':
      return {
        ...state,
        availableTo: action.payload,
      };
    case 'USER_CLOSED_ALERT_BAR':
      return { ...state, isUserClosedAlertBar: action.payload };
    case 'SELECTED_PROJECT_COMPLETED_DATE':
      return { ...state, selectedProjectCompletedDate: action.payload };
    case 'SET_CLEAR_SCORING_OBJECT':
      return {
        ...state,
        selectionObject: clearScoringData(state.selectionObject),
        setProjectScore: clearScoringData(state.setProjectScore),
      };
    case 'LOCATIONS_DATA':
      return { ...state, locationData: ReformatLocationData(action.payload) };
    case 'CLEAR_DATA':
      return { ...state, serachObject: {}, hkey: '', hwgtkey: '', hubUser: '' };
    case 'SET_MAPNAVIGATION':
      return { ...state, isMapNavigation: action.payload };
    case 'SET_DATAVIEW':
      return {
        ...state,
        dataviewList: action.payload,
        copyDataviewListData: action.payload,
      };
    case 'SET_MAP_FULLSCREEN':
      return { ...state, isMapFullScrren: action.payload };
    case 'SET_HUB_WORKSPACE':
      return { ...state, hubWorkspace: action.payload };
    case 'SET_HUB_USER':
      return { ...state, hubUser: action.payload };
    case 'SET_USER_EMAIL':
      return { ...state, email: action.payload };
    case 'SET_UPDATE_DATAVIEW':
      return {
        ...state,
        updateDataView: updateValue(state.updateDataView || [], action.payload),
      };
    case 'CLEAR_UPDATEDATA':
      return {
        ...state,
        updateDataView: [],
        dataviewList: state.copyDataviewListData,
      };
    case 'SET_MODIFY_DATAVIEW':
      return {
        ...state,
        dataviewList: modifyValue(state.dataviewList, action.payload),
      };
    case 'SET_MAP_PROPERTY_DETAILS':
      return { ...state, propertydata: action.payload };
    case 'SET_SINGLE_PROPERTY_DATA':
      return { ...state, singlePropertyData: action.payload };
    case 'SET_CURRENT_PROPERTY_DATA':
      return { ...state, currentPropertyData: action.payload };
    case 'SET_PROJECT_WEIGHTING_SCORES':
      return {
        ...state,
        selectionObject: fetchProjectWeightingScores(state.selectionObject, action.payload),
        setProjectScore: cloneDeep(fetchProjectWeightingScores(state.setProjectScore, action.payload)),
      };
    case 'SET_PROPERTIES_DATA':
      return {
        ...state,
        PropertyKeyIds: action.payload.PropertyKeyIds,
        propertyClickId: action.payload.propertyClickId,
      };
    case 'SET_SORT_BY_OPTION':
      return { ...state, sortByOption: action.payload };
    case 'SET_SHOW_BY_OPTION':
      return { ...state, showByOption: action.payload };
    case 'SET_SEARCH_VALUE_ADD_PROPERTY':
      return { ...state, searchValueinAddProp: action.payload };
    case 'SET_IS_SHORTLISTED':
      return { ...state, shortlistSelected: action.payload };
    case 'SET_ASSISTANT_OPEN':
      return { ...state, assistantOpen: action.payload };
    default:
      return state;
  }
}
const updateValue = (array, object) => {
  var arryList = [...array];
  var index = arryList.findIndex((e) => e.id == object.id);
  if (index != -1) {
    let keyValue = object.key;
    let value = object.value;
    arryList[index] = {
      ...arryList[index],
      [keyValue]: value,
      FI_ALL_COST: object.FI_ALL_COST,
      FI_TOTAL_ANNUAL_COST: object.FI_TOTAL_ANNUAL_COST,
    };
  } else {
    let keyValue = object.key;
    let value = object.value;
    let objectData = {
      [keyValue]: value,
      id: object.id,
      HKEY_HUB_PROPERTY: object.HKEY_HUB_PROPERTY,
      FI_ALL_COST: object.FI_ALL_COST,
      FI_TOTAL_ANNUAL_COST: object.FI_TOTAL_ANNUAL_COST,
    };
    arryList.push(objectData);
  }
  return arryList;
};
const modifyValue = (array, object) => {
  var arryList = [...array];
  var index = arryList.findIndex((e) => e.id == object.id);
  if (index != -1) {
    arryList[index] = { ...object };
  }
  return arryList;
};
const clearScoringData = (data) => {
  data?.map((obj) => {
    obj['score'] = 0;
    obj.data.map((item) => {
      item['value'] = true;
      item['score'] = 0;
    });
  });
  return data;
};
export const fetchProjectWeightingScores = (dataArray, responseArray) => {
  var arryList = [...dataArray];
  if (responseArray.length > 0) {
    arryList = [
      ...arryList.filter((el) => el.header !== 'Custom Criteria'),
      {
        header: 'Custom Criteria',
        data: [],
        score: 0,
      },
    ];
    Object.entries(responseArray[0]).map(([key, value]) => {
      switch (key) {
        case 'TRANSPORT':
          arryList = setArrayValues(arryList, 'Transport', value);
          break;
        case 'LOCAL_AMENITY':
          arryList = setArrayValues(arryList, 'Location & Amenity', value);
          break;
        case 'BUILDING':
          arryList = setArrayValues(arryList, 'Building', value);
          break;
        case 'ESG':
          arryList = setArrayValues(arryList, 'ESG', value);
          break;
        case 'DELIVERABILITY_RISK':
          arryList = setArrayValues(arryList, 'Deliverability & Risk', value);
          break;
        case 'CUSTOM_WEIGHTAGE':
          arryList = setArrayValues(arryList, 'Custom Criteria', value);
          break;
        case 'TR_PROXIMITY_NEAREST_TUBE_STATION':
          arryList = setsubCategeoryWeightingScore(arryList, 'Transport', 'Proximity to nearest tube station', value);
          break;
        case 'TR_PROXIMITY_MAINLINE_TRAIN_STATION':
          arryList = setsubCategeoryWeightingScore(arryList, 'Transport', 'Proximity to mainline train station', value);
          break;
        case 'TR_PROXIMITY_ELIZABETH_LINE':
          arryList = setsubCategeoryWeightingScore(arryList, 'Transport', 'Proximity to Elizabeth Line', value);
          break;
        case 'TR_TUBES_WITHIN_1KM':
          arryList = setsubCategeoryWeightingScore(arryList, 'Transport', 'Tube lines within 1km', value);
          break;
        case 'TR_PROXIMITY_REGIONAL_TRAIN_STATION':
          arryList = setsubCategeoryWeightingScore(
            arryList,
            'Transport',
            'REGIONAL - Proximity to mainline train station',
            value
          );
          break;
        case 'TR_PROXIMITY_REGIONAL_TRAM_STATION':
          arryList = setsubCategeoryWeightingScore(
            arryList,
            'Transport',
            'REGIONAL - Proximity to tram station',
            value
          );
          break;
        case 'LA_TRANSPORT_HUB':
          arryList = setsubCategeoryWeightingScore(
            arryList,
            'Location & Amenity',
            'Proximity to transport hubs',
            value
          );
          break;
        case 'LA_LEVEL_LOCAL_AMENITY':
          arryList = setsubCategeoryWeightingScore(arryList, 'Location & Amenity', 'Level of local amenity', value);
          break;
        case 'LA_GREEN_SPACE':
          arryList = setsubCategeoryWeightingScore(arryList, 'Location & Amenity', 'Green Spaces', value);
          break;
        case 'LA_PERSONAL_SAFETY':
          arryList = setsubCategeoryWeightingScore(arryList, 'Location & Amenity', 'Personal safety', value);
          break;
        case 'LA_PROX_CLIENT_COMPETITOR':
          arryList = setsubCategeoryWeightingScore(
            arryList,
            'Location & Amenity',
            'Proximity to Clients/Competitors',
            value
          );
          break;
        case 'LA_PROX_GYM_HOTEL':
          arryList = setsubCategeoryWeightingScore(arryList, 'Location & Amenity', 'Proximity to gyms/hotels', value);
          break;
        case 'LA_FLEX_OPTIONS':
          arryList = setsubCategeoryWeightingScore(
            arryList,
            'Location & Amenity',
            'Flex options in the immediate area',
            value
          );
          break;
        case 'BI_ON_SITE_DEDICATED_GYM':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'On-site Dedicated Gym', value);
          break;
        case 'BI_ON_SITE_CATERING_AVAILABLE':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'On-site Catering Available', value);
          break;
        case 'BI_TERRACES':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'Terraces', value);
          break;
        case 'BI_LOOK_AND_FEEL':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'Look and Feel', value);
          break;
        case 'BI_FLOOR_TO_CEILING_HEIGHT':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'Floor to ceiling height', value);
          break;
        case 'BI_OCCUPATIONAL_DENSITY':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'Occupational Density', value);
          break;
        case 'BI_NATURAL_LIGHT':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'Natural Light', value);
          break;
        case 'BI_ON_SITE_AMENITY':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'On-site Amenity', value);
          break;
        case 'BI_ON_SITE_SHOWER_FACILITIES':
          // check
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'On-site shower facilities', value);
          break;
        case 'BI_SELF_CONTAINED':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'Self-contained', value);
          break;
        case 'BI_WIREDSCORE_RATING':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'WiredScore Rating', value);
          break;
        case 'BI_BUILDING_TECHNOLOGY':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'Building Technology', value);
          break;
        case 'BI_BRANDING_RIGHTS':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'Branding Rights', value);
          break;
        case 'BI_AIR_CONDITIONING':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'Air Conditioning', value);
          break;
        case 'BI_LOCKERS':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'Lockers', value);
          break;
        case 'BI_FLOOR_PLATE_SIZE':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'Floor plate suitability', value);
          break;
        case 'BI_COLUMNS':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'Columns', value);
          break;
        case 'BI_RAISED_FLOORS':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'Raised Floors', value);
          break;
        case 'BI_CAR_PARKING_SPACES':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'Car Park Spaces', value);
          break;
        case 'BI_SINGLE_LET_BUILDING':
          arryList = setsubCategeoryWeightingScore(arryList, 'Building', 'Single-let building', value);
          break;
        case 'ESG_BREEAM_RATING':
          arryList = setsubCategeoryWeightingScore(arryList, 'ESG', 'BREEAM Rating', value);
          break;
        case 'ESG_EPC_RATING':
          arryList = setsubCategeoryWeightingScore(arryList, 'ESG', 'EPC Rating', value);
          break;
        case 'ESG_NABERS_RATING':
          arryList = setsubCategeoryWeightingScore(arryList, 'ESG', 'Nabers Rating', value);
          break;
        case 'ESG_WELL_RATING':
          arryList = setsubCategeoryWeightingScore(arryList, 'ESG', 'WELL Rating', value);
          break;
        case 'ESG_NET_CARBON_ZERO':
          arryList = setsubCategeoryWeightingScore(arryList, 'ESG', 'Net Carbon Zero', value);
          break;
        case 'ESG_CYCLE_SPACES':
          arryList = setsubCategeoryWeightingScore(arryList, 'ESG', 'Cycle Spaces', value);
          break;
        case 'ESG_ALL_ELECTRIC_BUILDING':
          arryList = setsubCategeoryWeightingScore(arryList, 'ESG', 'All-Electric Building', value);
          break;
        case 'ESG_NATURALLY_VENTILATED':
          arryList = setsubCategeoryWeightingScore(arryList, 'ESG', 'Naturally Ventilated', value);
          break;
        case 'DR_DELIVERY_DATE':
          arryList = setsubCategeoryWeightingScore(arryList, 'Deliverability & Risk', 'Delivery status', value);
          break;
        case 'DR_LANDLORD_CREDIBILITY':
          arryList = setsubCategeoryWeightingScore(arryList, 'Deliverability & Risk', 'Landlord Credibility', value);
          break;
        case 'CUSTOM_SUB_CAT':
          let temp = {};
          if (responseArray[0].CUSTOM_SUB_CAT) {
            temp = getObject(responseArray[0].CUSTOM_SUB_CAT);
          }
          for (const key of Object.keys(temp)) {
            arryList = setsubCategeoryWeightingScore(arryList, 'Custom Criteria', key, temp[key]);
          }
          break;
        default:
          break;
      }
    });
  }
  if (arryList?.length > 0) {
    var arrayfilter = arryList?.filter((e) => e.score > 0);
    arryList?.forEach((e) => {
      if (arrayfilter?.length == 1) {
        if (arrayfilter[0].header == e.header) {
          e.score = 100;
        }
      }
      var filterSubCatergory = e?.data.filter((subObj) => subObj.value == true);
      if (filterSubCatergory?.length == 1) {
        var indexValue = e?.data?.findIndex((eobj) => eobj.item == filterSubCatergory[0].item);
        if (indexValue != -1) {
          e.data[indexValue].score = 100;
        }
      }
    });
  }
  console.log('arryList.....', arryList);
  return arryList;
};
const setsubCategeoryWeightingScore = (arryList, headerName, keyValue, scoreValue) => {
  var objectIndex = arryList.findIndex((e) => e.header == headerName);
  if (objectIndex === -1) return arryList;
  var index = arryList[objectIndex].data.findIndex((e) => e.item == keyValue);
  if (headerName === 'Custom Criteria' && !arryList[objectIndex].data.find((el) => el.item === keyValue)) {
    arryList[objectIndex].data.push({
      item: keyValue,
      value: !!scoreValue,
      score: scoreValue,
    });
  } else if (index === -1) return arryList;
  else if (scoreValue != 0 && scoreValue) {
    arryList[objectIndex].data[index] = {
      ...arryList[objectIndex].data[index],
      value: true,
      score: Number(scoreValue) || 0,
    };
  } else {
    arryList[objectIndex].data[index] = {
      ...arryList[objectIndex].data[index],
      value: false,
      score: Number(scoreValue) || 0,
    };
  }

  return arryList;
};
const setArrayValues = (arryList, value, scoreValue) => {
  var index = arryList.findIndex((e) => e.header == value);
  if (index != -1) {
    arryList[index] = { ...arryList[index], score: Number(scoreValue) };
  }
  return arryList;
};
const ReformatLocationData = (data) => {
  var array = [];
  data?.map((item, index) => {
    array.push({ id: index + 1, ...item });
  });
  return array;
};
const rootReducer = combineReducers({
  mainReducer,
});

export default rootReducer;
