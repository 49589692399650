import React, { useMemo } from 'react';
import { MV_TUBE_CONNECTIONS } from '../utils/constants';
const TubeCellRenderer = (props) => {
  const stationBakerlooCode = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#996633',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationCentral = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#CC3333',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationCircle = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#FFCC00',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationDistrict = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#006633',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationHammer = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#CC9999',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationJubilee = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#868F98',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationMetro = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#660066',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationNorth = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#000000',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationPicadilly = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#000099',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationVictoria = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#0099CC',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationWaterloo = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#66CCCC',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationDLRCode = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#009999',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationOver = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#FF6600',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationTram = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#66CC00',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationCable = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#E21836',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationCross = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#7156A5',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationSouthEasternCode = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#00AFE8',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationGreatNorthernCode = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#471D5D',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationGreatAnglia = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#D70428',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationElizabethCode = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#6C59A0',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationTfLRailCode = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#013FBA',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationChiltrenCode = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#139BD4',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationThameslinkCode = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#50107D',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationLondonMidlandCode = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#57B948',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationC2CCode = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#B71C8C',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationGreatWesternCode = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#0A493E',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationSouthernCode = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#369656',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationSouthWesternCode = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#A82833',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationHeathrowConnectCode = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#FE7C00',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const stationHeathrowExpressCode = useMemo(
    () => ({
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: '#000000',
      width: 'fit-content',
      lineHeight: '24px',
      marginTop: '12px',
      padding: '0px 8px 0px 2px',
    }),
    []
  );
  const placeofprops = props.value
    .split(',')
    .filter((el) => MV_TUBE_CONNECTIONS.includes(el.trim()));
  let connectionOne, connectionTwo, connectionThree;
  let connectionOneClass, connectionTwoClass, connectionThreeClass;
  placeofprops.forEach(createColoredConnections);
  function createColoredConnections(item, index) {
    let refinedItem = item.replace(/\s{2,}/g, ' ').trim();
    const gotStationWithColorCode = createColorCodes(refinedItem);
    const connectionName = gotStationWithColorCode.station;
    const connectionClass = gotStationWithColorCode.colorCode;
    if (index === 0) {
      connectionOne = connectionName;
      connectionOneClass = connectionClass;
    } else if (index === 1) {
      connectionTwo = connectionName;
      connectionTwoClass = connectionClass;
    } else if (index === 2) {
      connectionThree = connectionName;
      connectionThreeClass = connectionClass;
    }
  }

  function createColorCodes(refinedItem) {
    let station, colorCode;
    let stationWithColorCode = { station, colorCode };
    stationWithColorCode.station = refinedItem;
    if (refinedItem == 'Bakerloo') {
      stationWithColorCode.colorCode = stationBakerlooCode;
    } else if (refinedItem == 'Central') {
      stationWithColorCode.colorCode = stationCentral;
    } else if (refinedItem == 'Circle') {
      stationWithColorCode.colorCode = stationCircle;
    } else if (refinedItem == 'District') {
      stationWithColorCode.colorCode = stationDistrict;
    } else if (refinedItem == 'Hammersmith and City') {
      stationWithColorCode.colorCode = stationHammer;
    } else if (refinedItem == 'Jubilee') {
      stationWithColorCode.colorCode = stationJubilee;
    } else if (refinedItem == 'Metropolitan') {
      stationWithColorCode.colorCode = stationMetro;
    } else if (refinedItem == 'Northern') {
      stationWithColorCode.colorCode = stationNorth;
    } else if (refinedItem == 'Piccadilly') {
      stationWithColorCode.colorCode = stationPicadilly;
    } else if (refinedItem == 'Victoria') {
      stationWithColorCode.colorCode = stationVictoria;
    } else if (refinedItem == 'Waterloo and City') {
      stationWithColorCode.colorCode = stationWaterloo;
    } else if (refinedItem == 'DLR') {
      stationWithColorCode.colorCode = stationDLRCode;
    } else if (refinedItem == 'Overground') {
      stationWithColorCode.colorCode = stationOver;
    } else if (refinedItem == 'Tramlink') {
      stationWithColorCode.colorCode = stationTram;
    } else if (refinedItem == 'Cable Car') {
      stationWithColorCode.colorCode = stationCable;
    } else if (refinedItem == 'Crossrail') {
      stationWithColorCode.colorCode = stationCross;
    } else if (refinedItem == 'Southeastern') {
      stationWithColorCode.colorCode = stationSouthEasternCode;
    } else if (refinedItem == 'Great Northern') {
      stationWithColorCode.colorCode = stationGreatNorthernCode;
    } else if (refinedItem == 'Greater Anglia') {
      stationWithColorCode.colorCode = stationGreatAnglia;
    } else if (refinedItem == 'Elizabeth') {
      stationWithColorCode.colorCode = stationElizabethCode;
    } else if (refinedItem == 'TfL Rail') {
      stationWithColorCode.colorCode = stationTfLRailCode;
    } else if (refinedItem == 'Chiltern Railways') {
      stationWithColorCode.colorCode = stationChiltrenCode;
    } else if (refinedItem == 'Thameslink') {
      stationWithColorCode.colorCode = stationThameslinkCode;
    } else if (refinedItem == 'London Midland') {
      stationWithColorCode.colorCode = stationLondonMidlandCode;
    } else if (refinedItem == 'C2C') {
      stationWithColorCode.colorCode = stationC2CCode;
    } else if (refinedItem == 'Great Western') {
      stationWithColorCode.colorCode = stationGreatWesternCode;
    } else if (refinedItem == 'Southern') {
      stationWithColorCode.colorCode = stationSouthernCode;
    } else if (refinedItem == 'South Western') {
      stationWithColorCode.colorCode = stationSouthWesternCode;
    } else if (refinedItem == 'Heathrow Connect') {
      stationWithColorCode.colorCode = stationHeathrowConnectCode;
    } else if (refinedItem == 'Heathrow Express') {
      stationWithColorCode.colorCode = stationHeathrowExpressCode;
    } else {
    }
    return stationWithColorCode;
  }

  return (
    <span style={{ display: 'flex', flexWrap: 'wrap' }}>
      {connectionOne && (
        <span style={{ ...connectionOneClass, marginBottom: '3px' }}>
          {connectionOne}
        </span>
      )}
      {connectionTwo && (
        <span style={{ ...connectionTwoClass, marginBottom: '3px' }}>
          {connectionTwo}
        </span>
      )}
      {connectionThree && (
        <span style={{ ...connectionThreeClass, marginBottom: '3px' }}>
          {connectionThree}
        </span>
      )}
    </span>
  );
};

export default TubeCellRenderer;
