import React from "react";

function FirstPageIcon({ color = '#1A1A1A', fillOpacity, ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="12"
            fill="none"
            viewBox="0 0 14 12"
            {...props}
        >
            <path
                fill={color}
                fillOpacity={fillOpacity}
                d="M13.205 10.59L8.615 6l4.59-4.59L11.795 0l-6 6 6 6 1.41-1.41zM.795 0h2v12h-2V0z"
            ></path>
        </svg>
    );
}

export default FirstPageIcon;
