import React, { useEffect, useState, useMemo } from 'react';
import Chart from 'react-apexcharts';
import LineImage from '../img/line.png';
import CurrencyFormatter from './currency_formatter';
const CustomBarComponent = ({
  data = [],
  color = '#1F3765',
  isShowTooltip = true,
  height = 405,
  isCurrency = false,
  tab,
}) => {
  const [xaxisLabels, setXaxisLabels] = useState([]);
  const [yaxisLabels, setYaxisLabels] = useState([]);
  useEffect(() => {
    var xaxiosList = [];
    var yaxiosList = [];
    data.forEach((obj) => {
      xaxiosList.push(obj.labelName);
      yaxiosList.push(
        !isCurrency ? Math.round(obj.labelValue) : obj.labelValue
      );
    });
    for (var i = xaxiosList.length; i < 10; i++) {
      xaxiosList.push('');
    }
    for (var i = yaxiosList.length; i < 10; i++) {
      yaxiosList.push('');
    }
    setXaxisLabels(xaxiosList);
    var yaxiosDataset = [
      {
        data: yaxiosList,
        color: color,
        name: data,
      },
    ];
    setYaxisLabels(yaxiosDataset);
  }, [data]);

  let max = useMemo(() => {
    if (isCurrency) return Math.max(...data.map((el) => el.labelValue));
    if (data?.at(0))
      return Math.round(Math.max(...data.map((el) => el.labelValue)));
    return 10;
  }, [data]);
  return (
    <Chart
      type='bar'
      width={'100%'}
      height={tab ? 350 : height}
      stacktype='normal'
      series={yaxisLabels}
      options={{
        chart: {
          type: 'bar',
          stacked: true,
          events: {
            stacktype: 'normal',
            stacked: true,
          },
        },
        stroke: {
          dashArray: 1,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            barHeight: '60%',
          },
        },
        grid: {
          show: true,
          position: 'back',
          strokeDashArray: 2,
          borderColor: 'rgba(203, 209, 211, 1)',
          yaxis: {
            lines: {
              show: false,
            },
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 20,
          },
        },
        yaxis: {
          labels: {
            show: true,
            align: 'left',
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        xaxis: {
          max: !isCurrency ? Math.ceil(max / 10) * 10 : max,
          labels: {
            formatter: function (value) {
              const length = value.toString().length;
              if (value > 999) {
                if (length > 9) return value / 1000000000 + 'B';
                else if (length > 6) return value / 1000000 + 'M';
                else if (length > 3) return value / 1000 + 'K';
                else return value;
              } else {
                if (Number(value) === value && value % 1 === 0) {
                  return value;
                } else {
                  return Number.parseFloat(value).toFixed(2);
                }
              }
            },
          },
          categories: xaxisLabels,
          type: 'category',
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          followCursor: false,
          shared: false,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let obj = w.globals.initialSeries[seriesIndex].name[dataPointIndex];
            if (isShowTooltip) {
              return `<style>
                                         .root{ 
                                            padding-right: 20px;
                                            padding-left: 20px;
                                            padding-bottom: 10px;
                                            background-color: #FFFFFF;
                                            }
                                            .top10costcontainer {
                                            position: relative;font-family:'Calibre-R';
                                            padding-top:15px;
                                            }
                                            div.c {
                                                overflow-wrap: anywhere;
                                              }
                                            .text-block {
                                                position: absolute; bottom: 5px;right: 10px;top: 20px;width: 25px;height: 28px;
                                                weight: 40px;background-color: #DFE6E5;padding-left: 9px;padding-right: 7px;
                                                border-radius: 10px;text-align: center;
                                            }
                                            span-cost {
                                                color:#1a1a1a99;font-weight:400;line-height:24px;font-size:12px;font-family:'Calibre-R';text-align=left;font-style: normal;left: 1454px;right: 213.94px;top: 891px;                                               
                                            }
                                            span-address {
                                                color:#000000;font-style:normal;font-weight:400;line-height:24px;font-size:20px;font-family:'Calibre-R';text-align=left;left: 1454px;width:200px;right: 211px;top: 816px;overflow-wrap:anywhere;
                                            }
                                            span-rank {
                                                color:#1a1a1a99;font-style:normal;font-weight:400;line-height:24px;font-size:14px;font-family:'Calibre-R';text-align=left;height: 24px;left: 1454px;right: 211px;top: 840px;
                                            }                                            
                                            span-right_cost_points {
                                             color:#000000;font-weight:540;font-style: normal;line-height:24px;font-size:12px;font-family:'Calibre-R';left: 1571.66px;right: 276px;top: 891px;float:right;padding-right: 15px;
                                            }
                                            P {
                                                padding : 0;
                                                margin : 0;
                                                line-height : 22px;
                                            }
                                         </style>
                                         <div class="root">
                                            <div class="top10costcontainer">                                               
                                                <span-address>${
                                                  obj?.tooltipLabel?.length > 30
                                                    ? obj?.tooltipLabel?.substring(
                                                        0,
                                                        30
                                                      ) + '...'
                                                    : obj.tooltipLabel
                                                }</span-address>
                                            
                                            </div>
        
                                            <p>
                                                <img src=${LineImage} style="width: 260px;height: 1px;left: 1454px;top: 362px;"/>
                                            </p>
                                            <p>
                                            <span-cost>Rank</span-cost>
                                            <span-right_cost_points>${
                                              obj.RANK
                                            }</span-right_cost_points>
                                        </p>     
                                            <p>
                                                <span-cost>${
                                                  obj.keyLabel
                                                }</span-cost>
                                                <span-right_cost_points> 
                                                ${
                                                  !isCurrency
                                                    ? CurrencyFormatter(
                                                        Math.round(
                                                          obj.labelValue
                                                        ) || ''
                                                      )
                                                    : CurrencyFormatter(
                                                        obj.labelValue || ''
                                                      )
                                                }
                                                </span-right_cost_points>
  
                                            </p>                                            
                                        </div > `;
            } else {
              return null;
            }
          },
        },
      }}
    />
  );
};
export default CustomBarComponent;
