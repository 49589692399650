import React from 'react';

function DataviewCheckBoxIcon({ color = '#003F2D', ...props }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill={color}
        fillRule='evenodd'
        d='M3 3h18v18H3V3zm2 9l5 5 9-9-1.41-1.42L10 14.17l-3.59-3.58L5 12z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default DataviewCheckBoxIcon;
