import React from "react";

function PluseIcon({ color = '#003F2D', ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      {...props}
    >
      <path fill={color} d="M14 8H8v6H6V8H0V6h6V0h2v6h6v2z"></path>
    </svg>
  );
}

export default PluseIcon;
