import React from 'react';
import LoginView from './pages/login_screen';
import NavigationLayout from './navigation/index';
import ProjectView from './pages/dashboard';
import WeighingsScreen from './pages/Weightings';
import SubCategoryWeighingsScreen from './pages/subcategory_weighting_screen';
import MapView from './pages/mapview';
import MapNavigation from './navigation/mapNavigation/index';
import TableView from './pages/tableview';
import ShortListView from './pages/shortlist_view';
import ClientReports from './pages/ClientReports';
import AdvancedFiltersView from './pages/advanced_filters';
import EditWeighingsScreen from './pages/edit_weighting_scores';
import EditWeightingIndex from './pages/edit_weighting_scores_index';
import Charts from './pages/chart/charts';
import Benchmarking from './pages/benchmarking';
import { ChartsProvider } from './Providers/ChartsProvider';

const routes = [
  {
    path: `/`,
    element: <LoginView />,
  },
  {
    path: `/login`,
    element: <LoginView />,
  },
  {
    path: `/`,
    element: <NavigationLayout />,
    children: [
      { path: '/dashboard', element: <ProjectView /> },
      { path: '/benchmarking', element: <Benchmarking /> },
      {
        path: '/map',
        element: <MapView />,
      },
      {
        path: '/weighting',
        element: <WeighingsScreen />,
      },
      {
        path: '/SubCategoryWeighting',
        element: <SubCategoryWeighingsScreen />,
      },
    ],
  },
  {
    path: `/map`,
    element: <MapNavigation />,
    children: [
      { path: '/map/mapview', element: <MapView /> },
      { path: '/map/tableview', element: <TableView /> },
      { path: '/map/shortlistview', element: <ShortListView /> },
      { path: '/map/advancedfilters', element: <AdvancedFiltersView /> },
      { path: '/map/editweightingview', element: <EditWeighingsScreen /> },
      { path: '/map/updateweightingsscores', element: <EditWeightingIndex /> },
      { path: '/map/reports', element: <ClientReports /> },
      {
        path: '/map/updatesubCategoryweighting',
        element: <SubCategoryWeighingsScreen />,
      },
      {
        path: '/map/charts',
        element: (
          <ChartsProvider>
            <Charts />
          </ChartsProvider>
        ),
      },
    ],
  },
];
export default routes;
