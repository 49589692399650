const flexSpacesTooltip = (item) => {
  const buildingName =
    item.BUILDING_NAME && item.BUILDING_NAME.length > 30
      ? item.BUILDING_NAME.substring(0, 30) + '...'
      : item.BUILDING_NAME;
  const buildingNumber = item.BUILDING_NUMBER && item.BUILDING_NUMBER ? item.BUILDING_NUMBER : '';
  const street = item.STREET && item.STREET.length > 30 ? item.STREET.substring(0, 30) + '...' : item.STREET;
  const Serviced_Desk =
    item.SERVICED_OFFICES_MIN && item.SERVICED_OFFICES_MIN
      ? item.SERVICED_OFFICES_MIN + '/' + item.SERVICED_OFFICES_MAX
      : '-';
  const Fixed_Desk =
    item.FIXED_DESKS_MIN && item.FIXED_DESKS_MIN ? item.FIXED_DESKS_MIN + '/' + item.FIXED_DESKS_MAX : '-';
  const Hot_Desk = item.HOT_DESKS_MIN && item.HOT_DESKS_MIN ? item.HOT_DESKS_MIN + '/' + item.HOT_DESKS_MAX : '-';
  const Desks = item.DESKS_MIN && item.DESKS_MIN ? item.DESKS_MIN + '/' + item.DESKS_MAX : '-';
  return `<div style="margin:20px;">
            <div style="font-size:20px;margin-bottom:5px;">${buildingName || buildingNumber + ' ' + street}</div>
            <div class="flex-postal-value">${item.POSTCODE}</div>
            <div class="flex-label-header">
              <div class="flex-label" style="opacity:0.6;">Landlord</div>
              <div class="flex-label-value">${item.LANDLORD}</div>
            </div>
            <div class="flex-label-header" style="padding-bottom:10px;border-bottom: 1px solid #e0e0e0;">
              <div class="flex-label" style="opacity:0.6;">Contact</div>
              <div class="flex-label-value">${item.CONTACT}</div>
            </div>
            <div class="flex-label-header flex-label" style="margin-top:15px;">
              <div class="flex-value-1"></div>
              <div class="flex-value-2" style="opacity:0.6;">Quantity (Min/Max)</div>
              <div class="flex-value-3" style="opacity:0.6;">Price from</div>
            </div>
            <div class="flex-label-header flex-label">
              <div class="flex-value-1">Serviced offices</div>
              <div class="flex-value-2">${Serviced_Desk}</div>
              <div class="flex-value-3">${
                item.SERVICED_OFFICES_MIN_PRICE ? `£${item.SERVICED_OFFICES_MIN_PRICE}` : '-'
              }</div>
            </div>
            <div class="flex-label-header flex-label">
              <div class="flex-value-1">Fixed desks</div>
              <div class="flex-value-2">${Fixed_Desk}</div>
              <div class="flex-value-3">${item.FIXED_DESKS_MIN_PRICE ? `£${item.FIXED_DESKS_MIN_PRICE}` : '-'}</div>
            </div>
            <div class="flex-label-header flex-label">
              <div class="flex-value-1">Hot desks</div>
              <div class="flex-value-2">${Hot_Desk}</div>
              <div class="flex-value-3">${item.HOT_DESKS_MIN_PRICE ? `£${item.HOT_DESKS_MIN_PRICE}` : '-'}</div>
            </div>
            <div class="flex-label-header flex-label">
              <div class="flex-value-1">Desks</div>
              <div class="flex-value-2">${Desks}</div>
              <div class="flex-value-3">${item.DESKS_MIN_PRICE ? `£${item.DESKS_MIN_PRICE}` : '-'}</div>
            </div>
            <div class="flex-info">
              <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path fill-rule='evenodd' clip-rule='evenodd'
                d='M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C15.9952 3.5837 12.4163 0.00477657 8 0ZM8.16667 3.33333C8.71895 3.33333 9.16667 3.78105 9.16667 4.33333C9.16667 4.88562 8.71895 5.33333 8.16667 5.33333C7.61438 5.33333 7.16667 4.88562 7.16667 4.33333C7.16667 3.78105 7.61438 3.33333 8.16667 3.33333ZM7 12.3333H9.66667C10.0349 12.3333 10.3333 12.0349 10.3333 11.6667C10.3333 11.2985 10.0349 11 9.66667 11H9.16667C9.07462 11 9 10.9254 9 10.8333V7.66667C9 6.93029 8.40305 6.33333 7.66667 6.33333H7C6.63181 6.33333 6.33333 6.63181 6.33333 7C6.33333 7.36819 6.63181 7.66667 7 7.66667H7.5C7.59205 7.66667 7.66667 7.74129 7.66667 7.83333V10.8333C7.66667 10.9254 7.59205 11 7.5 11H7C6.63181 11 6.33333 11.2985 6.33333 11.6667C6.33333 12.0349 6.63181 12.3333 7 12.3333Z' fill='#767676' />
              </svg>
              <span style="margin-left:5px;">Quantity shown does not imply availability.</span>
            </div>
          </div>`;
};

export default flexSpacesTooltip;
