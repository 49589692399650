import React from 'react';

function ChartsIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      fill='none'
      viewBox='0 0 18 18'
    >
      <path
        fill='#003F2D'
        d='M2 8h4c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2H2C.9 0 0 .9 0 2v4c0 1.1.9 2 2 2zM2 18h4c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2H2c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2zM10 2v4c0 1.1.9 2 2 2h4c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2zM12 18h4c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2z'
      ></path>
    </svg>
  );
}

export default ChartsIcon;
