import axios from 'axios';
const dataProvision = async (customerName) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_X_API_KEY,
    // Add any other headers you need
  };
  const apiUrl = `${process.env.REACT_APP_DATA_PROVISION_BASE_URL}/customers`;
  return await axios.get(apiUrl, {
    params: { customerName },
    headers: headers,
  });
};

export default dataProvision;
