import { Card, CardContent, Grid } from '@material-ui/core';
import Radio from '@mui/material/Radio';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProjectModal from '../components/project_modal';
import SearchCriteriaModal from '../components/search_criteria';
import CustomTypography from '../utils/text';
import CardComponent from '../components/card';
import PluseIcon from '../icons/pluse_icon';
import ScoringCriteria from '../components/ScoringCriteria';
import { Buffer } from 'buffer';
import { useDispatch, useSelector } from 'react-redux';
import WarningMessage from '../components/warning_snackbar';
import OAuthError from '../utils/errorMessages';
import LoadingOverlayComponent from '../components/lodding_component';
import ConfirmationPopup from '../components/confirmationPopup';
import SnackBar from '../components/SnackBar';
import ShareProjectModal from '../components/ShareProjectModal';
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { getCapitalized } from '../utils/utilities';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import ScoringCriteriaObject from '../utils/criteria_selection.json';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontFamily: 'Calibre-R',
    fontWeight: 'bold',
    backgroundColor: 'inherit !important',
    fontSize: '14px',
    lineHeight: '21px',
  },
}));

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sortByOption = useSelector((state) => state.mainReducer.sortByOption);
  const showByOption = useSelector((state) => state.mainReducer.showByOption);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState('');
  const [origin, modifyOrigin] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openScoringCriteria, setOpenScoringCriteria] = useState(false);
  const [projectNameError, setProjectNameError] = useState(false);
  const hubWorkspace = useSelector((state) => state.mainReducer.hubWorkspace);
  const email = useSelector((state) => state.mainReducer.email);
  const projectsData = useSelector((state) => state.mainReducer.projectsData);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [modifiedData, setModifiedData] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [renameSnackbar, setRenameSnackbar] = useState(null);
  const [openPopup, updatePopup] = useState(false);
  const [popUpItem, updatePopUpItem] = useState([]);
  const [popUpMessage, setPopUpMessage] = useState({
    title: '',
    confirm_button_text: '',
    footer_text: '',
    source: '',
  });
  const [shareProjectOpen, setShareProjectOpen] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [completeUsers, setCompleteUsers] = useState([]);
  const [consolidated, setConsolidated] = useState([]);
  const [hubKey, setHubKey] = useState();
  const [projectName, setProjectName] = useState();
  const hubUser = useSelector((state) => state.mainReducer.hubUser);
  const env = window.location.hostname;
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let cacheData = {};
    if (localStorage?.getItem('cache_data')) {
      cacheData = JSON.parse(localStorage?.getItem('cache_data'));
    }
    if (cacheData) {
      if (cacheData?.score_obj) {
        dispatch({
          type: 'SET_SELECTION_OBJECT',
          payload: cacheData?.score_obj,
        });
      }
      if (cacheData?.search_obj) {
        dispatch({
          type: 'SET_SEARCH_OBJECT',
          payload: cacheData?.search_obj,
        });
      }
      if (cacheData?.hkey_value) {
        dispatch({
          type: 'SET_HKEY',
          payload: cacheData?.hkey_value,
        });
      }
      if (cacheData?.step == 'step1') {
        setOpen(true);
      } else if (cacheData?.step == 'step2') {
        setOpenSearch(true);
      } else if (cacheData?.step == 'step3') {
        setOpenScoringCriteria(true);
      } else if (cacheData?.step == 'step4') {
        navigate('/weighting', { state: { value: 'demo' } });
      }
    }
  }, []);

  const handleClickOpen = () => {
    dispatch({
      type: 'SET_HWGT_HKEY',
      payload: '',
    });
    dispatch({ type: 'CLEAR_SEARCH_OBJECT' });
    dispatch({ type: 'SET_CLEAR_SCORING_OBJECT' });
    setOpen(true);
  };

  useEffect(() => {
    let sortedData = [];
    switch (sortByOption) {
      case 'Date created':
        sortedData = data?.sort((a, b) => (a.CREATE_DATETIME < b.CREATE_DATETIME ? 1 : -1));
        break;
      case 'Last viewed':
        // sortedData = data ?? [];
        sortedData = data?.sort((a, b) => (a.LAST_OPENED < b.LAST_OPENED ? 1 : -1));
        break;
      case 'Name':
        sortedData = data?.sort((a, b) => (a.PROJECT_NAME > b.PROJECT_NAME ? 1 : -1));
        break;
      case 'Status':
        sortedData = data?.sort((a, b) => (a.STATUS < b.STATUS ? 1 : -1));
        break;
      default:
        break;
    }
    setModifiedData([...sortedData]);
  }, [data, sortByOption]);

  useEffect(() => {
    setUsersLoading(true);
    getHsatUsers();
  }, []);

  const getHsatUsers = async () => {
    try {
      const response = await axiosPrivate.get(`/dashboard/hsat-users`);

      if (response.status === 200) {
        setUsersLoading(false);

        if (!response.data.length) return;

        const data = response.data
          .map((el) => {
            const fName = el.FIRST_NAME?.charAt(0).toUpperCase() + el.FIRST_NAME?.slice(1);
            return {
              label: `${fName} ${el.LAST_NAME === 'undefined' ? '' : el.LAST_NAME}|${el.EMAIL}`,
              value: el.EMAIL,
              HKEY_HUB_USER: Buffer.from(el?.HKEY_HUB_USER?.data)?.toString('hex').toUpperCase(),
            };
          })
          .sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });

        setUsers(data);
        setCompleteUsers(data);
        setConsolidated(data);
      }
    } catch (error) {
      setUsersLoading(false);
      console.log('error.........', error);
    }
  };

  useEffect(() => {
    if (projectsData?.length > 0) {
      setIsLoading(false);
      setData(projectsData);
    }
  }, [projectsData]);

  useEffect(() => {
    localStorage.removeItem('duplicating');
    dispatch({
      type: 'SET_IS_SHORTLISTED',
      payload: false,
    });
  }, []);

  const handleDuplicateProject = async ({
    name = '',
    desc = '',
    modifiedProject,
    action,
    itemObject,
    industryCode,
    companyName = {},
  }) => {
    try {
      const reqObj = {
        name: name.replaceAll("'", "''"),
        desc: desc.replaceAll("'", "''"),
        industryCode: industryCode,
        companyName: companyName.name,
        companyCode: companyName.code,
        hubWorkspace: hubWorkspace,
      };

      const response = await axiosPrivate.post(`/dashboard/create-duplicate-project`, reqObj);

      if (response.status === 200) {
        localStorage.removeItem('duplicating');
        if (response.data && response.data[0]) {
          DuplicateProjectCallback(response.data[0], name);
        } else {
          setWarningSnackbar({
            name: 'Project Info:',
            message: 'Unable to duplicate project ',
            severity: 'error',
          });
        }
      }
    } catch (error) {
      console.log('error-------------------', error);
      setWarningSnackbar({
        name: 'Project Info:',
        message: 'Unable to duplicate project ',
        severity: 'error',
      });
    }
  };

  const DuplicateProjectCallback = async (response, name = '') => {
    if (response) {
      const original_project_hkey = Buffer.from(item.HKEY_HUB_PROJECT?.data)?.toString('hex').toUpperCase();
      let duplicateHkey = Buffer.from(response['anonymous block']?.data).toString('hex').toUpperCase();

      try {
        const reqObj = {
          original_project_hkey: original_project_hkey,
          duplicateHkey: duplicateHkey,
          hubUser: hubUser,
        };

        const response = await axiosPrivate.post(`/dashboard/process-duplicate-project`, reqObj);

        if (response.status === 200) {
          setLoading(false);
          setIsLoading(false);
          setItem('');
          setSnackbar({
            name: name,
            message: `has been successfully created.`,
            severity: 'success',
          });
          fetchProjectsData();
        }
      } catch (error) {
        console.log('error--------------------', error);
        setWarningSnackbar({
          name: 'Project Info:',
          message: 'Unable to duplicate project ',
          severity: 'error',
        });
      }
    }
  };

  const handleClose = () => {
    setItem('');
    modifyOrigin('');
    setProjectNameError(false);
    setOpen(false);
    setOpenSearch(false);
    setOpenScoringCriteria(false);
    dispatch({ type: 'CLEAR_SEARCH_OBJECT' });
    localStorage.setItem('cache_data', JSON.stringify({}));
  };

  // start-of-handleSubmitModal()
  const handleSubmitModal = async (
    name,
    desc = '',
    modifiedProject,
    action,
    itemObject,
    industryCode,
    companyName = {}
  ) => {
    setLoading(true);
    modifyOrigin('');
    setOpen(false);
    setIsLoading(true);

    if (modifiedProject) {
      if (action === 'Duplicate') {
        // Duplicate
        if (localStorage.getItem('duplicating')) {
          setWarningSnackbar({
            name: 'Project Info:',
            message: 'Unable to duplicate project ',
            severity: 'error',
          });
          setIsLoading(false);
          setLoading(false);
          return;
        }
        localStorage.setItem('duplicating', 'yes');

        handleDuplicateProject({
          name,
          desc,
          modifiedProject,
          action,
          itemObject,
          industryCode,
          companyName,
        });
      }

      if (action === 'edit') {
        // rename
        const b = Buffer.from(item.HKEY_HUB_PROJECT.data);

        const reqObj = {
          name: name.replaceAll("'", "''"),
          desc: desc.replaceAll("'", "''"),
          industryCode: industryCode,
          companyName: companyName.name ? companyName.name : companyName.value,
          companyCode: companyName.code,
          projectKey: b.toString('hex').toUpperCase(),
        };
        try {
          const response = await axiosPrivate.put(`/dashboard/edit-project`, reqObj);

          if (response.status === 200) {
            handleCallback(true, response, modifiedProject, action, name, true);
          }
        } catch (error) {
          console.log('error while edit project----------', error);
        }
      }
    } else if (action == 'Complete') {
      // complete

      const userKey = Buffer.from(itemObject?.HKEY_HUB_USER?.data).toString('hex').toUpperCase();

      const reqObj1 = {
        userKey: userKey,
        hubKey: hubKey,
      };

      try {
        const response = await axiosPrivate.put(`/dashboard/complete-project`, reqObj1);

        setIsLoading(true);

        if (response.status === 200) {
          handleCallback(true, response, modifiedProject, action, '', false);
        }
      } catch (error) {
        console.log('error while complete project----------', error);
      }

      const reqObj2 = {
        projectKey: hubKey,
        status: 'TRUE',
      };
      try {
        const response = await axiosPrivate.post(`/dashboard/freeze-project`, reqObj2);

        if (response.status === 200) {
          handleCallback(true, response, modifiedProject, action, name, true);
        }
      } catch (error) {
        console.log('error while freeze project -after-complete---------', error);
      }

      try {
        const response = await axiosPrivate.get(`/dashboard/hub-users?hubprojectkey=${hubKey}`);

        if (response.status === 200) {
          const mUser = response.data.find((el) => el.ROLE_CODE === 'Owner');
          const fromUser = consolidated
            .find((elm) => elm.value.toLowerCase() === email.toLowerCase())
            ?.label.split('|')
            .at(0);

          try {
            const ownerName = getCapitalized(
              `${mUser?.FIRST_NAME ? mUser.FIRST_NAME : ''} ${mUser?.LAST_NAME ? mUser.LAST_NAME : ''}`
            );

            if (itemObject.USER_COUNT > 1) {
              const reqObj = {
                email: email,
                payload: {
                  owner: {
                    email: mUser?.EMAIL,
                    name: ownerName,
                  },
                  projectName,
                  url: `https://${env}`,
                  type: 'completed',
                  fromUser: getCapitalized(fromUser),
                  route: 'mailtToOwner',
                },
              };

              await axiosPrivate.post(`/dashboard/postusercount`, reqObj);
            } // end-of-usercount
          } catch (error) {
            console.log(error);
          }
        } // end-of-callobj-response
      } catch (error) {
        console.log('error while calling collabs in complete action--------------', error);
      }
    } else {
      // create

      const reqObj = {
        name: name.replaceAll("'", "''"),
        desc: desc.replaceAll("'", "''"),
        industryCode: industryCode,
        companyName: companyName.name,
        companyCode: companyName.code,
        hubWorkspace: hubWorkspace,
      };
      try {
        const response = await axiosPrivate.put(`/dashboard/create-project`, reqObj);

        if (response.status === 200) {
          handleCallback(true, response, modifiedProject, action, '', true);
        }
      } catch (error) {
        console.log('error while create project----------', error);
      }
    }
  };
  const handleCallback = (value, response, modifiedProject, action, name, isFetchProjectData) => {
    setLoading(false);
    setIsLoading(false);
    if (value) {
      if (!modifiedProject && action == 'create') {
        setOpenSearch(true);
        const b = Buffer.from(response.data[0]['anonymous block'].data);
        dispatch({
          type: 'SET_HKEY',
          payload: b.toString('hex').toUpperCase(),
        });
        let cacheArray = {
          step: 'step2',
          hkey_value: b.toString('hex').toUpperCase(),
        };
        localStorage.setItem('cache_data', JSON.stringify(cacheArray));
      }
      if (!modifiedProject && action == 'Complete') {
        setSnackbar({
          name: popUpItem.PROJECT_NAME,
          message: `has been marked as completed.`,
          severity: 'success',
        });
        updatePopUpItem([]);
      }
      if (!modifiedProject && action == 'Delete') {
        setSnackbar({
          name: popUpItem.PROJECT_NAME,
          message: `has been permanently deleted.`,
          severity: 'success',
        });
        updatePopUpItem([]);
      }

      if (action == 'Archive') {
        setSnackbar({
          name: name,
          message: `has been archived.`,
          severity: 'success',
        });
        updatePopUpItem([]);
      }
      if (action == 'UnArchive') {
        setSnackbar({
          name: name,
          message: ` has been unarchived.`,
          severity: 'success',
        });
        updatePopUpItem([]);
      }
      if (action == 'edit') {
        setRenameSnackbar({
          name: name,
          message: `Project has been renamed as`,
          severity: 'success',
        });
        updatePopUpItem([]);
      }
      if (isFetchProjectData) {
        fetchProjectsData();
      }
    } else {
      if (response?.response.data?.errorMessage?.code === '390318') {
        setWarningSnackbar(OAuthError);
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } else {
        setWarningSnackbar({
          name: 'Project Info:',
          message: 'Unable to insert/update project details',
          severity: 'error',
        });
      }
    }
  };
  useEffect(() => {
    // fetchCompanyName();
    dispatch({ type: 'SET_CLEAR_SCORING_OBJECT' });
    fetchProjectsData();
    fetchIndustryCode();
    fetchLocations();
    dispatch({
      type: 'SET_SELECTION_OBJECT',
      payload: ScoringCriteriaObject,
    });
  }, []);

  const fetchLocations = async () => {
    try {
      const response = await axiosPrivate.get(`/dashboard/locations`);
      if (response.status === 200) {
        dispatch({ type: 'LOCATIONS_DATA', payload: response.data });
      }
    } catch (error) {
      console.log('Error while fetching locations...', error);
      setWarningSnackbar({
        name: 'Location Info:',
        message: 'Unable to fetch the location details',
        severity: 'error',
      });
    }
  };

  const fetchIndustryCode = async () => {
    try {
      const response = await axiosPrivate.get(`/dashboard/industries`);
      var responseData = [];

      if (response.status === 200) {
        if (response.data.length > 0) {
          response.data?.map((obj) => {
            let object = {
              id: obj.INDUSTRY_CODE,
              name: obj.DESCRIPTION,
            };
            responseData.push(object);
          });
        }

        dispatch({ type: 'SET_INDUSTRY_CODE', payload: responseData });
      }
    } catch (error) {
      console.log('error.........', error);
      setWarningSnackbar({
        name: 'Industry Code Info:',
        message: 'Unable to fetch the Industry Code details',
        severity: 'error',
      });
    }
  };

  // const fetchCompanyName = async () => {
  //   try {
  //     updateLoader(false);
  //     const response = await axiosPrivate.get(`/dashboard/companies`);

  //     if (response.status === 200) {
  //       setCompanyList(response.data);
  //     }
  //   } catch (error) {
  //     console.log('error.........', error);
  //     setWarningSnackbar({
  //       name: 'Company Info:',
  //       message: 'Unable to fetch Company name',
  //       severity: 'error',
  //     });
  //   }
  // };

  const fetchProjectsData = async () => {
    setIsLoading(true);

    try {
      const response = await axiosPrivate.get(`/dashboard/projects?email=${email}`);

      if (response.status === 200) {
        setData(response.data);
        dispatch({ type: 'SET_PROJECTS_DATA', payload: response.data });
      }
    } catch (error) {
      console.log('error.........', error);
      setWarningSnackbar({
        name: 'Project Info:',
        message: 'Unable to fetch the project details',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCallbackShareProject = (isUnshared) => {
    fetchProjectsData();

    setSnackbar({
      name: projectName,
      message: isUnshared ? `is now a unshared project.` : `is now a shared project.`,
      severity: 'success',
    });
  };

  const closePopup = () => {
    updatePopup(false);
  };

  const handleClickCardContext = (name, itemObj) => {
    const hkeyProjectKey = Buffer.from(itemObj?.HKEY_HUB_PROJECT?.data);
    setHubKey(hkeyProjectKey?.toString('hex').toUpperCase());
    setProjectName(itemObj.PROJECT_NAME);
    switch (name) {
      case 'Share':
        setShareProjectOpen(true);
        break;
      case 'Duplicate':
        // duplicate
        setItem(itemObj);
        modifyOrigin('Duplicate');
        setOpen(true);

        break;
      case 'Rename':
        // rename
        modifyOrigin('Rename');

        setOpen(true);
        setItem(itemObj);
        break;
      case 'Complete':
        // complete
        updatePopup(true);
        updatePopUpItem(itemObj);
        setPopUpMessage({
          title: itemObj.PROJECT_NAME,
          confirm_button_text: 'Yes, complete project',
          footer_text:
            'Keep in mind, completing a project will freeze all data at this point in time and no other updates will be possible.',
          source: 'COMPLETE_PROJECT',
        });

        break;
      case 'Archive':
        // Archieve
        updatePopup(true);
        updatePopUpItem(itemObj);
        setPopUpMessage({
          title: itemObj.PROJECT_NAME,
          confirm_button_text: 'Yes, archive project',
          footer_text:
            'Archiving a project will freeze all data at this point in time and no other updates will be possible unless unarchived. ',
          source: 'ARCHIVE_PROJECT',
        });
        break;

      case 'Unarchive':
        // Unarchive
        updatePopup(true);
        updatePopUpItem(itemObj);
        setPopUpMessage({
          title: itemObj.PROJECT_NAME,
          confirm_button_text: 'Yes, unarchive project',
          footer_text:
            'Keep in mind: unarchiving a project will override the previous data. This action cannot be reverted. ',
          source: 'UN_ARCHIVE_PROJECT',
        });
        break;
      case 'Delete':
        updatePopup(true);
        updatePopUpItem(itemObj);
        setPopUpMessage({
          title: itemObj.PROJECT_NAME,
          confirm_button_text: 'Yes, delete project',
          footer_text:
            'Deleting a project will permanently remove all data and settings attached to it. This action cannot be reverted.',
          source: 'DELETE_PROJECT',
        });

        // delete
        break;

      default:
        break;
    }
  };

  const handleSubmitSearchCriteria = () => {
    let cacheData = JSON.parse(localStorage.getItem('cache_data'));
    var cache_data_obj = {
      ...cacheData,
      step: 'step3',
    };
    localStorage.setItem('cache_data', JSON.stringify(cache_data_obj));
    setOpenScoringCriteria(true);
    setOpenSearch(false);
  };

  const handleDeleteProject = async (data = {}) => {
    const userKey = Buffer.from(data?.HKEY_HUB_USER?.data).toString('hex').toUpperCase();

    try {
      const response = await axiosPrivate.get(`/dashboard/hub-users?hubprojectkey=${hubKey}`);

      if (response.status === 200) {
        const mUser = response.data.find((el) => el.ROLE_CODE === 'Owner');
        const fromUser = consolidated
          .find((elm) => elm.value.toLowerCase() === email.toLowerCase())
          ?.label.split('|')
          .at(0);

        try {
          const ownerName = getCapitalized(
            `${mUser?.FIRST_NAME ? mUser.FIRST_NAME : ''} ${mUser?.LAST_NAME ? mUser.LAST_NAME : ''}`
          );

          if (data.USER_COUNT > 1) {
            const reqObj = {
              email: email,
              payload: {
                owner: {
                  email: mUser?.EMAIL,
                  name: ownerName,
                },
                projectName,
                url: `https://${env}`,
                type: 'deleted',
                fromUser: getCapitalized(fromUser),
                route: 'mailtToOwner',
              },
            };

            await axiosPrivate.post(`/dashboard/postusercount`, reqObj);
          } // end-of-postusercount
        } catch (error) {
          console.log(error);
        }
      } // end-of-callobj-response
    } catch (error) {
      console.log('error while calling collabs in delete project--------------', error);
    }

    setIsLoading(true);

    const reqObj = {
      userKey: userKey,
      hubKey: hubKey,
    };
    try {
      const response = await axiosPrivate.put(`/dashboard/delete-project`, reqObj);

      if (response.status === 200) {
        handleCallback(true, response, false, 'Delete', '', true);
      }
    } catch (error) {
      console.log('error while delete project----------', error);
    }
  }; // end-of-delete

  const handleArchiveProject = async (data = {}) => {
    const userKey = Buffer.from(data?.HKEY_HUB_USER?.data).toString('hex').toUpperCase();
    const projectKey = Buffer.from(data?.HKEY_HUB_PROJECT?.data).toString('hex').toUpperCase();

    setIsLoading(true);

    const reqObj = {
      userKey: userKey,
      projectKey: projectKey,
    };

    try {
      const response = await axiosPrivate.put(`/dashboard/archive-project`, reqObj);

      if (response.status === 200) {
        handleCallback(true, response, false, 'Archive', data.PROJECT_NAME, false);
      }
    } catch (error) {
      console.log('error while Archive project----------', error);
    }

    const reqObj2 = {
      projectKey: projectKey,
      status: 'TRUE',
    };
    try {
      const response = await axiosPrivate.post(`/dashboard/freeze-project`, reqObj2);

      if (response.status === 200) {
        handleCallback(true, response, false, 'Archive', data.PROJECT_NAME, true);
      }
    } catch (error) {
      console.log('error while freeze project -after-archive---------', error);
    }
  }; // end-of-archive

  const handleUnArchiveProject = async (data = {}) => {
    const userKey = Buffer.from(data?.HKEY_HUB_USER?.data).toString('hex').toUpperCase();
    const projectKey = Buffer.from(data?.HKEY_HUB_PROJECT?.data).toString('hex').toUpperCase();
    setIsLoading(true);

    try {
      const reqObj = {
        userKey: userKey,
        projectKey: projectKey,
      };

      const response = await axiosPrivate.put(`/dashboard/unarchive-project`, reqObj);

      if (response.status === 200) {
        handleCallback(true, response, false, 'UnArchive', data.PROJECT_NAME, false);
      }
    } catch (error) {
      console.log('error while UnArchive project----------', error);
    }

    try {
      const reqObj = {
        projectKey: projectKey,
        status: 'FALSE',
      };

      const response = await axiosPrivate.post(`/dashboard/freeze-project`, reqObj);

      if (response.status === 200) {
        handleCallback(true, response, false, 'UnArchive', data.PROJECT_NAME, true);
      }
    } catch (error) {
      console.log('error while freeze project -after-unarchive---------', error);
    }
  };

  const popUpCallback = (data = [], source = '') => {
    switch (source) {
      case 'COMPLETE_PROJECT':
        handleSubmitModal(data.PROJECT_NAME, data.PROJECT_DESCRIPTION, false, 'Complete', data, data.INDUSTRY_CODE);
        closePopup();
        break;
      case 'DELETE_PROJECT':
        handleDeleteProject(data);
        closePopup();
        break;
      case 'ARCHIVE_PROJECT':
        handleArchiveProject(data);
        closePopup();
        break;
      case 'UN_ARCHIVE_PROJECT':
        handleUnArchiveProject(data);
        closePopup();
        break;
      default:
        break;
    }
  };
  const handleScoringBack = () => {
    let cacheData = JSON.parse(localStorage.getItem('cache_data'));
    var cache_data_obj = {
      ...cacheData,
      step: 'step2',
    };
    localStorage.setItem('cache_data', JSON.stringify(cache_data_obj));
    setOpenScoringCriteria(false);
    setOpenSearch(true);
  };
  const handleScoreSubmit = () => {
    setOpenScoringCriteria(false);
    dispatch({
      type: 'SELECTED_PROJECT_STATUS',
      payload: 'In progress',
    });
    let cacheData = JSON.parse(localStorage.getItem('cache_data'));
    var cache_data_obj = {
      ...cacheData,
      step: 'step4',
    };
    localStorage.setItem('cache_data', JSON.stringify(cache_data_obj));
    navigate('/weighting', { state: { value: 'demo' } });
  };

  const WeightingProcessCall = async (projectKey, hubweightingKey, availableTo) => {
    const date = availableTo ? "'" + moment(availableTo).format('YYYY-MM-DD') + "'" : null;

    try {
      const reqObj = {
        projectKey: projectKey,
        hubweightingKey: hubweightingKey,
        date,
      };

      await axiosPrivate.post(`/dashboard/weightings`, reqObj);
    } catch (error) {
      console.log('error in WeightingProcessCall--------------------', error);
      setWarningSnackbar({
        name: 'Scoring Info:',
        message: 'Issue in weight/scoring calculation',
        severity: 'error',
      });
    }
  };

  const handleOnclickProject = async (itemObj) => {
    if (itemObj) {
      const hkeyProjectKey = Buffer.from(itemObj?.HKEY_HUB_PROJECT?.data);
      dispatch({ type: 'SET_MAP_ZOOM', payload: 14.5 });
      dispatch({ type: 'SET_MAP_CENTER', payload: [-0.118092, 51.5072] });
      const hkey = hkeyProjectKey?.toString('hex').toUpperCase();
      dispatch({
        type: 'SET_HKEY',
        payload: hkey,
      });
      const hkeyProjectWeightKey = Buffer.from(itemObj?.HKEY_HUB_WEIGHTING?.data);
      const hwgtkey = hkeyProjectWeightKey?.toString('hex').toUpperCase();
      dispatch({
        type: 'SET_HWGT_HKEY',
        payload: hwgtkey,
      });
      dispatch({
        type: 'SELECTED_PROJECT_STATUS',
        payload: itemObj.STATUS,
      });

      dispatch({
        type: 'SET_PROJECT_NAME',
        payload: {
          projectName: itemObj.PROJECT_NAME,
          shortlisted: itemObj.SHORTLISTED,
        },
      });

      try {
        const response = await axiosPrivate.get(`/dashboard/project-search-history?hkey=${hkey}&hwgtkey=${hwgtkey}`);

        if (response.status === 200 && response.data.length) {
          dispatch({
            type: 'SET_AVAILABLE_TO',
            payload: response.data.at(0)?.AVAILABLE_TO
              ? moment(response.data[0].AVAILABLE_TO).format('YYYY-MM-DD')
              : null,
          });
          WeightingProcessCall(
            hkeyProjectKey?.toString('hex').toUpperCase(),
            hkeyProjectWeightKey?.toString('hex').toUpperCase(),
            response.data[0].AVAILABLE_TO
          );
        }

        if (itemObj.STATUS == 'Completed' || itemObj.STATUS == 'Archived') {
          dispatch({
            type: 'SELECTED_PROJECT_COMPLETED_DATE',
            // this need to be changes after backend work
            payload: moment(itemObj.COMPLETED_ON).format('DD.MM.yyyy'),
          });
        }

        navigate('/map/mapview');
      } catch (error) {
        console.log('error in the project search history-------', error);
      }
    }
  };

  const handleSortByDropDownChange = (e) => {
    dispatch({ type: 'SET_SORT_BY_OPTION', payload: e.target.value });
  };

  const handleShowByDropDownChange = (e) => {
    dispatch({ type: 'SET_SHOW_BY_OPTION', payload: e.target.value });
  };

  const sortByModal = () => {
    const sortBylist = ['Date created', 'Last viewed', 'Name', 'Status'];
    return (
      <FormControl>
        <Select
          value={sortByOption}
          onChange={handleSortByDropDownChange}
          disableUnderline
          variant='standard'
          renderValue={(selected) => selected}
          input={<BootstrapInput />}
          MenuProps={{
            PaperProps: {
              sx: {
                width: 215,
                borderRadius: '4px',
                paddingLeft: 1,
                paddingBottom: 1,
              },
            },
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingLeft: 17,
              paddingTop: 18,
              fontWeight: 400,
              fontSize: '24px',
              lineHeight: '40px',
              fontFamily: 'Calibre-R',
              marginBottom: '11px',
            }}
          >
            Sort by
          </div>
          {sortBylist.map((name) => (
            <MenuItem
              key={name}
              value={name}
              className={`dropdown_dashboard ${name === sortByOption ? 'dropdown_option' : ''}`}
            >
              <Radio
                checked={sortByOption === name}
                sx={{
                  '&.Mui-checked': {
                    color: '#003F2D',
                  },
                  '&.MuiRadio-colorPrimary': {
                    color: '#003F2D',
                  },
                }}
              />
              <CustomTypography
                text={name}
                fontsize='14px'
                fontweight='400'
                lineheight='21px'
                fontcolor='#1A1A1A'
                fontfamily='Calibre-R'
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const showByModal = () => {
    const showlist = ['All', 'In progress', 'Completed', 'Archive'];
    return (
      <FormControl>
        <Select
          value={showByOption}
          onChange={handleShowByDropDownChange}
          disableUnderline
          variant='standard'
          renderValue={(selected) => selected}
          input={<BootstrapInput />}
          MenuProps={{
            PaperProps: {
              sx: {
                width: 215,
                borderRadius: '4px',
                paddingLeft: 1,
                paddingBottom: 1,
              },
            },
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingLeft: 17,
              paddingTop: 18,
              fontWeight: 400,
              fontSize: '24px',
              lineHeight: '40px',
              fontFamily: 'Calibre-R',
              marginBottom: '11px',
            }}
          >
            Project type
          </div>
          {showlist.map((name) => (
            <MenuItem
              key={name}
              value={name}
              className={`dropdown_dashboard ${name === showByOption ? 'dropdown_option' : ''}`}
            >
              <Radio
                checked={showByOption === name}
                sx={{
                  '&.Mui-checked': {
                    color: '#003F2D',
                  },
                  '&.MuiRadio-colorPrimary': {
                    color: '#003F2D',
                  },
                }}
              />
              <CustomTypography
                text={name}
                fontsize='14px'
                fontweight='400'
                lineheight='21px'
                fontcolor='#1A1A1A'
                fontfamily='Calibre-R'
                // fontfamily='Roboto, Helvetica, Arial, sans-serif'
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const filterProjectList = (listValue, index) => {
    return (
      <Grid item md={4} key={index} style={{ padding: 20 }}>
        <CardComponent
          item={listValue}
          handleOnClickMenu={handleClickCardContext}
          handleOnclickProject={() => {
            handleOnclickProject(listValue);
          }}
        />
      </Grid>
    );
  };
  return (
    <div style={{ backgroundColor: '#EEF1F1', width: '100%' }}>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'row',
          paddingLeft: 60,
          paddingRight: 60,
          paddingTop: 26,
          // position: "fixed",
        }}
      >
        <Grid item md={7}>
          <CustomTypography
            text='Dashboard'
            fontsize='36px'
            fontweight='400'
            lineheight='40px'
            fontcolor='#1A1A1A'
            marginTop='40px'
          />
        </Grid>
        <Grid
          item
          md={5}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            marginTop: '45px',
            paddingRight: 15,
          }}
        >
          <Grid
            // md={6}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {/* Show:All */}
            <CustomTypography text={`Show:`} fontweight='400' lineheight='21px' />
            &nbsp;
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              {showByModal()}
            </div>
          </Grid>
          <Grid
            // md={6}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              paddingLeft: 14,
            }}
          >
            {/* Sort by: Last viewed */}
            <CustomTypography text={`Sort by:`} fontweight='400' lineheight='21px' />
            &nbsp;
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {sortByModal()}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 10,
        }}
      >
        <Grid item md={4} style={{ padding: 20 }}>
          <Card
            onClick={handleClickOpen}
            style={{
              cursor: 'pointer',
              minHeight: 260,
              borderRadius: 10,
              backgroundColor: '#d7dfdd',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 185,
              boxShadow: 'none',
            }}
          >
            <CardContent
              style={{
                paddingTop: 50,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <PluseIcon />
              <CustomTypography
                text='Create a new project'
                fontfamily='Calibre-SB'
                fontsize='16px'
                fontweight='500'
                lineheight='16px'
                fontcolor='#003F2D'
                marginLeft='10px'
              />
            </CardContent>
          </Card>
        </Grid>

        {!modifiedData?.length > 0 ? (
          <h4></h4>
        ) : (
          modifiedData.map((listValue, index) => {
            let condition1 = '';
            switch (showByOption) {
              case 'In progress':
                condition1 = 'In progress';
                break;
              case 'Completed':
                condition1 = 'Completed';
                break;
              case 'Archive':
                condition1 = 'Archived';
                break;
              case 'All':
                condition1 = 'All';
                break;
              default:
                condition1 = 'All';
                break;
            }
            if (showByOption !== 'All') {
              if (condition1 === listValue.STATUS) {
                return filterProjectList(listValue, index);
              }
            } else {
              return filterProjectList(listValue, index);
            }
          })
        )}
      </Grid>
      {open && (
        <ProjectModal
          origin={origin}
          item={item}
          open={open}
          handleClose={handleClose}
          error={projectNameError}
          handleFalseError={() => setProjectNameError(false)}
          handleSubmit={handleSubmitModal}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      {openSearch && (
        <SearchCriteriaModal
          open={openSearch}
          handleClose={handleClose}
          handleSubmit={handleSubmitSearchCriteria}
        ></SearchCriteriaModal>
      )}
      {openScoringCriteria && (
        <ScoringCriteria
          open={openScoringCriteria}
          handleClose={handleClose}
          handleSubmit={handleScoreSubmit}
          handleBack={handleScoringBack}
        ></ScoringCriteria>
      )}
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
      {snackbar && (
        <SnackBar
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          name={snackbar.name}
          message={snackbar.message}
        />
      )}
      {renameSnackbar && (
        <SnackBar
          open={true}
          setOpen={() => setRenameSnackbar(null)}
          severity={renameSnackbar.severity}
          name={renameSnackbar.name}
          message={renameSnackbar.message}
          source='edit'
        />
      )}
      {isLoading && <LoadingOverlayComponent open={isLoading ? true : false} />}
      {openPopup && (
        <ConfirmationPopup
          openPopup={openPopup}
          closePopup={closePopup}
          popUpItem={popUpItem}
          popUpMessage={popUpMessage}
          popUpCallback={popUpCallback}
        />
      )}
      {shareProjectOpen && (
        <ShareProjectModal
          open={shareProjectOpen}
          setOpen={setShareProjectOpen}
          loading={usersLoading}
          users={users}
          setUsers={setUsers}
          completeUsers={completeUsers}
          setCompleteUsers={setCompleteUsers}
          consolidated={consolidated}
          email={email}
          hubKey={hubKey}
          setHubKey={setHubKey}
          getProjects={handleCallbackShareProject}
          projectName={projectName}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
  );
};
export default Dashboard;
