import React from 'react';
import { PieChart, Pie, Legend, Tooltip } from 'recharts';
import { Grid } from '@material-ui/core';
import Divider from '@mui/material/Divider';

const renderColorfulLegendText = (value, entry) => {
  return (
    <span
      style={{
        color: '#000000',
        fontWeight: 400,
        padding: '10px',
        fontFamily: 'Calibre-R',
        lineHeight: '21px',
        fontSize: '14px',
      }}
    >
      {value !== 'Available points' && value}
    </span>
  );
};

const getSubCategoryName = (name) => {
  let labelName = '';
  switch (name) {
    case 'TRANSPORT_SCORE':
      labelName = 'Transport';
      break;
    case 'LOCAL_AMENITY_SCORE':
      labelName = 'Location & Amenity';
      break;
    case 'BUILDING_SCORE':
      labelName = 'Building';
      break;
    case 'ESG_SCORE':
      labelName = 'ESG';
      break;
    case 'DELIVERABILITY_RISK_SCORE':
      labelName = 'Deliverability & Risk';
      break;
    case 'TR_PROXIMITY_NEAREST_TUBE_STATION_SCORE':
      labelName = 'Proximity to nearest tube station';
      break;
    case 'TR_PROXIMITY_MAINLINE_TRAIN_STATION_SCORE':
      labelName = 'Proximity to mainline train station';
      break;
    case 'TR_PROXIMITY_ELIZABETH_LINE_SCORE':
      labelName = 'Proximity to Elizabeth Line';
      break;
    case 'TR_TUBES_WITHIN_1KM_SCORE':
      labelName = 'Tube lines within 1km';
      break;
    case 'TR_PROXIMITY_REGIONAL_TRAIN_STATION_SCORE':
      labelName = 'REGIONAL - Proximity to mainline train station';
      break;
    case 'TR_PROXIMITY_REGIONAL_TRAM_STATION_SCORE':
      labelName = 'REGIONAL - Proximity to tram station';
      break;
    case 'BI_ON_SITE_DEDICATED_GYM_SCORE':
      labelName = 'On-site Dedicated Gym';
      break;
    case 'BI_ON_SITE_CATERING_AVAILABLE_SCORE':
      labelName = 'On-site Catering Available';
      break;
    case 'BI_TERRACES_SCORE':
      labelName = 'Terraces';
      break;
    case 'BI_FLOOR_SIZE_SCORE':
      labelName = 'Floor plate suitability';
      break;
    case 'BI_LOOK_AND_FEEL_SCORE':
      labelName = 'Look and Feel';
      break;
    case 'BI_FLOOR_TO_CEILING_HEIGHT_SCORE':
      labelName = 'Floor to ceiling height';
      break;
    case 'BI_OCCUPATIONAL_DENSITY_SCORE':
      labelName = 'Occupational Density';
      break;
    case 'BI_COLUMNS_SCORE':
      labelName = 'Columns';
      break;
    case 'BI_NATURAL_LIGHT_SCORE':
      labelName = 'Natural Light';
      break;
    case 'BI_ON_SITE_AMENITY_SCORE':
      labelName = 'On-site Amenity';
      break;
    case 'BI_ON_SITE_SHOWER_FACILITIES_SCORE':
      labelName = 'On-site shower facilities';
      break;
    case 'BI_SELF_CONTAINED_SCORE':
      labelName = 'Self-contained';
      break;
    case 'BI_WIREDSCORE_RATING_SCORE':
      labelName = 'WiredScore Rating';
      break;
    case 'BI_BUILDING_TECHNOLOGY_SCORE':
      labelName = 'Building Technology';
      break;
    case 'BI_BRANDING_RIGHTS_SCORE':
      labelName = 'Branding Rights';
      break;
    case 'BI_AIR_CONDITIONING_SCORE':
      labelName = 'Air Conditioning';
      break;
    case 'BI_RAISED_FLOORS_SCORE':
      labelName = 'Raised Floors';
      break;
    case 'BI_CAR_PARKING_SCORE':
      labelName = 'Car Park Spaces';
      break;
    case 'BI_LOCKERS_SCORE':
      labelName = 'Lockers';
      break;
    case 'BI_SINGLE_LET_BUILDING_SCORE':
      labelName = 'Single let Building';
      break;
    case 'LA_TRANSPORT_HUB_SCORE':
      labelName = 'Proximity to transport hubs';
      break;
    case 'LA_LEVEL_LOCAL_AMENITY_SCORE':
      labelName = 'Level of local amenity';
      break;
    case 'LA_GREEN_SPACE_SCORE':
      labelName = 'Green Spaces';
      break;
    case 'LA_PERSONAL_SAFETY_SCORE':
      labelName = 'Personal safety';
      break;
    case 'LA_PROX_GYM_HOTEL_SCORE':
      labelName = 'Proximity to gyms/hotels';
      break;
    case 'LA_PROX_CLIENT_COMPETITOR_SCORE':
      labelName = 'Proximity to Clients/Competitors';
      break;
    case 'LA_FLEX_OPTIONS_SCORE':
      labelName = 'Flex options in the immediate area';
      break;
    case 'ESG_BREEAM_RATING_SCORE':
      labelName = 'BREEAM Rating';
      break;
    case 'ESG_NABERS_RATING_SCORE':
      labelName = 'Nabers Rating';
      break;
    case 'ESG_EPC_RATING_SCORE':
      labelName = 'EPC Rating';
      break;
    case 'ESG_ALL_ELECTRIC_BUILDING_SCORE':
      labelName = 'All-Electric Building';
      break;
    case 'ESG_NET_CARBON_ZERO_SCORE':
      labelName = 'Net Carbon Zero';
      break;
    case 'ESG_NATURALLY_VENTILATED_SCORE':
      labelName = 'Naturally Ventilated';
      break;
    case 'ESG_WELL_RATING_SCORE':
      labelName = 'WELL Rating';
      break;
    case 'ESG_CYCLE_SPACE_SCORE':
      labelName = 'Cycle Spaces';
      break;
    case 'DR_DELIVERY_DATE_SCORE':
      labelName = 'Delivery status';
      break;
    case 'DR_LANDLORD_CREDIBILITY_SCORE':
      labelName = 'Landlord Credibility';
      break;
    default:
      break;
  }
  return labelName;
};

const CustomTooltip = ({
  active,
  payload,
  label,
  transportData = [],
  deliveryData = [],
  esgData = [],
  buildingData = [],
  localAmenityData = [],
  customData = [],
}) => {
  if (active && payload && payload.length) {
    let SubWeightingDetails = [];
    let color = '#DFE6E5';
    let backgroundColor = '#DFE6E5';
    switch (payload[0]?.name) {
      case 'Transport':
        SubWeightingDetails = transportData;
        color = '#DBD99A';
        backgroundColor = 'rgb(246,245,229)';
        break;
      case 'Location & Amenity':
        SubWeightingDetails = localAmenityData;
        color = '#A388BF';
        backgroundColor = 'rgb(206,196,217)';
        break;
      case 'Building':
        SubWeightingDetails = buildingData;
        color = '#3E7CA6';
        backgroundColor = 'rgb(223,238,234)';
        break;
      case 'ESG':
        SubWeightingDetails = esgData;
        color = '#80BBAD';
        backgroundColor = 'rgb(206,222,232)';

        break;
      case 'Deliverability & Risk':
        SubWeightingDetails = deliveryData;
        color = '#885073';
        backgroundColor = 'rgb(193,150,177)';
        break;
      case 'Custom Criteria':
        SubWeightingDetails = customData;
        color = '#96b0bf';
        backgroundColor = '#c0d8e6';
        break;
      default:
        break;
    }

    const max = Math.max(...Object.values(SubWeightingDetails));

    return (
      <Grid style={{ padding: 8, paddingBottom: 3, paddingTop: 3 }}>
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 5,
            paddingBottom: 3,
            paddingTop: 3,
          }}
        >
          <Grid
            item
            xs={8}
            style={{
              fontSize: '20px',
              fontWeight: '400',
              fontColor: '#000000',
              lineHeight: '24px',
              fontFamily: 'Calibre-R',
            }}
          >
            {payload[0]?.name}
          </Grid>
          <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
              style={{
                backgroundColor: backgroundColor,
                width: '50px',
                height: '50px',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                lineHeight: '24px',
                fontFamily: 'Calibre-R',
                fontSize: '16px',
              }}
            >
              {Math.round(payload[0].payload.payload.value)}
            </div>
          </Grid>
        </Grid>
        {Object.keys(SubWeightingDetails).map((key, index) => {
          let keyName = getSubCategoryName(key) ? getSubCategoryName(key) : key;
          keyName = keyName.length > 35 ? keyName.substring(0, 35) + '...' : keyName;
          if (SubWeightingDetails[key] > 0) {
            return (
              <>
                {index === 0 && <Divider style={{ marginTop: 7, marginBottom: 7 }} />}
                <Grid
                  container
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: 5,
                    paddingBottom: 2,
                  }}
                >
                  <Grid
                    item
                    xs={7}
                    style={{
                      fontSize: '12px',
                      fontWeight: '400',
                      color: 'rgba(26, 26, 26, 0.6)',
                      lineHeight: '24px',
                      fontFamily: 'Calibre-R',
                    }}
                  >
                    {keyName}
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      fontSize: '12px',
                      fontWeight: '500',
                      fontColor: '#000000',
                      lineHeight: '24px',
                      fontFamily: 'Calibre-R',
                    }}
                  >
                    <svg width='200' height='20'>
                      <g>
                        <rect
                          center
                          width={(SubWeightingDetails[key] / max) * 100}
                          height='15'
                          style={{ fill: color }}
                          x={0}
                          y={5}
                        />
                        <text x={(SubWeightingDetails[key] / max) * 100 + 3.5} y='17'>
                          {parseFloat(SubWeightingDetails[key]).toFixed(2)}
                          {/* {SubWeightingDetails[key] <= 0.5
                            ? 1
                            : Math.round(SubWeightingDetails[key])} */}
                        </text>
                      </g>
                    </svg>{' '}
                    &nbsp;&nbsp;
                  </Grid>
                </Grid>
              </>
            );
          }
        })}
      </Grid>
    );
  }
  return null;
};

function IndividualPieCharts({
  chartData = [],
  transportData = [],
  deliveryData = [],
  esgData = [],
  buildingData = [],
  localAmenityData = [],
  customData = [],
  overAllScore = '',
}) {
  return (
    <PieChart width={320} height={240} cursor={false}>
      <Pie
        data={chartData}
        cx={170}
        cy={120}
        innerRadius={70}
        outerRadius={115}
        dataKey='value'
        startAngle={90}
        endAngle={-270}
        style={{ outline: 'none' }}
      ></Pie>
      <text
        x={175}
        y={125}
        textAnchor='middle'
        dominantBaseline='middle'
        style={{
          fontSize: 35,
          fontWeight: 'bold',
        }}
      >
        {Math.round(overAllScore)}
      </text>
      <Legend
        iconType='circle'
        layout='vertical'
        verticalAlign='middle'
        iconSize={10}
        padding={10}
        formatter={renderColorfulLegendText}
        wrapperStyle={{ width: '250px', top: 65, left: 335 }}
      />
      <Tooltip
        content={
          <CustomTooltip
            transportData={transportData}
            deliveryData={deliveryData}
            esgData={esgData}
            buildingData={buildingData}
            localAmenityData={localAmenityData}
            customData={customData}
          />
        }
        isAnimationActive={false}
        wrapperStyle={{
          outline: 'none',
          backgroundColor: 'white',
          padding: '8px',
          // border: '1px solid #cccc',
          width: '350px',
          overflow: 'auto',
          border: '1px solid #dadde9',
          boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.24)',
          borderRadius: '10px',
          zIndex: 2,
          top: '-82%',
        }}
      />
    </PieChart>
  );
}

export default IndividualPieCharts;
