import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ContextMenuIcon from '../icons/context_icon';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '../icons/share_icon';
import DeleteIcon from '../icons/delete_icon';
import RenameIcon from '../icons/rename_icon';
import CompleteIcon from '../icons/complete_icon';
import ArchieveIcon from '../icons/archieve_icon';
import DuplicateIcon from '../icons/duplicate_icon';
import Divider from '@mui/material/Divider';
import UnArchiveIcon from '../icons/unarchive_icon';

const useStyles = makeStyles(() => ({
  contextIconBtn: {
    '&:hover': {
      backgroundColor: 'white !important',
    },
  },
  contextIconSvg: {
    '&:hover': {
      color: 'white !important',
    },
  },
}));
const StyledMenu = withStyles({})((props) => (
  <Menu
    width={'100%'}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    marginThreshold={40}
    PaperProps={{
      style: {
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
        width: '15%',
        marginTop: 6,
        '&:hover': {
          backgroundColor: '#FFFFFF',
        },
      },
    }}
    {...props}
  />
));

const ContextMenu = ({
  menuItems = [],
  callBack = null,
  showLines = false,
  updateOptions,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    event.stopPropagation();
    updateOptions(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setTimeout(() => updateOptions(false), 1000);
    setAnchorEl(null);
  };
  const handleCallback = (name, event) => {
    handleClose();
    callBack(name, event);
  };
  const classes = useStyles();
  return (
    <div>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        className={classes.contextIconBtn}
        style={{
          backgroundColor: Boolean(anchorEl) ? 'white' : 'transparent',
          width: '45px',
          padding: '12px',
          marginRight: '-12px',
          marginTop: '-12px',
        }}
        onClick={handleClick}
      >
        <ContextMenuIcon
          className={classes.contextIconSvg}
          color={Boolean(anchorEl) ? '#FFFFFF' : 'white'}
        />
      </IconButton>
      <StyledMenu
        style={{ marginRight: 50 }}
        id='context'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.length ? (
          menuItems.map((item, index3) => (
            <div key={item.name + index3}>
              {(item.name == 'Delete' || item.name == 'Duplicate') && (
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              )}
              <MenuItem
                key={index3}
                onClick={(event) => {
                  handleCallback(item.name, event);
                }}
                id='dashboard_dropdown'
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexDirection: 'row',
                  height: '38px',
                }}
              >
                <Grid xs={1} style={{ paddingRight: 20, marginTop: 5 }}>
                  {item.name === 'Share' && <ShareIcon />}
                  {item.name === 'Duplicate' && <DuplicateIcon />}
                  {item.name === 'Rename' && <RenameIcon />}
                  {item.name === 'Complete' && <CompleteIcon />}
                  {item.name === 'Archive' && <ArchieveIcon />}
                  {item.name === 'Unarchive' && <UnArchiveIcon />}
                  {item.name === 'Delete' && <DeleteIcon />}
                </Grid>
                <Grid
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <ListItemText
                    primary={item.name}
                    style={{
                      color: '#1A1A1A',
                      fontSize: '16px',
                      lineHeight: '24px',
                      fontFamily: 'Calibre-R',
                      fontWeight: 400,
                    }}
                  />
                </Grid>
              </MenuItem>
            </div>
          ))
        ) : (
          <Grid></Grid>
        )}
      </StyledMenu>
    </div>
  );
};
export default ContextMenu;
