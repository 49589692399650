import React from "react";

function CloseFillIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill="#003F2D"
                d="M8 .5A7.493 7.493 0 00.5 8c0 4.148 3.353 7.5 7.5 7.5 4.148 0 7.5-3.352 7.5-7.5C15.5 3.853 12.148.5 8 .5zm3.75 10.193l-1.057 1.057L8 9.057 5.308 11.75 4.25 10.693 6.942 8 4.25 5.308 5.308 4.25 8 6.942l2.693-2.692 1.057 1.058L9.057 8l2.693 2.693z"
            ></path>
        </svg>
    );
}

export default CloseFillIcon;
