import React, { useEffect, useMemo, useState } from 'react';
import { Dialog, Button, Grid } from '@material-ui/core';
import CloseIcon from '../icons/close_icon';
import EditIcon from '../icons/EditIcon';
import CustomTypography from '../utils/text';
import CustomCheckbox from './custom_checkbox';
import InfoIcon from '../icons/info_icon';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import useStyles from '../styles/index';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { cloneDeep } from 'lodash';
import NoCustomData from '../icons/NoCustomCriteria';
import { FiPlus } from 'react-icons/fi';
import { RiTimerLine } from 'react-icons/ri';
import quickSetupData from '../utils/quickSetup';
import { MdOutlineChevronRight, MdOutlineClose, MdOutlineCheck } from 'react-icons/md';
import TextField from '../components/custom_textfield';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
const ScoringCriteriaModal = ({ open = false, handleClose, handleSubmit, handleBack, isEdit = false }) => {
  const ScoringCriteriaData = useSelector((state) => state.mainReducer.selectionObject);
  const hkey = useSelector((state) => state.mainReducer.hkey);
  const hwgtkey = useSelector((state) => state.mainReducer.hwgtkey);
  const classes = useStyles();
  const [selectAll, setSelectAll] = useState(true);
  const [selectAllTransport, setSelectAllTransport] = useState(true);
  const [selectAllBuilding, setSelectAllBuilding] = useState(true);
  const [selectAllLocation, setSelectAllLocation] = useState(true);
  const [selectAllESG, setSelectAllESG] = useState(true);
  const [selectAllDeliverability, setSelectAllDeliverability] = useState(true);
  const dispatch = useDispatch();
  const [scoringCriteriaList, setScoringCriteriaList] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [actieveTab, setActieveTab] = useState('Transport');
  const [criteriaValue, setCriteriaValue] = useState('');
  const [error, setError] = useState();
  const [isExist, setIsExist] = useState(false);
  const [editableIndex, setEditableIndex] = useState(11);
  const [persistedValue, setPersistedValue] = useState(-1);
  const [selectAllCustCrit, setSelectAllCustCrit] = useState(true);
  const validValue = /^[a-z0-9 ]+$/i;
  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    let cacheData = JSON.parse(localStorage.getItem('cache_data'));
    if (cacheData?.score_obj) {
      setScoringCriteriaList(cacheData?.score_obj);
    } else {
      let data = [];
      if (ScoringCriteriaData) data = JSON.parse(JSON.stringify(ScoringCriteriaData));
      setScoringCriteriaList(data);
      handleSaveCacheScoreData(data);
    }
  }, [ScoringCriteriaData]);
  useEffect(() => {
    if (scoringCriteriaList) {
      let selectAllValue = true;
      let isValueDisable = true;
      scoringCriteriaList?.forEach((obj) => {
        obj?.data?.forEach((objItem) => {
          if (objItem.value || objItem.value == 'true') {
            isValueDisable = false;
          } else {
            selectAllValue = false;
          }
        });
      });
      setSelectAll(selectAllValue);
      setDisabled(isValueDisable);
      const selectAllActiveTab = scoringCriteriaList
        .find((el) => el.header === actieveTab)
        ?.data.some((elm) => !elm.value);
      selectAllActiveTabHandler(selectAllActiveTab);
    }
  }, [scoringCriteriaList, actieveTab]);
  const handleSaveCacheScoreData = (dataSet) => {
    var data = [...dataSet];
    data.forEach((e) => {
      var filterArray = e.data.filter((item) => item.value == true || item.value == 'true');
      if (filterArray.length > 0) {
        e.data.forEach((obj) => {
          if (!obj.value || obj.value == 'false') {
            obj['score'] = 0;
          }
        });
      } else {
        e.score = 0;
        e.data.forEach((obj) => {
          obj['score'] = 0;
        });
      }
    });
    if (data?.length > 0) {
      var arrayfilter = data?.filter((e) => e.score > 0);
      data?.forEach((e) => {
        if (arrayfilter?.length == 1) {
          if (arrayfilter[0].header == e.header) {
            e.score = 100;
          }
        }
        var filterSubCatergory = e?.data.filter((subObj) => subObj.value == true);
        if (filterSubCatergory?.length == 1) {
          var indexValue = e?.data?.findIndex((eobj) => eobj.item == filterSubCatergory[0].item);
          if (indexValue != -1) {
            e.data[indexValue].score = 100;
          }
        } else {
          var indexValue2 = e?.data?.findIndex((eobj) => eobj.score == 100);

          if (indexValue2 != -1) {
            e.data[indexValue2].score = 0;
          }
        }
      });
    }
    let cacheData = JSON.parse(localStorage.getItem('cache_data'));
    var cache_data_obj = {
      ...cacheData,
      score_obj: data,
    };
    localStorage.setItem('cache_data', JSON.stringify(cache_data_obj));
  };
  const handleContinue = () => {
    var data = [...scoringCriteriaList];
    data.forEach((e) => {
      var filterArray = e.data.filter((item) => item.value == true || item.value == 'true');
      if (filterArray.length > 0) {
        e.data.forEach((obj) => {
          if (!obj.value || obj.value == 'false') {
            obj['score'] = 0;
          }
        });
      } else {
        e.score = 0;
        e.data.forEach((obj) => {
          obj['score'] = 0;
        });
      }
    });
    if (data?.length > 0) {
      var arrayfilter = data?.filter((e) => e.score > 0);
      data?.forEach((e) => {
        if (arrayfilter?.length == 1) {
          if (arrayfilter[0].header == e.header) {
            e.score = 100;
          }
        }
        var filterSubCatergory = e?.data.filter((subObj) => subObj.value == true);
        if (filterSubCatergory?.length == 1) {
          var indexValue = e?.data?.findIndex((eobj) => eobj.item == filterSubCatergory[0].item);
          if (indexValue != -1) {
            e.data[indexValue].score = 100;
          }
        }
      });
    }
    dispatch({ type: 'SET_SELECTION_OBJECT', payload: data });
    handleSubmit();
  };

  const handleCheckAll = () => {
    setSelectAll(!selectAll);
    var data = [...scoringCriteriaList];
    data?.forEach((e) => {
      e?.data?.forEach((item) => {
        item.value = !selectAll;
        if (selectAll) {
          item.score = 0;
        }
      });
    });
    handleSaveCacheScoreData(data);
    setScoringCriteriaList(data);
  };
  const handleCheckAllTransport = () => {
    setSelectAllTransport((flag) => !flag);
    const cloned = cloneDeep(scoringCriteriaList);
    const index = cloned.findIndex((el) => el.header === actieveTab);
    const updated = cloned[index].data.map((el) => ({
      ...el,
      value: !selectAllTransport,
      score: selectAllTransport ? 0 : el.score,
    }));
    cloned[index] = { ...cloned[index], data: updated };
    handleSaveCacheScoreData(cloned);
    setScoringCriteriaList(cloned);
  };
  const handleCheckAllBuilding = () => {
    setSelectAllBuilding((flag) => !flag);
    const cloned = cloneDeep(scoringCriteriaList);
    const index = cloned.findIndex((el) => el.header === actieveTab);
    const updated = cloned[index].data.map((el) => ({
      ...el,
      value: !selectAllBuilding,
      score: selectAllBuilding ? 0 : el.score,
    }));
    cloned[index] = { ...cloned[index], data: updated };
    handleSaveCacheScoreData(cloned);
    setScoringCriteriaList(cloned);
  };
  const handleCheckAllLocation = () => {
    setSelectAllLocation((flag) => !flag);
    const cloned = cloneDeep(scoringCriteriaList);
    const index = cloned.findIndex((el) => el.header === actieveTab);
    const updated = cloned[index].data.map((el) => ({
      ...el,
      value: !selectAllLocation,
      score: selectAllLocation ? 0 : el.score,
    }));
    cloned[index] = { ...cloned[index], data: updated };
    handleSaveCacheScoreData(cloned);
    setScoringCriteriaList(cloned);
  };
  const handleCheckAllESG = () => {
    setSelectAllESG((flag) => !flag);
    const cloned = cloneDeep(scoringCriteriaList);
    const index = cloned.findIndex((el) => el.header === actieveTab);
    const updated = cloned[index].data.map((el) => ({
      ...el,
      value: !selectAllESG,
      score: selectAllESG ? 0 : el.score,
    }));
    cloned[index] = { ...cloned[index], data: updated };
    handleSaveCacheScoreData(cloned);
    setScoringCriteriaList(cloned);
  };
  const handleCheckAllDeliverability = () => {
    setSelectAllDeliverability((flag) => !flag);
    const cloned = cloneDeep(scoringCriteriaList);
    const index = cloned.findIndex((el) => el.header === actieveTab);
    const updated = cloned[index].data.map((el) => ({
      ...el,
      value: !selectAllDeliverability,
      score: selectAllDeliverability ? 0 : el.score,
    }));
    cloned[index] = { ...cloned[index], data: updated };
    handleSaveCacheScoreData(cloned);
    setScoringCriteriaList(cloned);
  };

  const handleCloseScorePopup = () => {
    handleClose();
  };
  const handleCheck = (item) => {
    const data = [...scoringCriteriaList];
    const objectIndex = data.findIndex((i) => i.header === actieveTab);
    if (objectIndex !== -1) {
      const index = data[objectIndex].data.findIndex((e) => e.item === item);
      const flag = data[objectIndex].data[index].value;
      data[objectIndex].data[index].value = !flag;
      if (index !== -1 && !flag) {
        data[objectIndex].data[index].score = 0;
      }
    }
    let selectAllValue = true;
    data?.forEach((obj) => {
      obj?.data?.forEach((objItem) => {
        if (!objItem.value) {
          selectAllValue = false;
        }
      });
    });
    setSelectAll(selectAllValue);
    handleSaveCacheScoreData(data);
    setScoringCriteriaList(data);
    const selectAllActiveTab = data.find((el) => el.header === actieveTab).data.some((elm) => !elm.value);
    selectAllActiveTabHandler(selectAllActiveTab);
  };

  const selectAllActiveTabHandler = (flag) => {
    switch (actieveTab) {
      case 'Transport':
        setSelectAllTransport(!flag);
        break;
      case 'ESG':
        setSelectAllESG(!flag);
        break;
      case 'Building':
        setSelectAllBuilding(!flag);
        break;
      case 'Location & Amenity':
        setSelectAllLocation(!flag);
        break;
      case 'Custom Criteria':
        setSelectAllCustCrit(!flag);
        break;
      default:
        setSelectAllDeliverability(!flag);
        break;
    }
  };
  const handleClosecomponet = () => {
    if (!isEdit) {
      dispatch({ type: 'SET_CLEAR_SCORING_OBJECT' });
      handleClose();
    } else {
      handleCloseScorePopup();
    }
  };
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      placement='top'
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[3],
      fontSize: 12,
      fontfamily: 'Calibre-R',
    },
  }));

  const subCategoriesStyle = useMemo(
    () => ({
      padding: '2px 15px',
      background: '#F5F7F7',
    }),
    []
  );

  const addCriteriaHandler = () => {
    setEditableIndex(11);
    setIsExist(true);
  };
  const saveHandler = () => {
    const critValue = criteriaValue.trim();
    const temp = cloneDeep(scoringCriteriaList);
    const cData = temp.find((el) => el.header === 'Custom Criteria')?.data;
    const found = cData.find((elm) => elm.item === critValue);
    const foundIndex = cData.findIndex((elm) => elm.item === critValue);
    if (foundIndex === editableIndex) {
      setIsExist(false);
      setEditableIndex(11);
      setCriteriaValue('');
      setPersistedValue('');
      return;
    }
    if (found) {
      setError('Already exist');
      setCriteriaValue(critValue);
      // setEditableIndex(11);
      return;
    }
    const index = temp.findIndex((el) => el.header === 'Custom Criteria');
    let criteriaData = temp[index].data;
    const persistedIndex = cData.findIndex((elm) => elm.item === persistedValue);

    const reqObj = {
      hkey: hkey,
      persistedValue: persistedValue,
      criteriaValue: critValue,
    };

    if (persistedIndex !== -1) {
      if (hkey && hwgtkey) reNameCustomSubCtegory(reqObj);
      criteriaData[persistedIndex] = {
        ...criteriaData[persistedIndex],
        item: critValue,
      };
    } else {
      criteriaData = criteriaData.concat({
        item: critValue,
        value: true,
        score: 0,
        tooltip_text: 'Custom Criteria',
      });
    }
    temp[index] = { ...temp[index], data: criteriaData };
    setScoringCriteriaList(temp);
    handleSaveCacheScoreData(temp);
    setIsExist(false);
    setCriteriaValue('');
    setPersistedValue('');
    setEditableIndex(11);
  };

  const reNameCustomSubCtegory = async (reqObj) => {
    try {
      const response = await axiosPrivate.put(`/weightings/rename-custom-subcategory`, reqObj);
      if (response.status === 200 && response.data.length > 0) {
      }
    } catch (error) {
      console.log('Error while rename the custom subcategory...!', error);
    }
  };
  const editCriteriaHandler = (value) => {
    setIsExist(true);
    setCriteriaValue(value);
    setPersistedValue(value);
    const index = scoringCriteriaList
      .find((el) => el.header === 'Custom Criteria')
      .data.findIndex((elm) => elm.item === value);
    setEditableIndex(index);
  };
  const handleOnBlur = (value) => {
    setError('');
  };
  const resetHandler = () => {
    // const ccData = scoringCriteriaList
    //   .find((el) => el.header === 'Custom Criteria')
    //   ?.data.filter((elm) => elm.item !== persistedValue);
    // const updated = cloneDeep(scoringCriteriaList);
    // const index = updated.findIndex((el) => el.header === 'Custom Criteria');
    // updated[index] = { ...updated[index], data: [...ccData] };
    // setScoringCriteriaList(updated);
    // handleSaveCacheScoreData(updated);
    setIsExist(false);
    setCriteriaValue('');
    setEditableIndex(11);
    setPersistedValue('');
    setError('');
  };
  const handleCheckAllCustCrit = () => {
    setSelectAllCustCrit((flag) => !flag);
    const scData = [...scoringCriteriaList];
    const index = scData.findIndex((el) => el.header === actieveTab);
    const updated = scData[index].data.map((el) => ({
      ...el,
      value: !selectAllCustCrit,
      score: selectAllCustCrit ? 0 : el.score,
    }));
    scData[index] = { ...scData[index], data: updated };
    handleSaveCacheScoreData(scData);
    setScoringCriteriaList(scData);
  };
  const warningMsg = useMemo(() => {
    if (error === 'invalid') return 'Only alphanumeric characters are allowed.';
    else if (error === 'maxLength') return 'Max 30 characters are allowed.';
    else return 'Already exist';
  }, [error]);

  const valueHandler = (val) => {
    if (val.length > 30) {
      setError('maxLength');
    } else if (validValue.test(val || !val)) {
      setCriteriaValue(val);
      setError('');
    } else {
      setError('invalid');
    }
  };
  const quickSetupHandler = () => {
    // dispatch({
    //   type: 'SET_SELECTION_OBJECT',
    //   payload: quickSetupData,
    // });
    const cloned = cloneDeep(quickSetupData);
    if (scoringCriteriaList[5].data.length) {
      const updated = [
        ...cloned.slice(0, 5),
        {
          ...scoringCriteriaList[5],
          data: scoringCriteriaList[5].data.map((el) => ({ ...el, value: false, score: 0 })),
        },
      ];
      setScoringCriteriaList(updated);
      handleSaveCacheScoreData(updated);
      return;
    }
    setScoringCriteriaList(cloned);
    // setScoringCriteriaList((prev) => {
    // if (prev[5].data.length) {
    //   const updated = [
    //     ...cloned.slice(0, 5),
    //     { ...prev[5], data: prev[5].data.map((el) => ({ ...el, value: false, score: 0 })) },
    //   ];
    //   return updated;
    // }
    // return cloned;
    // });
    handleSaveCacheScoreData(cloned);
  };
  return (
    <Dialog
      open={open}
      classes={{ paper: classes.paper }}
      PaperProps={{
        sx: {
          width: 820,
          maxHeight: 696,
        },
      }}
    >
      <div
        style={{
          flexDirection: 'column',
          display: 'flex',
          width: 820,
          height: 696,
          borderRadius: 10,
          overflow: 'auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 30,
          }}
        >
          <Grid
            item
            xs={10}
            md={10}
            style={{ paddingLeft: 30, paddingBottom: 20 }}
          >
            <CustomTypography
              text='Select scoring criteria'
              fontfamily='Calibre-SB'
              fontsize='24px'
              fontweight='500'
              lineheight='32px'
              fontcolor='#1F1E1E'
            />
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: 30,
              paddingBottom: 27,
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={handleClosecomponet}
          >
            <CloseIcon />
          </Grid>
        </div>
        <div style={{ height: 546, paddingBottom: 70 }}>
          <Grid
            container
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Grid
              item
              xs={11}
              md={11}
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 10,
              }}
            >
              <CustomTypography
                text='Choosing your scoring criteria impacts what the properties get scored on. Also, keep in mind some of the criteria will require manual input.'
                fontsize='16px'
                fontweight='400'
                lineheight='24px'
                fontcolor='#1a1a1a99'
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: 'flex',
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 20,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Grid>
                <CustomCheckbox
                  data-qaid='customerDataTable_row_checkbox'
                  label={'Select all scoring criteria'}
                  checked={selectAll}
                  onChange={() => handleCheckAll()}
                  disabled={isExist}
                />
                <Grid
                  style={{
                    fontSize: 12,
                    color: '#1A1A1A',
                    opacity: 0.5,
                    paddingLeft: 31,
                    marginTop: '-10px',
                  }}
                >
                  {
                    scoringCriteriaList
                      .map((el) => el.data)
                      .flat()
                      .filter((elm) => elm.value).length
                  }
                  /{scoringCriteriaList.map((el) => el.data).flat().length} selected
                </Grid>
              </Grid>
              <Grid>
                <Button
                  variant='outlined'
                  style={{ background: '#fff', border: '1px solid #003F2D' }}
                  onClick={quickSetupHandler}
                >
                  <RiTimerLine style={{ width: 20, height: 20, color: '#003F2D', marginRight: 5 }} />
                  <CustomTypography
                    text='Quick setup'
                    fontfamily='Calibre-R'
                    fontsize='16px'
                    fontweight='500'
                    fontcolor='#003F2D'
                    lineheight='16px'
                  />
                </Button>
                <LightTooltip
                  title={
                    <React.Fragment>
                      <Grid
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          padding: 10,
                        }}
                      >
                        <CustomTypography
                          text='Quick setup'
                          fontfamily='Calibre-R'
                          fontsize='20px'
                          lineheight='24px'
                          fontweight='400'
                          fontcolor='#000000'
                          marginBottm={12}
                        />
                        <Divider />
                        <CustomTypography
                          text='Fastest way to set up your project, automatically selecting the criteria and weightings where we have the strongest data. Start with this and adapt as you need. '
                          fontfamily='Calibre-R'
                          fontsize='14px'
                          lineheight='21px'
                          fontweight='400'
                          fontcolor='#000000'
                          marginBottm='10px'
                          marginTop={12}
                        />
                      </Grid>
                    </React.Fragment>
                  }
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </LightTooltip>
              </Grid>
            </Grid>
            <div
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                height: 370,
                overflow: 'hidden',
                display: 'flex',
              }}
            >
              <Grid
                md={4}
                style={{ background: '#F5F7F7', marginRight: 10 }}
              >
                {scoringCriteriaList?.map((item) => (
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '12px 15px',
                      cursor: 'pointer',
                      background: actieveTab === item.header ? '#D8E2DF' : '#F5F7F7',
                    }}
                    key={item.header}
                    onClick={() => setActieveTab(item.header)}
                  >
                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Grid style={{ fontSize: 16, color: '#003F2D' }}>{item.header}</Grid>
                      <Grid style={{ fontSize: 12, color: '#1A1A1A', opacity: 0.5 }}>
                        {
                          scoringCriteriaList.find((el) => el.header === item.header).data.filter((elm) => elm.value)
                            .length
                        }
                        /{scoringCriteriaList.find((el) => el.header === item.header).data.length} selected
                      </Grid>
                    </Grid>
                    <MdOutlineChevronRight style={{ color: '#003F2D', width: 20, height: 20 }} />
                  </Grid>
                ))}
              </Grid>
              <Grid
                md={8}
                style={{
                  overflowY: 'auto',
                }}
              >
                {actieveTab === 'Transport' && (
                  <>
                    <Grid style={subCategoriesStyle}>
                      <CustomCheckbox
                        data-qaid='customerDataTable_row_checkbox'
                        label={'Select all'}
                        checked={selectAllTransport}
                        onChange={handleCheckAllTransport}
                      />
                    </Grid>
                    <Divider />
                  </>
                )}
                {actieveTab === 'Building' && (
                  <>
                    <Grid style={subCategoriesStyle}>
                      <CustomCheckbox
                        data-qaid='customerDataTable_row_checkbox'
                        label={'Select all'}
                        checked={selectAllBuilding}
                        onChange={handleCheckAllBuilding}
                      />
                    </Grid>
                    <Divider />
                  </>
                )}
                {actieveTab === 'Location & Amenity' && (
                  <>
                    <Grid style={subCategoriesStyle}>
                      <CustomCheckbox
                        data-qaid='customerDataTable_row_checkbox'
                        label={'Select all'}
                        checked={selectAllLocation}
                        onChange={handleCheckAllLocation}
                      />
                    </Grid>
                    <Divider />
                  </>
                )}
                {actieveTab === 'ESG' && (
                  <>
                    <Grid style={subCategoriesStyle}>
                      <CustomCheckbox
                        data-qaid='customerDataTable_row_checkbox'
                        label={'Select all'}
                        checked={selectAllESG}
                        onChange={handleCheckAllESG}
                      />
                    </Grid>
                    <Divider />
                  </>
                )}
                {actieveTab === 'Deliverability & Risk' && (
                  <>
                    <Grid style={subCategoriesStyle}>
                      <CustomCheckbox
                        data-qaid='customerDataTable_row_checkbox'
                        label={'Select all'}
                        checked={selectAllDeliverability}
                        onChange={handleCheckAllDeliverability}
                      />
                    </Grid>
                    <Divider />
                  </>
                )}
                {actieveTab === 'Custom Criteria' && (
                  <>
                    <Grid
                      style={{
                        ...subCategoriesStyle,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <CustomCheckbox
                        data-qaid='customerDataTable_row_checkbox'
                        label={'Select all'}
                        checked={selectAllCustCrit}
                        onChange={handleCheckAllCustCrit}
                        disabled={
                          isExist || !scoringCriteriaList.find((el) => el.header === 'Custom Criteria').data.length
                        }
                      />
                      <Button
                        style={{ fontSize: 16, color: '#003F2D' }}
                        onClick={addCriteriaHandler}
                        disabled={
                          isExist ||
                          scoringCriteriaList.find((el) => el.header === 'Custom Criteria')?.data.length === 10
                        }
                      >
                        <FiPlus
                          style={{
                            color: isExist ? '#767676' : '#003F2D',
                            width: 17,
                            height: 17,
                            marginRight: 5,
                          }}
                        />

                        <CustomTypography
                          text='Add new'
                          fontfamily='Calibre-R'
                          fontsize='16px'
                          lineheight='16px'
                          fontweight='500'
                          fontcolor={isExist ? '#767676' : '#003F2D'}
                        />
                      </Button>
                    </Grid>
                    {isExist && !scoringCriteriaList.find((el) => el.header === 'Custom Criteria')?.data.length && (
                      <Grid
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: 20,
                        }}
                      >
                        <Grid md={7}>
                          <TextField
                            disabled={false}
                            id='criteria-name'
                            size='small'
                            label={'Criteria name' + '\xa0' + '\xa0'}
                            type='string'
                            fullWidth
                            inputPlaceholder={'Enter criteria name'}
                            value={criteriaValue}
                            maxLength={31}
                            onChange={(e) => valueHandler(e.target.value)}
                            variant='outlined'
                            onBlur={handleOnBlur}
                            error={error}
                          />
                        </Grid>
                        <Grid>
                          <Button
                            varinat='outlined'
                            style={{
                              fontSize: 16,
                              color: criteriaValue.length > 2 ? '#fff' : '#7f8080',
                              background: criteriaValue.length > 2 ? '#003F2D' : '#d4d7d7',
                              width: 80,
                              marginRight: 5,
                            }}
                            onClick={saveHandler}
                          >
                            <MdOutlineCheck
                              style={{
                                color: criteriaValue.length > 2 ? '#fff' : '#7f8080',
                                width: 18,
                                height: 18,
                                marginRight: 5,
                              }}
                            />

                            <CustomTypography
                              text='Save'
                              fontfamily='Calibre-R'
                              fontsize='16px'
                              lineheight='16px'
                              fontweight='500'
                              fontcolor={criteriaValue.length > 2 ? '#fff' : '#7f8080'}
                            />
                          </Button>
                          <Button
                            style={{
                              fontSize: 16,
                              color: '#003F2D',
                              maxWidth: '30px',
                              maxHeight: '30px',
                              minWidth: '30px',
                              minHeight: '30px',
                              borderRadius: 50,
                            }}
                            onClick={resetHandler}
                          >
                            <MdOutlineClose
                              style={{
                                color: '#1A1A1A',
                              }}
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                    {isExist &&
                      !scoringCriteriaList.find((el) => el.header === 'Custom Criteria')?.data.length &&
                      error && (
                        <CustomTypography
                          text={warningMsg}
                          fontfamily='Calibre-R'
                          fontsize='11px'
                          fontweight='400'
                          lineheight='16px'
                          fontcolor='#FF543E'
                          marginLeft={12}
                          marginTop='-5px'
                        />
                      )}
                    {!!scoringCriteriaList.find((el) => el.header === 'Custom Criteria')?.data.length ? (
                      <>
                        {scoringCriteriaList
                          .find((el) => el.header === 'Custom Criteria')
                          ?.data?.slice(0, editableIndex)
                          .map((obj) => (
                            <>
                              <Grid
                                key={obj.item}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  padding: '2px 15px',
                                }}
                              >
                                <Grid style={{ display: 'flex' }}>
                                  <CustomCheckbox
                                    key={obj.item}
                                    data-qaid='customerDataTable_row_checkbox'
                                    label={obj.item}
                                    checked={obj.value}
                                    marginRight={5}
                                    onChange={() => handleCheck(obj.item)}
                                    disabled={isExist}
                                  />
                                </Grid>
                                {actieveTab === 'Custom Criteria' && (
                                  <Grid
                                    style={{
                                      cursor: !isExist ? 'pointer' : 'default',
                                    }}
                                    onClick={() => {
                                      if (isExist) return;
                                      editCriteriaHandler(obj.item);
                                    }}
                                  >
                                    <EditIcon disabled={isExist} />
                                  </Grid>
                                )}
                              </Grid>
                              <Divider />
                            </>
                          ))}
                        {isExist && (
                          <Grid
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: 20,
                            }}
                          >
                            <Grid md={7}>
                              <TextField
                                disabled={false}
                                id='criteria-name'
                                size='small'
                                label={'Criteria name' + '\xa0' + '\xa0'}
                                type='string'
                                fullWidth
                                inputPlaceholder={'Enter criteria name'}
                                value={criteriaValue}
                                maxLength={31}
                                onChange={(e) => valueHandler(e.target.value)}
                                variant='outlined'
                                onBlur={handleOnBlur}
                                error={error}
                              />
                            </Grid>
                            <Grid>
                              <Button
                                varinat='outlined'
                                style={{
                                  fontSize: 16,
                                  color: criteriaValue.length > 2 ? '#fff' : '#7f8080',
                                  background: criteriaValue.length > 2 ? '#003F2D' : '#d4d7d7',
                                  width: 80,
                                  marginRight: 5,
                                }}
                                onClick={saveHandler}
                              >
                                <MdOutlineCheck
                                  style={{
                                    color: criteriaValue.length > 2 ? '#fff' : '#7f8080',
                                    width: 18,
                                    height: 18,
                                    marginRight: 5,
                                  }}
                                />

                                <CustomTypography
                                  text='Save'
                                  fontfamily='Calibre-R'
                                  fontsize='16px'
                                  lineheight='16px'
                                  fontweight='500'
                                  fontcolor={criteriaValue.length > 2 ? '#fff' : '#7f8080'}
                                />
                              </Button>
                              <Button
                                style={{
                                  fontSize: 16,
                                  color: '#003F2D',
                                  maxWidth: '30px',
                                  maxHeight: '30px',
                                  minWidth: '30px',
                                  minHeight: '30px',
                                  borderRadius: 50,
                                }}
                                onClick={resetHandler}
                              >
                                <MdOutlineClose
                                  style={{
                                    color: '#1A1A1A',
                                  }}
                                />
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                        {error && (
                          <CustomTypography
                            text={warningMsg}
                            fontfamily='Calibre-R'
                            fontsize='11px'
                            fontweight='400'
                            lineheight='16px'
                            fontcolor='#FF543E'
                            marginLeft={12}
                            marginTop='-5px'
                          />
                        )}
                        {scoringCriteriaList
                          .find((el) => el.header === 'Custom Criteria')
                          ?.data?.slice(editableIndex + 1)
                          .map((obj) => (
                            <>
                              <Grid
                                key={obj.item}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  padding: '2px 15px',
                                }}
                              >
                                <Grid style={{ display: 'flex' }}>
                                  <CustomCheckbox
                                    key={obj.item}
                                    data-qaid='customerDataTable_row_checkbox'
                                    label={obj.item}
                                    checked={obj.value}
                                    marginRight={5}
                                    onChange={() => handleCheck(obj.item)}
                                    disabled={isExist}
                                  />
                                </Grid>
                                {actieveTab === 'Custom Criteria' && (
                                  <Grid
                                    style={{
                                      cursor: !isExist ? 'pointer' : 'default',
                                    }}
                                    onClick={() => {
                                      if (isExist) return;
                                      editCriteriaHandler(obj.item);
                                    }}
                                  >
                                    <EditIcon disabled={isExist} />
                                  </Grid>
                                )}
                              </Grid>
                              <Divider />
                            </>
                          ))}
                      </>
                    ) : (
                      !isExist && (
                        <Grid
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '84%',
                          }}
                        >
                          <NoCustomData />
                        </Grid>
                      )
                    )}
                  </>
                )}
                {scoringCriteriaList
                  .find((el) => el.header === actieveTab && el.header !== 'Custom Criteria')
                  ?.data?.map((obj) => (
                    <>
                      <Grid
                        key={obj.item}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          padding: '2px 15px',
                        }}
                      >
                        <Grid style={{ display: 'flex' }}>
                          <CustomCheckbox
                            key={obj.item}
                            data-qaid='customerDataTable_row_checkbox'
                            label={obj.item}
                            checked={obj.value}
                            marginRight={5}
                            onChange={() => handleCheck(obj.item)}
                          />
                          <Grid style={{ width: 20 }}></Grid>
                          <LightTooltip
                            title={
                              <React.Fragment>
                                <Grid
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: 10,
                                  }}
                                >
                                  <CustomTypography
                                    text={obj.item}
                                    fontfamily='Calibre-R'
                                    fontsize='20px'
                                    lineheight='24px'
                                    fontweight='400'
                                    fontcolor='#000000'
                                    marginBottm='27px'
                                  />
                                  <CustomTypography
                                    text={obj.tooltip_text}
                                    fontfamily='Calibre-R'
                                    fontsize='14px'
                                    lineheight='21px'
                                    fontweight='400'
                                    fontcolor='#000000'
                                    marginBottm='10px'
                                  />
                                </Grid>
                              </React.Fragment>
                            }
                          >
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </LightTooltip>
                        </Grid>
                      </Grid>
                      <Divider />
                    </>
                  ))}
              </Grid>
            </div>
          </Grid>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: 66,
            backgroundColor: '#FFFFFF',
          }}
        >
          {isEdit ? (
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: 30,
                  alignItems: 'center',
                }}
              >
                <Button
                  className={disabled ? classes.disablebtnClass : classes.btn}
                  onClick={() => handleContinue()}
                  disabled={disabled}
                >
                  <CustomTypography
                    text='Update'
                    fontfamily='Calibre-SB'
                    fontsize='16px'
                    lineheight='16px'
                    fontweight='500'
                    fontcolor={disabled ? '#7f8080' : 'white'}
                  />
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: 30,
                }}
              >
                <Button
                  className={classes.backbutton}
                  style={{ marginRight: 30 }}
                  onClick={handleBack}
                >
                  <CustomTypography
                    text='Back'
                    fontfamily='Calibre-SB'
                    fontsize='16px'
                    fontweight='500'
                    fontcolor='#003F2D'
                    lineheight='16px'
                  />
                </Button>
                <Button
                  className={disabled || isExist ? classes.disablebtnClass : classes.btn}
                  onClick={() => handleContinue()}
                  disabled={disabled || isExist}
                >
                  <CustomTypography
                    text='Continue'
                    fontfamily='Calibre-SB'
                    fontsize='16px'
                    lineheight='16px'
                    fontweight='500'
                    fontcolor={disabled ? '#7f8080' : 'white'}
                  />
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </Dialog>
  );
};
export default ScoringCriteriaModal;
